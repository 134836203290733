import React from 'react'
import styled from '@emotion/styled'
import crtlogo from "../assets/images/Channel Rock_Primary Logo_1_SM.png"

const CompanyInformation = styled.div`
  display: flex ;
  /* border: 1px solid blue; */
  justify-content: space-around;
  width: 59%;
  margin-top: 20px;
`
const CompanyAddress = styled.div`
  /* border: 1px solid red; */
  width: 193px;
`
const Footer = styled.div`
 /* border: 1px solid red; */
 display: flex;  
 justify-content: center;
 align-items: center;
 flex-direction: column;
 margin-top: 65px;
 
`

const GreyBar = styled.div`
  background-color:#F6F5F5;  
  height: 8px; 
  display: flex;
  width: 59%;
  margin-left: 10px;
  /* justify-content: center;
  align-items: center; */
`
const ImageDiv = styled.div`
  /* border: 1px solid yellow; */
  width: 207px;
  height: 70px;
  padding-top: 10px;
  
  .logo {
    width: 100%;
    height: auto;
  }

`

const GreyText = styled.p`
  font-size: 0.8rem;
  color: #929292;
  font-weight: 400;
`

function ChannelRockFooter() {
  return (
    <Footer>
      <GreyBar></GreyBar>
      <CompanyInformation>
        <ImageDiv>
          <img className='logo' src={crtlogo} alt="Channel Rock Technologies" />
        </ImageDiv>
        <CompanyAddress>
          <GreyText>
            Channel Rock Technologies, LLC 50 Speen Street, Suite 300 Framingham, MA 01701
          </GreyText>
          <GreyText>
            info@channelrocktechnologies.com
          </GreyText>
        </CompanyAddress>
      </CompanyInformation>
    </Footer>
  )
}

export default ChannelRockFooter
