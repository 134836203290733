import {
  OrderedListStyles,
  PageContainer,
  PageContentStyles,
  SuborderedListStyles,
  TextStyles
} from './EndUserAgreementPage';

function PrivacyPolicyPage() {
  return (
    <PageContainer>
      <PageContentStyles>
        <TextStyles>
          Channel Rock Technologies LLC (“CRT”, “we”, or “us”) has created this privacy policy (the “Privacy Policy”) in
          order to inform you (“you”, “your”, or “yourself”) as a purchaser, licenser, and/or user of one or more
          products and/or services offered by CRT (including, without limitation, any software applications)
          (collectively, the “Software”) of the circumstances under which we collect information and data from or about
          you. This Privacy Policy also serves to inform you about the circumstances under which we will release
          information about you to third parties. Please read this Privacy Policy carefully in order to ensure that you
          understand, agree to, and accept it in its entirety. This Privacy Policy may change from time to time, so
          please check back periodically to review it. By using or accessing the Software or purchasing, licensing, or
          using any products or services offered by CRT, you acknowledge that you understand, agree to, and accept,
          without limitation or qualification, all of the following terms and conditions of this Privacy Policy without
          modification:
        </TextStyles>
        <OrderedListStyles>
          <li>
            {' '}
            <strong>Types of Information Collected.</strong>
          </li>
          <SuborderedListStyles>
            <li>
              <strong>Traffic Data Collected.</strong> CRT automatically gathers general statistical information about
              visitors to the Software download page and users of the Software. Accordingly, CRT may automatically track
              and collect certain technical information from you when you use the Software. This may include: (i) your
              IP address; (ii) your domain server; (iii) your type of computer or device; (iv) your type of browser; (v)
              the pages you viewed; and (vi) the products and services you purchased or licensed (collectively “Traffic
              Data”). Traffic Data is anonymous information which does not personally identify you, but which may be
              used for improving your experience using the Software. Traffic Data may be provided to third parties. CRT
              may also use “cookies” for specific purposes such as customizing content specific to your interests or
              storing your password so that you do not have to re-enter it each time you use the Software. A cookie is a
              small amount of data that is sent to your browser from a web server and stored on your computer’s hard
              drive. Preference and options configurations in your browser determine if, and how, a cookie will be
              accepted. You can change those configurations on your computer if you desire. By changing your
              preferences, you can accept all cookies, you can be notified when a cookie is set, or you can reject all
              cookies. If you do so and cookies are disabled, you may be required to re-enter your information more
              often and certain features of the Software may be unavailable.
            </li>
            <li>
              <strong>Personal Information Collected.</strong> In order for you to use the Software, to have a Software
              account, to receive certain services, or to purchase, license, or use products or services that CRT offers
              or may offer in the future, CRT may require you to provide us with information that personally identifies
              you (“Personal Information”). Personal Information may include, without limitation: (i) contact data such
              as your name, email address, and telephone number (“Contact Data”); and (ii) demographic data such as your
              zip code (“Demographic Data”).
            </li>
          </SuborderedListStyles>
          <li>
            <strong>Use of Information Collected.</strong> CRT uses Traffic Data to improve our marketing and
            promotional efforts, to analyze usage of the Software, to improve our content and product and service
            offerings, and to customize the Software’s content, layout, and services. We may also use Traffic Data to
            help diagnose problems with our server, to manage the Software, and to enhance the Software based on the
            usage pattern data we receive. CRT may use Contact Data to send you newsletters, online surveys, information
            about CRT and its products and services, and promotional material from some of our partners. We may also use
            your Personal Information to deliver information to you that, in some cases, is targeted to your interests,
            such as new products, services, and promotions. We may also ask you to voluntarily provide us with
            additional information regarding your personal or business interests, experience, or requests which we may
            use to customize our services for you. CRT may also use your Contact Data to contact you via email when
            necessary or to advise you about CRT, our partners, events, your CRT account, new product and service
            offerings, and communications relevant to your use of the Software. CRT may use your Demographic Data to
            customize and tailor your experience using the Software, displaying content that CRT thinks you might be
            interested in and according to your preferences. If you communicate with us in any manner for any reason, or
            otherwise submit or post information or messages on or through the use of the Software, or purchase,
            license, or use products or services offered by us, any Personal Information provided in such communications
            or related to such licenses, purchases, or use may be collected by us as Personal Information and you
            expressly consent to the use of your Personal Information in accordance with the terms of this Privacy
            Policy.
          </li>
          <li>
            <strong>Disclosure of Information to Third Parties.</strong> CRT respects your privacy and is committed to
            protecting it as set forth in this Privacy Policy. Except as otherwise provided in this Privacy Policy or
            any CRT EULA (as defined below), CRT will keep your Personal Information private and will not disclose it to
            third parties without your prior consent, unless we believe in good faith that such disclosure is reasonably
            necessary or appropriate: (a) to comply with a court order or other legal process; (b) in connection with
            any legal proceeding or prospective legal proceeding; (c) in order to establish, exercise, protect, or
            enforce the rights of CRT or others (including, without limitation, for purposes of fraud prevention); (d)
            to enforce CRT’s EULA; or (e) to protect against imminent harm to the property or safety of CRT, our users,
            our customers, or the public. Notwithstanding anything in this Privacy Policy to the contrary, we may share
            Traffic Data and Demographic Data with advertisers and other third parties. Any Traffic Data and Demographic
            Data we share with advertisers and other third parties is not linked to any Personal Information and cannot
            identify any individual person. Furthermore, notwithstanding anything in this Privacy Policy to the
            contrary: (a) we may share your Personal Information with our employees or third parties who have a need to
            know your Personal Information in connection with providing you with the products and services purchased,
            licensed, or used by you from us, provided that such employees or third parties have agreed to comply with
            the restrictions on use and disclosure contained in this Privacy Policy with respect to such Personal
            Information; and (b) in the event that CRT sells or is contemplating selling all or a portion of its
            business or assets in any manner whatsoever, CRT reserves the right to disclose and/or transfer your
            Personal Information to the purchaser or prospective purchaser in connection with such acquisition, provided
            that such purchaser or prospective purchaser has agreed to comply with the restrictions on use and
            disclosure contained in this Privacy Policy with respect to such Personal Information.
          </li>
          <li>
            <strong>Collection of Payment Information.</strong> CRT does not request, collect, store or use any credit
            card or other payment or financial information (collectively, “Personal Financial Information”) from the
            users of the Software. The purchase of the license to access and use the Software is facilitated by a third
            party which provides us with an online e-commerce platform. Please review the third party’s privacy policy
            regarding its payment application, including, but not limited to, your provision of Personal Financial
            Information in connection with your purchase of a license to use the Software from CRT and the treatment of
            such information. It is your responsibility to review the privacy policy, terms of use and other conditions
            governing your interactions with any third party provider regarding the collection, use, disclosure and
            security of your personal information, including Personal Financial Information.
          </li>
          <li>
            <strong>Security of Personal Information.</strong> CRT employs reasonable and current technical and
            organizational security methods to prevent unauthorized access, maintain data accuracy, and ensure correct
            use of Personal Information. Your Personal Information is stored on secure servers that are kept in a
            physically secure environment. Furthermore, access to your Personal Information in our offices is
            restricted. Only employees or third parties who have a need to know your Personal Information in connection
            with providing you with the products and services purchased, licensed, or used by you from us are granted
            access to your Personal Information, provided that such employees or third parties have agreed to comply
            with the restrictions on use and disclosure contained in this Privacy Policy with respect to such Personal
            Information. Your Software account is password protected. We recommend that you do not divulge your password
            to anyone. Our personnel will never ask you for your password in an unsolicited phone call or in an
            unsolicited email. Remember to sign out of your account and to close your browser window when you have
            finished your session. This is to help ensure that others cannot access your Personal Information and
            correspondence if you share a computer with someone else or are using a computer in a public place where
            others may have access to it. Despite our commitment to protecting the privacy of your Personal Information,
            no data transmission over the Internet, wireless network, or otherwise can be guaranteed to be perfectly
            secure. As a result, we cannot guaranty the security of any Personal Information you transmit to us and you
            do so at your own risk.
          </li>
          <li>
            <strong>Deletion/Disposal of Personal Information.</strong> We may delete or otherwise dispose of any or all
            of your Personal Information, whether stored electronically or in paper form, at any time without notice. In
            the event that we do so, we will delete or dispose of your Personal Information in a manner that employs
            reasonable and current technical and organizational security methods to prevent any unauthorized access to
            your Personal Information.
          </li>
          <li>
            <strong>User Choice Regarding Collection of Personal Information.</strong> You may choose not to provide us
            with any Personal Information. However, you will not be able to access and use those portions of the
            Software that require your Personal Information. If you do not wish to receive information and promotional
            materials from us, you may select the appropriate “opt-out” option each time we ask you for Personal
            Information or by contacting CRT to “opt-out” at any time.
          </li>
          <li>
            <strong>User Ability to Access, Update, and Correct Personal Information.</strong> In order to ensure that
            all of your Personal Information is correct and up to date, at any time you may visit your personal profile
            by accessing your account through the Software. Through your personal profile you may review and update your
            Personal Information that we have already collected.
          </li>
          <li>
            <strong>Data Collection from Children.</strong> CRT does not knowingly collect any Personal Information from
            children under 18 years of age, as the Software is not directed to persons under such age. If a parent or
            guardian becomes aware that his or her child under 18 years of age has provided us with Personal
            Information, he or she should contact CRT. If we become aware that a child under 18 years of age has
            provided us with Personal Information, we will delete or dispose of such Personal Information in a manner
            that employs reasonable and current technical and organizational security methods to prevent any
            unauthorized access to such Personal Information.
          </li>
          <li>
            <strong>Other Limits to Your Privacy.</strong> CRT has no control over the use and privacy of any
            information disclosed by you to third parties through or as a result of your use of the Software or
            otherwise. Therefore, there is a chance such information may become public information. It is your
            responsibility to be aware of how any such recipients of your information may use, disclose and otherwise
            treat such information.
          </li>
          <li>
            <strong>Changes to Privacy Policy.</strong> CRT reserves the right, at any time and without notice, to
            change, modify, add to, or delete portions of this Privacy Policy, simply by posting such change to this
            Privacy Policy on the Software. Any such change will be effective immediately upon posting on the Software.
            It is your responsibility to regularly check the Software to determine if there have been changes to this
            Privacy Policy and to review such changes. If we make any changes to this Privacy Policy, we will indicate
            at the top of this Privacy Policy the date this Privacy Policy was last revised. Your continued use of the
            Software or your purchase, license, or continued use of any products or services offered by CRT after any
            such changes constitutes your acceptance of the new Privacy Policy. If you do not agree to and accept,
            without limitation or qualification, all of the terms and conditions of this Privacy Policy or any future
            Privacy Policy without modification, do not use or access (or continue to use or access) the Software or
            purchase, license, or use (or continue to purchase, license, or use) any products or services offered by
            CRT.
          </li>
          <li>
            <strong>Questions; Contact Information.</strong> If you have any questions or concerns about this Privacy
            Policy, the Software, or your dealings with the Software, please contact CRT via email at
            Privacy@channelrocktechnologies.com.
          </li>
          <li>
            <strong>CRT EULA; Other Agreements.</strong> Please also make sure to read the End User License Agreement
            provided by CRT that govern your purchase, license, and/or use of the Software (the “CRT EULA”), and any
            other agreements or terms and conditions provided by CRT to govern your purchase, license, and/or use of any
            other products or services offered by CRT (the “Other Agreements”). This Privacy Policy is subject to all of
            the terms and conditions CRT EULA, and Other Agreements applicable to you, and does not replace or limit
            them in anyway. In the event of any conflict between this Privacy Policy and the CRT EULA, or Other
            Agreements applicable to you, the CRT EULA or Other Agreements applicable to you will control.
          </li>
        </OrderedListStyles>
      </PageContentStyles>
    </PageContainer>
  );
}

export default PrivacyPolicyPage;
