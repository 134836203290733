import { gql } from '@apollo/client';
export const addDiscussionMutation = gql`
  mutation addDiscussionMutation(
    $content: String
    $createdAt: String
    $createdBy: String
    $isHighlighted: Boolean
    $noteType: String
    $projectId: String
    $typeId: String
    $workspaceId: String
  ) @api(name: "task") {
    add_NoteAndDisscussion(
      syncMode: NODE_COMMITTED
      input: {
        content: $content
        createdAt: $createdAt
        isHighlighted: $isHighlighted
        noteType: $noteType
        typeId: $typeId
        projectId: $projectId
        workspaceId: $workspaceId
        createdBy: $createdBy
        recordStatus: 0
      }
    ) {
      result {
        _id
      }
    }
  }
`;
