import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as TableDropDownIcon } from '../../assets/icons/TableDropDown.svg';
import { DarkGreenButton } from '../styles/ButtonStyles';

export type ToggleProps = {
  open: boolean;
  noLine: boolean;
};

const TableDropDownStyles = styled('span')<ToggleProps>`
  display: inline-block;
  margin-bottom: 27px;
  margin-right: 45px;
  position: relative;
  cursor: pointer;
  .title {
    margin-left: 5px;
    font-weight: 600;
    margin: auto;
  }
  .caret {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      /* cursor: pointer;  */
      svg {
        transform: rotate(360deg);
      }
    }
    svg {
      transform: rotate(270deg);
      transition: transform var(--transition-speed);
    }
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  ${(props) =>
    props.open &&
    `
    .caret{
      svg{
        transform: rotate(360deg)
      }
    }`}
  ${(props) =>
    !props.open &&
    !props.noLine &&
    ` &:after{
              content: "";
              background: #e5e5e5;
              position: absolute;
              height: 1px;
              width:90vw;
              top: 41px;
              left: 10px;    
            }
    `}
`;

const TableDropDown = (props: any) => {
  const [open, setOpen] = useState(props.state);

  return (
    <>
      <TableDropDownStyles noLine={props.noLine || false} open={open} onClick={() => setOpen(!open)}>
        <div className="container">
          <div>
            <button className="caret">
              <TableDropDownIcon />
            </button>{' '}
            <span className="title">
              {props.text}
              {props.icon}
            </span>
            {props.nav}
          </div>
          {props.buttonText && open && (
            <DarkGreenButton onClick={() => props.handleClick()}>{props.buttonText}</DarkGreenButton>
          )}
        </div>
      </TableDropDownStyles>
      <>{open && props.children}</>
    </>
  );
};

export default TableDropDown;
