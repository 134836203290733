import { useContext } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppContext } from '../../AppContext';
import {
  addTaskInTaskNoteMutation,
  updateTaskAssociationMutation
} from '../../graphql/operations/Mutations/Tasks/TaskNoteMutations';
import { LIST_ASSOCIATED_TASKS_FROM_NOTE } from '../../graphql/operations/Queries/Tasks/TaskNoteQueries';
import { ErrorPage } from '../../pages/Others';
import EditorComponent from '../Editor/Editor';
import Loader from '../Loader';
import RightSideBar from '../MeetingNotes/RightSideBar';

const MainContainer = styled.div`
  /* width: 100%; */
  min-height: 100%;
  background: #f6f5f5;
  height: 100vh;
  /* margin-top: 7px; */
  display: flex;
  flex-wrap: nowrap;

  overflow-y: auto;
  /*  */
`;

const LeftContainer = styled.div`
  width: 70%;
  padding: 10px 24px;

  /* overflow-y: auto; */
`;
const RightContainer = styled.div`
  background-color: #ffffff;

  max-height: 600px;
  width: 30%;
`;

const TaskNoteEditor = () => {
  let { taskId } = useParams();

  // @ts-ignore
  const { sections, taskNoteData, editingTable } = useSelector((state) => state.taskNote);
  const { state } = useContext(AppContext);

  const [addTask] = useMutation(addTaskInTaskNoteMutation, {
    fetchPolicy: 'no-cache'
  });

  const [updateTaskAssociation] = useMutation(updateTaskAssociationMutation, { fetchPolicy: 'no-cache' });

  const {
    data: tasks,
    loading: taskLoading,
    error: taskError,
    refetch: refetchTasks
  } = useQuery(LIST_ASSOCIATED_TASKS_FROM_NOTE, {
    variables: {
      noteId: taskNoteData._id,
      recordStatus: 0,
      organizationId_projectId_typeId: `${taskNoteData.organizationId}_${taskNoteData.projectId}_${taskNoteData.workspaceId}`,
      type: 'workspace'
    },
    skip: Object.keys(taskNoteData).length === 0
  });

  const handleCreateTask = async (taskTitle: string, blockId: string) => {
    taskTitle = taskTitle !== '' ? taskTitle : 'Task';
    const toastId = toast.loading('Creating task');

    try {
      const variables: any = {
        assignee: state.userId,
        assigneeDetails: {
          name: state.fullName
        },
        createdAt: new Date().toISOString(),
        priority: 'medium',
        organizationId_projectId_typeId: `${taskNoteData.organizationId}_${taskNoteData.projectId}_${taskNoteData.taskTypeId}`,
        organizationId: taskNoteData.organizationId,
        recordStatus: 0,
        status: 'todo',
        title: taskTitle,
        noteId: taskNoteData._id,
        links: [`${window.location.origin}/notebook/${taskNoteData._id}`],
        updatedAt: new Date().toISOString(),
        updatedBy: state.userId,
        type: 'workspace',
        typeId: taskNoteData.taskTypeId,
        parentAssociateId: taskId,
        isAssociated: true
      };

      if (state.profilePhoto) variables.assigneeDetails.profilePicture = state.profilePhoto;

      const addTaskPromise = addTask({ variables });
      const updateTaskAssociationPromise = updateTaskAssociation({
        variables: { isAssociated: true, updatedAt: new Date().toISOString(), updatedBy: state.userId, id: taskId }
      });

      await Promise.all([addTaskPromise, updateTaskAssociationPromise]);

      toast.success('Successfully created the task', { id: toastId });
      refetchTasks();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id: toastId });
    }
  };

  if (taskLoading) {
    return <Loader />;
  }

  if (taskError) {
    return <ErrorPage />;
  }

  return (
    <MainContainer>
      <LeftContainer>
        <EditorComponent
          sections={sections}
          editorType="TASK_NOTE"
          editingTable={editingTable}
          handleCreateTask={handleCreateTask}
          noteData={taskNoteData}
        />
      </LeftContainer>
      <RightContainer>
        <RightSideBar
          tasks={tasks?.list_TaskItems?._TaskItems}
          saveNoteAsDraft={() => {}}
          entityId={taskId as string}
          entityType="TASK"
          isAssociatedTasks={true}
        />
      </RightContainer>
    </MainContainer>
  );
};

export default TaskNoteEditor;
