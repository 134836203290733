import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../../store';
import { NoDataContainer } from '../../DashboardCard';
import ChatItem from '../ChatList/ChatListBody/ChatItem/ChatItem';
import { Conversations } from '../types';

type Props = {};

const DashboardChat = (props: Props) => {
  const [allMessages, setAllMessages] = useState<Conversations>({});
  const { orgData, nonOrgData } = useSelector((state: RootState) => state.conversations);
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/messages');
  };

  useEffect(() => {
    setAllMessages({ ...orgData, ...nonOrgData });
  }, [orgData, nonOrgData]);
  return Object.keys(allMessages).length === 0 ? (
    <NoDataContainer>
      <p>No Items Available</p>
    </NoDataContainer>
  ) : (
    <>
      {Object.keys(allMessages).map((key, index) => (
        <ChatItem key={key} conversation={allMessages[key][0]} clickHandler={onClick} />
      ))}
    </>
  );
};

export default DashboardChat;
