import styled from '@emotion/styled/macro';

type ButtonStylesCommon = {
  fontSize: string;
  lineHeight: string;
  borderRadius: string;
  fontFamily: string;
  letterSpacing: string;
  cursor: string;
  fontWeight: number;
};

type PaddingProps = {
  padding: string;
};

const ButtonStyles: ButtonStylesCommon = {
  fontSize: '0.9rem',
  lineHeight: '19px',
  borderRadius: '5px',
  fontFamily: 'Open Sans',
  letterSpacing: '0.5px',
  cursor: 'pointer',
  fontWeight: 600
};

const disabled = {
  cursor: 'not-allowed'
};

export const paddingOne: PaddingProps = {
  padding: '5px 12px 5px 12px'
};

export const paddingTwo: PaddingProps = {
  padding: '5px 29px 5px 29px'
};

export const DarkButton = styled.button`
  border: none;
  ${ButtonStyles}
  color: #ffffff;
  background-color: #4c6d66;
  padding: 5px 12px 5px 12px;
  height: 1.8rem;
  :hover {
    font-weight: 700;
    background-color: #4c6d68;
  }
  :disabled {
    cursor: not-allowed;
    background-color: #658a82;
  }
`;
export const LightButton = styled.button`
  border: none;
  ${ButtonStyles}
  color: #26303E;
  background-color: #deeef3;
  padding: 5px 12px 5px 12px;
  height: 1.8rem;
  :hover {
    font-weight: 700;
    background-color: #deeef8;
  }
`;

export const TransparentButton = styled.button`
  ${ButtonStyles}
  color: #26303E;
  height: 1.8rem;
  background-color: transparent;
  border: 1px solid var(--border-color);
  width: auto;
  padding: 5px 12px 5px 12px;
`;

export const GreyButton = styled('button')`
  font-size: 0.9rem;
  line-height: 19px;
  background-color: #dbe2e0;
  color: #26303e;
  border-radius: 5px;
  font-family: 'Open Sans';
  border: none;
  letter-spacing: 0.5px;
  line-height: 21px;
  padding: 5px 12px 5px 15px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Open Sans';
  height: 1.8rem;
`;

export const LightBlueButton = styled('button')`
  font-size: 0.9rem;
  line-height: 19px;
  background-color: #deeef3;
  color: #26303e;
  border-radius: 5px;
  font-family: 'Open Sans';
  border: none;
  letter-spacing: 0.5px;
  line-height: 21px;
  padding: 5px 12px 5px 15px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Open Sans';
  height: 1.8rem;
`;
export const DarkGreenButton = styled('button')`
  font-size: 0.9rem;
  line-height: 19px;
  background-color: #4c6d66;
  color: #ffffff;
  border-radius: 5px;
  font-family: 'Open Sans';
  border: none;
  letter-spacing: 0.5px;
  line-height: 21px;
  padding: 5px 12px 5px 15px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Open Sans';
  height: 1.8rem;
  :disabled {
    ${disabled}
  }
`;
export const DarkGreenCircleButton = styled('button')`
  font-size: 20px;
  background-color: #4c6d66;
  color: #ffffff;
  border-radius: 50%;
  font-family: 'Open Sans';
  border: none;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Open Sans';
  height: 27px;
  width: 27px;
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
  justify-content: center;
  :disabled {
    ${disabled}
  }
`;

export const DarkBlueButton = styled('button')`
  ${ButtonStyles}
  color: #ffffff;
  background-color: #26303e;
  height: 37px;
  padding: 5px 24px 5px 24px;
  border: none;
  :disabled {
    ${disabled}
  }
`;
