import styled from '@emotion/styled';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AppContext } from '../../AppContext';

// const PaymentsContainer = styled.div`
//   display: flex;
// `;

// const SeatWrapper = styled.div`
//   width: 50%;
//   color: white;
//   background: #26303e;
// `;

const PaymentsWrapper = styled.div`
  /* width: 50%; */
  overflow: auto;
  #payment-element {
    margin-bottom: 24px;
  }
`;

const Form = styled.form`
  width: 40vw;
  height: 100vh;
  min-width: 500px;
  align-self: center;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
  border-radius: 7px;
  padding: 40px;
  @media only screen and (max-width: 600px) {
    width: 80vw;
    min-width: initial;
  }
`;

const FormContainer = styled.div`
  margin-top: 10%;
`;
export const FormHeader = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  padding-bottom: 16px;
  display: block;
  opacity: 1;
  color: rgba(26, 26, 26, 0.9);
`;

export const EmailInputDiv = styled.div`
  margin-bottom: 0.75rem;
`;

export const EmailLabel = styled.label`
  margin-bottom: 0.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition-property: transform, opacity;
  transition-duration: 0.5s, 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1), cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s, 0s;
  display: block;
`;

export const EmailInput = styled.input`
  position: relative;
  padding: 0.75rem;
  background-color: white;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  display: block;
  width: 100%;
  :focus {
    outline: 0;
    border-color: hsla(215, 24%, 20%, 50%);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), var(--focusBoxShadow);
  }
  :disabled {
    cursor: not-allowed;
    background-color: var(--table-border);
  }
`;

export const PaymentButton = styled.button`
  background-color: #02b191;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  :hover {
    filter: contrast(115%);
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #658a82;
  }
`;

const PaymentMessage = styled.span`
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
`;

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(AppContext);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          toast.success('Payment Success...');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');

          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const toastId = toast.loading('Awaiting Payment.');
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: props.redirect ? props.redirect : `${window.location.origin}/organization/${state.orgId}`
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    console.log('ERROR', error);
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      toast.dismiss(toastId);
      toast.error('Something went wrong');
    } else {
      // toast.dismiss(toastId)
      toast.dismiss(toastId);
      toast.error('Something went wrong.');
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <PaymentsWrapper>
      <Form id="payment-form" onSubmit={handleSubmit}>
        <FormContainer>
          <FormHeader>Pay With Card</FormHeader>
          <EmailInputDiv>
            <EmailLabel htmlFor="email">Email</EmailLabel>
            <EmailInput placeholder={state.email} id="email" type="email" disabled />
          </EmailInputDiv>
          <PaymentElement id="payment-element" />
        </FormContainer>
        <PaymentButton disabled={isLoading || !stripe || !elements}>Subscribe</PaymentButton>
        {message && <PaymentMessage>{message}</PaymentMessage>}
      </Form>
    </PaymentsWrapper>
  );
}

export default CheckoutForm;
