import toast from 'react-hot-toast';
import { FormikHelpers, FormikState, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import AddProjectMemberValidations from '../../../components/Validations/AddProjectMemberValidations';
import axiosInstance from '../../../axiosInstance';
import { sentryError } from '../../../lib/SentryError';

// type User = {
//   username: string;
//   id: string;
// };

export enum userTypes {
  ADMIN = 'admin',
  USER = 'user'
}

type AddMemberPayload = {
  projectId: string;
  organizationId: string;
  recordType: 'projectRelation';
  projectId_recordType: string;
  userName: string;
  designation: string;
  userId: string;
  userProfile: string | null;
  userType: userTypes;
  id?: string;
};

type ProjectMember = {
  // id: string;
  // username: string;
  username: {
    label: string;
    value: string;
  };
  entityId?: string;
  userType: userTypes;
  relationship: string;
};

// type RouterProps = {
//   state: ProjectMember;
// };

function useAddProjectMembers() {
  const { state } = useContext(AppContext);
  const location = useLocation();
  const RouterState = location.state as ProjectMember;
  const { projectId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [isEdit] = useState(RouterState === null ? false : true);
  const orgUsers = useSelector((state) => state.orgMembers.users).map((user) => {
    // return { id: user.id, fullName: user.fullName, organizationId: user.organizationId, username: user.username };
    return { label: user.fullName, value: user.id };
  });
  const [showAddRelationship, setShowAddRelationship] = useState(RouterState?.relationship ? true : false);
  let navigate = useNavigate();
  // let usernameRef = null;

  const goToProjectMembersPage = () => {
    navigate(`/project/members/${projectId}`);
  };

  const projectMember: ProjectMember = {
    // id: '',
    // username: '',
    username: {
      label: '',
      value: ''
    },
    userType: userTypes.USER,
    relationship: ''
  };

  const getUsername = (id: string) => {
    let found = orgUsers.find((user) => user.id === id);
    return found;
  };

  const sendMemberDetails = async (data: AddMemberPayload) => {
    return axiosInstance.post(`/project/team`, data);
  };

  const editMemberDetails = async (data: AddMemberPayload) => {
    return axiosInstance.patch(`/project/team`, data);
  };

  const handleAdd = async (
    values: ProjectMember,
    resetForm: (nextState?: Partial<FormikState<ProjectMember>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj: AddMemberPayload = {
      projectId: projectId,
      organizationId: state.orgId,
      recordType: 'projectRelation',
      projectId_recordType: `${projectId}_projectRelation`,
      designation: values?.relationship,
      userName: values?.username.label,
      userId: values?.username.value,
      userProfile: null,
      userType: values.userType
    };

    let toastId = toast.loading('Submitting data');
    await sendMemberDetails(obj)
      .then((res) => {
        toast.success('Member added successfully.', {
          id: toastId
        });
        navigate(`/project/members/${projectId}`);
        // resetForm();
        // userNameRef.select.clearValue();
        // formik.setFieldValue('username', '');
        resetForm();
      })
      .catch((error) => {
        sentryError(error.response);
        if (error.response.data.message) {
          if (error.response.data.message === 'UserId is already addded for this project') {
            toast.error('This user is already added to the project', {
              id: toastId
            });
          } else {
            toast.error(error.response.data.message, {
              id: toastId
            });
          }
        } else {
          toast.error('Error while adding member to project.Please try again', {
            id: toastId
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleEdit = async (
    values: ProjectMember,
    resetForm: (nextState?: Partial<FormikState<ProjectMember>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj: AddMemberPayload = {
      projectId: projectId,
      organizationId: state.orgId,
      recordType: 'projectRelation',
      projectId_recordType: `${projectId}_projectRelation`,
      designation: values.relationship,
      userName: values.username.label,
      userId: values?.username.value,
      userProfile: null,
      userType: values.userType,
      id: values.entityId
    };
    let toastId = toast.loading('Submitting data');
    await editMemberDetails(obj)
      .then((res) => {
        toast.success('The user details are updated', {
          id: toastId
        });
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message, {
            id: toastId
          });
        } else {
          toast.error('Error while editing member details.', {
            id: toastId
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = (values: ProjectMember, { resetForm }: FormikHelpers<ProjectMember>) => {
    if (isEdit) {
      handleEdit(values, resetForm);
    } else {
      handleAdd(values, resetForm);
    }
  };

  const formik = useFormik({
    initialValues: RouterState === null ? projectMember : RouterState,
    validationSchema: AddProjectMemberValidations,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  //get user api with orgId
  //send data to api for project details

  return {
    formik,
    showAddRelationship,
    setShowAddRelationship,
    orgUsers,
    submitting,
    isEdit,
    getUsername,
    goToProjectMembersPage

    // usernameRef
  };
}

export default useAddProjectMembers;

//todo
//1. Clear values on submitting form. (done)
//2. Handle Edit Member details in the same Api. (done)
//3. Add Appropriate error messages. (done)
//4. Show profile picture in dropdown options.
//5. Show Icon for number of members in project.
//6. Push to dev and staging.
