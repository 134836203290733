import { createContext, useContext, useState } from 'react';

const localStateContext = createContext();
const LocalStateProvider = localStateContext.Provider;

function SidebarStateProvider({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }
  function closeSidebar() {
    setSidebarOpen(false);
  }
  function openSidebar() {
    setSidebarOpen(true);
  }

  return (
    <LocalStateProvider
      value={{
        sidebarOpen,
        setSidebarOpen,
        toggleSidebar,
        closeSidebar,
        openSidebar,
      }}
    >
      {children}
    </LocalStateProvider>
  );
}

// make a custom hook for accessing the sidebar local state
function useSidebar() {
  // We use a consumer here to access the local state
  const all = useContext(localStateContext);
  return all;
}
export { SidebarStateProvider, useSidebar };
