import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { createHttpLink } from 'apollo-link-http';
import { Auth } from 'aws-amplify';

const cache = new InMemoryCache({
  addTypename: false
});

const authLink = setContext(async (_, { headers }) => {
  let cognitoIdToken = await Auth.currentSession();
  let token = cognitoIdToken.getIdToken().getJwtToken();
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
      // "Content-Type": "application/graphql",
    }
  };
});

export const client = new ApolloClient({
  cache,
  connectToDevTools: true,
  link: ApolloLink.from([
    authLink,
    new MultiAPILink({
      httpSuffix: '',
      endpoints: {
        task: `${process.env.REACT_APP_BASE_URL}/proxyLayer/task`,
        project: `${process.env.REACT_APP_BASE_URL}/proxyLayer/project`,
        organization: `${process.env.REACT_APP_BASE_URL}/proxyLayer/organisation`,
        workspace: `${process.env.REACT_APP_BASE_URL}/proxyLayer/workspace`,
        library: `${process.env.REACT_APP_BASE_URL}/proxyLayer/library`,
        register: `${process.env.REACT_APP_BASE_URL}/proxyLayer/register`
      },
      createHttpLink: () => createHttpLink()
    })
  ])
});
