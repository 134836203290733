import { useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router';
import { AppContext } from '../../../AppContext';
import { LIST_INTERNAL_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';

export interface Project {
  _id: string;
  name: string;
  client: string;
  createdAt: string;
  createdBy: string;
}

const useListProject = () => {
  const { state } = useLocation();
  const [projectList, setProjectList] = useState<Project[]>([]);
  const { state: userDetails } = useContext(AppContext);

  const { error, loading } = useQuery(LIST_INTERNAL_PROJECTS_QUERY, {
    variables: {
      organizationId: userDetails.orgId
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (state) {
        // let found = data.list_ProjectItems._ProjectItems.some((project: Project) => project._id === state?._id);
        // if (!found) {
        //   let newarr = [...data.list_ProjectItems._ProjectItems, state];
        //   setProjectList([...newarr]);
        // } else {
        setProjectList([...data.list_ProjectItems._ProjectItems]);
        // }
        // dispatch(addProjects())
      } else {
        setProjectList([...data.list_ProjectItems._ProjectItems]);
      }
    }
  });

  return {
    error,
    loading,
    projectList
  };
};

export default useListProject;
