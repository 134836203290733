import { userTypes } from '../hooks/features/projects/useAddProjectMembers';

// type userTypes = {
//   id: number,
//   value: userTypes.ADMIN | userTypes.USER,
//   label: userTypes.ADMIN | userTypes.USER
// }[]

export const userTypeOptions = [
  { id: 1, value: userTypes.ADMIN, label: userTypes.ADMIN },
  { id: 2, value: userTypes.USER, label: userTypes.USER }
];
