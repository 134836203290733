import { useMutation, useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import CollectionValidationSchema from '../../../components/Validations/CollectionValidation';

import { addCollectionMutation } from '../../../graphql/operations/Mutations/Library/FileMutations';
import { LIST_FILES_QUERY } from '../../../graphql/operations/Queries/Library/FilesQueries';

type CollectionState = {
  name: string;
  checkedFiles: string[];
};

function useCreateCollection() {
  let navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const { state } = useContext(AppContext);
  const userId = state.userId;
  const {
    data: documentData,
    loading,
    error
  } = useQuery(LIST_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${userId}`,
      recordStatus: 0
    },
    fetchPolicy: 'network-only'
  });
  const initialState: CollectionState = {
    name: '',
    checkedFiles: []
  };
  const [addCollection] = useMutation(addCollectionMutation);

  const handleAddCollection = async (
    CollectionData: CollectionState,
    { resetForm }: FormikHelpers<CollectionState>
  ) => {
    setSubmitting(true);
    const toastId = toast.loading('Creating collection.');
    let CurrentDate = new Date().toISOString();
    try {
      let Response = await addCollection({
        variables: {
          libraryType: 'notesAndCollections',
          name: CollectionData?.name,
          type: 'library',
          typeId: userId,
          projectId: 'NOPROJECT',
          createdAt: CurrentDate,
          createdBy: userId,
          files: formik.values.checkedFiles
        }
      });
      if (Response) {
        toast.dismiss(toastId);
        toast.success('Collection created.');
        setSubmitting(false);
        navigate(-1);
      }
    } catch (error) {
      console.error('Error in creating collection', error);
      toast.dismiss(toastId);
      toast.error('There was an error while creating collection.Try Again.');
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CollectionValidationSchema,
    enableReinitialize: true,
    onSubmit: handleAddCollection
  });

  return {
    loading,
    error,
    formik,
    isSubmitting,
    documentData
  };
}

export default useCreateCollection;
