import styled from '@emotion/styled';
import React from 'react';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import { getBrandLogo } from '../../lib/getBrandLogo';
import prependZero from '../../lib/prependZero';
import { MiddleBox, TitleText } from '../../pages/Payments/UpdatePlan';
import { OptionItem, ToggleSettings } from '../OptionsMenu';

const NoPaymentMethodsStyles = styled.p`
  background: #f6f6f6;
  padding: 10px;
  border-radius: 5px;
`;

type PaymentMethodProps = {
  latestCard: any;
  changePaymentDetails: () => void;
};

function SavedPaymentMethod({ latestCard, changePaymentDetails }: PaymentMethodProps) {
  return (
    <>
      <TitleText>Payment Method</TitleText>
      {latestCard ? (
        <MiddleBox>
          <div className="visa-content">
            {getBrandLogo(latestCard?.card?.brand)}{' '}
            <span>
              {capitalizeFirstLetter(latestCard?.card?.brand)} * * * * {latestCard?.card?.last4}
            </span>
          </div>
          <div className="expiry-date">
            Expires {prependZero(latestCard?.card?.exp_month)} / {latestCard?.card?.exp_year}
          </div>
          <div>
            <ToggleSettings>
              <OptionItem onClick={() => changePaymentDetails()}>Change Payment Details</OptionItem>
            </ToggleSettings>
          </div>
        </MiddleBox>
      ) : (
        <NoPaymentMethodsStyles>No Payment Method Added</NoPaymentMethodsStyles>
      )}
    </>
  );
}

export default SavedPaymentMethod;
