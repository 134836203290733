import { StyledTag } from "../components/FileTag";
// import capitalizeFirstLetter from '../lib/CapitaliseFirstLetter';
import styled from "@emotion/styled";
import { ReactComponent as RoundIcon } from "../assets/icons/RoundIcon.svg";
import { DateStyle, Time } from "../components/ProjectList";
import { ReactComponent as DropdownIcon } from "../assets/icons/TableDropDown.svg";
import { ReactComponent as RightDropdownIcon } from "../assets/icons/RightDropDown.svg";

const GreenText = styled.span`
  color: #4c6d66;
  font-weight: 600;
`;

const YellowText = styled.span`
  color: var(--text-secondary);
  font-weight: 600;
`;

const Divider = styled.span`
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

export const RESPONSEHISTORYCOLUMNS = [
  {
    id: "ResponderName",
    accessor: "respondedBy",
    Cell: ({ cell: { value } }) => {
      return (
        <Divider>
          <RoundIcon />
          {value}
        </Divider>
      );
    },
  },
  {
    id: "status",
    accessor: "action",
    Cell: ({ cell: { value } }) => {
      return <StyledTag>{value ? value.toUpperCase() : ""}</StyledTag>;
    },
  },
  {
    id: "FileName",
    accessor: "responseFileName",
    Cell: ({ cell: { value } }) => {
      return <GreenText>{value}</GreenText>;
    },
  },
  {
    id: "Comment",
    accessor: "comment",
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <YellowText>
            View Comments <DropdownIcon />
          </YellowText>
        ) : (
          <YellowText>
            View Comments <RightDropdownIcon />
          </YellowText>
        )}
      </span>
    ),
  },
  {
    id: "respondedAt",
    accessor: "respondedAt",
    Cell: ({ cell: { value } }) => {
      return (
        <>
          <DateStyle as="span">
            {new Date(value).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </DateStyle>
          <Time as="span">
            {new Date(value).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Time>
        </>
      );
    },
  },
];
