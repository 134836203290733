import React from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import Button from '../../../components/CustomButton';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import Checkbox from '../../../components/Checkbox';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { Field, FieldArray, FormikProvider } from 'formik';
import { Label } from '../../../components/styles/Label';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../components/styles/Header';
import { statusOptions, selectOptions } from '../../../constants/DropDownOptions';
import { statusStyles } from '../../../components/styles/StatusStyles';
import { ProjectDropdownStyles } from '../../../components/styles/ProjectDropdownStyles';
import { WorkspaceDropdownStyles } from '../../../components/styles/WorkspaceDropdownStyles';
import { Input } from '../../../components/styles/Inputs';
import { Small } from '../../../components/styles/SmallText';
import { LinkInputWrapper } from '../../Library/FilesView/Files/CreateNewFile';
import { ErrorText } from '../../../components/Reusable/NewFormik/TextError';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  ChoicesWrapper,
  DateWrapper,
  DropDownWrapper,
  ErrorWrapper,
  FieldSet,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import { ErrorPage } from '../../Others/ErrorPage';
import LinkDisplay from '../../../components/LinkDisplay';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import { useCreateTask } from '../../../hooks/features/tasks/useCreateTask';
import Loader from '../../../components/Loader';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../components/Reusable/NewFormik/TextArea1';

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4.6rem;
`;

export const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.18rem;
  gap: 19px;
`;
export const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-left: 1.625rem;
`;
export const customStyles = {
  singleValue: (styles: any) => ({
    ...styles,
    background: '#DEEEF3',
    padding: '3px 10px',
    borderRadius: '5px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    display: 'none'
  })
};

const CheckBoxText = styled.span`
  margin-left: 8px;
`;

function CreateTask() {
  let userRef = React.useRef<HTMLInputElement>(null);
  let workspaceref = React.useRef<HTMLInputElement>(null);

  let {
    formik,
    projectsLoading,
    error,
    WorkspaceData,
    doNothing,
    handleProjectChange,
    handleWorkspaceChange,
    projectData,
    projectsError,
    state,
    submitting,
    users,
    handlePersonalTask,
    workspaceIds,
    getProjectName,
    parentTaskData,
    getWorkspaceName,
    workspaceLoading
  } = useCreateTask();

  if (projectsLoading || workspaceLoading) return <Loader />;
  if (error || projectsError) return <ErrorPage />;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>New Task</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={submitting} type="submit">
              Create Task
            </Button>
          </TitleRight>
        </Header>
        <TaskDetails>
          <FieldSet>
            <div>
              <InputWrapper>
                <InputField
                  label="Task Title"
                  type="text"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  touched={formik.touched.title}
                  errors={formik.errors.title}
                  disabled={submitting}
                  placeholder="Enter your task"
                />
                {/* <InputComponent label="Task Title" name="title" /> */}
              </InputWrapper>

              <ChoicesWrapper>
                {projectData?.list_ProjectItems?._ProjectItems ? (
                  <>
                    <Label htmlFor="projectId">Projects</Label>
                    <Select
                      name="projectId"
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e._id}
                      styles={ProjectDropdownStyles}
                      onBlur={formik.handleBlur}
                      defaultValue={parentTaskData ? getProjectName(parentTaskData?.projectId) : 'project-name'}
                      isDisabled={
                        submitting || state.orgId === 'null' || formik.values.isPersonal || parentTaskData?.projectId
                      }
                      options={[
                        ...projectData?.list_ProjectItems?._ProjectItems,
                        { name: 'Personal Task', _id: 'personal' }
                      ]}
                      onChange={(selectedOption) => {
                        handleProjectChange(selectedOption, workspaceref);
                      }}
                    />

                    <Label htmlFor="workspaceId">Workspace</Label>
                    <Select
                      name="workspaceId"
                      styles={WorkspaceDropdownStyles}
                      placeholder={'workspace-name'}
                      ref={(ref) => {
                        workspaceref = ref;
                      }}
                      options={WorkspaceData ? Object.values(WorkspaceData) : []}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e._id}
                      onBlur={formik.handleBlur}
                      isDisabled={
                        !formik.values.projectId ||
                        formik.values.isPersonal ||
                        workspaceIds.length === 0 ||
                        state.orgId === 'null' ||
                        submitting
                      }
                      onChange={(selectedOption) => {
                        handleWorkspaceChange(selectedOption, userRef);
                      }}
                      defaultValue={parentTaskData ? getWorkspaceName(parentTaskData?.typeId) : 'workspace-name'}
                    ></Select>
                  </>
                ) : null}
              </ChoicesWrapper>
              <ErrorWrapper>
                {formik.touched['projectId'] && formik.errors['projectId'] ? (
                  <p style={{ color: 'red' }}>{formik.errors['projectId']}</p>
                ) : null}
                {formik.touched['workspaceId'] && formik.errors['workspaceId'] ? (
                  <p style={{ color: 'red' }}>{formik.errors['workspaceId']}</p>
                ) : null}
              </ErrorWrapper>
              <InputWrapper style={{ width: 'fit-content' }}>
                <Label>
                  <Checkbox
                    disabled={state.orgId === 'null' || state.orgId === null}
                    name="isPersonal"
                    checked={formik.values.isPersonal}
                    onChange={handlePersonalTask}
                  />
                  <CheckBoxText>This is a personal task</CheckBoxText>
                </Label>
              </InputWrapper>
              <InputWrapper>
                <TextAreaField
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  touched={formik.touched.description}
                  errors={formik.errors.description}
                  disabled={submitting}
                  onChange={formik.handleChange}
                />
              </InputWrapper>
              <LinkWrapper>
                <Label>Links</Label>
                <FormikProvider value={formik}>
                  <FieldArray name="links">
                    {({ remove, push }) => (
                      <>
                        {formik.values.links &&
                          formik.values.links.length > 0 &&
                          formik.values.links.map((link, index) => {
                            return (
                              <>
                                {link.saved ? (
                                  <LinkDisplay link={link.value} removeLink={() => remove(index)} />
                                ) : (
                                  <Field name={`links.${index}`}>
                                    {({ meta, field }) => {
                                      return (
                                        <>
                                          <LinkInputWrapper>
                                            <Input
                                              className="link-input"
                                              disabled={submitting}
                                              type="text"
                                              {...field}
                                              value={field.value.value}
                                              name={`links.${index}`}
                                              onChange={(e) =>
                                                formik.setFieldValue(`links[${index}].value`, e.target.value)
                                              }
                                              error={meta.touched && meta.error}
                                            />
                                            <Button
                                              disabled={submitting}
                                              onClick={() => {
                                                if (!meta.error?.value && field.value.value !== '') {
                                                  formik.setFieldValue(`links[${index}].saved`, true);
                                                }
                                              }}
                                            >
                                              Save
                                            </Button>
                                            <CloseIcon
                                              onClick={() => (!submitting ? remove(index) : doNothing())}
                                              height="25px"
                                              width="25px"
                                            />
                                          </LinkInputWrapper>
                                          {meta.touched && meta.error && (
                                            <ErrorText>{meta.error.value || meta.error.saved} </ErrorText>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Field>
                                )}
                              </>
                            );
                          })}
                        <div>
                          <Button
                            disabled={formik.isSubmitting || submitting}
                            onClick={() => push({ value: '', saved: false })}
                          >
                            + Add Link
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </LinkWrapper>
            </div>
            <div>
              <ChoicesWrapper>
                <Label>Status</Label>
                <Select
                  name="status"
                  options={statusOptions}
                  defaultValue={statusOptions[0]}
                  onChange={(value: any) => {
                    formik.setFieldValue('status', value ? value : '');
                  }}
                  styles={statusStyles}
                  isDisabled={submitting}
                />
                <Label className="priority">Priority</Label>
                <Select
                  name="priority"
                  options={selectOptions}
                  defaultValue={selectOptions[1]}
                  styles={statusStyles}
                  isDisabled={submitting}
                  onChange={(value: any) => {
                    formik.setFieldValue('priority', value ? value : '');
                  }}
                />
              </ChoicesWrapper>
              <DateWrapper>
                <Label>Due Date</Label>
                <DateInputField
                  type="date"
                  name="dueDate"
                  value={formik.values.dueDate}
                  onChange={formik.handleChange}
                  touched={formik.touched.dueDate}
                  errors={formik.errors.dueDate}
                  disabled={submitting}
                  min={new Date().toISOString().slice(0, 10)}
                />
              </DateWrapper>
              <AssigneeWrapper>
                <Label>Reporter</Label>
                <AssigneeSeparator>
                  <AvatarStyles>
                    <CustomAvatar name={`${state.fullName}`} size={21} />
                  </AvatarStyles>
                  <Small>
                    {getDate()} {getTime()}
                  </Small>
                </AssigneeSeparator>
              </AssigneeWrapper>
              <DropDownWrapper>
                <Label>Assigned To</Label>
                <Select
                  name="assignedTo"
                  options={users}
                  isSearchable={true}
                  isClearable={true}
                  onBlur={formik.handleBlur}
                  placeholder={''}
                  ref={(ref) => {
                    userRef = ref;
                  }}
                  isDisabled={formik.values.isPersonal || submitting}
                  menuPortalTarget={document.body}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  onChange={(value: any) => {
                    formik.setFieldValue('assignedTo', value ? value.id : '');
                    formik.setFieldValue('assignedToDetails.name', value ? value.value : '');
                    formik.setFieldValue('assignedToDetails.email', value ? value.email : '');
                  }}
                />
              </DropDownWrapper>
              {formik.touched['assignedTo'] && formik.errors['assignedTo'] ? (
                <p style={{ color: 'red', marginLeft: '1rem' }}>{formik.errors['assignedTo']}</p>
              ) : null}
            </div>
          </FieldSet>
        </TaskDetails>
      </form>
    </>
  );
}

export default CreateTask;
