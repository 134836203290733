import { FormikHelpers, FormikState, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import ProjectAddressValidationSchema from '../../../components/Validations/ProjectAddressValidation';
import { designationEnum, ModuleType } from './useOtherContacts';
import { useNavigate } from 'react-router';

type AddressFormData = {
  name: string;
  phone?: string;
  emailId?: string;
  address: string;
};

export enum recordTypes {
  ADDRESS = 'address',
  CONTACT = 'contact',
  TEAM = 'team'
}

export type AddressDataPayload = {
  recordName: AddressFormData['name'];
  phoneNumber?: AddressFormData['phone'];
  emailId?: AddressFormData['emailId'];
  address?: AddressFormData['address'];
  projectId: string;
  entityType: string;
  recordType: recordTypes;
};

export type OtherContactsPayload = {
  address?: string;
  recordName: string;
  emailId?: string;
  contactName: designationEnum;
  projectId: string;
  entityType: string;
  organizationId: string;
  recordType: recordTypes;
  phoneNumber: string;
};

type RouterProps = {
  addressId?: string;
  isEdit?: boolean | null;
  module?: ModuleType;
};

const useProjectAddress = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { projectId, addressId } = useParams();
  const [address, setAddress] = useState<AddressFormData>();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { state: linkState }: { state: RouterProps | null } = useLocation();
  let orgId = state.orgId;
  const initialState: AddressFormData = {
    name: '',
    phone: '',
    emailId: '',
    address: ''
  };

  useEffect(() => {
    const getAddress = async () => {
      if (linkState?.isEdit && linkState?.module === 'project') {
        try {
          setLoading(true);
          let res = await axiosInstance.get(
            `/project/contact?id=${linkState.addressId}&projectId=${projectId}&recordType=${recordTypes.ADDRESS}`
          );
          let obj: AddressFormData = {
            name: res.data.data.recordName,
            phone: res.data.data.phoneNumber,
            emailId: res.data.data.emailId,
            address: res.data.data.address
          };
          setAddress(obj);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('ERROR', error);
        }
      }
    };
    getAddress();
  }, []);

  const submitData = async (data: AddressDataPayload | OtherContactsPayload) => {
    if (linkState?.isEdit) {
      return axiosInstance.patch(`/project/contact`, data);
    } else {
      return axiosInstance.post(`/project/contact`, data);
    }
  };

  const handleEdit = async (
    values: AddressFormData,
    resetForm: (nextState?: Partial<FormikState<AddressFormData>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj = {
      recordName: values.name,
      phoneNumber: values?.phone,
      emailId: values?.emailId,
      address: values?.address,
      projectId: projectId,
      organizationId: orgId,
      entityType: 'PROJECT',
      recordType: recordTypes.ADDRESS,
      id: linkState?.addressId
    };
    const toastId = toast.loading('Submitting data');
    await submitData(obj)
      .then((res) => {
        if (res.data) {
          toast.dismiss(toastId);
          toast.success('Project address edited successfully.');
          setAddress({
            name: '',
            phone: '',
            emailId: '',
            address: ''
          });
          resetForm();
          setSubmitting(false);
          navigate(`/project/${projectId}`);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        setSubmitting(false);
        toast.error('Error while editing data');
        console.error('ERROR', error);
      });
  };

  const handleAdd = async (
    values: AddressFormData,
    resetForm: (nextState?: Partial<FormikState<AddressFormData>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj = {
      recordName: values.name,
      phoneNumber: values?.phone,
      emailId: values?.emailId,
      address: values?.address,
      projectId: projectId,
      organizationId: orgId,
      entityType: 'PROJECT',
      recordType: recordTypes.ADDRESS
    };
    const toastId = toast.loading('Submitting data');
    await submitData(obj)
      .then((res) => {
        if (res.data) {
          toast.dismiss(toastId);
          toast.success('Project address added successfully.');
          setAddress({
            name: '',
            phone: '',
            emailId: '',
            address: ''
          });
          resetForm();
          setSubmitting(false);
          // }
          navigate(`/project/${projectId}`);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        setSubmitting(false);
        toast.error('Error while editing data');
        console.error('ERROR', error);
      });
  };

  const handleSubmit = async (values: AddressFormData, { resetForm }: FormikHelpers<AddressFormData>) => {
    if (linkState?.isEdit) {
      handleEdit(values, resetForm);
    } else {
      handleAdd(values, resetForm);
    }
    // let obj = {
    //   recordName: values.name,
    //   phoneNumber: values?.phone,
    //   emailId: values?.emailId,
    //   address: values?.address,
    //   projectId: projectId,
    //   organizationId: orgId,
    //   entityType: 'PROJECT',
    //   recordType: recordTypes.ADDRESS
    // };
    // if (addressId) {
    //   obj.id = addressId;
    // }
    // const toastId = toast.loading('Submitting Data');
    // await submitData(obj)
    //   .then((res) => {
    //     if (res.data) {
    //       toast.dismiss(toastId);
    //       toast.success('Project Address added successfully.');
    //       if (addressId) {
    //         setAddress({
    //           name: '',
    //           phone: '',
    //           emailId: '',
    //           address: ''
    //         });
    //         resetForm();
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     toast.dismiss(toastId);
    //     toast.error('Error while editing data');
    //     console.error('ERROR', error);
    //   });
  };

  const formik = useFormik({
    initialValues: address || initialState,
    onSubmit: handleSubmit,
    validationSchema: ProjectAddressValidationSchema,
    validateOnBlur: true,
    enableReinitialize: true
  });

  return {
    initialState,
    handleSubmit,
    ProjectAddressValidationSchema,
    submitData,
    address,
    loading,
    formik,
    linkState,
    isSubmitting
  };
};

export default useProjectAddress;
