import styled from "@emotion/styled";

export const ItemStyles = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--table-border);
  cursor: pointer;
  align-items: center;
`;

export const ItemContainer = styled.div``;
