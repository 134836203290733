import { gql } from "@apollo/client";

export const LIST_NON_ASSOCIATED_TASKS = gql`
      query ListTaskQuery($isAssociated: Boolean, $type:String ,$organizationId_projectId_typeId: String)
      @api(name: "task") {
            list_TaskItems(
            filter: {
                  type: {eq:$type}
                  organizationId_projectId_typeId: { ge: $organizationId_projectId_typeId }
                  recordStatus: { eq: 0 }
                  isAssociated:{eq: $isAssociated} 
            }
            ) {
                  _TaskItems {
                        assignedTo
                        assignedToDetails {
                              name
                              profilePicture
                        }
                        assignee
                        assigneeDetails {
                              name
                              profilePicture
                        }
                        createdAt
                        description
                        dueDate
                        _id
                        isAcknowledged
                        priority
                        projectId
                        recordStatus
                        status
                        title
                        isAssociated
                        updatedAt
                        updatedBy
                        type
                        typeId
                  }
            }
      }
`