import React from "react";
import Avatar from "react-avatar";
import styled from "@emotion/styled";

type NameProps = {
  isTable: boolean;
};

const NameStyle = styled("span")<NameProps>`
  ${(props) =>
    props.isTable === true
      ? `margin-left:10px;
     margin-top:10px;
  `
      : `margin-left:10px;   
    `}
`;

const CustomAvatar = (props: any) => {
  return (
    <>
      <Avatar
        round={true}
        size={props.size}
        name={props.name}
        textSizeRatio={2}
      />
      <NameStyle isTable={props.isTable}>{props.name}</NameStyle>
    </>
  );
};

export default CustomAvatar;
