import React, { useState } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '../../../axiosInstance';
import { useToggle } from '../../../lib/UseToggle';
import { PlanType, SeatType } from '../../../pages/Organization/ViewOrganization';

function useAssignSeatsToSelected(
  dispatch,
  userDetails,
  state,
  setIsInSufficientSeats,
  totalSeats,
  monthlySeats,
  yearlySeats
) {
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [isAssignSeatToSelectedUsersModalOpen, toggleAssignSeatToSelectedUsersModal] = useToggle();
  const [submittingAssignSeatToSelectedData, setSubmittingAssignSeatToSelected] = useState(false);

  const assignSeatsToSelectedUsers = async (seatType: SeatType) => {
    setSubmittingAssignSeatToSelected(true);
    let toastId = toast.loading('Assigning Seats...');
    let arrayOfUserIds = selectedRowIds.map((user) => user.original.id);
    let totalNumberOfSeats = totalSeats;
    let takenSeats;
    let availableSeats;
    if (seatType === 'MONTHLY') {
      takenSeats = state.currentOrganizationUsers.filter((user) => user.seatType === 'MONTHLY').length;
      availableSeats = monthlySeats - takenSeats;
    } else if (seatType === 'YEARLY') {
      takenSeats = state.currentOrganizationUsers.filter((user) => user.seatType === 'YEARLY').length;
      availableSeats = yearlySeats - takenSeats;
    } else if (seatType === 'TRIAL') {
      availableSeats = totalNumberOfSeats;
    }
    let numberOfUsersToAssignSeats = arrayOfUserIds.length;
    if (availableSeats < numberOfUsersToAssignSeats) {
      toast.error('The number of seats is insufficent. Please add more seats.');
      toast.dismiss(toastId);
      setIsInSufficientSeats(true);
      setSubmittingAssignSeatToSelected(false);
    } else {
      await axiosInstance
        .patch(`/organization/manageMembers`, {
          users: arrayOfUserIds,
          organizationId: userDetails.orgId,
          seatType: seatType
        })
        .then((response) => {
          if (response.data) {
            dispatch({ type: 'ASSIGNING SEAT_TO_SELECTED', payload: { ids: arrayOfUserIds, seatType: seatType } });
            toast.dismiss(toastId);
            toast.success('Seats Assigned to Selected Users.');
            setSubmittingAssignSeatToSelected(false);
            toggleAssignSeatToSelectedUsersModal();
          }
        })
        .catch((error) => {
          console.error('Error in Assigning seat to selected users', error);
          toast.dismiss(toastId);
          toast.error('Something went wrong in assigning seats to selected users.');
          setSubmittingAssignSeatToSelected(false);
          toggleAssignSeatToSelectedUsersModal();
        });
    }
  };
  return {
    selectedRowIds,
    setSelectedRowIds,
    isAssignSeatToSelectedUsersModalOpen,
    submittingAssignSeatToSelectedData,
    assignSeatsToSelectedUsers,
    toggleAssignSeatToSelectedUsersModal
  };
}

export default useAssignSeatsToSelected;
