import { Header, PageTitle, TitleLeft } from '../../../../components/styles/Header';
import styled from '@emotion/styled';
import { useQuery, useLazyQuery, gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import WorkspaceTemplateTile from '../../../../components/WorkspaceTemplateTile';
import Button from '../../../../components/CustomButton';
import ProjectWorkspaceList from '../../../../components/ProjectWorkspaceList';
import { useToggle } from '../../../../lib/UseToggle';
import Modal from '../../../../components/Modal';
import { ModalContainer } from '../../../../components/styles/ModalStyles';
import { useContext, useEffect, useState } from 'react';
import axiosInstance from '../../../../axiosInstance';
import { PinnedProjectsContext } from '../../../../PinnedProjectContext';
import _ from 'lodash';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { listTemplatesQuery } from '../../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { useDispatch } from 'react-redux';
import { getProjectQuery } from '../../../../graphql/operations/Queries/Projects/ProjectQueries';
import { postDashboardApi } from '../../../../reducers/dashboard';
import { useSelector } from 'react-redux';
import { ReactComponent as TaskIconSmall } from '../../../../assets/icons/icon-task-small.svg';
import { ReactComponent as WorkspaceIcon } from '../../../../assets/icons/WorkspaceIcon.svg';
import { AppContext } from '../../../../AppContext';
import { StyledArchiveIcon } from '../../ProjectViews/Projects';
import { Link } from 'react-router-dom';

// import CustomAvatar from "../../../../components/TableComponents/CustomAvatar";
// import { getFullNameByUserName } from "../../../../lib/getFullName";
// import BackIcon from "../../../../assets/SvgComponents/BackIcon";
// import { OptionItem, ToggleSettings } from "../../../../components/OptionsMenu";
// import { AssigneeSeparator, AssigneeWrapper, AvatarStyles } from "../../components/styles/FormStyles";
// import { ReactComponent as Ellipsis } from "../../assets/icons/ellipsis.svg";
// import { PageNavStyles } from "../Library/FilesView/ViewFile";
// import { templates, savedTemplates } from "../../constants/MockTemplateOptions";
// import { projectDetails } from "../../graphql/reactivities/project";
// import { Label } from "../../components/styles/Label";
// import CustomAvatar from "../../components/TableComponents/CustomAvatar";
// import { Small } from "../../components/styles/SmallText";
// import { data } from "../../constants/KanbanData";
// import {
//   AssigneeSeparator,
//   AssigneeWrapper,
//   AvatarStyles,
//   ClientSeparator,
//   ClientWrapper,
//   FieldSet,
//   InputWrapper,
// } from "../../../../components/styles/FormStyles";
// import { Label } from "../../../../components/styles/Label";
// import { Small } from "../../../../components/styles/SmallText";

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

const ModalTitle = styled.div`
  background: #26303e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 2.625rem;
`;

const ModalBody = styled.div`
  background: white;
  padding: 35px 0px;
  /* min-height: 619px; */
  height: auto;
  h3 {
    margin-left: 52px;
  }
`;

export const TemplateContainer = styled.div`
  display: flex;
  padding-left: 52px;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  > * + * {
    margin-left: 10px;
  }
`;

// const Container = styled.div`
//   display: flex;
//   /* flex-direction: column; */
//   gap: 10px;
//   justify-content: space-between;
//   margin-left: 2.7rem;
//   /* border: 1px solid green; */

//   .projectDetails {
//   }
// `;

// const TitleWrapper = styled.div`
//   padding-top: 25px;
//   padding-left: 1rem;
//   h3 {
//     max-width: 746px;
//   }
//   /* border: 1px solid violet; */
// `;

// const Description = styled.p`
//   max-width: 746px;
//   margin: 0px;
//   line-height: 21.79px;
//   font-weight: normal !important;
//   margin-top: 10px;
//   min-width: 50vw;
//   /* border: 1px solid red; */
// `;

export const NoWorkspacesAdded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;
  margin: 20px;
`;

export const generateQuery = (entityMappings: string[]) => {
  let emptyQuery = ``;
  if (entityMappings?.length > 0) {
    for (const [index, value] of entityMappings.entries()) {
      emptyQuery += `workspace_${index}: get_Workspace(id: "${value}", readMode: CACHED) {
            name
            projectId            
            createdAt
            createdBy
            organizationId            
            registers            
            updateBy
            updatedAt           
            _id
            recordStatus
          }\n`;
    }
  } else {
    emptyQuery = `get_Workspace(id: "null") {
            name
            projectId           
            createdAt
            createdBy
            organizationId            
            registers            
            updateBy
            updatedAt            
            _id
        }\n`;
  }
  return gql`
      query getWorkspaceQuery @api(name: "workspace") {
          ${emptyQuery}
      }
    `;
};

const ProjectView = () => {
  const { state } = useContext(AppContext);
  const { state: pinnedProjects, dispatch } = useContext(PinnedProjectsContext);
  const navigate = useNavigate();
  let reduxDispatch = useDispatch();
  const [isOpen, toggle] = useToggle(false);
  let { projectId } = useParams();
  const [selectedTemplateIndex, setSelectedTemplate] = useState(0);
  const [selectedTemplateData, setSelectedTemplateData] = useState('');
  const [workspaceIds, setWorkspaceIds] = useState<string[]>([]);
  const [belongsToWorkspaces, setBelongsToWorkspaces] = useState(true);
  const [globalTemplates, setGlobalWorkspaceTemplates] = useState([]);
  const [orgTemplates, setOrgTemplates] = useState([]);
  // const [fullName, setFullName] = useState("")
  const usersLoading = useSelector((state) => state.orgMembers.loading);
  const allUsers = useSelector((state) => state.orgMembers.users);

  useEffect(() => {
    axiosInstance
      .get(`/userEntity/?entityType=WORKSPACE&projectId=${projectId}&userId=${state.userId}`)
      .then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          let entities = response.data.data;
          let Ids = entities.map((entity) => entity.entityId);
          setWorkspaceIds(Ids);
        } else {
          setBelongsToWorkspaces(false);
        }
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  }, []);

  const batchGetWorkspaces = generateQuery(workspaceIds);
  const [getAllWorkspaces, { data: WorkspaceData }] = useLazyQuery(batchGetWorkspaces, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (workspaceIds.length > 0) {
      getAllWorkspaces();
    }
  }, [workspaceIds]);

  const {
    data: ProjectData,
    error,
    loading
    // refetch,
  } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    },
    onCompleted: (data) => {
      // let username = data?.get_Project?.createdBy
      // projectDetails(data.get_Project);
    }
  });

  const {
    data: workspaceTemplateData,
    error: templateLoadingError,
    loading: templatesLoading
  } = useQuery(listTemplatesQuery, {
    variables: {
      organizationId_type: `${state.orgId}_WORKSPACE`
    },
    onCompleted: () => {
      let templates = [...workspaceTemplateData.list_TemplateItems._TemplateItems];
      let globaltemplates = templates.filter((template) => template.isGlobal === true);
      let orgtemplates = templates.filter(
        (template) => template.isGlobal !== true && template.organizationId === state.orgId
      );
      setGlobalWorkspaceTemplates([...globaltemplates, { _id: 'new', templateName: 'Blank Template' }]);
      setOrgTemplates(orgtemplates);
    },
    fetchPolicy: 'network-only'
  });

  const handleClick = () => {
    toggle();
  };
  // const pinToSidebar = () => {
  //   if (pinnedProjects.length >= 10) {
  //     toast.error('Remove projects from sidebar before pinning');
  //   } else {
  //     dispatch({
  //       type: 'SET_PROJECTS',
  //       data: [{ name: ProjectData.get_Project.name, id: projectId }]
  //     });
  //     axiosInstance
  //       .patch(`/users/${state.userId}`, {
  //         pinnedProjects: [...pinnedProjects, { name: ProjectData.get_Project.name, id: projectId }]
  //       })
  //       .then((res) => {
  //         axiosInstance.get(`/users/${state.userId}`).then((res) => {});
  //       });
  //   }
  // };
  // const removeFromSidebar = () => {
  //   dispatch({
  //     type: 'FILTER_PROJECTS',
  //     data: [{ name: ProjectData.get_Project.name, id: projectId }]
  //   });
  //   let filteredProjects = pinnedProjects.filter((project) => project.id !== projectId);
  //   axiosInstance
  //     .patch(`/users/${state.userId}`, {
  //       pinnedProjects: [...filteredProjects]
  //     })
  //     .then((res) => {});
  // };
  const goToWorkspace = () => {
    navigate(`/newprojects/workspaceconfig/${projectId}/${selectedTemplateIndex}`);
  };
  const handleSelectedTemplate = (id) => {
    setSelectedTemplate(id);
  };
  const editProject = () => {
    navigate(`/editproject/${projectId}`);
  };

  useEffect(() => {
    return () => {
      if (ProjectData?.get_Project) {
        reduxDispatch(
          postDashboardApi({
            type: 'metaData',
            moduleType: 'PROJECTS',
            orgId: state.orgId,
            metaDataId: projectId,
            metaData: { _id: projectId, name: ProjectData?.get_Project?.name }
          })
        );
      }
    };
  }, [ProjectData]);

  // if (allUsers.length > 0) {
  //   let fullName = getFullNameByUserName(username, allUsers)
  //   setFullName(fullName)
  // }

  if (loading || templatesLoading || usersLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error || templateLoadingError) return <ErrorPage />;

  const currentUserRole = state?.role?.ORGANIZATION?.[0]?.roleName;
  return (
    <>
      {/* <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle> {ProjectData?.get_Project?.code} </PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <ToggleSettings>
            <OptionItem onClick={editProject}>Edit Project</OptionItem>
            {pinnedProjects.some((project) => project.id === projectId) ? (
              <OptionItem onClick={removeFromSidebar}>
                Remove from sidebar
              </OptionItem>
            ) : (
              <OptionItem onClick={pinToSidebar}>Pin to sidebar</OptionItem>
            )}
          </ToggleSettings>
        </TitleRight>
      </Header>
      <Container>
        <FieldSet>
          <div>
            <TitleWrapper>
              <PageTitle>{ProjectData?.get_Project?.name} </PageTitle>
            </TitleWrapper>
            <InputWrapper>
              <Label>Description</Label>
              <Description>
                {ProjectData?.get_Project?.projectDescription}
              </Description>
            </InputWrapper>
          </div>
          <div>
            <ClientWrapper>
              <Label>Client</Label>
              <ClientSeparator>
                <CustomAvatar
                  name={ProjectData?.get_Project?.client}
                  size={20}
                />
              </ClientSeparator>
            </ClientWrapper>
            <AssigneeWrapper>
              <Label>Created By</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar
                    name={getFullNameByUserName(ProjectData?.get_Project?.createdBy, allUsers)}
                    size={21}
                  />
                </AvatarStyles>
                <Small>
                  {new Date(
                    ProjectData?.get_Project?.createdAt
                  ).toLocaleDateString("en-US")}
                </Small>
              </AssigneeSeparator>
            </AssigneeWrapper>
          </div>
        </FieldSet>
      </Container> */}
      <Header>
        <TitleLeft>
          <PageTitle>Project Workspaces</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Link to="archived">
            <StyledArchiveIcon />
          </Link>
          <Button onClick={handleClick}>+ New Workspace</Button>
          {currentUserRole === 'SUPER_ADMIN' ? (
            <Button onClick={() => navigate(`/project/${projectId}/tasks`)} secondary={true}>
              <TaskIconSmall /> &nbsp; View All Tasks
            </Button>
          ) : (
            ''
          )}
        </TitleRight>
      </Header>
      <div>
        {belongsToWorkspaces ? (
          !_.isEmpty(WorkspaceData) ? (
            <ProjectWorkspaceList
              data={Object.values(WorkspaceData).filter(
                (workspace) => workspace.recordStatus === null || workspace.recordStatus === 0
              )}
              projectid={projectId}
              icon={<WorkspaceIcon />}
            />
          ) : (
            <Lottie options={defaultOptions} width={400} height={500} />
          )
        ) : (
          <NoWorkspacesAdded>You are not part of any workspaces inside this project.</NoWorkspacesAdded>
        )}
      </div>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="1115px">
          <ModalTitle>Workspace Template</ModalTitle>
          <ModalBody>
            <PageTitle>Platform Suite Workspaces</PageTitle>
            <TemplateContainer>
              {globalTemplates.length > 0 &&
                globalTemplates.map((template) => {
                  return (
                    <WorkspaceTemplateTile
                      key={template._id}
                      selected={selectedTemplateIndex}
                      template={template}
                      handleSelect={handleSelectedTemplate}
                      setSelectedTemplateData={setSelectedTemplateData}
                    >
                      {template.templateName}
                    </WorkspaceTemplateTile>
                  );
                })}
            </TemplateContainer>
            <PageTitle>Saved Workspaces</PageTitle>
            <TemplateContainer>
              {orgTemplates.length > 0 &&
                orgTemplates.map((template) => {
                  return (
                    <WorkspaceTemplateTile
                      key={template._id}
                      selected={selectedTemplateIndex}
                      template={template}
                      handleSelect={handleSelectedTemplate}
                      setSelectedTemplateData={setSelectedTemplateData}
                    >
                      {template.templateName}
                    </WorkspaceTemplateTile>
                  );
                })}
            </TemplateContainer>
            <ButtonWrapper>
              <Button onClick={() => toggle()} secondary>
                Cancel
              </Button>
              <Button onClick={() => goToWorkspace()}>Confirm</Button>
            </ButtonWrapper>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ProjectView;
