import * as yup from "yup";

const CreateWorkspaceValidationSchema = yup.object().shape({
  workspaceName: yup
    .string()
    .min(2, "Workspace name is short")
    .max(30, "Workspace name is too long")
    .required("Please enter a workspace name")
    .matches(/^(?!\s+$).*/, 'This field cannot contain only blankspaces'),
  description: yup
    .string()
    .min(2, "Description is too short")
    .max(10000, "Description has a character limit of 10,000")
    .required("Please give a description for your workspace")
    .matches(/^(?!\s+$).*/, 'This field cannot contain only blankspaces'),

});

export default CreateWorkspaceValidationSchema;
