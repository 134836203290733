import React from "react";
import styled from "@emotion/styled";
// import { useNavigate } from "react-router-dom";
// import { ReactComponent as FileIcon } from '../assets/icons/fileIcon.svg'
import FileTag from "./FileTag";
import { getFileType } from "../lib/getFileType";
import { getDate } from "../lib/getDate";
import { getTime } from "../lib/getTime";

export const DocumentItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  width: 70rem;
  .doc-info {
    display: flex;
    /* justify-content: space-evenly; */
    width: 50%;
    align-items: center;
    margin-left: 40px;   
    .doc-name {
      display: flex;
     gap:20px;
    flex-basis: 75%;
      align-items: center;
      text-align: left;
    }
    .links-data {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 25rem;
    }
  }
  .doc-date {
    display: flex;
    flex-basis: 25%;
    justify-content: space-around;
    color: var(--text-light);
  }
  &:after {
    content: "";
    background: var(--table-border);
    position: absolute;
    height: 1px;
    width: 85vw;
    top: 53px;
  }

  // checkbox styles
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 20px;
  }

  /* Hide the default checkbox */
  .container input {
    visibility: hidden;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border: 1px solid #26303e;
    border-radius: 4px;
    margin-top: 3px;
  }

  .container:hover input ~ .mark {
    background-color: #ffffff;
    border: 1px solid #26303e;
    border-radius: 4px;
  }

  .container input:checked ~ .mark {
    background-color: #ffffff;
    border: 1px solid #26303e;
    border-radius: 4px;
  }

  /* Create the mark/indicator (hidden when not checked) */
  .mark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the mark when checked */
  .container input:checked ~ .mark:after {
    display: block;
  }

  /* Style the mark/indicator */
  .container .mark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 12px;
    border: solid #26303e;
    border-radius: 4px;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

export default function CollectionFilesItem({
  data,
  icons,
  checkedFiles,
  setCheckedFiles,
  checked,
}: {
  data: any;
  icons: any;
  checkedFiles: any;
  setCheckedFiles: any;
  checked: boolean;
}) {
  // let navigate = useNavigate();
  const selectFile = () => {
    const FindData = checkedFiles?.find((list) => list === data?._id);
    if (FindData) {
      const removeData = checkedFiles.filter((list) => list !== FindData);
      setCheckedFiles(removeData);
    } else {
      setCheckedFiles([...checkedFiles, data?._id]);
    }
  };
  return (
    <>
      <DocumentItemStyles>
        <div className="doc-info">
          <label className="container">
            <input
              type="checkbox"
              name="checked"
              value={data?._id}
              onChange={selectFile}
              checked={checked}
            />
            <span className="mark"></span>
          </label>
          <div className="doc-name">
            {icons}
            <p>{data?.name}</p>
          </div>
          {data.fileType ? (
            <div>
              <FileTag>{getFileType(data?.fileName)?.toUpperCase()}</FileTag>
            </div>
          ) : (<div style={{ minWidth: "3.75rem" }}></div>)
          }
        </div>
        <div className="doc-date">
          <p>{getDate(data.updatedAt ? data?.updatedAt : data?.createdAt)}</p>
          <p>{getTime(data.updatedAt ? data?.updatedAt : data?.createdAt)}</p>
        </div>

      </DocumentItemStyles>
    </>
  );
}
