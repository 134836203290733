import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import AvatarTags from '../../../../components/AvatarTags';
import LinkDisplay from '../../../../components/LinkDisplay';
import { ErrorPage } from '../../../Others/ErrorPage';
import { DateText } from '../../Registers/CreateNewDynamicDocumentInRegister';
import { TaskDetails } from '../../../Tasks/TaskViews/CreateTask';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ChoicesWrapper,
  DateWrapper,
  FieldSet,
  InputWrapper
} from '../../../../components/styles/FormStyles';
import { Label } from '../../../../components/styles/Label';
import { Text } from '../../../../components/styles/Text';
import { DescriptionText } from '../../../Library/FilesView/Files/ViewFile/FileInformation/AboutFile';
import { Small } from '../../../../components/styles/SmallText';
import { AssignedList, AssignedToWrapper } from '../../../Tasks/TaskViews/ViewTask/TaskInformation/AboutTask';
import Select from 'react-select';
import { editTaskOptions, priorityOptions } from '../../../../constants/DropDownOptions';
import { getValue } from '../../../../components/Tasks';
import { statusStyles } from '../../../../components/styles/StatusStyles';
import { getDate } from '../../../../lib/getDate';
import useGetWorkspaceTask from '../../../../hooks/features/workspaces/useGetWorkspaceTask';
import Loader from '../../../../components/Loader';
// const Container = styled.div`
//   padding-left: 1.625rem;
//   padding-top: 2.68rem;
//   display: flex;
//   flex-direction: row;
//   gap: 40px;
//   .row:first-of-type{
//     /* border: 1px solid blue; */
//     width: 60vw;
//     .box{
//       flex-direction: column;
//       width: 100%;
//     }
//   }
//   .box{
//     display: flex;
//     align-items: center;
//     align-items: baseline;
//     h4{
//       font-weight: 600;
//       color: var(--black);
//       margin-bottom: 0px;
//     }
//     /* p{
//       display: flex;
//       justify-content: flex-start;
//       font-weight: 400;
//       color: var(--black);
//       /* padding-left: 1.9rem; */
//       align-items: baseline;
//       margin-bottom: 0px;
//     } */
//     small{
//       /* padding-left: 4rem; */
//       color: var(--text-primary);
//     }
//     .link-group{
//       margin-top: 10px;
//       display: flex;
//       flex-direction: column;
//       gap: 10px;
//       width: 70%;
//       p{
//         margin: 0px;
//       }
//     }
//   }
//   .row:last-of-type{
//     /* border: 1px solid red; */
//     .box{
//       gap: 20px;
//       margin-bottom: 20px;
//     }
//   }
//   .assignedto{
//     display: flex;
//     /* justify-content: flex-start; */
//     flex-direction: column;
//     .assignedlist{
//       display: flex;
//       flex-direction: column;

//     }
//   }
// `;

function AboutWorkspaceTask() {
  const {
    loading,
    data,
    error,
    handlePriorityChange,
    handleStatusChange,
    projectData,
    projectError,
    projectLoading,
    workspaceData,
    workspaceError,
    workspaceLoading
  } = useGetWorkspaceTask();
  if (loading || projectLoading || workspaceLoading) return <Loader />;
  if (error || projectError || workspaceError) return <ErrorPage />;
  const { get_Task } = data;
  return (
    <TaskDetails>
      <FieldSet>
        <div className="row">
          <InputWrapper>
            <Label>Project Name</Label>
            <Text>{projectData?.get_Project?.name}</Text>
          </InputWrapper>
          <InputWrapper>
            <Label>Workspace Name</Label>
            <Text>{workspaceData?.get_Workspace?.name}</Text>
          </InputWrapper>
          <InputWrapper>
            <Label>Description</Label>
            <DescriptionText>{get_Task.description}</DescriptionText>
          </InputWrapper>
          {/* <div className="box"> */}
          {get_Task.links.length > 0 && (
            <InputWrapper>
              <Label>Links</Label>
              {get_Task.links?.map((link: string, index: number) => {
                return <LinkDisplay key={index} link={link} />;
              })}
            </InputWrapper>
          )}
        </div>
        <div className="row">
          <ChoicesWrapper>
            <Label>Status</Label>
            <Select
              name="status"
              options={editTaskOptions}
              defaultValue={getValue(editTaskOptions, get_Task?.status)}
              styles={statusStyles}
              isSearchable={false}
              onChange={(status: any) => {
                if (status) {
                  handleStatusChange(status.value, get_Task._id);
                }
              }}
            />
            <Label>Priority</Label>
            <Select
              name="priority"
              options={priorityOptions}
              defaultValue={getValue(priorityOptions, get_Task.priority)}
              styles={statusStyles}
              isSearchable={false}
              onChange={(priority) => {
                if (priority) {
                  handlePriorityChange(priority.value, get_Task._id, get_Task.type, get_Task.typeId);
                }
              }}
            />
          </ChoicesWrapper>
          <DateWrapper>
            <Label>Due Date</Label>
            <DateText>{getDate(get_Task.dueDate)}</DateText>
            <CalendarIconPlacer></CalendarIconPlacer>
          </DateWrapper>
          <AssigneeWrapper>
            <Label>Reporter</Label>
            <AssigneeSeparator>
              <AvatarStyles>
                <CustomAvatar name={get_Task?.assigneeDetails?.name} size={20} />
              </AvatarStyles>
              <Small>{getDate(get_Task.createdAt)} </Small>
            </AssigneeSeparator>
          </AssigneeWrapper>
          <AssignedToWrapper>
            <Label>Assigned To</Label>
            {get_Task.assignedToDetails ? (
              <AssignedList>
                <AvatarTags name={get_Task.assignedToDetails.name} />
              </AssignedList>
            ) : null}
          </AssignedToWrapper>
        </div>
      </FieldSet>
    </TaskDetails>
  );
}

export default AboutWorkspaceTask;
