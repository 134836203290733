import * as yup from 'yup';

// const regex_OnlyNumber = /^[^a-zA-Z]*$/;
const regex_WithHyphens = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const ProjectAddressValidationSchema = yup.object().shape({
  name: yup.string().min(2, 'Name is too short.').required('Please enter a name.'),
  phone: yup
    .string()
    .matches(regex_WithHyphens, { message: 'Please enter a valid phone number' })
    .min(10, 'Please enter a valid phone number')
    .max(13, 'Please enter a valid phone number'),
  emailId: yup.string().email('Please enter a valid email'),
  address: yup
    .string()
    .required('Please enter the address')
    .min(2, 'Address is too short.')
    .max(500, 'Address cannot be longer than 500 characters.')
});

export default ProjectAddressValidationSchema;
