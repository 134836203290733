import toast from 'react-hot-toast';
import axiosInstance from '../axiosInstance';
import { sentryError } from './SentryError';

export const getWorkspaceUsers = async (workspaceId: string, userId: string) => {
  let toastId = toast.loading('Loading Users');
  return axiosInstance
    .get(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`)
    .then((response) => {
      if (response.data.data) {
        if (response.data.data.length > 0) {
          let fullDetails = response.data.data.map((user) => {
            return {
              id: user.userId,
              email: user.email,
              value: user.fullName,
              label: user.userId === userId ? `Myself (${user.fullName})` : user.fullName
            };
          });
          toast.dismiss(toastId);
          return fullDetails;
        }
      }
      toast.dismiss(toastId);
      return [];
    })
    .catch((error) => {
      sentryError(error);
      toast.error('Error loading users of the selected workspace');
      toast.dismiss(toastId);
      return [];
    });
};
