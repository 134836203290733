import { useState } from 'react';
import { SeatType } from '../../../pages/Organization/ViewOrganization';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { Flex, InputRadio, ModalLabel, Wrapper } from './ChoosePlanModal';
import { Seating } from './ChooseSeatTypeModal';

type AProps = {
  isOpen: boolean;
  onClose: () => void;
  handleAssignSeatForAll: (plan: SeatType) => Promise<void>;
  isInSufficient: boolean;
  updateSeats: () => void;
  buttonState: boolean;
  stripeLoading: boolean;
  monthlySeats: number;
  yearlySeats: number;
  currentUsers: any;
  subscriptionType: string;
};

function AssignSeatsForAllModal(props: AProps) {
  const {
    isOpen,
    buttonState,
    handleAssignSeatForAll,
    isInSufficient,
    onClose,
    stripeLoading,
    updateSeats,
    currentUsers,
    monthlySeats,
    subscriptionType,
    yearlySeats
  } = props;
  const [plan, setActivePlan] = useState<SeatType>(subscriptionType === 'PAID' ? 'MONTHLY' : 'TRIAL');
  const onClick = (type: 'MONTHLY' | 'YEARLY' | 'TRIAL') => {
    setActivePlan(type);
  };

  let availableYearlySeats = yearlySeats - currentUsers.filter((user) => user?.seatType === 'YEARLY').length;
  let availableMonthlySeats = monthlySeats - currentUsers.filter((user) => user?.seatType === 'MONTHLY').length;
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer width="638px">
        {!isInSufficient ? (
          <>
            <ModalTitle>Choose the seat type</ModalTitle>
            <ModalBody>
              <ModalText>Please choose preferred seat type</ModalText>
              <Flex margin="2rem 0 2rem ">
                {subscriptionType === 'PAID' && (
                  <>
                    <Wrapper active={plan === 'MONTHLY'} onClick={() => onClick('MONTHLY')}>
                      <InputRadio active={plan === 'MONTHLY'} />
                      <ModalLabel htmlFor="month">
                        <Seating period="Monthly" quantity={availableMonthlySeats} />
                      </ModalLabel>
                    </Wrapper>
                    <Wrapper active={plan === 'YEARLY'} onClick={() => onClick('YEARLY')}>
                      <InputRadio active={plan === 'YEARLY'} />
                      <ModalLabel htmlFor="year">
                        {' '}
                        <Seating period="Yearly" quantity={availableYearlySeats} />
                      </ModalLabel>
                    </Wrapper>
                  </>
                )}
                {(subscriptionType === 'TRIAL' || subscriptionType === 'FREE') && (
                  <Wrapper active={plan === 'TRIAL'} onClick={() => onClick('TRIAL')}>
                    <InputRadio active={plan === 'TRIAL'} />
                    <ModalLabel htmlFor="month">
                      <Seating period="Trial" quantity="TRIAL SEATS" />
                    </ModalLabel>
                  </Wrapper>
                )}
              </Flex>
            </ModalBody>
            <ModalWrapper>
              <Button disabled={buttonState} onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={buttonState} onClick={() => handleAssignSeatForAll(plan)}>
                Assign Seat
              </Button>
            </ModalWrapper>
          </>
        ) : (
          <>
            <ModalTitle>Available seats insufficient</ModalTitle>
            <ModalBody>
              <ModalText>
                You dont have sufficient seats available in your organization. Please increase the number of seats for
                your organization.
              </ModalText>
            </ModalBody>
            <ModalWrapper>
              <Button disabled={stripeLoading} secondary onClick={onClose}>
                Cancel
              </Button>
              <Button disabled={stripeLoading} onClick={updateSeats}>
                Upgrade
              </Button>
            </ModalWrapper>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
}

export default AssignSeatsForAllModal;
