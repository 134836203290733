import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AppContext } from '../../../AppContext';
import { ReactComponent as SearchIcon } from '../../../assets/icons/IconSearchShat.svg';
import { ReactComponent as NoUsersIllustration } from '../../../assets/illustration/noOrgChat.svg';
import useChatSocket from '../../../hooks/features/chat/useChatSocket';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Checkbox from '../../Checkbox';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { Member } from '../types';
import { AddMembers, IllustrationDiv, SearchInput } from './CreateGroupModal/SelectMembersModal';

interface Props {
  toggleModal: () => void;
  isOpen: boolean;
  users: any;
  selectedIds: string[];
  members: Member[];
  groupName: string;
  isOwner: boolean;
}

const UpdateMembersModal = ({ groupName, toggleModal, isOpen, users, selectedIds, members, isOwner }: Props) => {
  const { state } = useContext(AppContext);
  const [selected, setSelected] = useState<string[]>([]);
  const { updateGroup } = useChatSocket();

  const [userDetails, setUserDetails] = useState(users);

  useEffect(() => {
    setSelected(selectedIds);
  }, [selectedIds]);

  const handleSearchChange = (e: any) => {
    let enteredText = e.target.value;
    let filteredUsers = users.filter((user: any) =>
      user?.name?.toLowerCase().includes(enteredText?.toLowerCase() || '')
    );
    setUserDetails(filteredUsers);
  };

  const handleOnChange = (userId: string) => {
    if (selected.includes(userId)) {
      setSelected(selected.filter((id) => id !== userId));
    } else {
      setSelected([...selected, userId]);
    }
  };

  const handleCreateGroup = async () => {
    if (selected.length === 0) {
      toast.error('Please select atleast one user');
      return;
    }

    const removed = _.difference(selectedIds, selected);

    const added = _.difference(selected, selectedIds);

    const selectedMembers = members.filter((member) => selected.includes(member.id));

    updateGroup(groupName, selectedMembers, removed, added);

    toast.success('Group updated successfully');
    toggleModal();
  };

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="400px" style={{ minHeight: '350px' }}>
        <ModalTitle>Group Members</ModalTitle>
        <ModalBody>
          <ModalText>{isOwner ? 'Update members in the group' : 'All group members'}</ModalText>
          <AddMembers>
            <SearchInput>
              <SearchIcon className="icon" />
              <input onChange={(e) => handleSearchChange(e)} type="text" placeholder="Search Members" />
            </SearchInput>
            <div className="members">
              {userDetails.length === 0 ? (
                <IllustrationDiv>
                  <NoUsersIllustration />
                  <span>No users found</span>
                </IllustrationDiv>
              ) : (
                userDetails?.map((item: any) =>
                  item.id !== state?.userId ? (
                    <label key={item.id}>
                      <Checkbox
                        checked={selected.includes(item.id)}
                        onChange={() => handleOnChange(item.id)}
                        disabled={!isOwner}
                      />
                      <p className="chatItemDP">
                        {item.picture ? <img src={item.picture} alt="Chat Dp" /> : <span>{item.name.charAt(0)}</span>}
                      </p>
                      <p>{item.name}</p>
                    </label>
                  ) : null
                )
              )}
            </div>
          </AddMembers>
        </ModalBody>
        <ModalWrapper>
          {isOwner ? (
            <>
              <Button onClick={toggleModal} secondary>
                Cancel
              </Button>
              <Button onClick={handleCreateGroup}>Update Group</Button>
            </>
          ) : (
            <Button onClick={toggleModal} secondary>
              Close
            </Button>
          )}
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default UpdateMembersModal;
