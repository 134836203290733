import * as yup from 'yup';

const CreateWorkspaceTaskValidationSchema = yup.object().shape({
  description: yup.string().min(2, 'Description is too Short!.'),
  title: yup.string().min(2, 'Title is too Short!.').max(500, 'Title is too Long!.').required('Title is Required.'),
  dueDate: yup.date().required('Date is Required.').nullable(),
  links: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .url('Please enter correct url.')
        .required('A link cannot be empty.Please remove if link is not required.'),
      saved: yup.bool().oneOf([true], 'Please save this field before submission.')
    })
  ),
  assignedTo: yup.string().required('Please assign a task to a member.')
});

export default CreateWorkspaceTaskValidationSchema;
