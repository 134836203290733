import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import { Input } from "../../styles/Inputs";
import { Label } from "../../styles/Label";
import TextError from "./TextError";

export const InputField = (props: any) => {
  const { label, name, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <>
          <Label htmlFor={name}>{label}</Label>
          <Input
            id={name}
            {...rest}
            {...field}
            error={form.touched[name] && form.errors[name]}
          ></Input>
          {form.errors[name] && form.touched[name] ? (
            <ErrorMessage component={TextError} name={name} />
          ) : null}
        </>
      )}
    </Field>
  );
};

export default InputField;
