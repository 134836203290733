import styled from "@emotion/styled";
import ProjectWorkspaceItem from "./ProjectWorkspaceItem";
import { ReactComponent as WorkspaceIcon } from "../assets/icons/WorkspaceIcon.svg";
import React from "react";
import { NoWorkspacesAdded } from "../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView";
import { getDate } from "../lib/getDate";
import { getTime } from "../lib/getTime";


const WorkspaceInfo = styled.div`
  display: flex;
  padding-left: 2.6rem;
  align-items: center;
`;
const WorkspaceName = styled.p`
  font-weight: 600;
  margin-left: 2.01rem;
`;

const WorkspaceDate = styled.div`
  color: var(--text-light);
  display: flex;
  align-items: center;
  padding-right: 7.6rem;
`;
const DateStyle = styled.p`
  margin-right: 20px;
`;
const Time = styled.p``;

function ProjectWorkspaceList({
  data,
  icon,
  projectid,
}: {
  data: any;
  icon: any;
  projectid: any;
}) {
  return (
    <div>

      {data?.length > 0 ? data?.map((item, index) => {
        return (
          <>
            <ProjectWorkspaceItem
              key={item._id}
              item={item}
              projectid={projectid}
            >
              <WorkspaceInfo>
                <WorkspaceIcon />
                <WorkspaceName>{item.name}</WorkspaceName>
              </WorkspaceInfo>
              <WorkspaceDate>
                <DateStyle>
                  {getDate(item?.createdAt)}
                </DateStyle>
                <Time>
                  {getTime(item?.createdAt)}
                </Time>
              </WorkspaceDate>
            </ProjectWorkspaceItem>
          </>
        );
      }) : data.length === 0 &&
      (
        <NoWorkspacesAdded>
          You are not part of any workspaces inside this project.
        </NoWorkspacesAdded>
      )
      }
    </div>
  );
}

export default ProjectWorkspaceList;
