import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';

const DeleteNoteModal = ({
  isOpen,
  toggleModal,
  handleDeleteNote
}: {
  isOpen: boolean;
  toggleModal: () => void;
  handleDeleteNote: () => void;
}) => {
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Delete Note</ModalTitle>
        <ModalBody>
          <ModalText>Are you sure you want to delete this note?</ModalText>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={false} onClick={handleDeleteNote}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteNoteModal;
