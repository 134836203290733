import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import FileTag from './FileTag';
import { getFileType } from '../lib/getFileType';
import { getDate } from '../lib/getDate';
import { getTime } from '../lib/getTime';

export const DocumentItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  width: 70rem;
  .doc-info {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    align-items: center;
    .doc-name {
      display: flex;
      gap: 20px;
      flex-basis: 55%;
      align-items: center;
      text-align: left;
    }
    .links-data {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 25rem;
    }
  }
  .doc-date {
    display: flex;
    flex-basis: 25%;
    justify-content: space-around;
    color: var(--text-light);
  }
  &:after {
    content: '';
    background: var(--table-border);
    position: absolute;
    height: 1px;
    width: 85vw;
    top: 53px;
  }
`;

export default function DocumentItem({ data, icons, navlink }: { data: any; icons: any; navlink: any }) {
  let navigate = useNavigate();
  const viewItem = () => {
    navigate(`${navlink}${data._id}`);
  };
  return (
    <DocumentItemStyles onClick={viewItem}>
      <div className="doc-info">
        <div className="doc-name">
          {icons}
          <p>{data?.name}</p>
        </div>
        {data?.fileType ? (
          <div>
            <FileTag>{getFileType(data?.fileName)?.toUpperCase()}</FileTag>
          </div>
        ) : (
          <div style={{ minWidth: '3.75rem' }}></div>
        )}
      </div>
      <div className="doc-date">
        <p>{data.updatedAt ? getDate(data.updatedAt) : data.createdAt ? getDate(data?.createdAt) : getDate()}</p>
        <p>{data.createdAt ? getTime(data?.createdAt) : null}</p>
      </div>
    </DocumentItemStyles>
  );
}
