import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSidebar } from '../lib/SidebarState';

interface NavProps {
  icon?: React.ReactNode;
  name: string;
  route: string;
  children?: any;
  add?: React.ReactNode;
  drop?: React.ReactNode;
}

const NavItem = (props: NavProps) => {
  const [open, setOpen] = useState(false);
  const { sidebarOpen } = useSidebar();
  // let active = 'active'
  return (
    <li className="nav-item">
      {props.name === 'My Dashboard' ? (
        <NavLink
          end
          to={props.route}
          className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          onClick={() => setOpen(!open)}
        >
          {props.icon}
          <span className="link-text">{props.name}</span>
          {/* <Link to={`new${props.route}`}>  */}
          {/*should fix this as it will render a inside of a*/}
          <span className="add-project-icon">{props.add}</span>
          {/* </Link> */}
          <span onClick={() => setOpen(!open)} className="dropdown-icon">
            {props.drop}
          </span>
        </NavLink>
      ) : (
        <NavLink
          to={props.route}
          className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
          onClick={() => setOpen(!open)}
        >
          {props.icon}
          <span className="link-text">{props.name}</span>
          {/* <Link to={`new${props.route}`}> */}
          <span className="add-project-icon">{props.add}</span>
          {/* </Link> */}
          <span onClick={() => setOpen(!open)} className="dropdown-icon">
            {props.drop}
          </span>
        </NavLink>
      )}

      {open && sidebarOpen && props.children}
    </li>
  );
};

export default NavItem;
