import styled from '@emotion/styled';
import { Editor } from '@tiptap/react';
import { useState } from 'react';
import { EditingTable, SectionI } from '../../pages/MeetingNotes/types';
import Section from './Section';
import Toolbar from './Toolbar';

const EditorsContainer = styled.div`
  background-color: #f6f5f5;
  height: 100vh;
  /* padding: 30px 0px 0px 0px; */
`;

interface Props {
  editable?: boolean;
  handleCreateTask: (title: string, blockId: string) => void;
  editorType: 'MEETING_NOTE' | 'PROJECT_NOTE' | 'TASK_NOTE';
  sections: SectionI[];
  editingTable: EditingTable;
  noteData: any;
}

const EditorComponent = ({
  handleCreateTask,
  editorType,
  sections,
  editingTable,
  noteData,
  editable = true
}: Props) => {
  // @ts-ignore
  // const { sections } = useSelector((state) => state.meetingNote);
  const [currentEditor, setCurrentEditor] = useState<Editor | null>(null);

  return (
    <EditorsContainer>
      {editable && <Toolbar editor={currentEditor} editorType={editorType} />}
      {sections.map((section: SectionI, i: number) => (
        <Section
          section={section}
          key={i}
          editable={editable}
          handleCreateTask={handleCreateTask}
          setCurrentEditor={setCurrentEditor}
          editorType={editorType}
          editingTable={editingTable}
          noteData={noteData}
          sectionNumber={i}
          noOfSections={sections.length}
        />
      ))}
    </EditorsContainer>
  );
};

export default EditorComponent;
