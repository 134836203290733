import React from 'react'
import { AboutText } from '../../pages/Projects/AboutProject/ProjectInformation/ProjectDetailsView'
import { CardStyles, EmptyDataIllustration } from './Card'
import { ReactComponent as AddAddress } from '../../assets/illustration/AddAddress.svg'
function EmptyCard({ type, handleClick }) {
  let text = ''
  switch (type) {
    case 'address':
      text = 'Add a Project Address'
      break;
    case 'contact':
      text = 'Add Contact'
      break;
    case 'team':
      text = 'Add Team'
      break;

  }
  return (
    <CardStyles>
      <EmptyDataIllustration onClick={() => handleClick()}>
        <AddAddress />
        <AboutText>{text}</AboutText>
      </EmptyDataIllustration>
    </CardStyles>
  )
}

export default EmptyCard