import { gql } from '@apollo/client';
// recordStatus=0

export const createMeetingNotesMutation = gql`
  mutation exampleMutation(
    $createdAt: String
    $workspaceId: String
    $createdBy: String
    $metadata: String
    $noteType: String
    $noteType_typeId: String
    $organizationId: String
    $organizationId_projectId_workspaceId: String
    $otherFields: String
    $projectId: String
    $recordStatus: Float
    $title: String
    $typeId: String
    $isDraft: Boolean
    $updateBy: String
    $updatedAt: String
  ) @api(name: "workspace") {
    add_NoteAndDisscussion(
      input: {
        workspaceId: $workspaceId
        recordStatus: $recordStatus
        projectId: $projectId
        organizationId_projectId_workspaceId: $organizationId_projectId_workspaceId
        organizationId: $organizationId
        noteType_typeId: $noteType_typeId
        noteType: $noteType
        title: $title
        typeId: $typeId
        createdAt: $createdAt
        createdBy: $createdBy
        metadata: $metadata
        otherFields: $otherFields
        updateBy: $updateBy
        updatedAt: $updatedAt
        isDraft: $isDraft
      }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

// user create new block
//update blockOrder for same section Id for all blocks
export const updateBlockOrderMutation = gql`
  mutation exampleMutation($id: ID!, $blockOrder: [String]) {
    update_Content(id: $id, input: { blockOrder: $blockOrder }, syncMode: NODE_COMMITTED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const addContentMutation = gql`
  mutation exampleMutation(
    $blockContent: String
    $blockDate: String
    $blockId: String
    $blockNumber: String
    $blockOrder: [String]
    $blockStatus: Float
    $createdAt: String
    $createdBy: String
    $noteId: String
    $sectionHeader: String
    $sectionId: String
    $sectionOrder: [String]
    $sectionStatus: Float
    $updateBy: String
    $updatedAt: String
  ) {
    add_Content(
      input: {
        blockContent: $blockContent
        blockDate: $blockDate
        blockId: $blockId
        blockNumber: $blockNumber
        blockStatus: $blockStatus
        blockOrder: $blockOrder
        createdAt: $createdAt
        createdBy: $createdBy
        noteId: $noteId
        sectionHeader: $sectionHeader
        sectionId: $sectionId
        sectionOrder: $sectionOrder
        updateBy: $updateBy
        updatedAt: $updatedAt
        sectionStatus: $sectionStatus
      }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

// user creates new section
//update sectionder  for all contents of same noteId

export const updateSectionOrder = gql`
  mutation exampleMutation($id: ID!, $sectionOrder: [String]) {
    update_Content(id: $id, input: { sectionOrder: $sectionOrder }, syncMode: NODE_COMMITTED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
