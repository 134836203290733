export const statusStyles = {
  control: (base, { isDisabled }) => ({
    ...base,
    width: "8.125rem",
    // height: '1.5rem',
    border: 0,
    boxShadow: "none",
    cursor: isDisabled ? "not-allowed" : "default",
    background: isDisabled ? "transparent" : "transparent",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? "none" : "default",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontWeight: "400",
    overflow: "visible !important",
    // height: '1.2rem'
  }),
  option: (styles, { data, isFocused }) => ({
    ...styles,
    color: isFocused ? "white" : "#1C1C1C",
    background: isFocused ? "#2F3F48" : "white",
    height: "30px",
  }),
  menu: (styles, { data }) => ({
    ...styles,
    position: "absolute",
    border: "1px solid #ACADAC",
    borderRadius: "5px",
    top: "-10px",
  }),
};

export const tableStatusStyles = {
  control: (base) => ({
    // ...base,
    width: "8.125rem",
    // border: '1px solid black',
    boxShadow: "none",
    height: "22px",
    padding: "0px",
  }),
  valueContainer: () => ({
    padding: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.color,
    fontWeight: "400",
    overflow: "visible !important",
    background: data.backgroundColor,
    borderRadius: "5px",
    width: "5.875rem",
    textAlign: "center",
    fontSize: "14px",
    // height: '1.2rem'
  }),
  option: (styles, { data, isFocused }) => ({
    ...styles,
    color: isFocused ? "white" : "#1C1C1C",
    background: isFocused ? "#2F3F48" : "white",
    height: "30px",
  }),
  menu: (styles, { data }) => ({
    ...styles,
    position: "absolute",
    border: "1px solid #ACADAC",
    borderRadius: "5px",
    top: "-10px",
  }),
};
