import DocumentItem from './DocumentItem';

export default function DocumentList({ data, icons, navlink }: { data: any; icons: any; navlink: any }) {
  return (
    <div>
      {data?.map((file, index) => {
        return <DocumentItem key={index} data={file} icons={icons} navlink={navlink} />;
      })}
    </div>
  );
}
