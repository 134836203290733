import styled from '@emotion/styled';
import Button, { CustomButton } from '../../../../components/CustomButton';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { Label } from '../../../../components/styles/Label';
import { FieldSet, InputWrapper } from '../../../../components/styles/FormStyles';
import InputField from '../../../../components/Reusable/Formik/Input';
import CollectionFilesList from '../../../../components/CollectionFilesList';
import { ReactComponent as FileIcon } from '../../../../assets/icons/NewFileIcon.svg';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { ErrorPage } from '../../../Others';
import Loader from '../../../../components/Loader';
import useEditCollection from '../../../../hooks/features/library/useEditCollection';

const Form = styled.form`
  .input-width {
    width: 60%;
  }
`;
const CollectionDetails = styled.div`
  margin-left: 3.2%;
`;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #dbe2e0;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
  }
`;

const EditCollection = () => {
  const { documentData, error, formik, isSubmitting, loading, collectionError, collectionLoading, CollectionData } =
    useEditCollection();

  if (error || collectionError) return <ErrorPage />;
  if (loading || collectionLoading) return <Loader />;

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Edit Collection</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button type="submit" disabled={isSubmitting}>
              Save Collection
            </Button>
          </TitleRight>
        </Header>
        <CollectionDetails>
          <FieldSet>
            <div className="input-width">
              <InputWrapper>
                <Label>Collection Name</Label>
                <InputField disabled={isSubmitting} name="name" type="text" formik={formik} />
              </InputWrapper>
            </div>
          </FieldSet>
          <div>
            <CollectionFilesList
              data={documentData?.list_FileItems?._FileItems}
              icons={<FileIcon />}
              files={CollectionData?.get_Collection?.files}
              checkedFiles={formik.values.checkedFiles}
              setCheckedFiles={(file) => formik.setFieldValue('checkedFiles', file)}
            />
          </div>
        </CollectionDetails>
      </Form>
    </>
  );
};

export default EditCollection;
