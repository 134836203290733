import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import Button from '../../../../components/CustomButton';
import TableDropDown from '../../../../components/TableComponents/TableDropDown';
import DocumentList from '../../../../components/DocumentList';
import { Header, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import { useQuery } from '@apollo/client';
import { ReactComponent as FileIcon } from '../../../../assets/icons/NewFileIcon.svg';
import { ReactComponent as CollectionIcon } from '../../../../assets/icons/Folder.svg';
import { useSidebar } from '../../../../lib/SidebarState';
import { AppStyleProps } from '../../../../App';
import Select from 'react-select';
import { FileFilterOptions } from '../../../../constants/FilesFilterOptions';
import { FilesFilterStyles } from '../../../../components/styles/FilesFilterStyles';
import { ErrorPage } from '../../../Others/ErrorPage';
import {
  LIST_COLLECTION_QUERY,
  LIST_DRAFT_FILES_QUERY,
  LIST_FILES_QUERY
} from '../../../../graphql/operations/Queries/Library/FilesQueries';
import { GreyButton } from '../../../../components/styles/ButtonStyles';
import Loader from '../../../../components/Loader';
import { EmptyDataContainer } from '../../../Projects/ProjectViews/Projects';
import getSortDate from '../../../../lib/getSortDate';
// import {
//   LIST_FILES_QUERY,
//   LIST_DRAFT_FILES_QUERY,
//   LIST_COLLECTION_QUERY,
// } from "";

// import { ProjectDropdownStyles } from "../../../components/styles/ProjectDropdownStyles";

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;
export const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  .files-dropdown {
    margin-right: 3.9rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    /* margin: 0px; */
  }
`;

// const FilesFilterDiv = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

const DocumentSection = styled.div<AppStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1.3rem;
  margin-left: 1.5rem;
  max-width: 80vw;
  .drop-item {
    margin-top: 10px;
    margin-bottom: 20px;
    /* border: 1px solid green; */
  }
  .collectionButton {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
  }
  .collection-button {
    /* border: 1px solid blue; */
    margin-right: 3rem;
    align-self: flex-start;
    /* margin-right: ${(props) => (props.isOpen ? '10rem' : '7rem')} */
  }
  /* .files{
    display: flex;
    
  } */
`;

const CollectionBtn = styled.span({
  Button: {
    position: 'relative',
    background: '#DBE2E0',
    color: '#26303E',
    right: '9rem',
    whiteSpace: 'nowrap'
  }
});

const Files = () => {
  let navigate = useNavigate();
  const { sidebarOpen } = useSidebar();
  const { state } = useContext(AppContext);
  const [filterType, setFilterType] = useState('all');
  const userId = state.userId;
  const {
    data: documentData,
    error,
    loading,
    refetch
  } = useQuery(LIST_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${userId}`,
      recordStatus: 0
    }
  });
  const { data: draftData, refetch: draftRefetch } = useQuery(LIST_DRAFT_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${userId}`,
      recordStatus: 0
    }
  });
  const {
    data: ListCollection,
    error: collectionError,
    loading: collectionLoading
  } = useQuery(LIST_COLLECTION_QUERY, {
    variables: {
      operation: 'query',
      type: 'library',
      typeId: userId
    },
    fetchPolicy: 'network-only'
  });

  const handleFilterChange = (option) => {
    setFilterType(option.value);
  };

  const createNewFile = () => {
    navigate(`/Files/new`);
  };

  const createNewCollection = () => {
    navigate('/collections/new');
  };

  useEffect(() => {
    refetch();
    draftRefetch();
  }, []);

  if (loading || collectionLoading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <div>
      <Header>
        <NewTitleLeft>
          <PageTitle>Files</PageTitle>
        </NewTitleLeft>
        <TitleRight>
          <Select
            name="files"
            options={FileFilterOptions}
            styles={FilesFilterStyles}
            placeholder={'All Files'}
            onChange={(selectedOption) => {
              handleFilterChange(selectedOption);
            }}
            className="files-dropdown"
          ></Select>
          <Button onClick={createNewFile}>+ New File</Button>
        </TitleRight>
      </Header>
      <DocumentSection isOpen={sidebarOpen}>
        <div className="collectionButton">
          <div className="drop-item">
            <TableDropDown
              state={ListCollection?.list_CollectionItems?._CollectionItems?.length > 0 ? true : false}
              text="Collections"
            >
              {ListCollection?.list_CollectionItems?._CollectionItems?.length > 0 ? (
                <DocumentList
                  data={getSortDate(ListCollection?.list_CollectionItems?._CollectionItems)}
                  icons={<CollectionIcon />}
                  navlink={'/collections/'}
                />
              ) : (
                <EmptyDataContainer style={{ width: '50vw' }}>No Collections Have been created</EmptyDataContainer>
              )}
            </TableDropDown>
          </div>
          <div className="collection-button">
            <CollectionBtn>
              <GreyButton onClick={createNewCollection}>New Collection</GreyButton>
            </CollectionBtn>
          </div>
        </div>
        <div className="drop-item files">
          <TableDropDown state={true} text="Files">
            <DocumentList
              data={getSortDate(
                filterType === 'all'
                  ? documentData?.list_FileItems?._FileItems
                  : documentData?.list_FileItems._FileItems.filter((file) => file.fileType.includes('image'))
              )}
              icons={<FileIcon />}
              navlink={'/files/'}
            />
          </TableDropDown>
        </div>
        <div className="drop-item">
          <TableDropDown state={false} text="Draft">
            <DocumentList
              data={getSortDate(
                filterType === 'all'
                  ? draftData?.list_FileItems?._FileItems
                  : draftData?.list_FileItems?._FileItems.filter((file) => file.fileType.includes('image'))
              )}
              icons={<FileIcon />}
              navlink={'/files/'}
            />
          </TableDropDown>
        </div>
      </DocumentSection>
    </div>
  );
};

export default Files;
