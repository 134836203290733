import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  GET_REGISTER_QUERY,
  LIST_REGISTER_FILES_QUERY
} from '../../../graphql/operations/Queries/Registers/RegisterQueries';

function useGetRegisterItems() {
  const navigate = useNavigate();
  let { projectId, workspaceId, registerId } = useParams();
  const [documentList, setDocumentList] = useState([]);
  const { data, error, loading } = useQuery(GET_REGISTER_QUERY, {
    variables: { id: registerId, operation: 'query', type: 'WORKSPACE', typeId: workspaceId }
  });
  const {
    error: listError,
    loading: listLoading,
    refetch: refetchFiles
  } = useQuery(LIST_REGISTER_FILES_QUERY, {
    variables: {
      type_typeId: `register_${registerId}`,
      recordStatus: 0,
      workspaceId,
      operation: 'query'
    },
    onCompleted: (data) => {
      setDocumentList([...data.list_FileItems._FileItems]);
    },
    fetchPolicy: 'network-only'
  });

  const createNewRegisterDocument = () => {
    if (data.get_Register.registerType === 'static') {
      //navigate to create static document
      navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/staticdocument`);
    } else if (data.get_Register.registerType === 'dynamic')
      navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/dynamicdocument`);
  };
  const ViewRegisterDocument = (fileId: string) => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/viewdocument/${fileId}`);
  };
  useEffect(() => {
    refetchFiles();
  }, []);

  return {
    error,
    listError,
    listLoading,
    ViewRegisterDocument,
    createNewRegisterDocument,
    documentList,
    loading
  };
}

export default useGetRegisterItems;
