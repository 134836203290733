import React from 'react';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../CustomButton';
import Modal from '../Modal';
import { ModalBody, ModalTitle, ModalWrapper } from '../styles/ModalStyles';

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  deleteType: 'section' | 'block';
  confirmHandler: () => void;
}

//confirmation Modal
const EditorDeleteContentModal = ({ isOpen, toggleModal, deleteType, confirmHandler }: Props) => {
  return (
    <Modal onClose={toggleModal} open={isOpen}>
      <ModalTitle>{`Delete ${deleteType}`}</ModalTitle>
      <ModalBody>
        <ModalText>This action cannot be undone. Are you sure you want to delete this item ?</ModalText>
        <ModalWrapper>
          <Button secondary onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={confirmHandler}>Confirm</Button>
        </ModalWrapper>
      </ModalBody>
    </Modal>
  );
};

export default EditorDeleteContentModal;
