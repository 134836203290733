//to be deleted as it is unused

import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import CloseIcon from '../../../assets/SvgComponents/CloseIcon';
import PlusIcon from '../../../assets/SvgComponents/PlusIcon';
import Button from '../../../components/CustomButton';
import { DarkButton, GreyButton, LightButton } from '../../../components/styles/ButtonStyles';
import { AssigneeSeparator, AssigneeWrapper, AvatarStyles, InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Input } from '../../../components/styles/Inputs';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import Table from '../../../components/Table';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { COLUMNS, SELECTEDCOLUMNS } from '../../../constants/RegisterColumnData';
import {
  createWorkspaceMutation,
  updateWorkspaceMutation
} from '../../../graphql/operations/Mutations/Workspaces/WorkspaceMutations';
import { listRegistersQuery_Project } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import { getWorkspaceQuery } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { Container } from '../../Projects/ProjectViews/CreateProject';
// import { registerData } from '../../constants/MockRegisterData'
// import {  createdRegisterData } from '../../constants/MockRegisterData'
// import InputField from "../../components/Reusable/Formik/Input";

const WorkspaceNameStyles = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    //Apply the style to only first div ie-Inputwrapper which is a div.
    flex: 0 0 75%;
  }
`;

const RegisterContainer = styled.div`
  /* border: 1px solid blue; */
`;

export const RegisterList = styled.div`
  padding-top: 25px;
  table {
    margin-bottom: 50px;
  }
  button {
    svg {
      vertical-align: middle;
      margin-right: 5px;
      display: inline-block;
      padding-bottom: 2px;
    }
  }
  tr {
    td {
      font-weight: 600 !important;
    }
  }
`;

export const RegisterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  margin-right: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
  h3 {
    padding-bottom: 0px;
    margin-left: 10px;
  }
`;

interface WorkspaceInterface {
  name: string;
  registers: string[];
}

function WorkspaceConfiguration() {
  let navigate = useNavigate();
  let { projectId, workspaceId } = useParams();
  const { state } = useContext(AppContext);
  const userId = state.userId;
  const [workspaceInfo, setWorkSpaceInfo] = useState<WorkspaceInterface>({
    name: '',
    registers: []
  });
  const [registers, setRegisters] = useState([]);
  const [selectRegisters, setSelectRegisters] = useState([]);
  const {
    data: createdRegisterData,
    // error: listError,
    // loading: registerLoading,
    refetch: refetchRegisters
  } = useQuery(listRegistersQuery_Project, {
    variables: {
      projectId
    },
    onCompleted: (data) => {
      setRegisters(createdRegisterData.listRegisters.Registers);
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const [createWorkSpace] = useMutation(createWorkspaceMutation);
  const {
    data: workspaceDetails,
    // error: getWorkSpaceError,
    // loading: workspaceLoading,
    refetch
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    },
    onCompleted: (data) => {
      let name = data.getWorkspace.name;
      let registers = data.getWorkspace.registers;
      setWorkSpaceInfo({
        name: name,
        registers: [...registers]
      });
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const [updateWorkspace] = useMutation(updateWorkspaceMutation, {
    onCompleted: (data) => {
      // refetch()
    }
    // refetchQueries: [{ query: getWorkspaceQuery, variables: { id: workspaceId } }]
  });

  useEffect(() => {
    let confirmedRegisters = registers.filter((register) => {
      return workspaceInfo.registers.includes(register.id);
    });
    setSelectRegisters(confirmedRegisters);
  }, [workspaceInfo.registers, registers]);

  useEffect(() => {
    refetchRegisters();
  }, []);

  const addRegisterToWorkspace = async (register: any, ids: any) => {
    let registers = [];

    if (ids.length > 0) {
      registers = [...ids, register.id];
    } else {
      registers = [register.id];
    }
    setSelectRegisters([...selectRegisters, register]);
    let response = await updateWorkspace({
      variables: {
        id: workspaceId,
        name: workspaceInfo.name,
        registers: registers
      }
    });
    if (response) {
      refetch();
    }
  };
  const removeRegisterFromWorkspace = async (register: any, ids: any) => {
    let registers = ids.filter((registerId) => {
      return registerId !== register.id;
    });

    let response = await updateWorkspace({
      variables: {
        id: workspaceId,
        name: workspaceInfo.name,
        registers: registers
      }
    });
    if (response) {
      let result = selectRegisters.filter((selectRegister) => {
        return selectRegister.id !== register.id;
      });
      setSelectRegisters([...result]);
      refetch();
    }
  };

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        id: 'Action',
        Cell: ({ cell: { row } }) => {
          return (
            <>
              <span onClick={(e) => e.stopPropagation()}>
                <DarkButton onClick={() => removeRegisterFromWorkspace(row.original, workspaceInfo.registers)}>
                  <CloseIcon fill="#FFF" />
                  Remove
                </DarkButton>
              </span>
            </>
          );
        }
      }
    ],
    [workspaceInfo.registers, selectRegisters]
  );

  const createdColumns = useMemo(
    () => [
      ...SELECTEDCOLUMNS,
      {
        id: 'Action',
        Cell: ({ cell: { row } }) => {
          return (
            <>
              <span onClick={(e) => e.stopPropagation()}>
                <LightButton onClick={() => addRegisterToWorkspace(row.original, workspaceInfo.registers)}>
                  <PlusIcon stroke="#313B48" />
                  Add Register
                </LightButton>
              </span>
            </>
          );
        }
      }
    ],

    [workspaceInfo.registers, selectRegisters]
  );

  const selectedData = useMemo(() => (selectRegisters ? selectRegisters : null), [selectRegisters]);

  const createdData = useMemo(() => (registers ? registers : null), [registers]);

  const createNewRegister = async () => {
    if (workspaceId) {
      navigate(`/newprojects/workspaceconfig/${projectId}/newregister/${workspaceId}`);
    } else {
      const response = await createWorkSpace({
        variables: {
          name: workspaceInfo.name,
          projectId: projectId,
          createdAt: new Date().toISOString(),
          createdBy: userId,
          registers: []
        }
      });
      if (response) {
        let workspaceId = response.data.addWorkspace.result.id;
        navigate(`/newprojects/workspaceconfig/${projectId}/newregister/${workspaceId}`);
      }
    }

    // navigate('/newprojects/workspaceconfig/newregister')
  };
  const addMembers = () => {
    if (workspaceId && projectId) {
      navigate(`/newprojects/workspaceconfig/${projectId}/addmembers/${workspaceId}`, {
        state: {
          data: location.state
        }
      });
    } else {
      navigate('/newprojects/workspaceconfig/addmembers');
    }
  };
  const location = useLocation();
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Add Workspace</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button onClick={addMembers}>Next</Button>
        </TitleRight>
      </Header>
      <Container>
        <WorkspaceNameStyles>
          <InputWrapper>
            <Label>Workspace Name</Label>
            <Input
              value={workspaceInfo.name}
              onChange={(e) => setWorkSpaceInfo({ ...workspaceInfo, name: e.target.value })}
            ></Input>
          </InputWrapper>
          <AssigneeWrapper>
            <Label>Created By</Label>
            <AssigneeSeparator>
              <AvatarStyles>
                <CustomAvatar name={state.fullName} size={21} />
              </AvatarStyles>
              <Small>
                {new Date().toLocaleDateString('En-US', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric'
                })}{' '}
                {new Date().toLocaleTimeString('En-US', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </Small>
            </AssigneeSeparator>
          </AssigneeWrapper>
        </WorkspaceNameStyles>
        <RegisterContainer>
          <RegisterHeader>
            <PageTitle>Register Selection</PageTitle>
            <GreyButton onClick={createNewRegister}>Create New Register</GreyButton>
          </RegisterHeader>
          <RegisterList>
            {selectedData && <Table columns={columns} data={selectedData} />}
            {createdData && <Table columns={createdColumns} data={createdData} />}
          </RegisterList>
        </RegisterContainer>
      </Container>
    </>
  );
}

export default WorkspaceConfiguration;
