import * as yup from "yup";

const CreateNewCollectionInAFileSchema = yup.object().shape({
  collectionName: yup
    .string()
    .min(2, "Collection name is short")
    .max(50, "Collection name is too long")
    .required("Please enter a collection name"),
});

export default CreateNewCollectionInAFileSchema;
