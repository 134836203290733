import { useQuery } from '@apollo/client';
import React, { MutableRefObject, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import { LIST_MEETING_NOTE_TEMPLATE_QUERY } from '../../../graphql/operations/Queries/MeetingNotes/MeetingNoteQueries';
import useGetOrganizationName from '../../../hooks/useGetOrganizationName';
import { useToggle } from '../../../lib/UseToggle';
import { ErrorPage } from '../../../pages/Others';
import {
  ButtonWrapper,
  TemplateContainer
} from '../../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import Button from '../../CustomButton';
import Loader from '../../Loader';
import CreateNewTemplateTile from '../../MeetingNotes/CreateNewTemplateTile';
import MeetingNoteTemplateTile from '../../MeetingNotes/MeetingNoteTemplateTile';
import Modal from '../../Modal';
import { PageTitle } from '../../styles/Header';
import { ModalBody, ModalContainer, ModalTitle } from '../../styles/ModalStyles';
import CreateNoteModal from './CreateNoteModal';

type TemplateModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  selectedWorkspaceId: string;
  selectedProjectId: string;
};

function SelectMeetingNoteTemplateModal({
  isOpen,
  toggleModal,
  selectedWorkspaceId,
  selectedProjectId
}: TemplateModalProps) {
  const { state } = useContext(AppContext);
  let navigate = useNavigate();
  const selectedTemplateId = useRef<string | null>(null);
  const [isCreateNoteModalOpen, toggleCreateNoteModal] = useToggle(false);

  const {
    data: meetingNoteTemplates,
    error,
    loading
  } = useQuery(LIST_MEETING_NOTE_TEMPLATE_QUERY, {
    variables: {
      organizationId_type: `${state.orgId}_MEETING_NOTES`
    },
    fetchPolicy: 'network-only'
  });
  const { getOrganization, error: orgError, loading: orgLoading } = useGetOrganizationName();
  const [selectedTemplateIndex, setSelectedTemplate] = useState<string>('');

  const handleSelectedTemplate = (id: string) => {
    setSelectedTemplate(id);
  };

  const handleMeetingNoteTemplate = () => {
    if (selectedTemplateIndex === 'new') {
      navigate(`/project/workspace/${selectedProjectId}/${selectedWorkspaceId}/meetingnotes/newtemplate`);
    } else {
      selectedTemplateId.current = selectedTemplateIndex;
      toggleCreateNoteModal();
    }
  };

  // if (error || orgError) return <ErrorPage />;
  if (loading) return null;

  return (
    <>
      <Modal open={isOpen} onClose={toggleModal}>
        <ModalContainer width="1185px" style={{ paddingBottom: '20px' }}>
          <ModalTitle>Choose Template</ModalTitle>
          <ModalBody style={{ paddingLeft: '40px', maxHeight: '80vh', overflowY: 'scroll' }}>
            <PageTitle>Platform Suite Note Templates</PageTitle>
            {loading || orgLoading ? (
              <Loader />
            ) : (
              <>
                <TemplateContainer style={{ paddingLeft: '0px' }}>
                  {meetingNoteTemplates.list_TemplateItems._TemplateItems?.length
                    ? meetingNoteTemplates.list_TemplateItems._TemplateItems?.map((noteTemplate: any) => {
                        noteTemplate.isGlobal && (
                          <MeetingNoteTemplateTile
                            key={noteTemplate._id}
                            selected={selectedTemplateIndex}
                            handleSelect={handleSelectedTemplate}
                            template={noteTemplate}
                          >
                            {noteTemplate.templateName}
                          </MeetingNoteTemplateTile>
                        );
                      })
                    : null}
                </TemplateContainer>
                <PageTitle>{getOrganization?.get_Organization?.name}'s Templates</PageTitle>
                <TemplateContainer style={{ paddingLeft: '0px' }}>
                  <CreateNewTemplateTile
                    key="new"
                    selected={selectedTemplateIndex}
                    handleSelect={handleSelectedTemplate}
                    template={{ templateName: 'new', isGlobal: false, _id: 'new' }}
                  />
                  {meetingNoteTemplates.list_TemplateItems._TemplateItems?.length
                    ? meetingNoteTemplates.list_TemplateItems._TemplateItems?.map((noteTemplate: any) => {
                        return (
                          <MeetingNoteTemplateTile
                            key={noteTemplate._id}
                            selected={selectedTemplateIndex}
                            handleSelect={handleSelectedTemplate}
                            template={noteTemplate}
                          >
                            {noteTemplate.templateName}
                          </MeetingNoteTemplateTile>
                        );
                      })
                    : null}
                </TemplateContainer>
              </>
            )}
          </ModalBody>
          <ButtonWrapper>
            <Button onClick={() => toggleModal()} secondary>
              Cancel
            </Button>
            <Button onClick={() => handleMeetingNoteTemplate()}>Confirm</Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
      <CreateNoteModal
        isOpen={isCreateNoteModalOpen}
        toggleModal={toggleCreateNoteModal}
        projectId={selectedProjectId}
        workspaceId={selectedWorkspaceId}
        templateId={selectedTemplateId.current}
      />
    </>
  );
}

export default SelectMeetingNoteTemplateModal;
