import React from 'react'
import Lottie from 'react-lottie';
import animationData from "../assets/lotties/loader.json";
import { defaultOptions } from '../constants/LoaderDefaultOptions'

interface Options {
  loop: boolean,
  autoplay: boolean,
  animationData: typeof animationData
  rendererSettings: any
}


interface LoaderProps {
  width?: number
  height?: number
  options?: Options
}

function Loader({ options, width, height }: LoaderProps) {
  return (
    <Lottie options={options ? options : defaultOptions} width={width ? width : 400} height={height ? height : 500} />
  )
}

export default Loader