import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import DocumentList from '../../../components/DocumentList';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { Header, PageTitle, TitleLeft, TitleRight } from '../../../components/styles/Header';
import { ReactComponent as FileIcon } from '../../../assets/icons/NewFileIcon.svg';
import { AppStyleProps } from '../../../App';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import { LIST_DRAFT_FILES_QUERY, LIST_FILES_QUERY } from '../../../graphql/operations/Queries/Library/FilesQueries';
import { useSidebar } from '../../../lib/SidebarState';
// import { Small } from '../../../components/styles/SmallText';
// import React, { useContext } from "react";
// import { AppContext } from "../../../AppContext";
// import DocumentSection from '../../Library/FilesView/Files'
// import { documentData } from '../../../constants/MockDocumentData'

const DocumentSection = styled.div<AppStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 1.3rem;
  margin-left: 1.5rem;
  .drop-item {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .collectionButton {
    display: flex;
    justify-content: space-between;
  }
  .collection-button {
    // margin-right: 3rem;
    // margin-right: ${(props) => (props.isOpen ? '10rem' : '7rem')}
  }
`;

function WorkspaceLibrary() {
  let navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const { sidebarOpen } = useSidebar();
  // const { state } = useContext(AppContext);
  // const userId = state.userId;
  const {
    data: documentData,
    error,
    loading
  } = useQuery(LIST_FILES_QUERY, {
    variables: { type_typeId: `workspace_${workspaceId}`, recordStatus: 0, operation: 'query' },
    fetchPolicy: 'network-only'
  });
  const { data: draftWorkspaceData } = useQuery(LIST_DRAFT_FILES_QUERY, {
    variables: {
      type_typeId: `workspace_${workspaceId}`,
      recordStatus: 0,
      operation: 'query'
    }
  });
  const createNewFile = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/library/files/new`);
  };
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) {
    console.error('error', error);
    return <ErrorPage />;
  }
  return (
    <div>
      <Header>
        <TitleLeft>
          <PageTitle>Files</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={createNewFile}>+ New File</Button>
        </TitleRight>
      </Header>
      <DocumentSection isOpen={sidebarOpen}>
        <div className="drop-item">
          <TableDropDown state={true} text="Files">
            <DocumentList
              data={documentData?.list_FileItems?._FileItems}
              icons={<FileIcon />}
              navlink={`/project/workspace/${projectId}/${workspaceId}/library/files/`}
            />
          </TableDropDown>
        </div>
        <div className="drop-item">
          <TableDropDown state={false} text="Drafts">
            <DocumentList
              data={draftWorkspaceData?.list_FileItems?._FileItems}
              icons={<FileIcon />}
              navlink={`/project/workspace/${projectId}/${workspaceId}/library/files/`}
            />
          </TableDropDown>
        </div>
      </DocumentSection>
    </div>
  );
}

export default WorkspaceLibrary;
