import styled from '@emotion/styled';

type ChooseFieldProps = {
  selected?: number;
  template: {
    id: number;
    formHeader?: string;
    formLabel?: string;
    formType: string;
    formData?: { formLabel: string; formValue: boolean }[];
    formValue?: boolean;
  };
  handleSelect: (id: number) => void;
  children: React.ReactNode;
};

type ChooseProps = {
  isSelected: boolean;
};

const BoxStyles = styled.div<ChooseProps>`
  border: 1px solid rgb(237, 237, 237);
  border-radius: 10px;
  padding: 64px 0px;
  min-width: 200px;
  max-width: 200px;
  max-height: 150px;
  margin-bottom: 25px;
  margin-right: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  flex: 1 0 21%;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
  background-color: ${(props) => (props.isSelected ? '#E5E5E5' : '')};
`;

const TemplateName = styled.p`
  font-weight: 400;
  color: var(--black);
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

function ChooseFieldTemplateTile(props: ChooseFieldProps) {
  return (
    <BoxStyles
      isSelected={props.template.id === props.selected?.id}
      onClick={() => {
        props.handleSelect(props.template.id);
      }}
    >
      <TemplateName>{props.children}</TemplateName>
    </BoxStyles>
  );
}

export default ChooseFieldTemplateTile;
