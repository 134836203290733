import React from 'react';
import { useParams } from 'react-router';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import ArchivedProjectsList from '../../../components/ArchivedProjectsList';
import Loader from '../../../components/Loader';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import useArchivedWorkspaces from '../../../hooks/features/workspaces/useArchivedWorkspaces';
import { ErrorPage } from '../../Others';
import { EmptyDataContainer, ProjectsContainer } from '../../Projects/ProjectViews/Projects';

function WorkspaceArchivalPage() {
  const { projectId } = useParams();
  const { data, error, loading, handleUnArchive } = useArchivedWorkspaces(projectId);
  if (error) return <ErrorPage />;
  if (loading) return <Loader />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Archived Workspaces</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight></TitleRight>
      </Header>
      <ProjectsContainer>
        {data?.list_WorkspaceItems?._WorkspaceItems && data?.list_WorkspaceItems?._WorkspaceItems?.length === 0 ? (
          <div className="empty-data">
            <EmptyDataContainer>No Archived projects.</EmptyDataContainer>
          </div>
        ) : (
          <ArchivedProjectsList data={data?.list_WorkspaceItems?._WorkspaceItems} removeArchive={handleUnArchive} />
        )}
      </ProjectsContainer>
    </>
  );
}

export default WorkspaceArchivalPage;
