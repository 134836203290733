import { useState } from 'react';
import styled from '@emotion/styled';
import Button, { CustomButton } from '../../../components/CustomButton';
import { PageNavStyles } from '../../Library/FilesView/Files/ViewFile/ViewFile';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../components/styles/Header';
import { VIEW_SINGLE_TASK_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import Modal from '../../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../components/styles/ModalStyles';
import { useToggle } from '../../../lib/UseToggle';
import { ErrorPage } from '../../Others/ErrorPage';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { DELETE_TASK_MUTATION } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import NavigationLink from '../../../components/NavigationLink';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.18rem;
  gap: 19px;
`;
const Text = styled.p`
  font-weight: 600;
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;

const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;
const Delete = styled.span({
  Button: {
    background: '#D14747'
  }
});

const ViewWorkspaceTask = () => {
  let { taskId, projectId, workspaceId } = useParams();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, toggle] = useToggle(false);

  const { data, error, loading } = useQuery(VIEW_SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    fetchPolicy: 'network-only'
  });
  const [deleteTask, { error: deleteError }] = useMutation(DELETE_TASK_MUTATION, {
    variables: { id: taskId, type: data?.get_Task?.type, typeId: data?.get_Task?.typeId }
  });

  async function taskDelete() {
    setIsLoading(true);
    let toastId = toast.loading('Archiving Task..');
    try {
      let response = await deleteTask();
      if (response) {
        toast.dismiss(toastId);
        toast.success('Task Archived.');
        setIsLoading(false);
        navigate(`/project/workspace/${projectId}/${workspaceId}/tasks`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error in Archiving WS TASK', error);
      toast.dismiss(toastId);
      toast.error('Operation failed.');
    }
  }

  const editTask = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/tasks/${taskId}/edit`);
  };
  const handleNavigate = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/tasks`);
  };

  if (loading) return <Loader />;
  if (error || deleteError) return <ErrorPage />;
  const { get_Task } = data;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>{get_Task.title}</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button onClick={editTask} secondary={true}>
            Edit Task
          </Button>
          <Delete>
            <Button disabled={isLoading} onClick={() => toggle()}>
              Archive
            </Button>
          </Delete>
        </TitleRight>
      </Header>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Archive Task</ModalTitle>
          <ModalBody>
            <ModalText>Do you want to archive this task?</ModalText>
          </ModalBody>
          <ButtonWrapper>
            <Button disabled={isLoading} onClick={toggle} secondary>
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={() => taskDelete()}>
              Confirm
            </Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
      <PageNavStyles>
        <NavigationLink end to={`/project/workspace/${projectId}/${workspaceId}/tasks/${taskId}`}>
          About
        </NavigationLink>
        <NavigationLink to="discussions">Discussions</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
};

export default ViewWorkspaceTask;
