import { gql } from '@apollo/client';

export const LIST_TASKS_QUERY = gql`
  query ListTaskQuery($assignee: String, $assignedTo: String, $organizationId_projectId_typeId: String)
  @api(name: "task") {
    list_TaskItems(
      filter: {
        _or: { assignee: { eq: $assignee }, assignedTo: { eq: $assignedTo } }
        organizationId_projectId_typeId: { beginsWith: $organizationId_projectId_typeId }
        recordStatus: { eq: 0 }
      }
    ) {
      _TaskItems {
        assignedTo
        assignedToDetails {
          name
          profilePicture
        }
        assignee
        assigneeDetails {
          name
          profilePicture
        }
        createdAt
        description
        dueDate
        _id
        isAcknowledged
        priority
        projectId
        recordStatus
        status
        title
        updatedAt
        updatedBy
        type
        typeId
      }
    }
  }
`;

export const LIST_ASSOCIATE_TASKS_QUERY = gql`
  query ListAssociateTasks($organizationId_projectId_typeId: String, $parentAssociateId: String) @api(name: "task") {
    list_TaskItems(
      filter: {
        organizationId_projectId_typeId: { ge: $organizationId_projectId_typeId }
        recordStatus: { eq: 0 }
        isAssociated: { eq: true }
        parentAssociateId: { eq: $parentAssociateId }
      }
    ) {
      _TaskItems {
        _id
        acknowledgement
        assignedTo
        assignedToDetails {
          name
          profilePicture
        }
        assignee
        assigneeDetails {
          name
          profilePicture
        }
        dueDate
        isAcknowledged
        isAssociated
        organizationId
        organizationId_projectId_typeId
        parentAssociateId
        priority
        projectId
        recordStatus
        status
        title
        workspaceId
        typeId
        type
      }
    }
  }
`;

export const PROJECT_LIST_TASKS_QUERY = gql`
  query ListTaskQuery($organizationId_projectId_typeId: String) @api(name: "task") {
    list_TaskItems(filter: { organizationId_projectId_typeId: { beginsWith: $organizationId_projectId_typeId } }) {
      _TaskItems {
        assignedTo
        assignedToDetails {
          name
          profilePicture
        }
        assignee
        assigneeDetails {
          name
          profilePicture
        }
        createdAt
        description
        dueDate
        _id
        isAcknowledged
        priority
        projectId
        recordStatus
        status
        title
        updatedAt
        updatedBy
        type
        typeId
      }
    }
  }
`;

export const SINGLE_TASK_QUERY = gql`
  query SINGLE_TASK_QUERY($id: ID!) @api(name: "task") {
    get_Task(id: $id) {
      assignedTo
      assignedToDetails {
        name
        profilePicture
      }
      assignee
      assigneeDetails {
        name
        profilePicture
      }
      createdAt
      description
      dueDate
      _id
      isAcknowledged
      priority
      projectId
      recordStatus
      status
      title
      updatedAt
      updatedBy
      links
      type
      typeId
      isAssociated
      parentAssociateId
      organizationId
      workspaceId
    }
  }
`;

export const VIEW_SINGLE_TASK_QUERY = gql`
  query SINGLE_TASK_QUERY($id: ID!) @api(name: "task") {
    get_Task(id: $id) {
      title
      projectId
      type
      typeId
      dueDate
      assignedToDetails {
        name
        profilePicture
      }
      _id
      isAcknowledged
      assignedTo
      assignee
    }
  }
`;
export const TASK_DISCUSSION_QUERY = gql`
  query getDiscussionQuery($typeId: String, $type: String) @api(name: "task") {
    list_NoteAndDisscussionItems(
      filter: { typeId: { eq: $typeId }, noteType: { eq: $type }, recordStatus: { eq: 0 } }
    ) {
      _NoteAndDisscussionItems {
        content
        createdAt
        createdBy
        _id
        isHighlighted
      }
    }
  }
`;
