import styled from '@emotion/styled';
import NoteItem from './NoteItem';
import { ReactComponent as EmptyNotes } from '../../assets/illustration/EmptyNotes.svg';

const Container = styled.div`
  /* border: 1px solid blue; */
  padding-top: 20px;
  padding-left: 37px;
  padding-right: 49px;
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 268px);
  padding: 0;
`;
function NotesContainer({ data, clickHandler }: { data: any; clickHandler: (_id: string) => void }) {
  return data.length > 0 ? (
    <Container>
      {data.map((note, index) => {
        return <NoteItem clickHandler={clickHandler} key={index} data={note} />;
      })}
    </Container>
  ) : (
    <ImageWrapper>
      <EmptyNotes />
    </ImageWrapper>
  );
}

export default NotesContainer;
