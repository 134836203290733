export const getTime = (time?: Date | string) => {
  if (time) {
    return new Date(time).toLocaleTimeString('En-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  } else {
    return new Date().toLocaleTimeString('En-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  }
};
