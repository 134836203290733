import { Editor } from '@tiptap/react';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as BoldIcon } from '../../../../assets/icons/editor-icons/bold.svg';
import { ReactComponent as ItalicIcon } from '../../../../assets/icons/editor-icons/italics.svg';
import { ReactComponent as OrderedListIcon } from '../../../../assets/icons/editor-icons/ordered.svg';
import { ReactComponent as UnderLineIcon } from '../../../../assets/icons/editor-icons/underline.svg';
import { ReactComponent as UnOrderedListIcon } from '../../../../assets/icons/editor-icons/unordered.svg';
import { ReactComponent as FileIcon } from '../../../../assets/icons/fileIcon.svg';
import { ReactComponent as EmojiIcon } from '../../../../assets/icons/IconEmoji.svg';
import { ReactComponent as SendIcon } from '../../../../assets/icons/IconSend.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/icons/Image_icon.svg';
import { CustomButton } from '../../../CustomButton';
import { EmojiButton, MenuButton, ToolBarStyle } from '../../../Editor/Toolbar/style';
// import { ReactComponent as ExpandIcon } from '../../../../assets/icons/IconExpand.svg';
import { Storage } from 'aws-amplify';
import EmojiPicker from 'emoji-picker-react';
import toast from 'react-hot-toast';
import useChatSocket from '../../../../hooks/features/chat/useChatSocket';
import { RootState } from '../../../../store';

type Props = {
  editor: Editor | null;
  showEmojis: boolean;
  toggleShowEmojis: () => void;
  sendMessage: (editor: Editor) => void;
};

const Toolbar = ({ editor, showEmojis, toggleShowEmojis, sendMessage }: Props) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const hiddenImageInput = useRef<HTMLInputElement>(null);

  const { sendFileMessage } = useChatSocket();
  const { selectedConversationId } = useSelector((state: RootState) => state.conversations);

  const handleFileClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };
  const handleImageClick = (event: any) => {
    hiddenImageInput?.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const fileUploaded = event.target.files[0];
    uploadToS3('DOCUMENT', fileUploaded);
  };

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const fileUploaded = event.target.files[0];
    uploadToS3('IMAGE', fileUploaded);
  };

  const uploadToS3 = async (type: 'DOCUMENT' | 'IMAGE', fileUploaded: File) => {
    if (!selectedConversationId) return;

    const id = toast.loading(`Inserting...`);

    try {
      const fileName = fileUploaded.name;
      const fileSize = fileUploaded.size;

      if (fileUploaded.size > 6000000) {
        toast.error('Upload a smaller image');
        return;
      }
      const data = await Storage.put(`${selectedConversationId}_${type}_${uuidv4()}`, fileUploaded, {
        contentType: fileUploaded?.type,
        bucket: process.env.REACT_APP_S3_CONVERSATIONS_BUCKET_NAME,
        level: 'public'
      });

      sendFileMessage('IMAGE', data?.key, fileName, fileSize);
      toast.success('File sent successfully', { id });
    } catch (error) {
      toast.error('Something went wrong', { id });
    }
  };

  const handleEmojiClick = useCallback(
    ({ emoji }: any) => {
      if (!editor) return;
      editor.chain().focus('end').createParagraphNear().insertContent(emoji).run();
      toggleShowEmojis();
    },
    [editor]
  );

  if (!editor) return null;

  return (
    /**
     * USING SAME COMPONENTS FROM EDITOR
     */
    <ToolBarStyle style={{ margin: '0px', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignContent: 'center' }}>
        <MenuButton
          onClick={() => editor?.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <BoldIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <ItalicIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? 'is-active' : ''}
        >
          <UnderLineIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <OrderedListIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <UnOrderedListIcon />
        </MenuButton>
        <MenuButton style={{ padding: '10px' }} onClick={handleImageClick}>
          <input
            accept="image/*"
            type="file"
            ref={hiddenImageInput}
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
          <ImageIcon style={{ width: '20px', height: '20px' }} />
        </MenuButton>
        <MenuButton style={{ padding: '10px' }} onClick={handleFileClick}>
          <input type="file" ref={hiddenFileInput} onChange={handleFileChange} style={{ display: 'none' }} />
          <FileIcon style={{ width: '25px', height: '25px' }} />
        </MenuButton>
        <EmojiButton>
          <EmojiIcon onClick={toggleShowEmojis} />
          {showEmojis && (
            <div className="emojis">
              <EmojiPicker
                height={400}
                width={300}
                onEmojiClick={handleEmojiClick}
                previewConfig={{ showPreview: false }}
              />
            </div>
          )}
        </EmojiButton>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <MenuButton onClick={() => {}}>
          <ExpandIcon style={{ width: '30px' }} />
        </MenuButton> */}
        <CustomButton
          style={{ borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => sendMessage(editor)}
        >
          <SendIcon style={{ width: '20px', height: '20px' }} />
        </CustomButton>
      </div>
    </ToolBarStyle>
  );
};

export default Toolbar;
