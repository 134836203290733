import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import { ListItemStyles } from '../../../components/ListItem';
import { Header, PageTitle, TitleLeft } from '../../../components/styles/Header';
import { TitleRight } from '../Tasks/CreateProjectWorkspaceTask';
import { ReactComponent as FileIcon } from '../../../assets/icons/NewFileIcon.svg';
import FileTag, { StyledTag } from '../../../components/FileTag';
import { getFileType } from '../../../lib/getFileType';
import { DateStyle, Time } from '../../../components/ProjectList';
import { ErrorPage } from '../../Others';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { getDate } from '../../../lib/getDate';
import useGetRegisterItems from '../../../hooks/features/registers/useGetRegisterItems';
import { getTime } from '../../../lib/getTime';

const RegistersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  margin-right: 4rem;
  margin-top: 0.9rem;
`;

const RegisterInfo = styled.div`
  display: flex;
  padding-left: 2.6rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }
`;

export const NoDocuments = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;
`;

const FileDetails = styled.div`
  display: flex;
  align-items: center;
`;
const DocumentName = styled.p`
  font-weight: 600;
  margin-left: 2.01rem;
`;
const FileTypeContainer = styled.div`
  align-items: flex-start;
  > * + * {
    margin: 10px;
  }
`;

const DocumentDate = styled.div`
  color: var(--text - light);
  display: flex;
  align-items: center;
  padding-right: 7.6rem;
`;

export type File = {
  createdAt: string;
  createdByName: string;
  createdBy: string;
  fields?: string;
  fileDescription: string;
  fileId: string;
  fileType: string;
  fileName: string;
  _id: string;
  links: string;
  name: string;
  projectId: string;
  type: string;
  typeId: string;
  updatedAt: string;
  updatedBy: string;
  status: string;
};

function RegisterItemsPage() {
  const { error, listError, loading, listLoading, ViewRegisterDocument, createNewRegisterDocument, documentList } =
    useGetRegisterItems();
  if (error || listError) return <ErrorPage />;
  if (listLoading || loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Register Items</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={createNewRegisterDocument}>+ Add Item</Button>
        </TitleRight>
      </Header>
      <RegistersContainer>
        {/*Have to add types to the file*/}
        <div>
          {documentList.length > 0 ? (
            documentList.map((file: File) => {
              return (
                <ListItemStyles key={file._id} onClick={() => ViewRegisterDocument(file._id)}>
                  <RegisterInfo>
                    <div className="main">
                      <FileDetails>
                        <FileIcon />
                        <DocumentName>{file.name}</DocumentName>
                      </FileDetails>
                      <FileTypeContainer>
                        <FileTag>{getFileType(file?.fileName).toUpperCase()}</FileTag>
                        {file.status ? <StyledTag>{file.status ? file.status.toUpperCase() : ''}</StyledTag> : null}
                      </FileTypeContainer>
                    </div>
                    <DocumentDate>
                      <DateStyle>{getDate(file?.createdAt)}</DateStyle>
                      <Time>{getTime(file.createdAt)}</Time>
                    </DocumentDate>
                  </RegisterInfo>
                </ListItemStyles>
              );
            })
          ) : (
            <NoDocuments>No Documents Uploaded in Register</NoDocuments>
          )}
        </div>
      </RegistersContainer>
    </>
  );
}

export default RegisterItemsPage;
