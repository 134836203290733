import styled from '@emotion/styled';
import React from 'react';
import { GreyButton } from '../../styles/ButtonStyles';

export const EmptyMessageStyles = styled.div`
  /* position: relative; */
  margin-left: 45px;
  margin-right: 45px;
  .quickbar-section {
    background: #f6f5f5;
    border-radius: 5px;
    width: 100%;
    height: 7.06rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* opacity: 30%; */
    margin-top: 1.5rem;
  }
  .title {
    opacity: 30%;
  }
  .subtitle {
    color: #1c1c1c;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    line-height: 22px;
  }
  .button {
    margin-top: 10px;
  }
`;

function NoCurrentOrgUsers(props) {
  const { toggle } = props;
  return (
    <EmptyMessageStyles>
      <div className="quickbar-section">
        <span className="title">Your organization has no members yet.</span>
        <span className="button">
          <GreyButton type="button" onClick={() => toggle()}>
            Add Members
          </GreyButton>
        </span>
      </div>
    </EmptyMessageStyles>
  );
}

export default NoCurrentOrgUsers;
