/* eslint-disable no-useless-escape */
import * as yup from 'yup';
// let regex_OnlyNumber = /^[^a-zA-Z]*$/;
const regex_WithHyphens = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const regex_OnlyCharacters = /^(?=.*[A-Za-z])+[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/g;
const ProfileUpdateValidation = yup.object().shape({
  firstname: yup
    .string()
    .min(1, 'Firstname is too Short!')
    .max(50, 'Firstname is too Long!')
    .required('Firstname is Required')
    .matches(regex_OnlyCharacters, {
      message: 'Atleast one character required and no blankspaces'
    }),
  lastname: yup
    .string()
    .min(1, 'Lastname is too Short!')
    .max(50, 'Lastname is too Long!')
    .required('Lastname is Required')
    .matches(regex_OnlyCharacters, {
      message: 'Atleast one character required and no blankspaces'
    }),
  phone: yup
    .string()
    .matches(regex_WithHyphens, { message: 'Please Enter valid Phone number' })
    .min(10, 'PhoneNumber is too Short!')
    .max(15, 'PhoneNumber is too Long!')
    .required('PhoneNumber is Required')
});

export default ProfileUpdateValidation;
