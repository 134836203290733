import { useQuery } from '@apollo/client';
import { Outlet, useParams } from 'react-router';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../components/styles/Header';
import { Small } from '../../components/styles/SmallText';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../Others/ErrorPage';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import { GET_REGISTER_QUERY } from '../../graphql/operations/Queries/Registers/RegisterQueries';
import { PageNavStyles } from '../Library/FilesView/Files/ViewFile/ViewFile';
import { getDate } from '../../lib/getDate';
import { useNavigate } from 'react-router';
import NavigationLink from '../../components/NavigationLink';

function ListDocumentsInRegister() {
  let { projectId, workspaceId, registerId } = useParams();
  const { data, error, loading } = useQuery(GET_REGISTER_QUERY, {
    variables: { id: registerId, operation: 'query', type: 'WORKSPACE', typeId: workspaceId }
  });
  // const [documentList, setDocumentList] = useState<File[]>([]);
  // const {
  //   data: documentData,
  //   error: listError,
  //   loading: listLoading,
  //   refetch: refetchFiles
  // } = useQuery(LIST_REGISTER_FILES_QUERY, {
  //   variables: {
  //     type: 'register',
  //     typeId: registerId,
  //     recordStatus: 0,
  //     operation: 'query'
  //   },
  //   onCompleted: (data) => {
  //     setDocumentList([...data.list_FileItems._FileItems]);
  //   },
  //   fetchPolicy: 'network-only'
  // });
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/registers`);
  };

  if (error) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>{data.get_Register.name}</PageTitle>
            <Small>Last Updated on {getDate(data.get_Register.createdAt)}</Small>
          </NewTitleLeft>
        </HeaderLeft>
      </Header>
      <PageNavStyles>
        <NavigationLink end to={`/project/workspace/${projectId}/${workspaceId}/${registerId}/documentlist`}>
          Items
        </NavigationLink>
        <NavigationLink end to="summary">
          Summary
        </NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
}

export default ListDocumentsInRegister;
