import React, { useContext, useState } from 'react';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import Button, { CustomButton } from '../../components/CustomButton';
import styled from '@emotion/styled';
import { Text } from '../../components/styles/Text';
import PricingTable from '../../components/Payment/PricingTable';
import { useNavigate } from 'react-router';
import { MOCKPRICINGCOLUMS } from '../../constants/MockColumnData';
import CancelIcon from '../../assets/SvgComponents/CancelIcon';
import { AppContext } from '../../AppContext';
import { TooltipStyles } from './ViewOrganization';
import { usePopperTooltip } from 'react-popper-tooltip';

const TableHeader = styled.div`
  * {
    margin: 0;
    padding: 0;
  }
  padding: 2rem 1rem;
  p {
    &:first-of-type {
      font-size: 1rem;
      font-weight: 600;
      line-height: 22px;
    }
    &:last-child {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      color: #93979f;
    }
  }
  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */

    color: #7f9e90;
  }
`;

const Pricing = () => {
  const [period, setPeriod] = useState<'Monthly' | 'Annual'>('Monthly');
  const { state } = useContext(AppContext);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  const PersonalHeader = (
    <TableHeader>
      <p>Personal</p>
      <h6>$0</h6>
      <p>
        for individuals <br /> per user per year
      </p>
    </TableHeader>
  );
  const ColabHeader = (
    <TableHeader>
      <p>Collaborative</p>
      <h6>{period === 'Monthly' ? '$17' : '$108'}</h6>
      <p>
        {period === 'Monthly' ? ' (Monthly)' : '(Annually)'}
        <br /> per user {period === 'Annual' ? 'annually' : 'per month'}
        {/* <br />{period === 'Annual' ? '(9$/month per user)' : null} */}
      </p>
    </TableHeader>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = [
    { Header: '', accessor: 'title' },
    {
      Header: PersonalHeader,
      accessor: 'pers'
    },
    {
      Header: ColabHeader,
      accessor: 'col'
    }
  ];

  React.useMemo(() => columns, [columns]);

  const navigate = useNavigate();
  return (
    <StyledPaymentDiv>
      <Header>
        <HeaderLeft>
          <CancelIcon />
          <NewTitleLeft>
            <PageTitle>PlatformSuite Collaborative Space</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          {(!state.orgId || state.orgId === 'null' || state.orgId === null) && (
            <>
              <span ref={setTriggerRef}>
                <Button className="pricing_trail_btn" onClick={() => navigate('/createorganization')} type="submit">
                  Start Free Trial
                </Button>
              </span>
              {visible && (
                <TooltipStyles ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
                  No credit card required. Get free 30 days trial.
                  <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                </TooltipStyles>
              )}
            </>
          )}
        </TitleRight>
      </Header>
      <div className="pricing_header">
        <Text>Join the Collaborative Space</Text>
        <Text>Compare options to find the right package for your needs</Text>
      </div>
      <div className="pricing_period">
        <CustomButton
          onClick={() => setPeriod('Monthly')}
          className={`pricing_period_btn ${period === 'Monthly' && 'active'}`}
        >
          Monthly
        </CustomButton>
        <CustomButton
          onClick={() => setPeriod('Annual')}
          className={`pricing_period_btn ${period === 'Annual' && 'active'}`}
        >
          Annual
        </CustomButton>
      </div>
      <PricingTable columns={columns} data={MOCKPRICINGCOLUMS} />
    </StyledPaymentDiv>
  );
};

const StyledPaymentDiv = styled.div`
  .pricing_trail_btn {
    padding: 0 1.5rem;
  }
  .pricing_header {
    margin: 2rem 0;

    & > p {
      margin: 0;
      text-align: center;
      /* margin-bottom: 15px; */
      &:nth-of-type(1) {
        font-weight: 600;
        font-size: 24px;
        line-height: 43px;
      }
      &:nth-of-type(2) {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
    }
  }
  .pricing_period {
    margin-right: 3rem;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    &_btn {
      background-color: transparent;
      color: black;

      border: 1px solid #e9eaec;
    }
  }
  .pricing_period_btn.active {
    border: 1px solid #4c6d66;
  }
`;

export default Pricing;
