import * as Yup from 'yup';

export const CreateRegisterValidationSchema = Yup.object({
  registerName: Yup.string()
    .min(2, 'Register name is too short, try a longer one')
    .max(50, 'Register name is too long, try a shorter one. (max length is 50)')
    .required('A Register Name is required'),
  // fileFormat: Yup.array()
  //   .min(1, "Please select atleast one file format")
  //   .required("File Format is required"),
  initiatorRole: Yup.string().when('registerType', {
    is: 'dynamic',
    then: Yup.string().required('Please provide an initiator role')
  }),
  responderRole: Yup.string().when('registerType', {
    is: 'dynamic',
    then: Yup.string()
      .notOneOf([Yup.ref('initiatorRole'), null], 'Responder and Initiator roles cannot be same')
      .required('Please provide a responder role')
  }),
  initiatorStatusOptions: Yup.array().when('registerType', {
    is: 'dynamic',
    then: Yup.array().of(
      Yup.object().shape({
        status: Yup.string().required('Atleast one option is required. Remove other blank fields'),
        statusType: Yup.string().required('Status Type is Required'),
        description: Yup.string().required('Please give a description of the option.')
      })
    )
  }),
  responderStatusOptions: Yup.array().when('registerType', {
    is: 'dynamic',
    then: Yup.array().of(
      Yup.object().shape({
        status: Yup.string().required('Atleast one option is required. Remove other blank fields'),
        statusType: Yup.string().required('Status Type is Required'),
        description: Yup.string().required('Please give a description of the option.')
      })
    )
  })
});
