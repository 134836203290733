import { gql } from '@apollo/client';
export const createTemplateMutation = gql`
  mutation createTemplateMutation(
    $createdAt: String
    $createdBy: String
    $templateName: String
    $workspaceName: String
    $organizationId: String
    $recordStatus: Float
    $updatedBy: String
    $updatedAt: String
    $registers: [Self_Template_workspace_registers_registersItem_Input_]
    $otherFields: String
    $isGlobal: Boolean
    $organizationId_type: String
    $type: String
  ) @api(name: "workspace") {
    add_Template(
      syncMode: NODE_COMMITTED
      input: {
        createdAt: $createdAt
        createdBy: $createdBy
        templateName: $templateName
        workspace: { workspaceName: $workspaceName, registers: $registers }
        organizationId: $organizationId
        recordStatus: $recordStatus
        updateBy: $updatedBy
        updatedAt: $updatedAt
        otherFields: $otherFields
        isGlobal: $isGlobal
        organizationId_type: $organizationId_type
        type: $type
      }
    ) {
      result {
        _id
      }
    }
  }
`;
