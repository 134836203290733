import { gql } from '@apollo/client';

export const WORKSPACE_LIST_TASKS_QUERY = gql`
  query listTaskQuery($organizationId_projectId_typeId: String) @api(name: "task") {
    list_TaskItems(
      filter: { organizationId_projectId_typeId: { eq: $organizationId_projectId_typeId }, recordStatus: { eq: 0 } }
    ) {
      _TaskItems {
        _id
        title
        assignedTo
        assignee
        assignedToDetails {
          name
          profilePicture
        }
        assigneeDetails {
          name
          profilePicture
        }
        status
        priority
        dueDate
        recordStatus
        type
        typeId
        createdAt
      }
    }
  }
`;

export const getWorkspaceQuery = gql`
  query getWorkspaceQuery($id: ID!) @api(name: "workspace") {
    get_Workspace(id: $id) {
      name
      registers
      createdAt
      createdBy
    }
  }
`;

export const listWorkspaceQuery = gql`
  query listWorkspaceQuery($projectId: String) @api(name: "workspace") {
    list_WorkspaceItems(filter: { projectId: { eq: $projectId }, recordStatus: { eq: 0 } }) {
      _WorkspaceItems {
        name
        _id
        createdAt
        createdBy
      }
    }
  }
`;

export const LIST_ARCHIVED_WORKSPACES_QUERY = gql`
  query listWorkspaceQuery($projectId: String) @api(name: "workspace") {
    list_WorkspaceItems(filter: { projectId: { eq: $projectId }, recordStatus: { eq: 1.0 } }) {
      _WorkspaceItems {
        name
        _id
        createdAt
        createdBy
      }
    }
  }
`;

export const LIST_WORKSPACE_QUERY = gql`
  query listWorkspaceQuery @api(name: "workspace") {
    list_WorkspaceItems {
      _WorkspaceItems {
        _id
        name
      }
    }
  }
`;

export const listTemplatesQuery = gql`
  query listTemplatesQuery($organizationId_type: String) @api(name: "workspace") {
    list_TemplateItems(filter: { organizationId_type: { eq: $organizationId_type } }, readMode: NODE_COMMITTED) {
      _TemplateItems {
        workspace {
          registers {
            fileFormats
            registerName
            registerType
          }
          workspaceName
        }
        createdAt
        createdBy
        _id
        organizationId_type
        recordStatus
        templateName
        updateBy
        updatedAt
        otherFields
        isGlobal
      }
    }
  }
`;
