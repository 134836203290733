import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import getUtcDate from '../../../lib/getUtcDate';
import { DarkGreenButton } from '../../styles/ButtonStyles';

const InformationStyles = styled.div`
  margin-left: 45px;
  margin-right: 45px;
  background: #f6f5f5;
  border-radius: 5px;
  padding-top: 32px;
  /* height: 7.06rem; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
`;
const MessageStyles = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: hsla(0, 0%, 11%, 1);
  letter-spacing: 0.2px;
  line-height: 22px;
`;

const ActionWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

type SProps = {
  dueDate: string;
  planType: 'monthly' | 'yearly';
};

function SubscriptionCancelled({ dueDate, planType }: SProps) {
  let navigate = useNavigate();
  return (
    <InformationStyles>
      <>
        <MessageStyles>
          Your {planType} subscription for PlatformSuite Collaborative space will end on {getUtcDate(dueDate)}
        </MessageStyles>
        <MessageStyles>
          Please reactivate your subscription to avoid cancellation of your plan and continue using this organization.
        </MessageStyles>
        <ActionWrapper>
          <DarkGreenButton
            onClick={() => navigate(planType === 'monthly' ? `managesubscription` : `managesubscription/yearly`)}
          >
            Manage Subscription
          </DarkGreenButton>
        </ActionWrapper>
      </>
    </InformationStyles>
  );
}

export default SubscriptionCancelled;
