import React from 'react';
import {
  PricingText,
  SeatBox,
  SeatQuantityBox,
  SeatQuantityText,
  SeatSelectionDiv,
  SeatWrapper,
  SubscriptionType,
  TotalAmountText
} from './SelectSeats';
import { ReactComponent as LongArrow } from '../../assets/icons/LongArrow.svg';
import styled from '@emotion/styled';

const LoaderText = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

type ProrateProps = {
  originalQuantity: number;
  currentQuantity: number;
  calculatingProrate: boolean;
  prorateAmount: number;
};

function DisplayProrateAmount({ originalQuantity, currentQuantity, calculatingProrate, prorateAmount }: ProrateProps) {
  return (
    <SeatWrapper style={{ width: '90%' }}>
      <SeatBox style={{ marginBottom: '0px' }}>
        <PricingText>Prorate charge for Platform Suite.</PricingText>
        <SeatSelectionDiv>
          <div>
            <SeatQuantityText>Seat Quantity</SeatQuantityText>
            <SeatQuantityBox>
              <div>{originalQuantity}</div>
              <div>
                <LongArrow />
              </div>
              <div>{currentQuantity}</div>
            </SeatQuantityBox>
          </div>
          <div>
            <SubscriptionType>Prorate Amount due today </SubscriptionType>
            <TotalAmountText>
              {calculatingProrate ? <LoaderText> Calculating</LoaderText> : `US$ ${prorateAmount}`}
            </TotalAmountText>
          </div>
        </SeatSelectionDiv>
      </SeatBox>
    </SeatWrapper>
  );
}

export default DisplayProrateAmount;
