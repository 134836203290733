import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Select from 'react-select';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../../axiosInstance';
import AvatarTags from '../../../components/AvatarTags';
import Button from '../../../components/CustomButton';
import {
  AboutTaskStyles,
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ChoicesWrapper,
  DateWrapper,
  InputWrapper,
  SameLineWrapper
} from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { statusStyles } from '../../../components/styles/StatusStyles';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { getValue } from '../../../components/Tasks';
import { hubspotPriorityOptions } from '../../../constants/DropDownOptions';
import getUtcDate from '../../../lib/getUtcDate';
import { DescriptionText } from '../../Library/FilesView/Files/ViewFile/FileInformation/AboutFile';
import { DateText } from '../../Workspaces/Registers/CreateNewDynamicDocumentInRegister';
import { AssignedHubspotTask, DelegatedHubspotTask } from '../HubSpotTasksPage';
import { TaskDetails } from '../TaskViews/CreateTask';
import { AssignedList, AssignedToWrapper } from '../TaskViews/ViewTask/TaskInformation/AboutTask';

const Text = styled.p`
  font-weight: 400;
  margin-top: 15px;
`;

const SameLineWrapperStyles = styled(SameLineWrapper)`
  > * + * {
    margin-left: 20px;
  }
  padding-left: 1rem;
`;

function ViewHubspotTask() {
  let { taskId } = useParams();
  const { state } = useContext(AppContext);
  const location = useLocation();
  const [name, setName] = useState('');
  const taskData = location?.state as (AssignedHubspotTask | DelegatedHubspotTask) & {
    getDataByField: 'assignedTo' | 'createdBy' | 'none';
  };

  useEffect(() => {
    const getName = () => {
      axiosInstance
        .get(
          `/task?organizationId=${state.orgId}&email=${encodeURIComponent(state.email)}&${taskData.getDataByField}=${
            taskData[taskData.getDataByField]
          }`
        )
        .then((res) => {
          console.log('RES', res?.data.name);
          setName(res.data.name);
        })
        .catch((error) => {
          console.log('ERROR', error);
        });
    };
    if (taskData?.getDataByField === 'assignedTo' || 'createdBy') {
      // console.log("TASK DATA", taskData?.getDataByField)
      getName();
    }
  }, []);

  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>{taskData?.taskTitle}</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <a href={`https://app.hubspot.com`} target="_blank" rel="noreferrer">
            {' '}
            <Button>Go to Hubspot</Button>
          </a>
        </TitleRight>
      </Header>
      <TaskDetails>
        <AboutTaskStyles>
          <div>
            <InputWrapper>
              <Label>Task Title</Label>
              <Text>{taskData?.taskTitle}</Text>
            </InputWrapper>
            <SameLineWrapperStyles>
              <Label>Project</Label>
              <Text>Hubspot</Text>
              <Label>Type</Label>
              <Text>{taskData?.type}</Text>
            </SameLineWrapperStyles>
            <InputWrapper>
              <Label>Description</Label>
              <DescriptionText dangerouslySetInnerHTML={{ __html: taskData.description }}></DescriptionText>
            </InputWrapper>
          </div>
          <div>
            <ChoicesWrapper>
              <Label>Priority</Label>
              <Select
                styles={statusStyles}
                options={hubspotPriorityOptions}
                defaultValue={getValue(hubspotPriorityOptions, taskData.priority)}
                isDisabled
              />
            </ChoicesWrapper>
            <DateWrapper>
              <Label>Due Date</Label>
              <DateText>{getUtcDate(taskData?.dueDate)}</DateText>
              <CalendarIconPlacer isEditable={false}></CalendarIconPlacer>
            </DateWrapper>
            <ChoicesWrapper>
              <Label>Number of associations</Label>
              <Text>{taskData?.numberOfAssociations}</Text>
            </ChoicesWrapper>
            <AssigneeWrapper>
              <Label>Reporter</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  {taskData?.getDataByField !== 'none' ? (
                    <CustomAvatar
                      name={taskData?.getDataByField === 'createdBy' ? name : taskData?.createdBy}
                      size={20}
                    />
                  ) : (
                    <CustomAvatar name={taskData?.createdBy} size={20} />
                  )}
                </AvatarStyles>
              </AssigneeSeparator>
            </AssigneeWrapper>
            <AssignedToWrapper>
              <Label>Assigned To</Label>
              <AssignedList>
                {taskData?.getDataByField !== 'none' ? (
                  <AvatarTags name={taskData?.getDataByField === 'assignedTo' ? name : taskData?.assignedTo} />
                ) : (
                  <CustomAvatar name={taskData?.assignedTo} size={20} />
                )}
              </AssignedList>
            </AssignedToWrapper>
          </div>
        </AboutTaskStyles>
      </TaskDetails>
    </>
  );
}

export default ViewHubspotTask;
