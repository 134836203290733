import styled from '@emotion/styled';
import TimeAgo from 'javascript-time-ago';
import Avatar from 'react-avatar';
import { BsArrowRight } from 'react-icons/bs';
import { ReactComponent as GroupIcon } from '../../../../../assets/icons/outside.svg';
// English.
import en from 'javascript-time-ago/locale/en';
import { ConversationDataI } from '../../../types';
TimeAgo.addDefaultLocale(en);

type Props = {
  selected?: boolean;
  conversation: ConversationDataI;
  clickHandler?: () => void;
};
const timeAgo = new TimeAgo('en-US');

const ChatItem = ({ conversation, selected, clickHandler }: Props) => {
  return (
    <ChatItemStyles selected={selected} onClick={clickHandler}>
      <div className="profile-pic-container">
        <Avatar
          round={true}
          size="40"
          name={conversation?.conversationName}
          src={conversation?.profilePhoto}
          color="#26303e"
        />
        {conversation.conversationType === 'group' ? <GroupIcon className="icon" /> : null}
      </div>
      <div className="message-details">
        <div className="row">
          <p className="name">{conversation?.conversationName}</p>
          <p className="last-message-date">
            {timeAgo.format(
              new Date(
                conversation?.latestMessageTimestamp ? conversation?.latestMessageTimestamp : conversation.updatedAt
              )
            )}
          </p>
        </div>
        <div className="row">
          <p className="last-message" dangerouslySetInnerHTML={{ __html: conversation?.latestMessage }}></p>
          {selected ? (
            <BsArrowRight className="arrowIcon" />
          ) : conversation.unreadMessage !== 0 ? (
            <UnReadMessages>{conversation.unreadMessage > 99 ? '99+' : conversation.unreadMessage}</UnReadMessages>
          ) : null}
        </div>
      </div>
    </ChatItemStyles>
  );
};

export default ChatItem;

const ChatItemStyles = styled.div<{ selected?: boolean }>`
  height: 74px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e6e6e6;
  transition: background-color 0.5s ease;
  background-color: ${(props) => (props.selected ? '#f6f5f5' : '#ffffff')};

  .profile-pic-container {
    position: relative;

    .icon {
      position: absolute;
      bottom: 0px;
      left: 30px;
    }
  }

  .message-details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: space-between;
    padding: 2px;
  }

  p {
    margin: 0;
    /* padding: 0; */
  }

  .name {
    font-weight: 400;
    font-size: 16px;
    line-height: 21.79px;
    color: #1c1c1c;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
  }

  .last-message {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.34px;
    color: #929292;
    width: 240px;
    max-height: 30px;
  }

  /* Handle long name and message  */
  .last-message,
  .name {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .last-message-date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16.34px;
    color: #7d7d7d;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: #f6f5f5;
  }
`;

const UnReadMessages = styled.div`
  background-color: #00b292;
  font-size: 12px;
  color: #ffffff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
