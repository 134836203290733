import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance';

export const fetchNotification = createAsyncThunk('notification/fetchNotification', async (userId: String) => {
  const result = await axiosInstance.get(`/notification?userId=${userId}`);
  return result?.data?.notifications;
});

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationData: [],
    error: false,
    loading: false
  },
  reducers: {
    postNotification: (state, action) => {
      state.notificationData = [action.payload.data.data, ...state.notificationData];
    },
    notificationOnClick: (state, action) => {
      state.notificationData[action.payload].isRead = true;
    },
    clearAllNotifications: (state) => {
      state.notificationData = state.notificationData.map((notification) => {
        return {
          ...notification,
          isActive: false,
          isRead: true
        };
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.notificationData = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchNotification.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.notificationData = [];
      })
      .addCase(fetchNotification.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      });
  }
});

export const { postNotification, notificationOnClick, clearAllNotifications } = notificationSlice.actions;
