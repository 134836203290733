import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Component from './FileComponent';

export default Node.create({
  name: 'fileUpload',

  group: 'block',

  content: 'inline*',
  isolating: true,

  atom: true,

  addAttributes() {
    return {
      platform_suite_file_upload_name: {
        default: null
      },
      src: {
        default: ''
      }
    };
  },

  addOptions() {
    return {
      editable: false
    };
  },

  parseHTML() {
    return [
      {
        tag: 'file-component'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['file-component', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  }
});
