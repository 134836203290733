import { useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import { createOrganizationMutation } from '../../../graphql/operations/Mutations/Organization/OrganizationMutations';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../axiosInstance';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { fetchOrganizationMembers } from '../../../reducers/users';
import { Storage } from 'aws-amplify';
import { useGetUserDetails } from '../../common/useGetUserDetails';
import { roles } from '../../../constants/Roles';

interface InitialValues {
  organizationName: string;
  file: any;
  createdBy: string;
}

function useCreateOrganization() {
  let navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const { state } = useContext(AppContext);
  const [userRoles, setUserRoles] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createOrganization] = useMutation(createOrganizationMutation);
  const reduxDispatch = useDispatch();
  const { getUserData } = useGetUserDetails();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  useEffect(() => {
    let organizationRoles = Object.keys(roles.ORGANIZATION).map((role) => {
      return { value: String(roles.ORGANIZATION[role]), label: role };
    });
    setUserRoles(organizationRoles);
  }, []);

  const initialValues: InitialValues = {
    file: '',
    organizationName: '',
    createdBy: state.fullName
  };

  const handleSubmit = async (values: InitialValues) => {
    setIsSubmitting(true);
    const toastId = toast.loading('Creating...');
    let id = uuidv4();
    let date = new Date();
    console.log(date.toISOString());
    date.setDate(date.getDate() + 30);
    console.log(date.toISOString());
    try {
      if (values.file) {
        await Storage.put('organization_' + id, values.file, {
          contentType: values.file.type,
          bucket: process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME,
          level: 'public'
        });
      }
      const organizationValue = await createOrganization({
        variables: {
          createdAt: new Date().toISOString(),
          createdBy: state.userId,
          name: values?.organizationName,
          recordStatus: 0,
          fileType: values.file ? values.file.type : '',
          updatedAt: new Date().toISOString(),
          updatedBy: state.userId,
          // numberOfSeats: 1,
          // paymentStatus: 'null',
          // paymentUserId: 'null',
          // stripeCustomerId: 'null',
          // subscriptionId: 'null',
          // subscriptionType: 'FREE',
          // subscriptionEndDate: date.toISOString(),
          photo: values.file
            ? `https://${process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME}.s3.${
                process.env.REACT_APP_S3_REGION
              }.amazonaws.com/public/${'organization_' + id}`
            : ''
        }
      });
      if (organizationValue) {
        let userApiResult = axiosInstance.patch(`/users/${state.userId}`, {
          organizationId: organizationValue?.data?.add_Organization?.result?._id
        });
        let roleMappingResult = axiosInstance.post('/rolesMapping', {
          organizationMemberRole: userRoles[0],
          userId: state?.userId,
          roleId: userRoles[0].value,
          entityType: 'ORGANIZATION',
          entityId: organizationValue?.data?.add_Organization?.result?._id,
          organizationId: organizationValue?.data?.add_Organization?.result?._id
        });
        reduxDispatch(fetchOrganizationMembers(organizationValue?.data?.add_Organization?.result?._id));
        let userEntityResult = axiosInstance.post('/userEntity', [
          {
            userId: state.userId,
            entityType: 'ORGANIZATION',
            entityId: organizationValue?.data?.add_Organization?.result?._id,
            seatType: 'TRIAL'
          }
        ]);
        await Promise.all([userEntityResult, roleMappingResult, userApiResult]);
        await getUserData()
          .then((response) => {
            if (response) {
              toast.dismiss(toastId);
              toast.success('Organization created');
              setIsSubmitting(false);
              navigate('/');
            }
          })
          .catch((error) => {
            toast.dismiss(toastId);
            setIsSubmitting(false);
            toast.error('Oh no something went wrong. Please refresh the page.');
          });
      }
    } catch (error) {
      console.error('Error in creating organization', error);
      toast.dismiss(toastId);
      toast.error('Failed to create organization. Try again.');
      setIsSubmitting(false);
    }
  };

  return {
    loading,
    isSubmitting,
    hiddenFileInput,
    handleFile,
    handleSubmit,
    initialValues,
    state,
    selectedImage,
    setSelectedImage
  };
}

export default useCreateOrganization;
