import styled from '@emotion/styled';

export const DropdownDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .drop-item {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const NoteListContainer = styled.div`
  width: 98%;
  margin: auto;
  padding: 15px 25px 10px 10px;
`;

export const AlignedDiv = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

export const NoOrganizationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;
