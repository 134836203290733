import store from "../store";

export const handleProjectNoteAutoSave = (ws: WebSocket, authToken: string) => {
  const { projectNote } = store.getState();

  const body = {
    data: { ...projectNote.projectNoteData, content: JSON.stringify(projectNote.sections) },
    noteId: projectNote.projectNoteData._id,
    workspaceId: projectNote.projectNoteData.workspaceId,
    type: 'UPDATE_WORKSPACE_NOTE_CONTENT',
    token: authToken
  };
  ws.send(JSON.stringify(body));
};