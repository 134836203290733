import React from 'react';
import Lottie from 'react-lottie';
// import { useLocation } from 'react-router'
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import Button from '../../../components/CustomButton';
import { TextAreaField } from '../../../components/Reusable/NewFormik/TextArea1';
import { InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { Text } from '../../../components/styles/Text';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import useRegisterNote from '../../../hooks/features/registers/useRegisterNote';
import { ErrorPage } from '../../Others';
import { TextAreaWrapper } from '../../Projects/AboutProject/ProjectInformation/ProjectAddress';
import { Container } from '../../Projects/ProjectViews/CreateProject';

function RegisterNotePage() {
  const { registerData, registerError, registerLoading, isSubmitting, formik, RouterState } = useRegisterNote();

  let registerName = registerData?.get_Register.name;
  if (registerError) return <ErrorPage />;
  if (registerLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Register Note</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={isSubmitting} type="submit">
              {RouterState?.description ? 'Edit Note' : 'Add Note'}
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <div>
            <InputWrapper>
              <Label>Register Name</Label>
              <Text>{registerName}</Text>
            </InputWrapper>
            <TextAreaWrapper style={{ width: '60vw' }}>
              <TextAreaField
                label="Register Note"
                type="text"
                name="description"
                id="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                touched={formik.touched.description}
                errors={formik.errors.description}
                disabled={formik.isSubmitting}
              ></TextAreaField>
            </TextAreaWrapper>
          </div>
        </Container>
      </form>
    </>
  );
}

export default RegisterNotePage;
