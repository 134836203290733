import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useContext, useState } from 'react';

// import { PaymentElement } from '@stripe/react-stripe-js';
// import axios from 'axios';
import axiosInstance from '../../axiosInstance';
import { AppContext } from '../../AppContext';
import CheckoutForm from '../../components/Payment/CheckoutForm';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import SelectSeats from '../../components/Payment/SelectSeats';
import styled from '@emotion/styled';
import { useLocation } from 'react-router';
// import { Small } from '../../components/styles/SmallText';
import Button from '../../components/CustomButton';
import { PlanType } from '../Organization/ViewOrganization';
import toast from 'react-hot-toast';
import { stripePromise } from '../..';

type PageMode = 'seats' | 'card';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// const CheckoutForm = () => {
//   return (
//     <form>
//       <PaymentElement />
//       <button>Submit</button>
//     </form>
//   );
// };

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const PlanText = styled.span`
  color: hsla(120, 1%, 68%, 1);
`;

function NewPaymentPage() {
  const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
  const { state: RouterState } = useLocation();
  const [pageMode, setPageMode] = useState<PageMode>(paymentIntent ? 'card' : 'seats');
  const [clientSecret, setClientSecret] = useState('');
  const [monthlyQuantity, setMonthlyQuantity] = useState<number>(
    RouterState.plan === 'month' ? RouterState.seatvalue : 0
  );
  const [yearlyQuantity, setYearlyQuantity] = useState<number>(RouterState.plan === 'year' ? RouterState.seatvalue : 0);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AppContext);
  const getPlanType = (plan) => {
    if (plan === 'month') {
      return 'Monthly';
    } else if (plan === 'year') {
      return 'Yearly';
    }
  };

  const increaseSeatQuantity = (type: PlanType) => {
    if (type === 'month') {
      setMonthlyQuantity((monthlyQuantity) => monthlyQuantity + 1);
    } else if (type === 'year') {
      setYearlyQuantity(yearlyQuantity + 1);
    }
  };
  const decreaseSeatQuantity = (type: PlanType) => {
    if (type === 'month' && monthlyQuantity > 0) {
      setMonthlyQuantity((monthlyQuantity) => monthlyQuantity - 1);
    } else if (type === 'year' && yearlyQuantity > 0) {
      setYearlyQuantity(yearlyQuantity - 1);
    }
  };

  const createSubscription = async () => {
    if (RouterState.plan === 'month') {
      if (monthlyQuantity === 0) {
        toast.error('Please Select Seats.');
        return;
      }
    } else if (RouterState.plan === 'year') {
      if (yearlyQuantity === 0) {
        toast.error('Please Select Seats.');
        return;
      }
    }
    const toastId = toast.loading('Loading...');
    setLoading(true);
    axiosInstance
      .post(`/payment/create-subscription`, {
        organizationId: state.orgId,
        email: state.email,
        name: state.fullName,
        price: RouterState.plan === 'month' ? process.env.REACT_APP_MONTHLY : process.env.REACT_APP_YEARLY,
        quantity: RouterState.plan === 'month' ? monthlyQuantity : yearlyQuantity
      })
      .then((res) => {
        console.log('Response of Subscription APi', res);
        setClientSecret(res?.data?.clientSecret);
        toast.dismiss(toastId);
        setPageMode('card');
        setLoading(false);
      })
      .catch((error) => {
        console.log('ERROR', error);
        toast.dismiss(toastId);
        toast.error('Something went wrong try again.');
        setLoading(false);
      });
  };

  const options = {
    clientSecret
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>
              Platform Suite Subscription - <PlanText>{getPlanType(RouterState.plan)}</PlanText>
            </PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button disabled={loading} onClick={createSubscription}>
            Add Payment Method
          </Button>
        </TitleRight>
      </Header>
      <PageContainer>
        {pageMode === 'seats' ? (
          <SelectSeats
            increaseSeatQuantity={increaseSeatQuantity}
            decreaseSeatQuantity={decreaseSeatQuantity}
            monthlyQuantity={monthlyQuantity}
            yearlyQuantity={yearlyQuantity}
            routerState={RouterState}
            setClientSecret={setClientSecret}
          />
        ) : null}
        {pageMode === 'card' && clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        )}
      </PageContainer>
    </>
  );
}

export default NewPaymentPage;
