import * as yup from 'yup';
// const regex_OnlyNumber = /^[^a-zA-Z]*$/;
const regex_WithHyphens = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

const ProjectTeamValidation = yup.object().shape({
  organizationName: yup
    .string()
    .min(2, 'Organization name is too short.')
    .required('Please enter a name.')
    .max(500, 'Organization name is too long.'),
  fieldOfWork: yup
    .string()
    .min(2, 'Field of work is too short.')
    .max(500, 'Field of work is too long.')
    .required("Please enter the team's field of work."),
  phone: yup
    .string()
    .matches(regex_WithHyphens, {
      message: 'Please enter a valid phone number.'
    })
    .min(10, 'Please enter a valid phone number.')
    .max(13, 'Please enter a valid phone number.')
    .required('Please enter a phone number.'),
  emailId: yup.string().required('Please enter the emailId.').email('Please enter a valid email'),
  address: yup
    .string()
    .required('Please enter the address')
    .min(2, 'Address is too short.')
    .max(500, 'Address cannot be longer than 500 characters.')
});

export default ProjectTeamValidation;
