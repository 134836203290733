import * as yup from "yup"


const AddProjectMemberValidations = yup.object().shape({
  username:
    yup.object().shape({
      label: yup.string(),
      value: yup.string().required('Please select a user')
    })
      .nullable()
  // .required('Please select a user')
  ,
  userType:
    yup
      .string()
      .required('Please select a user type. (Admin or User)'),
  relationship:
    yup
      .string()

})

export default AddProjectMemberValidations