import styled from '@emotion/styled';
import CustomAvatar from '../../../../../components/TableComponents/CustomAvatar';
import AvatarTags from '../../../../../components/AvatarTags';
import LinkDisplay from '../../../../../components/LinkDisplay';
import { ReactComponent as IconTaskNotAck } from '../../../../../assets/icons/icon-task.svg';
import { ReactComponent as IconTaskAck } from '../../../../../assets/icons/icon-task-ack.svg';
import { Label } from '../../../../../components/styles/Label';
import {
  AboutTaskStyles,
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ChoicesWrapper,
  DateWrapper,
  InputWrapper
} from '../../../../../components/styles/FormStyles';
import { DescriptionText } from '../../../../Library/FilesView/Files/ViewFile/FileInformation/AboutFile';
import { TaskDetails } from '../../CreateTask';
import { DateText } from '../../../../Workspaces/Registers/CreateNewDynamicDocumentInRegister';
import { Small } from '../../../../../components/styles/SmallText';
import { editTaskOptions, priorityOptions } from '../../../../../constants/DropDownOptions';
import { getValue } from '../../../../../components/Tasks';
import { statusStyles } from '../../../../../components/styles/StatusStyles';
import Select from 'react-select';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../../constants/LoaderDefaultOptions';
import { getFullNameById } from '../../../../../lib/getFullName';
import { getDate } from '../../../../../lib/getDate';
import { useGetTask } from '../../../../../hooks/features/tasks/useGetTask';
// import { useContext, useEffect } from 'react';
// import { useLocation, useParams } from 'react-router-dom';
// import { useQuery, useMutation } from '@apollo/client';
// import {
//   // updateTaskMutation,
//   UPDATE_STATUS_TASK_MUTATION
// } from '../../../../../graphql/operations/Mutations/Tasks/TaskMutations';
// import { LIST_PROJECTS_QUERY } from '../../../../../graphql/operations/Queries/Projects/ProjectQueries';
// import { SINGLE_TASK_QUERY } from '../../../../../graphql/operations/Queries/Tasks/TaskQueries';
// import { LIST_WORKSPACE_QUERY } from '../../../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
// import { AppContext } from '../../../../../AppContext';
// import { useDispatch, useSelector } from 'react-redux';
// import { postDashboardApi } from '../../../../../reducers/dashboard';
// import axiosInstance from '../../../../../axiosInstance';
// import toast from 'react-hot-toast';
// import { ErrorPage } from "../../pages/Others/ErrorPage";

// const Container = styled.div`
//   padding-left: 1.625rem;
//   padding-top: 2.68rem;
//   display: flex;
//   flex-direction: row;
//   gap: 40px;
//   .row:first-of-type{
//     /* border: 1px solid blue; */
//     width: 60vw;
//     .box{
//       flex-direction: column;
//       width: 100%;
//     }
//   }
//   .box{
//     display: flex;
//     align-items: center;
//     align-items: baseline;
//     h4{
//       font-weight: 600;
//       color: var(--black);
//       margin-bottom: 0px;
//     }
//     /* p{
//       display: flex;
//       justify-content: flex-start;
//       font-weight: 400;
//       color: var(--black);
//       /* padding-left: 1.9rem; */
//       align-items: baseline;
//       margin-bottom: 0px;
//     } */
//     small{
//       /* padding-left: 4rem; */
//       color: var(--text-primary);
//     }
//     .link-group{
//       margin-top: 10px;
//       display: flex;
//       flex-direction: column;
//       gap: 10px;
//       width: 70%;
//       p{
//         margin: 0px;
//       }
//     }
//   }
//   .row:last-of-type{
//     /* border: 1px solid red; */
//     .box{
//       gap: 20px;
//       margin-bottom: 20px;
//     }
//   }
//   .assignedto{
//     display: flex;
//     /* justify-content: flex-start; */
//     flex-direction: column;
//     .assignedlist{
//       display: flex;
//       flex-direction: column;

//     }
//   }
// `;

const Text = styled.p`
  font-weight: 400;
  margin-top: 15px;
`;

const SameLineWrapper = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 20px;
  }
  padding-left: 1rem;
`;

export const AssignedToWrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  > * + * {
    //lobotomized owl selector
    margin-top: 10px;
  }
`;

export const AssignedList = styled.div`
  display: flex;
  .taskAckIcon:hover .new-tooltip {
    visibility: visible;
  }
  .new-tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1000;
    margin-top: 38px;
    margin-left: -28px;
  }
  > * + * {
    margin-left: 10px;
  }

  /* flex-direction: column; */
`;

function AboutTask() {
  // let { taskId } = useParams();
  // const dispatch = useDispatch();
  // const { data, error, loading } = useQuery(SINGLE_TASK_QUERY, {
  //   variables: { id: taskId },
  //   fetchPolicy: 'network-only'
  // });
  // const { state } = useContext(AppContext);
  // const location = useLocation();
  // const metaDataIds = useSelector((state) => state.dashboard.metaDataIds);
  // const usersLoading = useSelector((state) => state.orgMembers.loading);
  // const allUsers = useSelector((state) => state.orgMembers.users);
  // const ddData = useSelector((state) => state.dashboard.dashboardData);

  // const ack = location?.state?.isAcknowledged || false;

  // const {
  //   data: projects,
  //   loading: projectLoading,
  //   error: projectError
  // } = useQuery(LIST_PROJECTS_QUERY, {
  //   variables: {
  //     noValidate: true
  //   }
  // });
  // const {
  //   data: workspaces,
  //   loading: workspaceLoading,
  //   error: workspaceError
  // } = useQuery(LIST_WORKSPACE_QUERY, {
  //   variables: {
  //     noValidate: true
  //   }
  // });

  // const [updateTask] = useMutation(UPDATE_STATUS_TASK_MUTATION, {
  //   fetchPolicy: 'no-cache'
  // });
  // const getProjectName = (id: string) => {
  //   let found = projects?.list_ProjectItems._ProjectItems.find((project) => project._id === id);
  //   if (found) {
  //     return found.name;
  //   }

  //   return 'PERSONAL';
  // };

  // const getWorkspaceName = (id: string) => {
  //   let found = workspaces?.list_WorkspaceItems?._WorkspaceItems?.find((workspace) => workspace._id === id);
  //   if (found) {
  //     return found.name;
  //   }
  //   return 'PERSONAL';
  // };

  // const handleStatusChange = (statusValue: string, id: string) => {
  //   let messageType = statusValue === 'done' ? 'completed' : 'status';
  //   let actionType = statusValue === 'done' ? 'completed' : 'status';
  //   let userToNotify = actionType === 'completed' ? data?.get_Task?.assignee : data?.get_Task?.assignedTo;
  //   let variables =
  //     userToNotify !== state?.userId
  //       ? {
  //         status: statusValue,
  //         id: id,
  //         projectId: data?.get_Task?.projectId,
  //         type: 'workspace',
  //         typeId: data?.get_Task?.workspaceId,
  //         notificationData: {
  //           actionType,
  //           messageType,
  //           taskName: data?.get_Task?.title,
  //           dueDate: data?.get_Task?.dueDate,
  //           workspaceName: getWorkspaceName(data?.get_Task?.typeId),
  //           projectName: getProjectName(data?.get_Task?.projectId),
  //           projectId: data?.get_Task?.projectId,
  //           workspaceId: data?.get_Task?.typeId,
  //           organizationId: state?.orgId,
  //           userToNotify: data?.get_Task?.assignedTo,
  //           host: window.location.origin
  //         }
  //       }
  //       : {
  //         status: statusValue,
  //         id: id,
  //         projectId: data?.get_Task?.projectId,
  //         type: 'workspace',
  //         typeId: data?.get_Task?.workspaceId
  //       };
  //   updateTask({
  //     variables: variables
  //   });
  // };

  // const handlePriorityChange = async (priority, id: string) => {
  //   updateTask({
  //     variables: {
  //       priority: priority,
  //       id: id
  //     }
  //   });
  // };

  // useEffect(() => {
  //   let obj = {
  //     title: data?.get_Task?.title,
  //     status: data?.get_Task?.status,
  //     dueDate: data?.get_Task?.dueDate
  //   };
  //   return () => {
  //     // if (metaDataIds.TASKS.length === 0 || taskId !== metaDataIds.TASKS[0].id)

  //     // let TASKS = [...metaDataIds.TASKS]
  //     // TASKS.unshift({ id: taskId })
  //     if (data?.get_Task) {
  //       dispatch(
  //         postDashboardApi({
  //           type: 'metaData',
  //           moduleType: 'TASKS',
  //           orgId: state.orgId,
  //           metaDataId: taskId,
  //           metaData: { _id: taskId, ...obj }
  //         })
  //       );
  //     }
  //   };
  // }, [data]);
  const {
    data,
    error,
    loading,
    ack,
    allUsers,
    getProjectName,
    getWorkspaceName,
    handlePriorityChange,
    handleStatusChange,
    projectError,
    projectLoading,
    usersLoading,
    workspaceError,
    workspaceLoading,
    state
  } = useGetTask();

  if (error || projectError || workspaceError) return <p>Error</p>;
  if (loading || projectLoading || workspaceLoading || usersLoading)
    return <Lottie options={defaultOptions} width={400} height={500} />;

  const { get_Task } = data;

  return (
    <TaskDetails>
      <AboutTaskStyles>
        <div>
          <InputWrapper>
            <Label>Task Title</Label>
            <Text>{get_Task.title}</Text>
          </InputWrapper>
          <SameLineWrapper>
            <Label>Project</Label>
            <Text>{getProjectName(get_Task.projectId)}</Text>
            <Label>Workspace</Label>
            <Text>{getWorkspaceName(get_Task.typeId)}</Text>
          </SameLineWrapper>
          <InputWrapper>
            <Label>Description</Label>
            <DescriptionText>{get_Task.description}</DescriptionText>
          </InputWrapper>
          {get_Task?.links?.length > 0 && (
            <InputWrapper>
              <Label>Links</Label>
              {get_Task.links?.map((link: string, index: number) => {
                return <LinkDisplay key={index} link={link} />;
              })}
            </InputWrapper>
          )}
        </div>
        <div>
          <ChoicesWrapper>
            <Label>Status</Label>
            <Select
              name="status"
              options={editTaskOptions}
              defaultValue={getValue(editTaskOptions, get_Task.status)}
              styles={statusStyles}
              isSearchable={false}
              onChange={(status: any) => {
                if (status) {
                  handleStatusChange(status.value, get_Task._id);
                }
              }}
              isDisabled={get_Task.isAcknowledged ? false : true}
            />
            <Label>Priority</Label>
            <Select
              name="priority"
              options={priorityOptions}
              defaultValue={getValue(priorityOptions, get_Task.priority)}
              styles={statusStyles}
              isSearchable={false}
              onChange={(priority) => {
                if (priority) {
                  handlePriorityChange(priority.value, get_Task._id, get_Task.type, get_Task.typeId);
                }
              }}
              isDisabled={get_Task.isAcknowledged ? false : true}
            />
          </ChoicesWrapper>
          <DateWrapper>
            <Label>Due Date</Label>
            {/* <DateText>{getDate(get_Task?.dueDate)}</DateText> */}
            <DateText>{get_Task?.dueDate}</DateText>

            <CalendarIconPlacer isEditable={false}></CalendarIconPlacer>
          </DateWrapper>
          <AssigneeWrapper>
            <Label>Reporter</Label>
            <AssigneeSeparator>
              <AvatarStyles>
                <CustomAvatar name={getFullNameById(get_Task?.assignee, allUsers, state.fullName)} size={20} />
              </AvatarStyles>
              <Small>{getDate(get_Task.createdAt)} </Small>
            </AssigneeSeparator>
          </AssigneeWrapper>
          <AssignedToWrapper>
            <Label>Assigned To</Label>
            <AssignedList>
              {get_Task.isAcknowledged || ack ? (
                <span className="taskAckIcon">
                  <IconTaskAck height="28" width="28"></IconTaskAck>
                  <span className="new-tooltip">Task is acknowledged</span>
                </span>
              ) : (
                <span className="taskAckIcon">
                  <IconTaskNotAck height="28" width="28"></IconTaskNotAck>
                  <span className="new-tooltip">Task is not acknowledged</span>
                </span>
              )}{' '}
              <AvatarTags name={getFullNameById(get_Task.assignedTo, allUsers, state.fullName)} />
            </AssignedList>
          </AssignedToWrapper>
        </div>
      </AboutTaskStyles>
    </TaskDetails>
  );
}

export default AboutTask;
