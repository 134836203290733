// import { Label } from 'aws-amplify-react'
// import { useFormik } from 'formik'
import Select from 'react-select';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/CloseIcon.svg';
import Button from '../../../../components/CustomButton';
import { InputField } from '../../../../components/Reusable/NewFormik/Input1';
import { DarkGreenButton } from '../../../../components/styles/ButtonStyles';
import { FieldSet, InputWrapper, SameLineWrapper } from '../../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { taskDropdownStyles } from '../../../../components/styles/TaskDropdownStyles';
import { Container } from '../../ProjectViews/CreateProject';
import styled from '@emotion/styled';
import useAddProjectMembers, { userTypes } from '../../../../hooks/features/projects/useAddProjectMembers';
import { DropdownErrorText, ErrorText } from '../../../../components/styles/Text';
import { userTypeOptions } from '../../../../constants/userTypeOptions';
import { Label } from '../../../../components/styles/Label';
// import { options } from '../../../../constants/DropDownOptions'

const FormWrapper = styled.div`
  button {
    margin-left: 15px;
    margin-top: 10px;
  }
  .relationship {
    display: flex;
    align-items: center;
    margin-top: 10px;
    /* flex-direction: column; */
  }
  .relationship-input {
    display: flex;
    flex-direction: column;
    input {
      width: 35vw;
    }
  }
  .close-icon {
    margin-top: 30px;
    margin-left: 10px;
    cursor: pointer;
  }
`;

function AddEditProjectMembers() {
  // let userNameRef = null
  const {
    formik,
    setShowAddRelationship,
    showAddRelationship,
    orgUsers,
    submitting,
    isEdit,
    getUsername,
    goToProjectMembersPage
  } = useAddProjectMembers();
  // let { usernameRef } = useAddProjectMembers()
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon handlenavigate={goToProjectMembersPage} />
            <NewTitleLeft>
              <PageTitle>{isEdit ? 'Edit Project Members' : 'Add Project Members'}</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={submitting} type="submit">
              {isEdit ? 'Edit Member' : 'Add Member'}
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <FieldSet>
            <FormWrapper>
              <InputWrapper>
                <Label>Name</Label>
                <Select
                  name="username"
                  options={orgUsers}
                  isSearchable={true}
                  isDisabled={isEdit}
                  disabled={submitting}
                  // scontrolShouldRenderValue={false}
                  className="member-options"
                  menuPortalTarget={document.body}
                  value={formik.values.username}
                  defaultValue={getUsername(formik.values.username.value)}
                  // defaultValue={orgUsers[1]}
                  styles={{
                    // ...customStyles,
                    menu: (styles) => ({ ...styles, minWidth: '500px' }),
                    control: (base) => ({ ...base, minWidth: '60vw' }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  // getOptionLabel={(user) => user?.fullName}
                  // getOptionValue={(user) => user?.id}
                  onChange={(option) => {
                    formik.setFieldValue('username', option);
                  }}
                />
                {formik.touched[`username`] && formik.errors['username'] ? (
                  <ErrorText>{formik.errors.username.value}</ErrorText>
                ) : null}
              </InputWrapper>
              <SameLineWrapper style={{ marginLeft: '15px' }}>
                <Label>User Type</Label>
                <Select
                  name="userType"
                  styles={taskDropdownStyles}
                  menuPortalTarget={document.body}
                  options={userTypeOptions}
                  defaultValue={
                    userTypeOptions.find((option) => option.value === formik.values.userType) || userTypeOptions[1]
                  }
                  disabled={submitting}
                  onChange={(option: {
                    id: number;
                    value: userTypes.ADMIN | userTypes.USER;
                    label: userTypes.ADMIN | userTypes.USER;
                  }) => {
                    formik.setFieldValue('userType', option?.value);
                  }}
                />
              </SameLineWrapper>
              {formik.touched['userType'] && formik.errors['userType'] ? (
                <DropdownErrorText>{formik.errors['userType']}</DropdownErrorText>
              ) : null}
              {!showAddRelationship && (
                <DarkGreenButton disabled={formik.isSubmitting} onClick={() => setShowAddRelationship(true)}>
                  + Add Relation
                </DarkGreenButton>
              )}
              {showAddRelationship && (
                <InputWrapper>
                  <div className="relationship">
                    <div className="relationship-input">
                      <InputField
                        label="Project Relationship"
                        type="text"
                        name="relationship"
                        onChange={formik.handleChange}
                        value={formik.values.relationship}
                        touched={formik.touched.relationship}
                        errors={formik.errors.relationship}
                        disabled={submitting}
                      />
                    </div>
                    <div>
                      {!isEdit && (
                        <CloseIcon
                          disabled={submitting}
                          className="close-icon"
                          height="25px"
                          width="25px"
                          onClick={() => setShowAddRelationship(false)}
                        />
                      )}
                    </div>
                  </div>
                </InputWrapper>
              )}
            </FormWrapper>
          </FieldSet>
        </Container>
      </form>
    </>
  );
}

export default AddEditProjectMembers;
