// import React from "react";
import styled from '@emotion/styled';
import { ReactComponent as NotebookIcon } from '../../assets/icons/notebook.svg';
import { getDate } from '../../lib/getDate';
import { getTime } from '../../lib/getTime';
// import { useNavigate } from "react-router";

const ItemStyles = styled.div`
  display: flex;
  border-bottom: 1px solid var(--table-border);
  justify-content: space-between;
  cursor: pointer;
  :hover {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
  }
  .note-info,
  .note-date {
    display: flex;
    align-items: center;
  }
  .note-info {
    padding-left: 2.6rem;
    .note-title {
      margin-left: 2.01rem;
    }
  }
  .note-date {
    color: var(--text-light);
    padding-right: 7.6rem;
    .date {
      margin-right: 20px;
    }
  }
`;

function NoteItem({ data, clickHandler }: { data: any; clickHandler: (id: string) => void }) {
  return (
    <ItemStyles onClick={() => clickHandler(data._id)}>
      <div className="note-info">
        <NotebookIcon />
        <p className="note-title">{data.title}</p>
      </div>
      <div className="note-date">
        <p className="date">{getDate(data?.createdAt)}</p>
        <p className="time">{getTime(data?.createdAt)}</p>
      </div>
    </ItemStyles>
  );
}

export default NoteItem;
