// import Button from '../components/CustomButton';
// import { DarkButton, LightButton } from '../components/styles/ButtonStyles';
// import CloseIcon from '../assets/SvgComponents/CloseIcon.tsx';
// import PlusIcon from '../assets/SvgComponents/PlusIcon.tsx';
// import FileTag from "../components/FileTag";
import capitalizeFirstLetter from '../lib/CapitaliseFirstLetter';
export const COLUMNS = [
  {
    Header: 'RegisterName',
    accessor: 'name'
  },
  {
    Header: 'Type',
    accessor: 'registerType',
    Cell: ({ cell: { value } }) => {
      return <>{capitalizeFirstLetter(value)}</>;
    }
  },
  // {
  //   Header: "FileFormats",
  //   accessor: "fileFormats",
  //   Cell: ({ cell: { value } }) => {
  //     return <FileTag>{value[0].toUpperCase()}</FileTag>;
  //   },
  // },
  {
    Header: 'Initiator',
    accessor: 'initiatorRole'
  },
  {
    Header: 'Responder',
    accessor: 'responderRole'
  }
];

export const SELECTEDCOLUMNS = [
  {
    id: 'RegisterName',
    accessor: 'name'
  },
  {
    id: 'Type',
    accessor: 'registerType',
    Cell: ({ cell: { value } }) => {
      return <>{capitalizeFirstLetter(value)}</>;
    }
  },

  // {
  //   id: "FileFormat",
  //   accessor: "fileFormats",
  //   Cell: ({ cell: { value } }) => {
  //     return <FileTag>{value[0].toUpperCase()}</FileTag>;
  //   },
  // },
  {
    id: 'Initiator',
    accessor: 'initiatorRole'
  },
  {
    id: 'Responder',
    accessor: 'responderRole'
  }
];
