import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import { deleteCollectionMutation } from '../../../graphql/operations/Mutations/Library/FileMutations';
import { LIST_FILES_QUERY, SINGLE_COLLECTION } from '../../../graphql/operations/Queries/Library/FilesQueries';
import { useToggle } from '../../../lib/UseToggle';

function useGetCollectionFileItems() {
  let { collectionId } = useParams();
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [isOpen, toggle] = useToggle(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const userId = state.userId;
  const {
    data: documentData,
    error,
    loading
  } = useQuery(LIST_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${userId}`,
      recordStatus: 0
    },
    fetchPolicy: 'network-only'
  });
  const {
    data: CollectionData,
    error: collectionError,
    loading: collectionLoading
  } = useQuery(SINGLE_COLLECTION, {
    variables: {
      operation: 'query',
      id: collectionId
    },
    fetchPolicy: 'network-only'
  });

  const [deleteCollection] = useMutation(deleteCollectionMutation);

  const addFiles = () => {
    navigate(`/collections/${CollectionData?.get_Collection?._id}/add`);
  };
  const editFiles = () => {
    navigate(`/collections/${CollectionData?.get_Collection?._id}/edit`);
  };

  async function removeCollection() {
    setIsDeleting(true);
    const toastId = toast.loading('Deleting Collection');
    try {
      let response = await deleteCollection({
        variables: {
          id: collectionId,
          noValidate: true,
          libraryType: 'notesAndCollections'
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Collection deleted.');
        setIsDeleting(false);
        navigate('/Files');
      }
    } catch (error) {
      console.error('Error in deleting collection.', error);
      setIsDeleting(false);
      toast.dismiss(toastId);
      toast.error('Error in deleting collection.Please try again.');
    }
  }

  const handleNavigate = () => {
    navigate('/files');
  };

  return {
    loading,
    error,
    removeCollection,
    addFiles,
    editFiles,
    isDeleting,
    isOpen,
    toggle,
    documentData,
    CollectionData,
    collectionError,
    collectionLoading,
    handleNavigate
  };
}

export default useGetCollectionFileItems;
