import styled from '@emotion/styled';
import * as React from 'react';
import { useNavigate } from 'react-router';

const Span = styled.span`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding-right: 2px;
  /* margin: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
  :hover {
    /* filter: brightness(1.2); */
    background-color: #e5e5e5;
    cursor: pointer;
  }
`;

type IconProps = {
  handlenavigate?: () => void;
};

function BackIcon(props: React.SVGProps<SVGSVGElement> & IconProps) {
  let navigate = useNavigate();
  return (
    <>
      <Span onClick={props.handlenavigate ? () => props.handlenavigate() : () => navigate(-1)}>
        <svg
          width="11"
          height="17"
          viewBox="0 0 11 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // {...props}
        >
          <path d="M9.41463 1L2 8.5L9.41463 16" stroke="#93979F" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
      </Span>
    </>
  );
}

export default BackIcon;
