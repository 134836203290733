import styled from '@emotion/styled';
import React from 'react';
import Lottie from 'react-lottie';

import BackIcon from '../../../assets/SvgComponents/BackIcon';
import Button from '../../../components/CustomButton';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import Table from '../../../components/Table';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import useGetProjectMembers from '../../../hooks/features/projects/useGetProjectMembers';

import { ErrorPage } from '../../Others';
import { Container } from '../ProjectViews/CreateProject';

const MembersContainer = styled(Container)`
  padding-top: 1.65rem;
  padding-right: 54px;
`;

function ProjectMembers() {
  const { columns, data, error, goToAddMembers, loading, loadingIsProjectAdmin, goToProjectsPage } =
    useGetProjectMembers();
  if (loading || loadingIsProjectAdmin) return <Lottie options={defaultOptions} width={400} height={500} />;

  if (error) return <ErrorPage />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={() => goToProjectsPage()} />
          <NewTitleLeft>
            <PageTitle>Project Members</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button onClick={goToAddMembers}>Add Members</Button>
        </TitleRight>
      </Header>
      <MembersContainer>
        <Table columns={columns} data={data} />
      </MembersContainer>
    </>
  );
}

export default ProjectMembers;
