import styled from '@emotion/styled';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import Button from '../CustomButton';
import { InputField } from '../Reusable/NewFormik/Input1';
import { DarkGreenButton, DarkGreenCircleButton, LightBlueButton } from '../styles/ButtonStyles';
import { DynamicFormInputWrapper, InputWrapper } from '../styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../styles/Header';
import { ReactComponent as EyeIcon } from '../../assets/icons/EyeIcon.svg';
import { Label } from '../styles/Label';
// import React, {  } from 'react';
import Modal from '../Modal';
import { ModalBody, ModalContainer, ModalTitle } from '../styles/ModalStyles';
import {
  ButtonWrapper,
  TemplateContainer
} from '../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import ChooseFieldTemplateTile from './ChooseFieldTemplateFile';

import useCreateMeetingNoteTemplate from '../../hooks/features/meetingNotes/useCreateMeetingNoteTemplate';

const FormContainer = styled.div`
  border: 24px solid #f6f5f5;
  input[type='text'],
  input[type='email']:disabled {
    border: none !important;
    background-color: rgba(172, 173, 172, 0.2);
  }
  fieldset {
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    legend {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }
`;
export const ImagePlaceHolder = styled.div`
  background-color: #f3f3f3;
  width: 259px;
  height: 172px;

  position: relative;
  img {
    max-width: 259px;
    max-height: 172px;
  }
  button {
    display: none;
  }
  &:hover {
    button {
      display: unset;
    }
  }
`;

export const ActionContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  svg {
    vertical-align: middle;
    margin-right: 4px;
    margin-bottom: 2px;
  }

  button:nth-of-type(2) {
    margin-left: 20px;
  }
`;
const ChooseFieldPlaceHolder1 = styled.div`
  background: #8e8e8e;
  opacity: 0.2;
  border-radius: 5px;
  padding: 6px 20px;
  gap: 10px;
  width: 365px;
  height: 34px;
  margin: 10px 0px;
`;
const ChooseFieldPlaceHolder2 = styled.div`
  background: #f4f4f4;
  border-radius: 5px;
  padding: 6px 20px;
  gap: 10px;
  width: 280px;
  height: 34px;
  margin: 10px 0px;
  margin-left: -80px;
`;
const ChooseFieldPlaceHolder3 = styled.div`
  background: #f4f4f4;
  border-radius: 5px;
  padding: 6px 20px;
  gap: 10px;
  width: 280px;
  height: 21px;
  margin: 10px 0px;
  margin-left: -80px;
`;
const ChooseFieldPlaceHolderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .textStyle {
    margin: 10px 0px 20px -80px;
    color: '#8d8d8d';
  }
  .addFieldsButton {
    margin: 10px 0px 20px -80px;
  }
  .buttonStyle {
    margin: 20px;
  }
`;
export const DynamicFormInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .contentStyle {
    font-size: 18px;
  }
`;
const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
`;
const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0px;
  font-size: 16px;
`;
function CreateMeetingNoteTemplate() {
  const {
    isOpen,
    hiddenFileInput,
    isOpenSaveTemplate,
    submitting,
    handleImageChange,
    handleClick,
    handleClose,
    handleConfirm,
    handleImageUpload,
    handleRemove,
    handleSaveTemplateClick,
    handleSaveTemplateConfirm,
    handleSaveTemplateNameChange,
    handleSelectedField,
    meetingNotesChooseFields,
    formik,
    confirmField,
    saveTemplateToggle,
    selectedField,
    templateName,
    toggle
  } = useCreateMeetingNoteTemplate();

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Create Note Template</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button onClick={handleSaveTemplateClick}>Save as Template</Button>
          </TitleRight>
        </Header>
        <FormContainer>
          <InputWrapper>
            <InputField
              label="Notes Name"
              type="text"
              name="notesName"
              value={formik.values.notesName}
              onChange={formik.handleChange}
              touched={formik.touched.notesName}
              errors={formik.errors.notesName}
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              disabled={true}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Brand Logo</Label>
            <ImagePlaceHolder>
              {formik.values.brandLogo && <img src={formik.values.brandLogo} alt="brand-logo" />}
              <ActionContainer>
                <DarkGreenButton type="button" onClick={handleImageUpload}>
                  Add Logo
                </DarkGreenButton>
                <LightBlueButton type="button">
                  <EyeIcon />
                  Hide
                </LightBlueButton>
              </ActionContainer>
            </ImagePlaceHolder>
            <input
              type="file"
              accept="image/*"
              name="brandLogo"
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              onChange={(event) => {
                handleImageChange(event);
              }}
            />
          </InputWrapper>
          <fieldset>
            <legend>Organization Contact</legend>
            <InputWrapper>
              <InputField
                label="Organization Name"
                type="text"
                name="notesName"
                value={formik.values.organizationName}
                onChange={formik.handleChange}
                touched={formik.touched.organizationName}
                errors={formik.errors.organizationName}
                placeholder="AntStack"
                disabled={true}
              />
            </InputWrapper>
            <InputWrapper>
              <InputField
                label="Phone"
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                touched={formik.touched.phone}
                errors={formik.errors.phone}
                placeholder="99-222-444"
                disabled={true}
              />
            </InputWrapper>
            <InputWrapper>
              <InputField
                label="Email"
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                touched={formik.touched.email}
                errors={formik.errors.email}
                placeholder="abc@gmail.com"
                disabled={true}
              />
            </InputWrapper>
            <InputWrapper>
              <InputField
                label="Address"
                type="text"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                touched={formik.touched.address}
                errors={formik.errors.address}
                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                disabled={true}
              />
            </InputWrapper>
          </fieldset>
        </FormContainer>
        <FormContainer>
          {confirmField.length === 0 ? (
            <>
              <ChooseFieldPlaceHolderDiv>
                <ChooseFieldPlaceHolder1 />
                <ChooseFieldPlaceHolder2 />
                <ChooseFieldPlaceHolder3 />

                <div className="textStyle">Select form fields to add to this notes</div>
                <DarkGreenButton type="button" onClick={handleClick} className="addFieldsButton">
                  Add fields
                </DarkGreenButton>
              </ChooseFieldPlaceHolderDiv>
            </>
          ) : (
            <>
              {confirmField.map((item, i) => {
                // console.log('item i', i);
                switch (item.formLabel || item.formHeader) {
                  case 'Date':
                    return (
                      <DynamicFormInputContainer>
                        <DynamicFormInputWrapper>
                          <InputField
                            contentEditable="true"
                            label="Date"
                            type="date"
                            name="date"
                            touched={undefined}
                            errors={undefined}
                            disabled={true}
                            key={i}
                          />
                        </DynamicFormInputWrapper>
                        <DarkGreenCircleButton onClick={() => handleRemove(item.fieldId)}>
                          &#8213;
                        </DarkGreenCircleButton>
                      </DynamicFormInputContainer>
                    );
                  case 'Contact':
                    // code block
                    return (
                      <DynamicFormInputContainer>
                        <DynamicFormInputWrapper>
                          <fieldset style={{ margin: '0px' }}>
                            <legend className="contentStyle" key={i}>
                              Contact
                            </legend>
                            <InputWrapper>
                              <InputField
                                label="Organization Name"
                                type="text"
                                name="notesName"
                                placeholder="AntStack"
                                disabled={true}
                                touched={undefined}
                                errors={undefined}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <InputField
                                label="Phone"
                                type="text"
                                name="phone"
                                placeholder="99-222-444"
                                disabled={true}
                                touched={undefined}
                                errors={undefined}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <InputField
                                label="Email"
                                type="email"
                                name="email"
                                placeholder="abc@gmail.com"
                                disabled={true}
                                touched={undefined}
                                errors={undefined}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <InputField
                                label="Address"
                                type="text"
                                name="address"
                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                disabled={true}
                                touched={undefined}
                                errors={undefined}
                              />
                            </InputWrapper>
                          </fieldset>
                        </DynamicFormInputWrapper>
                        <DarkGreenCircleButton type="button" onClick={() => handleRemove(item.fieldId)}>
                          &#8213;
                        </DarkGreenCircleButton>
                      </DynamicFormInputContainer>
                    );
                  case 'Address':
                    return (
                      <DynamicFormInputContainer>
                        <DynamicFormInputWrapper>
                          <InputField
                            label="Address"
                            type="text"
                            name="address"
                            touched={undefined}
                            errors={undefined}
                            disabled={false}
                            key={i}
                            placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                          />
                        </DynamicFormInputWrapper>
                        <DarkGreenCircleButton onClick={() => handleRemove(item.fieldId)}>
                          &#8213;
                        </DarkGreenCircleButton>
                      </DynamicFormInputContainer>
                    );

                  case 'Project':
                    return (
                      <DynamicFormInputContainer>
                        <DynamicFormInputWrapper>
                          <InputField
                            label="Project"
                            type="text"
                            name="project"
                            touched={undefined}
                            errors={undefined}
                            disabled={true}
                            key={i}
                            placeholder="Lorem Ipsum is Project"
                          />
                        </DynamicFormInputWrapper>
                        <DarkGreenCircleButton onClick={() => handleRemove(item.fieldId)}>
                          &#8213;
                        </DarkGreenCircleButton>
                      </DynamicFormInputContainer>
                    );
                  case 'Subject':
                    return (
                      <DynamicFormInputContainer>
                        <DynamicFormInputWrapper>
                          <InputField
                            label="Subject"
                            type="text"
                            name="subject"
                            touched={undefined}
                            errors={undefined}
                            disabled={true}
                            key={i}
                            placeholder="Lorem Ipsum is Subject"
                          />
                        </DynamicFormInputWrapper>
                        <DarkGreenCircleButton type="button" onClick={() => handleRemove(item.fieldId)}>
                          &#8213;
                        </DarkGreenCircleButton>
                      </DynamicFormInputContainer>
                    );
                  case 'Custom Field':
                    return (
                      <DynamicFormInputContainer>
                        <DynamicFormInputWrapper>
                          <InputField
                            label="Custom field"
                            type="text"
                            name="custom"
                            touched={undefined}
                            errors={undefined}
                            disabled={true}
                            key={i}
                          />
                        </DynamicFormInputWrapper>
                        <DarkGreenCircleButton type="button" onClick={() => handleRemove(item.fieldId)}>
                          &#8213;
                        </DarkGreenCircleButton>
                      </DynamicFormInputContainer>
                    );
                }
              })}
              <DarkGreenButton type="button" onClick={handleClick} style={{ margin: '16px' }}>
                Add fields
              </DarkGreenButton>
            </>
          )}
        </FormContainer>
      </form>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="1150px">
          <ModalTitle>Choose Field</ModalTitle>
          <ModalBody>
            <TemplateContainer style={{ paddingLeft: '0px' }}>
              {meetingNotesChooseFields.map((ChooseFields) => {
                return (
                  <ChooseFieldTemplateTile
                    key={ChooseFields.id}
                    selected={selectedField}
                    handleSelect={() => handleSelectedField(ChooseFields)}
                    template={ChooseFields}
                  >
                    {ChooseFields.formLabel || ChooseFields.formHeader}
                  </ChooseFieldTemplateTile>
                );
              })}
            </TemplateContainer>
            <ButtonWrapper>
              <Button type="button" onClick={handleClose} secondary>
                Cancel
              </Button>
              <Button type="button" onClick={handleConfirm}>
                Confirm
              </Button>
            </ButtonWrapper>
          </ModalBody>
        </ModalContainer>
      </Modal>

      <Modal open={isOpenSaveTemplate} onClose={saveTemplateToggle}>
        <ModalContainer width="530px">
          <ModalTitle>Note Template</ModalTitle>
          <ModalBody>
            <ContainerDiv>
              <TextDiv>Enter template name to publish it </TextDiv>
              <InputField
                type="text"
                name="name"
                value={templateName}
                onChange={handleSaveTemplateNameChange}
                disabled={submitting}
                touched={undefined}
                errors={undefined}
              />
            </ContainerDiv>
            <ButtonWrapper>
              <Button disabled={submitting} onClick={saveTemplateToggle} secondary>
                Cancel
              </Button>
              <Button disabled={submitting || templateName === ''} onClick={handleSaveTemplateConfirm}>
                Confirm
              </Button>
            </ButtonWrapper>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateMeetingNoteTemplate;
