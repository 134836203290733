import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { TooltipStyles } from '../../pages/Organization/ViewOrganization';

type TooltipProps = {
  children: React.ReactNode;
  tooltipText: String;
};

function Tooltip({ children, tooltipText }: TooltipProps) {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  return (
    <>
      <span ref={setTriggerRef}>{children}</span>
      {visible && (
        <TooltipStyles ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          {tooltipText}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </TooltipStyles>
      )}
    </>
  );
}

export default Tooltip;
