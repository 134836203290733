import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axiosInstance from '../../axiosInstance';

function UseGetIsProjectAdmin(projectId: string) {
  const { state } = useContext(AppContext);
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);
  const [loadingIsProjectAdmin, setLoading] = useState(false);
  useEffect(() => {
    console.log('EFFECT CALLED');
    const getProjectMembers = async () => {
      setLoading(true);
      await axiosInstance
        .get(`/project/members?projectId=${projectId}`)
        .then((res) => {
          let memberData = res.data.data.projectDirectory;
          let users = memberData.filter((user) => user.userId === state.userId);
          if (users.length > 0) {
            console.log('CURRENT USER', users[0]);
            let currentUser = users[0];
            if (currentUser.userType === 'admin') {
              setIsProjectAdmin(true);
            }
          }
        })
        .catch((error) => {
          setIsProjectAdmin(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getProjectMembers();
  }, [projectId, state.userId]);

  return {
    isProjectAdmin,
    loadingIsProjectAdmin
  };
}

export default UseGetIsProjectAdmin;
