import { Global, css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
        ${emotionNormalize}
        *,*:before,*:after {
          box-sizing: inherit;
        }
        :root {
          --text-secondary: #c37f0f;
          --text-primary: #6b6962;
          --bg-secondary: #ffedbe;
          --black: #1c1c1c;
          --red: #ff3b3b;
          --button-background: #00b292;
          --button-secondary: #098b74;
          --high-priority: #ff3c3c;
          --medium-priority: #feb629;
          --low-priority: #3493b1;
          --border-color: #e6e6e6;
          --table-border: #e5e5e5;
          --text-light: #93979f;
          --green: ##4c6d66;
          --transition-speed: 600ms;
          --error-background: #d14747;
          --p-colorPrimaryAlpha20: hsla(215, 24%, 20%, 25%);
          --p-colorBackgroundContrastAlpha08: rgba(0, 0, 0, 0.08);
          --focusBoxShadow: 0 0 0 3px var(--p-colorPrimaryAlpha20), 0 1px 1px 0 var(--p-colorBackgroundContrastAlpha08);
        }
        html {
          box-sizing: border-box;
        }
        body {
          padding: 0;
          margin: 0;
          font-size: 1rem;
          font-family: 'Open Sans', sans-serif;
          /* font-weight: 600; */
          min-height: 100%;
        }
        a {
          text-decoration: none;
        }
      `}
    />
  );
};
