import styled from '@emotion/styled';

export const ProjectNotesListStyles = styled.div`
  width: 90vw;
  margin: 0 auto;
  padding: 15px;
  tr td:first-of-type {
    min-width: 250px;
  }
`;

export const SearchInput = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  .input {
    visibility: hidden;
    opacity: 0;
    width: 334px;
    height: 37px;
    border: 1px solid #93979f;
    border-radius: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    padding: 20px;
    padding-left: 40px;
    padding-right: 100px;
    transition: 0.5s cubic-bezier(0.17, 0.67, 0.85, 0.26);
    color: #bebebe;
  }

  .input:focus {
    outline: none;
  }

  .show {
    visibility: visible;
    opacity: 1;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
  }

  .input-control {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    transition: visibility 0.5s ease-in 0.33s, opacity 0.5s ease-in;
  }

  .icon {
    position: absolute;
    left: 0px;
    transition: visibility 0.5s ease-in 0.33s, opacity 0.5s ease-in;
  }

  .select {
    position: absolute;
    right: 5px;
    width: 100px;
    font-size: 15px;
    border: 0;
  }
`;
export const selectStyles = {
  control: (base: any) => ({
    ...base,
    border: 0,
    boxShadow: 'none'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    color: isFocused ? 'white' : '#1C1C1C',
    background: isFocused ? '#2F3F48' : '#F3F3F3',
    whiteSpace: 'wrap',
    lineHeight: '25px'
  })
};
