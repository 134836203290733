import React, { useContext } from 'react';
import Loader from '../../../../components/Loader';
import ProjectList from '../../../../components/ProjectList';
import useListSharedProjects from '../../../../hooks/features/projects/useListSharedProjects';
import { ErrorPage } from '../../../Others';
import { EmptyDataContainer, ProjectsContainer } from '../Projects';
import { PageContainer } from './InternalProjectsListPage';

function SharedProjectsListPage() {
  const { sharedProjectList, error, loading, userDetails } = useListSharedProjects();

  if (error) {
    if (error.networkError?.message.includes('401') && userDetails.orgId === 'null')
      return (
        <ProjectsContainer>
          <div className="empty-data">
            <EmptyDataContainer>
              You have to be a part of an organization or create an organization before seeing a shared project.
            </EmptyDataContainer>
          </div>
        </ProjectsContainer>
      );
    else {
      return <ErrorPage />;
    }
  }
  if (loading) return <Loader />;
  return (
    <PageContainer>
      <ProjectsContainer>
        {userDetails.orgId === 'null' || !userDetails.orgId ? (
          <div className="empty-data">
            <EmptyDataContainer>
              You have to be a part of an organization or create an organization before creating a project.
            </EmptyDataContainer>
          </div>
        ) : sharedProjectList && sharedProjectList.list_ProjectItems._ProjectItems?.length === 0 ? (
          <div className="empty-data">
            <EmptyDataContainer>No shared projects.</EmptyDataContainer>
          </div>
        ) : (
          <ProjectList data={sharedProjectList?.list_ProjectItems._ProjectItems}></ProjectList>
        )}
      </ProjectsContainer>
    </PageContainer>
  );
}

export default SharedProjectsListPage;
