import styled from '@emotion/styled';
import { GroupExitMessage } from './ConversationStatusStyles';

function GroupExit() {
  return (
    <Container>
      <GroupExitMessage>
        You are not part of this group anymore and will no longer be able to send messages
      </GroupExitMessage>
    </Container>
  );
}

export default GroupExit;

const Container = styled.div`
  height: 120px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px 10px;
`;
