import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
interface Props {
  messageType: string;
  removedOrAddedUserId: string | undefined;
}

const InfoMessage = ({ messageType, removedOrAddedUserId }: Props) => {
  const users = useSelector((state: any) =>
    state?.orgMembers?.users?.map((u: any) => ({
      id: u.id,
      name: u.fullName,
      picture: u.profilePhoto
    }))
  );

  const user = users?.filter((user: any) => user.id === removedOrAddedUserId)[0];

  if (messageType === 'remove_user')
    return (
      <InfoStyles>
        <span>{user.name}</span> left the chat
      </InfoStyles>
    );
  else if (messageType === 'add_user')
    return (
      <InfoStyles>
        <span>{user.name}</span> joined the chat
      </InfoStyles>
    );
  else return null;
};

export default InfoMessage;

const InfoStyles = styled.div`
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  font-style: italic;
  color: #707070;
  span {
    font-weight: 600;
  }
`;
