import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Storage } from 'aws-amplify';
import Compress from 'browser-image-compression';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../../axiosInstance';
import Button from '../../../components/CustomButton';
import EditorComponent from '../../../components/Editor/Editor';
import Loader from '../../../components/Loader';
import {
  ActionContainer,
  DynamicFormInputContainer,
  ImagePlaceHolder
} from '../../../components/MeetingNotes/CreateMeetingNoteTemplate';
import RightSideBar from '../../../components/MeetingNotes/RightSideBar';
import DeleteNoteModal from '../../../components/Modals/MeetingNotes/DeleteNote';
import AttendeeListModal from '../../../components/Modals/MeetingNotes/PublishNoteModals/AttendeeListModal';
import SharedListModal from '../../../components/Modals/MeetingNotes/PublishNoteModals/SharedListModal';
import ReOpenNoteModal from '../../../components/Modals/MeetingNotes/ReopenNoteModal';
import SaveAndPublishModal from '../../../components/Modals/MeetingNotes/SaveAndPublishModal';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import { DarkGreenButton } from '../../../components/styles/ButtonStyles';
import { DynamicFormInputWrapper, InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import LiveConnectionContext from '../../../contexts/LiveConnectionContext';
import {
  addTaskInMeetingNotesMutation,
  LIST_MEETING_NOTES_TASKS_QUERY
} from '../../../graphql/operations/Mutations/MeetingNotes/taskInMeetingNoteMutations';
import useGetOrganizationName from '../../../hooks/useGetOrganizationName';
import { getDate } from '../../../lib/getDate';
import { handleMeetingNoteAutoSave, handlePublishNote, meetingNoteSaveAsDraft } from '../../../lib/meetingNoteHelper';
import { useToggle } from '../../../lib/UseToggle';
import { fetchMeetingNote, resetNote, updateMetaData } from '../../../reducers/meetingNote';
import { ErrorPage } from '../../Others';
import NoAccessPage from '../NoAccessPage';
import { UserDataI } from '../types';

const MainContainer = styled.div`
  min-height: 100%;
  background: #f6f5f5;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
`;

const FormContainer = styled.div`
  border: 24px solid #f6f5f5;
  flex-grow: 1;

  fieldset {
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    legend {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  /* Override table dropdown menu styles*/
  span {
    background-color: #ffffff;
    margin-bottom: 0px;
    width: 100%;
    padding: 10px;
  }
`;

const EditorContainer = styled.div`
  padding: 0px 24px;
`;
const LeftContainer = styled.div`
  width: 70%;
`;
const RightContainer = styled.div`
  background-color: #ffffff;
  height: 600px;
  width: 30%;
`;

const NewEditMeetingNote = () => {
  const { noteId } = useParams();
  const navigate = useNavigate();
  const { loading: orgLoading, error: orgError, getOrganization } = useGetOrganizationName();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { state } = useContext(AppContext);

  const [fileObj, setFileObj] = useState<any>(null);
  const [isAttendeeOpen, setIsAttendeeOpen] = useToggle(false);
  const [isSharedListOpen, setSharedListOpen] = useToggle(false);
  const [isDeleteNoteOpen, setIsDeleteNoteOpen] = useToggle(false);
  const [isReOpenNoteModalOpen, toggleReopenNoteModal] = useToggle(false);
  const [isSaveAndPublishModalOpen, toggleSaveAndPublishModal] = useToggle(false);

  const dispatch = useDispatch();
  const { loading, error, meetingNoteData, sections, metaData, otherFields, editingTable } = useSelector(
    //@ts-ignore
    (state) => state.meetingNote
  );

  const timer = useRef<any>(null);

  const {
    state: { authToken }
  } = useContext(AppContext);

  // @ts-ignore
  const ws: WebSocket = useContext(LiveConnectionContext);

  const delaySave = () => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => handleMeetingNoteAutoSave(ws, authToken, undefined, undefined), 2000);
  };

  const selectedAttendees = useRef<any[]>([]);

  const {
    data: tasks,
    error: taskError,
    refetch
  } = useQuery(LIST_MEETING_NOTES_TASKS_QUERY, {
    variables: {
      noteId: noteId,
      organizationId_projectId_typeId: `${meetingNoteData?.organizationId}_${meetingNoteData.projectId}_${meetingNoteData.workspaceId}`,
      type: 'workspace'
    },
    skip: Object.keys(meetingNoteData).length === 0
  });
  const [createTask] = useMutation(addTaskInMeetingNotesMutation);

  useEffect(() => {
    if (noteId) dispatch(fetchMeetingNote(noteId));
    // isCreatePage && toggleNoteModal(true);

    return () => {
      console.log('Running cleanup');
      dispatch(resetNote());
    };
  }, []);

  const initialState = {
    documentName: metaData?.documentName ? metaData.documentName : '',
    meetingNumber: metaData?.meetingNumber ? metaData.meetingNumber : '',
    meetingLocation: metaData?.meetingLocation ? metaData.meetingLocation : '',
    organizationName: metaData?.organizationName ? metaData.organizationName : getOrganization?.get_Organization?.name,
    phone: metaData?.phone ? metaData.phone : '',
    email: metaData?.email ? metaData.email : '',
    address: metaData?.address ? metaData.address : '',
    brandLogo: metaData?.brandLogo ? metaData.brandLogo : '',
    abbreviation: metaData?.abbreviation ? metaData.abbreviation : '',
    otherFields:
      otherFields.length > 0
        ? otherFields?.map((field: any) => {
            if ('formData' in field) {
              let array = field.formData.map((item: any) => {
                return {
                  label: item?.label,
                  value: field?.value !== false ? field?.value : '',
                  type: item?.type
                };
              });
              return { label: field.formHeader, formData: array };
            }
            return { label: field?.label, value: field?.value !== false ? field?.value : '' };
          })
        : []
  };

  // console.log(initialState);
  // console.log({ metaData, otherFields });

  const handleSubmit = () => {};

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  //Image handlers
  const handleImageUpload = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const uploadToS3 = async (compressedBlob: Blob) => {
    const data = await Storage.put(`template_org_image_${uuidv4()}`, compressedBlob, {
      contentType: compressedBlob?.type,
      bucket: process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME,
      level: 'public'
    });
    return data;
  };

  const handleImageChange = (event: any) => {
    const image = event.currentTarget.files[0];
    if (image?.size < 10000000) {
      const toastId = image && toast.loading('Uploading Image');
      const options = {
        maxWidthOrHeight: 2000,
        maxSizeMB: 0.2,
        initialQuality: 0.6,
        useWebWorker: true
      };
      Compress(image, options).then((compressedBlob) => {
        toast.success('Uploaded', { id: toastId });
        formik.setFieldValue('brandLogo', URL.createObjectURL(compressedBlob));
        setFileObj(compressedBlob);
      });
    }
  };

  const saveNoteAsDraft = async (meetingNoteId: string, showLoading = true) => {
    const toastId = showLoading ? toast.loading('Saving Note') : null;
    //save to s3
    try {
      const saveData = formik.values;

      Object.keys(saveData).forEach((key: string) => {
        if (key !== 'otherFields') {
          // @ts-ignore
          saveData[key] = saveData[key].replace(/['"]+/g, '');
        }
      });

      if (fileObj) {
        const data = await uploadToS3(fileObj);
        const url = `https://${process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${data.key}`;
        formik.setFieldValue('brandLogo', url);
        saveData.brandLogo = url;
      }

      if ('otherFields' in saveData)
        await meetingNoteSaveAsDraft(
          meetingNoteData,
          sections,
          meetingNoteId,
          JSON.stringify(saveData),
          JSON.stringify(saveData.otherFields)
        );
      else await meetingNoteSaveAsDraft(meetingNoteData, sections, meetingNoteId, JSON.stringify(saveData));

      toastId && toast.success('Note saved successfully!', { id: toastId });
      // navigate(`/meetingnotes/${noteId}/edit`);
    } catch (error) {
      console.log(error);
      toastId && toast.error('Something went wrong!', { id: toastId });
    }
  };

  const handleCreateTask = async (taskTitle: string, blockId: string) => {
    taskTitle = taskTitle !== '' ? taskTitle : 'Task';
    const toastId = toast.loading('Creating task');

    try {
      saveNoteAsDraft(meetingNoteData.id, false);
      const variables: any = {
        type: 'workspace',
        typeId: meetingNoteData.workspaceId,
        noteId: noteId,
        assignee: state.userId,
        projectId: meetingNoteData.projectId,
        workspaceId: meetingNoteData.workspaceId,
        organizationId: meetingNoteData.organizationId,
        organizationId_projectId_typeId: `${meetingNoteData.organizationId}_${meetingNoteData.projectId}_${meetingNoteData.workspaceId}`,
        assigneeDetails: {
          name: state.fullName
        },
        title: taskTitle,
        recordStatus: 0,
        priority: 'medium',
        status: 'todo',
        updatedBy: state.userId,
        blockId: blockId,
        links: [`${window.location.origin}/meetingnotes/${noteId}`]
      };
      if (state.profilePhoto) variables.assigneeDetails.profilePicture = state.profilePhoto;

      const data = await createTask({ variables });
      refetch();
      toast.success('Successfully created the task', { id: toastId });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id: toastId });
    }
  };

  const getSharedMembers = async () => {
    const { data } = await axiosInstance.get(`/meetingNotes/members?noteId=${noteId}`);
    const userDetails: UserDataI[] = [];
    data.map((user: any) => {
      let sharedUserData: UserDataI = {
        access: user.access,
        memberType: user.memberType
      };
      if ('userData' in user) sharedUserData['userId'] = user?.userId;
      else sharedUserData['emailId'] = user?.emailId;

      userDetails.push(sharedUserData);
    });
    return userDetails;
  };

  const handleSaveAndShare = async (meetingNoteId: string) => {
    const id = toast.loading('Saving and sharing note ..');
    try {
      await saveNoteAsDraft(meetingNoteId, false);
      const usersData = await getSharedMembers();

      const data = await axiosInstance.post(`/meetingNotes/${noteId}`, {
        organizationId: meetingNoteData.organizationId,
        workspaceId: meetingNoteData.workspaceId,
        projectId: meetingNoteData.projectId,
        usersData: usersData,
        isShare: true
      });
      toast.success('Updated Note shared successfully', { id });
      navigate(`/meetingnotes/${noteId}`);
    } catch (error) {
      toast.error('Something went wrong', { id });
    }
  };

  const handleOnBlur = (e: any) => {
    // console.log('Update Meta data : ', formik.values);
    dispatch(updateMetaData({ data: formik.values, noteId }));
    handleMeetingNoteAutoSave(ws, authToken, undefined, undefined);
  };

  if (loading || orgLoading) return <Loader />;

  if (error) {
    return <NoAccessPage />;
  }

  if (error || orgError) {
    return <ErrorPage />;
  }
  return (
    <>
      <>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>{meetingNoteData?.title ? meetingNoteData.title : 'New Note'}</PageTitle>
              <Small>{getDate()}</Small>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <>
              {meetingNoteData?.isDraft ? (
                <>
                  <Button onClick={() => saveNoteAsDraft(noteId!)}>Save Note</Button>
                  <Button onClick={() => setIsAttendeeOpen(true)}>Publish Note</Button>
                </>
              ) : (
                // <Button onClick={toggleReopenNoteModal}>Reopen Note</Button>
                <Button onClick={toggleSaveAndPublishModal}>Save & Share</Button>
              )}
            </>

            <ToggleSettings style={{ top: '125px', right: '-70px' }}>
              {!meetingNoteData?.isDraft && (
                <OptionItem onClick={() => navigate(`/meetingnotes/${noteId}/publish`)}>Shared Users</OptionItem>
              )}{' '}
              <OptionItem onClick={setIsDeleteNoteOpen}>Delete Note</OptionItem>
            </ToggleSettings>
          </TitleRight>
        </Header>
      </>
      <MainContainer>
        <LeftContainer>
          <FormContainer>
            <TableDropDown
              noLine={true}
              state={false}
              text="Note Info"
              style={{ backgroundColor: '#ffffff', width: '100px', borderRadius: '5px' }}
            >
              <div style={{ backgroundColor: '#ffffff', paddingBottom: '10px' }}>
                <InputWrapper>
                  <InputField
                    label="Document Name"
                    type="text"
                    name="documentName"
                    value={formik.values.documentName}
                    onChange={formik.handleChange}
                    touched={formik.touched.documentName as boolean}
                    errors={formik.errors.documentName as string}
                    placeholder="Enter your document Name"
                    onBlur={handleOnBlur}
                    // disabled={formik.isSubmitting || !editable}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Meeting Number"
                    type="text"
                    name="meetingNumber"
                    value={formik.values.meetingNumber}
                    onChange={formik.handleChange}
                    touched={formik.touched.meetingNumber as boolean}
                    errors={formik.errors.meetingNumber as string}
                    placeholder="Enter the meeting number"
                    onBlur={handleOnBlur}

                    // disabled={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Meeting Location"
                    type="text"
                    name="meetingLocation"
                    value={formik.values.meetingLocation}
                    onChange={formik.handleChange}
                    touched={formik.touched.meetingLocation as boolean}
                    errors={formik.errors.meetingLocation as string}
                    placeholder="Enter the meeting location"
                    onBlur={handleOnBlur}

                    // disabled={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Abbreviation"
                    type="text"
                    name="abbreviation"
                    placeholder="Enter the Abbreviation"
                    touched={formik.touched.abbreviation as boolean}
                    errors={formik.errors.abbreviation as string}
                    onBlur={handleOnBlur}

                    // disabled={true}
                  />
                </InputWrapper>
              </div>
            </TableDropDown>
            <div>
              <p></p>
            </div>
            <TableDropDown
              noLine={true}
              state={false}
              text="Header Info"
              style={{ backgroundColor: '#FFFFFF', width: '100px', borderRadius: '5px' }}
            >
              <div style={{ backgroundColor: '#FFFFFF', paddingBottom: '10px' }}>
                <InputWrapper>
                  <Label>Brand Logo</Label>
                  <ImagePlaceHolder>
                    {formik.values.brandLogo && <img src={formik.values.brandLogo} alt="brand-logo" />}
                    <ActionContainer>
                      <DarkGreenButton type="button" onClick={handleImageUpload}>
                        Add/Edit Logo
                      </DarkGreenButton>
                    </ActionContainer>
                  </ImagePlaceHolder>
                  <input
                    type="file"
                    accept="image/*"
                    name="brandLogo"
                    style={{ display: 'none' }}
                    ref={hiddenFileInput}
                    onChange={(event) => {
                      handleImageChange(event);
                    }}
                    onBlur={handleOnBlur}

                    // disabled={!editable}
                  />
                </InputWrapper>
                <fieldset>
                  <legend>Organization Contact</legend>
                  <InputWrapper>
                    <InputField
                      label="Organization Name"
                      type="text"
                      name="organizationName"
                      value={formik.values.organizationName}
                      onChange={formik.handleChange}
                      touched={formik.touched.organizationName as boolean}
                      errors={formik.errors.organizationName as string}
                      // disabled={!editable}
                      onBlur={handleOnBlur}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label="Phone"
                      type="text"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      touched={formik.touched.phone as boolean}
                      errors={formik.errors.phone as string}
                      placeholder="99-222-444"
                      // disabled={!editable}
                      onBlur={handleOnBlur}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label="Email"
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      touched={formik.touched.email as boolean}
                      errors={formik.errors.email as string}
                      placeholder="abc@gmail.com"
                      // disabled={!editable}
                      onBlur={handleOnBlur}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label="Address"
                      type="text"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      touched={formik.touched.address as boolean}
                      errors={formik.errors.address as string}
                      placeholder="Address"
                      // disabled={!editable}
                      onBlur={handleOnBlur}
                    />
                  </InputWrapper>
                </fieldset>
                <FormikProvider value={formik}>
                  <FieldArray name="otherFields">
                    {({}) => (
                      <>
                        {formik.values.otherFields.length > 0 &&
                          formik.values.otherFields.map(
                            (item: { label: string; value: string; formData?: any }, index: number) => {
                              switch (item.label) {
                                case 'Date':
                                  return (
                                    <Field name={`otherFields.${index}`} key={`otherFields.${index}`}>
                                      {({ field }: any) => {
                                        return (
                                          <DynamicFormInputContainer>
                                            <DynamicFormInputWrapper>
                                              <Label>Date</Label>
                                              <DateInputField
                                                type="date"
                                                {...field}
                                                name={item.label}
                                                label={item.label}
                                                value={item.value}
                                                onChange={(e) =>
                                                  formik.setFieldValue(`otherFields[${index}].value`, e.target.value)
                                                }
                                                // disabled={!editable}
                                                onBlur={handleOnBlur}
                                              />
                                            </DynamicFormInputWrapper>
                                          </DynamicFormInputContainer>
                                        );
                                      }}
                                    </Field>
                                  );
                                case 'Address':
                                case 'Project':
                                case 'Subject':
                                  return (
                                    <Field name={`otherFields.${index}`}>
                                      {({ field, meta }: any) => {
                                        return (
                                          <DynamicFormInputContainer>
                                            <DynamicFormInputWrapper>
                                              <InputField
                                                type="text"
                                                {...field}
                                                touched={meta.touched}
                                                errors={meta.error}
                                                name={item.label}
                                                label={item.label}
                                                value={item.value}
                                                onChange={(e) =>
                                                  formik.setFieldValue(`otherFields[${index}].value`, e.target.value)
                                                }
                                                onBlur={handleOnBlur}

                                                // disabled={!editable}
                                              />
                                            </DynamicFormInputWrapper>
                                          </DynamicFormInputContainer>
                                        );
                                      }}
                                    </Field>
                                  );
                                case 'Contact':
                                  return (
                                    <DynamicFormInputContainer>
                                      <DynamicFormInputWrapper>
                                        <fieldset style={{ margin: '0px' }}>
                                          <legend className="contentStyle">Contact</legend>
                                          <FieldArray name={`otherFields[${index}].formData`}>
                                            {({}) => (
                                              <>
                                                {item.formData.map((subItem: any, subIndex: number) => {
                                                  return (
                                                    <Field
                                                      name={`otherFields[${index}].formData.${subIndex}`}
                                                      key={`otherFields[${index}].formData.${subIndex}`}
                                                    >
                                                      {({ field }: any) => {
                                                        return (
                                                          <InputWrapper>
                                                            <InputField
                                                              {...field}
                                                              name={subItem.label}
                                                              label={subItem.label}
                                                              value={subItem.value}
                                                              type={subItem?.type}
                                                              onChange={(e) =>
                                                                formik.setFieldValue(
                                                                  `otherFields[${index}].formData[${subIndex}].value`,
                                                                  e.target.value
                                                                )
                                                              }
                                                              onBlur={handleOnBlur}

                                                              // disabled={!editable}
                                                            />
                                                          </InputWrapper>
                                                        );
                                                      }}
                                                    </Field>
                                                  );
                                                })}
                                              </>
                                            )}
                                          </FieldArray>
                                        </fieldset>
                                      </DynamicFormInputWrapper>
                                    </DynamicFormInputContainer>
                                  );
                              }
                            }
                          )}
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </div>
            </TableDropDown>
          </FormContainer>
          <EditorContainer>
            {/* {loading ? <Loader /> : <EditorComponent handleCreateTask={handleCreateTask} />} */}
            {loading ? (
              <Loader />
            ) : (
              <EditorComponent
                handleCreateTask={handleCreateTask}
                editorType="MEETING_NOTE"
                sections={sections}
                editingTable={editingTable}
                noteData={meetingNoteData}
              />
            )}
          </EditorContainer>
        </LeftContainer>
        <RightContainer>
          <RightSideBar
            tasks={tasks?.list_TaskItems._TaskItems}
            saveNoteAsDraft={saveNoteAsDraft}
            entityId={noteId as string}
            entityType="MEETING_NOTES"
          />
        </RightContainer>
      </MainContainer>

      <AttendeeListModal
        isOpen={isAttendeeOpen}
        toggleModal={setIsAttendeeOpen}
        selectedAttendees={selectedAttendees}
        openSharedListModel={setSharedListOpen}
        workspaceId={meetingNoteData?.workspaceId}
      />
      <SharedListModal
        isOpen={isSharedListOpen}
        sections={sections}
        toggleModal={setSharedListOpen}
        selectedAttendees={selectedAttendees}
        handlePublishNote={handlePublishNote}
        saveNoteAsDraft={saveNoteAsDraft}
        organizationID={meetingNoteData?.organizationId}
        meetingNoteData={meetingNoteData}
      />
      <DeleteNoteModal isOpen={isDeleteNoteOpen} toggleModal={setIsDeleteNoteOpen} />
      <ReOpenNoteModal noteId={noteId} isOpen={isReOpenNoteModalOpen} toggleModal={toggleReopenNoteModal} />
      <SaveAndPublishModal
        isOpen={isSaveAndPublishModalOpen}
        toggleModal={toggleSaveAndPublishModal}
        handleSaveAndPublish={handleSaveAndShare}
      />
    </>
  );
};

export default NewEditMeetingNote;
