import styled from "@emotion/styled";
import { ReactComponent as PageComingSoonImage } from "../../assets/images/PageComingSoon.svg";
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  padding: 0;
`;

export const PageComingSoon = () => {
  return (
    <ImageWrapper>
      <h1>Coming Soon</h1>
      <PageComingSoonImage height="600px" width="600px" />
    </ImageWrapper>
  );
};
