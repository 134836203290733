import { useEffect, useState } from 'react';
import axiosInstance from '../../../axiosInstance';

interface MeetingNotes {
  _id: string;
  content: any;
  createdAt: string;
  createdBy: string;
  isHighlighted: any;
  metadata: string;
  noteType: string;
  noteType_typeId: string;
  organizationId: string;
  organizationId_projectId_workspaceId: string;
  otherFields: any;
  prefix: string;
  projectId: string;
  recordStatus: number;
  title: string;
  typeId: string;
  updateBy: any;
  updatedAt: any;
  workspaceId: string;
  __typename: string;
  projectName: string;
  workspaceName: string;
  organizationName: string;
  isDraft: boolean;
}

interface MeetingNotesList {
  today: MeetingNotes[];
  week: MeetingNotes[];
  previous: MeetingNotes[];
}

const fetchNotesList = async (orgId: string, external: boolean, workspaceId?: string, projectId?: string) => {
  try {
    const externalParam = external ? `&external=true` : '';
    const otherParams = workspaceId ? `&workspaceId=${workspaceId}&projectId=${projectId}` : '';
    const { data } = await axiosInstance.get(`/meetingNotes?organizationId=${orgId}${otherParams}${externalParam}`);
    return { data: data.data, ApiError: null };
  } catch (error: any) {
    return { data: [], ApiError: error.message };
  }
};

const useMeetingNotesList = (orgId: string, external: boolean, workspaceId?: string, projectId?: string) => {
  const emptyNotes = {
    today: [],
    week: [],
    previous: []
  };
  const [notes, setNotes] = useState<MeetingNotesList>(emptyNotes);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const initNotes = async () => {
    setLoading(true);
    const { data, ApiError } = await fetchNotesList(orgId, external, workspaceId, projectId);
    setError(ApiError);
    if (data.length === 0) setNotes(emptyNotes);
    else setNotes(data);
    setLoading(false);
  };

  useEffect(() => {
    initNotes();
  }, []);

  return {
    loading,
    notes,
    error
  };
};

export default useMeetingNotesList;
