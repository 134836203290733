import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useContext, useMemo, useReducer } from 'react';
import Lottie from 'react-lottie';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import CloseIcon from '../../../assets/SvgComponents/CloseIcon';
import PlusIcon from '../../../assets/SvgComponents/PlusIcon';
import Button from '../../../components/CustomButton';
import { DarkButton, GreyButton, LightButton } from '../../../components/styles/ButtonStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import Table from '../../../components/Table';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { COLUMNS, SELECTEDCOLUMNS } from '../../../constants/RegisterColumnData';
import { updateRegisterMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';
import {
  listOtherRegistersQuery,
  listRegistersQuery
} from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { NoRegistersAdded } from '../WorkspaceViews/CreateNewWorkspace';
import { RegisterHeader, RegisterList } from '../WorkspaceViews/WorkspaceConfiguration';

const RegisterContainer = styled.div`
  /* border: 1px solid red; */
  margin-top: 32px;
  margin-left: 25px;
`;

const initialRegisterState: any = { selected: [], notSelected: [] };
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_REGISTERS':
      if (action.id) {
        let selectedregisters = state.selected.filter((selected) => selected._id !== action.id);
        return {
          ...state,
          selected: selectedregisters,
          notSelected: action.data
        };
      } else {
        return {
          ...state,
          selected: action.data
        };
      }

    case 'SET_OTHER_REGISTERS':
      return {
        ...state,
        notSelected: action.data
      };
    case 'REMOVE_REGISTER_FROM_WORKSPACE':
      return action.data;
    case 'ADD_REGISTER_TO_WORKSPACE':
      return action.data;
  }
};

function EditRegisterList() {
  const [registers, dispatch] = useReducer(reducer, initialRegisterState);
  let navigate = useNavigate();
  const { state: RouterState } = useLocation();
  let { projectId, workspaceId } = useParams();
  const {
    state: { orgId }
  } = useContext(AppContext);

  const {
    data: createdRegisterData,
    error: listError,
    loading: registerLoading
  } = useQuery(listRegistersQuery, {
    variables: {
      workspaceId: workspaceId,
      organizationId: orgId
    },
    onCompleted: () => {
      if (RouterState && RouterState.registerData) {
        let found = createdRegisterData.list_RegisterItems._RegisterItems.some(
          (register) => register._id === RouterState.registerData._id
        );
        if (!found) {
          let newarr = [...createdRegisterData.list_RegisterItems._RegisterItems, RouterState.registerData];
          dispatch({ type: 'SET_REGISTERS', data: newarr });
          getOtherRegisters();
        } else if (found) {
          dispatch({
            type: 'SET_REGISTERS',
            data: createdRegisterData.list_RegisterItems._RegisterItems
          });
          getOtherRegisters();
        }
      } else {
        dispatch({
          type: 'SET_REGISTERS',
          data: createdRegisterData.list_RegisterItems._RegisterItems
        });
        getOtherRegisters();
      }
    },
    fetchPolicy: 'network-only'
  });

  const [
    getOtherRegisters,
    {
      data: otherRegisterData
      // error: otherRegistersError,
      // loading: otherRegistersLoading,
    }
  ] = useLazyQuery(listOtherRegistersQuery, {
    variables: {
      projectId: projectId,
      workspaceId: ''
    },
    onCompleted: () => {
      if (RouterState && RouterState.registerData) {
        let found = otherRegisterData.list_RegisterItems._RegisterItems.some(
          (register) => register._id === RouterState.registerData._id
        );
        if (!found) {
          dispatch({
            type: 'SET_OTHER_REGISTERS',
            data: otherRegisterData.list_RegisterItems._RegisterItems
          });
        } else if (found) {
          dispatch({
            type: 'SET_REGISTERS',
            data: otherRegisterData.list_RegisterItems._RegisterItems,
            id: RouterState.registerData.id
          });
        }
      } else {
        dispatch({
          type: 'SET_OTHER_REGISTERS',
          data: otherRegisterData.list_RegisterItems._RegisterItems
        });
      }
    },
    fetchPolicy: 'network-only'
  });

  const [
    updateRegister
    // { loading: updatingRegister, error: updateRegisterError },
  ] = useMutation(updateRegisterMutation);

  const addRegisterToWorkspace = async (data, registers) => {
    let notSelected = registers.notSelected.filter((register) => register._id !== data._id);
    let selected = [...registers.selected, { ...data, workspaceId: workspaceId }];
    dispatch({
      type: 'ADD_REGISTER_TO_WORKSPACE',
      data: {
        selected: selected,
        notSelected: notSelected
      }
    });
    try {
      let response = await updateRegister({
        variables: {
          id: data._id,
          workspaceId: workspaceId
        }
      });
      if (response) {
        // dispatch({
        //   type: "ADD_REGISTER_TO_WORKSPACE",
        //   data: {
        //     selected: selected,
        //     notSelected: notSelected
        //   }
        // })
      }
    } catch (error) {
      console.error(error);
    }
  };
  const removeRegisterFromWorkspace = async (data, registers) => {
    let selected = registers.selected.filter((register) => register._id !== data._id);
    let notSelectedRegisters = [...registers.notSelected, { ...data, workspaceId: '' }];
    dispatch({
      type: 'REMOVE_REGISTER_FROM_WORKSPACE',
      data: {
        selected: selected,
        notSelected: notSelectedRegisters
      }
    });
    try {
      let response = await updateRegister({
        variables: {
          id: data._id,
          workspaceId: ''
        }
      });
      if (response) {
        // dispatch({
        //   type: "REMOVE_REGISTER_FROM_WORKSPACE", data: {
        //     selected: selected,
        //     notSelected: notSelectedRegisters
        //   }
        // })
      }
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        id: 'Action',
        Cell: ({ cell: { row } }) => {
          return (
            <>
              <span onClick={(e) => e.stopPropagation()}>
                <DarkButton type="button" onClick={() => removeRegisterFromWorkspace(row.original, registers)}>
                  <CloseIcon fill="#FFF" />
                  Remove
                </DarkButton>
              </span>
            </>
          );
        }
      }
    ],
    [registers]
  );
  const createdColumns = useMemo(
    () => [
      ...SELECTEDCOLUMNS,
      {
        id: 'Action',
        Cell: ({ cell: { row } }) => {
          return (
            <>
              <span onClick={(e) => e.stopPropagation()}>
                <LightButton type="button" onClick={() => addRegisterToWorkspace(row.original, registers)}>
                  <PlusIcon stroke="#313B48" />
                  Add Register
                </LightButton>
              </span>
            </>
          );
        }
      }
    ],
    [registers]
  );
  const selectedData = useMemo(() => (registers ? registers.selected : null), [registers, registers.selected]);
  const createdData = useMemo(() => (registers ? registers.notSelected : null), [registers, registers.notSelected]);

  const createRegister = () => {
    navigate(`/project/workspace/${projectId}/add/register`, {
      state: {
        workspaceId: workspaceId,
        existingWorkspace: true
      }
    });
  };

  const editMembers = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/edit/members`);
  };

  const handleNavigate = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/registers`);
  };

  if (registerLoading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  if (listError) {
    return <p>Error</p>;
  }
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>Workspace Registers</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button onClick={editMembers}>Update</Button>
        </TitleRight>
      </Header>
      <Container>
        <RegisterContainer>
          <RegisterHeader>
            <PageTitle>Register Selection</PageTitle>
            <GreyButton onClick={createRegister}>Create New Register</GreyButton>
          </RegisterHeader>
          <RegisterList>
            {selectedData?.length > 0 ? (
              selectedData && <Table columns={columns} data={selectedData} />
            ) : (
              <NoRegistersAdded>No Registers Added</NoRegistersAdded>
            )}
            {createdData && <Table columns={createdColumns} data={createdData} />}
          </RegisterList>
        </RegisterContainer>
      </Container>
    </>
  );
}

export default EditRegisterList;
