import styled from '@emotion/styled';

type TemplateProps = {
  templateName: string;
  isGlobal: boolean;
  type: string;
  _id: string;
  recordStatus: number;
  organizationId_type: string;
};

type NoteTemplateTileProps = {
  selected: string;
  template: TemplateProps;
  handleSelect: (id: string) => void;
  children: React.ReactNode;
};

type TileProps = {
  isSelected: boolean;
};

export const BoxStyles = styled.div<TileProps>`
  border: 1px solid rgb(237, 237, 237);
  border-radius: 10px;
  padding: 64px 0px;
  min-width: 200px;
  max-width: 200px;
  max-height: 150px;
  margin-bottom: 25px;
  margin-right: 1.4rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  flex: 1 0 21%;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
  background-color: ${(props) => (props.isSelected ? '#E5E5E5' : '')};
`;

export const TemplateName = styled.p`
  font-weight: 400;
  color: var(--black);
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

function MeetingNoteTemplateTile(props: NoteTemplateTileProps) {
  return (
    <BoxStyles
      isSelected={props.template._id === props.selected}
      onClick={() => {
        props.handleSelect(props.template._id);
      }}
    >
      <TemplateName>{props.children}</TemplateName>
    </BoxStyles>
  );
}

export default MeetingNoteTemplateTile;
