import React from 'react';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  email: ''
};

const useForgotPassword = () => {
  // function validateEmail(email: string) {
  //   var re = /^[^\s@]+@[^\s@]+$/;
  //   if (re.test(email)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // const regex_NoWhiteSpace = /^\S*$/;
  const validationSchema = Yup.object({
    email: Yup.string().email('Please enter a valid email address.').required('Email is required.')
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  let navigate = useNavigate();

  const ForgotPassword = async (values) => {
    // const isEmail = validateEmail(values.username.trim());
    const email = values.email;
    try {
      setLoading(true);
      await Auth.forgotPassword(values.email);
      toast.success('Reset code is mailed to your Email Id');
      navigate('/resetPassword', { state: values.email });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      let errorMessage = 'Something is wrong please try later';
      if (err.code === 'UserNotFoundException') errorMessage = 'User not found';
      if (err.code === 'InvalidParameterException')
        errorMessage =
          'Cannot reset password for the user as there is no verified email, confirm the user and try again ';
      toast.error(errorMessage);
    }
  };

  const goToHomePage = () => {
    navigate('/');
  };
  return {
    loading,
    validationSchema,
    ForgotPassword,
    initialValues,
    goToHomePage
  };
};

export default useForgotPassword;
