import { Reducer, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router';
import axiosInstance from '../../../axiosInstance';
import { OtherContactsResponse } from '../projects/useGetProject';
import { TeamDetailsPayload } from '../projects/useTeam';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';

type ACTIONTYPE =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_DATA'; payload: WorkspaceResponse };

type WorkspaceResponse = {
  projectName: string;
  createdBy: string;
  workspaceDescription: string;
  createdAt: Date;
  OtherContacts: OtherContactsResponse[];
  workspaceTeam: IWorkspaceTeamResponse[];
  workspaceName: string;
  workspaceDirectory: Array<string>;
};

type IWorkspaceTeamResponse = Omit<TeamDetailsPayload, 'phoneNumber'> & {
  id: string;
  phoneNumber: number;
  projectId_recordType: string;
  recordStatus: number;
};

type WorkspaceState = {
  workspaceData: WorkspaceResponse;
  error: string;
  loading: boolean;
};

const initialState: WorkspaceState = {
  workspaceData: {} as WorkspaceResponse,
  error: '',
  loading: true
};

const dataReducer: Reducer<WorkspaceState, ACTIONTYPE> = (state: WorkspaceState, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: state.loading ? false : true };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_DATA':
      return { ...state, workspaceData: action.payload };
    default:
      return state;
  }
};

export const useGetWorkspace = (workspaceId: string | undefined, orgId: string) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const allUsers = useSelector((state) => state.orgMembers.users);
  const { state: AppState } = useContext(AppContext);
  let fullName = AppState.fullName;
  let navigate = useNavigate();
  useEffect(() => {
    const getWorkspaceDetails = async () => {
      await axiosInstance
        .get(`/workspace?workspaceId=${workspaceId}&organizationId=${orgId}`)
        .then((res) => {
          dispatch({ type: 'SET_DATA', payload: res.data.data });
        })
        .catch((error) => {
          if (error?.message) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
          } else {
            dispatch({ type: 'SET_ERROR', payload: 'Error in loading details of project.' });
          }
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING' });
        });
    };

    getWorkspaceDetails();
  }, []);

  const goToOtherContacts = (contactId: string) => {
    if (contactId) {
      navigate(`othercontacts`, {
        state: {
          isEdit: true,
          contactId: contactId,
          module: 'workspace'
        }
      });
    } else {
      navigate(`othercontacts`);
    }
  };

  const goToTeams = (teamId: string) => {
    if (teamId) {
      navigate(`team`, {
        state: {
          isEdit: true,
          teamId: teamId,
          module: 'workspace'
        }
      });
    } else {
      navigate(`team`);
    }
  };

  return {
    ...state,
    goToOtherContacts,
    goToTeams,
    allUsers,
    fullName
  };
};
