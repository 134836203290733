import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Storage } from 'aws-amplify';
import Compress from 'browser-image-compression';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import Button from '../../../components/CustomButton';
import EditorComponent from '../../../components/Editor/Editor';
import Loader from '../../../components/Loader';
import {
  ActionContainer,
  DynamicFormInputContainer,
  ImagePlaceHolder
} from '../../../components/MeetingNotes/CreateMeetingNoteTemplate';
import RightSideBar from '../../../components/MeetingNotes/RightSideBar';
import DeleteNoteModal from '../../../components/Modals/MeetingNotes/DeleteNote';
import ReOpenNoteModal from '../../../components/Modals/MeetingNotes/ReopenNoteModal';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import { DarkGreenButton } from '../../../components/styles/ButtonStyles';
import { DynamicFormInputWrapper, InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import {
  addTaskInMeetingNotesMutation,
  LIST_MEETING_NOTES_TASKS_QUERY
} from '../../../graphql/operations/Mutations/MeetingNotes/taskInMeetingNoteMutations';
import useMeetingNotes from '../../../hooks/features/meetingNotes/useMeetingNoteEditor';
import useGetOrganizationName from '../../../hooks/useGetOrganizationName';
import { getDate } from '../../../lib/getDate';
import { useToggle } from '../../../lib/UseToggle';
import { fetchMeetingNote } from '../../../reducers/meetingNote';
import { ErrorPage } from '../../Others';
import NoAccessPage from '../NoAccessPage';

interface LocationState {
  templateId: string | null | undefined;
}

const MainContainer = styled.div`
  min-height: 100%;
  background: #f6f5f5;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;

  overflow-y: auto;
`;

const FormContainer = styled.div`
  border: 24px solid #f6f5f5;
  flex-grow: 1;

  fieldset {
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    legend {
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  /* Override table dropdown menu styles*/
  span {
    background-color: #ffffff;
    margin-bottom: 0px;
    width: 100%;
    padding: 10px;
  }
`;

const EditorContainer = styled.div`
  padding: 0px 24px;
`;
const LeftContainer = styled.div`
  width: 70%;
`;
const RightContainer = styled.div`
  background-color: #ffffff;
  height: 600px;
  width: 30%;
`;

const ViewMeetingNote = () => {
  const { noteId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { loading: orgLoading, error: orgError, getOrganization } = useGetOrganizationName();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { state } = useContext(AppContext);

  const [fileObj, setFileObj] = useState<any>(null);
  const [isDeleteNoteOpen, setIsDeleteNoteOpen] = useToggle(false);
  const [isReOpenNoteModalOpen, toggleReopenNoteModal] = useToggle(false);
  const dispatch = useDispatch();
  const { loading, error, meetingNoteData, metaData, otherFields, sections, editingTable } = useSelector(
    //@ts-ignore
    (state) => state.meetingNote
  );

  useEffect(() => {
    if (noteId) dispatch(fetchMeetingNote(noteId));
  }, []);

  const { handleSaveDraft, loading: meetingNoteLoading, error: meetingNoteError } = useMeetingNotes(noteId);

  const {
    data: tasks,
    loading: taskLoading,
    error: taskError,
    refetch
  } = useQuery(LIST_MEETING_NOTES_TASKS_QUERY, {
    variables: {
      noteId: noteId,
      organizationId_projectId_typeId: `${meetingNoteData.organizationId}_${meetingNoteData.projectId}_${meetingNoteData.workspaceId}`,
      type: 'workspace'
    },
    skip: Object.keys(meetingNoteData).length===0
  });
  const [createTask] = useMutation(addTaskInMeetingNotesMutation);

  // const metaData = meetingNoteData?.metadata && JSON.parse(meetingNoteData?.metadata);
  // const otherFields = meetingNoteData?.otherFields && JSON.parse(meetingNoteData?.otherFields);

  const initialState = {
    documentName: metaData?.documentName,
    meetingNumber: metaData?.meetingNumber ? metaData.meetingNumber : '',
    meetingLocation: metaData?.meetingLocation ? metaData.meetingLocation : '',
    organizationName: metaData?.organizationName,
    phone: metaData?.phone,
    email: metaData?.email,
    address: metaData?.address,
    brandLogo: metaData?.brandLogo,
    abbreviation: metaData?.abbreviation ? metaData.abbreviation : '',
    otherFields: otherFields
      ? otherFields?.map((field: any) => {
          if ('formData' in field) {
            let array = field.formData.map((item: any) => {
              return { label: item?.label, value: item?.value, type: item?.type };
            });
            return { label: field.label, formData: array };
          }
          return { label: field?.label, value: field?.value };
        })
      : []
  };

  const handleSubmit = () => {};

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  const uploadToS3 = async (compressedBlob: Blob) => {
    const data = await Storage.put(`template_org_image_${uuidv4()}`, compressedBlob, {
      contentType: compressedBlob?.type,
      bucket: process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME,
      level: 'public'
    });
    return data;
  };

  const saveNoteAsDraft = async (noteId: string) => {
    const toastId = toast.loading('Saving Note');
    //save to s3
    try {
      const saveData = formik.values;

      if (fileObj) {
        const data = await uploadToS3(fileObj);
        const url = `https://${process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${data.key}`;
        formik.setFieldValue('brandLogo', url);
        saveData.brandLogo = url;
      }

      if ('otherFields' in saveData)
        await handleSaveDraft(noteId, JSON.stringify(saveData), JSON.stringify(saveData.otherFields));
      else await handleSaveDraft(noteId, JSON.stringify(saveData));

      toast.success('Note saved successfully!', { id: toastId });
      // navigate(`/meetingnotes/${noteId}/edit`);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!', { id: toastId });
    }
  };

  const handleCreateTask = async (taskTitle: string) => {
    taskTitle = taskTitle !== '' ? taskTitle : 'Task';
    const toastId = toast.loading('Creating task');

    try {
      saveNoteAsDraft(noteId!);

      const variables: any = {
        type: 'workspace',
        typeId: meetingNoteData.workspaceId,
        noteId: noteId,
        assignee: state.userId,
        projectId: meetingNoteData.projectId,
        workspaceId: meetingNoteData.workspaceId,
        organizationId: meetingNoteData.organizationId,
        organizationId_projectId_typeId: `${meetingNoteData.organizationId}_${meetingNoteData.projectId}_${meetingNoteData.workspaceId}`,
        assigneeDetails: {
          name: state.fullName
        },
        title: taskTitle,
        recordStatus: 0,
        priority: 'medium',
        status: 'todo',
        updatedBy: state.userId,
        links: [`${window.location.origin}/meetingnotes/${noteId}`]
      };

      if (state.profilePhoto) {
        variables.assigneeDetails.profilePicture = state.profilePhoto;
      }
      const data = await createTask({
        variables
      });

      refetch();
      toast.success('Successfully created the task', { id: toastId });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id: toastId });
    }
  };

  if (loading || meetingNoteLoading || orgLoading) return <Loader />;

  if (meetingNoteError) {
    return <NoAccessPage />;
  }

  if (error || orgError) {
    return <ErrorPage />;
  }
  return (
    <>
      <>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>{meetingNoteData?.title ? meetingNoteData.title : 'New Note'}</PageTitle>
              <Small>{getDate()}</Small>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            {meetingNoteData.isDraft ? (
              <>
                <Button
                  onClick={() => {
                    navigate(
                      `/project/workspace/${meetingNoteData.projectId}/${meetingNoteData.workspaceId}/meetingnotes/${meetingNoteData.id}`
                    );
                  }}
                >
                  Edit Note
                </Button>
              </>
            ) : (
              <>
                {meetingNoteData?.editPermission && (
                  <>
                    <Button
                      onClick={() => {
                        navigate(
                          `/project/workspace/${meetingNoteData.projectId}/${meetingNoteData.workspaceId}/meetingnotes/${meetingNoteData.id}`
                        );
                      }}
                    >
                      Edit Note
                    </Button>
                    <Button onClick={toggleReopenNoteModal}>Reopen Note</Button>
                  </>
                )}
              </>
            )}

            <ToggleSettings style={{ top: '125px', right: '-70px' }}>
              {!meetingNoteData?.isDraft && (
                <OptionItem onClick={() => navigate(`/meetingnotes/${noteId}/publish`)}>Shared Users</OptionItem>
              )}{' '}
              {meetingNoteData?.editPermission && <OptionItem onClick={setIsDeleteNoteOpen}>Delete Note</OptionItem>}
            </ToggleSettings>
          </TitleRight>
        </Header>
      </>
      <MainContainer>
        <LeftContainer>
          <FormContainer>
            <TableDropDown
              noLine={true}
              state={false}
              text="Note Info"
              style={{ backgroundColor: '#ffffff', width: '100px', borderRadius: '5px' }}
            >
              <div style={{ backgroundColor: '#ffffff', paddingBottom: '10px' }}>
                <InputWrapper>
                  <InputField
                    label="Document Name"
                    type="text"
                    name="documentName"
                    value={formik.values.documentName}
                    onChange={formik.handleChange}
                    touched={formik.touched.documentName as boolean}
                    errors={formik.errors.documentName as string}
                    placeholder="Enter your document Name"
                    // disabled={formik.isSubmitting || !editable}
                    disabled={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Meeting Number"
                    type="text"
                    name="meetingNumber"
                    value={formik.values.meetingNumber}
                    onChange={formik.handleChange}
                    touched={formik.touched.meetingNumber as boolean}
                    errors={formik.errors.meetingNumber as string}
                    placeholder="Enter the meeting number"
                    disabled={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Meeting Location"
                    type="text"
                    name="meetingLocation"
                    value={formik.values.meetingLocation}
                    onChange={formik.handleChange}
                    touched={formik.touched.meetingLocation as boolean}
                    errors={formik.errors.meetingLocation as string}
                    placeholder="Enter the meeting location"
                    disabled={true}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Abbreviation"
                    type="text"
                    name="abbreviation"
                    placeholder="Enter the Abbreviation"
                    touched={formik.touched.abbreviation as boolean}
                    errors={formik.errors.abbreviation as string}
                    disabled={true}
                  />
                </InputWrapper>
              </div>
            </TableDropDown>
            <div>
              <p></p>
            </div>
            <TableDropDown
              noLine={true}
              state={false}
              text="Header Info"
              style={{ backgroundColor: '#FFFFFF', width: '100px', borderRadius: '5px' }}
            >
              <div style={{ backgroundColor: '#FFFFFF', paddingBottom: '10px' }}>
                <InputWrapper>
                  <Label>Brand Logo</Label>
                  <ImagePlaceHolder>
                    {formik.values.brandLogo && <img src={formik.values.brandLogo} alt="brand-logo" />}
                    <ActionContainer>
                      {/* <DarkGreenButton type="button" onClick={handleImageUpload}>
                        Add/Edit Logo
                      </DarkGreenButton> */}
                    </ActionContainer>
                  </ImagePlaceHolder>
                </InputWrapper>
                <fieldset>
                  <legend>Organization Contact</legend>
                  <InputWrapper>
                    <InputField
                      label="Organization Name"
                      type="text"
                      name="organizationName"
                      value={formik.values.organizationName}
                      onChange={formik.handleChange}
                      touched={formik.touched.organizationName as boolean}
                      errors={formik.errors.organizationName as string}
                      // disabled={!editable}
                      disabled={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label="Phone"
                      type="text"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      touched={formik.touched.phone as boolean}
                      errors={formik.errors.phone as string}
                      placeholder="99-222-444"
                      disabled={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label="Email"
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      touched={formik.touched.email as boolean}
                      errors={formik.errors.email as string}
                      placeholder="abc@gmail.com"
                      disabled={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label="Address"
                      type="text"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      touched={formik.touched.address as boolean}
                      errors={formik.errors.address as string}
                      placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                      disabled={true}
                    />
                  </InputWrapper>
                </fieldset>
                <FormikProvider value={formik}>
                  <FieldArray name="otherFields">
                    {({}) => (
                      <>
                        {formik.values.otherFields.length > 0 &&
                          formik.values.otherFields.map(
                            (item: { label: string; value: string; formData?: any }, index: number) => {
                              switch (item.label) {
                                case 'Date':
                                  return (
                                    <Field name={`otherFields.${index}`} key={`otherFields.${index}`}>
                                      {({ field }: any) => {
                                        return (
                                          <DynamicFormInputContainer>
                                            <DynamicFormInputWrapper>
                                              <Label>Date</Label>
                                              <DateInputField
                                                type="date"
                                                {...field}
                                                name={item.label}
                                                label={item.label}
                                                value={item.value}
                                                onChange={(e) =>
                                                  formik.setFieldValue(`otherFields[${index}].value`, e.target.value)
                                                }
                                                disabled={true}
                                              />
                                            </DynamicFormInputWrapper>
                                          </DynamicFormInputContainer>
                                        );
                                      }}
                                    </Field>
                                  );
                                case 'Address':
                                case 'Project':
                                case 'Subject':
                                  return (
                                    <Field name={`otherFields.${index}`}>
                                      {({ field, meta }: any) => {
                                        return (
                                          <DynamicFormInputContainer>
                                            <DynamicFormInputWrapper>
                                              <InputField
                                                type="text"
                                                {...field}
                                                touched={meta.touched}
                                                errors={meta.error}
                                                name={item.label}
                                                label={item.label}
                                                value={item.value}
                                                onChange={(e) =>
                                                  formik.setFieldValue(`otherFields[${index}].value`, e.target.value)
                                                }
                                                disabled={true}
                                              />
                                            </DynamicFormInputWrapper>
                                          </DynamicFormInputContainer>
                                        );
                                      }}
                                    </Field>
                                  );
                                case 'Contact':
                                  return (
                                    <DynamicFormInputContainer>
                                      <DynamicFormInputWrapper>
                                        <fieldset style={{ margin: '0px' }}>
                                          <legend className="contentStyle">Contact</legend>
                                          <FieldArray name={`otherFields[${index}].formData`}>
                                            {({}) => (
                                              <>
                                                {item.formData.map((subItem: any, subIndex: number) => {
                                                  return (
                                                    <Field
                                                      name={`otherFields[${index}].formData.${subIndex}`}
                                                      key={`otherFields[${index}].formData.${subIndex}`}
                                                    >
                                                      {({ field }: any) => {
                                                        return (
                                                          <InputWrapper>
                                                            <InputField
                                                              {...field}
                                                              name={subItem.label}
                                                              label={subItem.label}
                                                              value={subItem.value}
                                                              type={subItem?.type}
                                                              onChange={(e) =>
                                                                formik.setFieldValue(
                                                                  `otherFields[${index}].formData[${subIndex}].value`,
                                                                  e.target.value
                                                                )
                                                              }
                                                              disabled={true}
                                                            />
                                                          </InputWrapper>
                                                        );
                                                      }}
                                                    </Field>
                                                  );
                                                })}
                                              </>
                                            )}
                                          </FieldArray>
                                        </fieldset>
                                      </DynamicFormInputWrapper>
                                    </DynamicFormInputContainer>
                                  );
                              }
                            }
                          )}
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </div>
            </TableDropDown>
          </FormContainer>
          <EditorContainer>
            {meetingNoteLoading ? (
              <Loader />
            ) : (
              <EditorComponent
                handleCreateTask={handleCreateTask}
                editorType="MEETING_NOTE"
                sections={sections}
                editingTable={editingTable}
                noteData={meetingNoteData}
                editable={false}
              />
            )}
          </EditorContainer>
        </LeftContainer>
        <RightContainer>
          <RightSideBar
            tasks={tasks?.list_TaskItems._TaskItems}
            saveNoteAsDraft={saveNoteAsDraft}
            entityId={noteId as string}
            entityType="MEETING_NOTES"
          />
        </RightContainer>
      </MainContainer>
      <DeleteNoteModal isOpen={isDeleteNoteOpen} toggleModal={setIsDeleteNoteOpen} />
      <ReOpenNoteModal noteId={noteId} isOpen={isReOpenNoteModalOpen} toggleModal={toggleReopenNoteModal} />
    </>
  );
};

export default ViewMeetingNote;
