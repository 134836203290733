import styled from '@emotion/styled';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppContext } from '../../AppContext';
import { useToggle } from '../../lib/UseToggle';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../CustomButton';
import Modal from '../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
import { PaymentButton } from './CheckoutForm';

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  button {
    width: 40% !important;
  }
  #cancel-button {
    padding: 12px 16px !important;
    font-size: 16px;
    font-weight: 600;
  }
`;

function AddCardForm() {
  const stripe = useStripe();
  const elements = useElements();
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState('');
  const [successModal, toggleSuccessModal] = useToggle(false);
  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret');
    // console.log('RETREIVED SECRET', clientSecret);
    clientSecret &&
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        switch (setupIntent.status) {
          case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            toast.success('Payment Method Saved');
            toggleSuccessModal();
            break;

          case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            toast('Your payment details are processing');
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            break;
        }
      });
  }, [stripe]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/organization/${state.orgId}/updatecard`
      }
    });

    if (error) {
      console.log('ERROR', error);
      setErrorMessage(error.message);
    } else {
      console.log('ERROR', error);
    }
    setIsLoading(false);
  };

  const goToManageSeats = () => {
    navigate(`/organization/${state.orgId}/managesubscription`);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <ButtonContainer>
          <PaymentButton disabled={isLoading || !stripe || !elements}>Add Payment</PaymentButton>
        </ButtonContainer>
        {errorMessage && <div>{errorMessage}</div>}
        {message && <div>{message}</div>}
      </form>
      <Modal open={successModal} onClose={toggleSuccessModal}>
        <ModalContainer width="555px">
          <ModalTitle>Payment Method Updated</ModalTitle>
          <ModalBody>
            <ModalText>Your payment method has been successfully updated.</ModalText>
          </ModalBody>
          <ModalWrapper>
            <Button secondary onClick={toggleSuccessModal}>
              Close
            </Button>
            <Button onClick={() => goToManageSeats()}>Manage Seats</Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default AddCardForm;
