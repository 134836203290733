/** @jsxImportSource @emotion/react */
import { useLazyQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Storage } from 'aws-amplify';
import Compress from 'browser-image-compression';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-hot-toast';
import Lottie from 'react-lottie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../AppContext';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIconWhite.svg';
import { ReactComponent as Messages } from '../assets/icons/icon-chat.svg';
import { ReactComponent as NotificationBell } from '../assets/icons/icon-noti.svg';
import organizationPlaceholder from '../assets/icons/orgplaceholder.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/SettingIcon.svg';
import { ReactComponent as NoNotification } from '../assets/illustration/NoNotify.svg';
import axiosInstance from '../axiosInstance';
import { defaultOptions } from '../constants/LoaderDefaultOptions';
import { GET_ORGANIZATION } from '../graphql/operations/Queries/Organization/OrganizationQueries';
import { useHandleInvite } from '../hooks/features/workspaces/useHandleInvite';
import useOnClickOutside from '../lib/useClickOutside';
import { useToggle } from '../lib/UseToggle';
import OrganizationFlymenu from '../pages/Organization/OrganizationFlymenu';
import { clearAllNotifications } from '../reducers/notification';
import { RootState } from '../store';
import ChatList from './Chat/ChatList/ChatList';
import Button from './CustomButton';
import CustomSvgIcon from './CustomSvgIcon';
import FlyInContainer from './FlyInContainer';
import Modal from './Modal';
import { Notifications } from './Notifications';
import { OptionItem, ToggleSettings } from './OptionsMenu';
import EditProfile from './Profile/EditProfile';
import ViewProfile from './Profile/ViewProfile';
import { GreyButton } from './styles/ButtonStyles';
import { InputWrapper } from './styles/FormStyles';
import { DescriptionInput } from './styles/Inputs';
import { Label } from './styles/Label';
import { ModalBody, ModalContainer, ModalTitle } from './styles/ModalStyles';

const HeaderStyles = styled.header`
  width: 100%;
  height: 67px;
  border-bottom: 7px solid #f6f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 8;
  /* padding-bottom: 5px; */
  .header-right {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 57px;
  }
  .create-org {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 10px;
    transition: all 0.5s ease;
    &:hover {
      background: #eaeaea;
    }
  }
  .organization {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 10px;
    transition: all 0.5s ease;
    &:hover {
      background: #eaeaea;
    }
    .org-logo {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      margin-right: 10px;
      object-fit: cover;
      border-radius: 50%;
    }
    .round {
      width: 80px;
      border-radius: 10px;
    }
  }
  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 10px;
    transition: all 0.5s ease;
    .profile-logo {
      margin-left: 10px;
    }
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-left: 10px;
      object-fit: cover;
      background: black;
    }
  }
  .search-bar {
    background: #f8f8f8;
    border: 1px solid #bdbab2;
    box-sizing: border-box;
    border-radius: 29px;
    padding: 10px;
    padding-left: 62px;
    width: 235px;
    height: 43px;
    background-image: url('../assets/images/Vector.png');
    background-repeat: no-repeat;
    background-position: left 22px top 11px;
  }
  ::-webkit-input-placeholder {
    color: #bdbab2;
  }
  .item {
    margin: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .settings {
    margin-left: 25px;
    a {
      all: unset;
    }
  }
  .dropdown-item {
    margin-left: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    :hover {
      /* filter: brightness(1.2); */
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
  .screenshot {
    width: 133px;
    height: 74px;
    display: block;
  }
`;

const CannyLink = styled.a`
  color: blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
const Info = styled.p`
  margin-bottom: 0px;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 175px;
  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  padding: 1rem;
  padding-left: 2rem;
  padding-bottom: 28px;
  > * + * {
    margin-left: 10px;
  }
`;

const ChatSearch = styled.div`
  height: 70px;
  border-bottom: solid 1px #f6f5f5;
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .heading {
    font-size: 18px;
  }
  .close-icon {
    color: white;
  }
  .clear-button {
    width: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 37px;
    letter-spacing: 0.5px;
    line-height: 21px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Open Sans';
    background-color: #00b292;
    color: white;
    border: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 300px);
  padding: 0;
`;

const Header = () => {
  let navigate = useNavigate();
  const [setToggle] = useToggle(false);
  const { state } = useContext(AppContext);
  const ref = useRef();
  const [openMessages, setOpenMessages] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openOrganization, setOpenOrganization] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isOpen, toggle] = useToggle(false);
  const [reportText, setReportText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageUpload, setImage] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [screenshotImage, setScreenshot] = useState('');
  const [loadingModal, setLoading] = useState(false);
  const formikRef = useRef();
  const dispatch = useDispatch();
  const [notificationLoader, setNotificationLoader] = useState(false);
  useOnClickOutside(ref, () => setToggle(false));

  const handleMessageClick = () => {
    navigate('/messages');
  };

  const { tokenProcessed, token } = useHandleInvite();
  const [fetchOrganization, { data: getOrganization, loading }] = useLazyQuery(GET_ORGANIZATION, {
    variables: {
      id: state?.orgId
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const getOrganizationDetails = () => {
      if (state?.orgId && state.orgId !== 'null') {
        console.log('fetchingOrgDetails');
        fetchOrganization();
      }
    };
    getOrganizationDetails();
  }, [state.orgId]);

  const NotificationData = useSelector((state: RootState) => state.notifications.notificationData);
  const conversation = useSelector((state: RootState) => state.conversations);
  const unreadMessagesCount = conversation.unreadConversations;
  const activeNotification = NotificationData.filter((data) => data.isRead !== true);

  const closeEditProfile = () => {
    setEditProfile(false);
    formikRef.current?.resetForm();
  };

  const handleReportChange = (e) => {
    setReportText(e.target.value);
  };

  const openReportIssueModal = () => {
    setLoading(true);
    toggle();
  };

  useEffect(() => {
    if (loadingModal) {
      let node = document.getElementById('root');
      htmlToImage.toPng(node).then(function (dataUrl) {
        setScreenshot(dataUrl);
        setLoading(false);
      });
    }
  }, [loadingModal]);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleImage = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleImageUpload = async (e) => {
    let image = e.currentTarget.files[0];
    if (image?.size < 6000000) {
      const toastId = image && toast.loading('Uploading Image');
      const options = {
        // As the key specify the maximum size
        // Leave blank for infinity
        maxWidthOrHeight: 2000,
        maxSizeMB: 0.2,
        initialQuality: 0.6,
        // Use webworker for faster compression with
        // the help of threads
        useWebWorker: true
      };
      // Initialize compression
      // First argument is the file object from the input
      // Second argument is the options object with the config
      Compress(image, options).then((compressedBlob) => {
        toast.success('Uploaded', { id: toastId });
        setImage(URL.createObjectURL(compressedBlob));
        setImageFile(compressedBlob);
      });
    } else {
      image && toast.error('Image size is too large');
    }
  };

  const goToNewTab = async () => {
    const blob = await fetch(screenshotImage).then((res) => res.blob());
    let imageUrl = URL.createObjectURL(blob);
    window.open(imageUrl, '_blank');
  };

  const handleModalClose = () => {
    setImage('');
    setImageFile('');
    setReportText('');
    setScreenshot('');
    toggle();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let id = uuidv4();
    if (imageUpload) {
      const toastId = toast.loading('Sending ....');
      try {
        await Storage.put(id, imageFile, {
          contentType: imageFile.type,
          bucket: process.env.REACT_APP_S3_BUGREPORT_BUCKET_NAME,
          level: 'public'
        }).then(async (res) => {
          if (res.key) {
            let result = await axiosInstance.post('/supportRequest', {
              type: 'BUGREPORT',
              organizationId: state.orgId || null,
              organizationName: getOrganization?.get_Organization?.name || null,
              comments: reportText,
              route: window.location.href,
              screenshotLink: `https://${process.env.REACT_APP_S3_BUGREPORT_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${id}`
            });
            if (result) {
              toast.dismiss(toastId);
              toast.success('Sent , our team will look into it');
            }
          }
        });
      } catch (err) {
        toast.dismiss(toastId);
        toast.error('Something is wrong please try later', { id: toastId });
        console.error('error', err);
      } finally {
        // toast.dismiss(toastId)
        setIsSubmitting(false);
        setReportText('');
        setImage('');
        setImageFile('');
        setTimeout(() => {
          toggle();
        }, 1000);
      }
    } else {
      const toastId = toast.loading('Sending ....');
      const decodedFile = Buffer.from(screenshotImage.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      try {
        await Storage.put(id, decodedFile, {
          contentType: 'image/png',
          bucket: process.env.REACT_APP_S3_BUGREPORT_BUCKET_NAME,
          level: 'public'
        }).then(async (res) => {
          if (res.key) {
            let result = await axiosInstance.post('/supportRequest', {
              type: 'BUGREPORT',
              organizationId: state.orgId || null,
              organizationName: getOrganization?.get_Organization?.name || null,
              comments: reportText,
              route: window.location.href,
              screenshotLink: `https://${process.env.REACT_APP_S3_BUGREPORT_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${id}`
            });
            if (result) {
              toast.dismiss(toastId);
              toast.success('Sent , our team will look into it');
            }
          }
        });
      } catch (err) {
        toast.dismiss(toastId);
        toast.error('Something is wrong please try later');
        console.error('error', err);
      } finally {
        // toast.dismiss(toastId)
        setIsSubmitting(false);
        setReportText('');
        setTimeout(() => {
          toggle();
        }, 1000);
      }
    }
  };

  useEffect(() => {
    //@ts-ignore
    Canny('identify', {
      appID: process.env.REACT_APP_CANNY_APP_ID,
      user: {
        email: state.email,
        name: state.fullName,
        id: state.userId
      }
    });
  }, []);

  const handleCanny = () => {
    window.open('https://platform-suite.canny.io/feature-requests', '_blank');
  };

  const handleCannyRoadmap = () => {
    window.open('https://platform-suite.canny.io/', '_blank');
  };

  if (loading || token) {
    return <span></span>;
  }
  const handleClearAll = async () => {
    setNotificationLoader(true);
    let result = await axiosInstance.post(`/notification/read`, {
      type: 'readall',
      date: new Date()
    });
    if (result) {
      console.log(result);
      dispatch(clearAllNotifications());
      setOpenNotification(false);
      setNotificationLoader(false);
    }
  };

  return (
    <>
      <HeaderStyles>
        <div className="header-left"></div>
        <div className="header-right">
          {getOrganization?.get_Organization?.name ? (
            <FlyInContainer
              closeFunction={() => setOpenOrganization(false)}
              openFunction={() => setOpenOrganization(!openOrganization)}
              openStatus={openOrganization}
              goBack
              trigger={
                <div className="organization">
                  {getOrganization?.get_Organization?.photo ? (
                    <img
                      className="org-logo round"
                      alt="organization"
                      src={`${getOrganization?.get_Organization?.photo}?${new Date().getTime()}`}
                    />
                  ) : (
                    <img className="org-logo" src={organizationPlaceholder} alt="Organization Default" />
                  )}
                  <div>
                    <p> {getOrganization?.get_Organization?.name} </p>
                  </div>
                </div>
              }
              title={
                <ChatSearch>
                  <div className="heading">Organization</div>
                </ChatSearch>
              }
            >
              <OrganizationFlymenu setOpenOrganization={setOpenOrganization} />
            </FlyInContainer>
          ) : (
            <div className="create-org">
              <p
                onClick={() => {
                  navigate('/pricing');
                }}
              >
                {' '}
                + Create Organization
              </p>
            </div>
          )}

          <div className="notifications item">
            <FlyInContainer
              closeFunction={() => setOpenNotification(false)}
              openFunction={() => setOpenNotification(!openNotification)}
              openStatus={openNotification}
              trigger={<CustomSvgIcon icon={<NotificationBell />} count={activeNotification.length} />}
              title={
                <ChatSearch>
                  <div className="heading">Notifications</div>
                  {activeNotification.length ? (
                    <button onClick={() => handleClearAll()} className="clear-button" disabled={loading}>
                      <CloseIcon className="close-icon" />
                      {notificationLoader ? 'Clearing...' : 'Clear All'}
                    </button>
                  ) : null}
                </ChatSearch>
              }
            >
              {NotificationData.length === 0 ? (
                <ImageWrapper>
                  <NoNotification />
                </ImageWrapper>
              ) : (
                <Notifications closeFunction={() => setOpenNotification(false)} />
              )}
            </FlyInContainer>
          </div>
          <div
            className="messages item"
            // onClick={() => setOpenMessages(!openMessages)}
          >
            <FlyInContainer
              closeFunction={() => setOpenMessages(false)}
              openFunction={() => setOpenMessages(!openMessages)}
              openStatus={openMessages}
              trigger={<CustomSvgIcon icon={<Messages />} count={unreadMessagesCount} />}
              goBack
              title={null}
              width={'360px'}
              fullWidth={true}
            >
              <ChatList onClick={handleMessageClick} />
            </FlyInContainer>
          </div>
          <FlyInContainer
            closeFunction={() => setOpenProfile(false)}
            openFunction={() => setOpenProfile(!openProfile)}
            openStatus={openProfile}
            goBack
            trigger={
              <div className="profile">
                {state?.profilePhoto ? (
                  <img src={`${state.profilePhoto}`} alt="profile" />
                ) : state?.fullName ? (
                  <div className="profile-logo ">
                    <Avatar round={true} size="32" name={state?.fullName} textSizeRatio={2} />
                  </div>
                ) : null}
              </div>
            }
            title={
              <ChatSearch>
                <div className="heading">Profile</div>
                <FlyInContainer
                  closeFunction={() => closeEditProfile()}
                  openFunction={() => setEditProfile(!editProfile)}
                  openStatus={editProfile}
                  goBack
                  trigger={<Button>Edit</Button>}
                  title={
                    <ChatSearch>
                      <div className="heading">Profile</div>
                    </ChatSearch>
                  }
                >
                  <EditProfile setEditProfile={setEditProfile} editProfile={editProfile} formikRef={formikRef} />
                </FlyInContainer>
              </ChatSearch>
            }
          >
            <ViewProfile setOpenProfile={setOpenProfile} />
          </FlyInContainer>
          <div className="settings item">
            <ToggleSettings icon={<SettingsIcon />} style={{ top: '55px', right: '-40px' }}>
              <OptionItem disabled={loadingModal} onClick={() => openReportIssueModal()}>
                {loadingModal ? 'Loading...' : 'Report Issue'}
              </OptionItem>
              <OptionItem disabled={loadingModal} onClick={() => handleCanny()}>
                Request Feature
              </OptionItem>
              <OptionItem disabled={loadingModal} onClick={() => handleCannyRoadmap()}>
                View Roadmap
              </OptionItem>
              <OptionItem>
                <Link to="/useragreement" target="_blank" rel="noopener noreferrer">
                  {' '}
                  User Agreement
                </Link>
              </OptionItem>
            </ToggleSettings>
          </div>
        </div>
      </HeaderStyles>
      <Modal open={isOpen} onClose={handleModalClose}>
        <ModalContainer style={{ overflowY: 'auto', maxHeight: '600px' }} width={'400'}>
          <form onSubmit={handleSubmit}>
            <ModalTitle css={{ padding: '12px 320px', whiteSpace: 'nowrap' }}>Report Your Issue</ModalTitle>
            <ModalBody>
              <InputWrapper>
                <Info>We are here to help</Info>
                <Info>
                  Please report the issue you are facing. We need to make sure that we know what happend, so we can get
                  right on with fixing it.{' '}
                </Info>
              </InputWrapper>
              <InputWrapper>
                <Label>Screenshot</Label>
                {imageUpload ? (
                  <ImageContainer>
                    <a href={imageUpload} rel="noreferrer" target="_blank">
                      <img src={imageUpload} alt="Upload" />
                    </a>
                  </ImageContainer>
                ) : (
                  <ImageContainer>
                    {screenshotImage ? (
                      <img
                        className="screenshot"
                        width="300"
                        src={screenshotImage}
                        alt={'ScreenShot'}
                        onClick={goToNewTab}
                      />
                    ) : (
                      <div>
                        <Lottie options={defaultOptions} width={200} height={150} />
                      </div>
                    )}
                  </ImageContainer>
                )}
                <Label>Or</Label>
                <div>
                  <GreyButton disabled={isSubmitting} type="button" onClick={handleImage}>
                    {imageUpload ? 'Upload another Image' : 'Upload an Image'}
                  </GreyButton>
                </div>
                <input
                  type="file"
                  name="file"
                  id="file"
                  style={{ display: 'none' }}
                  ref={hiddenFileInput}
                  accept="image/png, image/jpeg"
                  onChange={(e) => handleImageUpload(e)}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>Report</Label>
                <DescriptionInput
                  disabled={isSubmitting}
                  value={reportText}
                  onChange={(e) => handleReportChange(e)}
                ></DescriptionInput>
              </InputWrapper>
              {/* <InputWrapper> */}
              <p>
                Check out our roadmap and upcoming features. You can request new features or bugs{' '}
                <CannyLink onClick={handleCanny}>Here.</CannyLink>
              </p>
              {/* </InputWrapper> */}
            </ModalBody>
            <ModalWrapper>
              <Button onClick={handleModalClose} secondary>
                Cancel
              </Button>
              <Button disabled={isSubmitting} type="submit">
                Send
              </Button>
            </ModalWrapper>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default Header;
