import React, { useContext, useEffect } from 'react';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import Button from '../../components/CustomButton';
import { Small } from '../../components/styles/SmallText';
import { getDate } from '../../lib/getDate';
import ProjectEditor from '../../components/ProjectNotes';
import { useNavigate, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../components/Loader';
import { ErrorPage } from '../Others';
import { OptionItem, ToggleSettings } from '../../components/OptionsMenu';
import { LIST_MEETING_NOTES_TASKS_QUERY } from '../../graphql/operations/Mutations/MeetingNotes/taskInMeetingNoteMutations';
import { useToggle } from '../../lib/UseToggle';
import PublishNoteModal from '../../components/Modals/ProjectNotes/PublishNote';
import toast from 'react-hot-toast';
import DeleteNoteModal from '../../components/Modals/ProjectNotes/DeleteProjectNote';
import { fetchProjectNote, resetNote } from '../../reducers/projectNote';
import { useDispatch, useSelector } from 'react-redux';
import {
  DELETE_PROJECT_NOTE,
  UPDATE_PROJECT_NOTE_CONTENT_MUTATION
} from '../../graphql/operations/Mutations/ProjectNotes/ProjectNoteMutations';
import { LIST_PROJECT_NOTES_IN_WORKSPACE_QUERY } from '../../graphql/operations/Queries/ProjectNotes/ProjectNoteQueries';
import { AppContext } from '../../AppContext';
import { addTaskInProjectNotesMutation } from '../../graphql/operations/Mutations/ProjectNotes/addTaskMutation';

export interface PROJECT_NOTE_TYPE {
  content: string;
  noteType_typeId: string;
  organizationId_projectId_workspaceId: string;
  _id: string;
  createdBy: string;
  createdAt: string;
  title: string;
  recordStatus: number;
}

const ProjectNote = () => {
  const { noteId, workspaceId, projectId } = useParams();
  const navigate = useNavigate();

  const [isPublishModalOpen, togglePublishModal] = useToggle();
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();

  //@ts-ignore
  const { loading, error, projectNoteData, sections } = useSelector((state) => state.projectNote);
  const { state } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (noteId) dispatch(fetchProjectNote(noteId));

    return () => {
      console.log('Running cleanup');
      dispatch(resetNote());
    };
  }, []);

  // const { handleCreateTask } = useProjectNote(noteId);

  const {
    data: tasks,
    loading: taskLoading,
    error: taskError
  } = useQuery(LIST_MEETING_NOTES_TASKS_QUERY, {
    variables: {
      noteId: noteId,
      type: `workspace`,
      organizationId_projectId_typeId: `${projectNoteData.organizationId}_${projectNoteData.projectId}_${projectNoteData.workspaceId}`
    },
    skip: Object.keys(projectNoteData).length === 0
  });

  const [updateNoteContent, { error: mutationError, loading: mutationLoading }] = useMutation(
    UPDATE_PROJECT_NOTE_CONTENT_MUTATION,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [
    deleteProjectNote
    // { error: mutationError, loading: mutationLoading }
  ] = useMutation(DELETE_PROJECT_NOTE, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: LIST_PROJECT_NOTES_IN_WORKSPACE_QUERY,
        variables: {
          recordStatus: 0,
          noteType_typeId: `workspace_${projectNoteData.workspaceId}`
        }
      }
    ]
  });

  const [addTask] = useMutation(addTaskInProjectNotesMutation, {
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: LIST_MEETING_NOTES_TASKS_QUERY,
        variables: {
          noteId: noteId,
          type: `workspace`,
          organizationId_projectId_typeId: `${projectNoteData.organizationId}_${projectNoteData.projectId}_${projectNoteData.workspaceId}`
        }
      }
    ]
  });

  const handleCreateTask = async (taskTitle: string, blockId: string) => {
    taskTitle = taskTitle !== '' ? taskTitle : 'Task';
    const toastId = toast.loading('Creating task');

    try {
      handleSaveDraft(projectNoteData._id, false);
      const variables: any = {
        type: 'workspace',
        typeId: projectNoteData.workspaceId,
        noteId: projectNoteData._id,
        assignee: state.userId,
        projectId: projectNoteData.projectId,
        workspaceId: projectNoteData.workspaceId,
        organizationId: projectNoteData.organizationId,
        organizationId_projectId_typeId: `${projectNoteData.organizationId}_${projectNoteData.projectId}_${projectNoteData.workspaceId}`,
        assigneeDetails: {
          name: state.fullName
        },
        title: taskTitle,
        recordStatus: 0,
        priority: 'medium',
        status: 'todo',
        updatedBy: state.userId,
        links: [`${window.location.origin}/notebook/${projectNoteData._id}`],
        blockId: blockId
      };
      if (state.profilePhoto) variables.assigneeDetails.profilePicture = state.profilePhoto;

      const data = await addTask({ variables });
      toast.success('Successfully created the task', { id: toastId });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id: toastId });
    }
  };

  const publishNote = async () => {
    const id = toast.loading('Publishing Note');
    try {
      await handleSaveDraft(noteId!, false);
      toast.success('Note published successfully', { id });
      navigate(`/project/workspace/${projectNoteData.projectId}/${projectNoteData.workspaceId}/notebooks/`);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id });
    }
    togglePublishModal();
  };

  const handleDeleteNote = async () => {
    // await deleteNote();
    const id = toast.loading('Deleting note');
    try {
      const data = await deleteProjectNote({
        variables: {
          updateBy: state.userId,
          updatedAt: new Date().toISOString(),
          id: projectNoteData._id,
          recordStatus: 1,
          organizationId_projectId_workspaceId: `${projectNoteData.organizationId}_${projectNoteData.projectId}_${projectNoteData.workspaceId}`
        }
      });
      dispatch(resetNote());
      toast.success('Note deleted successfully', { id });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id });
    }
    navigate(`/project/workspace/${projectId}/${workspaceId}/notebooks`);
  };

  const handleSaveDraft = async (noteId: string, showLoading = true) => {
    const id = showLoading ? toast.loading('Saving note') : null;
    try {
      const data = await updateNoteContent({
        variables: {
          content: JSON.stringify(sections),
          updateBy: state.userId,
          updatedAt: new Date().toISOString(),
          id: noteId,
          isDraft: false,
          organizationId_projectId_workspaceId: `${projectNoteData.organizationId}_${projectNoteData.projectId}_${projectNoteData.workspaceId}`
        }
      });
      id && toast.success('Note saved successfully', { id });
    } catch (error) {
      console.log(error);
      id && toast.error('Something went wrong', { id });
    }
  };

  if (loading || taskLoading) {
    return <Loader />;
  }

  if (error || taskError || mutationError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>{projectNoteData.title}</PageTitle>
            <Small>{getDate(projectNoteData.updatedAt)}</Small>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          {projectNoteData.isDraft ? (
            <>
              <Button onClick={() => handleSaveDraft(noteId!)}>Save Note</Button>
              <Button onClick={togglePublishModal}>Publish Note</Button>
            </>
          ) : (
            <Button onClick={() => handleSaveDraft(noteId!)}>Update Note</Button>
          )}
          {/* <Button>Publish Note</Button> */}
          <ToggleSettings style={{ top: '125px', right: '-70px' }}>
            <OptionItem onClick={toggleDeleteModal}>Delete Note</OptionItem>
          </ToggleSettings>
        </TitleRight>
      </Header>
      <ProjectEditor
        handleCreateTask={handleCreateTask}
        tasks={tasks?.list_TaskItems?._TaskItems}
        saveAsDraft={handleSaveDraft}
      />
      <PublishNoteModal isOpen={isPublishModalOpen} toggleModal={togglePublishModal} publishNote={publishNote} />
      <DeleteNoteModal isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} handleDeleteNote={handleDeleteNote} />
    </div>
  );
};

export default ProjectNote;
