import { Field, FieldArray, FormikProvider } from 'formik';
import Select from 'react-select';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import Button from '../../../components/CustomButton';
import TextAreaField from '../../../components/Reusable/Formik/Textarea';
import { ErrorText } from '../../../components/Reusable/NewFormik/TextError';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  ChoicesWrapper,
  DateWrapper,
  DropDownWrapper,
  FieldSet,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Input } from '../../../components/styles/Inputs';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import { statusStyles } from '../../../components/styles/StatusStyles';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { selectOptions, statusOptions } from '../../../constants/DropDownOptions';
import { customStyles, TaskDetails } from '../../Tasks/TaskViews/CreateTask';
import { LinkInputWrapper } from '../../Library/FilesView/Files/CreateNewFile';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import useCreateWorkspaceTask from '../../../hooks/features/workspaces/useCreateWorkspaceTask';
import Loader from '../../../components/Loader';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import LinkDisplay from '../../../components/LinkDisplay';

function CreateWorkspaceTask() {
  const { formik, users, loadingWorkspaceMembers, doNothing, submitting, state } = useCreateWorkspaceTask();
  if (loadingWorkspaceMembers) {
    return <Loader />;
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>New Task</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button type="submit" disabled={submitting}>
              Create Task
            </Button>
          </TitleRight>
        </Header>
        <TaskDetails>
          <FieldSet>
            <div>
              <InputWrapper>
                <InputField
                  name="title"
                  type="text"
                  label="New Task"
                  id="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  errors={formik.errors.title}
                  touched={formik.touched.title}
                  onBlur={formik.handleBlur}
                  disabled={submitting}
                />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="description">Description</Label>
                <TextAreaField
                  name="description"
                  formik={formik}
                  disabled={submitting}
                  placeHolder="Description"
                  id="description"
                />
              </InputWrapper>
              <LinkWrapper>
                <Label>Links</Label>
                <FormikProvider value={formik}>
                  <FieldArray name="links">
                    {({ insert, remove, push }) => (
                      <>
                        {formik.values.links &&
                          formik.values.links.length > 0 &&
                          formik.values.links.map((link, index) => {
                            return (
                              <>
                                {link.saved ? (
                                  <LinkDisplay link={link.value} removeLink={() => remove(index)} />
                                ) : (
                                  <Field name={`links.${index}`}>
                                    {({ meta, field }) => (
                                      <>
                                        <LinkInputWrapper>
                                          <Input
                                            className="link-input"
                                            disabled={submitting}
                                            type="text"
                                            {...field}
                                            value={field.value.value}
                                            onChange={(e) =>
                                              formik.setFieldValue(`links[${index}].value`, e.target.value)
                                            }
                                            error={meta.touched && meta.error}
                                          />
                                          <Button
                                            disabled={submitting}
                                            onClick={() => {
                                              if (!meta.error?.value && field.value.value !== '') {
                                                formik.setFieldValue(`links[${index}].saved`, true);
                                              }
                                            }}
                                          >
                                            Save
                                          </Button>
                                          <CloseIcon
                                            onClick={() => (!submitting ? remove(index) : doNothing())}
                                            height="25px"
                                            width="25px"
                                          />
                                        </LinkInputWrapper>
                                        {meta.touched && meta.error && (
                                          <ErrorText>{meta.error.value || meta.error.saved} </ErrorText>
                                        )}
                                      </>
                                    )}
                                  </Field>
                                )}
                              </>
                            );
                          })}
                        <div>
                          <Button
                            disabled={formik.isSubmitting || submitting}
                            onClick={() => push({ value: '', saved: false })}
                          >
                            + Add Link
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </LinkWrapper>
            </div>
            <div>
              <ChoicesWrapper>
                <Label>Status</Label>
                <Select
                  name="status"
                  options={statusOptions}
                  defaultValue={statusOptions[0]}
                  onChange={(value: any) => {
                    formik.setFieldValue('status', value ? value : '');
                  }}
                  isDisabled={submitting}
                  styles={statusStyles}
                />
                <Label className="priority">Priority</Label>
                <Select
                  name="priority"
                  options={selectOptions}
                  defaultValue={selectOptions[1]}
                  styles={statusStyles}
                  isDisabled={submitting}
                  onChange={(value: any) => {
                    formik.setFieldValue('priority', value ? value : '');
                  }}
                />
              </ChoicesWrapper>
              <DateWrapper>
                <Label>Due Date</Label>
                <DateInputField
                  name="dueDate"
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.dueDate}
                  disabled={submitting}
                  touched={formik.touched.dueDate}
                  errors={formik.errors.dueDate}
                  min={new Date().toISOString().slice(0, 10)}
                />
              </DateWrapper>
              <AssigneeWrapper>
                <Label>Reporter</Label>
                <AssigneeSeparator>
                  <AvatarStyles>
                    <CustomAvatar name={`${state.fullName}`} size={21} />
                  </AvatarStyles>
                  <Small>
                    {getDate()} {getTime()}
                  </Small>
                </AssigneeSeparator>
              </AssigneeWrapper>
              <DropDownWrapper>
                <Label>Assigned To</Label>
                <Select
                  name="assignedTo"
                  options={users}
                  isSearchable={true}
                  isClearable={true}
                  placeholder={''}
                  isDisabled={submitting}
                  menuPortalTarget={document.body}
                  onBlur={formik.handleBlur}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  onChange={(value: any) => {
                    formik.setFieldValue('assignedTo', value ? value.id : '');
                    formik.setFieldValue('assignedToDetails.name', value ? value.value : '');
                  }}
                />
              </DropDownWrapper>
              {!!formik.errors['assignedTo'] && formik.touched['assignedTo'] && (
                <p style={{ color: '#ff3c3c', marginLeft: '1rem', marginTop: '0px' }}>{formik.errors['assignedTo']}</p>
              )}
            </div>
          </FieldSet>
        </TaskDetails>
      </form>
    </>
  );
}

export default CreateWorkspaceTask;
