import React from 'react';

import { useParams } from 'react-router';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';

const SaveAndPublishModal = ({
  isOpen,
  toggleModal,
  handleSaveAndPublish
}: {
  isOpen: boolean;
  toggleModal: () => void;
  handleSaveAndPublish: (noteId: string) => void;
}) => {
  const { noteId } = useParams();
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Save and Publish Note</ModalTitle>
        <ModalBody>
          <ModalText>This will notify all members in the shared and attendee list. Do you want to continue?</ModalText>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={false} onClick={() => handleSaveAndPublish(noteId!)}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default SaveAndPublishModal;
