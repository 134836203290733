import React from 'react';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
// import { useNavigate } from 'react-router';

function SubscriptionIsActiveModal(props) {
  // let navigate = useNavigate()
  const { isOpen, onClose } = props;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer width="638px">
        <ModalTitle>Collaborative space is active</ModalTitle>
        <ModalBody>
          <ModalText>
            Your subscription is successfully active. Please assign seats to the members in the waiting list to utiilze
            the organization space
          </ModalText>
        </ModalBody>
        <ModalWrapper>
          <Button onClick={onClose}>Go to organization</Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
}

export default SubscriptionIsActiveModal;
