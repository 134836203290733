import styled from '@emotion/styled';
import { Formik } from 'formik';
import Select from 'react-select';
import { userRoles } from '../../../constants/UserRoles';

import Button, { CustomButton } from '../../CustomButton';
import Modal from '../../Modal';
import InputField from '../../Reusable/NewFormik/Input';

import { Label } from '../../styles/Label';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { registerTypeStyles } from '../../styles/RegisterTypeStyles';

const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 10px;
`;
const Text = styled.p`
  font-weight: 600;
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;
const InputWrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
`;
const ChoicesWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: center;
`;

type SProps = {
  isOpen: boolean;
  toggle: () => void;
  validationSchema: any;
  initialvalues: any;
  handleSubmit: (values: any) => Promise<void>;
  isSubmitting: boolean;
};

function InviteMembersToOrganizationModal(props: SProps) {
  const { isOpen, toggle, validationSchema, initialvalues, handleSubmit, isSubmitting } = props;

  return (
    <>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="496px">
          <Formik initialValues={initialvalues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ModalTitle>Add members to organization</ModalTitle>
                <ModalBody>
                  <ModalText>Invite a member to this organization</ModalText>
                  <ChoicesWrapper>
                    <Label htmlFor="memberType">Member Type :</Label>
                    <Select
                      name="memberType"
                      options={userRoles}
                      defaultValue={userRoles[0]}
                      styles={registerTypeStyles}
                      onChange={(selectedOption) => {
                        setFieldValue(
                          'memberType',
                          selectedOption.value ? { value: selectedOption?.value, label: selectedOption?.label } : ''
                        );
                      }}
                    />
                  </ChoicesWrapper>
                  <InputWrapper>
                    <InputField
                      name="emailTo"
                      label="Email:"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.emailTo}
                      errors={errors.emailTo}
                      touched={touched.emailTo}
                      disabled={isSubmitting}
                    />
                  </InputWrapper>
                </ModalBody>
                <ButtonWrapper>
                  <Button disabled={isSubmitting} onClick={toggle} secondary>
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting} type="submit">
                    Send
                  </Button>
                </ButtonWrapper>
              </form>
            )}
          </Formik>
        </ModalContainer>
      </Modal>
    </>
  );
}
export default InviteMembersToOrganizationModal;
