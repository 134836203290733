import { useCallback, useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { Member } from '../../../components/Chat/types';
import LiveConnectionContext from '../../../contexts/LiveConnectionContext';
import store, { useAppDispatch } from '../../../store';
import {
  blockConversation,
  continueConversation,
  incomingMessage,
  leaveGroup,
  readMessage,
  updateGroupMember
} from './../../../reducers/conversations_new';
import { v4 as uuid } from 'uuid'

const useChatSocket = () => {
  const {
    state: { authToken, userId }
  } = useContext(AppContext);

  const dispatch = useAppDispatch();

  // const {
  //   orgData,
  //   nonOrgData,
  //   selectedConversationId
  // }: {
  //   orgData: Conversations;
  //   nonOrgData: Conversations;
  //   selectedConversationId: string | null
  // } = useSelector(
  //   (state: RootState) => state.conversations_new
  // );

  // @ts-ignore
  const ws: WebSocket = useContext(LiveConnectionContext);

  /**
   * Send Message using web socket
   @param : text : Message you want to send
   */
  const sendMessage = useCallback(
    (text: string) => {
      const {
        conversations: { selectedConversationId, orgData, nonOrgData }
      } = store.getState();
      if (!selectedConversationId) {
        return;
      }
      const allConvs = { ...orgData, ...nonOrgData };
      const data = {
        token: authToken,
        conversationId: selectedConversationId,
        type: 'CREATE_MESSAGE',
        message: text,
        messageType: 'text',
        sameOrg: allConvs[selectedConversationId][0].sameOrg,
        sentBy: userId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: uuid()

      };

      ws.send(JSON.stringify(data));
      //@ts-ignore
      dispatch(incomingMessage({ data, currentUser: userId }));
    },
    [authToken, userId, ws, dispatch]
  );

  /**
   * Send read conversation web socket request
   */
  const readConversation = (conversationId: string) => {
    const data = {
      token: authToken,
      conversationId: conversationId,
      type: 'UPDATE_UNREAD_COUNT',
      sendBy: userId
    };
    ws.send(JSON.stringify(data));
    dispatch(readMessage(conversationId));
  };

  const sendFileMessage = (fileType: 'IMAGE' | 'DOCUMENT', fileId: string, fileName: string, fileSize: number) => {
    const {
      conversations: { selectedConversationId, orgData, nonOrgData }
    } = store.getState();

    if (!selectedConversationId) {
      return;
    }
    const allConvs = { ...orgData, ...nonOrgData };
    const data = {
      token: authToken,
      conversationId: selectedConversationId,
      type: 'CREATE_MESSAGE',
      messageType: 'file',
      mediaType: fileType,
      message: fileName,
      mediaSize: fileSize,
      sameOrg: allConvs[selectedConversationId][0].sameOrg,
      fileId: fileId,
      sentBy: userId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      id: uuid()

    };

    ws.send(JSON.stringify(data));
    //@ts-ignore
    dispatch(incomingMessage({ data, currentUser: userId }));
  };

  const blockChat = () => {
    const {
      conversations: { selectedConversationId, orgData, nonOrgData }
    } = store.getState();

    const allConvs = { ...orgData, ...nonOrgData };

    if (!selectedConversationId) return;
    const data = {
      type: 'BLOCK_CONVERSATION',
      conversationId: selectedConversationId,
      token: authToken,
      sameOrg: allConvs[selectedConversationId][0].sameOrg
    };
    ws.send(JSON.stringify(data));
    dispatch(blockConversation({ ...data, sentBy: userId, blockedBy: userId }));
  };

  const continueChat = () => {
    const {
      conversations: { selectedConversationId, orgData, nonOrgData }
    } = store.getState();

    const allConvs = { ...orgData, ...nonOrgData };

    if (!selectedConversationId) return;
    const data = {
      type: 'CONTINUE_CONVERSATION',
      conversationId: selectedConversationId,
      token: authToken,
      sameOrg: allConvs[selectedConversationId][0].sameOrg
    };
    ws.send(JSON.stringify(data));

    dispatch(continueConversation({ ...data, sentBy: userId, endpointURL: '' }));
  };

  const leaveChat = (remainingMembers: Member[], deleteUserId: string) => {
    const {
      conversations: { selectedConversationId, orgData, nonOrgData }
    } = store.getState();

    if (!selectedConversationId) return;
    const data = {
      token: authToken,
      conversationId: selectedConversationId,
      type: 'LEAVE_GROUP',
      selected: remainingMembers,
      userId,
      conversationOwner: [userId],
      deleteUserId: [deleteUserId],
      addUserId: [],
      sentBy: userId
    };
    ws.send(JSON.stringify(data));
    dispatch(leaveGroup({ data, currentUser: userId }));
  };

  const updateGroup = (
    groupName: string,
    remainingMembers: Member[],
    removedMembersIds: string[],
    addMembersIds: string[]
  ) => {
    const {
      conversations: { selectedConversationId, orgData, nonOrgData }
    } = store.getState();

    if (!selectedConversationId) return;
    const data = {
      token: authToken,
      conversationId: selectedConversationId,
      selected: remainingMembers,
      type: 'UPDATE_GROUP_MEMBERS',
      userId,
      groupName: groupName,
      conversationOwner: userId,
      deleteUserId: removedMembersIds,
      addUserId: addMembersIds,
      sentBy: userId,
    };
    ws.send(JSON.stringify(data));
    dispatch(
      updateGroupMember({ data: { ...data, deleted: data.deleteUserId, added: data.addUserId }, currentUser: userId })
    );
  };

  return {
    sendMessage,
    readConversation,
    sendFileMessage,
    blockChat,
    continueChat,
    leaveChat,
    updateGroup
  };
};

export default useChatSocket;
