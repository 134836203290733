import styled from '@emotion/styled';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { AppContext } from '../../../../AppContext';
import { ModalText } from '../../../../pages/Workspaces/Registers/CreateNewRegister';
import CreatableSelect from 'react-select/creatable';
import { getOrganizationUsers } from '../../../../lib/getOrganizationUsers';
import { useNavigate, useParams } from 'react-router';
import { MeetingNoteI, SectionI, UserDataI } from '../../../../pages/MeetingNotes/types';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/CustomButton';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../../components/styles/ModalStyles';
import axiosInstance from '../../../../axiosInstance';
import { Member } from '../../../../components/Modals/MeetingNotes/PublishNoteModals/AttendeeListModal';
import capitalizeFirstLetter from '../../../../lib/CapitaliseFirstLetter';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';

export interface MemberWithPermission extends Member {
  permission: { value: string; label: string };
  isAttendee: boolean;
}

const permissionOptions = [
  { value: 'viewer', label: 'Viewer' },
  { value: 'editor', label: 'Editor' }
];

const InputContainer = styled.div`
  width: 464px;
  /* height: 61px; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f5;
  border-radius: 5px;
  margin: auto;
  font-size: 16px;

  .attendee-select {
    /* flex-grow: 4; */
    width: 75%;
    margin-right: 10px;
  }

  .permission-select {
    border: none;
    width: 25%;
    /* flex-grow: 1; */
  }
`;

const UserDetails = styled.div`
  background-color: #f6f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 464px;
  height: 44px;
  margin: auto;
  padding: 16px 20px;
  margin-top: 10px;
  border-radius: 5px;
  color: #1c1c1c66;
`;

const SharedModal = ({
  isOpen,
  toggleModal,
  handlePublishNote,
  organizationID,
  meetingNoteData,
  sharedList,
  attendeeList,
  fetchData
}: {
  isOpen: boolean;
  toggleModal: () => void;
  handlePublishNote: (selectedMembers: any, selectedPermission: any, selectedAttendees: any) => void;
  organizationID: string;
  meetingNoteData: MeetingNoteI;
  sharedList: any;
  attendeeList: any;
  fetchData: () => void;
}) => {
  const {
    state: { userId }
  } = useContext(AppContext);
  let { noteId } = useParams();
  const navigate = useNavigate();

  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<{ value: string; label: string } | null>({
    value: 'viewer',
    label: 'Viewer'
  });

  const [attendeeMembers, setAttendeeMembers] = useState([]);

  useEffect(() => {
    if (!organizationID) return;
    fetchUsersList();
  }, [organizationID]);

  const location = useLocation();
  const isSharedPage = location.pathname.includes('shared');

  //Fetch and filter users
  const fetchUsersList = async () => {
    const users = await getOrganizationUsers(organizationID, userId);
    let memberList = users?.filter((user: Member) => !user.label.includes('Myself'));

    /*Filter shared users
     check if platform suite(which has userData) or not
    */
    let sharedMembers = sharedList.map((member: any) => {
      if (member.hasOwnProperty('userData')) {
        return {
          id: member.userData.id,
          email: member.userData.email,
          value: member.userData.fullName,
          label: member.userData.fullName
        };
      } else {
        return {
          id: member.id,
          email: member.emailId,
          value: member.emailId,
          label: member.emailId
        };
      }
    });
    //Remove already shared users
    const sharedMemberList = memberList.filter((object1: any) => {
      return !sharedMembers.some((object2: any) => object1.id === object2.id);
    });
    setMembers(sharedMemberList);

    let attendeeMembers = attendeeList.map((member: any) => {
      if (member.hasOwnProperty('userData')) {
        return {
          id: member.userData.id,
          email: member.userData.email,
          value: member.userData.fullName,
          label: member.userData.fullName
        };
      } else {
        return {
          id: member.id,
          email: member.emailId,
          value: member.emailId,
          label: member.emailId
        };
      }
    });

    //Remove already shared users
    const attendeeMemberList = memberList.filter((object1: any) => {
      return !attendeeMembers.some((object2: any) => object1.id === object2.id);
    });

    setAttendeeMembers(attendeeMemberList);
  };

  const handleChange = (options: any) => {
    setSelectedMembers(options);
  };

  const handleAttendeeChange = (options: any) => {
    setSelectedMembers(options);
  };

  const addMemberToSharedList = async () => {
    const usersData: UserDataI[] = [];

    const id = toast.loading('Sharing note');

    selectedMembers.map((members: any) => {
      if (members.id) {
        usersData.push({ userId: members.id, access: selectedPermission!.value, memberType: 'SHARED' });
      } else {
        usersData.push({ emailId: members.value, access: selectedPermission!.value, memberType: 'SHARED' });
      }
    });

    if (selectedMembers.length === 0) {
      toast.error('Please select one or more member');
      return;
    }
    try {
      const data = await axiosInstance.patch(`/meetingNotes/${noteId}`, {
        organizationId: meetingNoteData.organizationId,
        workspaceId: meetingNoteData.workspaceId,
        projectId: meetingNoteData.projectId,
        usersData: usersData
      });

      setSelectedMembers([]);
      fetchData();
      toast.success('Note shared successfully', { id });
      toggleModal();
    } catch (error) {
      toast.error('Something went wrong', { id });
    }
  };

  const addMemberToAccessList = async () => {
    const usersData: UserDataI[] = [];

    const id = toast.loading('Sharing note');

    selectedMembers.map((members: any) => {
      if (members.id) {
        usersData.push({ userId: members.id, access: selectedPermission!.value, memberType: 'ATTENDEE' });
      } else {
        usersData.push({ emailId: members.value, access: selectedPermission!.value, memberType: 'ATTENDEE' });
      }
    });

    if (selectedMembers.length === 0) {
      toast.error('Please select one or more member');
      return;
    }
    try {
      const data = await axiosInstance.patch(`/meetingNotes/${noteId}`, {
        organizationId: meetingNoteData.organizationId,
        workspaceId: meetingNoteData.workspaceId,
        projectId: meetingNoteData.projectId,
        usersData: usersData
      });

      setSelectedMembers([]);
      toast.success('Note shared successfully', { id });
      fetchData();

      toggleModal();
    } catch (error) {
      toast.error('Something went wrong', { id });
    }
  };

  return isSharedPage ? (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Shared List</ModalTitle>
        <ModalBody>
          <ModalText>Search for user or enter email to share this note</ModalText>
          <InputContainer>
            <CreatableSelect
              isMulti
              className="attendee-select"
              options={members}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleChange}
              placeholder="Search Users"
              formatCreateLabel={(userInput) => `Share with ${userInput}`}
            />

            <Select
              options={permissionOptions}
              defaultValue={permissionOptions[0]}
              name="permission"
              className="permission-select"
              components={{ IndicatorSeparator: () => null }}
              onChange={(option) => {
                setSelectedPermission(option);
              }}
            />
          </InputContainer>
          <div>
            {sharedList.map((user: any) => (
              <UserDetails key={user.id}>
                {user.hasOwnProperty('userData') ? <p>{user.userData.fullName}</p> : <p>{user?.emailId}</p>}
                <div className="right-item">
                  <p>{capitalizeFirstLetter(user.access)}</p>
                </div>
              </UserDetails>
            ))}
          </div>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={addMemberToSharedList}>Share</Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  ) : (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Attendee List</ModalTitle>
        <ModalBody>
          <ModalText>Search for user or enter email to share this note</ModalText>
          <InputContainer>
            <CreatableSelect
              isMulti
              className="attendee-select"
              options={attendeeMembers}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleAttendeeChange}
              placeholder="Search Users"
              formatCreateLabel={(userInput) => `Share with ${userInput}`}
            />

            <Select
              options={permissionOptions}
              defaultValue={permissionOptions[0]}
              name="permission"
              className="permission-select"
              components={{ IndicatorSeparator: () => null }}
              onChange={(option) => {
                setSelectedPermission(option);
              }}
            />
          </InputContainer>
          <div>
            {attendeeList.map((user: any) => (
              <UserDetails key={user.id}>
                {user.hasOwnProperty('userData') ? <p>{user.userData.fullName}</p> : <p>{user?.emailId}</p>}
                <div className="right-item">
                  <p>{capitalizeFirstLetter(user.access)}</p>
                </div>
              </UserDetails>
            ))}
          </div>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={addMemberToAccessList}>Share</Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default SharedModal;
