import React from 'react';
import {
  PricingText,
  SeatBox,
  SeatQuantityBox,
  SeatQuantityText,
  SeatSelectionDiv,
  SeatSelectionTitle,
  SeatWrapper,
  SubscriptionType,
  TotalAmountText
} from './SelectSeats';
import { ReactComponent as PlusIcon } from '../../assets/icons/GreenPlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/MinusIcon.svg';
import { PlanType } from '../../pages/Organization/ViewOrganization';
import { PageMode } from '../../pages/Payments/MonthlySubscriptionPage';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';

type UpdateCurrentPlanSeatsProps = {
  plan: PlanType;
  pageMode: PageMode;
  increaseSeatQuantity: () => void;
  decreaseSeatQuantity: () => void;
  currentQuantity: number;
};

function UpdateCurrentPlanSeats({
  plan,
  pageMode,
  increaseSeatQuantity,
  decreaseSeatQuantity,
  currentQuantity
}: UpdateCurrentPlanSeatsProps) {
  let planType = plan === 'month' ? 'monthly' : 'yearly';
  let seatCharge = plan === 'month' ? 17 : 9;

  return (
    <>
      <SeatWrapper style={{ paddingBottom: '10px', paddingTop: '24px', marginTop: '26px', width: '90%' }}>
        <SeatSelectionTitle style={{ marginTop: '0px' }}>
          Update Your {capitalizeFirstLetter(planType)} Subscription
        </SeatSelectionTitle>
        <SeatBox>
          <PricingText>Each seat billed {planType} for US$ 17.00/Month</PricingText>
          <SeatSelectionDiv>
            <div>
              <SeatQuantityText>Seat Quantity</SeatQuantityText>
              <SeatQuantityBox>
                <div>
                  {pageMode === 'update' && (
                    <button onClick={() => decreaseSeatQuantity()}>
                      <MinusIcon />
                    </button>
                  )}
                </div>
                <div style={{ textAlign: 'center' }}>{currentQuantity}</div>
                <div>
                  {' '}
                  {pageMode === 'update' && (
                    <button onClick={() => increaseSeatQuantity()}>
                      <PlusIcon />
                    </button>
                  )}
                </div>
              </SeatQuantityBox>
            </div>
            <div>
              <SubscriptionType>{capitalizeFirstLetter(planType)} Bill Amount</SubscriptionType>
              {plan === 'month' && <TotalAmountText>US$ {seatCharge * currentQuantity}.00</TotalAmountText>}
              {plan === 'year' && <TotalAmountText>US$ {seatCharge * currentQuantity * 12}.00</TotalAmountText>}
            </div>
          </SeatSelectionDiv>
        </SeatBox>
      </SeatWrapper>
    </>
  );
}

export default UpdateCurrentPlanSeats;
