import styled from '@emotion/styled';
import Button from '../../../../../components/CustomButton';
import { DarkGreenButton } from '../../../../../components/styles/ButtonStyles';
import { Container } from '../../../../Projects/ProjectViews/CreateProject';
import { editTaskOptions } from '../../../../../constants/DropDownOptions';
import { Reducer, useContext, useMemo, useReducer } from 'react';
import Select from 'react-select';
import { tableStatusStyles } from '../../../../../components/styles/StatusStyles';
import { getValue } from '../../../../../components/Tasks';
import { HighPriority, LowPriority, MediumPriority } from '../../../../../components/TableComponents/Priority';
import AssociatedTasksTable from '../../../../../components/AssociatedTasksTable/AssociatedTasksTable';
import { useNavigate, useParams } from 'react-router';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  LIST_ASSOCIATE_TASKS_QUERY,
  SINGLE_TASK_QUERY
} from '../../../../../graphql/operations/Queries/Tasks/TaskQueries';
import { ErrorPage } from '../../../../Others';
import Loader from '../../../../../components/Loader';
import { AppContext } from '../../../../../AppContext';
import getUtcDate from '../../../../../lib/getUtcDate';
import { useToggle } from '../../../../../lib/UseToggle';
import AssociatedTaskModal from '../../../../../components/Modals/AssociatedTaskModal';
import { EmptyDataContainer, ProjectsContainer } from '../../../../Projects/ProjectViews/Projects';
import { OptionItem, ToggleSettings } from '../../../../../components/OptionsMenu';
import toast from 'react-hot-toast';
import axiosInstance from '../../../../../axiosInstance';
import { sentryError } from '../../../../../lib/SentryError';

const AssociatedTasksActionItems = styled.div`
  display: flex;
  margin-left: 24px;
  margin-right: 52px;
  justify-content: space-between;
  align-items: center;
`;

const CreateButton = styled(Button)`
  background: white;
  color: hsla(167, 18%, 36%, 1);
  border: 1px solid hsla(167, 18%, 36%, 1);
  padding: 4px 12px;

  font-weight: 600;
  :hover {
    color: white;
    background: hsla(167, 18%, 36%, 1);
  }
`;

const AddButton = styled(DarkGreenButton)`
  padding: 4px 12px;
  height: 37px;
`;

const ActionButtons = styled.div`
  display: flex;
  button {
    margin-left: 16px;
  }
`;

const Title = styled.h5`
  font-size: 1rem;
  font-weight: 600;
`;

const EmptyTasksContainer = styled(ProjectsContainer)``;

type associateTask = {
  _id: string;
  acknowledgement: string;
  assignedTo: string;
  assignedToDetails: {
    name: string;
    profilePicture: string;
  };
  assignee: string;
  assigneeDetails: {
    name: string;
    profilePicture: string;
  };
  dueDate: string;
  isAcknowledged: boolean;
  isAssociated: boolean;
  organizationId: string;
  organizationId_projectId_typeId: string;
  parentAssociateId: string | null;
  priority: string;
  projectId: string;
  recordStatus: string;
  status: string;
  title: string;
  workspaceId: string;
  typeId: string;
  type: string;
};

type associateTaskState = associateTask[];

type ActionType =
  | { type: 'SET_DATA'; payload: associateTaskState }
  | { type: 'ADD_TASK'; payload: associateTask }
  | { type: 'REMOVE_TASK'; payload: { id: string } };

const initialState: associateTaskState = [];
const associateTasksReducer: Reducer<associateTaskState, ActionType> = (
  state: associateTaskState,
  action: ActionType
) => {
  switch (action.type) {
    case 'SET_DATA':
      return action.payload;
    case 'ADD_TASK':
      return [...state, action.payload];
    case 'REMOVE_TASK':
      let newTasks = state.filter((tasks) => tasks._id !== action.payload.id);
      return [...newTasks];
    default:
      return state;
  }
};

function ListAssociatedTasksPage() {
  const [allTasks, dispatch] = useReducer(associateTasksReducer, initialState);
  const { state } = useContext(AppContext);
  let navigate = useNavigate();
  let { taskId } = useParams();
  const [isOpen, toggleModal] = useToggle();
  const {
    data: taskData,
    error,
    loading
  } = useQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (taskData?.get_Task?.isAssociated) {
        getAssociateTasks();
      }
      if (taskData?.get_Task?.parentAssociateId) {
        getParentTask();
      }
    }
  });

  const [getAssociateTasks, { data: associateTaskData, error: associateTaskError, loading: associateTaskLoading }] =
    useLazyQuery(LIST_ASSOCIATE_TASKS_QUERY, {
      variables: {
        parentAssociateId: taskId,
        organizationId_projectId_typeId: `${state.orgId}_${taskData?.get_Task?.projectId}_${taskData?.get_Task?.typeId}`,
        type: taskData?.get_Task?.type
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        console.log('ASSOSICATE TASK DATA', associateTaskData.list_TaskItems._TaskItems);
        if (
          associateTaskData?.list_TaskItems?._TaskItems.length &&
          associateTaskData?.list_TaskItems?._TaskItems.length > 0
        ) {
          dispatch({ type: 'SET_DATA', payload: associateTaskData?.list_TaskItems?._TaskItems });
        }
      }
    });

  const [getParentTask, { data: parentTaskdata }] = useLazyQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskData?.get_Task?.parentAssociateId },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      console.log('PTD', parentTaskdata?.get_Task);
      dispatch({ type: 'ADD_TASK', payload: parentTaskdata?.get_Task });
    }
  });

  const viewTask = (id: string) => {
    navigate(`/dashboard/tasks/${id}`);
  };

  const createAssociatedTask = () => {
    navigate(`/dashboard/tasks/new`, {
      state: {
        ...taskData?.get_Task
      }
    });
  };

  const removeTask = async (id: string) => {
    let toastId = toast.loading('Removing...');
    await axiosInstance
      .patch('/task', {
        associateToTaskIds: [id],
        associateByTaskId: taskId,
        removeAssociate: true
      })
      .then((res) => {
        console.log('removetask', res);
        dispatch({ type: 'REMOVE_TASK', payload: { id } });
        toast.dismiss(toastId);
        toast.success('Removed Association');
      })
      .catch((error) => {
        toast.error(error?.message);
        sentryError(error);
      });
  };

  const COLUMNS = [
    {
      Header: 'TASK',
      accessor: 'title',
      Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
    },

    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cell: { row, value } }) => {
        return (
          <span onClick={(e) => e.stopPropagation()}>
            <Select
              name="status"
              options={editTaskOptions}
              defaultValue={getValue(editTaskOptions, value)}
              styles={{ ...tableStatusStyles }}
              width="200px"
              menuPortalTarget={document.body}
              // onChange={(selectedOption) => {
              //   updateStatus(selectedOption, row.original._id, row.original);
              // }}
            />
          </span>
        );
      }
    },
    {
      Header: 'Priority',
      accessor: 'priority',
      Cell: ({ cell: { value } }) =>
        value === 'high' ? <HighPriority /> : value === 'medium' ? <MediumPriority /> : <LowPriority />
    },
    {
      Header: 'Due Date',
      accessor: 'dueDate',
      Cell: ({ cell: { value } }) => <span>{getUtcDate(value)}</span>
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (props) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ToggleSettings style={{ zIndex: '100' }}>
            <OptionItem onClick={() => removeTask(props.cell.row.original?._id)}>Remove Task</OptionItem>
          </ToggleSettings>
        </div>
      )
    }
  ];
  const data = useMemo(() => allTasks, [allTasks]);
  const columns = useMemo(() => COLUMNS, [allTasks]);

  if (error || associateTaskError) {
    return <ErrorPage />;
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <AssociatedTasksActionItems>
        <Title>Associated Tasks</Title>
        <ActionButtons>
          <CreateButton onClick={createAssociatedTask}>Create Associated Task</CreateButton>
          <AddButton onClick={toggleModal}> + Add Associated Task</AddButton>
        </ActionButtons>
      </AssociatedTasksActionItems>
      <Container style={{ marginRight: '52px' }}>
        {associateTaskLoading ? (
          <Loader />
        ) : data.length === 0 ? (
          <EmptyTasksContainer>
            <div className="empty-data">
              <EmptyDataContainer>No Associated Tasks found. You can create or add them.</EmptyDataContainer>
            </div>
          </EmptyTasksContainer>
        ) : (
          <AssociatedTasksTable clickHandler={viewTask} data={data} columns={columns} />
        )}
      </Container>
      <AssociatedTaskModal isOpen={isOpen} toggle={toggleModal} dispatch={dispatch} />
    </>
  );
}

export default ListAssociatedTasksPage;
