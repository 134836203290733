import React from "react";
import styled from "@emotion/styled";
import { ReactComponent as RedArrow } from "../../assets/icons/High.svg";
import { ReactComponent as YellowArrow } from "../../assets/icons/Medium.svg";
import { ReactComponent as BlueArrow } from "../../assets/icons/Low.svg";

const HighPriorityText = styled.span`
  color: var(--high-priority);
`;

const MediumPriorityText = styled.span`
  color: var(--medium-priority);
`;
const LowPriorityText = styled.span`
  color: var(--low-priority); ;
`;

export const HighPriority = () => {
  return (
    <HighPriorityText>
      <RedArrow /> High
    </HighPriorityText>
  );
};
export const MediumPriority = () => {
  return (
    <MediumPriorityText>
      <YellowArrow /> Medium
    </MediumPriorityText>
  );
};

export const LowPriority = () => {
  return (
    <LowPriorityText>
      <BlueArrow /> Low
    </LowPriorityText>
  );
};
