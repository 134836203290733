import styled from '@emotion/styled';
import { getDate } from '../lib/getDate';
import { ReactComponent as FileIcon } from '../assets/icons/NewFileIcon.svg';

const Divider = styled.span`
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

const PublishedSpan = styled.span`
  margin-left: 5px;
  color: #93979f;
  font-size: 15px;
`;

export const MockMeetingNoteColumns = [
  {
    id: 'Note',
    Header: 'Note',
    accessor: 'title',
    Cell: (props: any) => {
      return (
        <Divider>
          <FileIcon />
          {props.row.original.title}
          {!props.row.original.isDraft && <PublishedSpan>[Published]</PublishedSpan>}
        </Divider>
      );
    }
  },
  {
    id: 'createdOn',
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) => getDate(value)
  },

  {
    id: 'updatedOn',
    Header: 'Last Updated On',
    accessor: 'updatedAt',
    Cell: ({ cell: { value } }) => getDate(value)
  }
];

export const MockMeetingNotesData = [
  {
    id: '1',
    noteTitle: 'Meeting Note 1',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '2',
    noteTitle: 'Meeting Note 2',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '3',
    noteTitle: 'Meeting Note 3',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '4',
    noteTitle: 'Meeting Note 4',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '5',
    noteTitle: 'Meeting Note 5',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '6',
    noteTitle: 'Meeting Note 6',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '7',
    noteTitle: 'Meeting Note 7',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '8',
    noteTitle: 'Meeting Note 8',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '9',
    noteTitle: 'Meeting Note 9',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '10',
    noteTitle: 'Meeting Note 10',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '11',
    noteTitle: 'Meeting Note 11',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '12',
    noteTitle: 'Meeting Note 12',
    createdOn: getDate(),
    updatedOn: getDate()
  }
];
