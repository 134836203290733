import React from 'react';
import { useLocation, Navigate } from 'react-router';
import useGetIsSuperAdmin from '../hooks/common/useGetIsSuperAdmin';

function SuperAdminOnlyRoute({ children }) {
  const { isSuperAdmin } = useGetIsSuperAdmin();
  const location = useLocation();

  return isSuperAdmin ? children : <Navigate to="/" replace state={{ path: location.pathname }} />;
}

export default SuperAdminOnlyRoute;
