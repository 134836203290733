import { Link } from 'react-router-dom';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import {
  AccountName,
  DueDateDiv,
  ILatestInvoice,
  InvoiceBoxDiv,
  InvoiceDetails,
  InvoiceHistoryDiv,
  InvoiceList,
  InvoiceStatus
} from '../../pages/Payments/MonthlySubscriptionPage';
import { SmallText } from '../Organisation/Placeholders/OrganizationDetails';
import { DarkGreenButton } from '../styles/ButtonStyles';
import { ReactComponent as InvoiceIcon } from '../../assets/icons/InvoiceIcon.svg';
import { getDate } from '../../lib/getDate';
import { PlanType } from '../../pages/Organization/ViewOrganization';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { Label } from '../styles/Label';

type InvoiceProps = {
  upcomingDueDate: string;
  latestInvoice: ILatestInvoice | undefined;
  plan: PlanType;
};

function LatestInvoice({ upcomingDueDate, latestInvoice, plan }: InvoiceProps) {
  const { state } = useContext(AppContext);
  return (
    <InvoiceBoxDiv>
      <DueDateDiv>
        <Label>Upcoming Due Date</Label>
        <SmallText> {getDate(upcomingDueDate)}</SmallText>
      </DueDateDiv>
      <InvoiceList>
        <Label>Latest Invoice</Label>
        <InvoiceDetails>
          {latestInvoice?.created && new Intl.DateTimeFormat('en-US').format(latestInvoice?.created * 1000)}
          <a href={latestInvoice?.invoicePdf}>
            <InvoiceIcon />
          </a>
          {latestInvoice?.amountDue && `$ ${latestInvoice.amountDue / 100}`}
          <InvoiceStatus>{capitalizeFirstLetter(latestInvoice ? latestInvoice.status : '')}</InvoiceStatus>
          <AccountName>{latestInvoice?.accountName}</AccountName>
        </InvoiceDetails>
      </InvoiceList>
      <InvoiceHistoryDiv>
        <Link to={`/organization/${state.orgId}/invoices`} state={{ plan: plan }}>
          <DarkGreenButton style={{ display: 'inline-block' }}>Invoice History</DarkGreenButton>
        </Link>
      </InvoiceHistoryDiv>
    </InvoiceBoxDiv>
  );
}

export default LatestInvoice;
