import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import CreateWorkspaceTaskValidationSchema from '../../../components/Validations/CreateWorkspaceTaskValidation';
import { editTaskOptions, priorityOptions } from '../../../constants/DropDownOptions';
import { updateTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { SINGLE_TASK_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import useGetWorkspaceUsers from '../../common/useGetWorkspaceUsers';

function useEditWorkspaceTask() {
  let navigate = useNavigate();
  let { taskId, projectId, workspaceId } = useParams();
  const { state } = useContext(AppContext);
  const { data, error, loading } = useQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskId }
  });
  const [submitting, setSubmitting] = useState(false);
  const {
    workspaceUsers,
    error: workspaceUsersError,
    loading: loadingWorkspaceUsers
  } = useGetWorkspaceUsers(workspaceId);
  const initialState = {
    title: data?.get_Task?.title,
    projectId: data?.get_Task?.projectId,
    workspaceId: data?.get_Task?.typeId,
    description: data?.get_Task?.description,
    priority: data?.get_Task?.priority,
    status: data?.get_Task?.status,
    dueDate: data?.get_Task?.dueDate,
    assignedTo: data?.get_Task?.assignedTo,
    assignee: data?.get_Task?.assignee,
    links:
      data?.get_Task?.links?.length > 0
        ? data?.get_Task?.links.map((link) => {
            return { value: `${link}`, saved: true };
          })
        : [],
    assignedToDetails: {
      name: data?.get_Task?.assignedToDetails.name,
      profilePicture: '',
      email: ''
    },
    assigneeDetails: {
      name: data?.get_Task?.assigneeDetails.name,
      profilePicture: '',
      email: ''
    }
  };

  const [updateTask] = useMutation(updateTaskMutation, {
    fetchPolicy: 'no-cache'
  });

  const handleSubmit = async (taskData: any) => {
    let linkValues: string[] = [];
    setSubmitting(true);
    const toastId = toast.loading('Updating task ...');
    if (taskData.links && taskData.links.length > 0) {
      linkValues = taskData.links.map((link) => link.value);
    }
    try {
      let actionType = taskData?.status === 'done' ? 'completed' : 'update';
      let messageType = taskData?.status === 'done' ? 'completed' : 'update';
      let userToNotify = taskData?.status === 'done' ? taskData.assignee : taskData.assignedTo;
      let variables =
        userToNotify !== state?.userId
          ? {
              assignedTo: taskData?.assignedTo,
              description: taskData?.description,
              dueDate: taskData?.dueDate,
              priority: taskData?.priority,
              status: taskData?.status,
              title: taskData?.title,
              links: linkValues,
              id: taskId,
              projectId: taskData.projectId,
              type: 'workspace',
              typeId: taskData.workspaceId,
              assignedToDetails: {
                name: taskData?.assignedToDetails.name,
                profilePicture: ''
              },
              updatedAt: new Date().toISOString(),
              updatedBy: state.userId
              // notificationData: {
              //   actionType,
              //   messageType,
              //   taskName: taskData?.title,
              //   dueDate: taskData?.dueDate,
              //   projectId: taskData.projectId,
              //   workspaceId: taskData.typeId,
              //   organizationId: state?.orgId,
              //   userToNotify: taskData.assignedTo,
              //   host: window.location.origin
              // }
            }
          : {
              assignedTo: taskData?.assignedTo,
              assignedToDetails: {
                name: taskData?.assignedToDetails.name,
                profilePicture: ''
              },
              description: taskData?.description,
              dueDate: taskData?.dueDate,
              priority: taskData?.priority,
              status: taskData?.status,
              title: taskData?.title,
              id: taskId,
              projectId: taskData.projectId,
              type: 'workspace',
              links: linkValues,
              typeId: taskData.workspaceId,
              updatedAt: new Date().toISOString()
            };

      let response = await updateTask({
        variables: variables
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Task Updated');
        setSubmitting(false);
        navigate(`/project/workspace/${projectId}/${workspaceId}/tasks`);
      }
    } catch (error) {
      console.error('Error in Updating Task', error);
      setSubmitting(false);
      toast.error('Oh no something went wrong in updating the task.');
    } finally {
      toast.dismiss(toastId);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CreateWorkspaceTaskValidationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });

  const getUser = (id: string, workspaceUsers) => {
    let found = workspaceUsers.find((user) => user.id === id);
    if (found) {
      return { label: found.label, value: found.value, id: found.id };
    }
  };

  const doNothing = () => {};

  const statusIndex = editTaskOptions?.find((option) => option.value === formik.values.status);
  const priorityIndex = priorityOptions?.find((option) => option.value === formik.values.priority);

  return {
    loading,
    loadingWorkspaceUsers,
    error,
    workspaceUsersError,
    getUser,
    formik,
    data,
    statusIndex,
    priorityIndex,
    submitting,
    workspaceUsers,
    state,
    doNothing
  };
}

export default useEditWorkspaceTask;
