import styled from '@emotion/styled';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';

const BlockIDButton = styled.button`
  background-color: #edf0f0;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px 20px;
  margin-bottom: 15px;
`;

export default (props: any) => {
  return (
    <NodeViewWrapper className="react-component" contentEditable={false}>
      <BlockIDButton contentEditable={false}>{props.node.attrs.blockId}</BlockIDButton>
    </NodeViewWrapper>
  );
};
