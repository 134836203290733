import React from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { SINGLE_FILE_QUERY } from '../../../../../graphql/operations/Queries/Library/FilesQueries';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/LinkIcon.svg';
import { DateStyle, Time } from '../../../../../components/ProjectList';
import { ListItemStyles } from '../../../../../components/ListItem';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../../Others/ErrorPage';
import { getDate } from '../../../../../lib/getDate';
import { getTime } from '../../../../../lib/getTime';
import { NoDocuments } from '../../../Registers/RegisterItemsPage';
// import LinkedFileItem from "./WorkspaceLinkedFileItem";
const LinkDetails = styled.div`
  display: flex;
  align-items: center;
`;

const LinkDate = styled.div`
  color: var(--text-light);
  display: flex;
  align-items: center;
  padding-right: 7.6rem;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  margin-right: 4rem;
  margin-top: 0.9rem;
`;

const LinkExternal = styled.div`
  color: var(--text-light);
  display: flex;
  align-items: center;
`;

const LinkText = styled.p`
  font-weight: 600;
  // max-width  : 166px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 2.01rem;
`;

function WorkspaceLinkedFile() {
  let { fileId } = useParams();
  const { data, error, loading } = useQuery(SINGLE_FILE_QUERY, {
    variables: { id: fileId }
  });

  const goToLink = (link: string) => {
    window.open(link, '_blank');
  };

  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  const { get_File } = data;
  return (
    <LinksContainer>
      {get_File?.links?.length > 0 ? (
        get_File.links.map((link, index) => {
          return (
            <ListItemStyles key={index} onClick={() => goToLink(link)}>
              <LinkDetails>
                <LinkIcon />
                <LinkText>{link}</LinkText>
              </LinkDetails>
              <LinkExternal></LinkExternal>
              <LinkDate>
                <DateStyle>{getDate(data.get_File?.createdAt)}</DateStyle>
                <Time>{getTime(data.get_File?.createdAt)}</Time>
              </LinkDate>
            </ListItemStyles>
          );
        })
      ) : (
        <NoDocuments>No links created in document</NoDocuments>
      )}
    </LinksContainer>
  );
}

export default WorkspaceLinkedFile;
