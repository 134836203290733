import styled from "@emotion/styled";

export const EmptyValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;

`