import { useEffect } from 'react';
import { Header, PageTitle } from '../../../../components/styles/Header';
import styled from '@emotion/styled';
import Button from '../../../../components/CustomButton';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import WorkspaceNotesContainer from '../../../../components/WorkspaceNotes/WorkspaceNotesContainer';
import { LIST_NOTES_QUERY } from '../../../../graphql/operations/Queries/Library/NotesQueries';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import NotesContainer from '../../../../components/Notes/NotesContainer';

const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.12rem;
  h2 {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  small {
    padding-left: 3px;
  }
`;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

function WorkspaceNotes() {
  let navigate = useNavigate();
  let { fileId, projectId, workspaceId } = useParams();
  const { data, error, loading, refetch } = useQuery(LIST_NOTES_QUERY, {
    variables: {
      type_typeId: `workspace_${workspaceId}`,
      recordStatus: 0,
      operation: 'query'
    }
  });
  const createNewNote = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/library/files/${fileId}/Notes/new`);
  };

  const viewNote = (id: string) => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/library/files/${fileId}/notes/${id}`);
  };

  useEffect(() => {
    refetch();
  }, [data]);
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Notes</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={createNewNote}>+ New Notes</Button>
        </TitleRight>
      </Header>
      <NotesContainer clickHandler={viewNote} data={data?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems} />
    </>
  );
}

export default WorkspaceNotes;
