import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { GET_ORGANIZATION } from '../graphql/operations/Queries/Organization/OrganizationQueries';

function useGetOrganizationName() {
  const { state } = useContext(AppContext);
  const {
    data: getOrganization,
    loading,
    error
    // refetch
  } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: state?.orgId
    },
    skip: state.orgId === 'null' || state.orgId === null,
    fetchPolicy: 'network-only'
  });

  return {
    loading,
    error,
    getOrganization
  };
}

export default useGetOrganizationName;
