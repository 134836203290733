//to be deleted as it is not used.

import { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { css as emotionCss } from '@emotion/react';
import Avatar from 'react-avatar';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Button from '../../../components/CustomButton';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import useForm from '../../../lib/UseForm';
import LinkDisplay from '../../../components/LinkDisplay';
import { Label } from '../../../components/styles/Label';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Header, PageTitle } from '../../../components/styles/Header';
import { options, statusOptions, selectOptions } from '../../../constants/DropDownOptions';
import { statusStyles } from '../../../components/styles/StatusStyles';
import { LinkInput } from '../../../components/styles/Inputs';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ChoicesWrapper,
  DateWrapper,
  DropDownWrapper,
  FieldSet,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import { Small } from '../../../components/styles/SmallText';
import { AppContext } from '../../../AppContext';
import InputField from '../../../components/Reusable/Formik/Input';
import TextArea from '../../../components/Reusable/Formik/Textarea';
import DateInput from '../../../components/Reusable/Formik/DateInput';
import Select from '../../../components/Reusable/Formik/Select';
import TaskValidationSchema from '../../../components/Validations/CreateTaskValidation';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
// import LinkInput from '../../../components/Reusable/Formik/LinkInput';
// import { defaultOptions } from "../../../../constants/LoaderDefaultOptions";
import { ErrorPage } from '../../Others/ErrorPage';
import { LIST_TASKS_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import { addTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4.6rem;
`;

export const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.18rem;
  gap: 19px;
`;
export const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-left: 1.625rem;
`;
export const customStyles = {
  singleValue: (styles) => ({
    ...styles,
    background: '#DEEEF3',
    padding: '3px 10px',
    borderRadius: '5px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: () => ({
    display: 'none'
  })
};

function CreateProjectTask() {
  const navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [link] = useState<string>('');
  const [links, setLinks] = useState<string[]>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const { state } = useContext(AppContext);
  const { inputs } = useForm({
    assignedTo: `${state.userId}1234098`,
    assignee: state.userId,
    description: '',
    dueDate: new Date().toLocaleDateString('en-gb'),
    priority: 'low',
    projectId: 'NOPROJECT'
  });

  const initialState = {
    assignedTo: options[0],
    assignee: state.userId,
    description: '',
    dueDate: '',
    priority: selectOptions[1],
    projectId: projectId,
    recordStatus: 'active',
    status: statusOptions[0],
    title: '',
    updatedBy: state.userId,
    assignedToDetails: {
      name: 'Cameraon Williamson',
      profilePicture: ''
    },
    assigneeDetails: {
      name: 'Devon Lane',
      profilePicture: ''
    }
  };

  const [getTasks] = useLazyQuery(LIST_TASKS_QUERY, {
    variables: {
      assignee: state.userId
    },
    fetchPolicy: 'network-only'
  });

  const [addTask, { error, loading }] = useMutation(addTaskMutation, {
    variables: {
      ...inputs,
      links: links,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString()
    },
    onCompleted: async (data) => {},
    fetchPolicy: 'no-cache'
  });

  const AddTask = async (taskData: any, setSubmitting: any, resetForm: any) => {
    const assinedtoValue = taskData?.assignedTo?.value;
    try {
      await addTask({
        variables: {
          assignedTo: assinedtoValue,
          assignee: taskData?.assignee,
          description: taskData?.description,
          dueDate: taskData?.dueDate,
          priority: taskData?.priority?.value,
          projectId: taskData?.projectId,
          recordStatus: taskData?.recordStatus,
          status: taskData?.status?.value,
          title: taskData?.title,
          links: links,
          type: 'workspace',
          typeId: workspaceId,
          assignedToDetails: {
            name: 'Cameraon Williamson',
            profilePicture: ''
          },
          assigneeDetails: {
            name: 'Devon Lane',
            profilePicture: ''
          },
          notificationData: {
            actionType: 'assignment',
            userToNotify: assinedtoValue,
            organizationId: state.orgId,
            workspaceName: taskData.workspaceName,
            projectName: taskData.projectName,
            host: window.location.origin
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
    resetForm();
    setSubmitting(false);
  };

  const handleLinkChange = (event: any) => {
    event.preventDefault();
    let { value } = event.target;
    setLinks([...links, value]);
    setToggle(!toggle);
  };

  const showInput = () => {
    setToggle(!toggle);
  };

  const removeLink = (removeLink: any) => {
    let newLinks = links.filter((link) => link !== removeLink);
    setLinks([...newLinks]);
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: TaskValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setIsLoading(true);
      AddTask(values, setSubmitting, resetForm);
      setTimeout(async () => {
        await getTasks();
        navigate(`/project/workspace/${projectId}/${workspaceId}/tasks`);
      }, 3000);
    }
  });

  if (loading || isLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <TitleLeft>
            <PageTitle>New Task</PageTitle>
          </TitleLeft>
          <TitleRight>
            <Button type="submit">Create Task</Button>
          </TitleRight>
        </Header>
        <TaskDetails>
          <FieldSet>
            <div>
              <InputWrapper>
                <Label htmlFor="taskname">Task Title</Label>
                <InputField name="title" type="text" formik={formik} disabled={false} placeHolder="New Task" />
              </InputWrapper>
              <InputWrapper>
                <Label htmlFor="description">Description</Label>
                <TextArea name="description" formik={formik} disabled={false} placeHolder="Description" />
              </InputWrapper>
              <LinkWrapper>
                {links?.map((link, index) => {
                  return <LinkDisplay key={index} link={link} removeLink={removeLink} />;
                })}
                {!toggle && (
                  <>
                    <Label htmlFor="link">Link</Label>
                    <LinkInput name="link" onChange={handleLinkChange} value={link} type="text"></LinkInput>
                  </>
                )}
                <div>{toggle && <Button onClick={showInput}>+Add Link</Button>}</div>
              </LinkWrapper>
            </div>
            <div>
              <ChoicesWrapper>
                <Label>Status</Label>
                <Select
                  name="status"
                  value={formik.values.status}
                  onChange={(value: any) => {
                    formik.setFieldValue('status', value ? value : '');
                  }}
                  error={formik.errors.status}
                  touched={formik.touched.status}
                  options={statusOptions}
                  styles={{ ...statusStyles }}
                />
                <Label className="priority">Priority</Label>
                <Select
                  name="priority"
                  options={selectOptions}
                  value={formik.values.priority}
                  styles={statusStyles}
                  onChange={(value: any) => {
                    formik.setFieldValue('priority', value ? value : '');
                  }}
                  error={formik.errors.priority}
                  touched={formik.touched.priority}
                />
              </ChoicesWrapper>
              <DateWrapper>
                <Label>Due Date</Label>
                <CalendarIconPlacer>
                  <DateInput name="dueDate" type="date" formik={formik} />
                </CalendarIconPlacer>
              </DateWrapper>
              <AssigneeWrapper>
                <Label>Reporter</Label>
                <AssigneeSeparator>
                  <AvatarStyles>
                    <CustomAvatar isTable={true} name="Devon Lane" size={21} />
                  </AvatarStyles>
                  <Small>30 Apr 2021, 9:30 AM </Small>
                </AssigneeSeparator>
              </AssigneeWrapper>
              <DropDownWrapper>
                <Label>Assigned To</Label>
                <Select
                  name="assignedTo"
                  value={formik.values.assignedTo}
                  options={options}
                  isSearchable={true}
                  isClearable={true}
                  // className="basic-multi-select"
                  // classNamePrefix="select"
                  menuPortalTarget={document.body}
                  components={{ SinglePersonSelect }}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                  // onChange={handleSelectChange('assignedTo')}
                  onChange={(value: any) => {
                    formik.setFieldValue('assignedTo', value ? value : '');
                  }}
                  error={formik.errors.assignedTo}
                  touched={formik.touched.assignedTo}
                />
              </DropDownWrapper>
            </div>
          </FieldSet>
        </TaskDetails>
      </form>
    </>
  );
}

const SinglePersonSelect = ({ cx, getStyles, selectProps, data, isDisabled, className, ...props }) => {
  return (
    <div
      ref={innerRef}
      className={cx(
        emotionCss(getStyles('singleValue', props)),
        {
          'single-value': true,
          'single-value--is-disabled': isDisabled
        },
        className
      )}
    >
      <Avatar round={true} size="21" name={`${data.label}`} textSizeRatio={2} />
      <span className="name">{selectProps.getOptionLabel(data)}</span>
    </div>
  );
};

export default CreateProjectTask;
