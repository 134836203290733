import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import Button, { CustomButton } from '../../../../components/CustomButton';
import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import FileTag from '../../../../components/FileTag';
import toast from 'react-hot-toast';
import { TextAreaField } from '../../../../components/Reusable/NewFormik/Textarea';
import { DateInputField } from '../../../../components/Reusable/NewFormik/DateInput';
import FileValidationSchema from '../../../../components/Validations/CreateFileValidation';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { ReactComponent as FileUploadIcon } from '../../../../assets/icons/fileupload.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/CloseIcon.svg';
import { AppContext } from '../../../../AppContext';
import { useNavigate } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { Label } from '../../../../components/styles/Label';
import { Input } from '../../../../components/styles/Inputs';
import {
  LinkWrapper,
  FieldSet,
  InputWrapper,
  DateWrapper,
  AssigneeWrapper,
  AssigneeSeparator,
  AvatarStyles,
  FileUploadWrapper,
  FilePreview
} from '../../../../components/styles/FormStyles';
import { Small } from '../../../../components/styles/SmallText';
import { TaskDetails } from '../../../Tasks/TaskViews/CreateTask';
import { getFileType } from '../../../../lib/getFileType';

import { GreyButton } from '../../../../components/styles/ButtonStyles';
import TextError from '../../../../components/Reusable/NewFormik/TextError';
import { getDate } from '../../../../lib/getDate';
import { getTime } from '../../../../lib/getTime';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { InputField } from '../../../../components/Reusable/NewFormik/Input1';
import { useToggle } from '../../../../lib/UseToggle';
import Modal from '../../../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../../components/styles/ModalStyles';
import { CustomWrapper, LinkInputWrapper } from '../../../Library/FilesView/Files/CreateNewFile';
import { formatBytes } from '../../../../lib/getFileSize';
// import InputField from '../../../../components/Reusable/Formik/Input';
// import LinkDisplay from '../../../../components/LinkDisplay'
// import TextAreas from '../../../../components/Reusable/Formik/Textarea';
// import DateInputField from '../../../components/Reusable/Formik/DateInput';
// import { useToggle } from '../../../../lib/UseToggle'

const Form = styled.form``;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #ffffff;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
    border: 1px solid #dbe2e0;
    :hover {
      background: #dbe2e0;
    }
  }
`;

interface InitialValues {
  documentName: string;
  file: any;
  description: string;
  linkedFiles: string[];
  createdBy: string;
  collections: string[];
  createdAt: string;
  customFieldsLabels: string[];
  customInputs: string[];
  labelText: string;
}

function CreateNewWorkspaceFile() {
  let navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const { state } = useContext(AppContext);
  const [isOpen, toggle] = useToggle(false);
  const [submitting, setIsSubmitting] = useState(false);
  const userId = state.userId;

  const initialValues: InitialValues = {
    file: '',
    documentName: '',
    description: '',
    createdBy: state.fullName,
    collections: [],
    linkedFiles: [],
    createdAt: '',
    customFieldsLabels: [],
    customInputs: [],
    labelText: ''
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleAddNewField = (data, setFieldValue) => {
    setFieldValue('customInputs', [...data.customInputs, { label: data.labelText, value: '' }]);
    setFieldValue('labelText', '');
    toggle();
  };
  const doNothing = () => {};

  const handleSubmit = async (values: InitialValues, { setSubmitting, resetForm }: FormikHelpers<InitialValues>) => {
    setIsSubmitting(true);
    const toastId = toast.loading('Uploading...');
    let id = uuidv4();
    let size = (values.file.size / 1000).toString();
    if (Number(size) > 1000000) {
      setIsSubmitting(false);
      toast.error(`Upload Failed! File size should be less than 1GB`, { id: toastId });
    } else {
      Storage.put(id, values.file, {
        level: 'public',
        metadata: {
          documentName: values?.documentName,
          description: values?.description,
          createdByName: values?.createdBy,
          createdById: userId,
          createdAt: new Date().toISOString(),
          collections: JSON.stringify(values?.collections),
          linkedFiles: JSON.stringify(values?.linkedFiles),
          fileType: values?.file.type,
          fileName: values.file.name,
          projectId: projectId || 'NO PROJECT',
          size,
          fields: JSON.stringify(values.customInputs),
          type: 'workspace',
          workspaceId: workspaceId || 'NO WORKSPACE',
          updatedAt: new Date().toISOString(),
          updatedBy: userId,
          typeId: workspaceId,
          isDraft: 'false',
          organizationId: state.orgId,
          entityType: 'WORKSPACE',
          entityId: state.orgId,
          userId: state.userId
        },
        progressCallback(progress: { loaded: number; total: number }) {
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          let percentage = parseInt((progress.loaded / progress.total) * 100);
          toast.loading(`${percentage}% uploaded`, { id: toastId });
          // setUploadProgress(calculatePercent(progress.loaded, progress.total));
        },
        errorCallback: (err: any) => {
          console.error('Unexpected error while uploading.', err);
          toast.error(`Upload failed please try again later.`, { id: toastId });
          // setResumableUploadButtonDisabled(true);
        }
      })
        .then((data) => {
          if (data.key) {
            toast.success('Upload Completed Redirecting ETA 10 secs...', {
              id: toastId
            });
            setTimeout(() => {
              navigate(`/project/workspace/${projectId}/${workspaceId}/library`);
            }, 10000);
            setIsSubmitting(false);
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error('Error in Uploading WS File', error);
          toast.dismiss(toastId);
          toast.error('Upload Failed.');
        });
    }
  };
  // const formik = useFormik({
  //   initialValues: initialState,
  //   validationSchema: FileValidationSchema,
  //   enableReinitialize: true,
  //   onSubmit: async (values, { setSubmitting, resetForm }) => {
  //     toast.loading('Uploading...');
  //     let id = uuidv4();

  //     try {
  //       const result = await Storage.put(id, values.file, {
  //         contentType: values.file.type,
  //         level: 'public',
  //         metadata: {
  //           documentName: values?.documentName,
  //           description: values?.description,
  //           createdByName: values?.createdBy,
  //           collections: JSON.stringify(values?.collections),
  //           linkedFiles: JSON.stringify(values?.linkedFiles),
  //           createdAt: values?.createdAt,
  //           createdById: userId,
  //           fileType: values?.file.type,
  //           fields: '',
  //           projectId: projectId,
  //           typeId: workspaceId,
  //           type: 'workspace',
  //           updatedAt: values?.createdAt,
  //           updatedBy: userId,
  //           isDraft: 'false'
  //         },
  //       });
  //       const url: any = await Storage.get(id, {
  //         level: 'public',
  //       });
  //       setTimeout(() => {
  //         navigate(`/project/workspace/${projectId}/${workspaceId}/library`)
  //       }, 4000)

  //     } catch (err) {
  //       console.error('ERROR IN UPLOADING', err);
  //     }
  //   },
  // });

  const SaveDraft = async (values) => {
    setIsSubmitting(true);
    if (values.documentName === '') {
      toast.error('Please provide a document name to your draft.');
      return;
    }
    const toastId = toast.loading('Saving...');
    let id = uuidv4();
    let size = (values.file.size / 1000).toString();
    if (Number(size) > 1000000) {
      setIsSubmitting(false);
      toast.error(`Upload Failed! File size should be less than 1GB`, { id: toastId });
    } else {
      // console.log('Final Values', values);
      // console.log(state.orgId, userId, 'data');
      try {
        await Storage.put(id, values.file, {
          contentType: values.file.type,
          level: 'public',
          metadata: {
            documentName: values?.documentName,
            description: values?.description,
            createdByName: values?.createdBy,
            collections: JSON.stringify(values?.collections),
            linkedFiles: JSON.stringify(values?.linkedFiles),
            createdAt: new Date().toISOString(),
            createdById: userId,
            fileType: values?.file.type,
            fileName: values.file.name,
            size,
            fields: '',
            projectId: projectId,
            typeId: workspaceId,
            type: 'workspace',
            updatedAt: new Date().toISOString(),
            updatedBy: userId,
            isDraft: 'true',
            organizationId: state.orgId,
            entityType: 'WORKSPACE',
            entityId: state.orgId,
            userId: state.userId
          }
        }).then((data) => {
          if (data.key) {
            toast.success('SAVED TO DRAFT SUCCESSFULY', {
              id: toastId
            });
            setTimeout(() => {
              navigate(`/project/workspace/${projectId}/${workspaceId}/library`);
            }, 4000);
            setIsSubmitting(false);
          }
        });
      } catch (err) {
        console.error('ERROR IN UPLOADING', err);
        toast.dismiss(toastId);
        toast.error('Error in saving draft.');
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={FileValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, handleBlur }) => (
          <Form onSubmit={handleSubmit}>
            <Header>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <PageTitle>New Document</PageTitle>
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <Button disabled={submitting} secondary={true} onClick={() => SaveDraft(values)}>
                  Save as Draft
                </Button>
                <Button type="submit">Save File</Button>
              </TitleRight>
            </Header>
            <TaskDetails>
              <FieldSet>
                <div>
                  <InputWrapper>
                    <InputField
                      label="Document Name"
                      id="documentName"
                      value={values.documentName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.documentName}
                      touched={touched.documentName}
                      disabled={submitting}
                      name="documentName"
                      type="text"
                    />
                  </InputWrapper>
                  <FileUploadWrapper>
                    {values.file ? (
                      <FilePreview>
                        <FileTag>{getFileType(values.file.name)?.toUpperCase()}</FileTag>
                        <span className="file">{values.file.name}</span>
                        <span className="file">{formatBytes(values.file.size)}</span>
                        <GreyButton disabled={submitting} type="button" onClick={() => setFieldValue('file', '')}>
                          Remove File <CloseIcon />
                        </GreyButton>
                      </FilePreview>
                    ) : (
                      <>
                        <GreyButton type="button" onClick={handleFile}>
                          <FileUploadIcon /> Upload File
                        </GreyButton>
                        <input
                          type="file"
                          name="file"
                          id="file"
                          style={{ display: 'none' }}
                          ref={hiddenFileInput}
                          onChange={(event) => {
                            setFieldValue('file', event.currentTarget.files[0]);
                          }}
                        />
                        {touched['file'] && errors['file'] ? (
                          <p style={{ color: '#ff3c3c' }}>{errors['file']}</p>
                        ) : null}
                      </>
                    )}
                  </FileUploadWrapper>
                  <InputWrapper>
                    <TextAreaField disabled={submitting} label="Description" name="description" id="description" />
                  </InputWrapper>
                  <LinkWrapper>
                    <Label>Links</Label>
                    <FieldArray name="linkedFiles">
                      {({ insert, remove, push }) => (
                        <>
                          {values.linkedFiles.length > 0 &&
                            values.linkedFiles.map((link, index) => {
                              return (
                                <>
                                  <Field name={`linkedFiles.${index}`}>
                                    {({ field, form, meta }) => (
                                      <>
                                        <LinkInputWrapper>
                                          <Input
                                            className="link-input"
                                            disabled={submitting}
                                            type="text"
                                            {...field}
                                            error={meta.touched && meta.error}
                                          />
                                          <CloseIcon
                                            onClick={() => (!submitting ? remove(index) : doNothing())}
                                            height="25px"
                                            width="25px"
                                          />
                                        </LinkInputWrapper>
                                        {meta.touched && meta.error && (
                                          <ErrorMessage component={TextError} name={`linkedFiles.${index}`} />
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </>
                              );
                            })}
                          <div>
                            <Button disabled={submitting} onClick={() => push('')}>
                              + Add Link
                            </Button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </LinkWrapper>
                  <InputWrapper>
                    <FieldArray name="customInputs">
                      {({ remove }) => (
                        <>
                          {values.customInputs.length > 0 &&
                            values.customInputs.map((formgroup, index) => {
                              return (
                                <Field name={`customInputs.${index}.value`}>
                                  {({ field, meta }) => (
                                    <>
                                      <Label>{formgroup.label}</Label>
                                      <CustomWrapper>
                                        <Input
                                          className="custom-input"
                                          type="text"
                                          {...field}
                                          error={meta.touched & meta.error}
                                        />
                                        <CloseIcon
                                          onClick={() => {
                                            !submitting ? remove(index) : doNothing();
                                          }}
                                          height="25px"
                                          width="25px"
                                        />
                                      </CustomWrapper>
                                      {meta.touched && meta.error && (
                                        <ErrorMessage component={TextError} name={`customInputs.${index}.value`} />
                                      )}
                                    </>
                                  )}
                                </Field>
                              );
                            })}
                        </>
                      )}
                    </FieldArray>
                  </InputWrapper>
                  <AddNewField>
                    <Button disabled={submitting} type="button" onClick={() => toggle()}>
                      Add New Field
                    </Button>
                  </AddNewField>
                </div>
                <div>
                  <DateWrapper>
                    <Label htmlFor="date">Document Date</Label>
                    <DateInputField
                      name="createdAt"
                      type="date"
                      value={values.createdAt}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={submitting}
                      touched={touched.createdAt}
                      errors={errors.createdAt}
                    />
                  </DateWrapper>
                  <AssigneeWrapper>
                    <Label>Created By</Label>
                    <AssigneeSeparator>
                      <AvatarStyles>
                        <CustomAvatar name={state?.fullName} size={21} />
                      </AvatarStyles>
                      <Small>
                        {getDate()} {getTime()}
                      </Small>
                    </AssigneeSeparator>
                  </AssigneeWrapper>
                </div>
              </FieldSet>
              <Modal open={isOpen} onClose={toggle}>
                <ModalContainer width="528px">
                  <ModalTitle>New Field</ModalTitle>
                  <ModalBody>
                    <InputWrapper>
                      <Label>What do you want to name this field</Label>
                      <InputField
                        name="labelText"
                        type="text"
                        value={values.labelText}
                        onChange={handleChange}
                        errors={errors.labelText}
                        touched={touched.labelText}
                      />
                    </InputWrapper>
                    <ModalWrapper>
                      <Button onClick={toggle} secondary>
                        Cancel
                      </Button>
                      <Button
                        disabled={values.labelText === '' || submitting}
                        onClick={() => handleAddNewField(values, setFieldValue)}
                      >
                        Confirm
                      </Button>
                    </ModalWrapper>
                  </ModalBody>
                </ModalContainer>
              </Modal>
            </TaskDetails>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateNewWorkspaceFile;
