export const Success = {
  organisationCreated: 'New Organization Created',
  projectCreated: 'Project Created',
  projectUpdated: 'Project Updated',
  taskCreated: 'Task Created',
  taskUpdated: 'Task Updated',
  fileUploaded: 'File Uploaded',
  noteCreated: 'Note Created',
  noteUpdated: 'Note Updated',
  workspaceCreated: 'New Workspace Created',
  templateCreated: 'New Template Created',
  registerCreated: 'Register Added',
  roleCreated: 'New Role Created',
  sendInvite: 'Invite Sent',
  memberAdded: 'Member Added'
};
