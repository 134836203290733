import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { client } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { createMeetingNotesMutation } from '../../../graphql/operations/Mutations/MeetingNotes/CreateMeetingNote';
import { GET_MEETING_NOTE_TEMPLATE_QUERY } from '../../../graphql/operations/Queries/MeetingNotes/MeetingNoteQueries';
import useGetOrganizationName from '../../../hooks/useGetOrganizationName';
import { ModalInput, ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';

interface CreateNoteProps {
  noteName: string;
  userId: string;
  workspaceId: string;
  orgId: string;
  projectId: string;
  otherFields: any;
  templateData: any;
}

export const createNewNote = async ({
  noteName,
  userId,
  workspaceId,
  templateData,
  orgId,
  projectId,
  otherFields
}: CreateNoteProps) => {
  const response = await client.mutate({
    mutation: createMeetingNotesMutation,
    variables: {
      createdAt: new Date().toISOString(),
      createdBy: userId,
      recordStatus: 0,
      updatedAt: new Date().toISOString(),
      updatedBy: userId,
      workspaceId: workspaceId,
      metadata: JSON.stringify(templateData),
      noteType: 'MEETING_NOTE', //WORSKAPCE
      noteType_typeId: 'MEETING_NOTE_null',
      organizationId: orgId,
      organizationId_projectId_workspaceId: `${orgId}_${projectId}_${workspaceId}`,
      otherFields: JSON.stringify(otherFields),
      projectId: projectId,
      title: noteName,
      typeId: 'null',
      isDraft: true
    }
  });
  const data: any = await axiosInstance.post('/userEntity', [
    {
      entityType: 'MEETING_NOTES',
      entityId: response.data.add_NoteAndDisscussion.result._id,
      userId: userId,
      projectId: projectId,
      organizationId: orgId,
      workspaceId: workspaceId,
      access: 'OWNER',
      memberType: 'ATTENDEE'
    }
  ]);
  return response.data.add_NoteAndDisscussion.result._id;
};

const fetchTemplate = async (templateId: string) => {
  try {
    const data = await client.query({
      query: GET_MEETING_NOTE_TEMPLATE_QUERY,
      variables: {
        id: templateId
      }
    });
    if (!data.data) return {};
    return JSON.parse(data.data?.get_Template?.metaData);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const CreateNoteModal = ({
  isOpen,
  toggleModal,
  projectId,
  workspaceId,
  templateId
}: {
  isOpen: boolean;
  toggleModal: () => void;
  projectId: string | null;
  workspaceId: string | null;
  templateId: string | null;
}) => {
  const [noteName, setNoteName] = useState<string>('');

  const { state } = useContext(AppContext);

  const navigate = useNavigate();

  const { getOrganization } = useGetOrganizationName();

  const handleCreateNewNote = async () => {
    if (!templateId) return;
    const id = toast.loading('Creating new note');

    try {
      const data = await fetchTemplate(templateId);

      /**
       * Need to change the key for the meeting note form to work
       * formLabel -> label
       * formValue -> value
       */
      let otherFields = data.otherFields.map((field: any) => {
        if (field.formType === 'nested') {
          let formData = field.formData.map((data: any) => {
            return { ...data, label: data.formLabel, value: field.formValue !== false ? field.formValue : '' };
          });
          return { ...field, formData, label: field.formHeader };
        }
        return { ...field, label: field.formLabel, value: field.formValue !== false ? field.formValue : '' };
      });

      const templateData = { ...data, organizationName: getOrganization?.get_Organization?.name, otherFields };

      const meetingNoteId = await createNewNote({
        noteName,
        projectId: projectId!,
        workspaceId: workspaceId!,
        orgId: state.orgId,
        otherFields: templateData.otherFields,
        templateData,
        userId: state.userId
      });

      toast.success('Note created successfully', { id });

      navigate(`/project/workspace/${projectId}/${workspaceId}/meetingnotes/${meetingNoteId}`);
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong', { id });
    }
  };

  return (
    <Modal open={isOpen} onClose={() => null}>
      <ModalContainer width="528px">
        <ModalTitle>Note Name</ModalTitle>
        <ModalBody>
          <ModalText>Enter a Note Name</ModalText>
          <ModalInput
            placeholder="Note Name"
            type="text"
            value={noteName}
            onChange={(e) => setNoteName(e.target.value)}
            disabled={false}
            // style={{ display: 'block', margin: '0 auto', width: '464px', marginBottom: '21px' }}
          />
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            disabled={noteName === ''}
            onClick={() => {
              handleCreateNewNote();
            }}
          >
            Create Note
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default CreateNoteModal;
