import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const EditorStyles = styled.div<{ isNew?: boolean; isMeetingNote?: boolean }>`
  margin-top: 10px;
  margin-bottom: ${(props) => (props.isMeetingNote ? '25px' : '0px')};

  /* Only meeting notes have green border when focused */
  .ProseMirror {
    outline: none;
    min-height: 100px;
    background-color: #ffffff;
    padding: 10px 20px;
    border-left: ${(props) => (props.isMeetingNote ? '5px solid #00b292' : 'none')};
    border-top-left-radius: 5px;
    position: relative;
    padding-top: ${(props) => (props.isMeetingNote ? '50px' : '10px')};

    &:focus {
      border-left: none;
    }

    ${(props) =>
      props.isNew &&
      css`
        border-bottom-left-radius: 5px;
      `}

    table {
      border-collapse: collapse;
      margin: 0;
      overflow: hidden;
      table-layout: fixed;
      width: 70%;

      td,
      th {
        border: 2px solid #ced4da;
        box-sizing: border-box;
        min-width: 1em;
        padding: 3px 5px;
        position: relative;
        vertical-align: top;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        background-color: #f1f3f5;
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        background: rgba(200, 200, 255, 0.4);
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }

      .column-resize-handle {
        background-color: #adf;
        bottom: -2px;
        position: absolute;
        right: -2px;
        pointer-events: none;
        top: 0;
        width: 4px;
      }
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0px;
    margin: 0px;
  }

  img {
    max-height: 200px;
    max-width: 500px;
  }

  .tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  a {
    &:hover {
      cursor: pointer;
    }
  }

  .bubble_menu {
    padding: 5px 10px;
    margin: 2px 10px;
    border: 1px solid #93979f;
    border-radius: 5px;
    color: #93979f;

    &:hover {
      cursor: pointer;
    }
  }

  .editor-container {
    position: relative;
  }
`;

export const Footer = styled.div<{ isFocused?: boolean }>`
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #e9eaec;

  button {
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    background: rgba(196, 196, 196, 0.2);
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    &:hover {
      cursor: pointer;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    color: #7d7d7d;
  }

  .new-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DateContainer = styled.div`
  background-color: #ffffff;
  border-left: 5px solid #00b292;
  border-bottom-left-radius: 5px;

  padding: 10px 20px;
  font-size: 12px;

  p {
    margin: 0;
    padding: 5px;
    border-top: 0.5px solid #e9eaec;
    color: #7d7d7d;
  }
`;

export const BlockId = styled.div`
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 10px;

  background-color: #edf0f0;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 5px 20px;
  margin-bottom: 15px;
`;

export const EditStatus = styled.div`
  position: absolute;
  right: 20px;
  z-index: 1;
  top: 10px;
  border-radius: 5px;
  background-color: #edf0f0;
  padding: 2px 8px;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .userIcon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    background: black;
    margin: 0px 10px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const ToggleButton = styled.div`
  position: absolute;
  right: 20px;
  z-index: 1;
  top: 10px;

  .icon-button {
    :hover {
      background-color: transparent;
      cursor: pointer;
      /* filter: drop-shadow(0px 0px 10px #f0f0f0); */
    }
  }
`;
