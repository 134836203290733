import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { client } from '../apollo';
import { GET_REGISTER_QUERY, LIST_REGISTER_FILES_QUERY } from '../graphql/operations/Queries/Registers/RegisterQueries';

const getRegisterData = async (projectId: string, workspaceId: string, registerId: string) => {
  // const { data, error, loading } = useQuery(GET_REGISTER_QUERY, {
  //   variables: { id: registerId, operation: 'query', type: 'WORKSPACE', typeId: workspaceId }
  // });
  // const {
  //   error: listError,
  //   loading: listLoading,
  //   refetch: refetchFiles
  // } = useQuery(LIST_REGISTER_FILES_QUERY, {
  //   variables: {
  //     type_typeId: `register_${registerId}`,
  //     recordStatus: 0,
  //     workspaceId,
  //     operation: 'query'
  //   },
  //   onCompleted: (data) => {
  //     setDocumentList([...data.list_FileItems._FileItems]);
  //   },
  //   fetchPolicy: 'network-only'
  // });
  try {
    const registerData = await client.query({
      query: GET_REGISTER_QUERY,
      variables: { id: registerId, operation: 'query', type: 'WORKSPACE', typeId: workspaceId }
    });

    const fileList = await client.query({
      query: LIST_REGISTER_FILES_QUERY,
      variables: {
        type_typeId: `register_${registerId}`,
        recordStatus: 0,
        workspaceId,
        operation: 'query'
      },
      fetchPolicy: 'network-only'
    });


    return {
      register: registerData?.data?.get_Register,
      files: fileList.data.list_FileItems._FileItems
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default getRegisterData;
