import toast from 'react-hot-toast';
import { FormikHelpers, FormikState, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import ProjectTeamValidation from '../../../components/Validations/ProjectTeamValidation';
import { isEditProp, IWorkspaceId, ModuleType } from './useOtherContacts';
import { recordTypes } from './useProjectAddress';
import { useNavigate } from 'react-router';

type TeamDetailsForm = {
  organizationName: string;
  fieldOfWork: string;
  phone: string;
  emailId: string;
  address: string;
};

export type TeamDetailsPayload = {
  projectId: string;
  recordType: recordTypes.TEAM;
  designation: TeamDetailsForm['fieldOfWork'];
  emailId: string;
  phoneNumber: TeamDetailsForm['phone'];
  address: string;
  organizationName: string;
  organizationId: string;
};

type TeamRouterProps = {
  teamId?: string;
  isEdit?: boolean | null;
  module?: ModuleType;
};

export const useTeam = () => {
  const navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState<TeamDetailsForm>();
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { state: linkState }: { state: TeamRouterProps | null } = useLocation();
  const initialState: TeamDetailsForm = {
    organizationName: '',
    fieldOfWork: '',
    phone: '',
    emailId: '',
    address: ''
  };

  useEffect(() => {
    const getTeamDetails = async () => {
      if (linkState?.isEdit && linkState?.module === 'project') {
        try {
          setLoading(true);
          let res = await axiosInstance.get(
            `/project/team?id=${linkState.teamId}&projectId=${projectId}&recordType=${recordTypes.TEAM}`
          );
          let obj: TeamDetailsForm = {
            organizationName: res.data.data.organizationName,
            phone: res.data.data?.phoneNumber,
            emailId: res.data.data.emailId,
            address: res.data.data.address,
            fieldOfWork: res.data.data.designation
          };
          setTeam(obj);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setError(true);
          console.error('ERROR', error);
        }
      }
    };

    getTeamDetails();
  }, []);

  useEffect(() => {
    const getWorkspaceTeamDetails = async () => {
      if (linkState?.isEdit && linkState?.module === 'workspace') {
        try {
          setLoading(true);
          let res = await axiosInstance.get(
            `/workspace/team?id=${linkState.teamId}&workspaceId=${workspaceId}&recordType=${recordTypes.TEAM}`
          );
          let obj: TeamDetailsForm = {
            organizationName: res.data.data.organizationName,
            phone: res.data.data?.phoneNumber,
            emailId: res.data.data.emailId,
            address: res.data.data.address,
            fieldOfWork: res.data.data.designation
          };
          setTeam(obj);
        } catch (error) {
          setError(true);
          console.error('ERROR', error);
        } finally {
          setLoading(false);
        }
      }
    };
    getWorkspaceTeamDetails();
  }, []);

  const sendTeamDetails = async (data: TeamDetailsPayload) => {
    return axiosInstance.post(`/project/team`, data);
  };

  const sendWorkspaceTeamDetails = async (data: TeamDetailsPayload & IWorkspaceId) => {
    return axiosInstance.post(`/workspace/team`, data);
  };

  const updateWorkspaceTeamDetails = async (data: TeamDetailsPayload) => {
    return axiosInstance.patch(`/workspace/team`, data);
  };

  const handleEdit = async (
    values: TeamDetailsForm,
    resetForm: (nextState?: Partial<FormikState<TeamDetailsForm>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj: TeamDetailsPayload & isEditProp = {
      projectId: projectId,
      recordType: recordTypes.TEAM,
      designation: values.fieldOfWork,
      emailId: values.emailId,
      phoneNumber: values.phone,
      address: values.address,
      organizationName: values.organizationName,
      organizationId: state.orgId,
      id: linkState?.teamId
    };
    let toastId = toast.loading('Sending data');
    await sendTeamDetails(obj)
      .then((res) => {
        if (res.data) {
          toast.dismiss(toastId);
          toast.success('Team edited successfully');
          resetForm();
          setTeam({
            organizationName: '',
            fieldOfWork: '',
            phone: '',
            emailId: '',
            address: ''
          });
          setSubmitting(false);
          navigate(`/project/${projectId}`);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error('Error while editing data');
        console.error('Error in updating team', error);
        setSubmitting(false);
      });
  };

  const handleAdd = async (
    values: TeamDetailsForm,
    resetForm: (nextState?: Partial<FormikState<TeamDetailsForm>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj: TeamDetailsPayload = {
      projectId: projectId,
      recordType: recordTypes.TEAM,
      designation: values.fieldOfWork,
      emailId: values.emailId,
      phoneNumber: values.phone,
      address: values.address,
      organizationName: values.organizationName,
      organizationId: state.orgId
    };
    let toastId = toast.loading('Submitting Data');
    await sendTeamDetails(obj)
      .then((res) => {
        if (res) {
          toast.dismiss(toastId);
          toast.success('Record added successfully.');
          resetForm();
          setSubmitting(false);
          navigate(`/project/${projectId}`);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        console.error('ERROR', error);
        toast.error('Oh no something went wrong.');
        setSubmitting(false);
      });
  };

  const addWorkspaceTeam = async (
    values: TeamDetailsForm,
    resetForm: (nextState?: Partial<FormikState<TeamDetailsForm>> | undefined) => void
  ) => {
    const toastId = toast.loading('Submitting Data');
    setSubmitting(true);
    let obj: TeamDetailsPayload & IWorkspaceId = {
      projectId: projectId,
      recordType: recordTypes.TEAM,
      designation: values.fieldOfWork,
      emailId: values.emailId,
      phoneNumber: values.phone,
      address: values.address,
      organizationName: values.organizationName,
      organizationId: state.orgId,
      workspaceId: workspaceId
    };
    await sendWorkspaceTeamDetails(obj)
      .then((res) => {
        if (res) {
          toast.dismiss(toastId);
          toast.success('Team added to workspace successfully.');
          resetForm();
          navigate(`/project/workspace/${projectId}/${workspaceId}`);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        console.error('ERROR', error);
        toast.error('Oh no something went wrong.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const updateWorkspaceTeam = async (
    values: TeamDetailsForm,
    resetForm: (nextState?: Partial<FormikState<TeamDetailsForm>> | undefined) => void
  ) => {
    const toastId = toast.loading('Submitting Data');
    setSubmitting(true);
    let obj: TeamDetailsPayload & isEditProp & IWorkspaceId = {
      projectId: projectId,
      recordType: recordTypes.TEAM,
      designation: values.fieldOfWork,
      emailId: values.emailId,
      phoneNumber: values.phone,
      address: values.address,
      organizationName: values.organizationName,
      organizationId: state.orgId,
      workspaceId: workspaceId,
      id: linkState?.teamId
    };
    await updateWorkspaceTeamDetails(obj)
      .then((res) => {
        if (res) {
          toast.dismiss(toastId);
          if (res.data) {
            toast.success('Team updated successfully.');
            resetForm();
            setTeam({
              address: '',
              emailId: '',
              fieldOfWork: '',
              organizationName: '',
              phone: ''
            });
            navigate(`/project/workspace/${projectId}/${workspaceId}`);
          }
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        console.error('ERROR', error);
        toast.error('Oh no something went wrong.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = async (values: TeamDetailsForm, { resetForm }: FormikHelpers<TeamDetailsForm>) => {
    if (!projectId || !state.orgId) {
      toast.error('You cannot submit data here');
    }
    if (linkState?.isEdit) {
      handleEdit(values, resetForm);
    } else {
      handleAdd(values, resetForm);
    }
  };

  const handleWorkspaceSubmit = async (values: TeamDetailsForm, { resetForm }: FormikHelpers<TeamDetailsForm>) => {
    if (linkState?.isEdit && linkState?.module === 'workspace') {
      updateWorkspaceTeam(values, resetForm);
    } else {
      addWorkspaceTeam(values, resetForm);
    }
  };

  const formik = useFormik({
    initialValues: team || initialState,
    onSubmit: projectId && workspaceId ? handleWorkspaceSubmit : handleSubmit,
    validationSchema: ProjectTeamValidation,
    enableReinitialize: true
  });
  return {
    formik,
    loading,
    error,
    linkState,
    submitting
  };
};
