import styled from '@emotion/styled';
import React, { MutableRefObject, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { AppContext } from '../../../../AppContext';
import Button from '../../../CustomButton';
import Modal from '../../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../styles/ModalStyles';
import { getWorkspaceUsers } from '../../../../lib/getWorkspaceUsers';
import { ModalText } from '../../../../pages/Workspaces/Registers/CreateNewRegister';

const InputContainer = styled.div`
  width: 464px;
  height: 61px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f5;
  border-radius: 5px;
  margin: auto;
  font-size: 16px;

  .attendee-select {
    flex-grow: 4;
    margin-right: 10px;
  }

  .permission-select {
    border: none;
    flex-grow: 1;
  }
`;

const permissionOptions = [
  { value: 'viewer', label: 'Viewer' },
  { value: 'editor', label: 'Editor' }
];

export interface Member {
  id: string;
  value: string;
  label: string;
  email: string;
}

const AttendeeListModal = ({
  isOpen,
  toggleModal,
  selectedAttendees,
  openSharedListModel,
  workspaceId
}: {
  isOpen: boolean;
  toggleModal: () => void;
  selectedAttendees: MutableRefObject<any[]>;
  openSharedListModel: () => void;
  workspaceId: string;
}) => {
  const {
    state: { userId }
  } = useContext(AppContext);
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<{ value: string; label: string } | null>({
    value: 'viewer',
    label: 'Viewer'
  });

  const handleChange = (options: any) => {
    setSelectedMembers(options);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    const users = await getWorkspaceUsers(workspaceId, userId);
    const memberList = users?.filter((user: Member) => !user.label.includes('Myself'));
    setMembers(memberList);
  };

  return (
    <Modal open={isOpen} onClose={toggleModal} styles={{ borderRadius: '5px' }}>
      <ModalContainer width="528px">
        <ModalTitle>Attendee List</ModalTitle>
        <ModalBody>
          <ModalText>Add attendees for this meeting</ModalText>
          <InputContainer>
            <Select
              // defaultValue={[testMembers[2], testMembers[3]]}
              isMulti
              name="attendee"
              options={members}
              className="attendee-select"
              classNamePrefix="select"
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleChange}
              placeholder="Search Users"
            />
            <Select
              options={permissionOptions}
              defaultValue={permissionOptions[0]}
              name="permission"
              className="permission-select"
              onChange={(option) => {
                setSelectedPermission(option);
              }}
              components={{ IndicatorSeparator: () => null }}
              placeholder="Search Users"
            />
          </InputContainer>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              selectedAttendees.current = selectedMembers.map((members) => {
                return { ...members, permission: selectedPermission };
              });
              toggleModal();
              openSharedListModel();
            }}
          >
            Next
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default AttendeeListModal;
