import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as SelectChatIllustration } from '../../assets/illustration/selectchat.svg';
import ChatArea from '../../components/Chat/ChatArea/ChatArea';
import ChatMembers from '../../components/Chat/ChatList/ChatList';
import Loader from '../../components/Loader';
import { fetchConversations } from '../../reducers/conversations_new';
import { RootState, useAppDispatch } from '../../store';
import { ErrorPage } from '../Others';
type Props = {};

const Container = styled.div`
  display: flex;
  height: calc(100vh - 70px);
`;

const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NewMessages = (props: Props) => {
  const { loading, error, selectedConversationId } = useSelector((state: RootState) => state.conversations);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchConversations());
  }, []);

  if (loading) return <Loader />;
  if (error) return <ErrorPage />;

  return (
    <Container>
      <ChatMembers />

      {selectedConversationId ? (
        <ChatArea />
      ) : (
        <IllustrationContainer>
          <SelectChatIllustration width={500} />
        </IllustrationContainer>
      )}
      {/* <h1>Hi hello</h1> */}
    </Container>
  );
};

export default NewMessages;
