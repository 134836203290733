import styled from '@emotion/styled';
import { useState, useEffect, useContext } from 'react';
import { useToggle } from '../../lib/UseToggle';
import * as yup from 'yup';
import Modal from '../Modal';
import { DarkButton, LightBlueButton, paddingTwo, TransparentButton } from '../styles/ButtonStyles';
import { ItemStyles } from '../styles/ListItemStyles';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
import Button, { CustomButton } from '../CustomButton';
import Select from 'react-select';
import { memberStyles } from '../styles/MemberStyles';
import CustomAvatar from '../TableComponents/CustomAvatar';
import axiosInstance from '../../axiosInstance';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import CloseIcon from '../../assets/SvgComponents/CloseIcon';
import { Input } from '../styles/Inputs';
import { useLocation } from 'react-router';
import { AppContext } from '../../AppContext';
import { useSelector } from 'react-redux';
// import { css, jsx } from "@emotion/react";
// import { data } from "../../constants/KanbanData";
// import { PageTitle } from "../styles/Header";
// import { FileOptions } from "../../constants/FileOptions";
// import { MemberOptions } from "../../constants/MemberOptions";

const ActionContainer = styled.div`
  > * + * {
    margin-left: 10px;
  }
  padding-right: 35px;
  ${DarkButton} {
    ${paddingTwo}//modifying the padding
  }
  ${TransparentButton} {
    ${paddingTwo}//modifying the padding
  }
  button {
    svg {
      vertical-align: middle;
      margin-left: 5px;
      display: inline-block;
      margin-bottom: 2px;
    }
  }
`;
export const Text = styled.p`
  font-weight: 600;
`;
const RoleNameStyles = styled.div`
  display: flex;
  min-width: 40%;
`;
const RoleTitleStyles = styled.div`
  display: flex;
  padding-left: 48px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;
const RoleDetails = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 30%;
  > * + * {
    margin-left: 10px;
  }
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;

export const ModalInput = styled(Input)`
  width: 95%;
  display: block;
  margin: 0 auto;

  /* margin: 38px; */
`;
const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: flex-end;
  padding-right: 2rem;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;
const SelectDropdown = styled(Select)`
  margin-left: 32px;
`;

export const LoaderSkeleton = styled.div`
  .skeleton {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  .skeleton-text {
    width: 90%;
    height: 30px;
    margin-bottom: 1rem;
    border-radius: 2px;
  }
  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
`;

function RoleItem({
  role,
  users,
  dispatch
}: // userArray,
// addSelectedMemberToRole,
{
  role: any;
  users: any;
  dispatch: any;
  // userArray: any;
  // addSelectedMemberToRole: any;
}) {
  const { projectId, workspaceId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, toggle] = useToggle(false);
  const [isRemoveMemberModalOpen, toggleRemoveMemberModal] = useToggle(false);
  const [isinviteMemberModalOpen, toggleInviteMemberModal] = useToggle(false);
  const [member, setMember] = useState('');
  const [userId, setUserId] = useState('');
  const [selectedMember, setSelectedMember] = useState({});
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isUserInvited, setUserInvited] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const orgMembers = useSelector((state) => state.orgMembers.users);
  const { state } = useContext(AppContext);
  const { state: RouterState } = useLocation();

  let schema = yup.object().shape({
    email: yup.string().email()
  });

  const handleSelectChange = (name) => (option) => {
    setSelectedMember(option);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const inviteMember = () => {
    let toastId;
    schema
      .isValid({
        email: email
      })
      .then((valid) => {
        if (valid && email !== '') {
          toastId = toast.loading('Sending Email');
          let arrayOfEmaildIds = orgMembers && orgMembers.length > 0 && orgMembers.map((user) => user.email);
          if (arrayOfEmaildIds.includes(email)) {
            throw new Error('ExistingUser');
          }
          setEmailError(false);
          setSendingEmail(true);
          let values = {
            email: email.toLowerCase(),
            inviterId: state.userId,
            selectedRole: role.roleName,
            workspaceId: workspaceId,
            projectId: projectId,
            workspaceName: RouterState?.workspaceName || 'Test Workspace',
            projectName: 'TEST PROJECT',
            organisationId: state.orgId,
            roleId: role.id,
            host: window.location.origin
          };

          axiosInstance
            .post(`/workspaceInvite`, {
              ...values
            })
            .then((res) => {
              setSendingEmail(false);
              if (res.data) {
                setUserInvited(true);
                toggleInviteMemberModal();
                toast.dismiss(toastId);
                toast.success('Workspace Invite sent to user');
              }
            })
            .catch((err) => {
              setSendingEmail(false);
              toast.dismiss(toastId);
              toast.error('Something went wrong try again');
            });
        } else {
          setSendingEmail(false);
          setEmailError(true);
          toast.dismiss(toastId);
          toast.error('Please enter correct email address');
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        if (error.message === 'ExistingUser') {
          toast.error(`This user's email is already part of the organization`);
        }
        console.error('ERROR', error);
      });
  };
  const addMemberToRole = () => {
    let toastId = toast.loading('Adding Member');
    // const roleType = role.roleName===role.registerData.initiatorRole?"initiator":"responder"
    // const registerData=role.registerData
    axiosInstance
      .post('/rolesMapping', {
        userId: selectedMember.id,
        roleId: role.id,
        entityType: 'WORKSPACE',
        entityId: workspaceId,
        organizationId: state?.orgId
      })
      .then((res) => {
        setMember(selectedMember.value);
        // selectedMember.addedToRole = true
        dispatch({ type: 'UPDATE USER', data: { userId: selectedMember.id, roleValue: true } });
        // axiosInstance
        //   .get(`/rolesMapping?entityType=WORKSPACE&entityId=${workspaceId}&roleId=${role.id}`)
        //   .then((res) => {
        //     let roleDetails = res.data.data[res.data.data.length - 1];
        //     if (roleDetails && roleDetails.user) {
        //       // setMember(roleDetails.user.fullName)
        //       setUserId(roleDetails.id);
        //       //send notification here
        //       // axiosInstance
        //       // .post("/notification", {
        //       //   entityType: "REGISTER",
        //       //   entityId: registerData._id,
        //       //   actionType: "assignment",
        //       //   actionBy: state?.userId,
        //       //   actionByName: state?.fullName,
        //       //   actionByProfilePic: state?.profilePhoto,
        //       //   message: `<p><span class=blackBold>${state?.fullName}</span> assigned you as the ${roleType} for the regitser <span class=blackBold>${registerData.name}</span> in workspace <span class=blackBold>${role.workspaceName}</span> </p>`,
        //       //   userToNotify: selectedMember.id,
        //       //   workspaceId: workspaceId,
        //       //   projectId: projectId,
        //       //   organizationId: state?.orgId,
        //       // }).then((res)=>{
        //       // })
        //       setIsLoading(false);
        //       toast.dismiss(toastId)
        //       toast.success('Member Added');
        //       toggle();
        //     } else {
        //       setIsLoading(false);
        //     }
        //   }); // setUserId(role.id)
        toast.dismiss(toastId);
        toast.success('Member Added');
        toggle();
      })
      .catch((error) => {
        toast.dismiss(toastId);
        setIsLoading(false);
      });
  };

  const removeMemberFromRole = (role) => {
    let toastId = toast.loading('Removing Member');
    // const roleType = role.roleName === role.registerData.initiatorRole ? "initiator" : "responder"
    axiosInstance
      .delete(`/rolesMapping/${userId}`)
      .then((res) => {
        if (res.data.status_code === 200) {
          // selectedMember.addedToRole = false
          // axiosInstance
          //   .post("/notification", {
          //     entityType: "REGISTER",
          //     entityId: role.registerData._id,
          //     actionType: "delete",
          //     actionBy: state?.userId,
          //     actionByName: state?.fullName,
          //     actionByProfilePic: state?.profilePhoto,
          //     message: `<p><span class=blackBold>${state?.fullName}</span> removed you as the ${roleType} for the regitser <span class=blackBold>${role.registerData.name}</span> in workspace <span class=blackBold>${role.workspaceName}</span> </p>`,
          //     userToNotify: selectedMember.id,
          //     workspaceId: workspaceId,
          //     projectId: projectId,
          //     organizationId: state?.orgId,
          //   }).then((res) => {
          //   })
          dispatch({ type: 'UPDATE USER', data: { userId: selectedMember.id, roleValue: false } });
          setMember('');
          toast.success('Member Removed', {
            id: toastId
          });
          toggleRemoveMemberModal();
        }
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  };

  useEffect(() => {
    axiosInstance
      .get(`/rolesMapping?entityType=WORKSPACE&entityId=${workspaceId}&roleId=${role.id}`)
      .then((res) => {
        let roleDetails = res.data.data[res.data.data.length - 1];
        if (roleDetails && roleDetails.user) {
          setMember(roleDetails.user.fullName);
          setUserId(roleDetails.id);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  if (isLoading)
    return (
      <LoaderSkeleton>
        <div style={{ marginTop: '20px' }} className="skeleton skeleton-text"></div>
      </LoaderSkeleton>
    );

  return (
    <ItemStyles>
      <RoleDetails>
        <RoleTitleStyles>
          <div>{member && <CustomAvatar isTable={false} name={member} size={21} />}</div>
          {!member && <Text>{role?.roleName}</Text>}
        </RoleTitleStyles>
        {member && (
          <>
            <RoleNameStyles>
              <Text>{role?.roleName}</Text>
            </RoleNameStyles>
          </>
        )}
      </RoleDetails>
      <ActionContainer>
        {!isUserInvited ? (
          !member ? (
            <>
              <DarkButton onClick={() => toggle()}>Add Member</DarkButton>
              <TransparentButton onClick={() => toggleInviteMemberModal()}>Send Invite</TransparentButton>
            </>
          ) : (
            <>
              <LightBlueButton onClick={() => toggleRemoveMemberModal()}>
                Remove
                <CloseIcon fill="#4C6D66" />
              </LightBlueButton>
            </>
          )
        ) : (
          <Text>Invite sent</Text>
        )}
      </ActionContainer>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Add Members</ModalTitle>
          <ModalBody>
            <ModalText>Add Members to Register Role</ModalText>
            <SelectDropdown name="member" options={users} styles={memberStyles} onChange={handleSelectChange('role')} />
          </ModalBody>
          <ButtonWrapper>
            <Button onClick={toggle} secondary>
              Cancel
            </Button>
            <Button onClick={addMemberToRole}>Add</Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
      <Modal open={isRemoveMemberModalOpen} onClose={toggleRemoveMemberModal}>
        <ModalContainer width="370px">
          <ModalTitle>Remove Member</ModalTitle>
          <ModalBody>
            <ModalText>Do you want to remove this member?</ModalText>
          </ModalBody>
          <ModalWrapper>
            <Button secondary onClick={toggleRemoveMemberModal}>
              Cancel
            </Button>
            <Button onClick={() => removeMemberFromRole(role)}>Confirm</Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
      <Modal open={isinviteMemberModalOpen} onClose={toggleInviteMemberModal}>
        <ModalContainer width="528px">
          <ModalTitle>Send Invitation via Email</ModalTitle>
          <ModalBody>
            <ModalText>Invite a member to this workspace</ModalText>
            <ModalInput
              placeholder="email"
              type="email"
              className="input-email"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              error={emailError}
            />
          </ModalBody>

          <ModalWrapper>
            <Button secondary onClick={toggleInviteMemberModal}>
              Cancel
            </Button>
            <Button disabled={sendingEmail} onClick={() => inviteMember()}>
              Send
            </Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
      {/* <Toaster /> */}
    </ItemStyles>
  );
}

export default RoleItem;
