import { gql } from '@apollo/client';

export const archiveFileMutation = gql`
  mutation MyMutation($id: ID!, $recordStatus: Float) @api(name: "library") {
    update_File(id: $id, syncMode: NODE_COMMITTED, input: { recordStatus: $recordStatus }) {
      result {
        _id
      }
    }
  }
`;

export const addCollectionMutation = gql`
  mutation addCollectionMutation(
    $collectionDescription: String
    $createdAt: String
    $createdBy: String
    $name: String
    $projectId: String
    $type: String
    $typeId: String
    $files: [String]
  ) @api(name: "library") {
    add_Collection(
      syncMode: NODE_COMMITTED
      input: {
        collectionDescription: $collectionDescription
        createdAt: $createdAt
        createdBy: $createdBy
        files: $files
        name: $name
        projectId: $projectId
        type: $type
        typeId: $typeId
      }
    ) {
      result {
        _id
      }
    }
  }
`;

export const updateCollectionMutation = gql`
  mutation updateCollectionMutation($id: ID!, $files: [String], $name: String) @api(name: "library") {
    update_Collection(id: $id, syncMode: NODE_COMMITTED, input: { files: $files, name: $name }) {
      result {
        _id
      }
    }
  }
`;

export const unarchiveFileMutation = gql`
  mutation MyMutation($id: ID!, $recordStatus: Float) @api(name: "library") {
    update_File(id: $id, syncMode: NODE_COMMITTED, input: { recordStatus: $recordStatus }) {
      result {
        _id
      }
    }
  }
`;

export const deleteCollectionMutation = gql`
  mutation deleteCollectionMutation($id: ID!) @api(name: "library") {
    remove_Collection(syncMode: NODE_COMMITTED, id: $id) {
      transaction {
        _id
      }
    }
  }
`;
