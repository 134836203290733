import React from 'react';
import styled from "@emotion/styled";
import Avatar from 'react-avatar';
import { Small } from '../styles/SmallText';
import { AlignmentProps } from './ItemRevisionCards';

const NameTag = styled.div<AlignmentProps>`
display: flex;
align-items: center;
justify-content: ${(props) => (props.alignment === 'right' ? 'flex-end' : 'flex-start')};
`

const UserName = styled.p`
margin-bottom: 0px;
margin-top: 0px;
font-weight: 600;
`
const RoleName = styled(Small)``
const Divider = styled.div`
margin-left: 15px;
`

type AvatarProps = {
  name: string,
  role?: String
  alignment?: 'left' | 'right'
}

function UserAvatar(props: AvatarProps) {
  const { name, role, alignment } = props
  return <>
    <NameTag alignment={alignment}>
      <Avatar round={true} size="33" name={name} />
      <Divider>
        <UserName>{name}</UserName>
        <RoleName>{role}</RoleName>
      </Divider>
    </NameTag>
  </>;
}

export default UserAvatar;
