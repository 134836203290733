import Button from '../../../components/CustomButton';
import { Field, FieldArray, FormikProvider } from 'formik';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { TaskDetails } from './CreateProjectWorkspaceTask';
import { editTaskOptions, priorityOptions } from '../../../constants/DropDownOptions';
import { statusStyles } from '../../../components/styles/StatusStyles';
import { Small } from '../../../components/styles/SmallText';
import { Label } from '../../../components/styles/Label';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  ChoicesWrapper,
  DateWrapper,
  DropDownWrapper,
  FieldSet,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import { customStyles } from './CreateProjectWorkspaceTask';
import Select from 'react-select';
import { ErrorPage } from '../../Others/ErrorPage';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { LinkInputWrapper } from '../../Library/FilesView/Files/CreateNewFile';
import { Input } from '../../../components/styles/Inputs';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import { ErrorText } from '../../../components/Reusable/NewFormik/TextError';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';
import Loader from '../../../components/Loader';
import LinkDisplay from '../../../components/LinkDisplay';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import useEditWorkspaceTask from '../../../hooks/features/workspaces/useEditWorkspaceTask';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../components/Reusable/NewFormik/TextArea1';

function EditWorkspaceTask() {
  const {
    loading,
    loadingWorkspaceUsers,
    error,
    workspaceUsersError,
    statusIndex,
    priorityIndex,
    submitting,
    data,
    doNothing,
    formik,
    getUser,
    workspaceUsers
  } = useEditWorkspaceTask();

  if (loading || loadingWorkspaceUsers) return <Loader />;
  if (error || workspaceUsersError) return <ErrorPage />;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Edit Task</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button type="submit">Update Task</Button>
        </TitleRight>
      </Header>
      <TaskDetails>
        <FieldSet>
          <div>
            <InputWrapper>
              <InputField
                name="title"
                label="Task Title"
                id="title"
                type="text"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={submitting}
                touched={formik.touched.title}
                errors={formik.errors.title}
              />
            </InputWrapper>
            <InputWrapper>
              <TextAreaField
                name="description"
                value={formik.values.description}
                type="text"
                id="description"
                label="Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errors={formik.errors.description}
                touched={formik.touched.description}
                disabled={submitting}
              />
            </InputWrapper>
            <LinkWrapper>
              <Label>Links</Label>
              <FormikProvider value={formik}>
                <FieldArray name="links">
                  {({ remove, push }) => (
                    <>
                      {formik.values.links &&
                        formik.values.links?.length > 0 &&
                        formik.values.links?.map((link, index) => {
                          return (
                            <>
                              {link.saved ? (
                                <LinkDisplay
                                  link={link.value}
                                  editLink={() => formik.setFieldValue(`links[${index}].saved`, false)}
                                  removeLink={() => remove(index)}
                                />
                              ) : (
                                <Field name={`links.${index}`}>
                                  {({ meta, field }) => (
                                    <>
                                      <LinkInputWrapper>
                                        <Input
                                          className="link-input"
                                          disabled={submitting}
                                          {...field}
                                          type="text"
                                          value={field.value.value}
                                          error={meta.touched && meta.error}
                                          onChange={(e) =>
                                            formik.setFieldValue(`links[${index}].value`, e.target.value)
                                          }
                                        />
                                        <Button
                                          disabled={submitting}
                                          onClick={() => {
                                            if (!meta.error?.value) {
                                              formik.setFieldValue(`links[${index}].saved`, true);
                                            }
                                          }}
                                        >
                                          Save
                                        </Button>
                                        <CloseIcon
                                          onClick={() => (!submitting ? remove(index) : doNothing())}
                                          height="25px"
                                          width="25px"
                                        />
                                      </LinkInputWrapper>
                                      {meta.touched && meta.error && (
                                        <ErrorText>{meta.error.value || meta.error.saved} </ErrorText>
                                      )}
                                    </>
                                  )}
                                </Field>
                              )}
                            </>
                          );
                        })}
                      <div>
                        <Button disabled={submitting} onClick={() => push({ value: '', saved: false })}>
                          + Add Link
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </FormikProvider>
            </LinkWrapper>
          </div>
          <div>
            <ChoicesWrapper>
              <Label>Status</Label>
              <Select
                name="status"
                value={statusIndex}
                onChange={(status: any) => {
                  if (status) {
                    formik.setFieldValue('status', status.value);
                  } else {
                    formik.setFieldValue('status', '');
                  }
                }}
                error={formik.errors.status}
                touched={formik.touched.status}
                options={editTaskOptions}
                styles={{ ...statusStyles }}
                isDisabled={submitting}
              />
              <Label>Priority</Label>
              <Select
                name="priority"
                options={priorityOptions}
                value={priorityIndex}
                styles={statusStyles}
                onChange={(priority: any) => {
                  if (priority) {
                    formik.setFieldValue('priority', priority.value);
                  } else {
                    formik.setFieldValue('priority', '');
                  }
                }}
                error={formik.errors.priority}
                touched={formik.touched.priority}
                isDisabled={submitting}
              />
            </ChoicesWrapper>
            <DateWrapper>
              <Label>Due Date</Label>
              <DateInputField
                type="date"
                name="dueDate"
                value={formik.values.dueDate}
                onChange={formik.handleChange}
                touched={formik.touched.dueDate}
                errors={formik.errors.dueDate}
                disabled={submitting}
              />
            </DateWrapper>
            <AssigneeWrapper>
              <Label>Reporter</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar isTable={true} name={data?.get_Task?.assigneeDetails.name} size={21} />
                </AvatarStyles>
                <Small>
                  {getDate(data?.get_Task?.createdAt)} {getTime(data?.get_Task?.createdAt)}
                </Small>
              </AssigneeSeparator>
            </AssigneeWrapper>
            <DropDownWrapper>
              <Label>Assigned To</Label>
              <Select
                name="assignedTo"
                menuPortalTarget={document.body}
                value={getUser(formik.values.assignedTo, workspaceUsers)}
                isSearchable={true}
                isClearable={true}
                options={workspaceUsers}
                isDisabled={submitting}
                styles={{
                  ...customStyles,
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
                onChange={(value: any) => {
                  if (value) {
                    formik.setFieldValue('assignedTo', value.id);
                    formik.setFieldValue('assignedToDetails.name', value ? value.value : '');
                  } else {
                    formik.setFieldValue('assignedTo', '');
                  }
                }}
              />
            </DropDownWrapper>
            {formik.touched['assignedTo'] && formik.errors['assignedTo'] ? (
              <p style={{ color: '#ff3c3c', marginLeft: '1rem' }}>{formik.errors['assignedTo']}</p>
            ) : null}
          </div>
        </FieldSet>
      </TaskDetails>
    </form>
  );
}

export default EditWorkspaceTask;
