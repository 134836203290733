import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from '../../../../lib/UseToggle';
import { ModalInput, ModalText } from '../../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../../CustomButton';
import Modal from '../../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../styles/ModalStyles';
import SelectMembersModal from './SelectMembersModal';

interface Props {
  addGroup: boolean;
  closeAddGroup: () => void;
}

const GroupNameModal = ({ addGroup, closeAddGroup }: Props) => {
  const [groupName, setGroupName] = useState('');

  const [isMemberModalOpen, toggleMemberModal] = useToggle();

  const users = useSelector((state: any) =>
    state?.orgMembers?.users?.map((u: any) => ({
      id: u.id,
      name: u.fullName,
      picture: u.profilePhoto
    }))
  );

  return (
    <>
      <Modal open={addGroup} onClose={() => closeAddGroup()}>
        <ModalContainer width="500px">
          <ModalTitle>Group Name</ModalTitle>
          <ModalBody>
            <ModalText>What do you want to name the group ?</ModalText>
            <ModalInput
              value={groupName}
              type="text"
              placeholder="Group Name"
              onChange={(e) => setGroupName(e.target.value)}
            />
          </ModalBody>
          <ModalWrapper>
            <Button
              onClick={() => {
                setGroupName('');
                closeAddGroup();
              }}
              secondary
            >
              Cancel
            </Button>
            <Button
              disabled={groupName.trim() === ''}
              onClick={() => {
                closeAddGroup();
                toggleMemberModal();
              }}
            >
              Add Members
            </Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
      <SelectMembersModal
        groupName={groupName}
        users={users}
        isOpen={isMemberModalOpen}
        toggleModal={() => {
          toggleMemberModal();
        }}
        setGroupName={setGroupName}
      />
    </>
  );
};

export default GroupNameModal;
