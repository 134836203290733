import { gql } from '@apollo/client';

export const updateNoteDraftStatusMutation = gql`
  mutation exampleMutation($id: ID!, $isDraft: Boolean) @api(name: "workspace") {
    update_NoteAndDisscussion(id: $id, input: { isDraft: $isDraft }, syncMode: NODE_COMMITTED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
