import { useMutation } from '@apollo/client';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { deleteNoteMutation } from '../../../graphql/operations/Mutations/MeetingNotes/MeetingNotesMutation';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { useDispatch } from 'react-redux';
import { resetNote } from '../../../reducers/meetingNote';
const DeleteNoteModal = ({ isOpen, toggleModal }: { isOpen: boolean; toggleModal: () => void }) => {
  const [deleteNote] = useMutation(deleteNoteMutation);
  const navigate = useNavigate();
  const { noteId } = useParams();
  const dispatch = useDispatch();

  const handleDeleteNote = async () => {
    const id = toast.loading('Deleting note');
    try {
      await deleteNote({
        variables: {
          id: noteId,
          recordStatus: 1
        }
      });
      toast.success('Note deleted successfully', { id });
      dispatch(resetNote());
      navigate('/meetingnotes');
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!', { id });
    }
  };

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Delete Note</ModalTitle>
        <ModalBody>
          <ModalText>Are you sure you want to delete this note?</ModalText>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={false} onClick={handleDeleteNote}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteNoteModal;
