import { useReducer, createContext } from "react";
// import { useState } from "react";
// const pinnedProjectsContext = createContext();

type PinnedProjects = {
  id: string;
  name: string;
};

const initialState: PinnedProjects[] = [];

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_PROJECTS":
      return [...state, ...action.data];
    case "FILTER_PROJECTS":
      return state.filter(
        (project: PinnedProjects) => project.id !== action.data[0].id
      );
    case "RESET":
      return initialState;
    default:
      return [];
  }
};

const PinnedProjectsContext = createContext({
  state: initialState,
  dispatch: (param: Object) => { },
});

function PinnedProjectsProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value: any = { state, dispatch };
  return (
    <PinnedProjectsContext.Provider value={value}>
      {props.children}
    </PinnedProjectsContext.Provider>
  );
}

export { PinnedProjectsProvider, PinnedProjectsContext };
