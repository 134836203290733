import { useMutation } from '@apollo/client';
import styled from '@emotion/styled/';
import { Formik } from 'formik';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import InputField from '../../../components/Reusable/NewFormik/Input';
import { TextAreaField } from '../../../components/Reusable/NewFormik/Textarea';
import { GreyButton } from '../../../components/styles/ButtonStyles';
import { AssigneeSeparator, AssigneeWrapper, AvatarStyles, InputWrapper } from '../../../components/styles/FormStyles';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import CreateProjectValidation from '../../../components/Validations/CreateProjectValidation';
import { createProjectMutation } from '../../../graphql/operations/Mutations/Projects/ProjectMutations';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';

const Container = styled.div`
  background-color: #f3f3f3;
  padding: 25px;
  margin: 0px 50px;
  height: 100%;
`;
const HeadingContainer = styled.div`
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-top: 2rem;
  padding-bottom: 2.2rem;
  border-bottom: 1px solid #d5d6d5;
  margin: 0px 50px;
  p {
    margin-top: 2px;
  }
`;
const HeadingText = styled.p`
  font-size: 1rem;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 5px;
`;
const SubHeading = styled.p`
  font-size: 0.8rem;
`;

const Form = styled.form``;

function CreateProjectAndAddWorkspace() {
  const { state } = useContext(AppContext);
  const { state: RouterState } = useLocation();
  let navigate = useNavigate();
  const [createProject] = useMutation(createProjectMutation);
  const initialState = {
    projectCode: '',
    projectName: '',
    client: '',
    description: ''
    // organizationId: "",
    // createdBy: "",
    // createdAt: "",
    // updatedAt: "",
    // updatedBy:
  };
  const handleSubmit = async (values) => {
    const toastId = toast.loading('Creating project and adding workspace');
    try {
      let res = await createProject({
        variables: {
          projectCode: values.projectCode.toString(),
          projectName: values.projectName,
          client: values.client,
          description: values.description,
          organizationId: state.orgId,
          createdBy: state.userId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          updatedBy: state.userId,
          recordStatus: 0,
          isShared: true,
          notificationData: {
            actionType: 'add_project',
            // projectId: res.data.add_Project_async.result._id,
            organizationId: state.orgId
          }
        }
      });
      if (res) {
        let projectId = res.data.add_Project.result._id;
        let data = {
          userId: state.userId,
          entityType: 'WORKSPACE',
          entityId: RouterState?.entityId,
          projectId: projectId,
          organizationId: state.orgId
        };
        axiosInstance
          .post('/userEntity', [data])
          .then((response) => {})
          .then(() => {
            if (RouterState?.roleId) {
              axiosInstance
                .post('/rolesMapping', {
                  userId: state.userId,
                  roleId: RouterState.roleId,
                  entityType: 'WORKSPACE',
                  entityId: RouterState.entityId,
                  organizationId: state?.orgId
                })
                .then((response) => {
                  toast.dismiss(toastId);
                  toast.success('Added To Workspace');
                  navigate('/projects');
                });
            } else {
              toast.dismiss(toastId);
              toast.success('Added to workspace.');
              navigate('/projects');
            }
          });
      }
    } catch (error) {
      console.error('ERROR', error);
      toast.dismiss(toastId);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={CreateProjectValidation}
      >
        {({ values, isSubmitting, handleSubmit }) => {
          return (
            <div>
              <HeadingContainer>
                <div>
                  <HeadingText>Create a new project.</HeadingText>
                  <SubHeading>Create a new project to which you want to add this workspace to.</SubHeading>
                </div>
              </HeadingContainer>
              <Container>
                <Form onSubmit={handleSubmit}>
                  <InputWrapper>
                    <Label htmlFor="projectName">Project Name</Label>
                    <InputField name="projectName" type="text" disabled={isSubmitting} id="projectName"></InputField>
                  </InputWrapper>
                  <InputWrapper>
                    <Label htmlFor="projectCode">Project Code</Label>
                    <InputField name="projectCode" type="text" id="projectCode" disabled={isSubmitting}></InputField>
                  </InputWrapper>
                  <AssigneeWrapper>
                    <Label>Created By</Label>
                    <AssigneeSeparator>
                      <AvatarStyles>
                        <CustomAvatar name={`${state.fullName}`} size={21} />
                      </AvatarStyles>
                      <Small>
                        {getDate()} {getTime()}
                      </Small>
                    </AssigneeSeparator>
                  </AssigneeWrapper>
                  <InputWrapper>
                    <Label htmlFor="client">Client</Label>
                    <InputField name="client" id="client" type="text"></InputField>
                  </InputWrapper>
                  <InputWrapper>
                    <TextAreaField
                      name="description"
                      label="Description"
                      id="description"
                      disabled={isSubmitting}
                    ></TextAreaField>
                  </InputWrapper>
                  <GreyButton disabled={isSubmitting} type="submit">
                    Create Project
                  </GreyButton>
                </Form>
              </Container>
            </div>
          );
        }}
      </Formik>
    </>
  );
}

export default CreateProjectAndAddWorkspace;
