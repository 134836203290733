import styled from '@emotion/styled';
//TIP-TAP imports
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import { Editor, EditorContent, useEditor } from '@tiptap/react';

import { css } from '@emotion/react';
import useChatSocket from '../../../../hooks/features/chat/useChatSocket';
import { useToggle } from '../../../../lib/UseToggle';
import Toolbar from './Toolbar';

interface Props {
  conversationId: string;
  setScroll: (scroll: boolean) => void;
}

const MessageInput = ({ conversationId, setScroll }: Props) => {
  const [showEmojis, toggleShowEmojis] = useToggle();
  const { sendMessage } = useChatSocket();

  const CustomDocument = Document.extend({
    content: `block*`,
    addKeyboardShortcuts() {
      return {
        // ↓ your new keyboard shortcut
        Enter: () => {
          return handleSendMessage(this.editor as Editor);
        }
      };
    }
  });

  const editor = useEditor({
    extensions: [
      CustomDocument,
      Bold,
      Paragraph,
      Text,
      Italic,
      Link,
      ListItem,
      OrderedList,
      BulletList,
      Underline
      // Placeholder.configure({
      //   placeholder: 'My Custom Placeholder'
      // })
    ],
    content: '',
    onFocus: () => {
      if (showEmojis) toggleShowEmojis();
    }
  });

  const handleSendMessage = (editor: Editor) => {
    setScroll(true);
    if (!editor || editor.getHTML().trim() === '') return false;

    //Check if srring empty
    if (
      editor
        .getHTML()
        .trim()
        .replace(/<\/?[^>]+(>|$)/g, '') === ''
    )
      return false;
    sendMessage(editor.getHTML().trim());
    editor.commands.clearContent(true);
    return true;
  };

  // const handleSendMessage = (text: string) => {};

  return (
    <InputStyles expanded>
      <EditorContent editor={editor} />
      <Toolbar
        editor={editor}
        showEmojis={showEmojis}
        toggleShowEmojis={toggleShowEmojis}
        sendMessage={handleSendMessage}
      />
    </InputStyles>
  );
};

export default MessageInput;

const InputStyles = styled.div<{ expanded?: boolean }>`
  padding: 0px 10px;
  padding-top: 10px;
  background-color: #ffffff;
  /* position: relative; */

  .emojis {
    position: absolute;
    bottom: 50px;
    right: 25px;
    /* left: 10px;  */

    .EmojiPickerReact {
      --epr-emoji-size: 20px;
    }
  }

  .ProseMirror {
    outline: none;
    height: 35px;
    max-height: 35px;
    background-color: #f6f5f5;
    border: 1px solid #acadac;

    padding: 5px;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    border-radius: 5px;
    /* &:focus {
      border-left: none;
    } */
    /* justify-content: center; */
    width: 100%;
  }

  /* ${(props) =>
    props.expanded &&
    css`
      .ProseMirror {
        height: 500px;
      }
    `} */
`;
