export const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson' },
  { value: 'Savannah Nguyen', label: 'Savannah Nguyen' },
  { value: 'Esther Howard', label: 'Esther Howard' }
];

export const statusOptions = [
  { value: 'todo', label: 'To-do', color: '#10957D' },
  { value: 'inProgress', label: 'In Progress', color: '#C37F0F' }
];

export const selectOptions = [
  // { value: 'none', label: 'None', color: '#708389' },
  { value: 'low', label: 'Low', color: '#3493B1' },
  { value: 'medium', label: 'Medium', color: '#FEB629' },
  { value: 'high', label: 'High', color: '#E71C1C' }
];

export const editTaskOptions = [
  {
    value: 'todo',
    label: 'To-do',
    color: '#10957D',
    backgroundColor: '#dbefec'
  },
  {
    value: 'inProgress',
    label: 'In Progress',
    color: '#C37F0F',
    backgroundColor: '#FFF2DC'
  },
  {
    value: 'done',
    label: 'Done',
    color: '#26303E',
    backgroundColor: '#EDF0F0'
  }
];

export const priorityOptions = [
  // { value: 'none', label: 'None', color: '#708389' },
  { value: 'low', label: 'Low', color: '#3493B1' },
  { value: 'medium', label: 'Medium', color: '#FEB629' },
  { value: 'high', label: 'High', color: '#E71C1C' }
];

export const hubspotPriorityOptions = [
  { value: 'LOW', label: 'Low', color: '#3493B1' },
  { value: 'MEDIUM', label: 'Medium', color: '#FEB629' },
  { value: 'HIGH', label: 'High', color: '#E71C1C' },
  { value: 'NONE', label: 'None', color: '#3493B1' }
];
