import Lottie from 'react-lottie';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import Button from '../../../../components/CustomButton';
import { InputField } from '../../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../../components/Reusable/NewFormik/TextArea1';
import { FieldSet, InputWrapper } from '../../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { useTeam } from '../../../../hooks/features/projects/useTeam';
import { ErrorPage } from '../../../Others/ErrorPage';
import { Container } from '../../ProjectViews/CreateProject';
import { MultipleFormWrapper, TextAreaWrapper } from './ProjectAddress';

function TeamFormPage() {
  const { formik, loading, error, linkState, submitting } = useTeam();
  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  if (error) {
    return <ErrorPage />;
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Team Details</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={submitting} type="submit">
              {linkState?.isEdit ? 'Edit Team' : 'Add Team'}
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <FieldSet>
            <div>
              <InputWrapper>
                <InputField
                  label="Organization Name"
                  type="text"
                  name="organizationName"
                  onChange={formik.handleChange}
                  value={formik.values.organizationName}
                  touched={formik.touched.organizationName}
                  errors={formik.errors.organizationName}
                  disabled={submitting}
                />
              </InputWrapper>
              <InputWrapper>
                <InputField
                  label="Field of Work"
                  type="text"
                  name="fieldOfWork"
                  onChange={formik.handleChange}
                  value={formik.values.fieldOfWork}
                  touched={formik.touched.fieldOfWork}
                  errors={formik.errors.fieldOfWork}
                  disabled={submitting}
                />
              </InputWrapper>
              <MultipleFormWrapper>
                <div className="phone-number">
                  <InputField
                    label="Phone Number"
                    placeholder="xxx-xxx-xxxx"
                    type="tel"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    touched={formik.touched.phone}
                    errors={formik.errors.phone}
                    disabled={submitting}
                  />
                </div>
                <div className="email">
                  <InputField
                    label="Email Id"
                    type="email"
                    name="emailId"
                    onChange={formik.handleChange}
                    value={formik.values.emailId}
                    touched={formik.touched.emailId}
                    errors={formik.errors.emailId}
                    disabled={submitting}
                  />
                </div>
              </MultipleFormWrapper>
              <TextAreaWrapper>
                <TextAreaField
                  label="Address"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  touched={formik.touched.emailId}
                  errors={formik.errors.address}
                  disabled={submitting}
                />
              </TextAreaWrapper>
            </div>
          </FieldSet>
        </Container>
      </form>
    </>
  );
}

export default TeamFormPage;
