import React, { useContext, useState } from 'react';
import { ModalInput, ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { AppContext } from '../../../AppContext';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { CREATE_PROJECT_NOTE_MUTATION } from '../../../graphql/operations/Mutations/ProjectNotes/ProjectNoteMutations';
import { useNavigate, useParams } from 'react-router';
import axiosInstance from '../../../axiosInstance';

const AddNewProjectNoteOnlyTitle = ({ isOpen, toggleModal }: { isOpen: boolean; toggleModal: () => void }) => {
  const { projectId, workspaceId } = useParams();

  const [noteName, setNoteName] = useState('');

  const { state } = useContext(AppContext);

  const navigate = useNavigate();

  const [createNote, { error, loading }] = useMutation(CREATE_PROJECT_NOTE_MUTATION, {
    fetchPolicy: 'no-cache'
  });

  const handleCreateNote = async () => {
    const id = toast.loading('Creating note');
    if (!noteName) return toast.error('Please enter the note name');

    try {
      const response = await createNote({
        variables: {
          content: '',
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          createdBy: state.userId,
          updateBy: state.userId,
          noteType: 'workspace',
          typeId: workspaceId,
          noteType_typeId: `workspace_${workspaceId}`,
          organizationId: state.orgId,
          organizationId_projectId_workspaceId: `${state.orgId}_${projectId}_${workspaceId}`,
          projectId: projectId,
          recordStatus: 0,
          workspaceId: workspaceId,
          title: noteName,
          isDraft: true
        }
      });
      const noteId = response.data.add_NoteAndDisscussion.result._id;

      //Create discussion for this note
      const data = await axiosInstance.post('/discussion', {
        entityId: noteId,
        entityType: 'WORKSPACE_NOTE', //Project_Note === Workspace_Note
        orgId: state.orgId,
        workspaceId: workspaceId
      });

      navigate(`/project/workspace/${projectId}/${workspaceId}/notebooks/${noteId}`);
      toast.success('Note created successfully', { id });
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong !', { id });
    }
  };

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="728px">
        <ModalTitle>New Project Note</ModalTitle>
        <ModalBody>
          <div style={{ padding: '15px' }}>
            <ModalText>Enter note name</ModalText>
            <ModalInput value={noteName} onChange={(e) => setNoteName(e.target.value)} />
          </div>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={false} onClick={handleCreateNote}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default AddNewProjectNoteOnlyTitle;
