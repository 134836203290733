import { useState, useContext } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import * as ReactQuill from 'react-quill';
import { useMutation, useQuery } from '@apollo/client';
import AvatarPicture from '../../../../assets/images/avatarPic.svg';
import { modules, formats } from '../../../../components/Files/textEditorConfig';
import Button from '../../../../components/CustomButton';
import { MdSend } from 'react-icons/md';
import { AppContext } from '../../../../AppContext';
import { TASK_DISCUSSION_QUERY } from '../../../../graphql/operations/Queries/Tasks/TaskQueries';
import { Container } from '../../../Tasks/TaskViews/ViewTask/TaskInformation/TaskDiscussions';
import { addDiscussionMutation } from '../../../../graphql/operations/Mutations/Tasks/TaskDiscussionsMutations';
// import useForm from "../../../../lib/UseForm";

const Editor = styled.div`
  margin-bottom: 70px;
  display: flex;
  .EditorView {
    background: #ffffff;
    width: 85%;
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .editor-container {
    height: 60px;
    background: #f3f3f3;
    opacity: 0.5;
    border: 1px solid #acadac;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 70%;
    transform: translateY(100%);
    border: none;
  }
`;

const DateStyle = styled.p`
  margin-right: 20px;
`;
const Time = styled.p``;

const SubmitButton = styled.span({
  Button: {
    position: 'absolute',
    right: '0.5%',
    bottom: '5.5%',
    background: 'none'
  },
  '.sendIcon': {
    background: '#26303E',
    borderRadius: '18.5px',
    color: '#ffffff',
    padding: '5px',
    fontSize: '2rem',
    cursor: 'pointer'
  }
});

// const Form = styled.form``;

const TaskDiscussionWorkspace = () => {
  const { state } = useContext(AppContext);
  let { taskId } = useParams();
  const [html, setHtml] = useState({});

  const {
    data: discussionData,
    // error: discussionError,
    // loading: discussionLoading,
    refetch: discussionRefetch
  } = useQuery(TASK_DISCUSSION_QUERY, {
    variables: {
      typeId: taskId + '__discussion',
      type: 'task'
    },
    fetchPolicy: 'no-cache'
  });

  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    setHtml(data);
  };

  const [sendMessage] = useMutation(addDiscussionMutation);

  async function handleSubmit(discussionData: any) {
    try {
      let Response = await sendMessage({
        variables: {
          content: html,
          createdAt: new Date().toISOString(),
          isHighlighted: false,
          noteType: 'task',
          typeId: taskId + '__discussion',
          createdBy: state?.userId
        }
      });
      if (Response) {
      }
      setHtml('');
      setTimeout(async () => {
        await discussionRefetch();
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <>
      <Container>
        <div>
          {discussionData?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems
            ? discussionData?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems?.map((CheckUser: any) => {
                const rawMarkup = () => {
                  var rawMarkup = CheckUser?.content;
                  return { __html: rawMarkup };
                };
                return (
                  <>
                    {CheckUser ? (
                      CheckUser?.createdBy !== state?.userId ? (
                        <div className="UserMessage">
                          <div className="PicWithChat">
                            <div>
                              <img src={AvatarPicture} alt="profile" />
                            </div>
                            <div className="userChat">
                              <p className="message">
                                <span dangerouslySetInnerHTML={rawMarkup()} />
                              </p>
                            </div>
                          </div>
                          <div className="UsermessageDateTime">
                            <DateStyle>
                              {new Date(CheckUser?.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                              })}
                            </DateStyle>
                            <Time>
                              {new Date(CheckUser?.createdAt).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </Time>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {CheckUser ? (
                      CheckUser?.createdBy === state?.userId ? (
                        <div className="MyMessage">
                          <div className="myChat">
                            <p className="message">
                              <span dangerouslySetInnerHTML={rawMarkup()} />
                            </p>
                          </div>
                          <div className="messageDateTime">
                            <DateStyle>
                              {new Date(CheckUser?.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                              })}
                            </DateStyle>
                            <Time>
                              {new Date(CheckUser?.createdAt).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </Time>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </>
                );
              })
            : ''}
        </div>
      </Container>
      <Editor>
        <div className="EditorView">
          <ReactQuill
            className="editor-container"
            theme="snow"
            modules={modules}
            formats={formats}
            onChange={onTextChange}
            // defaultValue={html}
            placeholder="Type your message"
            value={html}
            bounds={'.app'}
          ></ReactQuill>
        </div>
        <SubmitButton>
          <Button
            onClick={() => {
              handleSubmit(discussionData);
            }}
          >
            <MdSend className="sendIcon" />
          </Button>
        </SubmitButton>
      </Editor>
    </>
  );
};

export default TaskDiscussionWorkspace;
