import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import CreateTaskValidationSchema from '../../../components/Validations/CreateTaskValidation';
import { Success } from '../../../constants/ToastMessages';
import { updateTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { SINGLE_TASK_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import { LIST_WORKSPACE_QUERY } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { getWorkspaceIds } from '../../../lib/getWorkspaceIds';
import { getWorkspaceUsers } from '../../../lib/getWorkspaceUsers';
import { generateQuery } from '../../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../../reducers/dashboard';
import { sentryError } from '../../../lib/SentryError';

function useEditTask() {
  const navigate = useNavigate();
  let { taskId, noteId } = useParams();
  const { state } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [workspaceIds, setWorkspaceIds] = useState<string[]>([]);
  const [submitting, setIsSubmitting] = useState(false);
  const { data, error, loading } = useQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    onCompleted: () => {
      loadWorkspacesOfCurrentProject();
    }
  });
  const batchGetWorkspaces = generateQuery(workspaceIds);
  const [getAllWorkspaces, { data: WorkspaceData }] = useLazyQuery(batchGetWorkspaces, {
    fetchPolicy: 'network-only'
  });
  const {
    data: projectData,
    error: projectsError,
    loading: projectsLoading
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    },
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (workspaceIds.length > 0) {
      getAllWorkspaces();
    }
  }, [workspaceIds]);

  const loadWorkspacesOfCurrentProject = async () => {
    if (!data?.get_Task?.isPersonal) {
      let wsIds = await getWorkspaceIds(data?.get_Task?.projectId, state.userId);
      setWorkspaceIds(wsIds);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      let users = await getWorkspaceUsers(data?.get_Task?.typeId, state.userId);
      if (users && users.length > 0) {
        setUsers(users);
      } else {
        setUsers([]);
      }
    };
    getUsers();
  }, [data]);

  const {
    data: workspaces,
    loading: workspaceLoading,
    error: workspaceError
  } = useQuery(LIST_WORKSPACE_QUERY, {
    variables: {
      organizationId: state.orgId
    }
  });

  const getProjectName = (id: string) => {
    let found = projectData?.list_ProjectItems?._ProjectItems.find((project) => project._id === id);
    if (found) {
      return { name: found.name, id: found.id };
    }
    return { label: 'PERSONAL', value: 'PERSONAL' };

    // return "PERSONAL";
  };
  const getWorkspaceName = (id: string) => {
    // list_WorkspaceItems?._WorkspaceItems
    let found = workspaces.list_WorkspaceItems._WorkspaceItems?.find((workspace) => workspace._id === id);
    if (found) {
      return { name: found.name, id: id };
    }
    return { label: 'PERSONAL', value: 'PERSONAL' };
  };
  const getUserEmailId = (id: string) => {
    let found = users.find((user) => user.id === id);
    if (found) {
      return found.email;
    }
  };

  const initialState = {
    title: data?.get_Task?.title,
    projectId: data?.get_Task?.projectId,
    type: data?.get_Task?.type,
    workspaceId: data?.get_Task?.typeId,
    workspaceName: '',
    description: data ? data?.get_Task?.description : '',
    dueDate: data?.get_Task?.dueDate,
    isPersonal: data?.get_Task?.type === 'user' ? true : false,
    priority: data?.get_Task?.priority,
    status: data?.get_Task?.status,
    links:
      data?.get_Task?.links?.length > 0
        ? data?.get_Task?.links.map((link) => {
            return { value: `${link}`, saved: true };
          })
        : [],
    assignedTo: data?.get_Task?.assignedTo,
    assignedToDetails: {
      name: data?.get_Task?.assignedToDetails?.name,
      profilePicture: '',
      email: getUserEmailId(data?.get_Task?.assignedTo)
    },
    assignee: data?.get_Task?.assignee,
    assigneeDetails: {
      name: data?.get_Task?.assigneeDetails?.name,
      profilePicture: '',
      email: getUserEmailId(data?.get_Task?.assignee)
    }
  };

  const getUser = (id: string) => {
    let found = users.find((user) => user.id === id);
    if (found) {
      return { label: found.label, value: found.value, id: found.id, email: found.email };
    }
  };

  const clearValue = (selectRef) => {
    selectRef.select.clearValue();
  };

  const doNothing = () => {};

  const [updateTask, { error: mutationError }] = useMutation(updateTaskMutation, {
    fetchPolicy: 'no-cache'
  });

  const handleProjectChange = async (selectedOption: any, selectRef: any) => {
    if (selectedOption.name === 'Personal Task') {
      formik.setFieldValue('isPersonal', true);
    } else if (selectedOption._id === formik.values.projectId) {
      formik.setFieldValue('projectId', selectedOption._id);
      formik.setFieldValue('isPersonal', false);
      formik.setFieldValue('workspaceId', '');
      formik.setFieldValue('workspaceName', '');
      clearValue(selectRef);
      const workspaceIds = await getWorkspaceIds(selectedOption._id, state.userId);
      if (workspaceIds.length) {
        setWorkspaceIds(workspaceIds);
      } else {
        toast.error('You are not part of any workspaces in this project');
        setWorkspaceIds([]);
      }
    } else if (selectedOption._id !== formik.values.projectId) {
      formik.setFieldValue('isPersonal', false);
      formik.setFieldValue('projectId', selectedOption._id);
      formik.setFieldValue('workspaceId', '');
      formik.setFieldValue('workspaceName', '');
      clearValue(selectRef);
      const workspaceIds = await getWorkspaceIds(selectedOption._id, state.userId);
      if (workspaceIds.length) {
        setWorkspaceIds(workspaceIds);
      } else {
        toast.error('You are not part of any workspaces in this project');
        setWorkspaceIds([]);
      }
    }
  };
  const handleWorkspaceChange = async (selectedOption: any, userRef: any) => {
    formik.setFieldValue('workspaceId', selectedOption?._id ? selectedOption._id : '');
    formik.setFieldValue('workspaceName', selectedOption?.name ? selectedOption.name : '');
    formik.setFieldValue('assignedTo', '');
    formik.setFieldValue('assignedToDetails', { name: '', profilePicture: '', email: '' });
    if (selectedOption?._id) {
      userRef.select.clearValue();
      const users = await getWorkspaceUsers(selectedOption._id, state.userId);
      if (users.length > 0) {
        setUsers(users);
      } else {
        setUsers([]);
      }
    }
  };

  const handlePersonalTask = (event) => {
    formik.setFieldValue('isPersonal', event.target.checked);
  };

  const handleSubmit = async (taskData: any, { resetForm }: FormikHelpers<any>) => {
    let linkValues: string[] = [];
    const toastId = toast.loading('Editing task ...');
    setIsSubmitting(true);
    if (taskData.links && taskData.links.length > 0) {
      linkValues = taskData.links.map((link) => link.value);
    }
    let values = {
      assignedToDetails: {
        name: taskData?.assignedToDetails.name,
        profilePicture: ''
      },
      assignee: taskData?.assignee,
      assignedTo: taskData?.assignedTo,
      description: taskData?.description,
      dueDate: taskData?.dueDate,
      priority: taskData?.priority,
      status: taskData?.status,
      title: taskData?.title,
      id: taskId,
      projectId: taskData?.projectId,
      typeId: taskData?.workspaceId,
      workspaceID: taskData?.workspaceId,
      links: linkValues,
      updatedAt: new Date().toISOString(),
      updatedBy: state.userId
    };
    const projectName = getProjectName(taskData.projectId);
    const workspaceName = getWorkspaceName(taskData.workspaceId);

    try {
      if (taskData.isPersonal) {
        let Response = await updateTask({
          variables: {
            ...values,
            projectId: 'NOPROJECT',
            type: 'user',
            typeId: taskData.assignee,
            assignedToDetails: {
              name: state.fullName,
              profilePicture: ''
            }
          }
        });
        if (Response) {
          let task = {
            ...values,
            projectId: 'NOPROJECT',
            type: 'user',
            typeId: taskData.assignee,
            assigneeDetails: {
              name: taskData?.assigneeDetails.name,
              profilePicture: ''
            },
            _id: taskId
          };
          setIsSubmitting(false);
          toast.dismiss(toastId);
          toast.success(Success.taskUpdated);

          if (noteId) navigate(-1);
          else navigate('/tasks', { state: task });
        }
      } else if (!taskData.isPersonal) {
        let actionType = taskData?.status === 'done' ? 'completed' : 'update';
        let messageType = taskData?.status === 'done' ? 'completed' : 'update';
        let userToNotify = taskData?.status === 'done' ? taskData.assignee : taskData.assignedTo;
        let variables =
          userToNotify !== state?.userId
            ? {
                ...values,
                projectId: taskData.projectId,
                type: 'workspace',
                typeId: taskData.workspaceId,
                _id: taskId,
                organizationId_projectId_typeId: `${state.orgId}_${taskData.projectId}_${taskData.workspaceId}`,
                notificationData: {
                  actionType,
                  messageType,
                  taskName: taskData?.title,
                  dueDate: taskData?.dueDate,
                  workspaceName: workspaceName,
                  projectName: projectName,
                  projectId: taskData.projectId,
                  workspaceId: taskData.typeId,
                  organizationId: state?.orgId,
                  userToNotify,
                  host: window.location.origin
                }
              }
            : {
                ...values,
                projectId: taskData.projectId,
                type: 'workspace',
                typeId: taskData.workspaceId,
                _id: taskId
              };
        let response = await updateTask({
          variables: variables
        });
        if (response) {
          let task = {
            ...variables
          };
          setIsSubmitting(false);
          toast.dismiss(toastId);
          toast.success(Success.taskUpdated);
          dispatch(
            updateItem({
              _id: taskId,
              ...values
            })
          );
          if (noteId) navigate(-1);
          else navigate('/tasks', { state: task });
        }
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('Something went wrong in updating the task.');
      sentryError(error);
    } finally {
      toast.dismiss(toastId);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CreateTaskValidationSchema,
    validateOnBlur: true,
    onSubmit: handleSubmit,
    enableReinitialize: true
  });

  return {
    formik,
    loading,
    projectsLoading,
    workspaceLoading,
    users,
    error,
    mutationError,
    projectsError,
    workspaceError,
    submitting,
    projectData,
    getProjectName,
    handleProjectChange,
    getWorkspaceName,
    WorkspaceData,
    handleWorkspaceChange,
    doNothing,
    data,
    getUser,
    handlePersonalTask
  };
}

export default useEditTask;
