import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { AppContext } from '../AppContext';

function NoOrgOnlyRoute({ children }) {
  const { state } = useContext(AppContext);
  const location = useLocation();
  return state.orgId !== 'null' ? <Navigate to="/" replace state={{ path: location.pathname }} /> : children;
}

export default NoOrgOnlyRoute;
