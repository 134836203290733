import { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Header } from '../../../components/styles/Header';
import Button from '../../../components/CustomButton';
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../../../components/Files/textEditorConfig';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { useFormik } from 'formik';
import InputField from '../../../components/Reusable/Formik/Input';
import NoteValidation from '../../../components/Validations/NoteValidation';
import { AppContext } from '../../../AppContext';
import { ErrorPage } from '../../Others/ErrorPage';
import { createNoteMutation } from '../../../graphql/operations/Mutations/Library/NoteMutations';
// import useForm from "../../../lib/UseForm";
// import { LIST_NOTES_QUERY } from "../../../graphql/operations/Queries/Library/NotesQueries";
// import { useParams } from 'react-router';

/**
 * TipTap imports
 */
import { EditorContent, useEditor, Editor, BubbleMenu } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import Heading from '@tiptap/extension-heading';
import HardBreak from '@tiptap/extension-hard-break';
import Toolbar from '../../../components/Editor/Toolbar';

const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4.6rem;
  .notename {
    border: none;
    font-size: 1.5rem;
    height: 60px;
  }
`;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

export const TextEditorStyles = styled.div`
  /* display: flex; */
  width: 100%;
  min-height: 80vh;
  background: #f6f5f5;

  .editor-container {
    padding: 20px;
  }

  .ProseMirror {
    outline: none;
    min-height: 400px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px 10px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0px;
      margin: 0px;
    }
  }
`;

const Form = styled.form``;

function CreateNewNote() {
  const { state } = useContext(AppContext);
  let navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [html, setHtml] = useState('');
  const initialState = {
    title: queryParams.get('title') ? queryParams.get('title') : ''
  };
  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    setHtml(data);
  };

  const [addNote, { error }] = useMutation(createNoteMutation, {
    // refetchQueries: [{ query: 'LIST_NOTES_QUERY' }]
  });

  const AddNote = async (noteData: any, setSubmitting: any, resetForm: any) => {
    const toastId = toast.loading('Creating...');
    try {
      let Response = await addNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: noteData?.title,
          content: html,
          createdAt: new Date().toISOString(),
          createdBy: state?.userId,
          noteType: 'user',
          typeId: state?.userId,
          noteType_typeId: `user_${state?.userId}`,
          projectId: 'NOPROJECT',
          updateBy: state?.userId,
          updatedAt: new Date().toISOString()
        }
      });
      if (Response) {
        toast.success('New Note Created', {
          id: toastId
        });
        setTimeout(() => {
          navigate('/notes');
        }, 1000);
      }
    } catch (e) {
      toast.dismiss(toastId);
      toast.error('oh no something went wrong');
      console.error(e);
    }
    resetForm();
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: NoteValidation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      await AddNote(values, setSubmitting, resetForm);
    }
  });

  const CustomImage = Image.extend({
    draggable: true
  });

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Heading,
      Text,
      TextStyle,
      BulletList,
      OrderedList,
      ListItem,
      Bold,
      Underline,
      Italic,
      History,
      TextAlign.configure({
        types: ['paragraph']
      }),
      Table.configure({
        resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      CustomImage.configure({
        inline: true
      }),
      Link.configure({
        openOnClick: true,
        linkOnPaste: true
      }),
      HardBreak
    ],
    onUpdate({ editor }) {
      setHtml(editor.getHTML());
    },
    content: `${html}`
  });

  // if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          <TitleLeft>
            <InputField
              style={{ border: 'none', fontSize: '1.5rem', height: '60px' }}
              name="title"
              type="text"
              formik={formik}
              disabled={formik.isSubmitting}
              placeHolder="New Note"
            />
          </TitleLeft>
          <TitleRight>
            <Button type="submit">Save Note</Button>
          </TitleRight>
        </Header>
      </Form>
      <TextEditorStyles>
        <div className="editor-container">
          <Toolbar editor={editor} editorType="PROJECT_NOTE" />
          <EditorContent editor={editor} />
        </div>
      </TextEditorStyles>
    </div>
  );
}

export default CreateNewNote;
