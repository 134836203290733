import styled from '@emotion/styled';
import { Editor } from '@tiptap/react';
import { number } from 'prop-types';
import { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { EditingTable, EditorI, SectionI } from '../../../pages/MeetingNotes/types';
import SectionBody from './SectionBody';
import SectionHeader from './SectionHeader';

export const HeaderStyles = styled.div`
  .ProseMirror {
    background-color: #ffffff;
    padding: 2px 20px;
  }
  .ProseMirror .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }
  .my-custom-heading {
    font-size: 20px;
  }
  border-bottom: 1px solid #e9eaec;

  margin: 0px;
`;

interface Props {
  section: SectionI;
  setCurrentEditor: Dispatch<Editor | null>;
  handleCreateTask: (title: string, blockId: string) => void;
  editable?: boolean;
  editorType: 'MEETING_NOTE' | 'PROJECT_NOTE' | 'TASK_NOTE';
  editingTable: EditingTable;
  noteData: any;
  sectionNumber: number;
  noOfSections: number;
}

const SectionStyles = styled.div`
  background-color: #ffffff;
  margin-bottom: 20px;
  border-bottom: 3px solid #26303e;
`;

const Section = ({
  section,
  setCurrentEditor,
  handleCreateTask,
  editorType,
  editingTable,
  noteData,
  sectionNumber,
  noOfSections,
  editable = true
}: Props) => {
  const BlockLength = Object.keys(section.blockData).length;

  const editedBy = useRef<EditorI>();
  const [editing, setEditing] = useState(false);
  const [isEditable, setIsEditable] = useState(editable);

  const {
    state: { userId }
  } = useContext(AppContext);

  /**
   * Project note and Task note will have only one block. Hence we need to show edit status based on sectionID.
   * Meeting note will have multiple blocks, hence it will be mapped using blockID. This is done inside the SectionBody Component
   */
  useEffect(() => {
    if (editorType === 'MEETING_NOTE') return;
    
    if (editingTable.hasOwnProperty(section.sectionId) && editingTable[section.sectionId].editingBy !== userId) {
      setEditing(true);
      editedBy.current = editingTable[section.sectionId].editorDetails;
      setIsEditable(false);
    } else {
      setEditing(false);
      setIsEditable(true);
    }
  }, [editingTable,editorType,section]);

  return (
    <SectionStyles>
      <SectionHeader
        sectionId={section.sectionId}
        sectionHeader={section.sectionHeader}
        editable={editable}
        noteData={noteData}
        editorType={editorType}
        noOfSections={noOfSections}
        editingTable={editingTable}
        blocks={section.blockData}
      />

      {section.blockData.map((block, i) => (
        <SectionBody
          sectionId={section.sectionId}
          key={block.blockId}
          block={block}
          setCurrentEditor={setCurrentEditor}
          isLast={BlockLength === i + 1}
          editable={editable}
          handleCreateTask={handleCreateTask}
          editorType={editorType}
          editingTable={editingTable}
          noteData={noteData}
          editing={editing}
          setEditing={setEditing}
          editedBy={editedBy}
          sectionNumber={sectionNumber}
          blockNumber={i}
          numberOfBlocks={section.blockData.length}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
        />
      ))}
    </SectionStyles>
  );
};

export default Section;
