import styled from '@emotion/styled';
import { ReactComponent as PlusIcon } from '../../assets/icons/GreenPlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/MinusIcon.svg';
import { PlanType } from '../../pages/Organization/ViewOrganization';

export const SeatWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 35px;
  border-radius: 10px;
  background: #f6f6f6;
  padding: 32px;
  margin-top: 30px;
`;

export const SeatSelectionTitle = styled.h3`
  font-size: 1.5rem;
  color: #1c1c1c;
  font-weight: 600;
`;

export const SeatBox = styled.div`
  border: 1px solid #bababa;
  border-radius: 5px;
  padding: 16px;
  padding-top: 16px;
  padding-left: 16px;
  margin-bottom: 32px;
`;

export const SubscriptionType = styled.p`
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
`;
export const PricingText = styled.p`
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #1c1c1c;
`;

export const SeatSelectionDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SeatQuantityText = styled.p`
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 0px;
`;

export const SeatQuantityBox = styled.div`
  display: flex;
  border-radius: 5px;
  background: #ffffff;
  justify-content: space-between;
  padding: 10px;
  align-content: center;
  align-items: center;
  min-width: 164px;
  border: 1px solid #acadac;
  button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`;

export const TotalAmountText = styled.p`
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 18px;
`;

const AwaitingSeatsInformationBox = styled.div`
  margin-left: 35px;
  border-radius: 5px;
  background: #f6f6f6;
  width: 40%;
  padding: 32px;
  margin-top: 30px;
`;
const NumberOfMembersAwaitingSeatsText = styled.p`
  font-size: 1rem;
  font-weight: 600;
`;
const InformationText = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

type SeatsProps = {
  setClientSecret: React.Dispatch<React.SetStateAction<string>>;
  plan?: PlanType | undefined;
  routerState: any;
  monthlyQuantity: number;
  yearlyQuantity: number;
  increaseSeatQuantity: (type: PlanType) => void;
  decreaseSeatQuantity: (type: PlanType) => void;
};

function SelectSeats(props: SeatsProps) {
  const { routerState, decreaseSeatQuantity, increaseSeatQuantity, monthlyQuantity, yearlyQuantity } = props;

  // const { state } = useContext(AppContext);

  return (
    <>
      {routerState?.awaiting ? (
        <AwaitingSeatsInformationBox>
          <NumberOfMembersAwaitingSeatsText>
            You have {routerState?.awaiting} members awaiting for seats in your organization.
          </NumberOfMembersAwaitingSeatsText>
          <InformationText>
            Choose the required seat quantity with either monthly or yearly pay to keep your organization active.
          </InformationText>
        </AwaitingSeatsInformationBox>
      ) : null}
      <SeatWrapper>
        <SeatSelectionTitle>Choose the number of seats</SeatSelectionTitle>
        {routerState.plan === 'month' && (
          <SeatBox>
            <SubscriptionType>Monthly Subscription</SubscriptionType>
            <PricingText>Each seat billed monthly for US$ 17.00/Month</PricingText>
            <SeatSelectionDiv>
              <div>
                <SeatQuantityText>Seat Quantity</SeatQuantityText>
                <SeatQuantityBox>
                  <button onClick={() => decreaseSeatQuantity('month')}>
                    <MinusIcon />
                  </button>
                  <div>{monthlyQuantity}</div>
                  <button onClick={() => increaseSeatQuantity('month')}>
                    <PlusIcon />
                  </button>
                </SeatQuantityBox>
              </div>
              <div>
                <SubscriptionType>Monthly Bill Amount</SubscriptionType>
                <TotalAmountText>US$ {17 * monthlyQuantity}.00</TotalAmountText>
              </div>
            </SeatSelectionDiv>
          </SeatBox>
        )}
        {routerState.plan === 'year' && (
          <SeatBox>
            <SubscriptionType>Yearly Subscription</SubscriptionType>
            <PricingText>Each seat billed yearly for US$ 9.00/Month</PricingText>
            <SeatSelectionDiv>
              <div>
                <SeatQuantityText>Seat Quantity</SeatQuantityText>
                <SeatQuantityBox>
                  <button onClick={() => decreaseSeatQuantity('year')}>
                    <MinusIcon />
                  </button>
                  <div>{yearlyQuantity}</div>
                  <button onClick={() => increaseSeatQuantity('year')}>
                    <PlusIcon />
                  </button>
                </SeatQuantityBox>
              </div>
              <div>
                <SubscriptionType>Yearly Bill Amount</SubscriptionType>
                <TotalAmountText>US$ {9 * yearlyQuantity * 12}.00</TotalAmountText>
              </div>
            </SeatSelectionDiv>
          </SeatBox>
        )}
      </SeatWrapper>
    </>
  );
}

export default SelectSeats;
