import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ReactComponent as Illustration } from '../../assets/illustration/cookieAndPrivacy.svg';
import axiosInstance from '../../axiosInstance';
import Button from '../CustomButton';
import Modal from '../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

interface Props {}

const CookieAndPrivacyModal = ({}: Props) => {
  const [isOpen, toggle] = useState(true);
  const { state } = useContext(AppContext);

  const location = useLocation();


  const handleClick = async () => {
    //Call /users with
    // /users/userId
    const id = toast.loading('Saving your choice..');
    try {
      const data = await axiosInstance.patch(`/users/${state.userId}`, {
        cookiesEnabled: true
      });
      toggle(false);
    } catch (error) {
      console.log('Error', error);
      toast.error('Something went wrong');
    } finally {
      toast.dismiss(id);
    }
  };

  return location.pathname !== '/useragreement' && !state.cookiesEnabled ? (
    <Modal open={isOpen} onClose={() => {}}>
      <ModalContainer width="660px">
        <ModalTitle>Cookies And Privacy</ModalTitle>
        <ModalBody>
          <Wrapper>
            <div className="illustration-container">
              <Illustration />
            </div>
            <div className="detail-container">
              <p> We use cookies to ensure you get the best experience on PlatformSuite.</p>
              <p>
                As mentioned in the{' '}
                <Link to="/useragreement" target="_blank">
                  User Agreement
                </Link>
                , please accept the necessary cookies for a hassle free functioning of PlatformSuite.
              </p>
              <div className="consent">
                <input type="checkbox" disabled checked />
                <p>Collect only necessary cookies</p>
              </div>
              <ModalWrapper style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={handleClick}>I Understand</Button>
              </ModalWrapper>
            </div>
          </Wrapper>
        </ModalBody>
      </ModalContainer>
    </Modal>
  ) : null;
};

export default CookieAndPrivacyModal;

const Wrapper = styled.div`
  display: flex;
  padding: 5px 35px;
  align-items: center;
  justify-content: space-around;

  .illustration-container {
    width: auto;
    margin-right: 20px;
  }
  .detail-container {
    /* width: 400px; */

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #1c1c1c;
      padding: 0;
      margin-bottom: 20px;
    }

    /* p:last-of-type {
      margin-bottom: 40px;
    } */
  }

  .consent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    p {
      padding: 0;
      margin: 0;
    }
    input {
      margin-right: 10px;
    }
  }
`;
