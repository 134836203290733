// import Status from '../components/TableComponents/Status';
import { TickIcon } from '../assets/SvgComponents/TickIcon';
import CustomAvatar from '../components/TableComponents/CustomAvatar';
// import Select from "react-select";
// import { editTaskOptions, statusOptions } from "./DropDownOptions";
// import { tableStatusStyles } from '../components/styles/StatusStyles';
// import { statusStyles } from "../components/styles/StatusStyles";

// import {
//   HighPriority,
//   MediumPriority,
//   LowPriority,
// } from "../components/TableComponents/Priority";
// import { getValue } from "../components/Tasks";

export const COLUMNS = [
  {
    Header: 'Task',
    accessor: 'title',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
  },
  {
    Header: 'Project',
    accessor: 'project',
    Cell: ({ cell: { value } }) => 'Personal'
  },
  {
    Header: 'Assigned To',
    accessor: 'assignedToDetails.name',
    Cell: ({ cell: { value } }) => <CustomAvatar isTable={true} name={`${value}`} size={21} />
  }
];

export const MOCKCOLUMNS = [
  {
    Header: 'Task',
    accessor: 'title',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
  },
  {
    Header: 'Assigned To',
    accessor: 'assignedToDetails.name',
    Cell: ({ cell: { value } }) => <CustomAvatar isTable={true} name={value} size={21} />
  },
  {
    Header: 'Assignee',
    accessor: 'assigneeDetails.name',
    Cell: ({ cell: { value } }) => <CustomAvatar isTable={true} name={value} size={21} />
  }
];

export const MOCKPRICINGCOLUMS = [
  {
    title: 'Centralized data storage',
    col: <TickIcon />,
    pers: <TickIcon />
  },
  {
    title: 'Automated Task Management Tools',
    col: <TickIcon />,
    pers: <TickIcon />
  },
  {
    title: 'Up-to-date project statuses',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Contract builder technologies',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Project linking',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Collaboration capabilities across different organizations',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Project Meeting Note',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Integrated Reporting Tools',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Project Archiving	',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Visitor Portal and Transmittal Management	',
    col: <TickIcon />,
    pers: ''
  },
  {
    title: 'Customized capabilities based on your unique needs	',
    col: <TickIcon />,
    pers: ''
  }
];
