import styled from '@emotion/styled';
import Lottie from 'react-lottie';
import { useParams } from 'react-router';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import Button from '../../../../components/CustomButton';
import { InputField } from '../../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../../components/Reusable/NewFormik/TextArea1';
import { FieldSet, FormGroup, InputWrapper } from '../../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import useProjectAddress from '../../../../hooks/features/projects/useProjectAddress';
import { TitleRight } from '../../../Library/FilesView/Files/Files';
import { Container } from '../../ProjectViews/CreateProject';
// import { useFormik } from "formik"

export const MultipleFormWrapper = styled.div`
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex: 1;
  }
`;

export const TextAreaWrapper = styled.div`
  ${FormGroup}
  flex-direction: column;
  > * + * {
    //lobotomized owl selector
    margin-top: 10px;
  }
  width: 60vw;
  textarea {
    max-width: 58vw !important;
  }
`;

function ProjectAddress() {
  let { projectId } = useParams();
  const { loading, formik, linkState, isSubmitting } = useProjectAddress();
  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Project Address</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={isSubmitting} type="submit">
              {linkState?.isEdit ? 'Edit Address' : 'Add Address'}
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <FieldSet>
            <div>
              <InputWrapper>
                <InputField
                  label="Name"
                  type="text"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                  disabled={formik.isSubmitting}
                />
              </InputWrapper>
              <MultipleFormWrapper>
                <div className="phone-number">
                  <InputField
                    label="Phone Number"
                    placeholder="xxx-xxx-xxxx"
                    type="text"
                    name="phone"
                    id="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    touched={formik.touched.phone}
                    errors={formik.errors.phone}
                    disabled={formik.isSubmitting}
                  />
                </div>
                <div className="email">
                  <InputField
                    label="Email Id"
                    type="email"
                    name="emailId"
                    id="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emailId}
                    touched={formik.touched.emailId}
                    errors={formik.errors.emailId}
                    disabled={formik.isSubmitting}
                  />
                </div>
              </MultipleFormWrapper>
              <TextAreaWrapper style={{ width: '60vw' }}>
                <TextAreaField
                  label="Address"
                  type="text"
                  name="address"
                  id="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  touched={formik.touched.address}
                  errors={formik.errors.address}
                  disabled={formik.isSubmitting}
                />
              </TextAreaWrapper>
            </div>
          </FieldSet>
        </Container>
      </form>
    </>
  );
}

export default ProjectAddress;
