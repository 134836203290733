import React, { useContext, useMemo } from 'react';
import Table from '../../../components/Table';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import { AlignedDiv, DropdownDiv, NoteListContainer } from './styles';
import { AppContext } from '../../../AppContext';
import useMeetingNotesList from '../../../hooks/features/meetingNotes/useMeetingNotesList';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { useNavigate } from 'react-router';
import { getDate } from '../../../lib/getDate';
import { ErrorPage } from '../../Others';
import styled from '@emotion/styled';

const InternalNotes = () => {
  const { state } = useContext(AppContext);
  const { notes, loading, error } = useMeetingNotesList(state.orgId, false);
  const navigate = useNavigate();

  const Divider = styled.span`
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  `;

  const PublishedSpan = styled.span`
    margin-left: 5px;
    /* font-style: italic; */
    color: #93979f;
    font-size: 15px;
  `;

  const viewMeetingNote = (id: string) => {
    navigate(`/meetingnotes/${id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Note Title',
        accessor: 'title',
        Cell: (props: any) => {
          return (
            <AlignedDiv className="text-wrap" style={{ display: 'flex', alignItems: 'center' }}>
              {/* <NoteIcon style={{ marginRight: '15px' }} /> */}
              {props.row.original.title}
              {!props.row.original.isDraft && <PublishedSpan>[Published]</PublishedSpan>}
            </AlignedDiv>
          );
        }
      },
      {
        Header: 'Project',
        accessor: 'projectName',
        Cell: ({ cell: { value } }) => <AlignedDiv className="text-wrap">{value}</AlignedDiv>
      },
      {
        Header: 'Workspace',
        accessor: 'workspaceName',
        Cell: ({ cell: { value } }) => <AlignedDiv className="text-wrap">{value}</AlignedDiv>
      },
      {
        Header: 'Organization',
        accessor: 'organizationName',
        Cell: ({ cell: { row, value } }) => <AlignedDiv className="text-wrap">{value}</AlignedDiv>
      },
      {
        Header: 'Created On',
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => <AlignedDiv>{getDate(value)}</AlignedDiv>
      },
      {
        Header: 'Updated On',
        accessor: 'updatedAt',
        Cell: ({ cell: { value } }) => <AlignedDiv>{getDate(value)}</AlignedDiv>
      }
    ],
    [notes]
  );

  const data = useMemo(() => notes, [notes]);

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }

  return (
    <NoteListContainer>
      <DropdownDiv>
        <div className="drop-item">
          <TableDropDown state={true} text="Today">
            {<Table columns={columns} data={data?.today} clickHandler={viewMeetingNote} />}
          </TableDropDown>
        </div>
        <div className="drop-item">
          <TableDropDown state={data.week.length > 0 ? true : false} text="This Week">
            {data.week && <Table columns={columns} data={data?.week} clickHandler={viewMeetingNote} />}
          </TableDropDown>
        </div>
        <div className="drop-item">
          <TableDropDown state={data.previous.length > 0 ? true : false} text="Previous Notes">
            {data.previous && <Table columns={columns} data={data?.previous} clickHandler={viewMeetingNote} />}
          </TableDropDown>
        </div>
      </DropdownDiv>
    </NoteListContainer>
  );
};

export default InternalNotes;
