import React from "react";
import styled from "@emotion/styled";

export const StyledTag = styled.span`
  /* border: 1.5px solid #C4C4C4; */
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: 600;
  color: #26303e;
  background-color: #dbdcdd;
  display: inline-block;
  min-width: 3.375rem;
  height: 1.5rem;
  text-align: center;
`;

export default function FileTag(props: any) {
  return <StyledTag>{props?.children?.slice(0, 3)}</StyledTag>;
}
