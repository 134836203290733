import React from 'react';
import { Outlet } from 'react-router';
import { AppStyles } from '../../App';
import { useSidebar } from '../../lib/SidebarState';

import Header from '../Header';
import Sidebar from '../Sidebar';

function DefaultLayout() {
  const { sidebarOpen } = useSidebar();

  return (
    <>
      <Sidebar />
      <Header />
      <AppStyles isOpen={sidebarOpen}>
        <Outlet />
      </AppStyles>
    </>
  );
}

export default DefaultLayout;
