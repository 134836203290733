import { useContext } from 'react';
import styled from '@emotion/styled';
import profilePlaceholder from '../../assets/icons/profilePlaceholder.svg';
import IconOrg from '../../assets/icons/IconOrg.svg';
import IconMail from '../../assets/icons/IconMail.svg';
import IconPhone from '../../assets/icons/IconPhone.svg';
import { LightBlueButton, DarkGreenButton } from '../../components/styles/ButtonStyles';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Auth } from 'aws-amplify';
import { PinnedProjectsContext } from '../../PinnedProjectContext';
// import axiosInstance from '../../axiosInstance';

const ProfileCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  color: #1c1c1c;
  .image {
    position: relative;
    img {
      height: 18vw;
      width: 18vw;
      object-fit: cover;
      border-radius: 10px;
    }
    .options {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      backdrop-filter: blur(10px);
      transition: all 0.5s ease;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        background: #dbe2e0;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        color: #26303e;
        li {
          padding: 5px 10px;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
          & ~ li {
            border-top: solid 1px #999;
          }
        }
      }
    }
    &:hover {
      .options {
        opacity: 1;
      }
    }
  }
  h2 {
    font-size: 36px;
    font-weight: 600;
    margin: 30px 0 15px;
    word-break: break-word;
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 15px;
  }
  .additionInfo {
    .icoList {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        height: 35px;
        width: 35px;
      }
      span {
        line-height: 1;
        font-size: 16px;
        word-break: break-word;
      }
      small {
        font-size: 16px;
        cursor: pointer;
        color: #93979f;
        &:hover {
          color: var(--button-background);
        }
      }
    }
  }
  .logoutButton {
    margin-top: 1.2rem;
  }
`;

const ViewProfile = ({ setOpenProfile }) => {
  const { state, dispatch } = useContext(AppContext);
  const { dispatch: setPinnedProjects } = useContext(PinnedProjectsContext);
  let navigate = useNavigate();
  const createOrganization = () => {
    setOpenProfile(false);
    navigate('/createorganization');
  };
  const viewOrg = () => {
    setOpenProfile(false);
    navigate(`/organization/${state.orgId}`);
  };
  const signOut = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
      setPinnedProjects({
        type: 'RESET'
      });
      dispatch({
        type: 'SIGN_OUT'
      });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };
  const profileData = {
    name: state?.fullName,
    picture: state?.profilePhoto,
    email: state?.email,
    designation: state?.designation,
    phone: state?.phone
  };

  return (
    <ProfileCard>
      <div className="image">
        <img src={profileData.picture ? `${profileData.picture}` : profilePlaceholder} alt="profile" />
      </div>
      <h2>{profileData.name}</h2>
      <div className="additionInfo">
        <p className="icoList">
          <img src={IconMail} alt="mail" />
          <span>{profileData.email ? profileData.email : <small>Add your Email</small>}</span>
        </p>

        {profileData.designation ? (
          <p className="icoList">
            <img src={IconOrg} alt="Title" />
            <span>{profileData.designation}</span>
          </p>
        ) : (
          <span></span>
        )}

        <p className="icoList">
          <img src={IconPhone} alt="phone" />
          <span>{profileData.phone ? profileData.phone : <small>Add your number</small>}</span>
        </p>
      </div>
      <div>
        {state?.orgId === 'null' ? (
          <LightBlueButton type="button" onClick={createOrganization}>
            Create Organization
          </LightBlueButton>
        ) : (
          <LightBlueButton type="button" onClick={viewOrg}>
            View Organization
          </LightBlueButton>
        )}
      </div>
      <div className="logoutButton">
        <DarkGreenButton type="button" onClick={signOut}>
          Logout
        </DarkGreenButton>
      </div>
    </ProfileCard>
  );
};

export default ViewProfile;
