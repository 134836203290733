import styled from '@emotion/styled';
import { AboutText } from '../../pages/Projects/AboutProject/ProjectInformation/ProjectDetailsView';
import { OptionItem, ToggleSettings } from '../OptionsMenu';
import { Label } from '../styles/Label';
import { ReactComponent as AddAddress } from '../../assets/illustration/AddAddress.svg';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { recordTypes } from '../../hooks/features/projects/useProjectAddress';
import {
  OtherContactsResponse,
  ProjectAddressResponse,
  ProjectTeamResponse
} from '../../hooks/features/projects/useGetProject';

export const CardStyles = styled.div`
  padding: 16px;
  border: 1px solid var(--table-border);
  border-radius: 5px;
  flex: 0 32%;
  margin-bottom: 2%;
  margin-left: 10px;
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
      /* padding-top: 15px; */
      /* padding-bottom: 15px; */
      padding: 15px 0px;
    }
    .icon-button {
      visibility: hidden;
    }
  }
  :hover {
    cursor: pointer;
    .icon-button {
      display: block;
      visibility: visible;
    }
  }
`;

export const EmptyDataIllustration = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 100%;
  svg {
    display: block;
  }
  p {
    display: block;
    margin-top: 20px;
  }
  :hover {
    cursor: pointer;
  }
`;

// type ICardDetails = {
//   recordName?: string;
//   id: string;
//   emailId: string;
//   phoneNumber?: string;
//   address: string;
//   contactName?: string;
//   organizationName?: string;
//   designation?: string;
// }

type ICardProps = {
  type: recordTypes;
  details: ProjectAddressResponse | OtherContactsResponse | ProjectTeamResponse;
  handleEdit: (id: string) => void;
  handleHide?: (id: string, type: recordTypes, projectId: string) => void;
};

function Card(props: ICardProps) {
  let navigate = useNavigate();
  let { projectId } = useParams();
  const goToAddressPage = () => {
    navigate(`/project/address/${projectId}`);
  };
  const { id, emailId, phoneNumber, address } = props.details;
  //recordName
  //contactName
  // organizationName
  //designation
  const { recordName, contactName } = props.details as OtherContactsResponse;
  const { organizationName } = props.details as ProjectAddressResponse;
  const { designation } = props.details as ProjectTeamResponse;

  return (
    <>
      <CardStyles>
        {props.isEmpty ? (
          <EmptyDataIllustration onClick={() => goToAddressPage()}>
            <AddAddress />
            <AboutText>Add a Project Address</AboutText>
          </EmptyDataIllustration>
        ) : (
          <>
            <div className="card-header">
              {props.type !== 'team' && <Label>{props.type === 'address' ? recordName : contactName}</Label>}
              {props.type === 'team' && <Label>{organizationName}</Label>}
              <ToggleSettings>
                <OptionItem onClick={() => props.handleEdit(id)}>Edit Entry</OptionItem>
                <OptionItem onClick={() => props.handleHide && props.handleHide(id, props.type, projectId)}>
                  Hide Entry
                </OptionItem>
              </ToggleSettings>
            </div>
            {props.type === 'contact' && <AboutText>{recordName}</AboutText>}
            {props.type === 'team' && <AboutText> {designation}</AboutText>}
            <AboutText>
              {phoneNumber && <span>{phoneNumber}</span>}
              <span>{emailId}</span>
              <span className="address-details">{address}</span>
            </AboutText>
          </>
        )}
      </CardStyles>
    </>
  );
}

export default Card;
