import styled from '@emotion/styled';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import { ReactComponent as StartOfChatIllustration } from '../../../assets/illustration/StartOfChat.svg';
import { fetchMessages } from '../../../reducers/conversations_new';
import { RootState } from '../../../store';
import Loader from '../../Loader';
import { getConversationStatus } from '../GetConversationStatus';
import { Member, Messages } from '../types';
import ChatBody from './ChatBody/ChatBody';
import Header from './Header/Header';
import MessageInput from './MessageInput/MessageInput';

type Props = {};

const ChatArea = ({}: Props) => {
  const [messages, setMessages] = useState<Messages>({});
  const [members, setMembers] = useState<Member[]>([]);
  const [conversationType, setConversationType] = useState('');
  const [conversationOwner, setConversationOwner] = useState('');
  const [scroll, setScroll] = useState(true);

  const {
    selectedConversationId,
    allMessages,
    currentMessagesLoading,
    currentConversationDetails,
    orgData,
    nonOrgData
  } = useSelector((state: RootState) => state.conversations);
  const {
    state: { userId }
  } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedConversationId) return;
    const allOrg = { ...orgData, ...nonOrgData };

    setConversationType(allOrg[selectedConversationId][0].conversationType);
    setConversationOwner(allOrg[selectedConversationId][0].createdBy);

    if (allMessages.hasOwnProperty(selectedConversationId)) {
      setMessages(allMessages[selectedConversationId].messages);
      setMembers(allMessages[selectedConversationId].members);
    } else {
      const conversationType = allOrg[selectedConversationId][0].conversationType;
      let timeLine = undefined;
      if (conversationType === 'group') timeLine = allOrg[selectedConversationId][0]?.timeLine;
      dispatch(fetchMessages({ conversationId: selectedConversationId, conversationType: 'group', timeLine }));
    }
  }, [selectedConversationId, allMessages, nonOrgData, orgData, currentConversationDetails]);

  return (
    <ChatContainer loading={currentMessagesLoading ? 1 : 0}>
      {!selectedConversationId || currentMessagesLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            name={currentConversationDetails?.name!}
            profilePic={currentConversationDetails?.profilePic}
            conversationType={conversationType}
            members={members}
            conversationOwner={conversationOwner}
          />
          <div className="chat-body">
            {Object.keys(messages).length === 0 ? (
              <IllustrationContainer>
                <StartOfChatIllustration />
                <p>Your interactions with {currentConversationDetails?.name} will appear here.</p>
              </IllustrationContainer>
            ) : (
              <div className="messages">
                <ChatBody
                  messages={messages}
                  conversationId={selectedConversationId}
                  userId={userId}
                  currentConversationDetails={currentConversationDetails!}
                  lastKey={allMessages[selectedConversationId]?.lastKey}
                  isLoadingMore={allMessages[selectedConversationId]?.isLoadingMore}
                  members={members}
                  conversationType={conversationType}
                  scroll={scroll}
                  setScroll={setScroll}
                />
              </div>
            )}
            {getConversationStatus(currentConversationDetails?.type!, currentConversationDetails?.recordStatus!) || (
              <MessageInput setScroll={setScroll} conversationId={selectedConversationId!} />
            )}
          </div>
        </>
      )}
    </ChatContainer>
  );
};

export default ChatArea;

const ChatContainer = styled.div<{ loading: any }>`
  width: 100%;
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;

  .chat-body {
    background-color: ${(props) => (props.loading ? '#ffffff' : '#f6f5f5')};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;

    .messages {
      flex: 1;
      width: 100%;
      flex-direction: column;
      overflow-y: hidden; /* Hide vertical scrollbar */
      display: flex;
      overflow-anchor: none;
    }

    .input-div {
      height: auto;
    }
  }
`;

const IllustrationContainer = styled.div`
  /* height: 80%; */
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #1c1c1c;
    opacity: 0.5;
  }
`;
