//not used to be deleted.

import { useMemo, useEffect, useContext } from 'react';
import { Header, PageTitle, TitleLeft, TitleRight } from '../../../components/styles/Header';
import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import { ListOfRegisterColumns } from '../../../constants/ViewWorkspaceRegisterColumn';
import Table from '../../../components/Table';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import { listRegistersQuery } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import { ReactComponent as NoRegistersIcon } from '../../../assets/icons/NoRegistersIcon.svg';
import { EmptyRegisterStyles } from '../WorkspaceViews/CreateNewWorkspace';
import { AppContext } from '../../../AppContext';

// import { Container } from '../../Projects/ProjectViews/CreateProject';
// import { PageNavStyles } from "../../Library/FilesView/ViewFile";
// import WorkspaceRegisterTable from "../../../components/WorkspaceRegisterTable";
const RegisterList = styled.div`
  padding-top: 25px;
  margin: 20px;
  margin-right: 55px;
  table {
    width: 80vw;
  }
  thead {
    th:first-of-type {
      /* border: 1px solid red; */
      padding-left: 55px;
    }
  }
  tr {
    height: 22px;
    td {
      font-weight: 600 !important;
    }
    /* td:first-of-type {
      border: 1px solid red;
      width: 10px !important;
    } */
  }

  .icon {
    border: 1px solid red;
  }
  .count {
    padding-left: 32px;
    /* border: 1px solid red; */
  }
  .divider {
    display: block;
    margin-right: 40px;
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
`;

const ViewWorkspaceRegister = () => {
  let navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const {
    state: { orgId }
  } = useContext(AppContext);

  const {
    data: registerData,
    error,
    loading,
    refetch
  } = useQuery(listRegistersQuery, {
    variables: {
      workspaceId: workspaceId,
      organizationId: orgId
    }
  });

  const columns = useMemo(() => ListOfRegisterColumns, []);
  const dynamicRegisterData = useMemo(
    () => registerData?.list_RegisterItems?._RegisterItems.filter((register) => register.registerType === 'dynamic'),
    [registerData?.list_RegisterItems?._RegisterItems]
  );
  const staticRegisterData = useMemo(
    () => registerData?.list_RegisterItems?._RegisterItems.filter((register) => register.registerType === 'static'),
    [registerData]
  );
  useEffect(() => {
    refetch();
  }, []);
  const viewRegister = (id: string) => {
    let registerId = id;
    navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/documentlist`);
  };
  const editRegisters = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/editregisterlist`);
  };
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Workspace Registers</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={editRegisters}>Add/Remove Registers</Button>
        </TitleRight>
      </Header>
      {/* <Container> */}
      <RegisterList>
        <TableDropDown state={true} text="Action Item Registers">
          {dynamicRegisterData.length > 0 ? (
            <Table columns={columns} clickHandler={viewRegister} data={dynamicRegisterData} />
          ) : (
            <EmptyRegisterStyles>
              <NoRegistersIcon />
            </EmptyRegisterStyles>
          )}
        </TableDropDown>
      </RegisterList>
      <RegisterList>
        <TableDropDown state={true} text="Record Item Registers">
          {staticRegisterData.length > 0 ? (
            <Table columns={columns} clickHandler={viewRegister} data={staticRegisterData} />
          ) : (
            <EmptyRegisterStyles>
              <NoRegistersIcon />
            </EmptyRegisterStyles>
          )}
        </TableDropDown>
      </RegisterList>

      {/* </Container> */}
    </>
  );
};

export default ViewWorkspaceRegister;
