import { useContext, useState } from 'react';
import styled from '@emotion/styled';
import Button, { CustomButton } from '../../../../components/CustomButton';
import { PageNavStyles } from '../../../Library/FilesView/Files/ViewFile/ViewFile';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import { useToggle } from '../../../../lib/UseToggle';
import Modal from '../../../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../../components/styles/ModalStyles';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { AppContext } from '../../../../AppContext';
import { ErrorPage } from '../../../Others/ErrorPage';
import { VIEW_SINGLE_TASK_QUERY } from '../../../../graphql/operations/Queries/Tasks/TaskQueries';
import {
  DELETE_TASK_MUTATION,
  UPDATE_ACKNOWLEDGED_TASK_MUTATION
} from '../../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { toast } from 'react-hot-toast';
import { getWorkspaceQuery } from '../../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { getProjectQuery } from '../../../../graphql/operations/Queries/Projects/ProjectQueries';
import NavigationLink from '../../../../components/NavigationLink';
import Loader from '../../../../components/Loader';
// import axiosInstance from '../../../../axiosInstance';
// import { Modal } from './Modal'
// const TitleLeft = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-left: 4.6rem;
// `;
const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.18rem;
  gap: 19px;
`;
const Text = styled.p`
  font-weight: 600;
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;

const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;

const ArchiveButton = styled(Button)`
  background-color: #d14747;
  :hover {
    background-color: #df0f0f;
  }
`;

const ViewTask = () => {
  let { taskId, projectId } = useParams();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);
  const [viewLoading, setViewLoading] = useState<boolean>(false);
  const [isOpen, toggle] = useToggle(false);
  const { state } = useContext(AppContext);
  // const [getTasks] = useLazyQuery(LIST_TASKS_QUERY, {});
  const { data, error, loading } = useQuery(VIEW_SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    onCompleted: (data) => {
      setIsAcknowledged(data?.get_Task?.isAcknowledged);
    },
    fetchPolicy: 'network-only'
  });
  const { data: workspaceData } = useQuery(getWorkspaceQuery, {
    variables: { id: data?.get_Task?.typeId },
    skip: !data?.get_Task.typeId || data?.get_Task.type !== 'workspace',
    fetchPolicy: 'network-only'
  });
  const { data: projectData } = useQuery(getProjectQuery, {
    variables: { id: data?.get_Task?.projectId },
    skip: !data?.get_Task.projectId || data?.get_Task.projectId === 'NOPROJECT',
    fetchPolicy: 'network-only'
  });

  let deleteTaskVariables = { id: taskId, type: data?.get_Task?.type, typeId: data?.get_Task?.typeId };
  const [deleteTask, { error: deleteError }] = useMutation(DELETE_TASK_MUTATION, {
    variables: deleteTaskVariables,
    onCompleted: async (data) => {
      // getData();
    }
  });

  const handleNavigate = () => {
    if (projectId) {
      navigate(`/project/${projectId}/tasks`);
    } else {
      navigate('/tasks');
    }
  };

  async function taskDelete() {
    setIsLoading(true);
    let toastId = toast.loading('Archiving Task');
    if (state?.userId !== data?.get_Task?.assignedTo) {
      deleteTaskVariables.notificationData = {
        actionType: 'delete',
        userToNotify:
          state?.userId !== data?.get_Task?.assignee ? data?.get_Task?.assignee : data?.get_Task?.assignedTo,
        organizationId: state.orgId,
        workspaceName: workspaceData?.get_Workspace?.name,
        projectName: projectData?.get_Project.name,
        projectId: data?.get_Task?.projectId,
        workspaceId: data?.get_Task?.typeId,
        host: window.location.origin,
        taskName: data?.get_Task?.title,
        dueDate: data?.get_Task?.dueDate
      };
    }
    try {
      let response = await deleteTask();
      if (response) {
        toast.success('Task Archived.');
        toast.dismiss(toastId);
        navigate(`/tasks`);
      }
    } catch (error) {
      console.error('Error in archiving/deleting task', error);
      toast.dismiss(toastId);
      toast.error('Error in archiving task.');
    }
  }
  const [updateTask] = useMutation(UPDATE_ACKNOWLEDGED_TASK_MUTATION);

  // async function getData() {
  //   setTimeout(async () => {
  //     await getTasks();
  //     window.location.href = '/';
  //   }, 2000);
  // }

  const editTask = () => {
    navigate(`edit`);
  };
  const acknowledgeTask = async (taskId: string) => {
    setViewLoading(true);
    const toastId = toast.loading('Acknowleding the task');
    let variables = {
      id: taskId,
      isAcknowledged: true,
      assignedTo: data?.get_Task?.assignedTo
    };
    try {
      let response = await updateTask({
        variables: variables
      });
      if (response) {
        setIsAcknowledged(true);
        setViewLoading(false);
        toast.success('Acknowledged', { id: toastId });
      }
    } catch (error) {
      toast.dismiss(toastId);
      setIsLoading(false);
      toast.error('Something went wrong.');
      console.error('Error in acknowledging task', error);
    }
  };
  if (loading || viewLoading) return <Loader />;
  if (error || deleteError) return <ErrorPage />;
  const { get_Task } = data;

  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>{get_Task.title}</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>

        {get_Task.assignedTo === state.userId && get_Task.typeId !== state.userId ? (
          isAcknowledged ? (
            <TitleRight>
              <Button onClick={editTask} secondary={true}>
                Edit Task
              </Button>
              <ArchiveButton onClick={() => toggle()}>Archive</ArchiveButton>
            </TitleRight>
          ) : (
            <TitleRight>
              <Button onClick={() => acknowledgeTask(get_Task._id)} secondary={true}>
                Acknowledge Task
              </Button>
            </TitleRight>
          )
        ) : (
          <TitleRight>
            <Button onClick={editTask} secondary={true}>
              Edit Task
            </Button>
            <ArchiveButton onClick={() => toggle()}>Archive</ArchiveButton>
          </TitleRight>
        )}
      </Header>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Archive Task</ModalTitle>
          <ModalBody>
            <ModalText>Do you want to Archive this task?</ModalText>
          </ModalBody>
          <ButtonWrapper>
            <Button disabled={isLoading} onClick={toggle} secondary>
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={() => taskDelete()}>
              Confirm
            </Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
      <PageNavStyles>
        <NavigationLink end to={projectId ? `/project/${projectId}/tasks/${taskId}` : `/dashboard/tasks/${taskId}`}>
          About
        </NavigationLink>
        {/* <NavigationLink to="discussions">Discussions</NavigationLink> */}
        <NavigationLink to="associated">Associated Task</NavigationLink>
        <NavigationLink to="note">Task Note</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
};

export default ViewTask;
