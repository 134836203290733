import React from "react";
import styled from "@emotion/styled";

const Dot = styled.div`
  background: var(--button-background);
  color: white;
  min-width: 21px;
  min-height: 21px;
  width: auto;
  height: auto;
  border-radius: 50%;
  font-size: 11px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  line-height: 0.8rem;
  top: 0;
  right: 0;
`;
const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IconProps {
  icon?: React.ReactNode;
  count: number;
}

const CustomSvgIcon = (props: IconProps) => {
  return (
    <>
      <IconContainer>
        {props.icon}
        {props.count > 0 ? <Dot>{props.count}</Dot> : ""}
      </IconContainer>
    </>
  );
};

export default CustomSvgIcon;
