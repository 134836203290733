export const NOTEBOOK_OPTIONS = [
  { value: 'all', label: 'All Notes' },
  { value: 'personal', label: 'Personal Notes' },
  { value: 'project', label: 'Project Notes' }
];

export const PROJECT_NOTES_SEARCH_OPTIONS = [
  { value: 'title', label: 'Title' },
  { value: 'author', label: 'Author' }
  // { value: 'project', label: 'Project' }
];
