import styled from '@emotion/styled';
import { useContext } from 'react';
import Lottie from 'react-lottie';
import { useParams } from 'react-router';
import { AppContext } from '../../../../AppContext';
import Card from '../../../../components/Projects/Card';
import EmptyCard from '../../../../components/Projects/EmptyCard';
import { DarkGreenButton } from '../../../../components/styles/ButtonStyles';
import { AssigneeSeparator, AvatarStyles, SameLineWrapper } from '../../../../components/styles/FormStyles';
import { Label } from '../../../../components/styles/Label';
import { Small } from '../../../../components/styles/SmallText';
import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import TableDropDown from '../../../../components/TableComponents/TableDropDown';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ProjectAddressResponse, useGetProject } from '../../../../hooks/features/projects/useGetProject';
import { recordTypes } from '../../../../hooks/features/projects/useProjectAddress';
import { getFullNameById } from '../../../../lib/getFullName';
import { ErrorPage } from '../../../Others/ErrorPage';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  margin-right: 1.625rem;
  margin-top: 2rem;
  .address {
    margin-top: 27px;
  }
  .address-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    button {
      margin-right: 2.6rem;
    }
  }
`;

export const InsideDropDownWrapper = styled.div`
  padding-left: 2.5rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  > * + * {
    margin-top: 10px;
  }
`;

export const AboutText = styled.p`
  font-weight: 400;
  span {
    display: block;
  }

  .address-details {
    margin-top: 10px;
  }
`;
export const CreatedByWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  > * + * {
    align-self: flex-start;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  /* justify-content: space-around; */
  /* margin-top: 25px; */
  flex-wrap: wrap;
  margin-right: 2rem;
`;

function ProjectDetailsView() {
  const { state } = useContext(AppContext);
  const { projectId } = useParams();
  const orgUsers = useSelector((state) => state.orgMembers.users);
  let orgId = state.orgId;
  const { loading, error, projectData, hideProjectCard, goToOtherContacts, goToAddTeams, goToAddressPage } =
    useGetProject(projectId, orgId);

  // TODO: remove if not required
  const handleCardHide = (id: string, addressData: ProjectAddressResponse) => {};

  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  if (error) {
    return <ErrorPage />;
  }
  const { projectName, description, createdBy, createdAt, projectAddress, projectCode, otherContacts, team } =
    projectData;
  return (
    <>
      <Container>
        <TableDropDown state={true} text="Project Details">
          <InsideDropDownWrapper>
            <SameLineWrapper>
              <Label>Project Code:</Label>
              <AboutText>{projectCode}</AboutText>
            </SameLineWrapper>
            <TextWrapper>
              <Label>Project Name</Label>
              <AboutText>{projectName}</AboutText>
            </TextWrapper>
            <TextWrapper>
              <Label>Description</Label>
              <AboutText>{description}</AboutText>
            </TextWrapper>
            <CreatedByWrapper>
              <Label>Created By</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar isTable={false} name={getFullNameById(createdBy, orgUsers, state.fullName)} size={21} />
                </AvatarStyles>
                <Small>{new Date(createdAt).toLocaleDateString('en-US')} </Small>
              </AssigneeSeparator>
            </CreatedByWrapper>
            <div className="address-wrapper">
              <Label>Project Address</Label>
              <DarkGreenButton onClick={() => goToAddressPage()}>+ Add Address</DarkGreenButton>
            </div>
            <div className="address">
              <CardContainer>
                {projectAddress?.length > 0 &&
                  projectAddress.map((address) => {
                    return (
                      address.recordStatus === 0 && (
                        <Card
                          details={address}
                          key={address.id}
                          type={recordTypes.ADDRESS}
                          handleEdit={goToAddressPage}
                          handleHide={hideProjectCard}
                        />
                      )
                    );
                  })}
                {projectAddress?.length === 0 && <EmptyCard type="address" handleClick={goToAddressPage} />}
              </CardContainer>
            </div>
          </InsideDropDownWrapper>
        </TableDropDown>
        <TableDropDown state={false} text="Other Contacts" buttonText="+ Contacts" handleClick={goToOtherContacts}>
          <InsideDropDownWrapper>
            <CardContainer>
              {otherContacts?.length > 0 &&
                otherContacts.map((contact) => {
                  return (
                    contact.recordStatus === 0 && (
                      <Card
                        details={contact}
                        key={contact.id}
                        type={recordTypes.CONTACT}
                        handleEdit={goToOtherContacts}
                        handleHide={hideProjectCard}
                      />
                    )
                  );
                })}
              {otherContacts?.length === 0 && <EmptyCard type="contact" handleClick={goToOtherContacts} />}
            </CardContainer>
          </InsideDropDownWrapper>
        </TableDropDown>
        <TableDropDown state={false} text="Team" buttonText="+ Add Team" handleClick={goToAddTeams}>
          <InsideDropDownWrapper>
            <CardContainer>
              {team?.length > 0 &&
                team.map((team) => {
                  return (
                    team.recordStatus === 0 && (
                      <Card
                        details={team}
                        key={team.id}
                        type={recordTypes.TEAM}
                        handleEdit={goToAddTeams}
                        handleHide={hideProjectCard}
                      />
                    )
                  );
                })}
              {team?.length === 0 && <EmptyCard type="team" handleClick={goToAddTeams} />}
            </CardContainer>
          </InsideDropDownWrapper>
        </TableDropDown>
      </Container>
    </>
  );
}

export default ProjectDetailsView;
