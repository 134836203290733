import styled from '@emotion/styled';
import VisaLogo from '../assets/CardLogos/Visa_Brandmark_White_RGB_2021.png';
import MasterCardLogo from '../assets/CardLogos/MasterCard.svg';
import AmericanExpressLogo from '../assets/CardLogos/Amex_logo_color.svg';
import DiscoverLogo from '../assets/CardLogos/Discover.png';

type BrandProps = {
  brand?: 'visa' | 'amex' | 'mastercard' | 'discover';
};

const CardLogo = styled.img<BrandProps>`
  max-height: 31px;
  max-width: 31px;
  padding: 5px;
  display: inline-block;
  background-color: ${(props) => (props.brand === 'visa' ? 'hsl(212,56%,40%);' : '')};
`;

export const getBrandLogo = (brand: BrandProps) => {
  if (!brand) {
    return null;
  } else {
    switch (brand) {
      case 'visa':
        return <CardLogo brand="visa" src={VisaLogo} alt="visa" />;
      case 'mastercard':
        return <CardLogo src={MasterCardLogo} alt="masterCard" />;
      case 'amex':
        return <CardLogo src={AmericanExpressLogo} alt="AmericanExpress" />;
      case 'discover':
        return <CardLogo src={DiscoverLogo} alt="discoverLogo" />;
      default:
        return null;
    }
  }
};
