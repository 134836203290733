import styled from '@emotion/styled';

export const Text = styled.p`
  font-weight: 600;
`;

export const ErrorText = styled.p`
  /* margin-left: 1rem; */
  color: var(--high-priority);
`;

export const DropdownErrorText = styled.p`
  margin-left: 1rem;
  color: var(--high-priority);
`;
