import React from "react";
import Avatar from "react-avatar";
import styled from "@emotion/styled";

const NameTag = styled.div`
  display: flex;
  background: #e1eef2;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 4px 10px;
  /* justify-content: flex-start; */
  /* align-items: baseline; */
  /* width: 25rem; */
  /* padding-left: 1.9rem; */

  .name {
    margin-left: 9px;
  }
  /* opacity: 85%; */
`;

const AvatarTags = ({ name }: { name: string }) => {
  return (
    <>
      <NameTag>
        <Avatar round={true} size="21" name={name} textSizeRatio={2} />
        <span className="name">{name}</span>
      </NameTag>
    </>
  );
};

export default AvatarTags;
