import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { ListItemStyles } from '../../../../components/ListItem';
import { GET_SINGLE_FILE_REGISTER_QUERY } from '../../../../graphql/operations/Queries/Registers/RegisterQueries';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/LinkIcon.svg';
import { DateStyle, Time } from '../../../../components/ProjectList';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { NoDocuments } from '../RegisterItemsPage';
// import { Container } from "../../../Projects/CreateProject";
const LinkDetails = styled.div`
  display: flex;
  align-items: center;
`;

const LinkDate = styled.div`
  color: var(--text-light);
  display: flex;
  align-items: center;
  padding-right: 7.6rem;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  margin-right: 4rem;
  margin-top: 0.9rem;
`;

const LinkText = styled.p`
  font-weight: 600;
  max-width: 166px;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 2.01rem;
`;

function LinkedFilesOfRegisterDocument() {
  // let navigate = useNavigate();
  let { fileId } = useParams();
  const { data, error, loading } = useQuery(GET_SINGLE_FILE_REGISTER_QUERY, {
    variables: { id: fileId }
  });
  const goToLink = (link: string) => {
    window.location.href = link;
  };
  if (error) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <LinksContainer>
      {data?.get_File?.links?.length > 0 ? (
        data.get_File.links.map((link, index) => {
          return (
            <ListItemStyles key={index} onClick={() => goToLink(link)}>
              <LinkDetails>
                <LinkIcon />
                <LinkText>{link}</LinkText>
              </LinkDetails>
              <LinkDate>
                <DateStyle>
                  {new Date(data.get_File?.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </DateStyle>
                <Time>
                  {new Date(data.get_File?.createdAt).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </Time>
              </LinkDate>
            </ListItemStyles>
          );
        })
      ) : (
        <NoDocuments>No links created in document</NoDocuments>
      )}
    </LinksContainer>
  );
}

export default LinkedFilesOfRegisterDocument;
