// import { Container } from 'aws-amplify-react'
import Lottie from 'react-lottie';
import Select from 'react-select';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import Button from '../../../../components/CustomButton';
import { InputField } from '../../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../../components/Reusable/NewFormik/TextArea1';
import { ErrorText } from '../../../../components/Reusable/NewFormik/TextError';
import { ErrorWrapper, FieldSet, InputWrapper, SameLineWrapper } from '../../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import { Label } from '../../../../components/styles/Label';
import { taskDropdownStyles } from '../../../../components/styles/TaskDropdownStyles';
import { designationOptions } from '../../../../constants/DesignationOptions';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { useOtherContacts } from '../../../../hooks/features/projects/useOtherContacts';
import { TitleRight } from '../../../Library/FilesView/Files/Files';
import { Container } from '../../ProjectViews/CreateProject';
import { MultipleFormWrapper, TextAreaWrapper } from './ProjectAddress';

function OtherContacts() {
  const { formik, loading, linkState, isSubmitting } = useOtherContacts();
  const designationOption = designationOptions?.find((option) => option.value === formik.values.designation);
  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Other Contacts</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={isSubmitting} type="submit">
              {linkState?.isEdit ? 'Edit Contact' : 'Add Contact'}
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <FieldSet>
            <div>
              <InputWrapper>
                <InputField
                  label="Name"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                  disabled={isSubmitting}
                />
              </InputWrapper>
              <SameLineWrapper style={{ marginLeft: '15px' }}>
                <Label>Designation</Label>
                <Select
                  name="designation"
                  styles={taskDropdownStyles}
                  style={{ width: '100px !important' }}
                  menuPortalTarget={document.body}
                  value={designationOption}
                  options={designationOptions}
                  onChange={(option) => {
                    formik.setFieldValue('designation', option?.value);
                  }}
                  disabled={isSubmitting}
                />
              </SameLineWrapper>
              <ErrorWrapper>
                {formik.errors['designation'] && <ErrorText>{formik.errors.designation}</ErrorText>}
              </ErrorWrapper>
              <MultipleFormWrapper>
                <div className="phone-number">
                  <InputField
                    label="Phone Number"
                    placeholder="xxx-xxx-xxxx"
                    type="text"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    touched={formik.touched.phone}
                    errors={formik.errors.phone}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="email">
                  <InputField
                    label="Email Id"
                    type="email"
                    name="emailId"
                    onChange={formik.handleChange}
                    value={formik.values.emailId}
                    touched={formik.touched.emailId}
                    errors={formik.errors.emailId}
                    disabled={isSubmitting}
                  />
                </div>
              </MultipleFormWrapper>
              <TextAreaWrapper style={{ width: '60vw' }}>
                <TextAreaField
                  label="Address"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  touched={formik.touched.address}
                  errors={formik.errors.address}
                  disabled={isSubmitting}
                />
              </TextAreaWrapper>
            </div>
          </FieldSet>
        </Container>
      </form>
    </>
  );
}

export default OtherContacts;
