import { gql } from '@apollo/client';
export const addTaskInProjectNotesMutation = gql`
  mutation addTaskMutation(
    $assignee: String
    $assigneeDetails: Self_Task_assigneeDetails_Input_
    $createdAt: String
    $priority: String
    $projectId: String
    $organizationId_projectId_typeId: String
    $organizationId: String
    $recordStatus: Float
    $status: String
    $title: String
    $noteId: String
    $links: [String]
    $updatedAt: String
    $updatedBy: String
    $type: String
    $typeId: String
  ) @api(name: "task") {
    add_Task(
      syncMode: NODE_COMMITTED
      input: {
        assignee: $assignee
        assigneeDetails: $assigneeDetails
        createdAt: $createdAt
        priority: $priority
        projectId: $projectId
        organizationId_projectId_typeId: $organizationId_projectId_typeId
        organizationId: $organizationId
        recordStatus: $recordStatus
        status: $status
        title: $title
        noteId: $noteId
        updatedAt: $updatedAt
        updatedBy: $updatedBy
        links: $links
        type: $type
        typeId: $typeId
      }
    ) {
      result {
        _id
      }
    }
  }
`;
