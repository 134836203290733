import styled from '@emotion/styled';
import React from 'react';

const EmptyMessageStyles = styled.div`
  /* position: relative; */
  margin-left: 45px;
  margin-right: 45px;
  .quickbar-section {
    background: #f6f5f5;
    border-radius: 5px;
    width: 100%;
    height: 7.06rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* opacity: 30%; */
    margin-top: 1.5rem;
  }
  .title {
    opacity: 30%;
  }
`;
function NoAwaitingUsers() {
  return (
    <EmptyMessageStyles>
      <div className="quickbar-section">
        <span className="title">No users awaiting for seat.</span>
      </div>
    </EmptyMessageStyles>
  );
}

export default NoAwaitingUsers;
