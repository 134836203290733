import styled from '@emotion/styled';
import { useContext, useMemo } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import { ReactComponent as NoNotesIllustration } from '../../../assets/illustration/noNotes.svg';
import Button from '../../../components/CustomButton';
import { Header, PageTitle, TitleLeft } from '../../../components/styles/Header';
import Table from '../../../components/Table';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { MockMeetingNoteColumns } from '../../../constants/MockMeetingNotesData';
import useMeetingNotesList from '../../../hooks/features/meetingNotes/useMeetingNotesList';
import { useToggle } from '../../../lib/UseToggle';
import { ErrorPage } from '../../Others';

import SelectMeetingNoteTemplateModal from '../../../components/Modals/MeetingNotes/SelectMeetingNoteTemplateModal';
import { TitleRight } from '../Tasks/CreateProjectWorkspaceTask';

const MeetingNotesList = styled.div`
  width: 90vw;
  margin: 0 auto;
  padding: 15px;
`;

function WorkspaceMeetingNotesPage() {
  const [isTemplateModalOpen, toggleTemplateModal] = useToggle();
  const { state } = useContext(AppContext);
  const { projectId, workspaceId } = useParams();
  const { notes, loading, error } = useMeetingNotesList(state.orgId, false, workspaceId, projectId);
  const allNotes = useMemo(() => [...notes.today, ...notes.week, ...notes.previous], [notes]);
  allNotes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  const columns = useMemo(() => MockMeetingNoteColumns, []);
  const data = useMemo(() => allNotes, [allNotes]);
  const navigate = useNavigate();

  if (error) {
    return <ErrorPage />;
  }

  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }

  const viewMeetingNote = (id: string) => {
    navigate(`/meetingnotes/${id}`);
  };

  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Meeting Notes</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={toggleTemplateModal}>Add New Meeting Note</Button>
        </TitleRight>
      </Header>
      {allNotes.length !== 0 ? (
        <MeetingNotesList>
          <Table columns={columns} data={data} clickHandler={viewMeetingNote} />
        </MeetingNotesList>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            flexDirection: 'column'
          }}
        >
          <NoNotesIllustration />
        </div>
      )}
      <SelectMeetingNoteTemplateModal
        isOpen={isTemplateModalOpen}
        toggleModal={toggleTemplateModal}
        selectedProjectId={projectId!}
        selectedWorkspaceId={workspaceId!}
      />
    </>
  );
}

export default WorkspaceMeetingNotesPage;
