import { gql } from '@apollo/client';

export const createOrganizationMutation = gql`
  mutation MyMutation(
    $createdAt: String
    $createdBy: String
    $name: String
    $photo: String
    $recordStatus: Float
    $updatedAt: String
    $updatedBy: String
    $fileType: String
    $numberOfSeats: Float
    $paymentStatus: String
    $paymentUserId: String
    $stripeCustomerId: String
    $subscriptionId: String
    $subscriptionType: String
    $subscriptionEndDate: String
  ) @api(name: "organization") {
    add_Organization(
      syncMode: NODE_COMMITTED
      input: {
        createdAt: $createdAt
        createdBy: $createdBy
        name: $name
        photo: $photo
        recordStatus: $recordStatus
        updatedAt: $updatedAt
        updatedBy: $updatedBy
        fileType: $fileType
        numberOfSeats: $numberOfSeats
        paymentStatus: $paymentStatus
        paymentUserId: $paymentUserId
        stripeCustomerId: $stripeCustomerId
        subscriptionId: $subscriptionId
        subscriptionType: $subscriptionType
        subscriptionEndDate: $subscriptionEndDate
      }
    ) {
      result {
        _id
      }
    }
  }
`;

export const updateOrganizationMutation = gql`
  mutation updateOrganizationMutation(
    $id: ID!
    $name: String
    $updatedAt: String
    $updatedBy: String
    $photo: String
    $fileType: String
    $subscriptionType: String
  ) @api(name: "organization") {
    update_Organization(
      id: $id
      syncMode: NODE_COMMITTED
      input: {
        name: $name
        updatedBy: $updatedBy
        updatedAt: $updatedAt
        fileType: $fileType
        photo: $photo
        subscriptionType: $subscriptionType
      }
    ) {
      result {
        _id
      }
    }
  }
`;
