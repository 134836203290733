import { Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { sanitize } from 'dompurify';

/**
 * TipTap imports
 */
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { BubbleMenu, Editor, EditorContent, useEditor } from '@tiptap/react';

/**
 *Custom Extensions
 */
import BlockIdComponent from '../CustomComponents/blockIdComponent/Extension';
import FileComponent from '../CustomComponents/FileUploadComponent/Extension';
// import ReactComponent from './CustomComponents/blockIdComponent/Extension';
/**
 * Icons
 */
import { ReactComponent as NewBlockIcon } from '../../../assets/icons/editor-icons/icon-plus.svg';
import { ReactComponent as NewSectionIcon } from '../../../assets/icons/editor-icons/new-section.svg';

import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../AppContext';
import useTextEditor from '../../../hooks/features/editor/useTextEditor';
import { getDate } from '../../../lib/getDate';
import { useToggle } from '../../../lib/UseToggle';
import { BlockDataI, EditingTable, EditorI, MeetingNoteI } from '../../../pages/MeetingNotes/types';
import { addBlock, addSection, deleteBlock, updateLastUpdateDate } from '../../../reducers/meetingNote';
import { addSection as addSectionToProjectNote, ProjectNoteI } from '../../../reducers/projectNote';
import { addSection as addSectionToTaskNote, TaskNoteI } from '../../../reducers/taskNote';
import EditorDeleteContentModal from '../../Modals/EditorDeleteContentModal';
import { OptionItem, ToggleSettings } from '../../OptionsMenu';
import { BlockId, DateContainer, EditorStyles, EditStatus, Footer, ToggleButton } from '../styles/EditorStyles';
interface Props {
  sectionId: string;
  block: BlockDataI;
  isLast: boolean;
  handleCreateTask: (title: string, blockId: string) => void;
  editable?: boolean;
  setCurrentEditor: Dispatch<Editor | null>;
  editorType: 'MEETING_NOTE' | 'PROJECT_NOTE' | 'TASK_NOTE';
  editingTable: EditingTable;
  noteData: MeetingNoteI | ProjectNoteI | TaskNoteI;
  editing: Boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  editedBy: MutableRefObject<EditorI | undefined>;
  sectionNumber: number;
  blockNumber: number;
  numberOfBlocks: number;
  isEditable: boolean;
  setIsEditable: (editable: boolean) => void;
}

const SectionBody = ({
  sectionId,
  block,
  isLast,
  handleCreateTask,
  editable = true,
  setCurrentEditor,
  editorType,
  editingTable,
  noteData,
  editing,
  setEditing,
  editedBy,
  sectionNumber,
  blockNumber,
  numberOfBlocks,
  isEditable,
  setIsEditable
}: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [lastEdited, setLastEdited] = useState<string>(getDate(block.blockDate));

  /**
   * Only for meeting notes
   * Other notes will have only one block
   * This variable is used to track which block is being currently edited
   */
  const [blockEditing, setBlockEditing] = useState<boolean>(false);

  //Delete block confirmation modal
  const [isOpen, toggleModal] = useToggle();

  const dispatch = useDispatch();

  const timer = useRef<any>(null);

  // const [editing, setEditing] = useState(false);
  const {
    state: { userId }
  } = useContext(AppContext);

  const { handleUpdateContents, handleAddEditStatus, handleRemoveEditStatus, handleAutoSave } = useTextEditor(
    sectionId,
    block
  );

  const delaySave = (editor: Editor) => {
    if (timer.current) clearTimeout(timer.current);
    /**
     * Disabling Delay save - timer 0
     * Update triggering unnecessary socket message - To be fixed
     */
    timer.current = setTimeout(() => handleAutoSave(editorType, editor.getHTML()), 3000);
  };

  const addNewBlock = (): boolean => {
    if (editorType === 'MEETING_NOTE') dispatch(addBlock(sectionId));

    return true;
  };

  const addNewSection = (): void => {
    switch (editorType) {
      case 'MEETING_NOTE':
        dispatch(addSection());
        break;
      case 'PROJECT_NOTE':
        dispatch(addSectionToProjectNote());
        break;
      case 'TASK_NOTE':
        dispatch(addSectionToTaskNote());
        break;
      default:
        break;
    }
  };

  const handleBlockDelete = () => {
    if (numberOfBlocks === 1) {
      toast.error('This item cannot be deleted, as this is the only block');
      toggleModal();
      return;
    }
    dispatch(deleteBlock({ sectionId, blockId: block.blockId }));
    handleAutoSave(editorType, undefined, true);
    toggleModal();
    toast.success('Block deleted successfully');
  };

  const CustomImage = Image.extend({
    draggable: true
  });

  const CustomDocument = Document.extend({
    content: `block*`,
    addKeyboardShortcuts() {
      return {
        // ↓ your new keyboard shortcut
        'Mod-/': addNewBlock
      };
    }
  });

  useEffect(() => {
    /**
     * Editing status is shown on the block.
     * Only meeting notes will have multiple blocks.
     * Other editors will have only one block, hence we have to check using section ID.
     * Which is done in the parent component
     */
    if (editorType !== 'MEETING_NOTE') return;

    if (editingTable.hasOwnProperty(block.blockId) && editingTable[block.blockId].editingBy !== userId) {
      setBlockEditing(true);
      editedBy.current = editingTable[block.blockId].editorDetails;
      // editor?.setEditable(false);
      setIsEditable(false);
    } else {
      setBlockEditing(false);
      setIsEditable(true);
    }
  }, [editingTable, block, editedBy, editable, editorType, setEditing, setIsEditable, userId]);

  const editor = useEditor({
    editable,
    extensions: [
      CustomDocument,
      Paragraph,
      Heading,
      Text,
      TextStyle,
      BulletList,
      OrderedList,
      ListItem,
      Bold,
      Underline,
      Italic,
      History,
      TextAlign.configure({
        types: ['paragraph']
      }),
      Table.configure({
        resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      CustomImage.configure({
        inline: true
      }),
      FileComponent,
      Link.configure({
        openOnClick: true,
        linkOnPaste: true
      }),
      BlockIdComponent,
      HardBreak
    ],
    onBlur({ editor }) {
      setIsFocused(false);
      setLastEdited(getDate(Date.now().toLocaleString()));
      handleUpdateContents(editorType, editor.getHTML());
      /**
       * Only meeting notes have last updated date
       */
      editorType === 'MEETING_NOTE' &&
        dispatch(
          updateLastUpdateDate({
            sectionId: sectionId,
            blockId: block.blockId,
            newDate: getDate(Date().toLocaleString())
          })
        );

      handleRemoveEditStatus(editorType, noteData);
    },
    onFocus({ editor }) {
      setIsFocused(true);
      editor.commands.focus('end');
      setCurrentEditor(editor as Editor);
      handleAddEditStatus(editorType, noteData);
    },
    onCreate({ editor }) {
      setCurrentEditor(editor as Editor);
    },
    onUpdate({ editor }) {
      // console.log("Running Update")
      // dispatch(updateBlockContent({ sectionId: sectionId, blockId: block.blockId, blockContent: editor.getHTML() }));
      handleUpdateContents(editorType, editor.getHTML());
      delaySave(editor as Editor);
    },

    content: `<div>${block.blockContent}</div>`
  });

  useEffect(() => {
    if (!isFocused && block.blockContent !== editor?.getHTML()) {
      editor?.commands.setContent(block.blockContent);
    }
  }, [block.blockContent]);

  return (
    <EditorStyles isNew={lastEdited === ''} isMeetingNote={editorType === 'MEETING_NOTE'}>
      <div className="editor-container">
        {editor && editable && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <button
              onClick={() => {
                const { view, state } = editor;
                const { from, to } = view.state.selection;
                const text = state.doc.textBetween(from, to);
                handleCreateTask(text, block.blockId);
                return;
              }}
              className="bubble_menu"
            >
              {editorType === 'TASK_NOTE' ? 'Create Associated Task' : 'Create Task'}
            </button>
          </BubbleMenu>
        )}
        {/* {editorType === 'MEETING_NOTE' && <BlockId>{block.blockNumber}</BlockId>} */}
        {editorType === 'MEETING_NOTE' && (
          <BlockId>{sectionNumber + 1 + '.' + ('0' + (blockNumber + 1)).slice(-2)}</BlockId>
        )}
        {/* <button onClick={() => dispatch(deleteBlock({ sectionId, blockId: block.blockId }))}>Delete</button> */}

        {(editorType === 'MEETING_NOTE' && blockEditing) || editing ? (
          <EditStatus>
            {editedBy.current?.profilePhoto && <img className="userIcon" src={editedBy.current?.profilePhoto} alt="" />}
            <p>
              <span>{editedBy.current?.fullName}</span> is editing this item
            </p>
          </EditStatus>
        ) : (
          editable &&
          editorType === 'MEETING_NOTE' && (
            <ToggleButton>
              <ToggleSettings style={{ top: '40px', left: '35px' }}>
                <OptionItem
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Delete Block
                </OptionItem>
              </ToggleSettings>
            </ToggleButton>
          )
        )}

        {isEditable ? (
          <EditorContent editor={editor} />
        ) : (
          <div className="ProseMirror" dangerouslySetInnerHTML={{ __html: sanitize(block?.blockContent) }} />
        )}
      </div>
      {editorType === 'MEETING_NOTE' && !isFocused && lastEdited && (
        <DateContainer>
          <p>{block.blockDate}</p>
        </DateContainer>
      )}
      {isLast && editable && (
        <Footer>
          {editorType === 'MEETING_NOTE' && (
            <div className="new-item">
              <button onClick={addNewBlock}>
                <NewBlockIcon />
              </button>
              <p>Press CMD + '/' to create an item.</p>
            </div>
          )}
          <button onClick={addNewSection}>
            <NewSectionIcon />
          </button>
        </Footer>
      )}

      <EditorDeleteContentModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        deleteType="block"
        confirmHandler={handleBlockDelete}
      />
    </EditorStyles>
  );
};

export default SectionBody;
