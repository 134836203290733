import Modal from '../Modal';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
import { getDate } from '../../lib/getDate';
import Button from '../CustomButton';

type ConfirmedCancellationProps = {
  isOpen: boolean;
  onClose: () => void;
  planType: 'monthly' | 'yearly';
  dueDate: string;
  goToOrganization: () => void;
};

function ConfirmedCancellationModal({
  isOpen,
  onClose,
  planType,
  dueDate,
  goToOrganization
}: ConfirmedCancellationProps) {
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalContainer width="785px">
          <ModalTitle>Your {planType} subscription plan is deactivated</ModalTitle>
          <ModalBody>
            <ModalText>
              Your {planType} subscription for PlatformSuite Collaborative space is successfully deactivated.
              <br />
              Your current {planType} subscription will end on {getDate(dueDate)}
            </ModalText>
            <ModalText>
              If you wish to reactivate your subscription to avoid cancellation of your plan and continue using this
              subscription, go to manage subscriptions.
            </ModalText>
          </ModalBody>
          <ModalWrapper>
            <Button secondary onClick={goToOrganization}>
              Go to organization
            </Button>
            <Button onClick={onClose}>Manage Subscription</Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default ConfirmedCancellationModal;
