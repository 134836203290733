import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import DashboardCard from '../../components/DashboardCard';
import { OptionItem, ToggleSettings } from '../../components/OptionsMenu';
import { Header, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../constants/LoaderDefaultOptions';
import { useLocation } from 'react-router';
import Masonry from 'react-masonry-css';

// import React, { useContext, useEffect, useState } from 'react';
// import { AppContext } from '../../AppContext';
// import toast from 'react-hot-toast';
// import jwt from 'jsonwebtoken';
// import axiosInstance from '../../axiosInstance';
// import { TokenContext } from '../../TokenContext';

const MainDiv = styled.div`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
    margin-right: 30px;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 30px;
  }
`;
const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function NewDashboard() {
  let navigate = useNavigate();
  const location = useLocation();
  // const { state } = useContext(AppContext);
  // const { setToken } = useContext(TokenContext);
  const querry = new URLSearchParams(location.search);
  // const [tokenProcessed, setTokenProcessed] = useState(true);
  // const token = querry.get('token');
  // let decoded: any;

  const editDashboard = () => {
    navigate('/dashboard/edit');
  };
  // useEffect(() => {
  //   if (token) {
  //     toast.remove();
  //     const decodedJwt = parseJwt(token);
  //     if (decodedJwt.exp * 1000 > Date.now()) {
  //       decoded = jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY);
  //     } else {
  //       navigate("/");
  //       toast.remove();
  //       toast.error("Invite Expired.");
  //     }
  //   }
  //   if (decoded && decoded.roleId) {
  //     if (decoded.email === state?.email) {
  //       setToken(null)
  //       navigate('/chooseinvite', {
  //         state: { ...decoded }
  //       })
  //     } else {
  //       toast.error("The invite does not belong to your emailid")
  //       setToken(null)
  //       navigate("/")
  //     }

  //   }
  //   else if (decoded && token) {
  //     if (
  //       decoded.email === state?.email &&
  //       decoded?.entityId !== state?.orgId
  //       && decoded?.invitationStatus === 'pending'
  //     ) {
  //       setTokenProcessed(false)
  //       decoded.type = "addMember";
  //       decoded.userId = state?.userId;
  //       toast.remove();
  //       toast.loading("Joining the Organization");
  //       axiosInstance
  //         .post("/addMemberToOrganization", decoded)
  //         .then(async (response) => {
  //           if (response.data.statusCode === 200) {
  //             let result = await axiosInstance.post("/notification", {
  //               entityType: "ORGANIZATION",
  //               entityId: decoded?.entityId,
  //               actionType: "new_member",
  //               actionBy: state?.userId,
  //               actionByName: state?.fullName,
  //               actionByProfilePic: state?.profilePhoto,
  //               message: `<p><span class=blackBold>${state?.fullName}</span> joined the organisation</p>`,
  //               workspaceId: "",
  //               projectId: "NOPROJECT",
  //               organizationId: decoded?.entityId,
  //             });
  //           }
  //           toast.remove();
  //           toast.success("Joined organisation");
  //           // setTokenProcessed(true)
  //           // setTimeout(() => {
  //           window.location.href = "/";
  //           // }, 2000)
  //           decoded = undefined;
  //         });
  //     } else {
  //       setToken(null)
  //       if (
  //         decoded?.entityId === state?.orgId

  //       ) {
  //         decoded = undefined;
  //         navigate("/");
  //         toast.remove();
  //         toast.error("Already in the organization");
  //       } else if (decoded?.email !== state?.email) {
  //         navigate("/");
  //         toast.remove();
  //         toast.error(
  //           `Please login with the email Id (i.e ${decoded?.email}) in which you received the invite`
  //         );
  //       } else {
  //         toast.remove()
  //         toast.error(`The invite has already been declined or expired. Please request for a new one`)
  //       }
  //     }
  //   }
  // }, [token]);

  let data = useSelector((state) => state?.dashboard?.dashboardData);
  let loading = useSelector((state) => state.dashboard?.loading);
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;


  return (
    <>
      <Header>
        <NewTitleLeft>
          <PageTitle> Dashboard</PageTitle>
        </NewTitleLeft>
        <TitleRight>
          <ToggleSettings>
            <OptionItem onClick={editDashboard}>Edit Dashboard</OptionItem>
          </ToggleSettings>
        </TitleRight>
      </Header>

      <MainDiv style={{ height: '100vh', backgroundColor: '#F6F5F5' }}>
        <Masonry breakpointCols={2} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
          {data.length > 0 &&
            data.map((item, index) => {
              return item.isVisible && <DashboardCard key={index} title={item.title} data={item.data} />;
            })}
        </Masonry>
      </MainDiv>
    </>
  );
}

export default NewDashboard;
