import styled from '@emotion/styled';
import axiosInstance from '../../../axiosInstance';
import { useContext, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/CustomButton';
import RoleItem, { LoaderSkeleton } from '../../../components/Roles/RoleItem';
import { Header, PageTitle, TitleLeft, TitleRight } from '../../../components/styles/Header';
import { ItemContainer } from '../../../components/styles/ListItemStyles';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import toast from 'react-hot-toast';

// import { useToggle } from "../../lib/UseToggle";
// import axios from "axios";
// import Modal from "../../components/Modal";
// import {
//   ModalBody,
//   ModalContainer,
//   ModalTitle,
//   ModalWrapper,
// } from "../../components/styles/ModalStyles";
// import MemberItem from "../../components/MemberItem";
// import { GreyButton } from "../../components/styles/ButtonStyles";
// import { MemberOptions } from "../../constants/MemberOptions";
// import { Auth } from 'aws-amplify'
// import Checkbox from "../../components/Checkbox";
// import Lottie from "react-lottie";
// import { defaultOptions } from "../../constants/LoaderDefaultOptions";
// import toast from "react-hot-toast";
// const OtherMembersWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   border-bottom: 1px solid var(--border-color);
//   padding: 30px 30px;
// `;

// const RolesWrapper = styled.div`
//   padding: 10px 30px;
//   border-bottom: 1px solid var(--border-color);
// `;
export const Text = styled.p`
  font-weight: 600;
`;
// const ModalText = styled(Text)`
//   font-weight: 400;
//   text-align: center;
// `;
// const CheckBoxListItem = styled.div`
//   padding: 12px;
//   display: flex;
//   border-bottom: 1px solid var(--border-color);
//   margin-top: 15px;
//   .memberName {
//     margin-left: 22px;
//   }
/* justify-content: center;
align-self: flex-start; */
// `;

// const CheckBoxContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   margin: 0 33px;
//   margin-bottom: 30px;
//   /* border: 1px solid red; */
// `;

const NoRolesAdded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;
`;

// interface RoleDetails {
//   selectedMember: any;
//   role: any;
//   toggle: () => void;
// }

const initialState: any = {};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET USERS':
      return action.data;
    case 'UPDATE USER':
      let newState = state.map((user) => {
        if (user.id === action.data.userId) {
          user.addedToRole = action.data.roleValue;
        }
        return user;
      });
      return newState;
    default:
      return initialState;
  }
};

function WorkspaceMembers() {
  let navigate = useNavigate();
  let { projectId, workspaceId } = useParams();
  const { state } = useContext(AppContext);
  let [isLoading, setIsLoading] = useState(true);
  let [roles, setRoles] = useState([]);
  // let [users, setUsers] = useState([]);
  let [onlyStaticRoles, setOnlyStaticRoles] = useState(false);
  const [users, dispatch] = useReducer(reducer, initialState);

  const goToFinalPage = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}`);
  };

  // const removeUser = (member) => {
  //   let newMembers = otherMembers.slice();
  //   newMembers.forEach((user) => {
  //     if (user.id === member.id) {
  //       user.checked = !user.checked;
  //     }
  //   });
  //   setOtherMembers(newMembers);
  // };

  useEffect(() => {
    if (projectId && workspaceId && state.orgId) {
      axiosInstance
        .get(`/roles?entityType=WORKSPACE&entityId=${workspaceId}`)
        .then((res) => {
          if (res.data.data.length === 0) {
            setOnlyStaticRoles(true);
            setIsLoading(false);
          } else {
            setRoles(res.data.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (projectId && workspaceId && state.orgId) {
      axiosInstance
        .get(`/project/members?projectId=${projectId}`)
        .then((response) => {
          if (response.data) {
            let members = response.data.data.projectDirectory.map((user) => {
              return {
                id: user.userId,
                value: user.userData.fullName,
                label: user.userData.fullName,
                checked: false,
                addedToRole: false
              };
            });
            dispatch({ type: 'SET USERS', data: members });
            // let otherMembers = members.map((user) => ({ ...user, checked: false }))
            // setOtherMembers(otherMembers)
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
          toast.error('Oh no something went wrong. Please refresh the page.');
        });
    }
  }, []);

  if (isLoading) {
    return (
      <LoaderSkeleton>
        <div className="skeleton skeleton-text" style={{ height: '50%' }}></div>
      </LoaderSkeleton>
    );
  }

  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Required roles for registers</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={goToFinalPage}>Update Workspace</Button>
        </TitleRight>
      </Header>
      <Container>
        <ItemContainer>
          {onlyStaticRoles ? (
            <NoRolesAdded>Only static registers selected</NoRolesAdded>
          ) : (
            roles.length > 0 &&
            roles.map((role) => {
              return <RoleItem key={role.id} role={role} users={users} dispatch={dispatch} />;
            })
          )}
        </ItemContainer>
      </Container>
    </>
  );
}

export default WorkspaceMembers;
