import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { sentryError } from '../../../lib/SentryError';

function useGetTakenSeats() {
  const [takenMonthlyUsers, setTakenMonthlyUsers] = useState([]);
  const [takenYearlyUsers, setTakenYearlyUsers] = useState([]);
  const { state } = useContext(AppContext);
  useEffect(() => {
    const getUsers = () => {
      axiosInstance
        .get(`/users?organizationId=${state.orgId}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            let monthlyUsers = response.data.data
              .map((item: any) => {
                return {
                  userName: item.fullName,
                  memberType: item?.roles?.ORGANIZATION[0]?.roleName,
                  seatType: item?.seatType
                };
              })
              .filter((user) => user.seatType === 'MONTHLY');
            let yearlyUsers = response.data.data
              .map((item: any) => {
                return {
                  userName: item.fullName,
                  memberType: item?.roles?.ORGANIZATION[0]?.roleName,
                  seatType: item?.seatType
                };
              })
              .filter((user) => user.seatType === 'YEARLY');
            setTakenMonthlyUsers(monthlyUsers);
            setTakenYearlyUsers(yearlyUsers);
          }
        })
        .catch((error) => {
          sentryError(error);
        });
    };
    getUsers();
  }, []);

  return {
    takenMonthlyUsers,
    takenYearlyUsers
  };
}

export default useGetTakenSeats;
