import styled from '@emotion/styled';
import { default as dayjs } from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useChatSocket from '../../../../hooks/features/chat/useChatSocket';
import { fetchRemainingGroupMessages, fetchRemainingMessages } from '../../../../reducers/conversations_new';
import { RootState } from '../../../../store';
import { CurrentConversationDetailsI, LastKey, Member, Messages } from '../../types';
import InfoMessage from '../Message/InfoMessage';
import Message from '../Message/Message';
import { v4 as uuid } from 'uuid';
interface Props {
  messages: Messages;
  currentConversationDetails: CurrentConversationDetailsI;
  userId: string;
  lastKey: LastKey | null;
  conversationId: string;
  isLoadingMore: boolean;
  members: Member[];
  conversationType: string;
  setScroll: (scroll: boolean) => void;
  scroll: boolean;
}

export const getDate = (date: string) => {
  const now = dayjs();
  const isToday = dayjs(date).isSame(now, 'D');
  if (isToday) return 'Today';
  const isYesterday = dayjs(date).isSame(now.subtract(1, 'day'), 'D');
  if (isYesterday) return 'Yesterday';
  return dayjs(date).format('MMM DD,YYYY');
};

const ChatBody = ({
  messages,
  currentConversationDetails,
  userId,
  lastKey,
  conversationId,
  isLoadingMore,
  members,
  conversationType,
  scroll,
  setScroll
}: Props) => {
  // const [isTop] = useAtTop();
  const dispatch = useDispatch();
  const topRef = useRef<HTMLDivElement | null>(null);

  const [topMessageIndex, setTopMessageIndex] = useState<string | null>(null);
  const { allMessages, orgData, nonOrgData } = useSelector((state: RootState) => state.conversations);
  const { readConversation } = useChatSocket();

  const loadMore = async () => {
    if (isLoadingMore || !lastKey) return;
    setTopMessageIndex(lastKey.id);
    if (conversationType === 'group')
      dispatch(
        fetchRemainingGroupMessages({
          conversationId,
          lastKey,
          timeLine: allMessages[conversationId].timeLine!,
          currentTimeLineIndex: allMessages[conversationId].currentTimeLineIndex!
        })
      );
    else if (conversationType === 'individual') {
      dispatch(fetchRemainingMessages({ conversationId, lastKey }));
    }
  };

  // to maintain scroll position
  useEffect(() => {
    !scroll && topRef?.current?.scrollIntoView();
  }, [scroll, messages]);

  //for infinity scroll
  const observer = useRef<IntersectionObserver>();
  const loadingElementRef = useCallback(
    (node: any) => {
      if (!lastKey) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && lastKey) {
          setScroll(false);
          loadMore();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [lastKey]
  );

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => elementRef?.current?.scrollIntoView(), []);
    return <div ref={elementRef} />;
  };
  useEffect(() => {
    const allChats = { ...orgData, ...nonOrgData };

    if (allChats[conversationId][0].unreadMessage > 0) {
      readConversation(conversationId);
    }
  }, [orgData, nonOrgData, conversationId]);

  return (
    <>
      <div style={{ width: '100%', padding: '10px 10px', overflowY: 'auto' }}>
        {isLoadingMore && (
          <div
            style={{
              height: '60px',
              display: 'flex',
              color: 'grey',
              padding: '20px',
              justifyContent: 'center'
            }}
          >
            Loading Previous Messages
          </div>
        )}
        {Object.keys(messages)?.map((key, index) => (
          <div key={uuid()}>
            {index === 0 && <div ref={loadingElementRef}></div>}
            <DateDiv key={uuid()}>{getDate(key)}</DateDiv>
            {messages[key]?.map((message, i) => (
              <div key={uuid()}>
                {!scroll && message.id === topMessageIndex ? <div ref={topRef}></div> : null}
                {message.messageType === 'remove_user' || message.messageType === 'add_user' ? (
                  <InfoMessage messageType={message.messageType} removedOrAddedUserId={message?.removedOrAddedUserId} />
                ) : (
                  <Message
                    fullName={currentConversationDetails.name}
                    message={message}
                    ownMessage={userId === message.sentBy}
                    profilePicture={currentConversationDetails.profilePic}
                    memberData={members.find((member) => member.id === message.sentBy)!}
                  />
                )}
              </div>
            ))}
            {scroll ? <AlwaysScrollToBottom /> : ''}
          </div>
        ))}
      </div>
    </>
  );
};

export default ChatBody;

const DateDiv = styled.div`
  background-color: #a1a1a1;
  color: #ffffff;
  width: 100px;
  display: flex;
  flex-wrap: nowrap;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  margin: auto;
  font-size: 14px;
  margin-top: 15px;
`;
