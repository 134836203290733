import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { ModalInput, ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { createNewNote } from './CreateNoteModal';

const ReOpenNoteModal = ({
  noteId,
  isOpen,
  toggleModal
}: {
  noteId: string | undefined;
  isOpen: boolean;
  toggleModal: () => void;
}) => {
  const navigate = useNavigate();
  const [newNoteName, setNewNoteName] = useState('');
  const { state } = useContext(AppContext);

  // @ts-ignore
  const { meetingNoteData, metaData, otherFields } = useSelector((state) => state.meetingNote);
  // const metaData = meetingNoteData?.metadata && JSON.parse(meetingNoteData?.metadata);
  // const otherFields = meetingNoteData?.otherFields && JSON.parse(meetingNoteData?.otherFields);

  const handleCreateNewNote = async () => {
    const id = toast.loading('Creating new note');

    try {
      const meetingNoteId = await createNewNote({
        noteName: newNoteName,
        projectId: meetingNoteData.projectId,
        workspaceId: meetingNoteData.workspaceId,
        orgId: meetingNoteData.organizationId,
        otherFields: otherFields,
        templateData: metaData,
        userId: state.userId
      });

      //Save note with content
      const body: any = {
        organizationId: meetingNoteData.organizationId,
        workspaceId: meetingNoteData.workspaceId,
        projectId: meetingNoteData.projectId,
        title: meetingNoteData.title,
        content: meetingNoteData.sections,
        metaData: metaData ? metaData : 'meta-data',
        noteId: noteId
      };

      if (otherFields) body['otherFields'] = otherFields;

      try {
        const res = await axiosInstance.post(`/meetingNotes/`, body);
        // toast.success('Notes saved successfully!', { id: toastId });
      } catch (error) {
        console.log(error);
        throw error;
      }

      toast.success('Note created successfully', { id });
      navigate(
        `/project/workspace/${meetingNoteData.projectId}/${meetingNoteData.workspaceId}/meetingnotes/${meetingNoteId}`
      );
      toggleModal();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id });
    }
  };

  if (!noteId) return null;

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Republish Note</ModalTitle>
        <ModalBody>
          <ModalText>
            This will create a copy of this note. What would you like to name the republished Note ?
          </ModalText>
          {/* <input /> */}
          <ModalInput
            placeholder="Note Name"
            type="text"
            value={newNoteName}
            onChange={(e) => setNewNoteName(e.target.value)}
            disabled={false}
          />
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={false} onClick={handleCreateNewNote}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default ReOpenNoteModal;
