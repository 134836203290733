import FileTag from '../../../../components/FileTag';
import styled from '@emotion/styled';
import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Button, { CustomButton } from '../../../../components/CustomButton';
import { getFileType } from '../../../../lib/getFileType';
import { DownloadFileQuery, SINGLE_FILE_QUERY } from '../../../../graphql/operations/Queries/Library/FilesQueries';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { DescriptionText, FileDownload } from '../../../Library/FilesView/Files/ViewFile/FileInformation/AboutFile';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  DateWrapper,
  WrapperDiv
} from '../../../../components/styles/FormStyles';
import { Label } from '../../../../components/styles/Label';
import React from 'react';
import { DateText } from '../../Registers/CreateNewDynamicDocumentInRegister';
import { getDate } from '../../../../lib/getDate';
import { Small } from '../../../../components/styles/SmallText';
const Container = styled.div`
  padding-left: 1.625rem;
  display: flex;
  flex-direction: row;
  gap: 40px;
  /* justify-content: space-between;   */
  .row {
    /* border: 1px solid red;     */
    /* padding: 25px; */
    /* padding-right: 5rem; */
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;

    h4 {
      font-weight: 600;
      color: var(--black);
    }
    p {
      display: flex;
      width: 25rem;
      justify-content: flex-start;
      font-weight: 400;
      color: var(--black);
      padding-left: 1.9rem;
      align-items: baseline;
      margin-bottom: 0px;
    }
    small {
      padding-left: 4rem;
      color: var(--text-primary);
    }
  }

  .filename {
    padding-left: 29px;
    padding-right: 19px;
  }

  ${CustomButton} {
    color: #26303e;
    background: #dbe2e0;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    display: inline-block;
  }
`;

function AboutWorkspaceFile() {
  let { fileId } = useParams();
  const { data, error, loading } = useQuery(SINGLE_FILE_QUERY, {
    variables: { id: fileId, operation: 'query' }
  });
  const downloadfileId = data?.get_File?.fileId;
  const { data: downloadData } = useQuery(DownloadFileQuery, {
    skip: !downloadfileId,
    variables: { id: downloadfileId, operation: 'query' }
  });
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  const { get_File } = data;

  return (
    <Container>
      <div className="row">
        <FileDownload>
          <FileTag>{getFileType(get_File.fileType).toUpperCase()}</FileTag>{' '}
          <span className="filename">{get_File.name}</span>
          <a
            href={downloadData?.getVendia_File?.temporaryUrl}
            download={`${get_File.name}.${get_File.fileType}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button>Download File</Button>
          </a>
        </FileDownload>
        <WrapperDiv>
          <Label>Description</Label>
          <DescriptionText>{get_File.fileDescription}</DescriptionText>
        </WrapperDiv>
        <WrapperDiv>
          {get_File.fields
            ? JSON.parse(get_File.fields).length > 0 &&
              JSON.parse(get_File.fields).map((field, index) => {
                return (
                  <React.Fragment key={index}>
                    <Label>{field.label}</Label>
                    <DescriptionText>{field.value}</DescriptionText>
                  </React.Fragment>
                );
              })
            : null}
        </WrapperDiv>
      </div>
      <div className="row">
        <DateWrapper>
          <Label htmlFor="date">Document Date</Label>
          <DateText>{getDate(get_File.createdAt)}</DateText>
          <CalendarIconPlacer></CalendarIconPlacer>
        </DateWrapper>
        <AssigneeWrapper>
          <Label>Created by</Label>
          <AssigneeSeparator>
            <AvatarStyles>
              <CustomAvatar name={get_File.createdByName} size={20} />
            </AvatarStyles>
            <Small>{getDate(get_File.createdAt)} </Small>
          </AssigneeSeparator>
        </AssigneeWrapper>
      </div>
    </Container>
  );
}

export default AboutWorkspaceFile;
