import { useQuery } from '@apollo/client';
import { useContext } from 'react';
// import { useLocation } from 'react-router';
import { AppContext } from '../../../AppContext';
import { LIST_SHARED_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
// import { Project } from './useListProjects';

const useListSharedProjects = () => {
  // const { state } = useLocation();
  // const [sharedProjectList, setProjectList] = useState<Project[]>([]);
  const { state: userDetails } = useContext(AppContext);

  const {
    data: sharedProjectList,
    error,
    loading
  } = useQuery(LIST_SHARED_PROJECTS_QUERY, {
    variables: {
      organizationId: userDetails.orgId,
      recordStatus: 0,
      isShared: true
    },
    fetchPolicy: 'network-only'
  });

  return {
    sharedProjectList,
    error,
    loading,
    userDetails
  };
};

export default useListSharedProjects;
