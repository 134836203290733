import { useReducer, createContext } from 'react';
const initialState = {
  role: '',
  permission: [],
  projectId: '',
  userId: '',
  userName: '',
  profilePhoto: '',
  organizationId: '',
  designation: '',
  orgId: '',
  phone: '',
  countryCode: '',
  fullName: '',
  firstName: '',
  lastName: '',
  email: '',
  allOrganizations: '',
  authToken: '',
  roleName: '',
  seatStatus: 0,
  subscription: '',
  cookiesEnabled: false,
  isChannelRockUser: false
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'CURRENT_USER':
      return {
        ...state,
        permission: action.data.permission,
        role: action.data.userRole,
        projectId: action.data.projectId
      };
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.data
      };
    case 'SIGN_OUT':
      return {
        role: '',
        permission: [],
        projectId: '',
        userId: '',
        userName: '',
        profilePhoto: '',
        organizationId: '',
        designation: '',
        orgId: 'null',
        phone: '',
        countryCode: '',
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        allOrganizations: '',
        authToken: '',
        roleName: '',
        subscription: '',
        seatStatus: ''
      };
    case 'SET_USER':
      return {
        ...state,
        ...action.data
        // userId: action.data.sub,
        // userName: action.data.username,
        // token: action.data.token
      };
    default:
      return {};
  }
};
const AppContext = createContext({
  state: initialState,
  dispatch: (param: Object) => {}
});
function AppContextProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value: any = { state, dispatch };
  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}
const AppContextConsumer = AppContext.Consumer;
export { AppContext, AppContextProvider, AppContextConsumer };
