import * as Yup from "yup";

const AddTemplateWorkspaceValidationSchema = Yup.object({
  templatename: Yup.string()
    .min(2, "Template Name is too short")
    .max(20, "Template Name is quite Long")
    .required("A Template Name is required"),
});

export default AddTemplateWorkspaceValidationSchema;
