import { gql } from '@apollo/client';
export const GET_SINGLE_TASK_NOTE_QUERY = gql`
      query blocksQuery($type_typeId: String, $organizationId_projectId_workspaceId: String )
      @api(name: "task") {
            list_NoteAndDisscussionItems(
            filter: {noteType_typeId: {eq: $type_typeId}, organizationId_projectId_workspaceId: {ge: $organizationId_projectId_workspaceId}}
            ) {
                  _NoteAndDisscussionItems {
                        _id
                        content
                        createdAt
                        createdBy
                        isDraft
                        metadata
                        noteType
                        noteType_typeId
                        organizationId
                        organizationId_projectId_workspaceId
                        otherFields
                        projectId
                        recordStatus
                        title
                        typeId
                        updateBy
                        updatedAt
                        workspaceId
                  }
            }
      }
`

export const LIST_ASSOCIATED_TASKS_FROM_NOTE = gql`
      query blocksQuery($organizationId_projectId_typeId: String, $noteId: String, $recordStatus:Float)
      @api(name: "task")  {
            list_TaskItems(
            filter: {organizationId_projectId_typeId: {ge: $organizationId_projectId_typeId}, noteId: {eq: $noteId}, recordStatus:{eq: $recordStatus}}
            ) {
                  _TaskItems {
                        acknowledgement
                        assignedTo
                        assignee
                        blockId
                        createdAt
                        description
                        dueDate
                        isAcknowledged
                        isAssociated
                        linktoRegister
                        noteId
                        organizationId
                        organizationId_projectId_typeId
                        otherFields
                        parentAssociateId
                        priority
                        projectId
                        recordStatus
                        status
                        title
                        type
                        typeId
                        updatedAt
                        updatedBy
                        workspaceId
                        _owner
                        _id
                        assignedToDetails {
                              name
                              profilePicture
                        }
                        assigneeDetails {
                              name
                              profilePicture
                        }
                        links
                  }
            }
      }
`