import styled from '@emotion/styled';
import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import { useParams } from 'react-router';
import { AppContext } from '../../../../AppContext';
import Card from '../../../../components/Projects/Card';
import EmptyCard from '../../../../components/Projects/EmptyCard';
import { AssigneeSeparator, AvatarStyles } from '../../../../components/styles/FormStyles';
import { Label } from '../../../../components/styles/Label';
import { Small } from '../../../../components/styles/SmallText';
import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import TableDropDown from '../../../../components/TableComponents/TableDropDown';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { useGetWorkspace } from '../../../../hooks/features/workspaces/useGetWorkspace';
import { recordTypes } from '../../../../hooks/features/projects/useProjectAddress';
import { getDate } from '../../../../lib/getDate';
import { getFullNameById } from '../../../../lib/getFullName';
import { ErrorPage } from '../../../Others';
import {
  AboutText,
  CardContainer,
  CreatedByWrapper,
  InsideDropDownWrapper,
  TextWrapper
} from '../../../Projects/AboutProject/ProjectInformation/ProjectDetailsView';
// import { useGetProject } from '../../../hooks/useGetProject'
// import { Container } from '../../Projects/ProjectViews/CreateProject'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  margin-right: 1.625rem;
  margin-top: 2rem;
`;

function WorkspaceDetailsView() {
  const { state } = useContext(AppContext);
  const { workspaceId } = useParams();

  let orgId = state.orgId;

  const { loading, error, workspaceData, goToOtherContacts, goToTeams, allUsers, fullName } = useGetWorkspace(
    workspaceId,
    orgId
  );
  // const { loading, error, projectData, hideProjectCard, goToOtherContacts, goToAddTeams } = useGetProject(projectId, orgId)

  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  if (error) {
    return <ErrorPage />;
  }
  const { projectName, workspaceDescription, createdBy, createdAt, workspaceName, OtherContacts, workspaceTeam } =
    workspaceData;
  return (
    <>
      <Container>
        <TableDropDown state={true} text="Workspace Summary">
          <InsideDropDownWrapper>
            <TextWrapper>
              <Label>Project Name</Label>
              <AboutText>{projectName}</AboutText>
            </TextWrapper>
            <TextWrapper>
              <Label>Workspace Name</Label>
              <AboutText>{workspaceName}</AboutText>
            </TextWrapper>
            <TextWrapper>
              <Label>Workspace Description</Label>
              <AboutText>
                {workspaceDescription ? workspaceDescription : 'No Description provided while creating workspaces'}
              </AboutText>
            </TextWrapper>
            <CreatedByWrapper>
              <Label>Created By</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar isTable={false} name={getFullNameById(createdBy, allUsers, fullName)} size={21} />
                </AvatarStyles>
                <Small>{getDate(createdAt)}</Small>
              </AssigneeSeparator>
            </CreatedByWrapper>
          </InsideDropDownWrapper>
        </TableDropDown>
        <TableDropDown
          state={OtherContacts?.length > 0 ? true : false}
          text="Other Contacts"
          buttonText="+ Other Contacts"
          handleClick={goToOtherContacts}
        >
          <InsideDropDownWrapper>
            <CardContainer>
              {OtherContacts?.length > 0 &&
                OtherContacts.map((contact) => {
                  return (
                    contact.recordStatus === 0 && (
                      <Card
                        details={contact}
                        key={contact.id}
                        type={recordTypes.CONTACT}
                        handleEdit={goToOtherContacts}
                      />
                    )
                  );
                })}
              {OtherContacts?.length === 0 && <EmptyCard type="contact" handleClick={goToOtherContacts} />}
            </CardContainer>
          </InsideDropDownWrapper>
        </TableDropDown>
        <TableDropDown
          state={workspaceTeam?.length > 0 ? true : false}
          text="Team"
          buttonText="+ Add Team"
          handleClick={goToTeams}
        >
          <InsideDropDownWrapper>
            <CardContainer>
              {workspaceTeam?.length > 0 &&
                workspaceTeam.map((team) => {
                  return (
                    team.recordStatus === 0 && (
                      <Card details={team} key={team.id} type={recordTypes.TEAM} handleEdit={goToTeams} />
                    )
                  );
                })}
              {workspaceTeam?.length === 0 && <EmptyCard type="team" handleClick={goToTeams} />}
            </CardContainer>
          </InsideDropDownWrapper>
        </TableDropDown>
      </Container>
    </>
  );
}

export default WorkspaceDetailsView;
