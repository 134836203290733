import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { default as dayjs } from 'dayjs';
import Avatar from 'react-avatar';
import toast from 'react-hot-toast';
import { ReactComponent as FileIcon } from '../../../../assets/icons/fileIcon.svg';
import { ReactComponent as ImageIcon } from '../../../../assets/icons/Image_icon.svg';
import axiosInstance from '../../../../axiosInstance';
import { Member, Message as MessageI } from '../../types';

/**
 * ownMessage - To check if message is owned by the current user
 */
type Props = {
  message: MessageI;
  ownMessage: boolean;
  fullName: string;
  profilePicture?: string;
  memberData: Member | undefined;
};

function formatBytes(bytes: number | undefined, decimals = 2) {
  if (!bytes) return '';
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const Message = ({ message, ownMessage, fullName, profilePicture, memberData }: Props) => {
  // console.log(dangerouslySetInnerHTML({ __html: message.message} ))
  const handleDownload = async () => {
    const id = toast.loading('Please wait while we fetch your file');
    try {
      const data = await axiosInstance.get(`/preSignedUrl?type=CONVERSATION&key=${message?.fileId}`);
      const newWindow = window.open(data?.data?.url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      toast.success('Successfully retrieved your file', { id });
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      {
        <MessageStyles ownMessage={ownMessage}>
          {!ownMessage && (
            <Avatar
              round={true}
              style={{ maxHeight: '40px' }}
              size="40"
              name={memberData ? memberData?.fullName : 'User Left'}
              src={memberData?.profilePhoto ? memberData.profilePhoto : ''}
              color="#26303e"
            />
          )}
          {message.mediaType && (message.mediaType === 'IMAGE' || message.mediaType === 'DOCUMENT') ? (
            <SpecialText className="messageText" onClick={handleDownload}>
              {message.mediaType === 'DOCUMENT' ? (
                <FileIcon />
              ) : (
                <ImageIcon style={{ width: '30px', height: '30px' }} />
              )}
              <div className="file-details">
                <span className="fileName">{message?.message}</span>
                <span className="fileSize">{formatBytes(message?.mediaSize)}</span>
              </div>
            </SpecialText>
          ) : (
            <div className="messageText" dangerouslySetInnerHTML={{ __html: message.message }}></div>
          )}
          <div className="date">
            {dayjs(message.createdAt).isSame(dayjs(), 'D')
              ? dayjs(message.createdAt).format('hh:mm A')
              : dayjs(message.createdAt).format('MMM DD,YYYY hh:mm A')}
          </div>
        </MessageStyles>
      }
    </>
  );
};

export default Message;

const MessageStyles = styled.div<{ ownMessage?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.ownMessage ? 'flex-end' : 'flex-start')};
  /* align-items: flex-start; */
  margin-top: 2px;
  margin-bottom: 40px;
  position: relative;
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  .messageText {
    background-color: ${(props) => (props.ownMessage ? '#F6ECDB' : '#FFFFFF')};
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    max-width: 60%;
    padding: 10px 15px;
    border-radius: 5px;
    margin-left: 10px;
  }

  .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7d7d7d;
    /* align-items: ${(props) => (props.ownMessage ? 'flex-start' : 'flex-end')}; */
    position: absolute;
    bottom: -20px;
    right: 0;
    ${(props) =>
      props.ownMessage
        ? css`
            right: 5px;
          `
        : css`
            left: 45px;
          `}
  }
`;

const SpecialText = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 20px;
  .file-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .fileName {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    .fileSize {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      color: #7d7d7d;
    }
  }

  &:hover {
    cursor: pointer;
  }
`;
