import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import { PlanType } from '../../pages/Organization/ViewOrganization';
import { PageMode } from '../../pages/Payments/MonthlySubscriptionPage';
import {
  PricingText,
  SeatBox,
  SeatQuantityBox,
  SeatQuantityText,
  SeatSelectionDiv,
  SeatSelectionTitle,
  SeatWrapper,
  SubscriptionType,
  TotalAmountText
} from './SelectSeats';

type CurrentPlanProps = {
  pageMode: PageMode;
  plan: PlanType;
  originalQuantity: number;
};

function DisplayCurrentPlan({ pageMode, plan, originalQuantity }: CurrentPlanProps) {
  let planType = plan === 'month' ? 'monthly' : 'yearly';
  let seatCharge = plan === 'month' ? 17 : 9;
  return (
    <>
      <SeatWrapper style={{ paddingBottom: '10px', paddingTop: '24px', marginTop: '26px', width: '90%' }}>
        <SeatSelectionTitle style={{ marginTop: '0px' }}>
          {pageMode === 'default' ? `${capitalizeFirstLetter(planType)} Subscription` : 'Current Plan'}
        </SeatSelectionTitle>
        <SeatBox>
          <PricingText>
            Each seat billed {planType} for US$ {seatCharge}.00/Month
          </PricingText>
          <SeatSelectionDiv>
            <div>
              <SeatQuantityText>Seat Quantity</SeatQuantityText>
              <SeatQuantityBox>
                <div>{''}</div>
                <div style={{ textAlign: 'center' }}>{originalQuantity}</div>
                <div> </div>
              </SeatQuantityBox>
            </div>
            <div>
              <SubscriptionType>{capitalizeFirstLetter(planType)} Bill Amount</SubscriptionType>
              {plan === 'month' && <TotalAmountText>US$ {seatCharge * originalQuantity}.00</TotalAmountText>}
              {plan === 'year' && <TotalAmountText>US$ {seatCharge * originalQuantity * 12}.00</TotalAmountText>}
            </div>
          </SeatSelectionDiv>
        </SeatBox>
      </SeatWrapper>
    </>
  );
}

export default DisplayCurrentPlan;
