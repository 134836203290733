import React from 'react';
import { ListArchivedItem } from './ListItem';
import { ReactComponent as ProjectIcon } from '../assets/icons/ProjectTitleIcon.svg';
import { ProjectName, ProjectInfo, DateStyle, Time, ProjectDate } from './ProjectList';
import { OptionItem, ToggleSettings } from './OptionsMenu';
import styled from '@emotion/styled';
import useGetIsSuperAdmin from '../hooks/common/useGetIsSuperAdmin';
// import UseGetIsProjectAdmin from '../hooks/UseGetIsProjectAdmin';
import { getDate } from '../lib/getDate';
import { getTime } from '../lib/getTime';

const MenuOptions = styled.span`
  position: relative;
  display: block;
  margin-left: 20px;
`;

function ArchivedProjectsList({ data, removeArchive }: any) {
  const { isSuperAdmin } = useGetIsSuperAdmin();

  return (
    <div>
      {data &&
        data?.length > 0 &&
        data.map((item, index) => {
          return (
            <React.Fragment key={item._id}>
              <ListArchivedItem>
                <ProjectInfo>
                  <ProjectIcon />
                  <ProjectName>{item.name}</ProjectName>
                </ProjectInfo>
                <ProjectDate>
                  <DateStyle>{getDate(item?.createdAt)}</DateStyle>
                  <Time>{getTime(item?.createdAt)}</Time>
                  {isSuperAdmin && (
                    <MenuOptions>
                      <ToggleSettings style={{ top: '30px', left: '160px' }}>
                        <OptionItem onClick={() => removeArchive(item._id)}>Remove from Archival</OptionItem>
                      </ToggleSettings>
                    </MenuOptions>
                  )}
                </ProjectDate>
              </ListArchivedItem>
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default ArchivedProjectsList;
