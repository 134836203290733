import useChatSocket from '../../../hooks/features/chat/useChatSocket';
import Button from '../../CustomButton';
import { ActionItems, Container, Message } from './ConversationStatusStyles';

function NewConversationMessage() {
  // const chatId = useSelector((state) => state.conversations.selected);
  // const ws = useContext(LiveConnectionContext);
  // const state = useContext(AppContext);
  const { blockChat, continueChat } = useChatSocket();

  return (
    <Container>
      <Message>You have a new conversation request. Do you wish to continue?</Message>
      <ActionItems>
        <Button className="white" secondary onClick={blockChat}>
          Block
        </Button>
        <Button onClick={continueChat}>Continue</Button>
      </ActionItems>
    </Container>
  );
}

export default NewConversationMessage;
