import React, {  useEffect, useState } from 'react';
import { Header, HeaderLeft } from '../../../components/styles/Header';
import Button from '../../../components/CustomButton';
import { Outlet} from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageNavStyles } from '../../Library/FilesView/Files/ViewFile/ViewFile';
import { useParams } from 'react-router';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { useToggle } from '../../../lib/UseToggle';
import useMeetingNotes from '../../../hooks/features/meetingNotes/useMeetingNoteEditor';
import SharedModal from './Modals/SharedModal';
import { TitleRight } from '../../Tasks/TaskViews/CreateTask';
import axiosInstance from '../../../axiosInstance';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import Lottie from 'react-lottie';

const MeetingNotesSharedList = () => {

  const { noteId } = useParams();
  const [isSharedListOpen, setSharedListOpen] = useToggle(false);
  const { meetingNoteData, handlePublishNote } = useMeetingNotes(noteId!);
  const [loading, setLoading] = useState(false);
  const [attendeeList, setAttendeeList] = useState<any>([]);
  const [sharedList, setSharedList] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/meetingNotes/members?noteId=${noteId}`);
      const attendee = data?.filter((d: any) => d.memberType === 'ATTENDEE');
      const shared = data?.filter((d: any) => d.memberType === 'SHARED');
      setAttendeeList(attendee);
      setSharedList(shared);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <h2>Note shared with</h2>
        </HeaderLeft>
        <TitleRight>
          <Button onClick={() => setSharedListOpen(true)}>Add Member</Button>
        </TitleRight>
      </Header>
      <>
        <>
          <PageNavStyles>
            <NavLink
              className={(navData) => (navData.isActive ? 'active' : '')}
              end
              to={`/meetingnotes/${noteId}/publish`}
            >
              Attendee List
            </NavLink>
            <NavLink
              className={(navData) => (navData.isActive ? 'active' : '')}
              to={`/meetingnotes/${noteId}/publish/shared`}
            >
              Shared List
            </NavLink>
          </PageNavStyles>
          {loading ? (
            <Lottie options={defaultOptions} width={400} height={500} />
          ) : (
            <Outlet context={[sharedList, attendeeList]} />
          )}
        </>
      </>
      <SharedModal
        isOpen={isSharedListOpen}
        toggleModal={setSharedListOpen}
        handlePublishNote={handlePublishNote}
        organizationID={meetingNoteData.organizationId}
        meetingNoteData={meetingNoteData}
        sharedList={sharedList}
        attendeeList={attendeeList}
        fetchData={fetchData}
      />
    </>
  );
};

export default MeetingNotesSharedList;
