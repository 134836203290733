import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { ListDetails } from '../../../constants/ProjectMembersColumn';
import capitalizeFirstLetter from '../../../lib/CapitaliseFirstLetter';
import UseGetIsProjectAdmin from '../../common/UseGetIsProjectAdmin';

function useGetProjectMembers() {
  let navigate = useNavigate();
  let { projectId } = useParams();
  const { state } = useContext(AppContext);
  const [memberData, setMemberData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isProjectAdmin, loadingIsProjectAdmin } = UseGetIsProjectAdmin(projectId);
  const goToAddMembers = () => {
    navigate(`add`);
  };

  const goToProjectsPage = () => {
    navigate(`/project/${projectId}/workspaces`);
  };

  // TODO: remove below line if not required
  let orgId = state.orgId;

  // this function is again used in multiple places. so again can be converted to a reusable hook.
  useEffect(() => {
    const getProjectData = async () => {
      await axiosInstance
        .get(`/project/members?projectId=${projectId}`)
        .then((res) => {
          setMemberData(res.data.data.projectDirectory);
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getProjectData();
  }, []);

  const ProjectMembersColumn = [
    {
      id: 'User',
      Header: 'User',
      width: 100,
      accessor: 'userData.fullName'
    },
    {
      id: 'Relationship',
      Header: 'Relationship',
      accessor: 'designation',
      Cell: ({ cell: { value } }) => (value ? value : 'No Relationship Added'),
      width: 100
    },
    {
      Header: 'Member Type',
      id: 'Member Type',
      accessor: 'userType',
      Cell: ({ cell: { value } }) => capitalizeFirstLetter(value),
      width: 100
    },
    {
      id: 'Workspaces',
      Header: 'Workspaces',
      accessor: 'workspaces',
      width: 100,
      Cell: ({ cell: { value }, row }) => <ListDetails isProjectAdmin={isProjectAdmin} values={value} row={row} />
    }
  ];

  const data = useMemo(() => memberData, [memberData]);
  const columns = useMemo(() => ProjectMembersColumn, [isProjectAdmin]);

  return {
    goToAddMembers,
    columns,
    error,
    loading,
    data,
    loadingIsProjectAdmin,
    goToProjectsPage
  };
}

export default useGetProjectMembers;
