import { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import Button, { CustomButton } from '../../../../components/CustomButton';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { AppContext } from '../../../../AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import CollectionFilesList from '../../../../components/CollectionFilesList';
import { ReactComponent as FileIcon } from '../../../../assets/icons/NewFileIcon.svg';
import { LIST_FILES_QUERY, SINGLE_COLLECTION } from '../../../../graphql/operations/Queries/Library/FilesQueries';
import { updateCollectionMutation } from '../../../../graphql/operations/Mutations/Library/FileMutations';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { ErrorPage } from '../../../Others';
import Loader from '../../../../components/Loader';
import { EmptyDataContainer, ProjectsContainer } from '../../../Projects/ProjectViews/Projects';
// import { Label } from "../../../../components/styles/Label";
// import {
//   LinkWrapper,
//   FieldSet,
//   InputWrapper,
//   DateWrapper,
//   AssigneeWrapper,
//   AssigneeSeparator,
//   AvatarStyles,
//   CalendarIconPlacer,
//   FileUploadWrapper,
//   FilePreview,
// } from "../../../../components/styles/FormStyles";
// // import { TaskDetails } from '../../Dashboard/TaskViews/CreateTask'
// import InputField from "../../../../components/Reusable/Formik/Input";
// import TextAreas from "../../../../components/Reusable/Formik/Textarea";
// import DateInputs from "../../../../components/Reusable/Formik/DateInput";
// import FileValidationSchema from "../../../../components/Validations/CreateFileValidation";

const Form = styled.form`
  .input-width {
    width: 60%;
  }
`;
const CollectionDetails = styled.div`
  margin-left: 3.2%;
`;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #dbe2e0;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
  }
`;

const AddFilesToCollection = () => {
  let navigate = useNavigate();
  let { collectionId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useContext(AppContext);
  const userId = state?.userId;
  const {
    data: documentData,
    error,
    loading
    // refetch,
  } = useQuery(LIST_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${userId}`,
      recordStatus: 0
    }
  });

  const [updateCollection] = useMutation(updateCollectionMutation);

  const {
    data: CollectionData,
    error: collectionError
    // loading: collectionLoading
  } = useQuery(SINGLE_COLLECTION, {
    variables: {
      id: collectionId,
      operation: 'query'
    }
  });
  const initialState = {
    name: CollectionData?.get_Collection?.name,
    checkedFiles: CollectionData?.get_Collection?.files
  };

  const updateCollectionData = async (CollectionDatas: any) => {
    const toastid = toast.loading('Updating Collection.');
    setIsSubmitting(true);
    try {
      let Response = await updateCollection({
        variables: {
          libraryType: 'notesAndCollections',
          id: collectionId,
          name: CollectionData?.get_Collection?.name,
          files: CollectionDatas?.checkedFiles
        }
      });
      if (Response) {
        toast.success('Collection Updated.Redirecting..', { id: toastid });
        navigate(`/collections/${CollectionData?.get_Collection?._id}`);
      }
    } catch (error) {
      console.error('Error in Updating collection', error);
      toast.dismiss(toastid);
      toast.error('Error in updating collection.Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: updateCollectionData
  });

  if (error || collectionError) return <ErrorPage />;
  if (loading || collectionError) return <Loader />;

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Add Files to {CollectionData?.get_Collection?.name} </PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={isSubmitting} type="submit">
              Save Collection
            </Button>
          </TitleRight>
        </Header>
        <CollectionDetails>
          <div>
            {documentData?.list_FileItems?._FileItems.length === 0 ? (
              <ProjectsContainer>
                <div className="empty-data">
                  <EmptyDataContainer>No Files added to your library.</EmptyDataContainer>
                </div>
              </ProjectsContainer>
            ) : (
              <CollectionFilesList
                data={documentData?.list_FileItems?._FileItems}
                icons={<FileIcon />}
                files={CollectionData?.get_Collection?.files}
                checkedFiles={formik.values.checkedFiles}
                setCheckedFiles={(file) => formik.setFieldValue('checkedFiles', file)}
              />
            )}
          </div>
        </CollectionDetails>
      </Form>
      <Toaster />
    </>
  );
};

export default AddFilesToCollection;
