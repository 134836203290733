import { useMutation } from '@apollo/client';
import styled from '@emotion/styled/';
import { useContext, useLayoutEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from '../../AppContext';
import axiosInstance from '../../axiosInstance';
import Button from '../../components/CustomButton';
import { Header, PageTitle, TitleLeft, TitleRight } from '../../components/styles/Header';
import { Small } from '../../components/styles/SmallText';
import { UPDATE_PROJECT_SHARED_STATUS } from '../../graphql/operations/Mutations/Projects/ProjectMutations';
import ChooseProjectList from '../Projects/WorkspaceInvitation/ChooseProjectList';
import CreateProjectAndAddWorkspace from '../Projects/WorkspaceInvitation/CreateProjectAndAddWorkspace';
// import axios from 'axios'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
`;

function ChooseWorkspaceInvite() {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { state: RouterState } = useLocation();
  const [projectId, setProjectId] = useState<string>('');
  const [addingToWorkspace, setAddingToWorkspace] = useState(false);
  const [updateProject] = useMutation(UPDATE_PROJECT_SHARED_STATUS);

  useLayoutEffect(() => {
    if (state.orgId === 'null' || null) {
      navigate('/createorganization');
    }
  }, []);

  const addToWorkspace = async () => {
    if (!projectId) {
      toast.error('Please select a project or create a new one');
    } else {
      setAddingToWorkspace(true);
      let toastId = toast.loading('Adding to Workspace');
      if (RouterState?.roleId) {
        let data = {
          userId: state.userId,
          entityType: 'WORKSPACE',
          entityId: RouterState?.entityId,
          projectId: projectId,
          organizationId: state.orgId
        };
        axiosInstance
          .post('/userEntity', [data])
          .then((response) => {})
          .then(() => {
            if (RouterState?.roleId) {
              axiosInstance
                .post('/rolesMapping', {
                  userId: state.userId,
                  roleId: RouterState.roleId,
                  entityType: 'WORKSPACE',
                  entityId: RouterState.entityId,
                  organizationId: state?.orgId
                })
                .then((response) => {
                  updateProject({
                    variables: {
                      id: projectId,
                      isShared: true
                    }
                  }).then((response) => {
                    toast.dismiss(toastId);
                    toast.success('Added To Workspace');
                    navigate('/projects');
                  });
                })
                .catch((error) => {
                  console.error('ERROR IN ADDING PROJECT', error);
                  toast.dismiss(toastId);
                  toast.error('Something went wrong. Try Again.');
                });
            }
          })
          .catch((error) => {
            console.error('ERRROR', error);
            toast.dismiss(toastId);
            toast.error('Something went wrong.');
          })
          .finally(() => {
            setAddingToWorkspace(false);
          });
        //update project and make it isShared.:true
      } else if (RouterState?.entityId) {
        let data = {
          userId: state.userId,
          entityType: 'WORKSPACE',
          entityId: RouterState?.entityId,
          projectId: projectId,
          organizationId: state.orgId
        };
        axiosInstance
          .post('/userEntity', [data])
          .then((response) => {
            updateProject({
              variables: {
                id: projectId,
                isShared: true
              }
            }).then((response) => {
              if (response?.data?.update_Project?.result?._id) {
                toast.dismiss(toastId);
                toast.success('Added To Workspace.');
                navigate('/projects');
              } else if (response?.errors) {
                toast.dismiss(toastId);
                toast.error('Something went wrong.');
              }
            });
          })
          .catch((error) => {
            console.error('ERROR', error);
            toast.dismiss(toastId);
            toast.error('Something went wrong.');
          })
          .finally(() => {
            setAddingToWorkspace(false);
          });
        //update project and make it isShared.:true
      }
    }
  };
  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Choose a project</PageTitle>
          <Small style={{ color: '#1C1C1C' }}>
            Select or create a project to which you want to add this worksplace.
          </Small>
        </TitleLeft>
        <TitleRight>
          <Button disabled={addingToWorkspace} onClick={addToWorkspace}>
            Add this workspace
          </Button>
        </TitleRight>
      </Header>
      <Container>
        <ChooseProjectList setProjectId={setProjectId}></ChooseProjectList>
        <CreateProjectAndAddWorkspace></CreateProjectAndAddWorkspace>
      </Container>
    </>
  );
}

export default ChooseWorkspaceInvite;
