import styled from '@emotion/styled';
import { Formik } from 'formik';
import Button, { CustomButton } from '../../components/CustomButton';
import FileTag from '../../components/FileTag';
import toast from 'react-hot-toast';
import OrganizationValidationSchema from '../../components/Validations/CreateOrganizationValidation';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { FieldSet, InputWrapper, FileUploadWrapper, FilePreview } from '../../components/styles/FormStyles';
import { TaskDetails } from '../Tasks/TaskViews/CreateTask';
import { getFileType } from '../../lib/getFileType';
import { GreyButton } from '../../components/styles/ButtonStyles';
import Compress from 'browser-image-compression';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import Loader from '../../components/Loader';
import { InputField } from '../../components/Reusable/NewFormik/Input1';
import useCreateOrganization from '../../hooks/features/organization/useCreateOrganization';

const Form = styled.form``;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #dbe2e0;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
  }
`;
export const ImgPreview = styled.div`
  .preview {
    width: 250px;
    position: relative;
    height: 200px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
`;

function CreateOrganization() {
  const {
    loading,
    isSubmitting,
    hiddenFileInput,
    handleFile,
    handleSubmit,
    initialValues,
    state,
    selectedImage,
    setSelectedImage
  } = useCreateOrganization();
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={OrganizationValidationSchema} onSubmit={handleSubmit}>
        {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {state?.orgId === 'null' || !state.orgId ? (
              <>
                <Header>
                  <HeaderLeft>
                    <BackIcon />
                    <NewTitleLeft>
                      <PageTitle>Create your Organization</PageTitle>
                    </NewTitleLeft>
                  </HeaderLeft>
                  <TitleRight>
                    <Button disabled={isSubmitting} type="submit">
                      Create
                    </Button>
                  </TitleRight>
                </Header>
                <TaskDetails>
                  <FieldSet>
                    <div>
                      <InputWrapper style={{ width: '40rem' }}>
                        <InputField
                          disabled={isSubmitting}
                          label="Organization Name"
                          name="organizationName"
                          id="Organization Name"
                          type="text"
                          onChange={handleChange}
                          touched={touched.organizationName}
                          errors={errors.organizationName}
                          value={values.organizationName}
                        />
                      </InputWrapper>
                      <FileUploadWrapper>
                        {values.file ? (
                          <FilePreview>
                            <FileTag>{getFileType(values.file.name)?.toUpperCase()}</FileTag>
                            <span className="file">{values.file.name}</span>
                            <ImgPreview>
                              <img className="preview" src={URL.createObjectURL(selectedImage)} alt="Thumb" />
                            </ImgPreview>

                            <GreyButton type="button" onClick={() => setFieldValue('file', '')} disabled={isSubmitting}>
                              Remove Photo <CloseIcon />
                            </GreyButton>
                          </FilePreview>
                        ) : (
                          <>
                            <GreyButton disabled={isSubmitting} type="button" onClick={handleFile}>
                              {' '}
                              Add Photo
                            </GreyButton>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              style={{ display: 'none' }}
                              ref={hiddenFileInput}
                              accept="image/*"
                              onChange={(event) => {
                                const image = event.currentTarget.files[0];
                                if (image?.size < 6000000) {
                                  const toastId = image && toast.loading('Uploading Image');
                                  const options = {
                                    // As the key specify the maximum size
                                    // Leave blank for infinity
                                    maxWidthOrHeight: 2000,
                                    maxSizeMB: 0.2,
                                    initialQuality: 0.6,
                                    // Use webworker for faster compression with
                                    // the help of threads
                                    useWebWorker: true
                                  };
                                  setSelectedImage(image);
                                  // Initialize compression
                                  // First argument is the file object from the input
                                  // Second argument is the options object with the config
                                  Compress(image, options).then((compressedBlob) => {
                                    toast.success('Uploaded', {
                                      id: toastId
                                    });
                                    compressedBlob.lastModifiedDate = new Date();

                                    // Conver the blob to file
                                    const convertedBlobFile = new File([compressedBlob], image.name, {
                                      type: image.type,
                                      lastModified: Date.now()
                                    });
                                    setFieldValue('file', convertedBlobFile);
                                  });
                                } else {
                                  image && toast.error('Image size is too large');
                                }
                              }}
                            />

                            {touched['file'] && errors['file'] ? (
                              <p style={{ color: 'red' }}>{errors['file']}</p>
                            ) : null}
                          </>
                        )}
                      </FileUploadWrapper>
                    </div>
                  </FieldSet>
                </TaskDetails>
              </>
            ) : (
              <Loader />
              // <Header>
              //   {/* <TitleLeft>
              //     <PageTitle>Organization is already created.</PageTitle>
              //   </TitleLeft>
              //   <TitleRight>
              //     <Button onClick={viewOrg}>View Organization</Button>
              //   </TitleRight> */}
              // </Header>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateOrganization;
