import styled from '@emotion/styled';
import { Amplify } from 'aws-amplify';
import { createRef, useContext, useEffect } from 'react';
import awsconfig from './aws-exports';
import Loader from './components/Loader';
import CookieAndPrivacyModal from './components/Modals/CookieAndPrivacyModal';
import LiveConnectionContext from './contexts/LiveConnectionContext';
import useSetUser from './hooks/common/useSetUser';
import useWebsocket from './hooks/common/useWebsocket';
import AppRoutes from './Routes';
import { TokenContext } from './TokenContext';
// If the check takes > 1 second, supported will be false and timedOut will be true
// import { OrgInvite } from './pages/Organization/orgInvite';
// import { ForceResetPassword } from './pages/customAuth/forceResetPassword';
// import _ from "lodash";
// import Lottie from "react-lottie";
// import { defaultOptions } from "./constants/LoaderDefaultOptions";
// import { useSidebar } from '../src/lib/SidebarState.js';
// import { BrowserRouter as Router, Navigate } from 'react-router-dom';
// import Sidebar from './components/Sidebar';
// import Header from './components/Header';
// import CustomLoginForm from './pages/customAuth/customLogin';
// import { CustomSignupForm } from './pages/customAuth/customSignup';
// import { CustomForgotPasswordForm } from './pages/customAuth/forgotPassword';
// import { CustomResetPasswordForm } from './pages/customAuth/resetPassword';
// import { CustomConfirmSignUpForm } from './pages/customAuth/customConfirmSignUp';
// import { Routes, Route } from 'react-router-dom';
// import { AppContext } from "./AppContext";
// import { Auth, Hub } from "aws-amplify";
// import { PinnedProjectsContext } from "./PinnedProjectContext";
// import axiosInstance from "./axiosInstance";
// import { useSelector } from "react-redux";
// import { ErrorPage } from "./pages/Others/ErrorPage";

Amplify.configure({ ...awsconfig, storage: sessionStorage });

export type AppStyleProps = {
  isOpen?: boolean;
};

export const AppStyles = styled.div<AppStyleProps>`
  margin-left: ${(props) => (props.isOpen ? '250px' : '45px')};
  transition: margin 500ms ease;
  overflow-x: hidden;
  padding-top: 70px;
  min-height: 100vh;
  /* padding: 70px 0; */
`;

const App = (): JSX.Element => {
  const { setToken } = useContext(TokenContext);
  const { user, loading, state } = useSetUser();
  const webSocket = useWebsocket(state);
  const imageRef = createRef(null);

  localStorage.clear();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setToken(params.get('token'));
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div ref={imageRef}>
        <LiveConnectionContext.Provider value={webSocket}>
          <AppRoutes user={user} />
        </LiveConnectionContext.Provider>
      </div>
      {user && <CookieAndPrivacyModal />}
    </>
  );
};

export default App;
