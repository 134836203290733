import { useLazyQuery, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { generateQuery } from '../../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { ProjectDropdownStyles } from '../../styles/ProjectDropdownStyles';

const FormControl = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 20px;

  .select {
    width: 70%;
    margin-left: 20px;
  }

  .projectSelect {
    width: 70%;
    margin-left: 50px;
  }
`;

export const getWorkspaces = async (projectId: string, userId: string) => {
  return axiosInstance
    .get(`/userEntity/?entityType=WORKSPACE&projectId=${projectId}&userId=${userId}`)
    .then((response) => {
      if (response.data.data && response.data.data.length > 0) {
        let entities = response.data.data;
        let Ids = entities.map((entity: any) => entity.entityId);
        return Ids;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.error('ERROR IN API', error);
      return error;
    });
};

const AddNewNoteModal = ({
  isOpen,
  toggleModal,
  toggleTemplateModal,
  selectedWorkspaceId,
  selectedProjectId
}: {
  isOpen: boolean;
  toggleModal: () => void;
  toggleTemplateModal: () => void;
  selectedWorkspaceId: MutableRefObject<string | null>;
  selectedProjectId: MutableRefObject<string | null>;
}) => {
  const { state } = useContext(AppContext);

  const [workspaceIds, setWorkspaceIds] = useState<string[]>([]);
  const batchGetWorkspaces = generateQuery(workspaceIds);
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Ref to workspace select
   * Used to clear selected workspace option if user changes the Project
   */
  const selectInputRef = useRef<any>();

  const [getAllWorkspaces, { data: WorkspaceData }] = useLazyQuery(batchGetWorkspaces, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (workspaceIds.length > 0) {
      getAllWorkspaces();
    }
  }, [workspaceIds]);

  const {
    data: projectData,
    error: projectsError,
    loading: projectsLoading
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    },
    fetchPolicy: 'network-only'
  });

  const projects = projectData?.list_ProjectItems._ProjectItems.map((data: any) => {
    return { ...data, label: data.name };
  });

  const workspaces = WorkspaceData
    ? Object.values(WorkspaceData).map((data: any) => {
        return { ...data, label: data.name };
      })
    : [];

  const initWorkspaceSelect = async (projectId: string) => {
    const id = toast.loading('Loading workspaces, please wait');
    const workspaceIds = await getWorkspaces(projectId, state.userId);
    toast.dismiss(id);

    if (workspaceIds.length) {
      setWorkspaceIds(workspaceIds);
    } else {
      toast.error('You are not part of any workspaces in this project');
      setWorkspaceIds([]);
    }
  };

  const onProjectChange = (options: any) => {
    if (!options) return;
    selectedProjectId.current = options._id;
    setWorkspaceIds([]);
    selectInputRef.current.select.clearValue();

    initWorkspaceSelect(options._id);
  };

  const onWorkspaceChange = (options: any) => {
    if (!options) return;
    selectedWorkspaceId.current = options._id;

    if (selectedWorkspaceId.current !== null) setIsDisabled(false);
    else setIsDisabled(true);
  };

  if (projectsLoading) {
    return null;
  }

  if (projectsError) {
    console.error(projectsError);
    toast.error('Something went wrong');
    return null;
  }
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Add New Note</ModalTitle>
        <ModalBody>
          <ModalText>Select project and workspace of the note</ModalText>
          {/* <ModalInput
            placeholder="Note Name"
            type="text"
            value={noteName}
            onChange={(e) => setNoteName(e.target.value)}
            disabled={false}
          /> */}
          <FormControl>
            Project
            <Select
              className="projectSelect"
              options={[...projects]}
              components={{ IndicatorSeparator: () => null }}
              styles={ProjectDropdownStyles}
              onChange={onProjectChange}
            />
          </FormControl>
          <FormControl>
            Workspace
            <Select
              ref={selectInputRef}
              className="select"
              components={{ IndicatorSeparator: () => null }}
              options={workspaces}
              styles={ProjectDropdownStyles}
              isDisabled={workspaceIds.length === 0 || state.orgId === 'null'}
              onChange={onWorkspaceChange}
            />
          </FormControl>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={isDisabled} onClick={toggleTemplateModal}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default AddNewNoteModal;
