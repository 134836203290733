import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as Menu } from '../../../../assets/icons/SidebarToggle.svg';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import { useQuery } from '@apollo/client';
import { SINGLE_FILE_QUERY } from '../../../../graphql/operations/Queries/Library/FilesQueries';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import NavigationLink from '../../../../components/NavigationLink';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 3.12rem;
  h2 {
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  small {
    padding-left: 3px;
  }
`;

const TitleRight = styled.div``;

export const PageNavStyles = styled.div`
  height: 2.68rem;
  a {
    text-decoration: none;
    color: var(--black);
    /* border: 1px solid red; */
    padding: 11px 50px;
  }
  display: flex;
  text-decoration: none;
  border-bottom: 2px solid var(--table-border);
  align-items: center;
  .active {
    border-bottom: 5px solid var(--text-secondary);
  }
`;

function ViewProjectFile() {
  let { fileId, projectId, workspaceId } = useParams();
  let navigate = useNavigate();
  const { data, error, loading } = useQuery(SINGLE_FILE_QUERY, {
    variables: { id: fileId, operation: 'query' }
  });
  const handleNavigate = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/library`);
  };
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  const { get_File } = data;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>{get_File.name}</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
      </Header>
      <PageNavStyles>
        <NavigationLink end to={`/project/workspace/${projectId}/${workspaceId}/library/files/${fileId}`}>
          About
        </NavigationLink>
        <NavigationLink to="workspacelinkedfiles">Links</NavigationLink>
        <NavigationLink to="workspacenotes">Notes</NavigationLink>
        {/* <NavigationLink to="discussion">Discussion</NavigationLink> */}
      </PageNavStyles>
      <Outlet />
    </>
  );
}

export default ViewProjectFile;
