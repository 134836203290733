export const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ["bold", "italic", "underline"],
    // [{ script: 'sub' }, { script: 'super' }],
    // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    // [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    [{ size: [] }],
    ["link"],
    // ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
};
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "script",
  "color",
  "background",
  "align",
];
