import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { SINGLE_TASK_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { LIST_WORKSPACE_QUERY } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { UPDATE_STATUS_TASK_MUTATION } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { postDashboardApi } from '../../../reducers/dashboard';

export const useGetTask = () => {
  let { taskId } = useParams();
  const dispatch = useDispatch();
  const { state } = useContext(AppContext);
  const location = useLocation();
  const usersLoading = useSelector((state) => state.orgMembers.loading);
  const allUsers = useSelector((state) => state.orgMembers.users);
  const ack = location?.state?.isAcknowledged || false;
  const { data, error, loading } = useQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    fetchPolicy: 'network-only'
  });
  const {
    data: projects,
    loading: projectLoading,
    error: projectError
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      noValidate: true
    }
  });
  const {
    data: workspaces,
    loading: workspaceLoading,
    error: workspaceError
  } = useQuery(LIST_WORKSPACE_QUERY, {
    variables: {
      noValidate: true
    }
  });

  const [updateTask] = useMutation(UPDATE_STATUS_TASK_MUTATION, {
    fetchPolicy: 'no-cache'
  });

  const getProjectName = (id: string) => {
    let found = projects?.list_ProjectItems._ProjectItems.find((project) => project._id === id);
    if (found) {
      return found.name;
    }

    return 'PERSONAL';
  };

  const getWorkspaceName = (id: string) => {
    let found = workspaces?.list_WorkspaceItems?._WorkspaceItems?.find((workspace) => workspace._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };

  const handleStatusChange = (statusValue: string, id: string) => {
    let messageType = statusValue === 'done' ? 'completed' : 'status';
    let actionType = statusValue === 'done' ? 'completed' : 'status';
    let userToNotify = actionType === 'completed' ? data?.get_Task?.assignee : data?.get_Task?.assignedTo;
    let variables =
      userToNotify !== state?.userId
        ? {
            status: statusValue,
            id: id,
            projectId: data?.get_Task?.projectId,
            type: data?.get_Task?.type,
            typeId: data?.get_Task?.typeId,
            notificationData: {
              actionType,
              messageType,
              taskName: data?.get_Task?.title,
              dueDate: data?.get_Task?.dueDate,
              workspaceName: getWorkspaceName(data?.get_Task?.typeId),
              projectName: getProjectName(data?.get_Task?.projectId),
              projectId: data?.get_Task?.projectId,
              workspaceId: data?.get_Task?.typeId,
              organizationId: state?.orgId,
              userToNotify,
              host: window.location.origin
            }
          }
        : {
            status: statusValue,
            id: id,
            projectId: data?.get_Task?.projectId,
            type: 'workspace',
            typeId: data?.get_Task?.typeId
          };
    updateTask({
      variables: variables
    });
  };

  const handlePriorityChange = async (priority: any, id: string, type: string, typeId: string) => {
    updateTask({
      variables: {
        priority: priority,
        id: id,
        type,
        typeId
      }
    });
  };

  useEffect(() => {
    let obj = {
      title: data?.get_Task?.title,
      status: data?.get_Task?.status,
      dueDate: data?.get_Task?.dueDate
    };
    return () => {
      // if (metaDataIds.TASKS.length === 0 || taskId !== metaDataIds.TASKS[0].id)

      // let TASKS = [...metaDataIds.TASKS]
      // TASKS.unshift({ id: taskId })
      if (data?.get_Task) {
        dispatch(
          postDashboardApi({
            type: 'metaData',
            moduleType: 'TASKS',
            orgId: state.orgId,
            metaDataId: taskId,
            metaData: { _id: taskId, ...obj }
          })
        );
      }
    };
  }, [data]);

  return {
    data,
    error,
    loading,
    projectLoading,
    projectError,
    workspaceLoading,
    usersLoading,
    workspaceError,
    getProjectName,
    getWorkspaceName,
    handleStatusChange,
    handlePriorityChange,
    allUsers,
    ack,
    state
  };
};
