import React, { useMemo, useState, useEffect, useContext, useReducer } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import Select from 'react-select';
import styled from '@emotion/styled';
import Table from './Table';
import Button from './CustomButton';
import TableDropDown from './TableComponents/TableDropDown';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../lib/SidebarState';
import { AppStyleProps } from '../App';
import { AppContext } from '../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../constants/LoaderDefaultOptions';
import { columnsFromBackend } from '../constants/KanbanData';
import WorkspaceKanban from './KanbanComponents/WorkspaceKanban';
import { editTaskOptions } from '../constants/DropDownOptions';
import { tableStatusStyles } from '../components/styles/StatusStyles';
import { HighPriority, MediumPriority, LowPriority } from './TableComponents/Priority';
import { useLocation } from 'react-router';
import { ReactComponent as TodoIcon } from '../assets/icons/todoicon.svg';
import { ReactComponent as KanbanIcon } from '../assets/icons/kanbanIcon.svg';
import { ErrorPage } from '../pages/Others/ErrorPage';
import { taskOptions } from '../constants/TasksDropdown';
import { taskDropdownStyles } from './styles/TaskDropdownStyles';
import { LIST_TASKS_QUERY } from '../graphql/operations/Queries/Tasks/TaskQueries';
import { LIST_PROJECTS_QUERY } from '../graphql/operations/Queries/Projects/ProjectQueries';
import { UPDATE_STATUS_TASK_MUTATION } from '../graphql/operations/Mutations/Tasks/TaskMutations';
import { LIST_WORKSPACE_QUERY } from '../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import axiosInstance from '../axiosInstance';
import getUtcDate from '../lib/getUtcDate';
// import * as LottiePlayer from "@lottiefiles/lottie-player";
// import { projectDetails } from "../graphql/reactivities/project.js";
// import CustomAvatar from "./TableComponents/CustomAvatar";
// import animationData from '../assets/lotties/loader.json'

export const isOverdue = (dueDate: any) => {
  const today = new Date();
  let someDate = new Date(dueDate);
  return (
    (someDate.getDate() < today.getDate() &&
      someDate.getMonth() <= today.getMonth() &&
      someDate.getFullYear() <= today.getFullYear()) ||
    (someDate.getDate() > today.getDate() &&
      someDate.getMonth() < today.getMonth() &&
      someDate.getFullYear() <= today.getFullYear()) ||
    (someDate.getDate() === today.getDate() &&
      someDate.getMonth() <= today.getMonth() &&
      someDate.getFullYear() < today.getFullYear())
  );
};

export const isToday = (dueDate: any) => {
  const today = new Date();
  let someDate = new Date(dueDate);
  //check if its today
  if (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  ) {
    return true;
  }
  //check if its overdue
  //same month and year but day is lesser than today
  else if (
    someDate.getDate() < today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  ) {
    return true;
  }
  //month is lesser and year is less than today but day is greater than or equal to today
  else if (
    someDate.getDate() > today.getDate() &&
    someDate.getMonth() < today.getMonth() &&
    someDate.getFullYear() <= today.getFullYear()
  ) {
    return true;
  } else if (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() < today.getMonth() &&
    someDate.getFullYear() <= today.getFullYear()
  ) {
    return true;
  } else if (
    someDate.getDate() > today.getDate() &&
    someDate.getMonth() > today.getMonth() &&
    someDate.getFullYear() <= today.getFullYear()
  ) {
    return true;
  }

  // return someDate.getDate() <= today.getDate() &&
  //   someDate.getMonth() <= today.getMonth() &&
  //   someDate.getFullYear() <= today.getFullYear()
};

export const isMonth = (dueDate: any) => {
  const today = new Date();
  let someDate = new Date(dueDate);
  return (
    someDate.getDate() > today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isUpcoming = (dueDate: any) => {
  const today = new Date();
  let someDate = new Date(dueDate);
  // if (someDate.getDate() != today.getDate() && someDate.getMonth() != today.getMonth() && someDate.getFullYear() >= today.getFullYear()) {
  //   return true
  // }
  // else if (someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() > today.getFullYear()) {
  //   return true
  // }
  if (someDate.getMonth() > today.getMonth() && someDate.getFullYear() >= today.getFullYear()) {
    return true;
  } else if (someDate.getMonth() <= today.getMonth() && someDate.getFullYear() > today.getFullYear()) {
    return true;
  }
  return false;
};

export const TaskStyles = styled.div<AppStyleProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .tasks-header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .tasks-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    svg {
      display: block;
      margin-left: 20px;
      margin-top: 1px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &:after {
    content: '';
    background: var(--border-color);
    position: absolute;
    height: 1px;
    width: ${(props) => (props.isOpen ? '100%' : '100%')};
    top: 100%;
  }
  .add-task {
    display: inline-block;
  }
  .task-dropdown {
    margin-left: 1.5rem;
    width: 180px;
  }
`;
export const DropdownDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .drop-item {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  table {
    tr {
      td {
        .text-wrap {
          inline-size: 120px;
          overflow-wrap: break-word;
        }
      }
    }
  }
`;

export const getValue = (options, value: string) => {
  return options.find((option) => option.value === value);
};

const initialState: any = {};
export const reducer = (state, action) => {
  switch (action.type) {
    case 'TABLE':
      let object = {
        today: [],
        month: [],
        upcoming: [],
        done: []
      };
      object.today = action.data.filter((task) => isToday(task.dueDate)).filter((task) => task.status !== 'done');
      object.month = action.data.filter((task) => isMonth(task.dueDate)).filter((task) => task.status !== 'done');
      object.upcoming = action.data.filter((task) => isUpcoming(task.dueDate)).filter((task) => task.status !== 'done');
      object.done = action.data.filter((task) => task.status === 'done');
      return object;

    case 'UPDATESTATUS':
      let flattenedState = [...state.today, ...state.month, ...state.upcoming, ...state.done];
      let updatedTasks = flattenedState.map((task) => {
        if (task._id !== action.data.id) {
          return { ...task };
        } else if (task._id === action.data.id) {
          return { ...task, status: action.data.status };
        }
      });
      let updatedObject = {
        today: [],
        month: [],
        upcoming: [],
        done: []
      };
      updatedObject.today = updatedTasks
        .filter((task) => task.status !== 'done')
        .filter((task) => isToday(task.dueDate));
      updatedObject.month = updatedTasks
        .filter((task) => task.status !== 'done')
        .filter((task) => isMonth(task.dueDate));
      updatedObject.upcoming = updatedTasks
        .filter((task) => task.status !== 'done')
        .filter((task) => isUpcoming(task.dueDate));
      updatedObject.done = updatedTasks.filter((task) => task.status === 'done');
      return updatedObject;

    case 'KANBAN':
      let newstate = [...state.today, ...state.month, ...state.upcoming, ...state.done];
      let inProgress = newstate.filter((task) => task.status === 'inProgress');
      let todo = newstate.filter((task) => task.status === 'todo');
      let done = newstate.filter((task) => task.status === 'done');
      let result = Object.entries(columnsFromBackend).map((item: any) => {
        if (item[1].title === 'In Progress') {
          item[1].items = [...inProgress];
          return item;
        } else if (item[1].title === 'To-do') {
          item[1].items = [...todo];
          return item;
        } else {
          item[1].items = [...done];
          return item;
        }
      });
      return Object.fromEntries(result);
    case 'REORDER':
      return action.data;
    case 'BACKTOTABLE':
      let todoitems = state.todo.items.map((task) => {
        return { ...task, status: 'todo' };
      });
      let inProgressTasks = state.inProgress.items.map((task) => {
        return { ...task, status: 'inProgress' };
      });
      let doneTasks = state.done.items.map((task) => {
        return { ...task, status: 'done' };
      });
      let newarr = [...todoitems, ...inProgressTasks, ...doneTasks];
      let newobject = {
        today: [],
        month: [],
        upcoming: [],
        done: []
      };
      newobject.today = newarr.filter((task) => isToday(task.dueDate)).filter((task) => task.status !== 'done');
      newobject.month = newarr.filter((task) => isMonth(task.dueDate)).filter((task) => task.status !== 'done');
      newobject.upcoming = newarr.filter((task) => isUpcoming(task.dueDate)).filter((task) => task.status !== 'done');
      newobject.done = newarr.filter((task) => task.status === 'done');
      return newobject;
    // return [...action.data]
    default:
      return initialState;
  }
};
const Tasks = () => {
  const { state } = useContext(AppContext);
  const { state: taskState } = useLocation();
  const [toggleKanban, setToggleKanban] = useState(false);
  const { sidebarOpen } = useSidebar();
  const navigate = useNavigate();
  const [taskdata, dispatch] = useReducer(reducer, initialState);
  const {
    data: apidata,
    error,
    loading,
    refetch
  } = useQuery(LIST_TASKS_QUERY, {
    variables: {
      assignee: '',
      assignedTo: state.userId,
      organizationId_projectId_typeId: state.orgId
    },
    onCompleted: (data) => {
      if (taskState) {
        console.log('INSIDE TASK STATE', taskState.task);
        if (taskState.assignedTo === state.userId) {
          handleTaskChange(taskOptions[0]);
        } else {
          handleTaskChange(taskOptions[1]);
        }
        let found = data.list_TaskItems._TaskItems.some((task) => task._id === taskState._id);
        if (!found) {
          if (taskState.assignedTo === state.userId) {
            let newarr = [...data.list_TaskItems._TaskItems, taskState];
            dispatch({
              type: 'TABLE',
              data: newarr
            });
          } else {
            dispatch({
              type: 'TABLE',
              data: data.list_TaskItems._TaskItems
            });
          }
        } else {
          let newarr = [...data.list_TaskItems._TaskItems];
          const indexOfItemInArray = newarr.findIndex((task) => task._id === taskState._id);
          newarr.splice(indexOfItemInArray, 1, taskState);
          dispatch({
            type: 'TABLE',
            data: newarr
          });
        }
      } else {
        dispatch({
          type: 'TABLE',
          data: data.list_TaskItems._TaskItems
        });
      }
    },
    fetchPolicy: 'network-only'
  });

  const [filterTasks] = useLazyQuery(LIST_TASKS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!toggleKanban) {
        dispatch({
          type: 'TABLE',
          data: data?.list_TaskItems._TaskItems
        });
      } else if (toggleKanban) {
        let object = {
          today: [],
          month: [],
          upcoming: [],
          done: []
        };
        object.today = data?.list_TaskItems._TaskItems
          .filter((task) => isToday(task.dueDate))
          .filter((task) => task.status !== 'done');
        object.month = data?.list_TaskItems._TaskItems
          .filter((task) => isMonth(task.dueDate))
          .filter((task) => task.status !== 'done');
        object.upcoming = data?.list_TaskItems._TaskItems
          .filter((task) => isUpcoming(task.dueDate))
          .filter((task) => task.status !== 'done');
        object.done = data?.list_TaskItems._TaskItems.filter((task) => task === 'done');
        dispatch({
          type: 'TABLE',
          data: data?.list_TaskItems._TaskItems
        });
        dispatch({
          type: 'KANBAN'
        });
      }
    }
  });

  const {
    data: projects,
    loading: projectLoading,
    error: projectError
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    },
    fetchPolicy: 'network-only'
  });
  const {
    data: workspaces,
    loading: workspaceLoading,
    error: workspaceError
  } = useQuery(LIST_WORKSPACE_QUERY, { fetchPolicy: 'network-only' });

  const [updateTask] = useMutation(UPDATE_STATUS_TASK_MUTATION, {
    fetchPolicy: 'no-cache'
  });
  const updateStatus = async (selectedOption: any, id: string, data) => {
    let actionType = selectedOption.value === 'done' ? 'completed' : 'status';
    let messageType = selectedOption.value === 'done' ? 'completed' : 'status';
    let userToNotify = selectedOption.value === 'done' ? data?.assignee : data?.assignedTo;
    let variables =
      userToNotify !== state?.userId
        ? {
            status: selectedOption.value,
            id: id,
            type: data?.type,
            typeId: data?.typeId,
            notificationData: {
              actionType,
              messageType,
              taskName: data?.title,
              dueDate: data?.dueDate,
              workspaceName: getWorkspaceName(data?.typeId),
              projectName: getProjectName(data?.projectId),
              workspaceId: data?.typeId,
              projectId: data?.projectId,
              organizationId: state?.orgId,
              userToNotify: userToNotify,
              host: window.location.origin,
              status: selectedOption.value,
              assignedToName: data?.assignedToDetails.name
            }
          }
        : { status: selectedOption.value, id: id, type: data?.type, typeId: data?.typeId };
    updateTask({
      variables: variables
    }).then(async (res) => {
      dispatch({
        type: 'UPDATESTATUS',
        data: { id: id, status: selectedOption.value }
      });
    });
  };

  const handleTaskChange = async (option: { value: string; label: string }) => {
    if (option.value === 'assignedtask') {
      let res = await filterTasks({
        variables: {
          assignedTo: state.userId,
          assignee: '',
          organizationId_projectId_typeId: state.orgId
        }
      });
    } else if (option.value === 'delegatedtask') {
      let res = await filterTasks({
        variables: {
          assignee: state.userId,
          assignedTo: '',
          organizationId_projectId_typeId: state.orgId
        }
      });
    }
  };

  //these 2 functions are kind of doing the same . so have to optimise later.
  const getProjectName = (id: string) => {
    let found = projects.list_ProjectItems._ProjectItems.find((project) => project._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };

  const getWorkspaceName = (id: string) => {
    let found = workspaces?.list_WorkspaceItems?._WorkspaceItems.find((workspace) => workspace._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Task',
        accessor: 'title',
        Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
      },
      {
        Header: 'Project',
        accessor: 'projectId',
        Cell: ({ cell: { value } }) => {
          return getProjectName(value);
        }
      },
      {
        Header: 'Workspace',
        accessor: 'typeId',
        Cell: ({ cell: { value } }) => getWorkspaceName(value, workspaces)
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { row, value } }) => {
          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Select
                name="status"
                options={editTaskOptions}
                defaultValue={getValue(editTaskOptions, value)}
                styles={{ ...tableStatusStyles }}
                width="200px"
                menuPortalTarget={document.body}
                onChange={(selectedOption) => {
                  updateStatus(selectedOption, row.original._id, row.original);
                }}
              />
            </span>
          );
        }
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ cell: { value } }) =>
          value === 'high' ? <HighPriority /> : value === 'medium' ? <MediumPriority /> : <LowPriority />
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ cell: { value } }) => <span>{getUtcDate(value)}</span>
      }
    ],
    [projects, workspaces, taskdata]
  );
  const data = useMemo(() => taskdata, [taskdata]);
  useEffect(() => {
    refetch();
  }, []);
  const viewTask = (id: string) => {
    navigate(`/dashboard/tasks/${id}`);
  };
  const createTask = () => {
    navigate('/dashboard/tasks/new');
  };
  const toggleAndFetch = (toggleState: boolean) => {
    if (toggleState) {
      dispatch({
        type: 'KANBAN'
      });
    }
    if (!toggleState) {
      dispatch({
        type: 'BACKTOTABLE',
        data: apidata.list_TaskItems._TaskItems
      });
    }
    setToggleKanban(toggleState);
  };

  if (loading || projectLoading || workspaceLoading)
    return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error || projectError || workspaceError) return <ErrorPage />;
  return (
    <div>
      <TaskStyles isOpen={sidebarOpen}>
        <div className="tasks-header">
          <h4 className="tasks-title">To-do List</h4>
          <Select
            options={taskOptions}
            name="tasks"
            styles={taskDropdownStyles}
            isSearchable={false}
            // defaultValue={taskOptions[0]}
            defaultValue={
              taskState ? (taskState.assignedTo === state.userId ? taskOptions[0] : taskOptions[1]) : taskOptions[0]
            }
            onChange={(selectedOption) => {
              handleTaskChange(selectedOption);
            }}
            className="task-dropdown"
          />
          {!toggleKanban && <TodoIcon onClick={() => toggleAndFetch(true)} />}
          {toggleKanban && <KanbanIcon onClick={() => toggleAndFetch(false)} />}
        </div>
        <Button onClick={createTask}>+ New Task</Button>
      </TaskStyles>
      {!toggleKanban && (
        <DropdownDiv>
          <div className="drop-item">
            <TableDropDown state={true} text="Today">
              {data.today && (
                <Table
                  columns={columns}
                  data={data?.today}
                  clickHandler={viewTask}
                  getRowProps={(row) => {
                    return {
                      style: {
                        color: isOverdue(row.values.dueDate) && row.values.status !== 'done' ? '#D14747' : 'black'
                      }
                    };
                  }}
                />
              )}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={true} text="This Month">
              {data.month && <Table columns={columns} data={data?.month} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={data?.upcoming?.length > 0 && true} text="Coming Up">
              {data.upcoming && <Table columns={columns} data={data?.upcoming} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={data?.done?.length > 0 && true} text="Completed">
              {data.done && <Table columns={columns} data={data?.done} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
          {/*  */}
        </DropdownDiv>
      )}
      {toggleKanban && <WorkspaceKanban columns={taskdata} setColumns={dispatch} updateTask={updateTask} />}
    </div>
  );
};

export default Tasks;
