import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { default as dayjs } from 'dayjs';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import axiosInstance from '../axiosInstance';
import {
  ConversationDataI,
  ConversationMessages,
  Conversations,
  CurrentConversationDetailsI,
  LastKey,
  Member,
  Messages,
  TimeLine
} from './../components/Chat/types';
import { recordStatus } from './../constants/RecordStatus';

interface InitialStateI {
  loading: boolean;
  error: null | string;
  orgData: Conversations;
  nonOrgData: Conversations;
  selectedConversationId: null | string;
  currentConversationDetails: CurrentConversationDetailsI | null;
  allMessages: ConversationMessages; //All Loaded Messages
  selectedMessage: Messages | null;
  currentMessagesLoading: boolean;
  currentTimeLineIndex?: number;
  unreadConversations: number;
}

const initialState: InitialStateI = {
  loading: false,
  error: null,
  orgData: {},
  nonOrgData: {},
  currentConversationDetails: null,
  selectedConversationId: null,
  allMessages: {},
  currentMessagesLoading: false,
  selectedMessage: null,
  currentTimeLineIndex: 0,
  unreadConversations: 0
};

export const fetchConversations = createAsyncThunk('conversations/fetchConversations', async () => {
  const data = await axiosInstance.get('/conversations');
  return data.data;
});

export const fetchMessages = createAsyncThunk(
  'conversations/fetchMessages',
  async ({
    conversationId,
    conversationType,
    timeLine
  }: {
    conversationId: string;
    conversationType: 'individual' | 'group';
    timeLine?: TimeLine[];
  }) => {
    let params = '';
    if (conversationType === 'group' && timeLine) {
      if (timeLine[0].joined) params += `&joined=${timeLine[0].joined}`;
      if (timeLine[0].removed) params += `&removed=${timeLine[0].removed}`;
      else params += `&removed=${new Date().toISOString()}`;
    }
    const data = await axiosInstance.get(`/conversations/${conversationId}?count=30${params}`);
    return { ...data?.data, conversationId } as {
      lastKey: null | LastKey;
      memberData: Member[];
      messages: Messages;
      conversationId: string;
    };
  }
);

export const fetchRemainingMessages = createAsyncThunk(
  'conversations/fetchRemainingMessages',
  async ({ conversationId, lastKey }: { conversationId: string; lastKey: LastKey }) => {
    const data = await axiosInstance.get(
      `/conversations/${conversationId}?lastKeyId=${lastKey.id}&lastConversationId=${lastKey.conversationId}&createdAt=${lastKey.createdAt}&count=30`
    );
    return { ...data?.data, conversationId } as {
      lastKey: null | LastKey;
      memberData: Member[];
      messages: Messages;
      conversationId: string;
    };
  }
);

export const fetchRemainingGroupMessages = createAsyncThunk(
  'conversations/fetchRemainingGroupMessages',
  async ({
    conversationId,
    lastKey,
    timeLine,
    currentTimeLineIndex
  }: {
    conversationId: string;
    lastKey: LastKey | null;
    timeLine: TimeLine[];
    currentTimeLineIndex: number;
  }) => {
    if (timeLine.length < currentTimeLineIndex) return;

    if (!lastKey) return;

    let params = '';
    if (timeLine[currentTimeLineIndex].joined) params += `&joined=${timeLine[currentTimeLineIndex].joined}`;
    if (timeLine[currentTimeLineIndex].removed) params += `&removed=${timeLine[currentTimeLineIndex].removed}`;
    else params += `&removed=${new Date().toISOString()}`;
    if (lastKey)
      params += `&lastKeyId=${lastKey.id}&lastConversationId=${lastKey.conversationId}&createdAt=${lastKey.createdAt}`;

    const data = await axiosInstance.get(`/conversations/${conversationId}?count=30${params}`);
    console.log(data);
    return { ...data?.data, conversationId, currentTimeLineIndex } as {
      lastKey: null | LastKey;
      memberData: Member[];
      messages: Messages;
      conversationId: string;
      currentTimeLineIndex: number;
    };
  }
);

//bring a key to top
const bringKeyToTop = (object: any, key: string) => {
  const temp = object.key;
  delete object.key;
  return { [key]: temp, ...object };
};

//Not Thunk Function
export const createConversation = async (data: any) => {
  const res = await axiosInstance.post('/conversations', data);
  if (res.data.chatDetails) return res.data.chatDetails;
  else return {};
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: initialState,
  reducers: {
    switchChat: (state, action: PayloadAction<ConversationDataI>) => {
      const { id, sameOrg } = action.payload;
      state.selectedConversationId = id;
      const allConvs = { ...state.orgData, ...state.nonOrgData };
      const currentConversation = allConvs[id][0];

      state.currentConversationDetails = {
        name: currentConversation.conversationName,
        profilePic: currentConversation.profilePhoto,
        type: currentConversation.conversationType,
        recordStatus: currentConversation.recordStatus
      };

      //If the conversation is blocked, add blockedBy to conversationDetails
      if (currentConversation.hasOwnProperty('blockedBy'))
        state.currentConversationDetails.blockedBy = currentConversation.blockedBy;

      //When you open messages -> Read all messages
      if (sameOrg) state.orgData[id][0].unreadMessage = 0;
      else state.nonOrgData[id][0].unreadMessage = 0;

      state.unreadConversations -= 1;
    },
    appendConversation: (state, action) => {
      const id = action.payload.id;
      if (action.payload.sameOrg) {
        state.orgData = Object.assign({ [id]: [action.payload] }, state.orgData);
      } else {
        state.nonOrgData = Object.assign({ [id]: [action.payload] }, state.nonOrgData);
      }
    },
    readMessage: (state, action) => {
      const allChats = { ...state.orgData, ...state.nonOrgData };
      if (allChats[action.payload][0].sameOrg) state.orgData[action.payload][0].unreadMessage = 0;
      else state.nonOrgData[action.payload][0].unreadMessage = 0;

      state.unreadConversations -= 1;
    },
    incomingMessage: (state, action: PayloadAction<IncomingMessageActionI>) => {
      const { data: message, currentUser } = action.payload;

      state.unreadConversations += 1;

      const date = dayjs(message.createdAt).format('MM/DD/YYYY');

      //Update allMessages if it's loaded, else ignore
      if (state.allMessages.hasOwnProperty(message.conversationId)) {
        /**
         * Check if message exists 
         * This case occurs when same user logged in different device 
         */
        const allDates = Object.keys(state.allMessages[message.conversationId].messages)
        const lastKey = allDates.pop()
        if (lastKey) {
          const messages = state.allMessages[message.conversationId].messages[lastKey]
          const lastMessageId = messages[messages.length - 1].id
          if (lastMessageId === message.id) return
        }

        /*
          Add the message to correct date {conId: {date1:[messages...],...}}
          If message with that date doesn't exist, create one
        */
        if (state.allMessages[message.conversationId].messages.hasOwnProperty(date)) {
          state.allMessages[message.conversationId].messages[date].push(message);
        } else {
          /* 
            Messages with this date does not exist
            Create key with date
          */
          const messagesWithNewDate = { [date]: new Array(message) };
          state.allMessages[message.conversationId].messages = {
            ...state.allMessages[message.conversationId].messages,
            ...messagesWithNewDate
          };
        }
      }
      //Also update in left panel
      //Update the orgData/nonOrgData state
      //Also Set this conversation to top
      if (message.sameOrg) {
        state.orgData[message.conversationId][0].latestMessage = message.message;
        state.orgData[message.conversationId][0].latestMessageTimestamp = message.createdAt;
        state.orgData[message.conversationId][0].latestMessageSender = message.sentBy;
        state.orgData = bringKeyToTop(state.orgData, message.conversationId);
      } else {
        state.nonOrgData[message.conversationId][0].latestMessage = message.message;
        state.nonOrgData[message.conversationId][0].latestMessageTimestamp = message.createdAt;
        state.nonOrgData[message.conversationId][0].latestMessageSender = message.sentBy;
        state.nonOrgData = bringKeyToTop(state.nonOrgData, message.conversationId);
      }

      //If the message is from other user -> Increment number of unread Messages
      if (message.sentBy !== currentUser) {
        if (message.sameOrg) {
          state.orgData[message.conversationId][0].unreadMessage += 1;
        } else state.nonOrgData[message.conversationId][0].unreadMessage += 1;
      }
    },
    continueConversation: (state, action: PayloadAction<ContinueConversationActionI>) => {
      const { sameOrg, conversationId } = action.payload;
      if (sameOrg) {
        state.orgData[conversationId][0].recordStatus = 0;
        // if(state.orgData[conversationId][0].hasOwnProperty('block'))
      } else state.nonOrgData[conversationId][0].recordStatus = 0;

      //If the chat is open, update currentConversationDetails
      if (state.currentConversationDetails) {
        state.currentConversationDetails.recordStatus = 0;
        delete state.currentConversationDetails.blockedBy; //Not necessary to do this. Just fancy
      }
    },
    blockConversation: (state, action: PayloadAction<BlockConversationActionI>) => {
      const { sameOrg, conversationId, blockedBy } = action.payload;

      if (sameOrg) {
        state.orgData[conversationId][0].recordStatus = 3;
        state.orgData[conversationId][0].blockedBy = blockedBy;
      } else {
        state.nonOrgData[conversationId][0].recordStatus = 3;
        state.nonOrgData[conversationId][0].blockedBy = blockedBy;
      }

      //If the chat is open, update currentConversationDetails
      if (state.currentConversationDetails) {
        state.currentConversationDetails.recordStatus = 3;
        state.currentConversationDetails.blockedBy = blockedBy;
      }
    },
    leaveGroup: (state, action: PayloadAction<LeaveGroupActionI>) => {
      const {
        data: { conversationId, sentBy },
        currentUser
      } = action.payload;

      //If current user leave change the record status
      if (sentBy === currentUser) {
        state.orgData[conversationId][0].recordStatus = recordStatus.REMOVED;
        if (state.currentConversationDetails) {
          state.currentConversationDetails.recordStatus = recordStatus.REMOVED;
        }
      } else {
        //Remove member from the group
        if (state.allMessages.hasOwnProperty(conversationId)) {
          const date = dayjs().format('MM/DD/YYYY');

          //Also add a message to the chat if it's open
          if (state.allMessages.hasOwnProperty(conversationId)) {
            const message = {
              mediaType: 'text',
              hasRead: true,
              sentBy,
              updatedAt: date,
              messageType: 'remove_user',
              createdAt: date,
              message: 'User left the chat',
              conversationId,
              id: uuid(),
              removedOrAddedUserId: sentBy,
              mediaLink: null
            };
            if (state.allMessages[conversationId].messages.hasOwnProperty(date)) {
              state.allMessages[conversationId].messages[date].push(message);
            } else {
              state.allMessages[conversationId].messages[date] = [message];
            }
          }
        }
      }
    },
    updateGroupMember: (state, action: PayloadAction<UpdateGroupMemberActionI>) => {
      const {
        data: { conversationId, selected, added, deleted, sentBy },
        currentUser
      } = action.payload;
      //If you are part of the conversation, set removed status
      if (deleted.includes(currentUser)) {
        state.orgData[conversationId][0].recordStatus = 3;

        if (
          state.allMessages.hasOwnProperty(conversationId) &&
          conversationId === state.selectedConversationId &&
          state.currentConversationDetails
        ) {
          state.currentConversationDetails.recordStatus = 3;
        }
      }

      //If you are part of the conversation, reset status if added back
      if (added.includes(currentUser)) {
        state.orgData[conversationId][0].recordStatus = 0;

        if (
          state.allMessages.hasOwnProperty(conversationId) &&
          conversationId === state.selectedConversationId &&
          state.currentConversationDetails
        ) {
          state.currentConversationDetails.recordStatus = 0;
        }
      }

      //Remove members from the state
      if (state.allMessages.hasOwnProperty(conversationId)) state.allMessages[conversationId].members = selected;

      const date = dayjs().format('MM/DD/YYYY');

      //If User Added, add 'user joined' message
      added.map((id) => {
        const message = {
          mediaType: 'text',
          hasRead: true,
          sentBy,
          updatedAt: date,
          messageType: 'add_user',
          createdAt: date,
          message: 'User joined the chat',
          conversationId,
          id: uuid(),
          removedOrAddedUserId: id,
          mediaLink: null
        };
        if (state.allMessages[conversationId].messages.hasOwnProperty(date)) {
          state.allMessages[conversationId].messages[date].push(message);
        } else {
          state.allMessages[conversationId].messages[date] = [message];
        }

        return null; //Added To remove ts no return error
      });

      //If Users were removed, send user removed message
      deleted.map((id) => {
        const message = {
          mediaType: 'text',
          hasRead: true,
          sentBy,
          updatedAt: date,
          messageType: 'remove_user',
          createdAt: date,
          message: 'User left the chat',
          conversationId,
          id: uuid(),
          removedOrAddedUserId: id,
          mediaLink: null
        };
        if (state.allMessages[conversationId].messages.hasOwnProperty(date)) {
          state.allMessages[conversationId].messages[date].push(message);
        } else {
          state.allMessages[conversationId].messages[date] = [message];
        }

        return null; //Added To remove ts no return error
      });
    },
    cleanUp: (state) => {
      state = initialState;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state, action) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.loading = false;
        state.orgData = action.payload.orgData;
        state.nonOrgData = action.payload.outsideOrgData;
        state.unreadConversations = action.payload.unreadConversations;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
      .addCase(fetchMessages.pending, (state, action) => {
        state.currentMessagesLoading = true;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { conversationId, memberData, messages, lastKey } = action.payload;
        //Add the new message to allMessage
        state.allMessages[conversationId] = {
          messages: messages,
          lastKey: lastKey,
          isLoadingMore: false,
          members: memberData
        };

        // If  group chat -> Add timeline to messages
        //Init timelineIndex to 0
        if (
          state.orgData.hasOwnProperty(conversationId) &&
          state.orgData[conversationId][0].conversationType === 'group'
        ) {
          state.allMessages[conversationId].timeLine = state.orgData[conversationId][0]?.timeLine;
          state.allMessages[conversationId].currentTimeLineIndex = 0;
        }
        state.currentMessagesLoading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.currentMessagesLoading = false;
        state.error = action.error.message as string;
      })
      .addCase(fetchRemainingMessages.pending, (state, action) => {
        //We will always have selected conversation -> Adding if only to remove ts error
        if (!state.selectedConversationId) return;
        state.allMessages[state.selectedConversationId].isLoadingMore = true;
      })
      .addCase(fetchRemainingMessages.fulfilled, (state, action) => {
        const { conversationId, lastKey, messages } = action.payload;
        state.allMessages[conversationId].lastKey = lastKey;

        const sameDate = _.intersection(Object.keys(messages), Object.keys(state.allMessages[conversationId].messages));
        /**
         * If same date -> There can only be one same date
         * ie lastKey of newly loaded messages and first key of already loaded messages
         */
        if (sameDate.length > 0) {
          let date = sameDate[0];
          /**
           * Get data from newly loaded message with that date
           */
          const newMessages = messages[date];
          /**
           * Get data from old messages with same date
           */
          const oldMessages = state.allMessages[conversationId].messages[date];

          //Merge both messages
          const tempMessages = [...newMessages, ...oldMessages];

          /**
           * Delete the messages with this date from both
           */
          delete messages[date];
          delete state.allMessages[conversationId].messages[date];

          /**
           * Merge all three
           * Newly-Loaded-Messages + Temp-Message (Merged which has same date key) + All-State-Messages
           */
          state.allMessages[conversationId].messages = {
            ...messages,
            [date]: tempMessages,
            ...state.allMessages[conversationId].messages
          };
        } else {
          state.allMessages[conversationId].messages = { ...messages, ...state.allMessages[conversationId].messages };
        }

        state.allMessages[conversationId].isLoadingMore = false;
      })
      .addCase(fetchRemainingMessages.rejected, (state, action) => {
        if (!state.selectedConversationId) return;
        state.allMessages[state.selectedConversationId].isLoadingMore = false;
      })
      .addCase(fetchRemainingGroupMessages.pending, (state, action) => {
        if (!state.selectedConversationId) return;
        state.allMessages[state.selectedConversationId].isLoadingMore = true;
      })
      .addCase(fetchRemainingGroupMessages.fulfilled, (state, action) => {
        const data = action.payload;

        if (!state.selectedConversationId) return;

        state.allMessages[state.selectedConversationId].isLoadingMore = false;
        if (!data) return;

        const { messages, conversationId, lastKey, currentTimeLineIndex } = data;

        state.allMessages[conversationId].lastKey = lastKey;

        if (!lastKey) state.allMessages[conversationId].currentTimeLineIndex = currentTimeLineIndex + 1;

        const sameDate = _.intersection(Object.keys(messages), Object.keys(state.allMessages[conversationId].messages));
        /**
         * If same date -> There can only be one same date
         * ie lastKey of newly loaded messages and first key of already loaded messages
         */
        if (sameDate.length > 0) {
          let date = sameDate[0];
          /**
           * Get data from newly loaded message with that date
           */
          const newMessages = messages[date];
          /**
           * Get data from old messages with same date
           */
          const oldMessages = state.allMessages[conversationId].messages[date];

          //Merge both messages
          const tempMessages = [...newMessages, ...oldMessages];

          /**
           * Delete the messages with this date from both
           */
          delete messages[date];
          delete state.allMessages[conversationId].messages[date];

          /**
           * Merge all three
           * Newly-Loaded-Messages + Temp-Message (Merged which has same date key) + All-State-Messages
           */
          state.allMessages[conversationId].messages = {
            ...messages,
            [date]: tempMessages,
            ...state.allMessages[conversationId].messages
          };
        } else {
          state.allMessages[conversationId].messages = { ...messages, ...state.allMessages[conversationId].messages };
        }
      })
      .addCase(fetchRemainingGroupMessages.rejected, (state, action) => {
        state.allMessages[state.selectedConversationId!].isLoadingMore = false;
      });
  }
});

export default conversationsSlice;
export const {
  appendConversation,
  switchChat,
  incomingMessage,
  continueConversation,
  blockConversation,
  leaveGroup,
  updateGroupMember,
  cleanUp,
  readMessage
} = conversationsSlice.actions;

/**
 * TYPES
 */
interface IncomingMessageActionI {
  data: {
    id: string;
    conversationId: string;
    sentBy: string;
    message: string;
    messageType: string;
    mediaType: any;
    mediaLink: any;
    hasRead: boolean;
    createdAt: string;
    updatedAt: string;
    sameOrg: boolean;
  };
  currentUser: string;
}

interface ContinueConversationActionI {
  conversationId: string;
  sentBy: string;
  type: string;
  token: string;
  sameOrg: boolean;
  endpointURL: string;
}
interface BlockConversationActionI {
  conversationId: string;
  sentBy: string;
  blockedBy: string;
  type: string;
  token: string;
  sameOrg: boolean;
}
interface LeaveGroupActionI {
  data: {
    conversationId: string;
    selected: Member[];
    userId: string;
    sentBy: string;
  };
  currentUser: string;
}

interface UpdateGroupMemberActionI {
  data: {
    conversationId: string;
    sentBy: string;
    selected: Member[];
    deleted: string[];
    added: string[];
  };
  currentUser: string;
}
