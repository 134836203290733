import { Reducer, useContext, useEffect, useReducer, useState } from 'react';
import axiosInstance from '../../../axiosInstance';
import { AddressDataPayload, recordTypes } from './useProjectAddress';
import { TeamDetailsPayload } from './useTeam';
import { Contacts } from './useOtherContacts';
import { useNavigate } from 'react-router';
import { PinnedProjectsContext } from '../../../PinnedProjectContext';
import toast from 'react-hot-toast';
import { AppContext } from '../../../AppContext';
import { useMutation } from '@apollo/client';
import { archiveProjectMutation } from '../../../graphql/operations/Mutations/Projects/ProjectMutations';

export type OtherContactsResponse = {
  address: Contacts['address'];
  name: Contacts['name'];
  emailId: Contacts['emailId'];
  contactName: Contacts['designation'];
  phoneNumber: Contacts['phone'];
  entityId: string;
  entityId_recordType: string;
  enitityType: string;
  id: string;
  organizationId: string;
  organization: null | string;
  recordName: string;
  recordType: recordTypes;
  recordStatus: number;
};

export type ProjectAddressResponse = Omit<AddressDataPayload, 'projectId'> & {
  contactName: null | string;
  entityId: string;
  entityId_recordType: string;
  organizationId: string;
  organizationName?: string | null;
  id: string;
  recordStatus: number;
};

export type ProjectTeamResponse = Omit<TeamDetailsPayload, 'phoneNumber'> & {
  id: string;
  phoneNumber: number;
  projectId_recordType: string;
  recordStatus: number;
};

type ProjectResponse = {
  projectName: string;
  createdBy: string;
  description: string;
  otherContacts: OtherContactsResponse[];
  projectAddress: ProjectAddressResponse[];
  projectCode: string;
  projectDirectory: [];
  team: ProjectTeamResponse[];
  createdAt: string;
};

type ProjectState = {
  projectData: ProjectResponse;
  error: string;
  loading: boolean;
};

const initialState: ProjectState = {
  projectData: {} as ProjectResponse,
  error: '',
  loading: true
};

type ACTIONTYPE =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_DATA'; payload: ProjectResponse }
  | { type: 'HIDE_DATA'; payload: { id: string; module: recordTypes } };

const dataReducer: Reducer<ProjectState, ACTIONTYPE> = (state: ProjectState, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: state.loading ? false : true };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_DATA':
      return { ...state, projectData: action.payload };
    case 'HIDE_DATA':
      let record = '';
      if (action.payload.module === recordTypes.ADDRESS) {
        record = 'projectAddress';
      } else if (action.payload.module === recordTypes.CONTACT) {
        record = 'otherContacts';
      } else {
        record = 'team';
      }
      // TODO: Don't mutate state object elements directly
      let newProjectData = state.projectData[record].map((item) => {
        if (item.id === action.payload.id) {
          item.recordStatus = 1;
        }
        return item;
      });
      return { ...state };
    default:
      return state;
    // throw new Error('action not available');
  }
};

export const useGetProject = (projectId: string, orgId: string) => {
  const [state, dispatch] = useReducer(dataReducer, initialState);
  const { state: pinnedProjects, dispatch: PinnedProjectsDispatch } = useContext(PinnedProjectsContext);
  const { state: userDetails } = useContext(AppContext);
  const [isProjectAdmin, setIsProjectAdmin] = useState(false);

  const [memberData, setMemberData] = useState([]);
  let navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/projects');
  };

  useEffect(() => {
    // dispatch({ type: 'SET_LOADING' });
    const getProjectData = async () => {
      await axiosInstance
        .get(`/project?projectId=${projectId}&organizationId=${orgId}`)
        .then((res) => {
          dispatch({ type: 'SET_DATA', payload: res.data.data });
        })
        .catch((error) => {
          if (error?.message) {
            dispatch({ type: 'SET_ERROR', payload: error.message });
          } else {
            dispatch({ type: 'SET_ERROR', payload: 'Error in loading details of project.' });
          }
        })
        .finally(() => {
          dispatch({ type: 'SET_LOADING' });
        });
    };
    getProjectData();
  }, []);

  useEffect(() => {
    const getProjectMembers = async () => {
      await axiosInstance
        .get(`/project/members?projectId=${projectId}`)
        .then((res) => {
          setMemberData(res.data.data.projectDirectory);
          return res;
        })
        .then((res) => {
          let memberData = res.data.data.projectDirectory;
          let users = memberData.filter((user) => user.userId === userDetails.userId);
          if (users.length > 0) {
            let currentUser = users[0];
            if (currentUser.userType === 'admin') {
              setIsProjectAdmin(true);
            }
          }
        })
        .catch((error) => {
          console.error('ERROR IN GETTING DATA', error);
        })
        .finally(() => {});
    };
    getProjectMembers();
  }, []);

  const goToMembersPage = () => {
    navigate(`/project/members/${projectId}`);
  };

  const hideProjectCard = async (id: string, module: recordTypes, projectId: string) => {
    let type = recordTypes.CONTACT;
    if (module === recordTypes.TEAM) {
      type = recordTypes.TEAM;
    }
    dispatch({ type: 'HIDE_DATA', payload: { id: id, module: module } });
    await axiosInstance
      .patch(`/project/${type}`, {
        projectId: projectId,
        operationType: 'hide',
        id: id,
        recordType: module
      })
      .then((response) => {
        // TODO: add dispatch function
        //do a dispatch
      })
      .catch((error) => {
        console.error('ERROR IN HIDE CARD', error);
      });
  };

  const goToOtherContacts = (contactId: string) => {
    if (contactId) {
      navigate(`/project/othercontacts/${projectId}`, {
        state: {
          isEdit: true,
          contactId: contactId,
          module: 'project'
        }
      });
    } else {
      navigate(`/project/othercontacts/${projectId}`);
    }
  };

  const goToAddTeams = (teamId: string) => {
    if (teamId) {
      navigate(`/project/teams/${projectId}`, {
        state: {
          isEdit: true,
          teamId: teamId,
          module: 'project'
        }
      });
    } else {
      navigate(`/project/teams/${projectId}`);
    }
  };

  const goToAddressPage = (addressId?: string) => {
    if (addressId) {
      navigate(`/project/address/${projectId}`, {
        state: {
          isEdit: true,
          addressId: addressId,
          module: 'project'
        }
      });
    } else {
      navigate(`/project/address/${projectId}`);
    }
  };

  const pinToSidebar = () => {
    if (pinnedProjects.length >= 10) {
      toast.error('Remove projects from sidebar before pinning');
    } else {
      PinnedProjectsDispatch({
        type: 'SET_PROJECTS',
        data: [{ name: state.projectData.projectName, id: projectId }]
      });
      axiosInstance
        .patch(`/users/${userDetails.userId}`, {
          pinnedProjects: [...pinnedProjects, { name: state.projectData.projectName, id: projectId }]
        })
        .then((res) => {
          axiosInstance.get(`/users/${userDetails.userId}`).then((res) => {});
        });
    }
  };

  const removeFromSidebar = () => {
    PinnedProjectsDispatch({
      type: 'FILTER_PROJECTS',
      data: [{ name: state.projectData.projectName, id: projectId }]
    });
    let filteredProjects = pinnedProjects.filter((project) => project.id !== projectId);
    axiosInstance.patch(`/users/${userDetails.userId}`, {
      pinnedProjects: [...filteredProjects]
    });
  };

  const archiveProject = async (projectId: string) => {
    const toastId = toast.loading('Archiving Project ...');
    try {
      let response = await archiveProjectOperation({
        variables: {
          id: projectId,
          recordStatus: 1.0
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Project Archived');
        navigate('/projects/archived');
      }
    } catch (error) {
      console.error('Error in Archival of project', error);
      toast.dismiss(toastId);
      toast.success('Error in archiving project.Please try again');
    }
  };
  const [archiveProjectOperation] = useMutation(archiveProjectMutation, {
    fetchPolicy: 'no-cache'
  });

  return {
    ...state,
    dispatch,
    hideProjectCard,
    goToMembersPage,
    goToOtherContacts,
    goToAddTeams,
    goToAddressPage,
    memberData,
    pinToSidebar,
    removeFromSidebar,
    isProjectAdmin,
    handleNavigate,
    archiveProject
  };
};

// export const useGetProject = (projectId: string, orgId: string) => {
//   const [loading, setIsLoading] = useState(false);
//   const [error, setError] = useState(false);
//   const [projectData, setProjectData] = useState<ProjectResponse>({} as ProjectResponse);
//   useEffect(() => {
//     setIsLoading(true);
//     const getProjectData = async () => {
//       await axiosInstance
//         .get(`/project?projectId=${projectId}&organizationId=${orgId}`)
//         .then((res) => {
//           setProjectData(res.data.data);
//           setIsLoading(false);
//         })
//         .catch((error) => {

//           setError(true);
//           setIsLoading(false);
//         });
//     };
//     getProjectData();
//   }, [orgId, projectId]);

//   return { loading, error, projectData };
// };
