import { useContext, useState } from 'react';
import { Header, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import { gql, useQuery } from '@apollo/client';
import { LIST_FILES_QUERY } from '../../../graphql/operations/Queries/Library/FilesQueries';
import { AppContext } from '../../../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { LIST_NOTES_QUERY } from '../../../graphql/operations/Queries/Library/NotesQueries';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import { ErrorPage } from '../../Others/ErrorPage';
import { unarchiveFileMutation } from '../../../graphql/operations/Mutations/Library/FileMutations';
import { unarchiveNoteMutation } from '../../../graphql/operations/Mutations/Library/NoteMutations';
import { ReactComponent as NotebookIcon } from '../../../assets/icons/notebook.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/NewFileIcon.svg';
import { ReactComponent as EmptyTrash } from '../../../assets/illustration/EmptyTrash.svg';
// import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  padding-top: 30px;
  padding-left: 37px;
  padding-right: 49px;

  .wrapper {
    margin: 30px 0;
  }
  .text-center {
    text-align: center;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 268px);
  padding: 0;
`;
const TrashItem = styled.div`
  display: flex;
  border-bottom: 1px solid var(--table-border);
  justify-content: space-between;
  cursor: pointer;
  :hover {
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
  }
  .trash-info,
  .trash-date {
    display: flex;
    align-items: center;
  }
  .trash-info {
    padding-left: 2.6rem;
    .trash-title {
      margin-left: 2.01rem;
    }
  }
  .trash-date {
    color: var(--text-light);
    padding-right: 7.6rem;
    .date {
      margin-right: 20px;
    }
    .time {
      margin-right: 20px;
    }
    .trash-options {
      position: relative;
    }
  }
`;

const generateClearTrashMutation = (notesData, filesData) => {
  let notesMutation = ``;
  let filesMutation = ``;
  if (notesData?.length > 0) {
    for (const [index, value] of notesData.entries()) {
      notesMutation += `note${index}: update_NoteAndDisscussion(id: "${value._id}", syncMode: NODE_COMMITTED, input: {recordStatus: 9}) {
        result {
          _id
        }
      }\n`;
    }
  } else {
    notesMutation = `update_NoteAndDisscussion(id: "null", syncMode: NODE_COMMITTED, input: {recordStatus: 9}) {
        result {
            _id
        }
      }\n`;
  }

  if (filesData?.length > 0) {
    for (const [index, value] of filesData.entries()) {
      notesMutation += `file${index}: update_File(id: "${value._id}",syncMode: NODE_COMMITTED, input: {recordStatus: 9}) {
        result {
          _id
        }
      }\n`;
    }
  } else {
    filesMutation = `update_File(id: "null", syncMode: NODE_COMMITTED, input: {recordStatus: 0}) {
        result {
          _id
        }
      }\n`;
  }

  return gql`
    mutation deleteTrashItemsMutation @api(name: "library") {
        ${notesMutation}
        ${filesMutation}
    }
    `;
};

const Trash = () => {
  const { state } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filesDataState, setFilesDataState] = useState([]);
  const [notesDataState, setNotesDataState] = useState([]);
  // let navigate = useNavigate();
  // const userId = state?.userId;
  const {
    // data: listFilesData,
    loading,
    error
    // refetch,
  } = useQuery(LIST_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${state?.userId}`,
      recordStatus: 1
    },
    onCompleted: (listFilesData) => {
      setFilesDataState(listFilesData.list_FileItems._FileItems);
    },
    fetchPolicy: 'network-only'
  });
  const {
    // data: listNotesData,
    loading: notesLoading
    // error: notesError,
    // refetch: notesRefetch,
  } = useQuery(LIST_NOTES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `user_${state.userId}`,
      recordStatus: 1
    },
    onCompleted: (listNotesData) => {
      setNotesDataState(listNotesData.list_NoteAndDisscussionItems._NoteAndDisscussionItems);
    },
    fetchPolicy: 'network-only'
  });

  const filesData = filesDataState;
  const notesData = notesDataState;
  const clearTrashMutation = generateClearTrashMutation(notesData, filesData);
  const [deleteAll, { error: deleteAllError }] = useMutation(clearTrashMutation);
  const [restoreFile, { error: restoreError }] = useMutation(unarchiveFileMutation);
  const [deleteNote, { error: deleteNoteError }] = useMutation(unarchiveNoteMutation);
  const [restoreNotes, { error: restoreNoteError }] = useMutation(unarchiveNoteMutation);
  const [deleteFile, { error: deleteFileError }] = useMutation(unarchiveFileMutation);

  const restoreBack = async (id, recordStatus) => {
    try {
      setIsLoading(true);
      await restoreFile({
        variables: {
          id,
          noValidate: true,
          libraryType: 'notesAndCollections',
          recordStatus
        }
      }).then((res) => {
        let fileIdToBeRestored = res.data.update_File.result._id;
        let updatedFilesData = filesData.filter((data) => data._id !== fileIdToBeRestored);
        setTimeout(() => {
          setFilesDataState(updatedFilesData);
          toast.success('File is restored');
          setIsLoading(false);
        }, 3000);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const clearTrash = async () => {
    //call delete all api
    try {
      setIsLoading(true);
      await deleteAll({
        variables: {
          libraryType: 'notesAndCollections'
        }
      }).then(() => {
        setFilesDataState([]);
        setNotesDataState([]);
        setIsLoading(false);
        toast.success('Trash is cleared');
      });
    } catch (err) {
      console.error(err);
    }
    return;
  };
  const restoreBackNote = async (id, recordStatus) => {
    try {
      setIsLoading(true);
      await restoreNotes({
        variables: {
          id,
          noValidate: true,
          libraryType: 'notesAndCollections',
          recordStatus
        }
      }).then((res) => {
        let noteIdToBeRemovedFromState = res.data.update_NoteAndDisscussion.result._id;
        let updatedNotesData = notesData.filter((data) => data._id !== noteIdToBeRemovedFromState);
        setTimeout(() => {
          setNotesDataState(updatedNotesData);
          setIsLoading(false);
          toast.success('Note is Restored');
        }, 3000);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const deleteNoteHandler = async (id, recordStatus) => {
    try {
      setIsLoading(true);
      await deleteNote({
        variables: {
          id,
          noValidate: true,
          libraryType: 'notesAndCollections',
          recordStatus
        }
      }).then((res) => {
        let noteIdToBeDeleted = res.data.update_NoteAndDisscussion.result._id;
        let updatedNotesData = notesData.filter((data) => data._id !== noteIdToBeDeleted);
        setNotesDataState(updatedNotesData);
        setIsLoading(false);
        toast.success('Note is Deleted');
      });
    } catch (err) {
      console.error(err);
    }
  };
  const deleteFileHandler = async (id, recordStatus) => {
    try {
      setIsLoading(true);
      await deleteFile({
        variables: {
          id,
          libraryType: 'notesAndCollections',
          recordStatus
        }
      }).then((res) => {
        let fileIdToBeDeleted = res.data.update_File.result._id;
        let updatedFilesData = filesData.filter((data) => data._id !== fileIdToBeDeleted);
        setFilesDataState(updatedFilesData);
        toast.success('File is deleted');
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (loading || notesLoading || isLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error || deleteFileError || deleteNoteError || deleteAllError || restoreError || restoreNoteError)
    return <ErrorPage />;

  return (
    <>
      <Header>
        <NewTitleLeft>
          <PageTitle>Trash </PageTitle>
        </NewTitleLeft>
        <TitleRight>
          <Button onClick={() => clearTrash()}>Clear Trash</Button>
        </TitleRight>
      </Header>
      <Container>
        {filesData?.length === 0 && notesData?.length === 0 ? (
          <ImageWrapper>
            <EmptyTrash />
          </ImageWrapper>
        ) : (
          ''
        )}
        <div className="wrapper">
          {filesData?.length !== 0 ? (
            <TableDropDown state={true} text="Files">
              {filesData?.map((data, index) => {
                return (
                  <TrashItem key={index}>
                    <div className="trash-info">
                      <FileIcon />
                      <p className="trash-title">{data.name}</p>
                    </div>
                    <div className="trash-date">
                      <p className="date">{new Date(data?.createdAt).toLocaleDateString('en-GB')}</p>
                      <p className="time">
                        {new Date(data?.updatedAt ? data.updatedAt : data?.createdAt).toLocaleTimeString('EN-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </p>
                      <span className="trash-options">
                        <ToggleSettings style={{ top: '20px' }}>
                          <OptionItem onClick={() => restoreBack(data._id, 0)}>Restore Back</OptionItem>
                          <OptionItem onClick={() => deleteFileHandler(data._id, 9)}>Delete from Trash</OptionItem>
                        </ToggleSettings>
                      </span>
                    </div>
                  </TrashItem>
                );
              })}
            </TableDropDown>
          ) : (
            ''
          )}
        </div>
        <div className="wrapper">
          {notesData?.length !== 0 ? (
            <TableDropDown state={true} text="Notes">
              {notesData?.map((data, index) => {
                return (
                  <TrashItem key={index}>
                    <div className="trash-info">
                      <NotebookIcon />
                      <p className="trash-title">{data.title}</p>
                    </div>
                    <div className="trash-date">
                      <p className="date">{new Date(data?.createdAt).toLocaleDateString('en-GB')}</p>
                      <p className="time">
                        {new Date(data?.updatedAt ? data.updatedAt : data?.createdAt).toLocaleTimeString('EN-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </p>
                      <span className="trash-options">
                        <ToggleSettings style={{ top: '20px' }}>
                          <OptionItem onClick={() => restoreBackNote(data?._id, 0)}>Restore Back</OptionItem>
                          <OptionItem onClick={() => deleteNoteHandler(data?._id, 9)}>Delete from Trash</OptionItem>
                        </ToggleSettings>
                      </span>
                    </div>
                  </TrashItem>
                );
              })}
            </TableDropDown>
          ) : (
            ''
          )}
        </div>
      </Container>
    </>
  );
};

export default Trash;
