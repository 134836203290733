import styled from '@emotion/styled';
import { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import Select from 'react-select';
import { client } from '../../apollo';
import axiosInstance from '../../axiosInstance';
import { editTaskOptions } from '../../constants/DropDownOptions';
import { LIST_NON_ASSOCIATED_TASKS } from '../../graphql/operations/Queries/Tasks/AssociatedTaskQueries';
import { useGetTask } from '../../hooks/features/tasks/useGetTask';
import getUtcDate from '../../lib/getUtcDate';
import Button from '../CustomButton';
import Modal from '../Modal';
import NonAssociatedTable from '../NonAssiciatedTaskTable/NonAssociatedTable';
import { Input } from '../styles/Inputs';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
import { tableStatusStyles } from '../styles/StatusStyles';
import { HighPriority, LowPriority, MediumPriority } from '../TableComponents/Priority';
import { getValue } from '../Tasks';

const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  margin-top: 10px;
`;
const Text = styled.p`
  font-weight: 600;
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  flex-direction: row;
  align-items: baseline;
  Input[name='search'] {
    width: 85%;
    margin-right: 20px;
  }
`;
const ChoicesWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: center;
  justify-content: center;
`;
// const AssociatedButton = styled.span({
//   Button: {
//     background: '#EEF3F2',
//     color: '#9BB3AE'
//   }
// });

const DataDiv = styled.div`
  background-color: #f6f5f5;
  display: flex;
  align-items: center;
  padding: 0px 40px;
  border-radius: 5px;
  font-weight: 600;

  .label {
    color: #1c1c1c;
    padding: 0px;
  }

  .value {
    padding: 0px 10px;
    color: #929292;
  }
`;

type SProps = {
  isOpen: boolean;
  toggle: () => void;
  dispatch: any;
};

const COLUMNS = [
  {
    Header: 'TASK',
    accessor: 'title',
    Cell: ({ cell: { value } }: any) => <div className="text-wrap">{value}</div>
  },

  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ cell: { row, value } }: any) => {
      return (
        <span onClick={(e) => e.stopPropagation()}>
          <Select
            name="status"
            // options={editTaskOptions}
            defaultValue={getValue(editTaskOptions, value)}
            // @ts-ignore
            styles={{ ...tableStatusStyles }}
            width="200px"
            menuPortalTarget={document.body}
            // onChange={(selectedOption) => {
            //   updateStatus(selectedOption, row.original._id, row.original);
            // }}
          />
        </span>
      );
    }
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    Cell: ({ cell: { value } }: any) =>
      value === 'high' ? <HighPriority /> : value === 'medium' ? <MediumPriority /> : <LowPriority />
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    Cell: ({ cell: { value } }: any) => <span>{getUtcDate(value)}</span>
  }
];

const fetchTasks = async (type: string, orgId: string, projectId: string, typeId: string) => {
  try {
    const tasks = await client.query({
      query: LIST_NON_ASSOCIATED_TASKS,
      variables: {
        isAssociated: false,
        type: type,
        organizationId_projectId_typeId: `${orgId}_${projectId}_${typeId}`
      },
      fetchPolicy: 'network-only'
    });
    return tasks.data?.list_TaskItems?._TaskItems;
  } catch (error) {
    console.error(error);
    toast.error("Something went wrong, couldn't fetch tasks");
    return [];
  }
};

function AssociatedTaskModal(props: SProps) {
  const { isOpen, toggle, dispatch } = props;

  //This data is showing in table
  const [tasks, setTasks] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);

  //This contains all tasks from the API
  const allTasks = useRef<any>([]);

  const { taskId } = useParams();

  const { data, getProjectName, getWorkspaceName } = useGetTask();

  const initTable = async () => {
    const fetchedTasks = await fetchTasks(
      data?.get_Task.type,
      data?.get_Task.organizationId,
      data?.get_Task.projectId,
      data?.get_Task.typeId
    );

    allTasks.current = fetchedTasks;
    setTasks(fetchedTasks);
  };

  useEffect(() => {
    if (!data?.get_Task) return;
    initTable();
  }, [data]);

  const addToAssociatedTasks = async () => {
    if (selectedTasks.length === 0) return;

    const toastId = toast.loading('Adding associated task');
    const selectedIds = selectedTasks.map((d: any) => d._id);
    try {
      const response = await axiosInstance.patch('/task', {
        associateToTaskIds: selectedIds,
        associateByTaskId: taskId
      });
      toast.success('Association created successfully', { id: toastId });

      //Dispatch selected tasks to display it the associated tasks page
      selectedTasks.map((d: any) => dispatch({ type: 'ADD_TASK', payload: d }));

      //clear search
      setSearchText('');

      toggle();
    } catch (err) {
      toast.error('Something went wrong', { id: toastId });
    }
  };

  const handleSearchChange = (val: string) => {
    setSearchText(val);
    filterTasks(val);
  };

  const filterTasks = (val: string) => {
    const filteredData = allTasks.current.filter((task: any) =>
      task.title.toLowerCase().includes(val.trim().toLowerCase())
    );
    setTasks(filteredData);
  };

  const columns = useMemo(() => COLUMNS, []);

  const tableData = useMemo(() => tasks, [tasks]);

  return (
    <>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="1000px">
          <ModalTitle>Choose associated task</ModalTitle>
          <ModalBody>
            <ModalText>Choose a task from the same workspace to add it as an associated task</ModalText>

            <ChoicesWrapper>
              <>
                <DataDiv>
                  <p className="label">Projects</p>
                  <p className="value" style={{ marginRight: '40px' }}>
                    {getProjectName(data?.get_Task.projectId)}
                  </p>

                  <p className="label">Workspace</p>
                  <p className="value">{getWorkspaceName(data?.get_Task.typeId)}</p>
                </DataDiv>
              </>
            </ChoicesWrapper>
            <InputWrapper>
              <Input
                name="search"
                type="text"
                placeholder="Search tasks"
                value={searchText}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
              {/* <Button type="submit" onClick={filterTasks}>
                Search Task
              </Button> */}
            </InputWrapper>

            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <NonAssociatedTable columns={columns} data={tableData} setSelectedTasks={setSelectedTasks} />
            </div>
          </ModalBody>
          <ButtonWrapper>
            <Button onClick={toggle} secondary>
              Cancel
            </Button>

            <Button onClick={addToAssociatedTasks} disabled={selectedTasks.length === 0}>
              Add as Associated Task
            </Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
}
export default AssociatedTaskModal;
