const getUtcDate = (date) => {
  var dateWrapper = new Date(date);
  var strArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var d = dateWrapper.getUTCDate();
  var m = strArray[dateWrapper.getUTCMonth()];
  var y = dateWrapper.getUTCFullYear();
  return '' + (d <= 9 ? '0' + d : d) + ' ' + m + ' ' + y;
};

export default getUtcDate;
