import styled from '@emotion/styled';
import Button, { CustomButton } from '../../../../components/CustomButton';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { Label } from '../../../../components/styles/Label';
import { FieldSet, InputWrapper } from '../../../../components/styles/FormStyles';
import InputField from '../../../../components/Reusable/Formik/Input';
import CollectionFilesList from '../../../../components/CollectionFilesList';
import { ReactComponent as FileIcon } from '../../../../assets/icons/NewFileIcon.svg';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import useCreateCollection from '../../../../hooks/features/library/useCreateCollection';
import Loader from '../../../../components/Loader';
import { ErrorPage } from '../../../Others';
// import { TaskDetails } from '../../Dashboard/TaskViews/CreateTask'
// import TextAreas from "../../../../components/Reusable/Formik/Textarea";
// import DateInputs from "../../../../components/Reusable/Formik/DateInput";
// import FileValidationSchema from "../../../../components/Validations/CreateFileValidation";
// import BackIcon from "../../../../assets/SvgComponents/BackIcon";

const Form = styled.form`
  .input-width {
    width: 60%;
  }
`;
const CollectionDetails = styled.div`
  margin-left: 3.2%;
`;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #dbe2e0;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
  }
`;

const CreateNewCollection = () => {
  const { formik, isSubmitting, documentData, error, loading } = useCreateCollection();
  if (loading) return <Loader />;
  if (error) return <ErrorPage />;
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Create new collection</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button type="submit" disabled={isSubmitting}>
              Save Collection
            </Button>
          </TitleRight>
        </Header>
        <CollectionDetails>
          <FieldSet>
            <div className="input-width">
              <InputWrapper>
                <Label>Collection Name</Label>
                <InputField disabled={isSubmitting} name="name" type="text" formik={formik} />
              </InputWrapper>
            </div>
          </FieldSet>
          <div>
            <CollectionFilesList
              data={documentData?.list_FileItems?._FileItems}
              icons={<FileIcon />}
              files={null}
              checkedFiles={formik.values.checkedFiles}
              setCheckedFiles={(selectedFile) => formik.setFieldValue('checkedFiles', selectedFile)}
            />
          </div>
        </CollectionDetails>
      </Form>
    </>
  );
};

export default CreateNewCollection;
