import { useState } from 'react';
import { PlanType } from '../../../pages/Organization/ViewOrganization';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { Flex, InputRadio, ModalLabel, Text, Wrapper } from './ChoosePlanModal';

type ChooseSeatTypeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  monthlySeats: number;
  yearlySeats: number;
  totalSeats: number;
  currentUsers: any;
  confirm: () => void;
};

export const Seating = (props) => (
  <Flex flexDireaction="column">
    <Text margin="0 0 0.5rem 0">{props.period} seat</Text>
    <Text fontWeight="400" margin="0 0 0.5rem 0" fontSize="10px">
      {props.period === 'Trial'
        ? 'Trial Seats are available for 30 days.'
        : ` Billed ${props.period === 'Yearly' ? 'yearly' : 'per month'}`}
    </Text>
    <Text fontWeight="600" fontSize="16px">
      {props.period === 'Trial' ? 'Unlimited Seats Available' : `${props.quantity} Available`}
    </Text>
  </Flex>
);

function ChooseSeatTypeModal(props: ChooseSeatTypeModalProps) {
  const { currentUsers, isOpen, monthlySeats, onClose, yearlySeats, confirm } = props;
  const [plan, setActivePlan] = useState<PlanType>('month');
  const onClick = (type: 'month' | 'year') => {
    setActivePlan(type);
  };

  const handleClick = () => {
    onClose();
    confirm();
  };

  let availableMonthlySeats = monthlySeats - currentUsers.filter((user) => user?.seatType === 'MONTHLY').length;
  let availableYearlySeats = yearlySeats - currentUsers.filter((user) => user?.seatType === 'YEARLY').length;

  return (
    <Modal styles={{ width: '550px' }} open={isOpen} onClose={onClose}>
      <ModalTitle>Choose the seat type</ModalTitle>
      <ModalBody>
        <ModalText>Please choose preferred seat type</ModalText>
        <Flex margin="2rem 0 2rem ">
          <Wrapper active={plan === 'month'} onClick={() => onClick('month')}>
            <InputRadio active={plan === 'month'} />
            <ModalLabel htmlFor="month">
              <Seating period="Monthly" quantity={availableMonthlySeats} />
            </ModalLabel>
          </Wrapper>
          <Wrapper active={plan === 'year'} onClick={() => onClick('year')}>
            <InputRadio active={plan === 'year'} />
            <ModalLabel htmlFor="year">
              {' '}
              <Seating period="Yearly" quantity={availableYearlySeats} />
            </ModalLabel>
          </Wrapper>
        </Flex>
      </ModalBody>
      <ModalWrapper>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleClick}>Assign Seat</Button>
      </ModalWrapper>
    </Modal>
  );
}

export default ChooseSeatTypeModal;
