import { Reducer, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import { useToggle } from '../../../lib/UseToggle';
import useGetIsSuperAdmin from '../../common/useGetIsSuperAdmin';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION } from '../../../graphql/operations/Queries/Organization/OrganizationQueries';
import axiosInstance from '../../../axiosInstance';
import toast from 'react-hot-toast';
import { userRoles } from '../../../constants/UserRoles';
import { getDate } from '../../../lib/getDate';
import * as Yup from 'yup';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { LightBlueButton } from '../../../components/styles/ButtonStyles';
import useAssignSeat from '../payments/useAssignSeat';
import useAssignSeatsToSelected from '../payments/useAssignSeatsToSelected';
import { SeatType } from '../../../pages/Organization/ViewOrganization';

type CurrentOrganizationUsers = {
  userName: string;
  memberType: string;
  profilePhoto: string;
  id: string;
  email: string;
  updatedAt: string;
  organizationId: string;
  currentLoggedInUserId: string;
  currentLoggedInUserPhoto: string;
  currentLoggedInUserFullName: string;
  currentLoggedInUserMemberType: string;
  seatType: 'MONTHLY' | 'YEARLY' | 'TRIAL';
};

type PendingOrganizationUsers = {
  memberType: string;
  id: string;
  email: string;
  updatedAt: string;
  date: string;
  organizationId: string;
  organizationName: string;
  currentLoggedInUserMemberType: string;
  invitationStatus: string;
};

type awaitingSeatOrganizationUsers = {
  memberType: string;
  id: string;
  email: string;
  updatedAt: string;
  date: string;
  organizationId: string;
  organizationName: string;
  currentLoggedInUserMemberType: string;
};

type OrganizationState = {
  error: string;
  loading: boolean;
  currentOrganizationUsers: CurrentOrganizationUsers[];
  pendingOrganizationUsers: PendingOrganizationUsers[];
  awaitingSeatOrganizationUsers: awaitingSeatOrganizationUsers[];
};

type CancelType = {
  monthly: {
    isCancelled: boolean;
    endDate: string;
  };
  yearly: {
    isCancelled: boolean;
    endDate: string;
  };
};

const initalState: OrganizationState = {
  currentOrganizationUsers: [],
  pendingOrganizationUsers: [],
  awaitingSeatOrganizationUsers: [],
  error: '',
  loading: true
};

type ACTIONTYPE =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_PENDING'; payload: any }
  | { type: 'ADD_PENDING'; payload: PendingOrganizationUsers }
  | { type: 'REMOVE_CURRENT'; payload: string }
  | { type: 'REMOVE_SEAT_FROM_CURRENT'; payload: string }
  | { type: 'UPDATE_PENDING'; payload: string }
  | { type: 'SET_AWAITING'; payload: any }
  | { type: 'ASSIGN_SEAT_TO_USER'; payload: { ID: string; seatType: SeatType } }
  | { type: 'ASSIGN_SEAT_TO_ALL_USERS'; payload: { seatType: SeatType } }
  | { type: 'ASSIGNING SEAT_TO_SELECTED'; payload: { ids: any; seatType: SeatType } };

const dataReducer: Reducer<OrganizationState, ACTIONTYPE> = (state: OrganizationState, action: ACTIONTYPE) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: state.loading ? false : true };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_CURRENT':
      return { ...state, currentOrganizationUsers: [...action.payload] };
    case 'SET_PENDING':
      return { ...state, pendingOrganizationUsers: [...action.payload] };
    case 'SET_AWAITING':
      return { ...state, awaitingSeatOrganizationUsers: [...action.payload] };
    case 'ADD_PENDING':
      return { ...state, pendingOrganizationUsers: [...state.pendingOrganizationUsers, action.payload] };
    case 'UPDATE_PENDING':
      let newPendingUsers = state?.pendingOrganizationUsers.filter((user) => user.email !== action.payload);
      return { ...state, pendingOrganizationUsers: newPendingUsers };
    case 'REMOVE_CURRENT':
      let newCurrentUsers = state.currentOrganizationUsers.filter((user) => user.id !== action.payload);
      return { ...state, currentOrganizationUsers: newCurrentUsers };
    case 'REMOVE_SEAT_FROM_CURRENT':
      let actualUser = state.currentOrganizationUsers.filter((user) => user.id === action.payload);
      let currentUsers = state.currentOrganizationUsers.filter((user) => user.id !== action.payload);
      let newAwaitingSeatUsers = [...state.awaitingSeatOrganizationUsers, ...actualUser];

      return { ...state, currentOrganizationUsers: currentUsers, awaitingSeatOrganizationUsers: newAwaitingSeatUsers };
    case 'ASSIGN_SEAT_TO_USER':
      let user = state.awaitingSeatOrganizationUsers.filter((user) => user.id === action.payload.ID);
      let awaitingUsers = state.awaitingSeatOrganizationUsers.filter((user) => user.id !== action.payload.ID);
      let finalUser = { ...user[0], seatType: action.payload.seatType };
      let currentOrganizationUsers = [...state.currentOrganizationUsers, finalUser];
      return {
        ...state,
        awaitingSeatOrganizationUsers: awaitingUsers,
        currentOrganizationUsers: currentOrganizationUsers
      };
    case 'ASSIGNING SEAT_TO_SELECTED':
      let selectedUsers = state.awaitingSeatOrganizationUsers
        .filter((user) => action.payload.ids.includes(user.id))
        .map((user) => {
          return {
            ...user,
            seatType: action.payload.seatType
          };
        });
      let remainingUsers = state.awaitingSeatOrganizationUsers.filter((user) => !action.payload.ids.includes(user.id));
      let currentOrgUsers = [...state.currentOrganizationUsers, ...selectedUsers];
      return {
        ...state,
        currentOrganizationUsers: currentOrgUsers,
        awaitingSeatOrganizationUsers: [...remainingUsers]
      };
    case 'ASSIGN_SEAT_TO_ALL_USERS':
      let newusers = state.awaitingSeatOrganizationUsers.map((user) => {
        return {
          ...user,
          seatType: action.payload.seatType
        };
      });
      let allusers = [...state.currentOrganizationUsers, ...newusers];
      return {
        ...state,
        currentOrganizationUsers: allusers,
        awaitingSeatOrganizationUsers: []
      };

    default:
      return state;
  }
};

function useGetOrganization() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { state: userDetails } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, toggle] = useToggle(false);
  const [isAssignSeatsForAllModalOpen, toggleAssignSeatsForAllModal] = useToggle(false);
  const [isRemoveSeatModalOpen, toggleRemoveSeatModal] = useToggle(false);
  const [removeSeatFromUser, setRemoveSeatFromUser] = useState();
  const { isSuperAdmin } = useGetIsSuperAdmin();
  const [state, dispatch] = useReducer(dataReducer, initalState);
  const [isInsufficientSeats, setIsInSufficientSeats] = useState(false);
  const [monthlySeats, setMonthlySeats] = useState(0);
  const [yearlySeats, setYearlySeats] = useState(0);
  const [totalSeats, setTotalSeats] = useState(0);
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState<CancelType>({
    monthly: { isCancelled: false, endDate: '' },
    yearly: { isCancelled: false, endDate: '' }
  });
  const {
    assignSeat,
    assignSeatToUser,
    cancelAssigningSeat,
    isAssignSeatModalOpen,
    submittingAssignSeatData,
    seatAssigningTo,
    showConfirmForAssigningSeat,
    setShowConfirmForAssigningSeat,
    toggleAssignSeatModal
  } = useAssignSeat(dispatch, userDetails, state, setIsInSufficientSeats, totalSeats, monthlySeats, yearlySeats);
  const [submittingAssignSeatToAll, setSubmittingAssignSeatToAll] = useState(false);
  const {
    selectedRowIds,
    setSelectedRowIds,
    assignSeatsToSelectedUsers,
    isAssignSeatToSelectedUsersModalOpen,
    toggleAssignSeatToSelectedUsersModal,
    submittingAssignSeatToSelectedData
  } = useAssignSeatsToSelected(
    dispatch,
    userDetails,
    state,
    setIsInSufficientSeats,
    totalSeats,
    monthlySeats,
    yearlySeats
  );
  const [disableUpgradePlanButton, setDisableUpgradePlanButton] = useState(false);
  const [disableRemoveSeatButton, setDisableRemoveSeatButton] = useState(false);

  // let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const orgUsers = useSelector((state) => state.orgMembers.users);

  const editOrganization = () => {
    navigate('/editorganization');
  };

  const validationSchema = Yup.object({
    emailTo: Yup.string().email('Invalid email').required('Required')
  });

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      // console.log(query)
      // setSuccess(true);
      setSessionId(query.get('session_id'));
      toast.success('Payment Successfull');
      navigate(`/organization/${userDetails.orgId}`);
    }

    if (query.get('canceled')) {
      // setSuccess(false);
      toast.error('Payment Failed');
      navigate(`/organization/${userDetails.orgId}`);
    }
  }, []);

  const viewOrganization = () => {};

  const {
    data: getOrganization,
    loading,
    error
    // refetch
  } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: organizationId
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.get_Organization?.paymentStatus !== 'null') {
        let monthlyData;
        let yearlyData;
        let monthlySubscriptionData = data?.get_Organization?.monthlySubscription;
        let yearlySubscriptionData = data?.get_Organization?.yearlySubscription;
        if (monthlySubscriptionData !== null && monthlySubscriptionData !== 'null') {
          // console.log('INSIDE IF');
          monthlyData = JSON.parse(monthlySubscriptionData);
          // console.log('Monthly seats', monthlyData.numberOfSeats);
          setMonthlySeats(monthlyData.numberOfSeats);
          setTotalSeats(monthlyData.numberOfSeats);
          setCancelAtPeriodEnd((cancelAtPeriodEnd) => ({
            ...cancelAtPeriodEnd,
            monthly: { endDate: monthlyData.endDate, isCancelled: monthlyData.cancelAtPeriodEnd }
          }));
        }
        if (yearlySubscriptionData !== null && yearlySubscriptionData !== 'null') {
          yearlyData = JSON.parse(yearlySubscriptionData);
          setYearlySeats(yearlyData.numberOfSeats);
          setTotalSeats((totalSeats) => totalSeats + yearlyData.numberOfSeats);
          setCancelAtPeriodEnd((cancelAtPeriodEnd) => ({
            ...cancelAtPeriodEnd,
            yearly: { endDate: yearlyData.endDate, isCancelled: yearlyData.cancelAtPeriodEnd }
          }));
        }
      }
      if (data?.get_Organization?.subscriptionType === 'FREE') {
        setTotalSeats(data?.get_Organization?.numberOfSeats);
      }
    }
  });

  useEffect(() => {
    const getUsers = () => {
      axiosInstance
        .get(`/users?organizationId=${userDetails.orgId}`)
        .then((response) => {
          // console.log("RESPONSE OF GET ORG", response)
          if (response.data.data.length > 0) {
            let results = response.data.data.map((item: any) => {
              return {
                userName: item.fullName,
                memberType: item?.roles?.ORGANIZATION[0]?.roleName,
                profilePhoto: item?.profilePhoto,
                id: item?.id,
                email: item?.email,
                updatedAt: item?.updatedAt,
                createdAt: item?.createdAt,
                organizationId: userDetails?.orgId,
                currentLoggedInUserId: userDetails?.userId,
                currentLoggedInUserPhoto: userDetails?.profilePhoto,
                currentLoggedInUserFullName: userDetails?.fullName,
                currentLoggedInUserMemberType: userDetails?.roleName,
                seatType: item?.seatType
              };
            });
            // console.log('dispatching', results)
            dispatch({ type: 'SET_CURRENT', payload: results });
          }
          if (response.data?.invitations?.Items?.length > 0) {
            let results = response.data.invitations.Items.map((item) => {
              return {
                memberType: item.role.label,
                id: item.inviterId,
                email: item.email,
                updatedAt: item.updatedAt,
                date: item.date,
                organizationId: item.entityId,
                organizationName: item.entityName,
                currentLoggedInUserMemberType: userDetails?.roleName,
                invitationStatus: item.invitationStatus
              };
            });
            // console.log('dispatching2', results)
            dispatch({ type: 'SET_PENDING', payload: results });
          }
          if (response.data?.unAssignedUsers?.length > 0) {
            let results = response.data.unAssignedUsers.map((user) => {
              return {
                userName: user.fullName,
                memberType: user?.roles?.ORGANIZATION[0]?.roleName,
                profilePhoto: user.profilePhoto,
                id: user.id,
                email: user.email,
                isSubscribed: user.isSubscribed,
                updatedAt: user.updatedAt,
                organizationId: user.organizationId,
                currentLoggedInUserId: userDetails?.userId,
                currentLoggedInUserMemberType: userDetails?.roleName
              };
            });
            dispatch({ type: 'SET_AWAITING', payload: results });
          }
        })
        .catch((error) => {
          console.error('Error in setting org users and pending invitations', error);
          toast.error('Oh no something went wrong.');
          dispatch({ type: 'SET_ERROR', payload: 'Error in setting org users and pending invitations' });
        });
    };

    getUsers();
  }, [userDetails.orgId, orgUsers]);

  const initialvalues = {
    emailTo: '',
    memberType: { value: userRoles[0].value, label: userRoles[0].label }
  };

  const cancelAssigningSeatToAllUsers = () => {
    setIsInSufficientSeats(false);
    toggleAssignSeatsForAllModal();
  };

  const assignSeatToAllUsers = async (plan: SeatType) => {
    if (!plan) return;
    let toastId = toast.loading('Assigning Seats..');
    setSubmittingAssignSeatToAll(true);
    let totalNumberOfSeats = totalSeats;
    let takenSeats;
    let availableSeats;
    if (plan === 'MONTHLY') {
      takenSeats = state.currentOrganizationUsers.filter((user) => user.seatType === 'MONTHLY').length;
      availableSeats = monthlySeats - takenSeats;
    } else if (plan === 'YEARLY') {
      takenSeats = state.currentOrganizationUsers.filter((user) => user.seatType === 'YEARLY').length;
      availableSeats = yearlySeats - takenSeats;
    } else if (plan === 'TRIAL') {
      availableSeats = totalNumberOfSeats;
    }
    let numberOfUsersToAssignSeat = state.awaitingSeatOrganizationUsers.length;

    if (availableSeats < numberOfUsersToAssignSeat) {
      toast.error('The number of seats is insufficent. Please add more seats.');
      toast.dismiss(toastId);
      setIsInSufficientSeats(true);
      setSubmittingAssignSeatToAll(false);
      //throw error
    } else {
      let arrayOfUserIds = state.awaitingSeatOrganizationUsers.map((user) => user.id);
      // console.log("ARRAY", arrayOfUserIds)
      await axiosInstance
        .patch(`/organization/manageMembers`, {
          users: arrayOfUserIds,
          organizationId: userDetails.orgId,
          seatType: plan
        })
        .then((response) => {
          if (response.data) {
            toast.dismiss(toastId);
            toast.success('Seats Assigned to All.');
            dispatch({ type: 'ASSIGN_SEAT_TO_ALL_USERS', payload: { seatType: plan } });
            setIsInSufficientSeats(false);
            setSubmittingAssignSeatToAll(false);
            toggleAssignSeatsForAllModal();
          }
        })
        .catch((error) => {
          toast.dismiss(toastId);
          console.error('ERROR IN ASSIGNIGN SEATS TO ALL', error);
          toast.error('Something went wrong in assigning seats to users');
          setSubmittingAssignSeatToAll(false);
          toggleAssignSeatsForAllModal();
        });
    }
  };

  const removeMemberFromOrganization = async (user) => {
    const toastId = toast.loading('REMOVING...');
    try {
      axiosInstance
        .post('/removeMemberFromOrganization', {
          organizationId: user.organizationId,
          userId: user.id,
          currentUserId: user.currentLoggedInUserId
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            dispatch({
              type: 'REMOVE_CURRENT',
              payload: user.id
            });
            toast.success('REMOVED MEMBER SUCCESSFULY', {
              id: toastId
            });
          } else {
            toast.dismiss(toastId);
            console.error('Error in removing member from org', response);
            toast.error('Error in removing member.');
          }
        });
    } catch (error) {
      console.error('Error in removing members', error);
      toast.dismiss(toastId);
      toast.error('Error in removing member.');
    }
  };

  const confirmRemoveSeatFromUser = (user) => {
    // console.log('here')
    setRemoveSeatFromUser(user);
    toggleRemoveSeatModal();
  };
  const handleRemoveSeatFromUser = async (user) => {
    // console.log("USER", user)
    setDisableRemoveSeatButton(true);
    const toastId = toast.loading('Removing Seat..');
    axiosInstance
      .delete(`/organization/manageMembers`, {
        data: {
          users: [user.id],
          organizationId: user.organizationId
        }
      })
      .then((res) => {
        // console.log("res", res)
        toast.dismiss(toastId);
        toast.success('User removed from seat.');
        dispatch({ type: 'REMOVE_SEAT_FROM_CURRENT', payload: user.id });
        setRemoveSeatFromUser('');
        toggleRemoveSeatModal();
        setDisableRemoveSeatButton(false);
      })
      .catch((error) => {
        console.error('err', error);
        toast.dismiss(toastId);
        toast.error('Error in removing seat');
        setDisableRemoveSeatButton(false);
      });
  };

  const cancelInvite = async (user) => {
    const toastId = toast.loading('CANCELLING INVITE...');
    try {
      axiosInstance
        .post('/cancelOrganizationInvite', {
          currentUserId: user.id,
          email: user.email,
          host: window.location.origin,
          organizationId: user.organizationId,
          organizationName: user.organizationName,
          date: user.date
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success('INVITE CANCELLED SUCCESSFULY', {
              id: toastId
            });
            dispatch({ type: 'UPDATE_PENDING', payload: user.email });
          } else if (response) {
            toast.success('INVITE CANCELLED SUCCESSFULY', {
              id: toastId
            });
            dispatch({ type: 'UPDATE_PENDING', payload: user.email });
          } else {
            toast.dismiss(toastId);
            toast.error('ERROR WHILE CANCELLING INVITE');
          }
        });
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('ERROR WHILE CANCELLING INVITE');
    }
  };

  const resendInvite = async (user) => {
    const toastId = toast.loading('Resending Invite.');
    try {
      axiosInstance
        .post('/organizationInvite', {
          email: user.email.toLowerCase(),
          host: window.location.origin,
          selectedRole: user.memberType,
          organizationId: user.organizationId,
          organizationName: user.organizationName,
          inviterId: user.id
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toast.success('INVITE SENT SUCCESSFULY', {
              id: toastId
            });
          } else if (response.data.statusCode === 503) {
            toast.error('Account with this Email already exists', {
              id: toastId
            });
          } else {
            toast.error('ERROR WHILE SENDING INVITE');
          }
        });
    } catch (error) {
      toast.error('ERROR WHILE SENDING INVITE');
    }
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    for (const [index, value] of state.currentOrganizationUsers.entries()) {
      if (value.email.toLowerCase() === values.emailTo.toLowerCase()) {
        toast.error('Person with this email already exist in organization');
        setIsSubmitting(false);
        return;
      }
    }
    const toastId = toast.loading('Sending Invite');
    try {
      await axiosInstance
        .post('/organizationInvite', {
          email: values.emailTo.toLowerCase(),
          host: window.location.origin,
          selectedRole: values.memberType,
          organizationId: userDetails?.orgId,
          organizationName: getOrganization?.get_Organization?.name,
          inviterId: userDetails.userId
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            dispatch({
              type: 'ADD_PENDING',
              payload: {
                memberType: values.memberType.label,
                id: userDetails.userId,
                email: values.emailTo.toLowerCase(),
                updatedAt: getDate(),
                organizationId: userDetails?.orgId,
                organizationName: getOrganization?.get_Organization?.name,
                currentLoggedInUserMemberType: userDetails.roleName,
                date: getDate(),
                invitationStatus: 'pending'
              }
            });
            toast.success('INVITE SENT SUCCESSFULY', {
              id: toastId
            });
            setIsSubmitting(false);
            toggle();
          } else if (response.data.statusCode === 503) {
            toast.error('Account with this Email already exists', {
              id: toastId
            });
            setIsSubmitting(false);
          } else {
            toast.error('ERROR WHILE SENDING INVITE');
            setIsSubmitting(false);
          }
        });
    } catch (error) {
      console.error('ERROR OCCURED WHILE SENDING INVITE', error);
      toast.dismiss(toastId);
      toast.error('ERROR WHILE SENDING INVITE');
      setIsSubmitting(false);
    }
  };

  //depreciated -this code is for stripe checkout which is not used anymore
  const upgradePlan = async (plan?: 'month' | 'year') => {
    setDisableUpgradePlanButton(true);
    let toastId = toast.loading('Redirecting to checkout page');
    let priceId = plan === 'year' ? `${process.env.REACT_APP_YEARLY}` : `${process.env.REACT_APP_MONTHLY}`;
    await axiosInstance
      .post(`/payment/create-checkout-session`, {
        organizationId: userDetails.orgId,
        priceId: priceId,
        numberOfSeats: 2
      })
      .then((response) => {
        // console.log('response', response);
        window.location = `${response?.data?.url}`;

        // window.open(response?.data?.url, '_blank')
        // navigate(response?.data?.url)
      })
      .catch((error) => {
        console.error(error);
        toast.dismiss(toastId);
        toast.error('Something went wrong.');
      })
      .finally(() => {
        setDisableUpgradePlanButton(false);
      });
  };

  const AWAITING_USERS_COLUMN = [
    {
      Header: 'Member Name',
      accessor: 'userName',
      Cell: (props) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomAvatar round={true} size="25" name={props.cell.row.original?.userName} textSizeRatio={2} />
          {/* {props.cell.row.original?.userName} */}
        </div>
      )
    },
    {
      Header: 'MemberType',
      accessor: 'memberType',
      Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
    },
    {
      Header: ' ',
      accessor: '',
      Cell: ({ cell: { value } }) => 'Awaiting For Seat'
    },
    {
      Header: '',
      accessor: 'email',
      Cell: (props) =>
        isSuperAdmin ? <LightBlueButton onClick={() => assignSeat(props)}>Assign Seat</LightBlueButton> : null
    },
    {
      Header: '',
      accessor: 'id',
      Cell: () =>
        isSuperAdmin ? (
          <ToggleSettings>
            <OptionItem>Remove User</OptionItem>
          </ToggleSettings>
        ) : (
          <div style={{ height: '39px' }}></div>
        )
    }
  ];

  const COLUMNS = [
    {
      Header: 'Name',
      accessor: 'userName',
      tooltip: true,
      Cell: (props) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomAvatar round={true} size="25" name={props.cell.row.original?.userName} textSizeRatio={2} />
          {props.cell.row.original.currentLoggedInUserFullName === props.cell.row.original?.userName ? '(Myself)' : ''}
        </div>
      )
    },
    {
      Header: 'Email',
      accessor: 'email',
      tooltip: true,
      Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
    },
    {
      Header: 'Member Type',
      accessor: 'memberType',
      tooltip: true,
      Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
    },
    {
      Header: 'Seat Type',
      accessor: 'seatType',
      tooltip: true,
      Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      tooltip: true,
      Cell: ({ cell: { value } }) => (
        // <div className="text-wrap">
        <>{getDate(value)} </>
        // </div>
      )
    },
    {
      Header: ' ',
      accessor: 'id',
      Cell: (props) =>
        props.cell.row.original.currentLoggedInUserMemberType &&
        props.cell.row.original.currentLoggedInUserMemberType[0]?.roleName !== 'MEMBER' ? (
          <div style={{ width: '2rem' }}>
            <span>
              {props.cell.row.original.currentLoggedInUserId !== props.cell.row.original.id &&
              props.cell.row.original.memberType === 'MEMBER'
                ? isSuperAdmin && (
                    <ToggleSettings style={{ top: '0rem', marginLeft: '9.5rem' }}>
                      <OptionItem onClick={() => removeMemberFromOrganization(props.cell.row.original)}>
                        Remove Member
                      </OptionItem>
                      {isSuperAdmin && (
                        <OptionItem onClick={() => confirmRemoveSeatFromUser(props.cell.row.original)}>
                          Remove Seat from user
                        </OptionItem>
                      )}
                    </ToggleSettings>
                  )
                : null}
            </span>
          </div>
        ) : null
    }
  ];

  const PENDING_COLUMNS = [
    {
      Header: 'Email',
      accessor: 'email',
      tooltip: true,
      Cell: (props) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ alignItems: 'center', paddingLeft: '5px' }} className="text-wrap divider">
            {props.cell.row.original?.email.toLowerCase()}
          </span>
        </div>
      )
    },
    {
      Header: 'Member Type',
      accessor: 'memberType',
      Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
    },
    {
      Header: 'Status',
      accessor: 'invitationStatus',
      Cell: ({ cell: { value } }) => (
        <div className="text-wrap">
          <span style={{ color: '#93979F' }}>{value}</span>
        </div>
      )
    },
    {
      Header: ' ',
      accessor: 'updatedAt',
      tooltip: true,
      Cell: ({ cell: { value } }) => <div className="text-wrap">{getDate(value)}</div>
    },
    {
      Header: ' ',
      accessor: 'id',
      Cell: (props) =>
        props.cell.row.original.currentLoggedInUserMemberType &&
        props.cell.row.original.currentLoggedInUserMemberType[0]?.roleName !== 'MEMBER' ? (
          isSuperAdmin ? (
            <div style={{ width: '2rem' }}>
              <span>
                <ToggleSettings style={{ top: '0rem', marginLeft: '8rem' }}>
                  <OptionItem onClick={() => cancelInvite(props.cell.row.original)}>Cancel Invite</OptionItem>
                  <OptionItem onClick={() => resendInvite(props.cell.row.original)}>Resend Invite</OptionItem>
                </ToggleSettings>
              </span>
            </div>
          ) : null
        ) : null
    }
  ];

  const columns = useMemo(() => COLUMNS, [isRemoveSeatModalOpen]);
  const data = useMemo(() => state.currentOrganizationUsers, [state.currentOrganizationUsers]);
  const pendingColumns = useMemo(() => PENDING_COLUMNS, []);
  const pendingData = useMemo(() => state.pendingOrganizationUsers, [state.pendingOrganizationUsers]);
  const awaitingUsersColumns = useMemo(() => AWAITING_USERS_COLUMN, [isAssignSeatModalOpen]);
  const awaitingUsersData = useMemo(() => state.awaitingSeatOrganizationUsers, [state.awaitingSeatOrganizationUsers]);

  return {
    isOpen,
    isSuperAdmin,
    handleSubmit,
    initialvalues,
    validationSchema,
    loading,
    error,
    editOrganization,
    state,
    getOrganization,
    toggle,
    viewOrganization,
    columns,
    data,
    pendingColumns,
    pendingData,
    isSubmitting,
    orgUsers,
    userDetails,
    upgradePlan,
    disableUpgradePlanButton,
    awaitingUsersData,
    removeSeatFromUser,
    isRemoveSeatModalOpen,
    toggleRemoveSeatModal,
    handleRemoveSeatFromUser,
    seatAssigningTo,
    isAssignSeatModalOpen,
    isAssignSeatsForAllModalOpen,
    toggleAssignSeatsForAllModal,
    cancelAssigningSeat,
    assignSeatToUser,
    awaitingUsersColumns,
    submittingAssignSeatData,
    isInsufficientSeats,
    assignSeatToAllUsers,
    cancelAssigningSeatToAllUsers,
    submittingAssignSeatToAll,
    setSelectedRowIds,
    isAssignSeatToSelectedUsersModalOpen,
    submittingAssignSeatToSelectedData,
    toggleAssignSeatToSelectedUsersModal,
    assignSeatsToSelectedUsers,
    selectedRowIds,
    disableRemoveSeatButton,
    monthlySeats,
    yearlySeats,
    totalSeats,
    showConfirmForAssigningSeat,
    setShowConfirmForAssigningSeat,
    toggleAssignSeatModal,
    cancelAtPeriodEnd
  };
}

export default useGetOrganization;

// userRole[0]?.roleName === "SUPER_ADMIN"
