import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import CollectionValidationSchema from '../../../components/Validations/CollectionValidation';
import { updateCollectionMutation } from '../../../graphql/operations/Mutations/Library/FileMutations';
import { LIST_FILES_QUERY, SINGLE_COLLECTION } from '../../../graphql/operations/Queries/Library/FilesQueries';

function useEditCollection() {
  let navigate = useNavigate();
  let { collectionId } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const { state } = useContext(AppContext);
  const userId = state.userId;
  const {
    data: documentData,
    error,
    loading
  } = useQuery(LIST_FILES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `library_${userId}`,
      recordStatus: 0
    }
  });

  const [updateCollection] = useMutation(updateCollectionMutation);

  const {
    data: CollectionData,
    error: collectionError,
    loading: collectionLoading
  } = useQuery(SINGLE_COLLECTION, {
    variables: {
      operation: 'query',
      id: collectionId
    }
  });
  const initialState = {
    name: CollectionData?.get_Collection?.name,
    checkedFiles: CollectionData?.get_Collection?.files ?? []
  };

  const handleEditCollection = async (CollectionData: any) => {
    setSubmitting(true);
    const toastId = toast.loading('Editing collection.');
    try {
      let response = await updateCollection({
        variables: {
          libraryType: 'notesAndCollections',
          id: collectionId,
          name: CollectionData?.name,
          files: CollectionData?.checkedFiles
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Collection Edited.');
        setSubmitting(false);
        navigate(`/collections/${collectionId}`);
      }
    } catch (error) {
      console.error('Error in Updating Collection', error);
      toast.dismiss(toastId);
      toast.error('There was an error while editing collection.Try Again.');
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CollectionValidationSchema,
    enableReinitialize: true,
    onSubmit: handleEditCollection
  });

  return {
    formik,
    isSubmitting,
    documentData,
    error,
    loading,
    CollectionData,
    collectionError,
    collectionLoading
  };
}

export default useEditCollection;
