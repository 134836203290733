function getSortDate(data) {
  if (data && data?.length > 0) {
    const SortedData = data.slice().sort(function (itemOne, itemTwo) {
      let keyA = new Date(itemOne?.createdAt);
      let keyB = new Date(itemTwo?.createdAt);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    return SortedData;
  } else {
    return [];
  }
}

export default getSortDate;
