import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import Lottie from 'react-lottie';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import _ from 'lodash';
import Checkbox from '../../../components/Checkbox';
import { Formik, Form } from 'formik';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  FieldSet,
  InputWrapper
} from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../components/Reusable/NewFormik/TextArea1';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import CreateProjectValidation from '../../../components/Validations/CreateProjectValidation';
import useCreateProject from '../../../hooks/features/projects/useCreateProject';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  > * + * {
    margin: 10px;
  }
  .autoGenerateCode {
    margin-left: 1rem;
  }
`;

const CheckBoxText = styled.span`
  margin-left: 8px;
`;

type Values = {
  projectName?: string;
  sequentialCode: boolean;
  client?: string;
  description?: string;
  projectCode?: string | number;
};

function CreateProject() {
  let date = getDate();
  let time = getTime();
  const {
    initialValues,
    onSubmit,
    generateCode,
    updateCheckBoxClick,
    projectList,
    projectsError,
    projectsLoading,
    fullName,
    submitting
  } = useCreateProject();

  if (projectsLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (projectsError) return <ErrorPage />;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={CreateProjectValidation}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, handleChange, touched, errors }) => {
          return (
            <Form id="form1">
              <Header>
                <HeaderLeft>
                  <BackIcon />
                  <NewTitleLeft>
                    <PageTitle>Create New Project</PageTitle>
                  </NewTitleLeft>
                </HeaderLeft>
                <TitleRight>
                  <Button disabled={submitting} type="submit">
                    Create Project
                  </Button>
                </TitleRight>
              </Header>
              <Container>
                <FieldSet>
                  <div>
                    <InputWrapper>
                      <InputField
                        label="Project Name"
                        name="projectName"
                        id="projectName"
                        onChange={handleChange}
                        touched={touched.projectName}
                        errors={errors.projectName}
                        value={values.projectName}
                        disabled={submitting}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputField
                        label="Project Code"
                        name="projectCode"
                        id="projectCode"
                        onChange={handleChange}
                        touched={touched.projectCode}
                        errors={errors.projectCode}
                        value={values.projectCode}
                        disabled={submitting}
                      />
                    </InputWrapper>
                    {!_.isEmpty(projectList?.list_ProjectItems?._ProjectItems) ? (
                      <InputWrapper>
                        <Label>
                          <Checkbox
                            name="sequentialCode"
                            checked={values.sequentialCode}
                            onChange={(e) => updateCheckBoxClick(setFieldValue, e.target.checked)}
                          />
                          <CheckBoxText>Sequential Project Code</CheckBoxText>
                        </Label>
                      </InputWrapper>
                    ) : null}
                    <div className="autoGenerateCode">
                      {_.isEmpty(projectList?.list_ProjectItems?._ProjectItems) || values.sequentialCode === false ? (
                        <Button disabled={submitting} onClick={() => generateCode(setFieldValue)}>
                          Create a new code for me
                        </Button>
                      ) : null}
                    </div>
                    <InputWrapper>
                      <InputField
                        label="Client"
                        name="client"
                        id="client"
                        onChange={handleChange}
                        touched={touched.client}
                        errors={errors.client}
                        disabled={submitting}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <TextAreaField
                        label="Description"
                        name="description"
                        id="description"
                        onChange={handleChange}
                        touched={touched.description}
                        errors={errors.description}
                        value={values.description}
                        disabled={submitting}
                      />
                    </InputWrapper>
                  </div>
                  <div>
                    <AssigneeWrapper>
                      <Label>Created By</Label>
                      <AssigneeSeparator>
                        <AvatarStyles>
                          <CustomAvatar isTable={false} name={fullName} size={21} />
                        </AvatarStyles>
                        <Small>
                          {date}
                          {'  '}
                          {time}
                        </Small>
                      </AssigneeSeparator>
                    </AssigneeWrapper>
                  </div>
                </FieldSet>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default CreateProject;
