import styled from "@emotion/styled";
import ReactDOM from "react-dom";

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  /* margin-top: 20px; */
`;

const OverlayStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

type ModalProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  styles?: any
}

function Modal({ open, onClose, children, styles }: ModalProps) {
  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <OverlayStyles onClick={onClose} />
      <ModalWrapper style={styles}>{children}</ModalWrapper>
    </>,
    document.getElementById("modal")
  );
}

export default Modal;
