import styled from "@emotion/styled/macro";

type Inputs = {
  border: any;
  height: string;
  borderRadius: string;
  paddingLeft: string;
  marginTop: string;
};

type InputProps = {
  error?: boolean | string;
};

type TextAreaProps = {
  error?: boolean | string;
};

const inputStyles: Inputs = {
  //Almost all Inputs have this common styles.
  height: "2.8rem",
  border: "1px solid #ACADAC",
  borderRadius: "5px",
  paddingLeft: "10px",
  marginTop: "10px",
};

export const Input = styled.input<InputProps>`
  ${inputStyles}
  border:${(props) => (props.error ? "1px solid #FF3C3C" : "1px solid #ACADAC")}
`;

export const LinkInput = styled.input`
  width: 21.25rem;
  height: 49px;
  align-self: flex-start;
  ${inputStyles}
`;

export const DateInput = styled.input<InputProps>`
  border: ${(props) => (props.error ? "1px solid #FF3C3C" : "none")};
  border-radius: ${(props) => (props.error ? "5px" : "none")};
  cursor: pointer;
  ::-webkit-calendar-picker-indicator {
    //to remove deafult calendar icon (Works in chrome)
    position: absolute;
    width: 100%;
    color: transparent;
    background: transparent;
  }
`;

export const TextArea = styled.textarea<TextAreaProps>`
  min-width: 45vw;
  min-height: 7.3rem;
  max-width: 45vw;
  border-radius: 5px;
  border: 1px solid #acadac;
  padding-top: 5px;
  padding-left: 10px;
  border: ${(props) =>
    props.error ? "1px solid #FF3C3C" : "1px solid #ACADAC"};
`;

export const DescriptionInput = styled.textarea<TextAreaProps>`
  /* min-width: 45vw; */
  min-height: 100px;
  margin-top: 10px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #acadac;
  padding-top: 5px;
  padding-left: 10px;
  border: ${(props) =>
    props.error ? "1px solid #FF3C3C" : "1px solid #ACADAC"};
`;
