import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance';

// fetch the
export const fetchOrganizationMembers = createAsyncThunk('users/fetchUsers', async (orgId: String) => {
  const result = await axiosInstance.get(`/users/?organizationId=${orgId}&forChat=${true}`);
  return result?.data?.data;
});

export const fetchOrgMembers = createAsyncThunk('users/orgUsers', async (orgId: String) => {
  const result = await axiosInstance.get(`/users/?organizationId=${orgId}`);
  return result?.data?.data;
});

const orgMembersSlice = createSlice({
  name: 'members',
  initialState: {
    users: [],
    orgUsers: [],
    loading: false,
    error: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationMembers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchOrganizationMembers.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.users = [];
      })
      .addCase(fetchOrganizationMembers.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchOrgMembers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchOrgMembers.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.users = [];
      })
      .addCase(fetchOrgMembers.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      });
  }
});

export default orgMembersSlice;
