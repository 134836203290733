import styled from '@emotion/styled';

export const selectNotebookStyles = {
  control: (base: any) => ({
    ...base,
    border: '1px solid #E5E5E5',
    boxShadow: 'none',
    width: '175px'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    color: isFocused ? 'white' : '#929292',
    background: isFocused ? '#2F3F48' : '#F3F3F3',
    whiteSpace: 'wrap',
    lineHeight: '25px'
  })
};

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  .input-container {
    display: flex;
    align-items: center;
    position: relative;
    transition: 0.5s cubic-bezier(0.17, 0.67, 0.85, 0.26);
  }

  .search-input {
    border: 1px solid #e5e5e5;
    padding: 5px 10px;
    padding-left: 40px;
    border-radius: 7px;
    width: 250px;
    height: 35px;
  }

  .search-icon {
    cursor: pointer;
    position: absolute;
    left: 0px;
  }

  .show {
    visibility: visible;
    opacity: 1;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
  }
`;
