import styled from '@emotion/styled';
import React from 'react';
import { CustomButton } from '../../CustomButton';

const EmptyMessageStyles = styled.div`
  margin-left: 45px;
  margin-right: 45px;
  .quickbar-section {
    background: #f6f5f5;
    border-radius: 5px;
    width: 100%;
    height: 7.06rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    /* opacity: 30%; */
    margin-top: 1.5rem;
    &-lg {
      height: auto;
      padding: 2rem 0;
    }
    .title-bold {
      opacity: 1;
      margin-bottom: 1rem;
      font-weight: 600;
    }
    .title {
      opacity: 30%;
    }
    .subtitle {
      color: #1c1c1c;
      font-weight: 400;
      text-align: center;
      margin-bottom: 20px;
    }
    .button {
      margin-top: 10px;
    }
  }
`;

type TrialProps = {
  disabledButtonState: boolean;
  upgradePlan: () => Promise<void>;
};

function TrialEnd(props: TrialProps) {
  return (
    <EmptyMessageStyles>
      <div className="quickbar-section quickbar-section-lg">
        <span className="title-bold">Your Trial has Ended</span>
        <span className="subtitle">
          Please add seats to your organization to continue <br />
          using the collaborative space
        </span>
        <span className="button">
          <CustomButton
            disabled={props.disabledButtonState}
            onClick={() => props.upgradePlan()}
            style={{ backgroundColor: '#4C6D66' }}
          >
            Upgrade Plan
          </CustomButton>
        </span>
      </div>
    </EmptyMessageStyles>
  );
}

export default TrialEnd;
