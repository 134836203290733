import styled from '@emotion/styled';
import React, { MutableRefObject, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { AppContext } from '../../../../AppContext';
import Button from '../../../CustomButton';
import Modal from '../../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../styles/ModalStyles';
import { ModalText } from '../../../../pages/Workspaces/Registers/CreateNewRegister';
import { Member } from './AttendeeListModal';
import CreatableSelect from 'react-select/creatable';
import { getOrganizationUsers } from '../../../../lib/getOrganizationUsers';
import { useNavigate, useParams } from 'react-router';
import { MeetingNoteI, SectionI } from '../../../../pages/MeetingNotes/types';

export interface MemberWithPermission extends Member {
  permission: { value: string; label: string };
  isAttendee: boolean;
}


const permissionOptions = [
  { value: 'viewer', label: 'Viewer' },
  { value: 'editor', label: 'Editor' }
];

const InputContainer = styled.div`
  width: 464px;
  /* height: 61px; */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f5;
  border-radius: 5px;
  margin: auto;
  font-size: 16px;

  .attendee-select {
    /* flex-grow: 4; */
    width: 75%;
    margin-right: 10px;
  }

  .permission-select {
    border: none;
    width: 25%;
    /* flex-grow: 1; */
  }
`;

const UserDetails = styled.div`
  background-color: #f6f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 464px;
  height: 44px;
  margin: auto;
  padding: 16px 10px;
  margin-top: 10px;
  border-radius: 5px;

  .right-item {
    display: flex;
    align-items: center;
    width: 45%;
    p {
      color: #1c1c1c66;
      flex-grow: 2;
    }

    .permission-select {
      flex-grow: 4;
    }
  }
`;

const SharedListModal = ({
  isOpen,
  toggleModal,
  selectedAttendees,
  sections,
  handlePublishNote,
  saveNoteAsDraft,
  organizationID,
  meetingNoteData
}: {
  isOpen: boolean;
  toggleModal: () => void;
  selectedAttendees: MutableRefObject<any[]>;
  sections: SectionI[];
  handlePublishNote: (
    meetingNoteData: MeetingNoteI,
    selectedMembers: any,
    selectedPermission: any,
    selectedAttendees: any
  ) => void;
  saveNoteAsDraft: (meetingNoteId: string, showLoading?: boolean) => Promise<void>;
  organizationID: string;
  meetingNoteData: MeetingNoteI;
}) => {
  const {
    state: { userId }
  } = useContext(AppContext);
  let { noteId } = useParams();
  const navigate = useNavigate();

  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [selectedPermission, setSelectedPermission] = useState<{ value: string; label: string } | null>({
    value: 'viewer',
    label: 'Viewer'
  });

  useEffect(() => {
    fetchOrgUserList();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [selectedAttendees.current]);

  const fetchOrgUserList = async () => {
    const users = await getOrganizationUsers(organizationID, userId);
    let memberList = users?.filter((user: Member) => !user.label.includes('Myself'));

    setMembers(memberList);
  };

  const filterUsers = () => {
    const originalArray = members;
    const itemsToBeRemoved = selectedAttendees.current;
    const memberList = originalArray.filter(
      (item: Member) => !itemsToBeRemoved.some((itemToBeRemoved) => itemToBeRemoved.id === item.id)
    );
    setMembers(memberList);
  };

  const handleChange = (options: any) => {
    setSelectedMembers(options);
  };

  const publishNote = async () => {
    // await saveNoteAsDraft(noteId as string,false);
    await handlePublishNote(meetingNoteData, selectedMembers, selectedPermission, selectedAttendees);
    toggleModal();
    navigate(`/meetingnotes/${noteId}`);
  };

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="528px">
        <ModalTitle>Shared List</ModalTitle>
        <ModalBody>
          <ModalText>Search for user or enter email to share this note</ModalText>
          <InputContainer>
            <CreatableSelect
              isMulti
              className="attendee-select"
              options={members}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              onChange={handleChange}
              placeholder="Search Users"
              formatCreateLabel={(userInput) => `Share with ${userInput}`}
            />

            <Select
              options={permissionOptions}
              defaultValue={permissionOptions[0]}
              name="permission"
              className="permission-select"
              components={{ IndicatorSeparator: () => null }}
              onChange={(option) => {
                setSelectedPermission(option);
              }}
            />
          </InputContainer>
          <div>
            {selectedAttendees.current.map((member) => (
              <UserDetails key={member.id}>
                <p>{member.label}</p>
                <div className="right-item">
                  <p>Attendee</p>
                  <Select
                    options={permissionOptions}
                    defaultValue={member.permission}
                    name="permission"
                    className="permission-select"
                    components={{ IndicatorSeparator: () => null }}
                    onChange={(option) => {
                      selectedAttendees.current = selectedAttendees.current.map((user) => {
                        if (user.id === member.id) return { ...user, permission: option };
                        return user;
                      });
                    }}
                  />
                </div>
              </UserDetails>
            ))}
          </div>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={publishNote}>Share</Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default SharedListModal;
