import styled from '@emotion/styled';
// import { Label } from 'aws-amplify-react';
import React from 'react';
import Lottie from 'react-lottie';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import { DarkGreenButton } from '../../../components/styles/ButtonStyles';
import { InputWrapper } from '../../../components/styles/FormStyles';
import { Label } from '../../../components/styles/Label';
import { Text } from '../../../components/styles/Text';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import useGetRegisterSummary from '../../../hooks/features/registers/useGetRegisterSummary';
import { DescriptionText } from '../../Library/FilesView/Files/ViewFile/FileInformation/AboutFile';
import { ErrorPage } from '../../Others';
import { Container } from '../../Projects/ProjectViews/CreateProject';

const NoteCardStyles = styled.div`
  border: 1px solid var(--table-border);
  max-width: 80%;
  border-radius: 5px;
  p {
    font-weight: 600;
  }
`;

const NoteTitleStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function RegisterSummaryPage() {
  const {
    loading,
    workspaceLoading,
    error,
    workspaceError,
    projectData,
    workspaceData,
    createRegisterNote,
    registerData,
    registerError,
    registerLoading,
    editRegisterNote,
    removeRegisterNote,
    registerDescription
  } = useGetRegisterSummary();

  if (error || workspaceError || registerError) return <ErrorPage />;
  if (loading || workspaceLoading || registerLoading)
    return <Lottie options={defaultOptions} width={400} height={500} />;
  let ProjectName = projectData?.get_Project?.name;
  let workspaceName = workspaceData?.get_Workspace?.name;
  // let registerNote = registerData?.get_Register?.registerDescription
  return (
    <>
      <Container>
        <div>
          <InputWrapper>
            <Label>Project Name:</Label>
            <Text>{ProjectName}</Text>
          </InputWrapper>
          <InputWrapper>
            <Label>Workspace Name:</Label>
            <Text>{workspaceName}</Text>
          </InputWrapper>
          {registerDescription ? (
            <NoteCardStyles>
              <InputWrapper>
                <NoteTitleStyles>
                  <Label>Note:</Label>
                  <ToggleSettings>
                    <OptionItem onClick={() => editRegisterNote(registerDescription)}>Edit Note</OptionItem>
                    <OptionItem onClick={() => removeRegisterNote()}>Remove Note</OptionItem>
                  </ToggleSettings>
                </NoteTitleStyles>

                <DescriptionText>{registerDescription}</DescriptionText>
              </InputWrapper>
            </NoteCardStyles>
          ) : (
            <DarkGreenButton onClick={createRegisterNote}>Add Note</DarkGreenButton>
          )}
        </div>
      </Container>
    </>
  );
}

export default RegisterSummaryPage;
