// This component is for creating a register and adding to a workspace which just got created.(Its used during the workspace creation phase).
// There is a similar component where we are creatingAndAdding Register to an already existing Workspace.(CreateAndAddRegisterToWorkspace.tsx).
import styled from '@emotion/styled';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Button from '../../../components/CustomButton';
import Modal from '../../../components/Modal';
import axios from '../../../axiosInstance';
import { Formik, Form, FormikProps, FieldArray, Field, ErrorMessage } from 'formik';
import { FileOptionStyles } from '../../../components/styles/FileOptionsStyles';
import { ChoicesWrapper, InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { DescriptionInput, Input } from '../../../components/styles/Inputs';
import { Label } from '../../../components/styles/Label';
import { registerTypeStyles } from '../../../components/styles/RegisterTypeStyles';
import { AllOption, FileOptions } from '../../../constants/FileOptions';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { components } from 'react-select';
import { useToggle } from '../../../lib/UseToggle';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../components/styles/ModalStyles';
import { useMutation } from '@apollo/client';
import { InitiatorOptions } from '../../../constants/InitiatorAndResponderOptions';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import { InputField } from '../../../components/Reusable/NewFormik/Input';
import { CreateRegisterValidationSchema } from '../../../components/Validations/CreateRegisterValidation';
import { RegisterTypeOptions } from '../../../constants/RegisterTypeOptions';
import { DarkButton, GreyButton } from '../../../components/styles/ButtonStyles';
import { InitiatorAndResponderStyles } from '../../../components/styles/InitiatorAndResponderStyles';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { InitiatorAndResponderRoleStyles } from '../../../components/styles/InitiatorAndResponderDropDownStyles';
import CloseIcon from '../../../assets/SvgComponents/CloseIcon';
import { DropdownErrorText, ErrorText } from '../../../components/styles/Text';
import TextError from '../../../components/Reusable/NewFormik/TextError';
import toast from 'react-hot-toast';
import { createRegisterMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';
import { useLocation } from 'react-router';

const RegisterNameStyles = styled.div`
  display: flex;
  justify-content: space-between;
  /* border: 1px solid red; */
  padding-left: 10px;
  > div {
    //Apply the style to only first div ie-Inputwrapper which is a div.
    flex: 0 0 70%;
  }
`;

const StatusOptionsContainer = styled.div`
  width: 484px;
  background: #e9eaec;
  min-height: 209px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.9rem;
  .add-new-option {
    align-self: flex-start;
    margin-left: 25px;
    margin-bottom: 15px;
  }
`;

const FormGroupStyles = styled.div`
  width: 90%;
  background: white;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .close-icon {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 25px;
    margin-bottom: 10px;
    .remove-button {
      display: flex;
      /* align-self: center; */
    }
    svg {
      display: inline-block;
      align-self: center;
      margin-left: 10px;
    }
  }
  .form-group {
    display: flex;
    align-items: center;
    margin-top: 5px;
    .form-input {
      margin-left: 50px;
      flex: 1;
      input {
        width: 100%;
      }
    }
  }
  .form-group-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 20px;
  }
  label {
    display: block;
  }
`;

const RegisterSettingsWrapper = styled.div`
  display: flex;
  margin-left: 1.625rem;
  justify-content: space-between;
  /* border: 1px solid blue; */
  padding: 0 10px;
  width: 80%;
`;
const InitiatorSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ResponderSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalText = styled.p`
  font-weight: 400;
  text-align: center;
`;
export const ModalInput = styled(Input)`
  display: block;
  margin: 0 auto;
  width: 464px;
  margin-bottom: 21px;
`;

// export const Form = styled.form``

interface Values {
  registerName: string;
  registerType: string;
  initiatorRole: string;
  responderRole: string;
  fileFormat: string;
  initiatorStatusOptions: any;
  responderStatusOptions: any;
}

function CreateNewRegister() {
  let navigate = useNavigate();
  let { projectId } = useParams();
  const [isOpen, toggle] = useToggle(false);
  const { state } = useContext(AppContext);
  const { state: RouterState } = useLocation();
  const userId = state.userId;
  const [roleOptions, setRoleOptions] = useState([]);
  // const {loading, error, }
  const [registerType, setRegisterType] = useState('initiator');
  const [role, setRole] = useState('');
  const [createRegister, loading] = useMutation(createRegisterMutation, {
    onCompleted: (data) => {}
  });
  const openInitiatorModal = () => {
    setRegisterType('initiator');
    toggle();
  };

  const openResponderModal = () => {
    setRegisterType('responder');
    toggle();
  };

  const handleGoToPreviousPage = () => {
    if (RouterState.existingWorkspace) {
      navigate(`/project/workspace/${projectId}/${RouterState.workspaceId}/editregisterlist`, {
        state: {
          workspaceId: RouterState.workspaceId
        }
      });
    } else {
      navigate(`/newprojects/workspaceconfig/${projectId}/new`, {
        state: {
          workspaceId: RouterState.workspaceId,
          workspaceName: RouterState.workspaceName
        }
      });
    }
  };

  const getRoles = () => {
    axios
      .get(`/roles?entityType=WORKSPACE&entityId=${RouterState?.workspaceId}`)
      .then((res) => {
        let options = res.data.data.map((role) => {
          return {
            value: role.roleName,
            label: role.roleName,
            id: role.id,
            role: { value: role.roleName, id: role.id }
          };
        });
        setRoleOptions(options);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);

  const addRole = () => {
    let toastId = toast.loading('Creating', {
      style: {
        zIndex: 2000
      }
    });
    let data = {
      entityType: 'WORKSPACE',
      roleName: role,
      entityId: RouterState.workspaceId
    };

    axios
      .post(`/roles`, data)
      .then((res) => {
        setRoleOptions([...roleOptions, { value: role, label: role }]);
        setRole('');
        axios.get(`/roles?entityType=WORKSPACE&entityId=${RouterState?.workspaceId}`).then((res) => {
          let options = res.data.data.map((role) => {
            return {
              value: role.roleName,
              label: role.roleName,
              id: role.id,
              role: { value: role.roleName, id: role.id }
            };
          });
          setRoleOptions(options);
          toast.success('Role Created', {
            id: toastId
          });
          toggle();
        });
      })
      .catch((err) => {
        console.error('ERROR', err);
        toast.error('OOPS! Something went Wrong Try Again');
      });
  };

  const handleSubmit = async (values: any) => {
    let toastId = toast.loading('Creating Register');
    // let fileArray = values.fileFormat.map((format) => format.value);
    let finalValues = {
      // fileFormats: fileArray,
      initiatorRole: values.initiatorRole,
      initiatorRoleId: values.initiatorRoleId,
      responderRole: values.responderRole,
      responderRoleId: values.responderRoleId,
      registerType: values.registerType,
      projectId: projectId,
      name: values.registerName,
      initiatorStatusOptions: values.initiatorStatusOptions,
      responderStatusOptions: values.responderStatusOptions,
      createdAt: new Date().toISOString(),
      createdBy: userId,
      workspaceId: RouterState.workspaceId,
      organizationId: state.orgId
    };
    try {
      if (finalValues.registerType === 'static') {
        let variables = {
          ...finalValues,
          initiatorStatusOptions: [],
          responderStatusOptions: [],
          initiatorRole: '',
          responderRole: '',
          initiatorRoleId: '',
          responderRoleId: ''
        };

        const res = await createRegister({
          variables: variables
        });
        if (res) {
          toast.success('Register Created', {
            id: toastId
          });
          if (RouterState.existingWorkspace) {
            navigate(`/project/workspace/${projectId}/${RouterState.workspaceId}/editregisterlist`, {
              state: {
                workspaceId: RouterState.workspaceId,
                registerData: {
                  ...variables,
                  _id: res.data.add_Register.result._id
                }
              }
            });
          } else {
            navigate(`/newprojects/workspaceconfig/${projectId}/new`, {
              state: {
                workspaceId: RouterState.workspaceId,
                workspaceName: RouterState.workspaceName,
                description: RouterState.description,
                registerData: {
                  ...variables,
                  _id: res.data.add_Register.result._id
                }
              }
            });
          }
        }
      } else if (finalValues.registerType === 'dynamic') {
        const res = await createRegister({
          variables: {
            ...finalValues
          }
        });
        if (res) {
          toast.success('Register Created', {
            id: toastId
          });
          if (RouterState.existingWorkspace) {
            navigate(`/project/workspace/${projectId}/${RouterState.workspaceId}/editregisterlist`, {
              state: {
                workspaceId: RouterState.workspaceId,

                registerData: {
                  ...finalValues,
                  _id: res.data.add_Register.result._id
                }
              }
            });
          } else {
            navigate(`/newprojects/workspaceconfig/${projectId}/new`, {
              state: {
                workspaceId: RouterState.workspaceId,
                workspaceName: RouterState.workspaceName,
                description: RouterState.description,
                registerData: {
                  ...finalValues,
                  _id: res.data.add_Register.result._id
                }
              }
            });
          }
        }
      }
    } catch (error) {
      console.error('ERROR', error);
      toast.dismiss(toastId);
      toast.error('OOPS! Something went wrong from our end. Do try again');
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          registerName: '',
          registerType: RegisterTypeOptions[0].value,
          initiatorRole: '',
          responderRole: '',
          initiatorRoleId: '',
          responderRoleId: '',
          // fileFormat: [],
          initiatorStatusOptions: [{ status: '', statusType: 'open', description: '' }],
          responderStatusOptions: [{ status: '', statusType: 'open', description: '' }]
        }}
        validationSchema={CreateRegisterValidationSchema}
        onSubmit={handleSubmit}
      >
        {(props: FormikProps<Values>) => (
          <Form>
            <Header>
              <HeaderLeft>
                <BackIcon handlenavigate={handleGoToPreviousPage} />
                <NewTitleLeft>
                  <PageTitle>Create New Register</PageTitle>
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <Button type="submit">Save Register</Button>
              </TitleRight>
            </Header>
            <Container>
              <RegisterNameStyles>
                <InputWrapper>
                  <Label htmlFor="registerName">Register Name</Label>
                  <InputField type="text" name="registerName" id="registerName" disabled={loading.loading} />
                </InputWrapper>
              </RegisterNameStyles>
              {/* <InputWrapper>
                <Label htmlFor="fileFormat">File-Format</Label>
                <Select
                  name="fileFormat"
                  id="fileFormat"
                  value={props.values.fileFormat}
                  isMulti={true}
                  options={[...AllOption, ...FileOptions]}
                  styles={FileOptionStyles}
                  menuPortalTarget={document.body}
                  onChange={(selectedOption) => {
                    if (
                      selectedOption !== null &&
                      selectedOption.length > 0 &&
                      selectedOption[selectedOption.length - 1].value === 'all'
                    ) {
                      props.setFieldValue('fileFormat', FileOptions);
                    } else {
                      props.setFieldValue('fileFormat', selectedOption);
                    }
                  }}
                // value={props.values.fileFormat}
                ></Select>
                {props.touched['fileFormat'] && props.errors['fileFormat'] ? (
                  <ErrorText>{props.errors['fileFormat']}</ErrorText>
                ) : null}
              </InputWrapper> */}

              <ChoicesWrapper>
                <Label htmlFor="registerType">Register Type</Label>
                <Select
                  label="Register-Type"
                  name="registerType"
                  id="registerType"
                  isSearchable={false}
                  options={RegisterTypeOptions}
                  defaultValue={RegisterTypeOptions[0]}
                  styles={registerTypeStyles}
                  menuPortalTarget={document.body}
                  onChange={(selectedOption) => {
                    props.setFieldValue('registerType', selectedOption.value);
                  }}
                  isDisabled={loading.loading}
                ></Select>
              </ChoicesWrapper>
            </Container>
            <RegisterSettingsWrapper>
              <InitiatorSettingsWrapper>
                {props.values.registerType === 'dynamic' && (
                  <>
                    <ChoicesWrapper>
                      <Label htmlFor="initiatorRole">Register Initiator</Label>
                      <Select
                        name="initiatorRole"
                        id="initiatorRole"
                        clickHandler={openInitiatorModal}
                        options={roleOptions}
                        styles={InitiatorAndResponderRoleStyles}
                        components={{ MenuList: SelectMenuButton }}
                        menuPortalTarget={document.body}
                        onChange={(selectedOption) => {
                          props.setFieldValue('initiatorRole', selectedOption.value);
                          props.setFieldValue('initiatorRoleId', selectedOption.id);
                        }}
                        isDisabled={loading.loading}
                      />
                    </ChoicesWrapper>
                    {props.touched['responderRole'] && props.errors['initiatorRole'] ? (
                      <DropdownErrorText>{props.errors['initiatorRole']}</DropdownErrorText>
                    ) : null}
                    <InputWrapper>
                      <Label>Initiator Status Options</Label>
                      <StatusOptionsContainer>
                        <FieldArray name="initiatorStatusOptions">
                          {({ insert, remove, push }) => (
                            <>
                              {props.values.initiatorStatusOptions.map((option, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <FormGroupStyles>
                                      <div className="form-group">
                                        <Label>Status</Label>
                                        <div className="form-input">
                                          <Field name={`initiatorStatusOptions.${index}.status`}>
                                            {({ field, form, meta }) => (
                                              <>
                                                <Input
                                                  type="text"
                                                  placeholder="Enter Status Option"
                                                  {...field}
                                                  disabled={loading.loading}
                                                ></Input>
                                                {meta.touched && meta.error && (
                                                  <ErrorMessage
                                                    component={TextError}
                                                    name={`initiatorStatusOptions.${index}.status`}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <Label>Type</Label>
                                        <div className="form-input">
                                          <Field name={`initiatorStatusOptions.${index}.statusType`}>
                                            {({ field, form, meta }) => {
                                              return (
                                                <>
                                                  <Select
                                                    name={`initiatorStatusOptions.${index}.statusType`}
                                                    options={InitiatorOptions}
                                                    defaultValue={InitiatorOptions[0]}
                                                    styles={InitiatorAndResponderStyles}
                                                    menuPortalTarget={document.body}
                                                    onChange={(selectedOption) => {
                                                      props.handleChange(`initiatorStatusOptions.${index}.statusType`)(
                                                        selectedOption.value
                                                      );
                                                    }}
                                                    value={InitiatorOptions.find(
                                                      (option) =>
                                                        option.value ===
                                                        props.values.initiatorStatusOptions[index].statusType
                                                    )}
                                                    isDisabled={loading.loading}
                                                  ></Select>
                                                </>
                                              );
                                            }}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="form-group-column">
                                        <Label>Description</Label>
                                        <Field name={`initiatorStatusOptions.${index}.description`}>
                                          {({ field, form, meta }) => (
                                            <>
                                              <DescriptionInput
                                                {...field}
                                                placeholder="A brief about what the option means"
                                                disabled={loading.loading}
                                              ></DescriptionInput>
                                              {meta.touched && meta.error && (
                                                <ErrorMessage
                                                  component={TextError}
                                                  name={`initiatorStatusOptions.${index}.description`}
                                                />
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                      {index !== 0 && (
                                        <div className="close-icon">
                                          <GreyButton
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="remove-button"
                                          >
                                            Remove Option
                                            <CloseIcon fill="#4C6D66" />
                                          </GreyButton>
                                        </div>
                                      )}
                                    </FormGroupStyles>
                                  </React.Fragment>
                                );
                              })}
                              <DarkButton
                                type="button"
                                className="add-new-option"
                                onClick={() => push({ status: '', statusType: 'open' })}
                              >
                                Add New Option
                              </DarkButton>
                            </>
                          )}
                        </FieldArray>
                      </StatusOptionsContainer>
                    </InputWrapper>
                  </>
                )}
              </InitiatorSettingsWrapper>
              <ResponderSettingsWrapper>
                {props.values.registerType === 'dynamic' && (
                  <>
                    <ChoicesWrapper>
                      <Label htmlFor="responderRole">Register Responder</Label>
                      <Select
                        name="responderRole"
                        id="responderRole"
                        clickHandler={openResponderModal}
                        components={{ MenuList: SelectMenuButton }}
                        options={roleOptions}
                        styles={InitiatorAndResponderRoleStyles}
                        menuPortalTarget={document.body}
                        onChange={(selectedOption) => {
                          props.setFieldValue('responderRole', selectedOption.value);
                          props.setFieldValue('responderRoleId', selectedOption.id);
                        }}
                        isDisabled={loading.loading}
                      />
                    </ChoicesWrapper>
                    {props.touched['responderRole'] && props.errors['responderRole'] ? (
                      <DropdownErrorText>{props.errors['responderRole']}</DropdownErrorText>
                    ) : null}

                    <InputWrapper>
                      <Label>Responder Status Options</Label>
                      <StatusOptionsContainer>
                        <FieldArray name="responderStatusOptions">
                          {({ insert, remove, push }) => (
                            <>
                              {props.values.responderStatusOptions.map((option, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <FormGroupStyles>
                                      <div className="form-group">
                                        <Label>Status</Label>
                                        <div className="form-input">
                                          <Field name={`responderStatusOptions.${index}.status`}>
                                            {({ field, form, meta }) => (
                                              <div>
                                                <Input
                                                  type="text"
                                                  {...field}
                                                  placeholder="Enter Status Option"
                                                  disabled={loading.loading}
                                                ></Input>
                                                {meta.touched && meta.error && (
                                                  <ErrorMessage
                                                    component={TextError}
                                                    name={`responderStatusOptions.${index}.status`}
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <Label>Type</Label>
                                        <div className="form-input">
                                          <Field name={`responderStatusOptions.${index}.statusType`}>
                                            {({ field, form, meta }) => (
                                              <>
                                                <Select
                                                  name={`responderStatusOptions.${index}.statusType`}
                                                  options={InitiatorOptions}
                                                  defaultValue={InitiatorOptions[0]}
                                                  styles={InitiatorAndResponderStyles}
                                                  menuPortalTarget={document.body}
                                                  onChange={(selectedOption) => {
                                                    props.handleChange(`responderStatusOptions.${index}.statusType`)(
                                                      selectedOption.value
                                                    );
                                                  }}
                                                  isDisabled={loading.loading}
                                                ></Select>
                                              </>
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="form-group-column">
                                        <Label>Description</Label>
                                        <Field name={`responderStatusOptions.${index}.description`}>
                                          {({ field, form, meta }) => (
                                            <>
                                              <DescriptionInput
                                                {...field}
                                                placeholder="A brief about what the option means"
                                                disabled={loading.loading}
                                              ></DescriptionInput>
                                              {meta.touched && meta.error && (
                                                <ErrorMessage
                                                  component={TextError}
                                                  name={`responderStatusOptions.${index}.description`}
                                                />
                                              )}
                                            </>
                                          )}
                                        </Field>
                                      </div>
                                      {index !== 0 && (
                                        <div className="close-icon">
                                          <GreyButton
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="remove-button"
                                          >
                                            Remove Option
                                            <CloseIcon fill="#4C6D66" />
                                          </GreyButton>
                                        </div>
                                      )}
                                    </FormGroupStyles>
                                  </React.Fragment>
                                );
                              })}
                              <DarkButton
                                type="button"
                                className="add-new-option"
                                onClick={() => push({ status: '', statusType: 'open' })}
                              >
                                Add New Option
                              </DarkButton>
                            </>
                          )}
                        </FieldArray>
                      </StatusOptionsContainer>
                    </InputWrapper>
                  </>
                )}
              </ResponderSettingsWrapper>
            </RegisterSettingsWrapper>
            <Modal open={isOpen} onClose={toggle}>
              <ModalContainer width="528px">
                <ModalTitle>New Role</ModalTitle>
                <ModalBody>
                  <ModalText>Enter new {`${registerType}`} role</ModalText>
                  <ModalInput
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    type="text"
                    placeholder="Enter New Role"
                  ></ModalInput>
                  <ModalWrapper>
                    <Button onClick={toggle} secondary>
                      Cancel
                    </Button>
                    <Button disabled={role === ''} onClick={addRole}>
                      Confirm
                    </Button>
                  </ModalWrapper>
                </ModalBody>
              </ModalContainer>
            </Modal>
          </Form>
        )}
      </Formik>
      {/* <Toaster
        toastOptions={{
          success: { ...SuccessStyles, duration: 3000 },
          error: { ...errorStyles },
        }}
      /> */}
    </>
  );
}

export default CreateNewRegister;

const OptionButton = styled.button`
  height: '30px';
  color: #4c6d66;
  &:hover {
    /* background: grey; */
    cursor: pointer;
  }
  &::focus {
    background: #2f3f48;
  }
`;

const SelectMenuButton = (props) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <OptionButton as="option" onClick={props.selectProps.clickHandler}>
        {' '}
        + Add new{' '}
      </OptionButton>
    </components.MenuList>
  );
};
