import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { formats } from '../../../../../components/Files/textEditorConfig';
import * as ReactQuill from 'react-quill';
import Button from '../../../../../components/CustomButton';
import { MdSend } from 'react-icons/md';
import { AppContext } from '../../../../../AppContext';
import { TASK_DISCUSSION_QUERY } from '../../../../../graphql/operations/Queries/Tasks/TaskQueries';
import { SINGLE_TASK_QUERY } from '../../../../../graphql/operations/Queries/Tasks/TaskQueries';
import { ErrorPage } from '../../../../Others/ErrorPage';
import axiosInstance from '../../../../../axiosInstance';
import Avatar from 'react-avatar';
import { useMutation, useQuery } from '@apollo/client';
import { addDiscussionMutation } from '../../../../../graphql/operations/Mutations/Tasks/TaskDiscussionsMutations';
import { getFullNameById } from '../../../../../lib/getFullName';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useToggle } from '../../../../../lib/UseToggle';
import AssociatedTaskModal from '../../../../../components/Modals/AssociatedTaskModal';

// import AvatarPicture from "../../assets/images/avatarPic.svg";
// import useForm from "../../lib/UseForm";

export const Container = styled.div`
  background: rgba(236, 236, 228, 0.5);
  padding: 30px;
  height: 30rem;
  overflow-y: scroll;
  .UserMessage {
    .PicWithChat {
      display: flex;
      align-items: baseline;
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
      .profile-logo {
        margin: 10px;
      }
      .userChat {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        max-width: 50%;
        .message {
          padding: 0px;
          font-size: 14px;
          background: #ffffff;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }

    .UsermessageDateTime {
      display: flex;
      justify-content: flex-start;
      margin-left: 3.2%;
      margin-top: -20px;
      color: #93979f;
      font-size: 10px;
      /* line-height: 16px; */
    }
  }

  .MyMessage {
    .myChat {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      .message {
        margin-right: 0%;
        padding: 0px;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 14px;
        background: rgba(244, 195, 112, 0.3);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        max-width: 50%;
      }
    }
    .messageDateTime {
      display: flex;
      margin-right: -1.5%;
      justify-content: flex-end;
      color: #93979f;
      font-size: 10px;
      margin-top: -20px;
      /* line-height: 16px; */
    }
  }
`;

const Editor = styled.div`
  margin-bottom: 50px;
  display: flex;
  .EditorView {
    background: #ffffff;
    width: 100%;
    margin-top: 1rem;
    margin-left: 3rem;
    margin-right: 0;
    position: relative;
  }
  .editor-container {
    height: 60px;
    background: #f3f3f3;
    opacity: 0.5;
    border: 1px solid #acadac;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 70%;
    transform: translateY(100%);
    border: none;
  }
  ::placeholder {
    background-color: black;
  }
`;

const DateStyle = styled.p`
  margin-right: 20px;
`;
// const Time = styled.p``;

type ButtonProps = {
  isSending: boolean;
  disabled: boolean;
};

const SubmitButton = styled.span<ButtonProps>`
  button {
    position: relative;
    right: 100%;
    top: 110%;
    bottom: 7%;
    background: none;
    border: none;
  }
  .sendIcon {
    background: ${(props) => (props.isSending || props.disabled ? '#658a82' : '#00B292')};
    border-radius: 18.5px;
    color: #ffffff;
    width: 37px;
    height: 37px;
    font-size: 12px;
    padding: 10px 7px 10px 10px;
    cursor: ${(props) => (props.isSending || props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
const Parent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// const Form = styled.form``;

const TaskDiscussions = () => {
  const { state } = useContext(AppContext);
  let { taskId } = useParams();
  const orgUsers = useSelector((state) => state.orgMembers.users);
  const [html, setHtml] = useState({});
  const [text, setText] = useState<string>('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const quillRef = useRef();
  const [isOpen, toggle] = useToggle(false);
  const { data: TaskName } = useQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    fetchPolicy: 'network-only'
  });

  const {
    data: discussionData,
    // error: discussionError,
    // loading: discussionLoading,
    refetch: discussionRefetch
  } = useQuery(TASK_DISCUSSION_QUERY, {
    variables: {
      typeId: taskId + '__discussion',
      type: 'task',
      noValidate: true
    },
    fetchPolicy: 'no-cache'
  });
  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    let text = editor.getText(content);
    setHtml(data);
    setText(text);
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView(), []);
    return <div ref={elementRef} />;
  };

  const [sendMessage, { error }] = useMutation(addDiscussionMutation);

  async function handleSubmit(discussionData: any) {
    if (!text || text.length === 1) {
      return;
    }
    let toastId = toast.loading('Sending Message.');
    setSendingMessage(true);
    try {
      const html = quillRef.current.getEditor().container.firstChild.innerHTML.trim();
      await sendMessage({
        variables: {
          content: html,
          createdAt: new Date().toISOString(),
          isHighlighted: false,
          noteType: 'task',
          typeId: taskId + '__discussion',
          createdBy: state?.userId,
          noValidate: true
        }
      });
      setHtml('');
      quillRef.current.editor.setContents([]);
      setText('');
      await discussionRefetch();
      if (TaskName?.get_Task?.assignedTo !== state?.userId) {
        await axiosInstance.post('/notification', {
          entityType: 'DISCUSSION',
          entityId: taskId,
          actionType: 'discussion',
          actionBy: state?.userId,
          actionByName: state?.fullName,
          actionByProfilePic: state?.profilePhoto,
          message: `< p p > <span class=blackBold>${state?.fullName}</span> has send a new message in <span class=blackBold>${TaskName?.get_Task?.title}</span> discussion </ > `,
          userToNotify: TaskName?.get_Task?.assignedTo,
          workspaceId: TaskName?.get_Task?.typeId,
          projectId: TaskName?.get_Task?.projectId,
          organizationId: state?.orgId
        });
      } else if (TaskName?.get_Task?.assignee !== state?.userId) {
        await axiosInstance.post('/notification', {
          entityType: 'DISCUSSION',
          entityId: taskId,
          actionType: 'discussion',
          actionBy: state?.userId,
          actionByName: state?.fullName,
          actionByProfilePic: state?.profilePhoto,
          message: `< p p > <span class=blackBold>${state?.fullName}</span> has send a new message in <span class=blackBold>${TaskName?.get_Task?.title}</span> discussion </ > `,
          userToNotify: TaskName?.get_Task?.assignee,
          workspaceId: TaskName?.get_Task?.typeId,
          projectId: TaskName?.get_Task?.projectId,
          organizationId: state?.orgId
        });
      }
      setSendingMessage(false);
      toast.dismiss(toastId);
      toast.success('Message Sent');
      // });
    } catch (e) {
      console.error(e);
      toast.dismiss(toastId);
      toast.error('Error while sending message.');
      setSendingMessage(false);
    }
  }

  const modules = useMemo(
    () => ({
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            handler: () => {
              // setHtml(html);
              handleSubmit(discussionData);
            }
          }
        }
      },
      toolbar: [
        // [{ header: '1' }, { header: '2' }, { font: [] }],
        // [{ size: [] }],
        ['bold', 'italic', 'underline'],
        // [{ script: 'sub' }, { script: 'super' }],
        // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ font: [] }],
        // [{ align: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' }
          // { indent: '-1' },
          // { indent: '+1' },
        ],
        [{ size: [] }]
        // ['clean'],
      ],
      clipboard: {
        matchVisual: false
      }
    }),
    []
  );
  if (error) {
    console.log('error', error);
    return <ErrorPage />;
  }

  return (
    <Parent>
      <Container>
        <div>
          {discussionData?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems
            ? discussionData?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems?.map((CheckUser: any) => {
                const rawMarkup = () => {
                  var rawMarkup = CheckUser?.content;
                  return { __html: rawMarkup };
                };
                return (
                  <>
                    {CheckUser ? (
                      CheckUser?.createdBy !== state?.userId ? (
                        <div className="UserMessage">
                          <div className="PicWithChat">
                            <div>
                              {state?.profilePhoto ? (
                                <img src={state?.profilePhoto} alt="profile" />
                              ) : (
                                <div className="profile-logo ">
                                  <Avatar
                                    round={true}
                                    size="23"
                                    name={getFullNameById(CheckUser?.createdBy, orgUsers, state.fullName)}
                                    textSizeRatio={2}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="userChat">
                              <p className="message">
                                <span dangerouslySetInnerHTML={rawMarkup()} />
                              </p>
                            </div>
                          </div>
                          <div className="UsermessageDateTime">
                            <DateStyle>
                              {new Date(CheckUser?.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </DateStyle>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                    {CheckUser ? (
                      CheckUser?.createdBy === state?.userId ? (
                        <div className="MyMessage">
                          <div className="myChat">
                            <p className="message">
                              <span dangerouslySetInnerHTML={rawMarkup()} />
                            </p>
                          </div>
                          <div className="messageDateTime">
                            <DateStyle>
                              {new Date(CheckUser?.createdAt).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                              })}
                            </DateStyle>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </>
                );
              })
            : ''}
          <AlwaysScrollToBottom />
        </div>
      </Container>
      <Editor>
        <div className="EditorView">
          <ReactQuill
            className="editor-container"
            theme="snow"
            ref={quillRef}
            modules={modules}
            formats={formats}
            onChange={onTextChange}
            placeholder="Type your message"
            value={html}
            bounds={'.app'}
          ></ReactQuill>
          <Button onClick={() => toggle()}> + Add Associated Task Modal</Button>
          <AssociatedTaskModal isOpen={isOpen} toggle={toggle} handleSubmit={handleSubmit} />
        </div>
        <SubmitButton isSending={sendingMessage} disabled={text.length === 1 || !text}>
          <button
            onClick={() => {
              handleSubmit(discussionData);
            }}
            disabled={sendingMessage || text.length === 1 || !text}
          >
            <MdSend className="sendIcon" />
          </button>
        </SubmitButton>
      </Editor>
    </Parent>
  );
};

export default TaskDiscussions;
