import { gql } from '@apollo/client';

export const addTaskMutation = gql`
  mutation addTaskMutation(
    $assignedTo: String
    $assignedToDetails: Self_Task_assignedToDetails_Input_
    $assignee: String
    $assigneeDetails: Self_Task_assigneeDetails_Input_
    $createdAt: String
    $description: String
    $dueDate: String
    $priority: String
    $isAcknowledged: Boolean
    $projectId: String
    $organizationId_projectId_typeId: String
    $organizationId: String
    $recordStatus: Float
    $status: String
    $title: String
    $links: [String]
    $updatedAt: String
    $updatedBy: String
    $type: String
    $typeId: String
  ) @api(name: "task") {
    add_Task(
      syncMode: NODE_COMMITTED
      input: {
        assignedTo: $assignedTo
        assignedToDetails: $assignedToDetails
        assignee: $assignee
        assigneeDetails: $assigneeDetails
        createdAt: $createdAt
        description: $description
        dueDate: $dueDate
        isAcknowledged: $isAcknowledged
        priority: $priority
        projectId: $projectId
        organizationId_projectId_typeId: $organizationId_projectId_typeId
        organizationId: $organizationId
        recordStatus: $recordStatus
        status: $status
        title: $title
        updatedAt: $updatedAt
        updatedBy: $updatedBy
        links: $links
        type: $type
        typeId: $typeId
        isAssociated:false
      }
    ) {
      result {
        _id
      }
    }
  }
`;

export const updateTaskAssociationMutation = gql`
  mutation exampleMutation($isAssociated: Boolean, $updatedAt: String, $updatedBy: String, $id: ID!)
  @api(name: "task") {
    update_Task(
      syncMode: NODE_COMMITTED
      id: $id
      input: { isAssociated: $isAssociated, updatedBy: $updatedBy, updatedAt: $updatedAt }
    ) {
      result {
        _id
      }
    }
  }
`;

export const updateTaskMutation = gql`
  mutation updateTaskMutation(
    $id: ID!
    $description: String
    $assignedToDetails: Self_Task_assignedToDetails_UpdateInput_
    $dueDate: String
    $priority: String
    $projectId: String
    $recordStatus: Float
    $status: String
    $title: String
    $typeId: String
    $links: [String]
    $type: String
    $updatedAt: String
    $updatedBy: String
    $assignedTo: String
    $organizationId_projectId_typeId: String
  ) @api(name: "task") {
    update_Task(
      id: $id
      syncMode: NODE_COMMITTED
      input: {
        assignedTo: $assignedTo
        assignedToDetails: $assignedToDetails
        description: $description
        dueDate: $dueDate
        priority: $priority
        projectId: $projectId
        recordStatus: $recordStatus
        status: $status
        title: $title
        links: $links
        type: $type
        typeId: $typeId
        updatedAt: $updatedAt
        updatedBy: $updatedBy
        organizationId_projectId_typeId: $organizationId_projectId_typeId
      }
    ) {
      result {
        _id
      }
    }
  }
`;

export const addAssociateTaskMutation = gql`
  mutation addTaskMutation(
    $assignedTo: String
    $assignedToDetails: Self_Task_assignedToDetails_Input_
    $assignee: String
    $assigneeDetails: Self_Task_assigneeDetails_Input_
    $createdAt: String
    $description: String
    $dueDate: String
    $priority: String
    $isAcknowledged: Boolean
    $projectId: String
    $organizationId_projectId_typeId: String
    $organizationId: String
    $recordStatus: Float
    $status: String
    $title: String
    $links: [String]
    $updatedAt: String
    $updatedBy: String
    $type: String #same as current task
    $typeId: String # same as current task
    $parentAssociateId: String #current TASKID
    $isAssociated: Boolean #True
  ) @api(name: "task") {
    add_Task(
      syncMode: NODE_COMMITTED
      input: {
        assignedTo: $assignedTo
        assignedToDetails: $assignedToDetails
        assignee: $assignee
        assigneeDetails: $assigneeDetails
        createdAt: $createdAt
        description: $description
        dueDate: $dueDate
        isAcknowledged: $isAcknowledged
        priority: $priority
        projectId: $projectId
        organizationId_projectId_typeId: $organizationId_projectId_typeId
        organizationId: $organizationId
        recordStatus: $recordStatus
        status: $status
        title: $title
        updatedAt: $updatedAt
        updatedBy: $updatedBy
        links: $links
        type: $type
        typeId: $typeId
        isAssociated: $isAssociated
        parentAssociateId: $parentAssociateId
      }
    ) {
      result {
        _id
      }
    }
  }
`;

export const DELETE_TASK_MUTATION = gql`
  mutation DELETE_TASK_MUTATION($id: ID!) @api(name: "task") {
    update_Task(id: $id, input: { recordStatus: 1 }, syncMode: NODE_COMMITTED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const UPDATE_STATUS_TASK_MUTATION = gql`
  mutation updateTaskMutation($id: ID!, $status: String) @api(name: "task") {
    update_Task(id: $id, input: { status: $status }, syncMode: NODE_LEDGERED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
export const UPDATE_ACKNOWLEDGED_TASK_MUTATION = gql`
  mutation updateTaskMutation($id: ID!, $isAcknowledged: Boolean) @api(name: "task") {
    update_Task(id: $id, input: { isAcknowledged: $isAcknowledged }, syncMode: NODE_LEDGERED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
