import { useContext } from 'react';
import Lottie from 'react-lottie';
import { Outlet, useParams, Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import NavigationLink from '../../../components/NavigationLink';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { useGetProject } from '../../../hooks/features/projects/useGetProject';
import { PinnedProjectsContext } from '../../../PinnedProjectContext';
import { PageNavStyles } from '../../Library/FilesView/Files/ViewFile/ViewFile';
import { ErrorPage } from '../../Others/ErrorPage';
import { NumberOfMembers } from '../../Workspaces/AboutWorkspace/WorkspaceInformation/ViewWorkspace';
// import { useNavigate } from 'react-router';
// type ProjectDetailsData = {
//   projectName: string

// }

function NewProjectView() {
  const { state } = useContext(AppContext);
  const { state: pinnedProjects } = useContext(PinnedProjectsContext);
  let orgId = state.orgId;
  let { projectId } = useParams();
  // let navigate = useNavigate();
  const {
    loading,
    error,
    projectData,
    goToMembersPage,
    memberData,
    pinToSidebar,
    removeFromSidebar,
    isProjectAdmin,
    archiveProject,
    handleNavigate
  } = useGetProject(projectId, orgId);

  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  if (error) {
    return <ErrorPage />;
  }
  const { projectName } = projectData;

  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>{projectName}</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <NumberOfMembers onClick={() => goToMembersPage()}>
            {/* {projectDirectory ? projectDirectory.length === 0 || projectDirectory.length === 1 ? "1 Member" : `${projectDirectory.length} Members` : null} */}
            {memberData &&
              `${memberData?.length} ${memberData?.length === 1 ? 'Member in the project' : 'Members in the project'} `}
          </NumberOfMembers>
          <ToggleSettings>
            <Link to={`/editproject/${projectId}`}>
              <OptionItem>Edit Project</OptionItem>
            </Link>
            {pinnedProjects.some((project) => project.id === projectId) ? (
              <OptionItem onClick={removeFromSidebar}>Remove from sidebar</OptionItem>
            ) : (
              <OptionItem onClick={pinToSidebar}>Pin to sidebar</OptionItem>
            )}
            {isProjectAdmin && <OptionItem onClick={() => archiveProject(projectId)}>Archive Project</OptionItem>}
          </ToggleSettings>
        </TitleRight>
      </Header>
      <PageNavStyles>
        <NavigationLink end to={`/project/${projectId}`}>
          About
        </NavigationLink>
        <NavigationLink to="workspaces">Workspaces</NavigationLink>
        {isProjectAdmin && <NavigationLink to="tasks">Tasks</NavigationLink>}
      </PageNavStyles>
      <Outlet />
    </>
  );
}

export default NewProjectView;
