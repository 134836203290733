import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import Button from '../../../../components/CustomButton';
import { formats, modules } from '../../../../components/Files/textEditorConfig';
import { SINGLE_NOTE_QUERY } from '../../../../graphql/operations/Queries/Library/NotesQueries';
import {
  OptionItem,
  // OptionsMenu,
  ToggleSettings
} from '../../../../components/OptionsMenu';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import NoteValidation from '../../../../components/Validations/NoteValidation';
import { TextEditorStyles } from '../../NotesView/CreateNewNote';
import { updateNoteMutation } from '../../../../graphql/operations/Mutations/Library/NoteMutations';
import { NoteInput } from './CreateNewLinkedNote';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
const Form = styled.form``;

function ViewLinkedNote() {
  const { noteId } = useParams();
  const [html, setHtml] = useState({});
  const [editMode, setEditMode] = useState(false);
  const { data, error, loading } = useQuery(SINGLE_NOTE_QUERY, {
    variables: { id: noteId, operation: 'query' },
    onCompleted: () => {
      setHtml(data.get_NoteAndDisscussion.content);
      formik.setFieldValue('title', data?.get_NoteAndDisscussion?.title);
    },
    fetchPolicy: 'network-only'
  });
  const [updateNote, { error: updateError }] = useMutation(updateNoteMutation, {
    onCompleted: () => {
      setEditMode(false);
    }
  });
  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    setHtml(data);
  };

  const handleSubmit = async (values: { title: string }) => {
    try {
      const id = toast.loading('Updating...');
      let response = await updateNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: values.title,
          content: html,
          noValidate: true,
          id: noteId
        }
      });
      if (response) {
        toast.success('Updated', {
          id: id
        });
      }
      setHtml(html);
    } catch (error) {
      console.error('ERROR');
    }
  };

  const formik = useFormik({
    initialValues: {
      title: data?.get_NoteAndDisscussion?.title
    },
    validationSchema: NoteValidation,
    onSubmit: handleSubmit
  });

  if (error || updateError) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          {editMode ? (
            <>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <NoteInput
                    type="text"
                    value={formik.values.title}
                    placeholder="Note Title"
                    name="title"
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <Button disabled={formik.isSubmitting} type="submit">
                  Update Note
                </Button>
              </TitleRight>
            </>
          ) : (
            <>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <PageTitle>{data?.get_NoteAndDisscussion?.title}</PageTitle>
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <ToggleSettings>
                  <OptionItem onClick={() => setEditMode(true)}>Edit</OptionItem>
                </ToggleSettings>
              </TitleRight>
            </>
          )}
        </Header>
      </Form>
      <TextEditorStyles>
        <ReactQuill
          theme="snow"
          value={html}
          readOnly={!editMode}
          bounds={'.app'}
          onChange={onTextChange}
          modules={editMode ? modules : { toolbar: false }}
          formats={formats}
        ></ReactQuill>
      </TextEditorStyles>
    </>
  );
}

export default ViewLinkedNote;
