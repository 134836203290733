import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
  query getOrganizationQuery($id: ID!) @api(name: "organization") {
    get_Organization(id: $id) {
      createdAt
      createdBy
      fileType
      _id
      name
      photo
      recordStatus
      updatedAt
      updatedBy
      stripeCustomerId
      subscriptionEndDate
      subscriptionId
      subscriptionType
      numberOfSeats
      otherPaymentFields
      paymentStatus
      paymentUserId
      monthlySubscription
      yearlySubscription
    }
  }
`;
