// import React from "react";
import styled from '@emotion/styled';
import { DarkButton, GreyButton } from '../styles/ButtonStyles';
import { ReactComponent as RoundIcon } from '../../assets/icons/RoundIcon.svg';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import { useLazyQuery } from '@apollo/client';
import { DownloadFileQuery } from '../../graphql/operations/Queries/Library/FilesQueries';
// import { useNavigate } from "react-router";
// import { StyledTag } from "../FileTag";

const FileCardContainer = styled.div`
  background: #f7f7f7;
  display: flex;
  /* width: 80%; */
  border-radius: 5px;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
  .status {
    display: flex;
    align-items: center;
  }
`;
const Subject = styled.p`
  margin-left: 20px;
  font-size: 1rem;
  color: #4c6d66;
  font-weight: 600;
`;
const ButtonContainer = styled.div`
  /* margin-left: auto; */
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 15px;
  }
  /* width: 200px; */
`;
interface data {
  subject: String;
  status: String;
  fileId: String;
}

export const FileCard = (props: data) => {
  // let navigate = useNavigate()
  const [downloadFileId] = useLazyQuery(DownloadFileQuery, {
    onCompleted: (data) => {
      window.open(data.getVendia_File.temporaryUrl, '_blank');
    }
  });
  // const downloadFile = async (fileId: String) => {
  //     let result = downloadFileId({
  //         variables: {
  //             id: fileId
  //         }
  //     })
  // }
  return (
    <>
      <FileCardContainer>
        <div className="status">
          <RoundIcon />
          <Subject>{props.subject}</Subject>
        </div>
        <ButtonContainer>
          <GreyButton>{capitalizeFirstLetter(props.status)}</GreyButton>
          <DarkButton onClick={() => downloadFileId({ variables: { id: props.fileId, operation: 'query' } })}>
            Download
          </DarkButton>
        </ButtonContainer>
      </FileCardContainer>
    </>
  );
};
