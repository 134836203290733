import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router';
import { Discussion } from '../../../../components/Discussion';
import { ItemRevisionCard } from '../../../../components/ItemRevisionComponents/ItemRevisionCards';
import NewDiscussion from '../../../../components/NewDiscussion';
import { DarkButton } from '../../../../components/styles/ButtonStyles';
import { AssigneeWrapper } from '../../../../components/styles/FormStyles';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { GET_SINGLE_FILE_REGISTER_QUERY } from '../../../../graphql/operations/Queries/Registers/RegisterQueries';
import { ErrorPage } from '../../../Others/ErrorPage';
// import { AppContext } from "../../../../AppContext";
// import { CommentCard } from "../../../../components/ItemRevisionComponents/ItemRevisionCommentCard";
// import { FileCard } from "../../../../components/ItemRevisionComponents/ItemRevisionFileCard";
// import { DownloadFileQuery } from "../../../../graphql/operations/Queries/Library/FilesQueries";
// import { getProjectQuery } from "../../../../graphql/operations/Queries/Projects/ProjectQueries";

const WrapperDiv = styled.div`
  display: flex;
  min-height: 100%;

  .discussion-container {
    width: 300px;
    float: right;
    max-height: 450px;
    min-height: 450px;
    /* overflow-y: auto; */
  }

  .header {
    position: sticky;
    background-color: #ffffff;
    width: 100%;
    height: 35px;
    padding: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid var(--table-border);
    font-size: 18px;
  }
`;
const ItemContainer = styled.div`
  min-width: 60vw;
  overflow-y: scroll !important;
  max-height: 76vh;
  flex: 1;
  /* border: 1px solid blue; */
`;
export const ItemRevision = () => {
  let navigate = useNavigate();
  let { registerId, fileId } = useParams();
  const { data, error, loading, refetch } = useQuery(GET_SINGLE_FILE_REGISTER_QUERY, {
    variables: { id: fileId },
    onCompleted: (data) => {}
  });
  // const { state } = useContext(AppContext);
  // const {
  //   data: projectData,
  //   error: projectError,
  //   loading: projectLoading,
  // } = useQuery(getProjectQuery, {
  //   variables: {
  //     id: projectId,
  //   },
  // });

  // const {
  //   data: registerData,
  //   error: registerError,
  //   loading: registerLoading,
  // } = useQuery(getRegisterQuery, { variables: { id: registerId } });

  useEffect(() => {
    refetch();
  }, []);

  // const downloadfileId = data?.get_File?.fileId;
  // const { data: downloadData } = useQuery(DownloadFileQuery, {
  //   skip: !downloadfileId,
  //   variables: { id: downloadfileId },
  //   fetchPolicy: "network-only",
  // });

  // const [fileDownload, { data: downloadedData }] = useLazyQuery(DownloadFileQuery);

  // const downloadFile = async (id: string) => {
  //   let response =  await fileDownload({ variables: { id } });
  //   let url = downloadedData?.getVendia_File?.temporaryUrl;
  //   window.open(url);
  // };

  const sendResponse = () => {
    navigate(`/project/workspace/${registerId}/${fileId}/response`);
  };
  // const goToResponseHistory = () => {
  //   navigate(
  //     `/project/workspace/${projectId}/${workspaceId}/${registerId}/${fileId}/responsehistory`
  //   );
  // };
  if (error) return <ErrorPage />;

  // let lastResponse =
  //   data?.get_File?.responses?.length > 0
  //     ? data?.get_File?.responses[data.get_File.responses.length - 1]
  //     : null;
  // let type = registerData.get_Register.registerType;
  // let props = {
  //     subject:"subject or name of file",
  //     status:"status"
  // }
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <WrapperDiv>
      <ItemContainer>
        <ItemRevisionCard
          name={data.get_File.createdByName}
          subject={data.get_File.fileName}
          status={data.get_File.status}
          comment={data.get_File.fileDescription}
          role={data.get_File.initiatorRole}
          alignment="left"
          fileId={data.get_File.fileId}
        />
        {data.get_File?.responses.map((response, index) => {
          return (
            <>
              <ItemRevisionCard
                key={index}
                name={response.respondedBy}
                subject={response.subject}
                status={response.action}
                comment={response.comment}
                role={data.get_File.responderRole}
                alignment={index % 2 === 0 ? 'right' : 'left'}
                fileId={response.responseFileId}
              />
            </>
          );
        })}
        <AssigneeWrapper>
          <DarkButton type="button" onClick={() => sendResponse()} style={{ marginLeft: 'auto' }}>
            Send Response
          </DarkButton>
        </AssigneeWrapper>
      </ItemContainer>
      <div className="discussion-container">
        <div className="header">Discussion</div>
        <NewDiscussion entityType="register" entityId={fileId as string} registerId={registerId} />
      </div>
    </WrapperDiv>
  );
};
