import { PlanType } from '../../pages/Organization/ViewOrganization';
import { CustomButton } from '../CustomButton';
import { EmptyMessageStyles } from '../Organisation/Placeholders/NoCurrentOrgUsers';

type SubscriptionNotActiveProps = {
  plan: PlanType;
  clickHandler: () => void;
};

function SubscriptionNotActive({ plan, clickHandler }: SubscriptionNotActiveProps) {
  let subscriptionType = plan === 'month' ? 'Monthly' : plan === 'year' ? 'Yearly' : '';
  return (
    <>
      <EmptyMessageStyles>
        <div className="quickbar-section " style={{ height: '10rem' }}>
          <span className="subtitle">
            {' '}
            Your {subscriptionType} subscription is not active.
            <br /> To add seats with {subscriptionType} plan please subscribe with a {subscriptionType} subscription.
          </span>
          <span className="button">
            <CustomButton style={{ backgroundColor: '#4C6D66' }} onClick={() => clickHandler()}>
              Subscribe To {subscriptionType} Plan
            </CustomButton>
          </span>
        </div>
      </EmptyMessageStyles>
    </>
  );
}

export default SubscriptionNotActive;
