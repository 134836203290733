import { Auth } from 'aws-amplify';
import jwt from 'jsonwebtoken';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { parseJwt } from '../../../lib/parseJWT';
import { sentryError } from '../../../lib/SentryError';

const useLogin = () => {

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [username, setUsername] = useState<null | string>(null)
  const [email, setEmail] = useState<null | string>(null)

  useEffect(() => {
    if (!token) return
    let decoded: any
    const parsedToken = parseJwt(token)
    if (parsedToken && parsedToken.exp * 1000 > Date.now()) {
      decoded = jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY!);
      if (decoded) {
        setUsername(decoded.userName)
        setEmail(decoded.email)
      }
    } else {
      toast.error('Invite Expired or is invalid. Please request for a new link');
    }
  }, [])


  const initialValues = {
    email: email ? email : '',
    password: '',
    username: username ? username : ''
  };
  // const regex_NoWhiteSpace = /^\S*$/;
  const validationSchema = Yup.object({
    email: Yup.string().email('Must be a valid email').max(255).required('Email is Required'),
    password: Yup.string().max(255).required('Password is Required'),
    username: Yup.string()
  });

  function validateEmail(email: string) {
    var re = /^[^\s@]+@[^\s@]+$/;
    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  const [loading, setLoading] = React.useState<boolean>(false);
  let navigate = useNavigate();
  const location = useLocation();

  const signIn = async (values) => {
    const email = validateEmail(values.email);
    const username = values.username ? values.username : (email ? values.email.toLowerCase() : values.email);
    const password = values.password;
    try {
      const toastid = toast.loading('Logging In.....');
      setLoading(true);
      const userDetails = await Auth.signIn(username.trim(), password);

      if (userDetails.challengeName === 'NEW_PASSWORD_REQUIRED') {
        toast.success('Set new password', { id: toastid });
        navigate('/forceResetPassword', { state: { username, password } });
      } else {
        setTimeout(() => {
          toast.success('Logged In', { id: toastid });
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      console.log(err?.code, 'error');
      if (err?.code === 'PasswordResetRequiredException') {
        navigate('/forceResetPassword', { state: { username, password } });
      }

      else if (err?.code === 'UserNotConfirmedException') {
        toast.remove();
        toast.error('User is not confirmed');
        await Auth.resendSignUp(username.trim());
        navigate('/confirmSignUp', { state: { username, password } });
      }
      if (err?.code === 'UserNotFoundException') {
        toast.remove();
        toast.error('User not found. Try singing up or if this is your first time logging in, please login using the link from your mail.', { duration: 7000 });
      }
      else {
        toast.remove();
        toast.error('Username or Password is not correct');
        sentryError(err);
      }
    }
  };

  const goToForgotPasswordPage = () => {
    navigate('/forgotPassword');
  };
  const goToSignUpPage = () => {
    navigate('/signup');
  };
  return {
    initialValues,
    signIn,
    validationSchema,
    loading,
    location,
    goToForgotPasswordPage,
    goToSignUpPage
  };
};
export default useLogin;
