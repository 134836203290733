import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Header, HeaderLeft, PageTitle, TitleRight, NewTitleLeft } from '../../../../../components/styles/Header';
import { useMutation, useQuery } from '@apollo/client';
import { SINGLE_FILE_QUERY } from '../../../../../graphql/operations/Queries/Library/FilesQueries';
import BackIcon from '../../../../../assets/SvgComponents/BackIcon';
import { OptionItem, ToggleSettings } from '../../../../../components/OptionsMenu';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../../constants/LoaderDefaultOptions';
import toast from 'react-hot-toast';
import { useToggle } from '../../../../../lib/UseToggle';
import Button, { CustomButton } from '../../../../../components/CustomButton';
import Modal from '../../../../../components/Modal';
import { ModalContainer, ModalTitle, ModalBody, ModalWrapper } from '../../../../../components/styles/ModalStyles';
import { ModalText } from '../../../../Workspaces/Registers/CreateNewRegister';
import { useDispatch } from 'react-redux';
import { ErrorPage } from '../../../../Others/ErrorPage';
import { archiveFileMutation } from '../../../../../graphql/operations/Mutations/Library/FileMutations';
import { postDashboardApi } from '../../../../../reducers/dashboard';
import { AppContext } from '../../../../../AppContext';
import NavigationLink from '../../../../../components/NavigationLink';
import { getDate } from '../../../../../lib/getDate';
// import { ReactComponent as Menu } from "../../../assets/icons/SidebarToggle.svg";
// import { ModalText } from "../../Projects/ProjectViews/ProjectWorkspaceRegister/CreateNewRegister";
// const TitleLeft = styled.div`
//     display: flex;
//     flex-direction: column;
//     margin-left: 2.12rem;
//     h2{
//       font-weight: 600;
//       margin-top: 0px;
//       margin-bottom: 0px;
//     }
//     small{
//       padding-left: 3px;
//     }
// `

export const PageNavStyles = styled.div`
  height: 2.68rem;
  a {
    text-decoration: none;
    color: var(--black);
    /* border: 1px solid red; */
    padding: 11px 50px;
  }
  display: flex;
  text-decoration: none;
  border-bottom: 2px solid var(--table-border);
  align-items: center;
  .active {
    border-bottom: 5px solid var(--text-secondary);
  }
`;
const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;

function ViewFile() {
  let { fileId } = useParams();
  let navigate = useNavigate();
  const [isOpen, toggle] = useToggle(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { state } = useContext(AppContext);
  const dispatch = useDispatch();
  const { data, error, loading, refetch } = useQuery(SINGLE_FILE_QUERY, {
    variables: { id: fileId, operation: 'query' }
  });
  const handleNavigate = () => {
    navigate('/files');
  };
  const [deleteFile, { loading: deleteLoading, error: deleteError }] = useMutation(archiveFileMutation);
  const handleDelete = async (id, recordStatus) => {
    try {
      setIsLoading(true);
      let response = await deleteFile({
        variables: {
          libraryType: 'notesAndCollections',
          id,
          recordStatus
        }
      });
      if (response) {
        await refetch().then(() => {
          setTimeout(() => {
            toast.success('File is Moved to Trash');
            navigate('/Files');
          }, 3000);
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    return () => {
      if (data?.get_File) {
        dispatch(
          postDashboardApi({
            type: 'metaData',
            moduleType: 'FILES',
            orgId: state.orgId,
            metaDataId: fileId,
            metaData: { _id: fileId, name: data?.get_File?.name }
          })
        );
      }
    };
  }, [data]);

  if (loading || deleteLoading || isLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error || deleteError) return <ErrorPage />;
  const { get_File } = data;
  const deleteRecordStatus = 1; //1 means delete
  if (data.get_File.recordStatus === 9) return <h2>File is permanently deleted from Platform Suite</h2>;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle>{get_File.name}</PageTitle>
            <small className="subtitle">Updated on {getDate(get_File.createdAt)}</small>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <ToggleSettings>
            <OptionItem onClick={() => toggle()}>Delete</OptionItem>
          </ToggleSettings>
        </TitleRight>
      </Header>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Delete File</ModalTitle>
          <ModalBody>
            <ModalText>Do you want to delete this File?</ModalText>
          </ModalBody>
          <ButtonWrapper>
            <Button onClick={toggle} secondary>
              Cancel
            </Button>
            <Button onClick={() => handleDelete(fileId, deleteRecordStatus)}>Confirm</Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
      <PageNavStyles>
        <NavigationLink end to={`/files/${fileId}`}>
          About
        </NavigationLink>
        <NavigationLink to="linkedfiles">Links</NavigationLink>
        <NavigationLink to="notes">Notes</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
}

export default ViewFile;
