import { gql } from '@apollo/client';

export const createNoteMutation = gql`
      mutation createNoteMutation(
      $content: String
      $createdAt: String
      $createdBy: String
      $noteType: String
      $projectId: String
      $title: String
      $typeId: String
      $updateBy: String
      $updatedAt: String
      $noteType_typeId: String
      ) @api(name: "library") {
            add_NoteAndDisscussion(
            syncMode: NODE_COMMITTED
            input: {
                  title: $title
                  content: $content
                  createdAt: $createdAt
                  createdBy: $createdBy
                  noteType: $noteType
                  typeId: $typeId
                  projectId: $projectId
                  updateBy: $updateBy
                  updatedAt: $updatedAt
                  noteType_typeId: $noteType_typeId
                  recordStatus: 0
            }
            ) {
                  result {
                        _id
                  }
                  transaction {
                        _id
                  }
            }
      }
`;

export const updateNoteMutation = gql`
      mutation updateNoteMutation($id: ID!, $title: String, $content: String) @api(name: "library") {
            update_NoteAndDisscussion(id: $id, syncMode: NODE_COMMITTED, input: { title: $title, content: $content }) {
                  result {
                        _id
                  }
                  transaction {
                        _id
                  }
            }
      }
`;

export const archiveNoteMutation = gql`
      mutation updateNoteMutation($id: ID!, $recordStatus: Float) @api(name: "library") {
            update_NoteAndDisscussion(id: $id, syncMode: NODE_COMMITTED, input: { recordStatus: $recordStatus }) {
                  result {
                        _id
                  }
                  transaction {
                        _id
                  }
            }
      }
`;

export const unarchiveNoteMutation = gql`
      mutation updateNoteMutation($id: ID!, $recordStatus: Float) @api(name: "library") {
            update_NoteAndDisscussion(id: $id, syncMode: NODE_COMMITTED, input: { recordStatus: $recordStatus }) {
                  result {
                        _id
                  }
                  transaction {
                        _id
                  }
            }
      }
`;

export const createTaskInNote = gql`
      mutation addTaskMutation(
      $assignee: String
      $assigneeDetails: Self_Task_assigneeDetails_Input_
      $createdAt: String
      $priority: String
      $projectId: String
      $organizationId_projectId_typeId: String
      $organizationId: String
      $recordStatus: Float
      $status: String
      $title: String
      $noteId: String
      $updatedAt: String
      $updatedBy: String
      $type: String
      $typeId: String
      $assignedTo: String
      $assignedToDetails:Self_Task_assignedToDetails_Input_
  

      ) @api(name: "task") {
            add_Task(
            syncMode: NODE_COMMITTED
            input: {
                  assignee: $assignee
                  assigneeDetails: $assigneeDetails
                  assignedTo: $assignedTo
                  assignedToDetails: $assignedToDetails
                  createdAt: $createdAt
                  priority: $priority
                  projectId: $projectId
                  organizationId_projectId_typeId: $organizationId_projectId_typeId
                  organizationId: $organizationId
                  recordStatus: $recordStatus
                  status: $status
                  title: $title
                  noteId: $noteId
                  updatedAt: $updatedAt
                  updatedBy: $updatedBy
                  type: $type
                  typeId: $typeId
\            }
            ) {
                  result {
                        _id
                  }
            }
      }
`;
