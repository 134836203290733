export const WorkspaceDropdownStyles = {
  control: (base) => ({
    ...base,
    width: "200px",
    border: 0,
    boxShadow: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    color: isFocused ? "white" : "#1C1C1C",
    background: isFocused ? "#2F3F48" : "white",
    whiteSpace: "wrap",
    lineHeight: "25px",
  }),
};
