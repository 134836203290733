import { gql } from '@apollo/client';

export const LIST_PROJECT_NOTES_IN_WORKSPACE_QUERY = gql`
  query blocksQuery($type_typeId: String, $recordStatus: Float) @api(name: "workspace") {
    list_NoteAndDisscussionItems(
      filter: { noteType_typeId: { eq: $type_typeId }, recordStatus: { eq: $recordStatus } }
    ) {
      nextToken
      _NoteAndDisscussionItems {
        _id
        content
        createdAt
        createdBy
        isDraft
        isHighlighted
        metadata
        noteType
        noteType_typeId
        organizationId
        organizationId_projectId_workspaceId
        otherFields
        prefix
        projectId
        recordStatus
        title
        typeId
        updateBy
        updatedAt
        workspaceId
        _owner
      }
    }
  }
`;

export const GET_SINGLE_PROJECT_NOTE_QUERY = gql`
  query GET_NOTE($id: ID!) @api(name: "workspace") {
    get_NoteAndDisscussion(id: $id) {
      content
      noteType_typeId
      organizationId_projectId_workspaceId
      _id
      createdBy
      createdAt
      title
      recordStatus
      updatedAt
      organizationId
      projectId
      workspaceId
      isDraft
    }
  }
`;
