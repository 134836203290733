import React, { useContext, useEffect, useState } from 'react';
// import styled from "@emotion/styled";
import Button from '../../../components/CustomButton';
import FileTag from '../../../components/FileTag';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { ReactComponent as FileUploadIcon } from '../../../assets/icons/fileupload.svg';
import { TaskDetails } from '../../Tasks/TaskViews/CreateTask';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ChoicesWrapper,
  ClientSeparator,
  ClientWrapper,
  DateWrapper,
  FieldSet,
  FilePreview,
  FileUploadWrapper,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import InputField from '../../../components/Reusable/Formik/Input';
import { GreyButton } from '../../../components/styles/ButtonStyles';
import { Label } from '../../../components/styles/Label';
import TextArea from '../../../components/Reusable/Formik/Textarea';
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { LinkInputWrapper } from '../../Library/FilesView/Files/CreateNewFile';
import { Input } from '../../../components/styles/Inputs';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import TextError from '../../../components/Reusable/NewFormik/TextError';
import { DateText } from './CreateNewDynamicDocumentInRegister';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { Small } from '../../../components/styles/SmallText';
import { AppContext } from '../../../AppContext';
import { getProjectQuery } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import axiosInstance from '../../../axiosInstance';
import { getFileType } from '../../../lib/getFileType';
import { ErrorPage } from '../../Others/ErrorPage';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { StaticDocumentSchemaValidation } from '../../../components/Validations/StaticDocumentValidation';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { Storage } from 'aws-amplify';
import { getRegisterQuery } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import { getWorkspaceQuery } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';

type StaticDocument = {
  documentName: string;
  file: any;
  description: string;
  links: string[];
};

function CreateNewStaticDocumentInRegister() {
  const navigate = useNavigate();
  const { projectId, workspaceId, registerId } = useParams();
  const [usersWithRoles, setUsersWithRoles] = useState({});
  // const [allowedToUpload, setAllowedToUpload] = useState(false);
  const { state } = useContext(AppContext);
  const initialState: StaticDocument = {
    documentName: '',
    file: '',
    links: [''],
    description: ''
  };
  const { loading, error, data } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    }
  });
  const {
    data: workspaceData,
    error: workspaceError,
    loading: workspaceLoading
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    }
  });
  const {
    data: registerData,
    error: registerDataError,
    loading: registerLoading
  } = useQuery(getRegisterQuery, {
    variables: {
      id: registerId
    }
  });
  const doNothing = () => {};

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSubmit = async (values: StaticDocument) => {
    let id = uuidv4();
    let toastId = toast.loading('Uploading ...');
    let filedata = {
      fileName: values.file.name,
      createdAt: new Date().toISOString(),
      createdByName: state.userName,
      fileType: values.file.type,
      projectId: projectId,
      type: 'register',
      typeId: registerId,
      updatedAt: new Date().toISOString(),
      updatedBy: state.userId
    };
    try {
      let size = (values.file.size / 1000).toString();
      if (Number(size) > 1000000) {
        toast.error(`Upload Failed! File size should be less than 1GB`, { id: toastId });
      } else {
        const result = await Storage.put(id, values.file, {
          contentType: values.file.type,
          level: 'public',
          metadata: {
            isDraft: 'false',
            description: values.description,
            linkedFiles: JSON.stringify(values.links),
            projectName: data.get_Project.name,
            workspaceName: workspaceData?.get_Workspace?.name,
            createdById: state.userId,
            documentName: values.documentName,
            client: data.get_Project.client,
            collections: JSON.stringify([]),
            organizationId: state.orgId,
            entityType: 'WORKSPACE',
            entityId: workspaceId,
            userId: state.userId,
            ...filedata
            // initiator: "",
            // responder: "",
            // initiatorRole: "",
            // responderRole: "",
          }
        });

        if (result) {
          toast.success('Document Uploaded Successfully', {
            id: toastId
          });
          setTimeout(() => {
            navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/documentlist`);
          }, 7000);
        }
      }
    } catch (err) {
      console.error('ERROR', err);
      toast.dismiss(toastId);
      toast.error('Oh no. File was not uploaded. Try agin');
    }
  };

  useEffect(() => {
    axiosInstance.get(`/rolesMapping?entityType=WORKSPACE&entityId=${workspaceId}`).then((response) => {
      if (response.data.data.length > 0) {
        let results = response.data.data.map((item) => {
          return { user: item.user, role: item.role };
        });
        setUsersWithRoles(results);
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: StaticDocumentSchemaValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });

  if (error || registerDataError || workspaceError) return <ErrorPage />;
  if (loading || registerLoading || workspaceLoading)
    return <Lottie options={defaultOptions} width={400} height={500} />;
  if (registerData.get_Register.registerType === 'dynamic') {
    navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/dynamicdocument`);
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>New Document</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </TitleRight>
        </Header>
        <TaskDetails>
          <FieldSet>
            <div>
              <InputWrapper>
                <Label htmlFor="documentName">Document Name</Label>
                <InputField
                  disabled={formik.isSubmitting}
                  id="documentName"
                  name="documentName"
                  type="text"
                  formik={formik}
                  //  placeholder="Document Name"
                />
              </InputWrapper>
              <FileUploadWrapper>
                {formik.values.file ? (
                  <FilePreview>
                    <FileTag>{getFileType(formik.values.file.name)?.toUpperCase()}</FileTag>
                    <span className="file">{formik.values.file.name}</span>
                    <GreyButton type="button" onClick={() => formik.setFieldValue('file', '')}>
                      Remove File <CloseIcon />
                    </GreyButton>
                  </FilePreview>
                ) : (
                  <>
                    <GreyButton
                      type="button"
                      // disabled={!allowedToUpload || isSubmitting}
                      onClick={handleFile}
                    >
                      <FileUploadIcon /> Upload File
                    </GreyButton>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      style={{ display: 'none' }}
                      ref={hiddenFileInput}
                      onChange={(event) => {
                        formik.setFieldValue('file', event.currentTarget.files[0]);
                      }}
                    />
                    {formik.touched['file'] && formik.errors['file'] ? (
                      <p style={{ color: 'red' }}>{formik.errors['file']}</p>
                    ) : null}
                  </>
                )}
              </FileUploadWrapper>
              <InputWrapper>
                <Label>Project Name</Label>
                <p>{data?.get_Project?.name}</p>
              </InputWrapper>
              <ChoicesWrapper>
                <Label>Workspace Name</Label>
                <p>{workspaceData?.get_Workspace?.name}</p>
              </ChoicesWrapper>
              <InputWrapper>
                <Label htmlFor="description">Description</Label>
                <TextArea name="description" id="description" formik={formik} disabled={formik.isSubmitting} />
              </InputWrapper>
              <LinkWrapper>
                <Label>Links</Label>
                <FormikProvider value={formik}>
                  <FieldArray name="links">
                    {({ insert, remove, push }) => (
                      <>
                        {formik.values.links.length > 0 &&
                          formik.values.links.map((link, index) => {
                            return (
                              <>
                                <Field key={index} name={`links.${index}`}>
                                  {({ meta, field }) => (
                                    <>
                                      <LinkInputWrapper>
                                        <Input
                                          className="link-input"
                                          disabled={formik.isSubmitting}
                                          type="text"
                                          {...field}
                                          error={meta.touched && meta.error}
                                        />
                                        <CloseIcon
                                          onClick={() => (!formik.isSubmitting ? remove(index) : doNothing())}
                                          height="25px"
                                          width="25px"
                                        />
                                      </LinkInputWrapper>
                                      {meta.touched && meta.error && (
                                        <ErrorMessage component={TextError} name={`links.${index}`} />
                                      )}
                                    </>
                                  )}
                                </Field>
                              </>
                            );
                          })}
                        <div>
                          <Button type="button" disabled={formik.isSubmitting} onClick={() => push('')}>
                            + Add Link
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </FormikProvider>
              </LinkWrapper>
            </div>
            <div>
              <DateWrapper>
                <Label htmlFor="date">Document Date</Label>
                <DateText>
                  {new Date().toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                </DateText>
                <CalendarIconPlacer></CalendarIconPlacer>
              </DateWrapper>
              <AssigneeWrapper>
                <Label>Created By</Label>
                <AssigneeSeparator>
                  <AvatarStyles>
                    <CustomAvatar isTable={false} name={state.fullName} size={21} />
                  </AvatarStyles>
                  <Small>
                    {new Date().toLocaleDateString('En-US')}{' '}
                    {new Date().toLocaleTimeString('En-US', {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </Small>
                </AssigneeSeparator>
              </AssigneeWrapper>
              <ClientWrapper>
                <Label>Client</Label>
                <ClientSeparator>
                  <AvatarStyles>
                    <CustomAvatar
                      // isTable={true}
                      name={data?.get_Project?.client}
                      size={21}
                    />
                  </AvatarStyles>
                </ClientSeparator>
              </ClientWrapper>
            </div>
          </FieldSet>
        </TaskDetails>
      </form>
    </>
  );
}

export default CreateNewStaticDocumentInRegister;
