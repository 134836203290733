import React from 'react';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';

function RemoveSeatModal(props) {
  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <ModalContainer width="638px">
        <ModalTitle>Remove Seat</ModalTitle>
        <ModalBody>
          <ModalText>
            Do you want to remove the seat from {<strong>{props.user?.userName}</strong>}. The user will need to have a
            new seat reassigned to be active in the organization.
          </ModalText>
        </ModalBody>
        <ModalWrapper>
          <Button disabled={props.buttonState} secondary onClick={props.onClose}>
            Cancel
          </Button>
          <Button disabled={props.buttonState} onClick={props.onSubmit}>
            Remove Seat
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
}

export default RemoveSeatModal;
