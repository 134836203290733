import * as React from "react";

function PlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 1v11M12 6.5H1"
        stroke={props.stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        className="prefix__svg-primary"
      />
    </svg>
  );
}

export default PlusIcon;
