import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Tabs } from '../types';
import ChatListBody from './ChatListBody/ChatListBody';
import ChatListHeader from './ChatListHeader/ChatListHeader';
import SearchUsers from './SearchUsers';

interface Props {
  onClick?: () => void;
}

/**
 * Left side panel in Chat
 * Lists all members
 */
const ChatList = ({ onClick }: Props) => {
  const [sidebarTab, setSidebarTab] = useState<Tabs>('Organization');
  const [isSearching, setIsSearching] = useState(false);
  const { orgData, selectedConversationId } = useSelector((state: RootState) => state.conversations);

  const toggleSearch = () => setIsSearching(!isSearching);

  /**
   * Switch sidebar tab when conversationId changes
   */
  useEffect(() => {
    if (!selectedConversationId) return;
    if (orgData.hasOwnProperty(selectedConversationId)) {
      setSidebarTab('Organization');
    } else setSidebarTab('Outside Organization');
  }, [selectedConversationId, orgData]);

  return (
    <ChatListStyles>
      {!isSearching ? (
        <>
          <ChatListHeader activeTab={sidebarTab} setActiveTab={setSidebarTab} toggleSearch={toggleSearch} />
          <ChatListBody selectedTab={sidebarTab} onClick={onClick} />
        </>
      ) : (
        <SearchUsers toggleSearch={toggleSearch} onClick={onClick} />
      )}
    </ChatListStyles>
  );
};

export default ChatList;

const ChatListStyles = styled.div`
  /* max-width: 475px; */
  width: 360px;
  min-width: 360px;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e6e6e6;
  overflow-x: hidden;
`;
