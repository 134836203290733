import React from 'react';
import CollectionFilesItem from './CollectionFilesItem';

export default function CollectionFilesList({
  data,
  icons,
  checkedFiles,
  setCheckedFiles,
  files
}: {
  data: any;
  icons: any;
  checkedFiles: any;
  setCheckedFiles: any;
  files: any;
}) {
  return (
    <div>
      {data?.map((file, index) => {
        let checked = false;
        if (checkedFiles?.includes(file?._id)) {
          checked = true;
        }
        return (
          <CollectionFilesItem
            key={index}
            data={file}
            icons={icons}
            checkedFiles={checkedFiles}
            setCheckedFiles={setCheckedFiles}
            checked={checked}
          />
        );
      })}
    </div>
  );
}
