export const CollectionDropDownStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (styles) => ({
    ...styles,
    background: "#DEEEF3",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    ":hover": {
      // borderRadius: '100%',
      cursor: "pointer",
      // backgroundColor: '#4C6D66',
      // color: 'white'
    },
  }),
};
