import { useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import Button from '../../../../components/CustomButton';
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../../../../components/Files/textEditorConfig';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import InputField from '../../../../components/Reusable/Formik/Input';
import NoteValidation from '../../../../components/Validations/NoteValidation';
import { SINGLE_NOTE_QUERY } from '../../../../graphql/operations/Queries/Library/NotesQueries';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { updateNoteMutation } from '../../../../graphql/operations/Mutations/Library/NoteMutations';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { OptionItem, ToggleSettings } from '../../../../components/OptionsMenu';
// import { AppContext } from "../../../../AppContext";
// import useForm from "../../../../lib/UseForm";

const Form = styled.form``;
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4.6rem;
  .notename {
    border: none;
    font-size: 1.5rem;
    height: 60px;
  }
`;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;
const TextEditorStyles = styled.div`
  .ql-container {
    min-height: 76vh;
  }
`;

function ViewWorkspaceNote() {
  // const { state } = useContext(AppContext);
  let navigate = useNavigate();
  let { noteId, fileId, projectId, workspaceId } = useParams();
  const [editMode, setEditMode] = useState(false);
  const { data, error, loading } = useQuery(SINGLE_NOTE_QUERY, {
    variables: { id: noteId, operation: 'query' },
    onCompleted: () => {
      setHtml(data.get_NoteAndDisscussion.content);
      formik.setFieldValue('title', data?.get_NoteAndDisscussion?.title);
    }
  });

  const [updateNote, { error: updateError }] = useMutation(updateNoteMutation, {
    onCompleted: () => {
      setEditMode(false);
    }
  });
  const [html, setHtml] = useState({});

  const initialState = {
    title: data?.get_NoteAndDisscussion?.title
  };

  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    setHtml(data);
  };

  const handleSubmit = async (values: { title: string }) => {
    const toastId = toast.loading('Updating ...');
    try {
      let Response = await updateNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: values?.title,
          content: html,
          noValidate: true,
          id: noteId
        }
      });
      if (Response) {
        toast.success('Note Updated', {
          id: toastId
        });
        navigate(`/project/workspace/${projectId}/${workspaceId}/library/files/${fileId}/workspacenotes`);
      }
      setHtml(html);
    } catch (e) {
      toast.dismiss(toastId);
      toast.error('Oh no something went wrong.');
      console.error(e);
    }
    // setTimeout(() => {
    //   refetch();
    // }, 2000)
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: NoteValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });

  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error || updateError) {
    console.error(error);
    return <ErrorPage />;
  }
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          {editMode ? (
            <>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <InputField
                    style={{ border: 'none', fontSize: '1.5rem', height: '60px' }}
                    name="title"
                    type="text"
                    formik={formik}
                    disabled={formik.isSubmitting}
                    placeHolder="New Note"
                  />
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <Button disabled={formik.isSubmitting} type="submit">
                  Update Note
                </Button>
              </TitleRight>
            </>
          ) : (
            <>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <PageTitle>{data?.get_NoteAndDisscussion?.title}</PageTitle>
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <ToggleSettings>
                  <OptionItem onClick={() => setEditMode(true)}>Edit</OptionItem>
                </ToggleSettings>
              </TitleRight>
            </>
          )}
        </Header>
      </Form>
      <TextEditorStyles>
        <ReactQuill
          theme="snow"
          readOnly={!editMode || formik.isSubmitting}
          formats={formats}
          onChange={onTextChange}
          value={html}
          modules={editMode ? modules : { toolbar: false }}
          bounds={'.app'}
        ></ReactQuill>
      </TextEditorStyles>
    </>
  );
}

export default ViewWorkspaceNote;
