import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';

function useGetIsSuperAdmin() {
  const { state } = useContext(AppContext);
  let currentOrgId = state.orgId;
  let currentOrg =
    currentOrgId === 'null' ? [] : state?.role?.ORGANIZATION?.find((org) => org.entityId === currentOrgId);

  const [isSuperAdmin, setIsSuperAdmin] = useState(
    currentOrgId === 'null' ? false : currentOrg && currentOrg?.roleName === 'SUPER_ADMIN' ? true : false
  );

  useEffect(() => {
    setIsSuperAdmin(
      currentOrgId === 'null' ? false : currentOrg && currentOrg?.roleName === 'SUPER_ADMIN' ? true : false
    );
  }, [state]);

  return {
    isSuperAdmin
  };
}

export default useGetIsSuperAdmin;
