import React from 'react';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import TableDropDown from '../../../../components/TableComponents/TableDropDown';
import { DropdownDiv } from '../../../../components/Tasks';
import { Container } from '../../../Projects/ProjectViews/CreateProject';
import { RESPONSEHISTORYCOLUMNS } from '../../../../constants/ResponseHistoryColumnData';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { GET_SINGLE_FILE_REGISTER_QUERY } from '../../../../graphql/operations/Queries/Registers/RegisterQueries';
import { useQuery } from '@apollo/client';
import ResponseHistoryTable from '../../../../components/ResponseHistoryTable';
import styled from '@emotion/styled';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';

const ExpandedDiv = styled.div`
  min-height: 41px;
`;

function ResponseHistory() {
  const { fileId } = useParams();

  const {
    data: filedata,
    error,
    loading
  } = useQuery(GET_SINGLE_FILE_REGISTER_QUERY, {
    variables: { id: fileId }
  });
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return <ExpandedDiv>{row.original.comment}</ExpandedDiv>;
  }, []);

  const columns = useMemo(() => RESPONSEHISTORYCOLUMNS, []);
  const data = useMemo(() => filedata, [filedata]);
  if (error) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Response History</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
      </Header>
      <Container>
        <DropdownDiv>
          <div className="drop-item">
            <TableDropDown state={true} text="Current Version">
              {filedata && (
                <ResponseHistoryTable
                  columns={columns}
                  data={data?.get_File.responses}
                  renderRowSubComponent={renderRowSubComponent}
                />
              )}
            </TableDropDown>
          </div>
        </DropdownDiv>
      </Container>
    </>
  );
}

export default ResponseHistory;
