/* eslint-disable no-useless-escape */
import React from 'react';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const useResetPassword = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  console.log('LOCATION', location.state);
  const username = location?.state ? location?.state : Object.fromEntries([...searchParams])?.username;
  console.log(username);
  const initialValues = {
    code: '',
    password: '',
    confirm_password: ''
  };

  const validationSchema = Yup.object({
    code: Yup.string().required('Reset Password Code is Required'),
    password: Yup.string()
      .required('New Password is Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm New Password is Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
  });

  const forgotPasswordSubmit = async (values) => {
    const code = values.code;
    const password = values.password.trim();
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      toast.success('Password has been changed.');
      navigate('/');
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.error('Invalid Reset code.');
    }
  };
  const goToHomePage = () => {
    navigate('/');
  };

  return {
    loading,
    validationSchema,
    forgotPasswordSubmit,
    initialValues,
    location,
    goToHomePage,
    username
  };
};

export default useResetPassword;
