import styled from '@emotion/styled';
import { useTable } from 'react-table';

const defaultPropGetter = () => ({});

const AssociatedTasksTableStyles = styled.div`
  background-color: hsla(0, 5%, 96%, 1);
  border-radius: 5px;

  /* margin-right: 20px; */
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    width: 98%;
    margin-left: 8px;
    /* ,border: 1px solid blue; */
  }
  tr {
    transform: translateY(0);
    th {
      padding-bottom: 11px;
      padding-top: 15px;
      padding-left: 20px;
      text-align: left;
      color: #93979f;
      font-weight: 600 !important;
      /* border-bottom: 1px solid var(--table-border); */
    }
    td {
      text-align: left;
      border-bottom: 1px solid var(--table-border);
      padding: 9px 20px;
      font-weight: 400;
      line-height: 22px;
      overflow: hidden;
    }
  }
  tbody {
    tr {
      background-color: white;
      td:first-of-type {
        /* border-left: 2px solid #e5e5e5; */
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    tr:hover {
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
      td {
        /* border-top: 2px solid #E5E5E5; */
        /* border-bottom: 2px solid #E5E5E5; */
        /* border-style: none !important; */
      }
      td:first-of-type {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    td:hover .new-tooltip {
      visibility: visible;
    }
  }
`;

function AssociatedTasksTable({
  columns,
  data,
  clickHandler,
  getCellProps = defaultPropGetter,
  getRowProps = defaultPropGetter
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });
  return (
    <AssociatedTasksTableStyles>
      <table className="tasks-table" {...getTableProps()}>
        {columns.length > 0 && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: { width: column.width }
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(getRowProps(row))}
                onClick={() => (clickHandler ? clickHandler(row.original._id) : null)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      // style={{ ...cell.getCellProps().style }}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style
                        },
                        getCellProps(cell)
                      ])}
                    >
                      {cell.render('Cell')}
                      {cell.column.tooltip ? <span className="new-tooltip">{cell.render('Cell')}</span> : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </AssociatedTasksTableStyles>
  );
}

export default AssociatedTasksTable;
