import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useContext } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { AppContext } from '../../AppContext';
import { LIST_PROJECTS_QUERY } from '../../graphql/operations/Queries/Projects/ProjectQueries';
import { LIST_WORKSPACE_QUERY } from '../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { TaskColumnStyles, TaskList, Title } from '../Kanban';
import TaskCard from './TaskCard';

const Container = styled.div`
  display: flex;
`;

function WorkspaceKanban({ setColumns, columns, updateTask }: any) {
  const { state } = useContext(AppContext);
  const {
    data: projects
    // loading: projectLoading,
    // error: projectError,
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    },
    fetchPolicy: 'network-only'
  });
  const {
    data: workspaces
    // loading: workspaceLoading,
    // error: workspaceError,
  } = useQuery(LIST_WORKSPACE_QUERY, { fetchPolicy: 'network-only' });
  const getProjectName = (id: string) => {
    let found = projects.list_ProjectItems._ProjectItems.find((project) => project._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };

  const getWorkspaceName = (id: string) => {
    let found = workspaces?.list_WorkspaceItems?._WorkspaceItems.find((workspace) => workspace._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };
  const onDragEnd = async (result: DropResult, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        type: 'REORDER',
        data: {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems
          }
        }
      });
      try {
        let actionType = destination.droppableId === 'done' ? 'completed' : 'status';
        let messageType = destination.droppableId === 'done' ? 'completed' : 'status';
        let userToNotify = destination.droppableId === 'done' ? removed.assignee : removed.assignedTo;
        let variables =
          userToNotify !== state?.userId
            ? {
                status: destination.droppableId,
                id: removed._id,
                type: removed.type,
                typeId: removed.typeId,
                notificationData: {
                  actionType,
                  messageType,
                  taskName: removed?.title,
                  dueDate: removed?.dueDate,
                  workspaceName: getWorkspaceName(removed.typeId),
                  projectName: getProjectName(removed.projectId),
                  projectId: removed.projectId,
                  workspaceId: removed.typeId,
                  organizationId: state?.orgId,
                  userToNotify: userToNotify,
                  host: window.location.origin,
                  status: destination.droppableId,
                  assignedToName: removed.assignedToDetails.name
                }
              }
            : { status: destination.droppableId, id: removed._id };
        await updateTask({
          variables: variables
        });
      } catch (error) {
        console.error('ERROR', error);
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        type: 'REORDER',
        data: {
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems
          }
        }
      });
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
        <Container>
          <TaskColumnStyles>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <Droppable key={columnId} droppableId={columnId}>
                  {(provided, snapshot) => (
                    <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                      <Title title={column.title}>{column.title}</Title>
                      {column.items.map((item: any, index: any) => {
                        return <TaskCard key={item._id} item={item} index={index} />;
                      })}
                      {provided.placeholder}
                    </TaskList>
                  )}
                </Droppable>
              );
            })}
          </TaskColumnStyles>
        </Container>
      </DragDropContext>
    </>
  );
}

export default WorkspaceKanban;
