import React from 'react';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { v4 as uuidv4 } from 'uuid';

type TSignUpValues = {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  confirm_password: string;
  ph_country_code: string;
  phone_number: string;
};

const useSignUp = () => {
  const regex_username = /^[A-Za-z0-9_]+$/;
  const regex_WithHyphens = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  // eslint-disable-next-line no-useless-escape
  const regex_OnlyCharacters = /^(?=.*[A-Za-z])+[0-9a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/g;
  const validationSchema = Yup.object({
    firstname: Yup.string()
      .matches(regex_OnlyCharacters, {
        message: 'Atleast one character required and no blankspaces'
      })
      .required('First name is Required.'),
    lastname: Yup.string()
      .matches(regex_OnlyCharacters, {
        message: 'Atleast one character required and no blankspaces'
      })
      .required('Last name is Required.'),
    password: Yup.string()
      .required('Password is required.')
      // .min(8, 'Minimum password length is 8. ')
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    email: Yup.string().email('Invalid Email.').required('Email is Required.'),
    phone_number: Yup.string()
      .required('Phone Number is Required.')
      .matches(regex_WithHyphens, { message: 'Please Enter valid Phone number.' })
      .min(10, 'Please Enter valid Phone number.')
      .max(13, 'Please Enter valid Phone number.'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .required('Confirm Password is required.')
      // .min(8, 'Min Password length is 8.'),
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    ph_country_code: Yup.string().required('Country Code is required.')
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  let navigate = useNavigate();
  const location = useLocation();
  const querry = new URLSearchParams(location.search);
  const token = querry.get('token');
  const decoded = token ? jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY) : undefined;

  const initialValues: TSignUpValues = {
    firstname: '',
    lastname: '',
    password: '',
    confirm_password: '',
    ph_country_code: '+1',
    email: decoded ? decoded.email.toLowerCase() : '',
    phone_number: ''
  };
  const goToHomePage = () => {
    navigate('/');
  };

  const signUp = async (values) => {
    const username = uuidv4();
    const password = values.password;
    const email = decoded ? decoded.email.toLowerCase() : values.email.toLowerCase();
    const filteredPhoneNumer = values.phone_number.replace(/[^0-9]/gi, '');
    const phone_number = values.ph_country_code + filteredPhoneNumer;
    const nickname = values.firstname + ' ' + values.lastname;
    const countryCode = values.ph_country_code;
    try {
      setLoading(true);
      const toastid = toast.loading('Signing Up....');
      const attributes = token
        ? {
            email, // optional
            phone_number, // optional - E.164 number convention
            nickname,
            'custom:countryCode': countryCode,
            'custom:first_name': values.firstname,
            'custom:last_name': values.lastname,
            'custom:host': window.location.origin,
            'custom:token': token
          }
        : {
            email, // optional
            phone_number, // optional - E.164 number convention
            nickname,
            'custom:countryCode': countryCode,
            'custom:first_name': values.firstname,
            'custom:last_name': values.lastname,
            'custom:host': window.location.origin
          };
      await Auth.signUp({
        username: username,
        password,
        attributes
      });
      toast.success('Signed Up', { id: toastid });

      navigate('/confirmSignUp', {
        state: { email, password, decoded, nickname, username }
      });
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        setLoading(false);
        toast.remove();
        toast.error('Username already exists');
      } else if (error.code === 'UserLambdaValidationException') {
        setLoading(false);
        toast.remove();
        toast.error('Email already exists');
      } else {
        console.error('error signing up:', error);
        toast.remove();
        toast.error('Unable to sign Up');
        setLoading(false);
      }
    }
  };

  return {
    initialValues,
    loading,
    validationSchema,
    signUp,
    decoded,
    goToHomePage
  };
};

export default useSignUp;
