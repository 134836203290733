import { gql } from '@apollo/client';

export const LIST_PROJECTS_QUERY = gql`
  query ListProjectsQuery($organizationId: String) @api(name: "project") {
    list_ProjectItems(filter: { organizationId: { eq: $organizationId }, recordStatus: { eq: 0 } }) {
      _ProjectItems {
        _id
        name
        client
        code
        createdAt
        createdBy
      }
    }
  }
`;

export const LIST_INTERNAL_PROJECTS_QUERY = gql`
  query ListProjectsQuery($organizationId: String) @api(name: "project") {
    list_ProjectItems(
      filter: { organizationId: { eq: $organizationId }, recordStatus: { eq: 0 }, isShared: { eq: false } }
    ) {
      _ProjectItems {
        _id
        name
        client
        code
        createdAt
        createdBy
      }
    }
  }
`;

export const LIST_SHARED_PROJECTS_QUERY = gql`
  query ListProjectsQuery($organizationId: String, $recordStatus: Float, $isShared: Boolean) @api(name: "project") {
    list_ProjectItems(
      filter: {
        organizationId: { eq: $organizationId }
        recordStatus: { eq: $recordStatus }
        isShared: { eq: $isShared }
      }
    ) {
      _ProjectItems {
        _id
        name
        client
        code
        createdAt
        createdBy
      }
    }
  }
`;

export const LIST_ARCHIVED_PROJECTS_QUERY = gql`
  query ListProjectsQuery($organizationId: String) @api(name: "project") {
    list_ProjectItems(filter: { organizationId: { eq: $organizationId }, recordStatus: { eq: 1 } }) {
      _ProjectItems {
        _id
        name
        client
        code
        createdAt
        createdBy
      }
    }
  }
`;

// export const getProjectQuery = gql`
//   query getProjectQuery($projectId: ID!) {
//     getProject(id: $projectId) {
//       name
//       client
//       projectDescription
//       code
//     }
//   }
// `;

export const getProjectQuery = gql`
  query getProjectQuery($id: ID!) @api(name: "project") {
    get_Project(id: $id) {
      client
      createdAt
      createdBy
      _id
      manager
      name
      projectDescription
      recordStatus
      updateAt
      updatedBy
      code
    }
  }
`;
