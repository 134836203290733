import * as React from 'react';
import { useExpanded, useTable } from 'react-table';
import styled from '@emotion/styled';

const TableStyles = styled.div`
  table {
    width: 100%;
    margin-left: 8px;
    border-spacing: 0;
    /* border-collapse: separate; */
    table-layout: fixed;
    tr {
      padding-bottom: 11px;
      transform: translateY(0);
      th {
        padding-bottom: 11px;
        padding-left: 50px;
        text-align: left;
        color: #929292;
        font-weight: 600 !important;
        border-bottom: 1px solid var(--table-border);
      }
      td {
        text-align: left;
        border-bottom: 1px solid var(--table-border);
        padding: 9px 50px;
        font-weight: 400;
        line-height: 22px;
        color: var(--black);
        white-space: nowrap;
        overflow: hidden;
        .text-wrap {
          white-space: nowrap;
        }
      }
      .text-wrap {
        /* white-space: normal; */
        /* wrap: nowrap; */
      }
    }
    tbody {
      tr:hover {
        cursor: pointer;
        /* transform: translateY(-10px); */

        border-radius: 5px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
        transition: 0.3s;
        td {
          /* border-top: 2px solid #E5E5E5; */
          /* border-bottom: 2px solid #E5E5E5; */
          /* border-style: none !important; */
        }
        td:first-of-type {
          border-left: 2px solid #e5e5e5;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        td:last-child {
          border-right: 2px solid #e5e5e5;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
`;

function ResponseHistoryTable({
  columns,
  data,
  clickHandler,
  renderRowSubComponent
}: {
  columns?: any;
  data?: any;
  clickHandler?: (id: string) => void;
  renderRowSubComponent?: any;
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns
    // state:
  } = useTable(
    {
      columns,
      data
    },
    useExpanded
  );
  // TODO: remove this
  const doNothing = () => {};

  return (
    <TableStyles>
      <table className="tasks-table" {...getTableProps()}>
        {columns.length > 0 && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} onClick={() => (clickHandler ? clickHandler(row.original.id) : doNothing())}>
                  {row.cells.map((cell) => {
                    return (
                      <td style={{ ...cell.getCellProps().style }} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>

                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </TableStyles>
  );
}

export default ResponseHistoryTable;
