import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import conversationsSlice from './reducers/conversations_new';
import dashboardSlice from './reducers/dashboard';
import { discussionSlice } from './reducers/discussion';
import meetingNoteSlice from './reducers/meetingNote';
import { notificationSlice } from './reducers/notification';
import projectNoteSlice from './reducers/projectNote';
import taskNoteSlice from './reducers/taskNote';
import usersSlice from './reducers/users';

export const store = configureStore({
  reducer: {
    orgMembers: usersSlice.reducer,
    notifications: notificationSlice.reducer,
    dashboard: dashboardSlice.reducer,
    discussion: discussionSlice.reducer,
    meetingNote: meetingNoteSlice.reducer,
    projectNote: projectNoteSlice.reducer,
    taskNote: taskNoteSlice.reducer,
    conversations: conversationsSlice.reducer
  }
});
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
