import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { ReactComponent as SearchIcon } from '../../../assets/icons/IconSearchShat.svg';
import { ReactComponent as NoNotesIllustration } from '../../../assets/illustration/noNotes.svg';
import axiosInstance from '../../../axiosInstance';
import Button from '../../../components/CustomButton';
import Loader from '../../../components/Loader';
import AddNewProjectNote from '../../../components/Modals/ProjectNotes/AddNewProjectNote';
import { Header, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import Table from '../../../components/Table';
import { MockNotebookColumn } from '../../../constants/MockProjectNoteData';
import { NOTEBOOK_OPTIONS } from '../../../constants/ProjectNotesDropdown';
import { useToggle } from '../../../lib/UseToggle';
import { ErrorPage } from '../../Others';
import { ProjectNotesListStyles } from '../ProjectNotesList/styles';
import { SearchContainer, selectNotebookStyles } from './styles';

const fetchNotebooks = async () => {
  return await axiosInstance.get('/notes');
};

const NotebooksList = () => {
  const [isSearchOpen, setSearchOpen] = useState(false);

  const [isOpen, toggleModal] = useToggle();

  const [searchText, setSearchText] = useState('');
  const [allNotes, setAllNotes] = useState<any>([]);
  const columns = useMemo(() => MockNotebookColumn, []);
  const data = useMemo(() => allNotes, [allNotes]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedNoteType, setSelectedNoteType] = useState('all');

  const navigate = useNavigate();

  let personalNotes = useRef<any>([]);
  let projectNotes = useRef<any>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    try {
      const data = await fetchNotebooks();
      personalNotes.current = data?.data.personalNotes
        .map((note: any) => {
          return { ...note, type: 'Personal' };
        })
        .filter((note: any) => note.recordStatus !== 1);

      projectNotes.current = data?.data.workspaceNotes
        .map((note: any) => {
          return { ...note, type: 'Project' };
        })
        .filter((note: any) => note.recordStatus !== 1);

      setAllNotes([...personalNotes.current, ...projectNotes.current]);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value: string) => {
    setSearchText(value);
    let notes = [];
    if (selectedNoteType === 'personal') notes = [...personalNotes.current];
    else if (selectedNoteType === 'project') notes = [...projectNotes.current];
    else notes = [...personalNotes.current, ...projectNotes.current];
    const filteredNotes = notes.filter((note: any) => note.title.toLowerCase().includes(value.toLowerCase()));
    setAllNotes(filteredNotes);
  };

  const handleNoteTypeSelectChange = (option: any) => {
    setSelectedNoteType(option.value);
    if (option.value === 'personal') setAllNotes([...personalNotes.current]);
    else if (option.value === 'project') setAllNotes([...projectNotes.current]);
    else setAllNotes([...personalNotes.current, ...projectNotes.current]);
  };

  const handleViewNote = (id: string) => {
    let obj = [...projectNotes.current, ...personalNotes.current].find((o) => o._id === id);

    if (obj.type === 'Project') {
      navigate(`/project/workspace/${obj.projectId}/${obj.workspaceId}/notebooks/${obj._id}`);
    } else {
      navigate(`/notes/${id}`);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Header>
        <NewTitleLeft>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PageTitle>Notebook</PageTitle>
            <div style={{ marginLeft: '20px' }}>
              <Select
                defaultValue={NOTEBOOK_OPTIONS[0]}
                options={NOTEBOOK_OPTIONS}
                styles={selectNotebookStyles}
                onChange={handleNoteTypeSelectChange}
              />
            </div>
          </div>
        </NewTitleLeft>
        <TitleRight>
          <SearchContainer>
            <div className={`input-container ${isSearchOpen ? 'show' : 'hide'}`}>
              <SearchIcon className="search-icon " onClick={() => setSearchOpen(!isSearchOpen)} />
              <input
                value={searchText}
                type="text"
                className={`search-input`}
                onChange={(e) => handleChange(e.target.value)}
              />
            </div>

            <SearchIcon className={`${isSearchOpen ? 'hide' : 'show'}`} onClick={() => setSearchOpen(!isSearchOpen)} />
          </SearchContainer>

          <Button onClick={toggleModal}>Add New Notebook</Button>
        </TitleRight>
      </Header>
      {allNotes.length === 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            flexDirection: 'column'
          }}
        >
          <NoNotesIllustration />
        </div>
      ) : (
        <ProjectNotesListStyles>
          <Table columns={columns} data={data} clickHandler={handleViewNote} />
        </ProjectNotesListStyles>
      )}
      <AddNewProjectNote isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
};

export default NotebooksList;
