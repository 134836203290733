import styled from '@emotion/styled';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../AppContext';
import useChatSocket from '../../../hooks/features/chat/useChatSocket';
import { RootState } from '../../../store';
import { CustomButton } from '../../CustomButton';
type Props = {};

const Blocked = (props: Props) => {
  const { continueChat } = useChatSocket();

  const { currentConversationDetails } = useSelector((state: RootState) => state.conversations);
  const {
    state: { userId }
  } = useContext(AppContext);

  // Only user who blocked the conversation can unblock
  return (
    <Container>
      This Conversation has been blocked
      {currentConversationDetails?.blockedBy === userId ? (
        <CustomButton onClick={continueChat}>Unblock?</CustomButton>
      ) : null}
    </Container>
  );
};

export default Blocked;

const Container = styled.div`
  height: 120px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px 10px;
`;
