import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../../AppContext';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import Button from '../../../../components/CustomButton';
import { formats, modules } from '../../../../components/Files/textEditorConfig';
import TextError from '../../../../components/Reusable/NewFormik/TextError';
import { Header, HeaderLeft, NewTitleLeft, TitleRight } from '../../../../components/styles/Header';
import NoteValidation from '../../../../components/Validations/NoteValidation';
import { createNoteMutation } from '../../../../graphql/operations/Mutations/Library/NoteMutations';
import { ErrorPage } from '../../../Others/ErrorPage';
import { TextEditorStyles } from '../../NotesView/CreateNewNote';

type NoteProps = {
  error?: boolean | string;
};

export const NoteInput = styled.input<NoteProps>`
  border: none;
  font-size: 1.5rem;
  font-weight: 600;
  height: 50px;
  border: ${(props) => (props.error ? '1px solid red' : 'none')};
  border-radius: 5px;
`;
const Form = styled.form``;

function CreateNewLinkedNote() {
  const { state } = useContext(AppContext);
  const { fileId } = useParams();
  const navigate = useNavigate();
  const [html, setHtml] = useState('');

  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    setHtml(data);
  };
  const [addNote, { error }] = useMutation(createNoteMutation, {});

  const handleSubmit = async (values: { title: string }) => {
    const toastId = toast.loading('Creating...');
    try {
      let response = await addNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: values.title,
          content: html,
          createdAt: new Date().toISOString(),
          createdBy: state.userId,
          noteType: 'file',
          typeId: fileId,
          projectId: 'NOPROJECT',
          noteType_typeId: `file_${fileId}`,
          updateBy: state.userId,
          updatedAt: new Date().toISOString()
        }
      });
      if (response) {
        toast.success('New Note Created', {
          id: toastId
        });
        setTimeout(() => {
          navigate(`/Files/${fileId}/notes`);
        }, 5000);
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.error(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      title: ''
    },
    validationSchema: NoteValidation,
    onSubmit: handleSubmit
  });
  if (error) return <ErrorPage />;
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <NoteInput
                type="text"
                value={formik.values.title}
                placeholder="Note Title"
                name="title"
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                error={formik.errors.title}
              />
              {formik.errors.title ? <TextError>{formik.errors.title}</TextError> : ''}
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={formik.isSubmitting} type="submit">
              Save Note
            </Button>
          </TitleRight>
        </Header>
      </Form>
      <TextEditorStyles>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          onChange={onTextChange}
          defaultValue={html}
          placeholder="Write Your Note"
          readOnly={formik.isSubmitting}
          // bounds={'.app'}
        ></ReactQuill>
      </TextEditorStyles>
      {/* <Toaster
        toastOptions={{
          success: {
            duration: 5000,
            style: {
              background: "var(--button-background)",
              color: "#ffffff",
            },
            iconTheme: {
              primary: "#ffffff",
              secondary: "var(--button-background)",
            },
          },
        }}
      /> */}
    </>
  );
}

export default CreateNewLinkedNote;
