import { client } from './../apollo';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EditingTable, EditorI, SectionI } from '../pages/MeetingNotes/types';
import { createNewSection } from './meetingNote';
import axiosInstance from '../axiosInstance';
import { GET_SINGLE_TASK_NOTE_QUERY } from '../graphql/operations/Queries/Tasks/TaskNoteQueries';
export interface TaskNoteI {
  _id: string;
  content: SectionI[];
  createdAt: Date;
  createdBy: string;
  isDraft?: any;
  noteType: string;
  noteType_typeId: string;
  organizationId: string;
  organizationId_projectId_workspaceId: string;
  otherFields?: any;
  projectId: string;
  recordStatus: number;
  title: string;
  typeId: string;
  updateBy?: any;
  updatedAt?: any;
  workspaceId: string;
  taskTypeId?: string;
}

interface InitialStateI {
  loading: boolean;
  error: string;
  taskNoteData: TaskNoteI;
  sections: SectionI[];
  editingTable: EditingTable;
}

const initialState: InitialStateI = {
  loading: false,
  error: '',
  taskNoteData: {} as TaskNoteI,
  sections: [],
  editingTable: {} as EditingTable
};

export const fetchTaskNote = createAsyncThunk(
  'taskNote/fetchTaskNote',
  async ({
    taskId,
    workspaceId,
    projectId,
    orgId,
    taskTypeId
  }: {
    taskId: string;
    workspaceId: string;
    projectId: string;
    orgId: string;
    taskTypeId: string;
  }) => {
    const { data } = await client.query({
      query: GET_SINGLE_TASK_NOTE_QUERY,
      variables: {
        type_typeId: `TASK_${taskId}`,
        organizationId_projectId_workspaceId: ` ${orgId}_${projectId}_${workspaceId}`
      }
    });

    let response = { ...data?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems[0] };
    if (!response.content || response.content === 'null' || response.content === '') response.content = [];
    else response.content = JSON.parse(response.content);

    response.workspaceId = workspaceId;

    /**
     * Personal task will have userId as TypeId
     */
    response.taskTypeId = taskTypeId;

    const editingStatus = await axiosInstance.get(`/notes/status?noteId=${response?._id}`);
    return { data: response as TaskNoteI, editingStatus: editingStatus?.data };
  }
);

const taskNoteSlice = createSlice({
  name: 'taskNote',
  initialState,
  reducers: {
    addSection: (state) => {
      const section = createNewSection(state.sections.length + 1);
      state.sections.push(section);
    },
    deleteSection: (state, action) => {
      state.sections = state.sections.filter((section) => section.sectionId !== action.payload);
    },
    updateBlockContent: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string; blockContent: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      const blockIndex = state?.sections[sectionIndex]?.blockData?.findIndex((x) => x.blockId === data.blockId);
      state.sections[sectionIndex].blockData[blockIndex].blockContent = data.blockContent;
    },
    updateSectionHeader: (state, action) => {
      const data = action.payload as { sectionId: string; header: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      state.sections[sectionIndex].sectionHeader = data.header;
    },
    updateLastUpdateDate: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string; newDate: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      const blockIndex = state.sections[sectionIndex].blockData.findIndex((x) => x.blockId === data.blockId);
      state.sections[sectionIndex].blockData[blockIndex].blockDate = data.newDate;
    },
    replaceContent: (state, action) => {
      const { content, _id: noteId } = action.payload;

      if (noteId !== state.taskNoteData._id) return;

      state.sections = JSON.parse(content);
    },
    addToEditorTable: (state, action) => {
      //If project note not loaded
      if (
        state.taskNoteData &&
        Object.keys(state.taskNoteData).length === 0 &&
        Object.getPrototypeOf(state.taskNoteData) === Object.prototype
      )
        return;

      if (action.payload.noteId !== state.taskNoteData._id) return;

      if (!state.editingTable.hasOwnProperty(action.payload.blockId)) {
        state.editingTable[action.payload.blockId] = {
          editorDetails: action.payload.editedByData,
          editingStatus: true,
          editingBy: action.payload.editingBy
        };
      }
    },
    removeFromEditorTable: (state, action) => {
      //If project note not loaded
      if (
        state.taskNoteData &&
        Object.keys(state.taskNoteData).length === 0 &&
        Object.getPrototypeOf(state.taskNoteData) === Object.prototype
      )
        return;

      if (action.payload.noteId !== state.taskNoteData._id) return;

      if (state.editingTable.hasOwnProperty(action.payload.blockId)) {
        delete state.editingTable[action.payload.blockId];
      }
    },
    resetNote: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTaskNote.fulfilled, (state, action) => {
        state.taskNoteData = action.payload.data;

        // Populate the editing status table
        action.payload.editingStatus.map((status: { blockId: string; editingBy: string; editingByData: EditorI }) => {
          if (!state.editingTable.hasOwnProperty(status.blockId))
            state.editingTable[status.blockId] = {
              editingStatus: true,
              editorDetails: status.editingByData,
              editingBy: status.editingBy
            };
          return status;
        });

        state.loading = false;
        state.sections = action.payload?.data.content;
        if (state.sections.length === 0) {
          const section = createNewSection(state.sections.length + 1);
          state.sections.push(section);
        }
        state.error = '';
      })
      .addCase(fetchTaskNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ? action.error.message : 'Error';
      });
  }
});

export default taskNoteSlice;
export const {
  addSection,
  updateBlockContent,
  updateLastUpdateDate,
  updateSectionHeader,
  replaceContent,
  addToEditorTable,
  removeFromEditorTable,
  resetNote,
  deleteSection
} = taskNoteSlice.actions;
