import { useQuery } from '@apollo/client';
import { Storage } from '@aws-amplify/storage';
import Compress from 'browser-image-compression';
import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as BoldIcon } from '../../../assets/icons/editor-icons/bold.svg';
import { ReactComponent as CenterAlignIcon } from '../../../assets/icons/editor-icons/center-align.svg';
import { ReactComponent as Divider } from '../../../assets/icons/editor-icons/divider.svg';
import { ReactComponent as ItalicIcon } from '../../../assets/icons/editor-icons/italics.svg';
import { ReactComponent as LeftAlignIcon } from '../../../assets/icons/editor-icons/left-align.svg';
import { ReactComponent as OrderedListIcon } from '../../../assets/icons/editor-icons/ordered.svg';
import { ReactComponent as RightAlignIcon } from '../../../assets/icons/editor-icons/right-align.svg';
import { ReactComponent as UnderLineIcon } from '../../../assets/icons/editor-icons/underline.svg';
import { ReactComponent as UnOrderedListIcon } from '../../../assets/icons/editor-icons/unordered.svg';
import { listRegistersQuery } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import getRegisterData from '../../../lib/getRegisterData';
import { useToggle } from '../../../lib/UseToggle';
import { RootState } from '../../../store';
import { OptionItem } from '../../OptionsMenu';
import AddRegisterModal from './modals/AddRegisterModal';
import { DropDownMenu, MenuButton, ToolBarStyle } from './style';

const Toolbar = ({
  editor,
  editorType
}: {
  editor: any;
  editorType: 'MEETING_NOTE' | 'PROJECT_NOTE' | 'TASK_NOTE';
}) => {
  const imageUploadRef = useRef<HTMLInputElement>(null);
  const documentUploadRef = useRef<HTMLInputElement>(null);
  const [isOpen, toggleRegisterModal] = useToggle(false);
  const { meetingNoteData } = useSelector((state: RootState) => state.meetingNote);

  const openImageInput = () => {
    imageUploadRef.current?.click();
  };

  const openDocumentInput = () => {
    documentUploadRef.current?.click();
  };

  const { data: RegisterData, loading } = useQuery(listRegistersQuery, {
    variables: {
      workspaceId: meetingNoteData.workspaceId
    },
    skip: editorType !== 'MEETING_NOTE'
  });

  const register =
    !loading && RegisterData
      ? RegisterData?.list_RegisterItems._RegisterItems.map((register: any) => {
          return { label: register.name, value: register };
        })
      : [];

  const addDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !editor) return;
    const file = e.target.files[0];
    const toastId = toast.loading('Uploading File');
    try {
      const data = await uploadFileToS3(file);
      const url = `https://${process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${data.key}`;

      editor.commands.insertContent(
        `<file-component src=${url} platform_suite_file_upload_name=${file.name} ><a class="document" href=${url}>${file.name} ></a></file-component>`
      );

      toast.success('Uploaded', { id: toastId });
    } catch (error) {
      console.log(error);
      toast.dismiss(toastId);
    }
  };

  const addImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    imageUploadRef.current?.click();
    if (!e.target.files || !editor) return;

    if (e.target.files[0].size < 6000000) {
      const toastId = toast.loading('Uploading Image');

      const image = e.target.files[0];
      const options = {
        maxWidthOrHeight: 2000,
        maxSizeMB: 0.2,
        initialQuality: 0.6,
        useWebWorker: true
      };
      try {
        const compressedBlob = await Compress(image, options);

        const data = await uploadToS3(compressedBlob);
        const url = `https://${process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${data.key}`;
        // const url = URL.createObjectURL(e.target?.files[0]);
        editor?.chain().focus().setImage({ src: url }).run();
        toast.success('Uploaded', { id: toastId });
      } catch (error) {
        toast.error('Something went wrong', { id: toastId });
        console.log(error);
      }
    } else toast.error('Uploaded file is too large');
  };

  const uploadToS3 = async (compressedBlob: Blob) => {
    const data = await Storage.put(`meetingNotes_image_${uuidv4()}`, compressedBlob, {
      contentType: compressedBlob?.type,
      bucket: process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME,
      level: 'public'
    });
    return data;
  };

  const uploadFileToS3 = async (file: File) => {
    const data = await Storage.put(`meetingNotes_file_${uuidv4()}`, file, {
      contentType: file.type,
      bucket: process.env.REACT_APP_S3_MEETINGNOTES_BUCKET_NAME,
      level: 'public'
    });
    return data;
  };

  // const addTable = (editor: any) => {
  //   editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
  // };

  const handleAddRegisterToTable = async (selectedRegister: any, moduleName: string) => {
    const id = toast.loading('Fetching register data');

    let table: any[] = [];
    let result = selectedRegister.map(async (reg: any) => {
      return await getRegisterData(meetingNoteData.projectId, meetingNoteData.workspaceId, reg.value._id);
    });

    try {
      result = await Promise.all(result);

      result.map((res: any) => {
        res.files.map((file: any) => {
          const row = {
            register: '',
            item: '',
            status: '',
            registerId: '',
            projectId: '',
            workspaceId: '',
            fileId: ''
          };
          row.register = res.register.name;
          row.item = file.name;
          row.status = file.status ? file.status : 'Un-viewed';
          row.projectId = res.register.projectId;
          row.workspaceId = res.register.workspaceId;
          row.fileId = file._id;
          row.registerId = res.register._id;
          table.push(row);
        });
      });
      // table = result.map((res) => [...res]);
      let rowHtml = '';
      table.map((row: any) => {
        rowHtml += `<tr>
      <td>${row.register}</td>
      <td>${row.item}</td>
      <td>${row.status}</td>
    </tr>`;
      });
      const tableHTML = `
    <table style="width:100%">
      <tr>
        <th>Register</th>
        <th>Item</th>
        <th>Status</th>
      </tr>
      ${rowHtml}
    </table>
    `;
      // editor.commands.insertContent(tableHTML);
      editor
        .chain()
        .focus()
        .insertContent(tableHTML, {
          parseOptions: {
            preserveWhitespace: false
          }
        })
        .run();
      toast.success('Register data added successfully', { id });
    } catch (error) {
      toast.error('Something went wrong', { id });
      console.log(error);
    }

    toggleRegisterModal();
  };

  if (!editor) {
    return null;
  }

  return (
    <>
      <ToolBarStyle>
        <MenuButton
          onClick={() => editor?.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <BoldIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <ItalicIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive('underline') ? 'is-active' : ''}
        >
          <UnderLineIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <OrderedListIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <UnOrderedListIcon />
        </MenuButton>
        <Divider style={{ marginRight: '10px' }} />
        <MenuButton
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <LeftAlignIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
        >
          <CenterAlignIcon />
        </MenuButton>
        <MenuButton
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
        >
          <RightAlignIcon />
        </MenuButton>
        <Divider style={{ marginRight: '10px' }} />
        {/* <ToggleSettings button={<MenuButton border>Insert + </MenuButton>}>
          <OptionItem onClick={openImageInput}>Image</OptionItem>
          <OptionItem onClick={openDocumentInput}>Document</OptionItem> */}
        {/* <OptionItem onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}>
        Table
      </OptionItem> */}
        {/* <OptionItem onClick={() => toggleRegisterModal()}>Register</OptionItem>
        </ToggleSettings> */}
        <DropDownMenu>
          <MenuButton border>Insert + </MenuButton>
          <div className="dropdown-content">
            <OptionItem onClick={openImageInput}>Image</OptionItem>
            <OptionItem onClick={openDocumentInput}>Document</OptionItem>
            {editorType === 'MEETING_NOTE' && <OptionItem onClick={() => toggleRegisterModal()}>Register</OptionItem>}
          </div>
        </DropDownMenu>
        <input type="file" ref={imageUploadRef} onChange={addImage} hidden />
        <input type="file" ref={documentUploadRef} onChange={addDocument} hidden />
      </ToolBarStyle>
      <AddRegisterModal
        isOpen={isOpen}
        toggleModal={toggleRegisterModal}
        register={register}
        handleAddRegisterToTable={handleAddRegisterToTable}
      />
    </>
  );
};

export default Toolbar;
