import React, { useState } from 'react';
import NavigationLink from '../NavigationLink';
import { PageNavStyles } from '../styles/PageNavStyles';
import SideBarTabs from './SideBarTabs';
import SideBar from '../../../src/assets/images/SideBar.svg';
import { ReactComponent as EditTaskIcon } from '../../../src/assets/icons/editTaskIcon.svg';
import styled from '@emotion/styled';
import { getDate } from '../../lib/getDate';
import { useNavigate, useParams } from 'react-router';
import { Discussion } from '../Discussion';
import Avatar from 'react-avatar';
import NewDiscussion from '../NewDiscussion';

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  /* border: 1px solid blue; */

  width: 100%;
  .child2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px 0px 0px;
  }

  .content {
    padding: 20px 5px 5px 5px;
    /* max-height: 550px; */
    /* overflow-y: auto; */
    /* align-items: stretch; */
    height: 100%;
  }

  .tasks {
    overflow-y: auto;
    max-height: 90%;
  }

  .edit-task-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .task-item {
    border: 1px solid #26303e;
    border-radius: 5px;
    width: 100%;
    margin: auto;
    padding: 10px;
    position: relative;
    margin-top: 5px;
  }

  .date {
    color: #00b292;
  }

  .assignee {
    display: flex;
    align-items: center;

    img {
      object-fit: cover;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
  }
`;

interface Props {
  tasks: any;
  saveNoteAsDraft: any;
  entityType: 'MEETING_NOTES' | 'WORKSPACE_NOTE' | 'TASK' | 'register';
  entityId: string;
  isAssociatedTasks?: boolean;
}

function RightSideBar({
  tasks,
  saveNoteAsDraft,
  entityType = 'MEETING_NOTES',
  entityId,
  isAssociatedTasks = false
}: Props) {
  const [activeTab, setActiveTab] = useState('tab1');
  const navigate = useNavigate();
  const { noteId } = useParams();

  return (
    <Parent>
      <PageNavStyles>
        <SideBarTabs activeTab={activeTab} setActiveTab={setActiveTab} isAssociatedTasks={isAssociatedTasks} />
      </PageNavStyles>
      <div className="content">
        {activeTab === 'tab1' ? (
          tasks && tasks.length > 0 ? (
            <div className="tasks">
              {tasks.map((task: any, i: number) => (
                <div className="task-item" key={i}>
                  <p>{task.title}</p>
                  {task.dueDate && <p className="date">Due Date : {getDate(task.dueDate)}</p>}
                  {/* TODO: HOT-FIX */}
                  {task.assigneeDetails && (
                    <div className="assignee">
                      <strong style={{ marginRight: '15px' }}>Assignee </strong>
                      {task.assigneeDetails.profilePicture && task.assigneeDetails.profilePicture !== 'null' ? (
                        <img src={`${task.assigneeDetails.profilePicture}`} alt="profile" />
                      ) : task.assigneeDetails.name ? (
                        <div className="profile-logo " style={{ marginRight: '5px' }}>
                          <Avatar round={true} size="32" name={task.assigneeDetails.name} textSizeRatio={2} />
                        </div>
                      ) : (
                        ''
                      )}

                      {task.assigneeDetails.name}
                    </div>
                  )}
                  <EditTaskIcon
                    className="edit-task-icon"
                    onClick={() => {
                      saveNoteAsDraft(noteId, false);
                      navigate(`/meetingnotes/${noteId}/task/${task._id}`);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="child2">
              <img src={SideBar} alt="" />
            </div>
          )
        ) : (
          <NewDiscussion entityId={entityId} entityType={entityType} />
        )}
      </div>
      {/* <div className="child2">
        <img src={SideBar} />
      
      </div> */}
    </Parent>
  );
}

//  <Discussion entityId={noteId!} entityType={entityType} />

export default RightSideBar;
