import { gql } from '@apollo/client';

export const CREATE_PROJECT_NOTE_MUTATION = gql`
  mutation exampleMutation(
    $content: String
    $createdAt: String
    $createdBy: String
    $noteType: String
    $noteType_typeId: String
    $organizationId: String
    $organizationId_projectId_workspaceId: String
    $projectId: String
    $recordStatus: Float
    $typeId: String
    $title: String
    $updateBy: String
    $updatedAt: String
    $workspaceId: String
    $isDraft: Boolean
  ) @api(name: "workspace") {
    add_NoteAndDisscussion(
      input: {
        content: $content
        createdAt: $createdAt
        createdBy: $createdBy
        noteType: $noteType
        noteType_typeId: $noteType_typeId
        organizationId: $organizationId
        organizationId_projectId_workspaceId: $organizationId_projectId_workspaceId
        projectId: $projectId
        recordStatus: $recordStatus
        typeId: $typeId
        workspaceId: $workspaceId
        updateBy: $updateBy
        updatedAt: $updatedAt
        title: $title
        isDraft: $isDraft
      }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const UPDATE_PROJECT_NOTE_CONTENT_MUTATION = gql`
  mutation MyMutation($content: String, $updateBy: String, $updatedAt: String, $isDraft: Boolean, $id: ID!)
  @api(name: "workspace") {
    update_NoteAndDisscussion(
      id: $id
      input: { content: $content, updatedAt: $updatedAt, updateBy: $updateBy, isDraft: $isDraft }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const DELETE_PROJECT_NOTE = gql`
  mutation MyMutation($recordStatus: Float, $updateBy: String, $updatedAt: String, $id: ID!) @api(name: "workspace") {
    update_NoteAndDisscussion(
      id: $id
      input: { updatedAt: $updatedAt, updateBy: $updateBy, recordStatus: $recordStatus }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
