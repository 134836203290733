import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import Loader from '../../../../components/Loader';
import ProjectList from '../../../../components/ProjectList';
import useListProject from '../../../../hooks/features/projects/useListProjects';
import { ErrorPage } from '../../../Others';
import SeatNotProvided from '../../../Others/SeatNotProvided';
import { EmptyDataContainer, ProjectsContainer } from '../Projects';

export const PageContainer = styled.div`
  margin-top: 20px;
`;

function InternalProjectsListPage() {
  const { projectList, error, loading } = useListProject();
  const { state } = useContext(AppContext);

  if (error) {
    if (error.networkError?.message.includes('401') && state.orgId === 'null') {
      return (
        <ProjectsContainer>
          <div className="empty-data">
            <EmptyDataContainer>
              You have to be a part of an organization or create an organization before creating a project.
            </EmptyDataContainer>
          </div>
        </ProjectsContainer>
      );
    } else if (error.networkError?.message.includes('401') && state.orgId !== 'null') {
      return <SeatNotProvided />;
    } else {
      return <ErrorPage />;
    }
  }
  if (loading) return <Loader />;
  return (
    <PageContainer>
      <ProjectsContainer>
        {state.orgId === 'null' || !state.orgId ? (
          <div className="empty-data">
            <EmptyDataContainer>
              You have to be a part of an organization or create an organization before creating a project.
            </EmptyDataContainer>
          </div>
        ) : projectList && projectList?.length === 0 ? (
          <div className="empty-data">
            <EmptyDataContainer>No projects created.</EmptyDataContainer>
          </div>
        ) : (
          <ProjectList data={projectList}></ProjectList>
        )}
      </ProjectsContainer>
    </PageContainer>
  );
}

export default InternalProjectsListPage;
