import Blocked from './ChatComponents/Blocked';
import GroupExit from './ChatComponents/GroupExit';
import NewConversationMessage from './ChatComponents/NewConversationMessage';

export const getConversationStatus = (type: 'group' | 'individual', recordStatus: number) => {
  if (type === 'group' && recordStatus === 3) {
    return <GroupExit />;
  } else if (type === 'individual' && recordStatus === 3) {
    return <Blocked />;
  } else if (type === 'individual' && recordStatus === 4) {
    return <NewConversationMessage />;
  }
  return null;
};
