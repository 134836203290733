import React from 'react';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';

const PublishNoteModal = ({
  isOpen,
  toggleModal,
  publishNote
}: {
  isOpen: boolean;
  toggleModal: () => void;
  publishNote: () => void;
}) => {
  return (
    <>
      <Modal open={isOpen} onClose={toggleModal} styles={{ borderRadius: '5px' }}>
        <ModalContainer width="528px">
          <ModalTitle>Publish Note</ModalTitle>
          <ModalBody>
            <ModalText>This will notify all members in the workspace</ModalText>
          </ModalBody>
          <ModalWrapper>
            <Button secondary disabled={false} onClick={toggleModal}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                publishNote();
              }}
            >
              Publish
            </Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default PublishNoteModal;
