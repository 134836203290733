import styled from '@emotion/styled';
import { getDate } from '../../lib/getDate';
import { DueDateDiv } from '../../pages/Payments/MonthlySubscriptionPage';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Modal from '../Modal';
import { Label } from '../styles/Label';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
import { SeatQuantityText, SeatWrapper } from './SelectSeats';
import { ReactComponent as LongArrow } from '../../assets/icons/LongArrow.svg';
import Button from '../CustomButton';
import { TitleText } from '../../pages/Payments/UpdatePlan';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import prependZero from '../../lib/prependZero';
import { getBrandLogo } from '../../lib/getBrandLogo';

const SummaryRowDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;
const SummaryText = styled.span`
  font-weight: 400;
`;

const TotalAmountText = styled(SummaryText)`
  font-weight: 600;
  font-size: 16px;
`;
export const DueDateWrapper = styled.div`
  background: hsla(0, 0%, 96%, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 31px;
  width: 90%;
  margin-left: 35px;
  border-radius: 5px;
  margin-top: 12px;
`;
const PaymentTitleText = styled(TitleText)`
  font-size: 16px;
  padding-bottom: 16px;
  margin-left: 35px;
  margin-right: 30px;
  margin-top: 16px;
  padding-left: 31px;
`;
const CardDetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-left: 35px;
  padding: 20px 31px;
  border-bottom: 1px solid #cfd3db;
  .card-details {
    display: flex;
    width: 50%;
    align-items: center;
    span {
      margin-left: 20px;
    }
  }
`;

const ProrateText = styled(SeatQuantityText)``;

type ConfirmPaymentProps = {
  isOpen: boolean;
  onClose: () => void;
  planType: 'monthly' | 'yearly';
  totalQuantity: number;
  dueDate: string;
  originalQuantity: number;
  prorateAmount: number;
  latestCard: any;
  cardBrand: string;
  confirmPayment: () => void;
  loading: boolean;
};

function ConfirmPaymentModal({
  isOpen,
  onClose,
  planType,
  totalQuantity,
  dueDate,
  originalQuantity,
  prorateAmount,
  latestCard,
  cardBrand,
  confirmPayment,
  loading
}: ConfirmPaymentProps) {
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalContainer width="638px">
          <ModalTitle>Collaborative Space</ModalTitle>
          <ModalBody>
            <ModalText>Updated {planType} subscription plan summary </ModalText>
            <SeatWrapper style={{ width: '90%' }}>
              <SeatQuantityText>Updated {planType} Seat Quantity</SeatQuantityText>
              <SummaryRowDiv>
                <div>
                  <SummaryText>Total Seat Quantity</SummaryText>
                </div>
                <div>{totalQuantity}</div>
              </SummaryRowDiv>
              <SummaryRowDiv>
                <div>
                  <TotalAmountText>Total Amount</TotalAmountText>
                </div>
                <div>
                  <TotalAmountText>
                    {' '}
                    US$ {planType === 'monthly' ? `${17 * totalQuantity}` : `${9 * totalQuantity * 12}`}.00
                  </TotalAmountText>
                </div>
              </SummaryRowDiv>
            </SeatWrapper>
            <DueDateWrapper>
              <Label>Upcoming Due Date</Label>
              <div>{getDate(dueDate)}</div>
            </DueDateWrapper>
            <SeatWrapper style={{ width: '90%', marginTop: '10px', paddingTop: '20px', paddingBottom: '16px' }}>
              <ProrateText>Prorate charge for Platform Suite</ProrateText>
              <div>
                Qty {originalQuantity} <LongArrow /> {totalQuantity}
              </div>
              <SummaryRowDiv>
                <div>
                  <TotalAmountText>Amount Due Today</TotalAmountText>
                </div>
                <div>
                  <TotalAmountText>US$ {prorateAmount}</TotalAmountText>
                </div>
              </SummaryRowDiv>
            </SeatWrapper>
            <PaymentTitleText>Payment Method</PaymentTitleText>
            <CardDetailsDiv>
              <div className="card-details">
                {getBrandLogo(cardBrand)}{' '}
                <span>
                  {capitalizeFirstLetter(cardBrand)} * * * * {latestCard?.card?.last4}
                </span>
              </div>
              <div className="expiry-date">
                {prependZero(latestCard?.card?.exp_month)} / {latestCard?.card?.exp_year}
              </div>
            </CardDetailsDiv>
          </ModalBody>
          <ModalWrapper>
            <Button disabled={loading} onClick={onClose} secondary>
              Go Back
            </Button>
            <Button disabled={loading} onClick={() => confirmPayment()}>
              Update Plan
            </Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default ConfirmPaymentModal;
