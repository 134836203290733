import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SearchConversation } from '../../../../assets/illustration/searchConversation.svg';
import useChatSocket from '../../../../hooks/features/chat/useChatSocket';
import { switchChat } from '../../../../reducers/conversations_new';
import { RootState } from '../../../../store';
import { ConversationDataI, Conversations, Tabs } from '../../types';
import ChatItem from './ChatItem/ChatItem';
type Props = {
  selectedTab: Tabs;
  onClick?: () => void;
};

const ChatListBody = ({ selectedTab, onClick }: Props) => {
  const dispatch = useDispatch();
  const [usersData, setUsersData] = useState<Conversations>({});
  const { orgData, nonOrgData, selectedConversationId } = useSelector((state: RootState) => state.conversations);

  const { readConversation } = useChatSocket();

  useEffect(() => {
    if (selectedTab === 'Organization') setUsersData(orgData);
    else setUsersData(nonOrgData);
  }, [selectedTab, nonOrgData, orgData]);

  const handleChatOnClick = (data: ConversationDataI) => {
    dispatch(switchChat(data));
    if (data.unreadMessage !== 0) readConversation(data.id);
    onClick && onClick();
  };

  return (
    <Container>
      <div>
        {Object.keys(usersData).length === 0 ? (
          <div className="illustration">
            <SearchConversation />
          </div>
        ) : (
          Object.keys(usersData).map((key, index) => (
            <ChatItem
              conversation={usersData[key][0]}
              key={key}
              clickHandler={() => {
                handleChatOnClick(usersData[key][0]);
              }}
              selected={selectedConversationId === key}
            />
          ))
        )}
      </div>
    </Container>
  );
};

export default ChatListBody;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  flex-direction: column;
  scrollbar-width: 10px;

  .illustration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px 0px;
  }
`;
