import React, { useState } from 'react';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../styles/ModalStyles';
import Select from 'react-select';
import styled from '@emotion/styled';
import { CustomButton } from '../../../CustomButton';
import Modal from '../../../Modal';

const RegisterModalContainer = styled.div`
  .label {
    padding: 0px;
    margin: 5px;
  }

  .input-control {
    margin: 20px 0px;
  }
  .module-name {
    width: 100%;
    border: 1px solid #acadac;
    border-radius: 5px;
    padding: 4px 10px;
    height: 40px;
  }
  .register-select {
    margin-bottom: 20px;
    height: 45px;
  }
`;

const AddRegisterModal = ({
  isOpen,
  toggleModal,
  register,
  handleAddRegisterToTable
}: {
  isOpen: boolean;
  toggleModal: () => void;
  register: any[];
  handleAddRegisterToTable: (registers: any, moduleName: string) => void;
}) => {
  const [selectedRegisters, setSelectedRegisters] = useState<any[]>([]);
  const [moduleName, setModuleName] = useState<string>('');

  const handleChange = (options: any) => {
    setSelectedRegisters(options);
  };
  return (
    <div>
      <Modal open={isOpen} onClose={toggleModal}>
        <ModalContainer width="528px">
          <ModalTitle>Register Item</ModalTitle>
          <ModalBody>
            <RegisterModalContainer>
              <div className="input-control">
                <p className="label">Module Name</p>
                <input className="module-name" value={moduleName} onChange={(e) => setModuleName(e.target.value)} />
              </div>
              <div className="input-control">
                <p className="label">Registers</p>
                <Select className="register-select" options={register} isMulti onChange={handleChange} />
              </div>
            </RegisterModalContainer>
          </ModalBody>
          <ModalWrapper>
            <CustomButton secondary disabled={false} onClick={toggleModal}>
              Discard
            </CustomButton>
            <CustomButton onClick={() => handleAddRegisterToTable(selectedRegisters, moduleName)}>
              Add Module
            </CustomButton>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </div>
  );
};

export default AddRegisterModal;
