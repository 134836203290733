import { useState } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '../../../axiosInstance';
import { useToggle } from '../../../lib/UseToggle';
import { PlanType, SeatType } from '../../../pages/Organization/ViewOrganization';

function useAssignSeat(dispatch, userDetails, state, setIsInSufficientSeats, totalSeats, monthlySeats, yearlySeats) {
  const [submittingAssignSeatData, setSubmittingAssignSeatData] = useState(false);
  const [seatAssigningTo, setSeatAssigningTo] = useState('');
  const [isAssignSeatModalOpen, toggleAssignSeatModal] = useToggle(false);
  const [showConfirmForAssigningSeat, setShowConfirmForAssigningSeat] = useState(false);

  const assignSeat = (props) => {
    console.log(props.cell.row);
    setSeatAssigningTo(props.cell.row.original);
    // toggleChooseSeatTypeModal();
    toggleAssignSeatModal();
  };

  const cancelAssigningSeat = () => {
    setSeatAssigningTo('');
    toggleAssignSeatModal();
    setIsInSufficientSeats(false);
  };

  const assignSeatToUser = async (plan: SeatType) => {
    const toastId = toast.loading('Assigning seat to user.');
    setSubmittingAssignSeatData(true);
    let totalNumberOfSeats = totalSeats;
    let takenSeats;
    let availableSeats;

    if (plan === 'MONTHLY') {
      takenSeats = state.currentOrganizationUsers.filter((user) => user.seatType === 'MONTHLY').length;
      availableSeats = monthlySeats - takenSeats;
      console.log('TAKEN, AVAILABLE', { takenSeats, availableSeats });
    } else if (plan === 'YEARLY') {
      takenSeats = state.currentOrganizationUsers.filter((user) => user.seatType === 'YEARLY').length;
      availableSeats = yearlySeats - takenSeats;
    } else if (plan === 'TRIAL') {
      availableSeats = totalNumberOfSeats;
    }

    let numberOfUsersToAssign = 1;
    if (availableSeats < numberOfUsersToAssign) {
      toast.error('The number of seats is insufficent. Please add more seats.');
      toast.dismiss(toastId);
      setIsInSufficientSeats(true);
      setSubmittingAssignSeatData(false);
    } else {
      await axiosInstance
        .patch(`/organization/manageMembers`, {
          users: [seatAssigningTo.id],
          organizationId: userDetails.orgId,
          seatType: plan // 'YEARLY' , 'TRIAL'
        })
        .then((response) => {
          console.log('response of assigning seat', response);
          if (response.data) {
            toast.dismiss(toastId);
            toast.success('Seat Assigned Successfully');
            dispatch({ type: 'ASSIGN_SEAT_TO_USER', payload: { ID: seatAssigningTo.id, seatType: plan } });
            setSeatAssigningTo('');
            toggleAssignSeatModal();
            setSubmittingAssignSeatData(false);
            setShowConfirmForAssigningSeat(false);
          }
        })
        .catch((error) => {
          toast.dismiss(toastId);
          console.error('error in assigning seat', error);
          toast.error('Error in assigning seat.');
          toggleAssignSeatModal();
          setSeatAssigningTo('');
          setSubmittingAssignSeatData(false);
        });
    }
  };

  return {
    submittingAssignSeatData,
    isAssignSeatModalOpen,
    cancelAssigningSeat,
    assignSeatToUser,
    assignSeat,
    seatAssigningTo,
    toggleAssignSeatModal,
    showConfirmForAssigningSeat,
    setShowConfirmForAssigningSeat
  };
}

export default useAssignSeat;
