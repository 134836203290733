import styled from '@emotion/styled';

export const Container = styled.div`
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.p`
  font-weight: 400;
  margin-bottom: 0px;
`;

export const GroupExitMessage = styled.p`
  font-weight: 400;
`;

export const ActionItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  > button {
    margin: 16px;
  }
  .white {
    background-color: white;
    padding: 7px 23px;
  }
`;
