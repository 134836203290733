import { useContext, useState } from 'react';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import Button from '../../components/CustomButton';
import { ListItemStyles } from '../../components/ListItem';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import { ReactComponent as DragoptionIcon } from '../../assets/icons/DragoptionIcon.svg';
import { ReactComponent as Visible } from '../../assets/icons/Visible.svg';
import { ReactComponent as NotVisible } from '../../assets/icons/NotVisible.svg';
import styled from '@emotion/styled';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { postDashboardApi, reorderItems, resetItems } from '../../reducers/dashboard';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router';
// import { Container } from '../../pages/Projects/CreateProject'
// import { toast } from 'aws-amplify'
// import { Paragraph } from '../../pages/Workspaces/Registers/RegisterView/AboutRegisterDocument'

type Item = {
  order: number;
  isVisible: boolean;
  title: string;
  id: string;
};

// const data: Item[] = [
//   {
//     order: 1,
//     isVisible: true,
//     title: 'Task Manager',
//     id: '1'
//     // data: [{}]
//   },
//   {
//     order: 2,
//     isVisible: true,
//     title: 'Projects',
//     id: '2',
//     // data: [{}]
//   },
//   {
//     order: 3,
//     isVisible: true,
//     title: 'Library',
//     id: '3'
//   },
//   {
//     order: 4,
//     isVisible: false,
//     title: 'Messages',
//     id: '4'
//   }
// ]

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
  margin-right: 4rem;
  margin-top: 0.9rem;
`;
const ItemStyles = styled.div`
  display: flex;
  align-items: center;
`;
const ItemText = styled.p`
  font-weight: 600;
  /* overflow: hidden; */
  white-space: nowrap;
  margin-left: 2.01rem;
`;

const IconStyles = styled.div`
  display: flex;
  justify-content: space-between;
  > * + * {
    margin-left: 1rem;
  }
`;

const Information = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const EditDashboard = () => {
  let [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = useContext(AppContext);
  // let [items, setItems] = useState<Array>([])
  let dashboardData = useSelector((state) => state?.dashboard?.dashboardData).map((item) => {
    if (state.orgId === 'null' && (item.moduleType === 'TASKS' || item.moduleType === 'FILES')) {
      return { ...item };
    } else if (state.orgId === 'null') {
      return { ...item, disabled: true };
    } else {
      return item;
    }
  });
  // let [dashboardData, setDashboardData] = useState<Item[]>(data)

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(dashboardData, result.source.index, result.destination.index);
    let orderedItems = items.map((item, index) => {
      return { ...item, order: index };
    });
    dispatch(reorderItems(orderedItems));
    // setItems(orderedItems)
    // setDashboardData(orderedItems)
  };

  const updateItem = (item: Item, toggle: boolean) => {
    let data = dashboardData.map((data) => (data.id === item.id ? { ...item, isVisible: toggle } : data));
    // setItems(data)
    dispatch(reorderItems(data));
  };

  const updateDashboard = async () => {
    setIsLoading(true);
    // dispatch(reorderItems(items))
    await dispatch(
      postDashboardApi({
        type: 'config',
        orgId: state.orgId === 'null' ? null : state.orgId,
        configData: dashboardData
      })
    );
    setIsLoading(false);
    navigate('/');
  };

  const resetData = () => {
    dispatch(resetItems());
    navigate('/');
  };
  // useEffect(() => {

  //   return () => {
  //     dispatch(resetItems())
  //   }
  // }, [])

  const handleBack = () => {
    dispatch(resetItems());
    navigate(-1);
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={() => handleBack()} />
          <NewTitleLeft>
            <PageTitle>Edit Dashboard</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button secondary onClick={resetData}>
            Discard
          </Button>
          <Button disabled={loading} onClick={updateDashboard}>
            UpdateDashboard
          </Button>
        </TitleRight>
      </Header>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ItemsContainer ref={provided.innerRef} {...provided.droppableProps}>
              {dashboardData.map((item, index) => {
                return (
                  item &&
                  !item?.disabled && (
                    <Draggable draggableId={String(item.id)} index={index} key={String(item?.id)}>
                      {(provided) => (
                        <ListItemStyles
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ItemStyles>
                            <IconStyles>
                              <DragoptionIcon />
                              {!item?.disabled ? (
                                item.isVisible ? (
                                  <Visible onClick={() => updateItem(item, false)} />
                                ) : (
                                  <NotVisible onClick={() => updateItem(item, true)} />
                                )
                              ) : null}
                            </IconStyles>
                            <ItemText>{item.title}</ItemText>
                          </ItemStyles>
                        </ListItemStyles>
                      )}
                    </Draggable>
                  )
                );
              })}
              {provided.placeholder}
            </ItemsContainer>
          )}
        </Droppable>
      </DragDropContext>
      {state.orgId === 'null' ? (
        <>
          <Information>Please join an organization to add below items to your dashboard</Information>
          <ItemsContainer>
            {dashboardData.map((item, index) => {
              return (
                item &&
                item?.disabled && (
                  <ListItemStyles>
                    <ItemStyles>
                      <IconStyles>
                        <DragoptionIcon />
                        <NotVisible />
                      </IconStyles>
                      <ItemText>{item.title}</ItemText>
                    </ItemStyles>
                  </ListItemStyles>
                )
              );
            })}
          </ItemsContainer>
        </>
      ) : null}
    </>
  );
};

export default EditDashboard;
