import * as yup from "yup";

const CollectionValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Name is too Short!")
    .max(50, "Name is too Long!")
    .required("Name is Required"),
});

export default CollectionValidationSchema;
