import styled from '@emotion/styled';
import { useContext } from 'react';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../../AppContext';
import useChatSocket from '../../../../hooks/features/chat/useChatSocket';
import { useToggle } from '../../../../lib/UseToggle';
import { OptionItem, ToggleSettings } from '../../../OptionsMenu';
import UpdateMembersModal from '../../Modals/UpdateMembersModal';
import { Member } from '../../types';
interface Props {
  name: string;
  profilePic?: string;
  conversationType: string;
  members: Member[];
  conversationOwner: string;
}

function Header({ name, profilePic, conversationType, members, conversationOwner }: Props) {
  const { blockChat, leaveChat } = useChatSocket();
  const [isOpen, toggleModal] = useToggle();
  const ids = members.map((member) => member.id);

  const {
    state: { userId }
  } = useContext(AppContext);

  const users = useSelector((state: any) =>
    state?.orgMembers?.users?.map((u: any) => ({
      id: u.id,
      name: u.fullName,
      picture: u.profilePhoto
    }))
  );

  const handleLeaveGroup = () => {
    /**
     * members will contain only other member data
     */

    leaveChat(members, userId);
  };

  // console.log('User : ', userId);
  // console.log('Owner : ', conversationOwner);

  return (
    <>
      <HeaderStyles>
        <div className="chat-details">
          <Avatar round={true} size="40" name={name} src={profilePic ? profilePic : ''} color="#26303e" />
          <span className="title">{name}</span>
        </div>
        <div className="right">
          {conversationType === 'group' ? <span onClick={toggleModal}>{members.length + 1} Members</span> : null}

          <ToggleSettings style={{ top: '125px', right: '-40px' }}>
            {conversationType === 'group' ? (
              <OptionItem onClick={handleLeaveGroup}>Leave group</OptionItem>
            ) : (
              <OptionItem onClick={blockChat}>Block User</OptionItem>
            )}
          </ToggleSettings>
        </div>
      </HeaderStyles>
      <UpdateMembersModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        users={users}
        selectedIds={ids}
        groupName={name}
        members={members}
        isOwner={conversationOwner === userId}
      />
    </>
  );
}

export default Header;

const HeaderStyles = styled.div`
  height: 70px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .title {
    margin-left: 15px;
  }

  .chat-details {
    display: flex;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
    margin-right: 20px;

    span {
      margin-right: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #00b292;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;
