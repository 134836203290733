import styled from "@emotion/styled";
import React from "react";

interface WorkSpaceProps {
  isSelected: boolean;
}

export const BoxStyles = styled.div<WorkSpaceProps>`
  border: 2px solid #e5e5e5;
  padding: 15px 52.75px;
  max-width: 243px;
  max-height: 52px;
  margin-bottom: 25px;
  margin-right: 1.4rem;
  flex: 1 0 21%;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
  background-color: ${(props) => (props.isSelected ? "#E5E5E5" : "")};
`;

export const WorkSpaceName = styled.p`
  font-weight: 400;
  color: var(--black);
  margin: 0px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 137px; */
`;

function WorkspaceTemplateTile(props) {
  return (
    <BoxStyles
      isSelected={props.template._id === props.selected}
      onClick={() => {
        props.handleSelect(props.template._id);
        props.setSelectedTemplateData(props.template);
      }}
    >
      <WorkSpaceName>{props.children}</WorkSpaceName>
    </BoxStyles>
  );
}

export default WorkspaceTemplateTile;
