import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { Dashboard, EditDashboard } from './pages/Dashboard';
import { Notes, Trash, ViewNote } from './pages/Library';
import {
  AboutFile,
  AddFilesToCollection,
  CreateNewCollection,
  CreateNewFile,
  CreateNewLinkedNote,
  EditCollection,
  Files,
  LinkedFiles,
  LinkedNotes,
  ViewCollection,
  ViewFile,
  ViewLinkedNote
} from './pages/Library/FilesView';
import { CreateOrganization, EditOrganization, OrgInvite, Pricing, ViewOrganization } from './pages/Organization/';
import { ErrorPage, PageNotFound, PageUnderConstruction } from './pages/Others';
import {
  AddEditProjectMembers,
  NewProjectView,
  OtherContacts,
  ProjectAddress,
  ProjectDetailsView,
  ProjectMembers,
  ProjectWorkspacesView,
  TeamFormPage
} from './pages/Projects/AboutProject';
import ListDocumentsInRegister from './pages/Projects/ListDocumentsInRegister';
import {
  CreateProject,
  EditProject,
  InternalProjectsListPage,
  ProjectArchivalPage,
  Projects,
  SharedProjectsListPage
} from './pages/Projects/ProjectViews';
import ProjectTasks from './pages/Projects/Tasks/ProjectTasks';
import { AboutTask, AllTasks, CreateTask, EditTask, ListAssociatedTasksPage, ViewTask } from './pages/Tasks';
import { ViewWorkspace, WorkspaceDetailsView } from './pages/Workspaces/AboutWorkspace';
import ChooseWorkspaceInvite from './pages/Workspaces/ChooseWorkspaceInvite';
import {
  CreateWorkspaceNewNote,
  ViewWorkspaceNote,
  WorkspaceLibrary,
  WorkspaceLibraryDiscussion,
  WorkspaceNotes
} from './pages/Workspaces/Library';
import {
  AboutWorkspaceFile,
  CreateNewWorkspaceFile,
  ViewWorkspaceFile,
  WorkspaceLinkedFile
} from './pages/Workspaces/Library/Files';
import {
  CreateNewDynamicDocumentInRegister,
  CreateNewRegister,
  CreateNewStaticDocumentInRegister,
  EditRegisterList,
  RegisterItemsPage,
  RegisterNotePage,
  RegisterSummaryPage,
  ViewRegisterDocument
} from './pages/Workspaces/Registers';
import {
  AboutRegisterDocument,
  DiscussionsOfRegisterDocument,
  ItemRevision,
  LinkedFilesOfRegisterDocument,
  NotesOfRegisterDocument, //depreciated.
  SendResponse
} from './pages/Workspaces/Registers/RegisterView';
import ViewWorkspaceRegisters from './pages/Workspaces/Registers/WorkspaceRegisters';
import {
  AboutWorkspaceTask,
  CreateWorkspaceTask,
  EditWorkspaceTask,
  TaskDiscussionWorkspace,
  ViewWorkspaceTask,
  WorkspaceTasks
} from './pages/Workspaces/Tasks';
import ViewTemplate from './pages/Workspaces/Templates/ViewTemplate';
import { EditOtherWorkspaceMembers, EditWorkspaceMembers, WorkspaceMembers } from './pages/Workspaces/WorkspaceMembers';
import { CreateNewWorkspace, EditWorkspace, WorkspaceArchivalPage } from './pages/Workspaces/WorkspaceViews';
import InviteOnlyRoute from './RouteGuards/InviteOnlyRoute';
// contexts
import { Elements } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { stripePromise } from '.';
import { AppContext } from './AppContext';
import DefaultLayout from './components/layouts/DefaultLayout';
import InformationPageLayout from './components/layouts/InformationPageLayout';
import CreateMeetingNoteTemplate from './components/MeetingNotes/CreateMeetingNoteTemplate';
import useGetIsSuperAdmin from './hooks/common/useGetIsSuperAdmin';
import { CustomConfirmSignUpForm } from './pages/customAuth/customConfirmSignUp';
import CustomLoginForm from './pages/customAuth/customLogin';
import { CustomSignupForm } from './pages/customAuth/customSignup';
import { ForceResetPassword } from './pages/customAuth/forceResetPassword';
import { CustomForgotPasswordForm } from './pages/customAuth/forgotPassword';
import { CustomResetPasswordForm } from './pages/customAuth/resetPassword';
import WelcomePage from './pages/Dashboard/WelcomePage';
import MeetingNotes from './pages/MeetingNotes/AllMeetingNotes';
import ExternalNotes from './pages/MeetingNotes/AllMeetingNotes/ExternalNotes';
import InternalNotes from './pages/MeetingNotes/AllMeetingNotes/InternalNotes';
import EditTaskFromMeetingNote from './pages/MeetingNotes/EditTask';
import NewEditMeetingNote from './pages/MeetingNotes/NewEditMeetingNotes';
import MeetingNotesSharedList from './pages/MeetingNotes/SharedList';
import AttendeeList from './pages/MeetingNotes/SharedList/AttendeeList';
import SharedList from './pages/MeetingNotes/SharedList/SharedList';
import ViewMeetingNote from './pages/MeetingNotes/ViewMeetingNote';
import NewMessages from './pages/Messages/NewMessages';
import AddNewCardPage from './pages/Payments/AddNewCardPage';
import InvoicesPage from './pages/Payments/InvoicesPage';
import MonthlySubscriptionPage from './pages/Payments/MonthlySubscriptionPage';
import NewPaymentPage from './pages/Payments/NewPaymentPage';
import UpdatePlan from './pages/Payments/UpdatePlan';
import YearlySubscriptionPage from './pages/Payments/YearlySubscriptionPage';
import EndUserAgreementPage from './pages/Policies/EndUserAgreementPage';
import NotebooksList from './pages/ProjectNotes/Notebooks';
import ProjectNote from './pages/ProjectNotes/ProjectNote';
import ProjectNotesList from './pages/ProjectNotes/ProjectNotesList';
import TaskNote from './pages/Tasks/TaskViews/ViewTask/TaskInformation/TaskNote';
import PrivacyPolicyPage from './pages/Policies/PrivacyPolicyPage';
import TasksLayout from './components/layouts/TasksLayout';
import HubSpotTasksPage from './pages/Tasks/HubSpotTasksPage';
import ViewHubspotTask from './pages/Tasks/HubspotTasks/ViewHubspotTask';
import { WorkspaceMeetingNotesPage } from './pages/Workspaces/MeetingNotes';
import { fetchConversations } from './reducers/conversations_new';
import { fetchDashboard } from './reducers/dashboard';
import { fetchNotification } from './reducers/notification';
import { fetchOrganizationMembers } from './reducers/users';
import NoOrgOnlyRoute from './RouteGuards/NoOrgOnlyRoute';
import SuperAdminOnlyRoute from './RouteGuards/SuperAdminOnlyRoute';

type AllRoutesType = {
  auth: {
    noOrganizationUser: RouteObject;
    memberWithoutSeat: RouteObject;
    memberWithSeat: RouteObject;
    superAdminWithoutSeat: RouteObject;
    superAdminWithSeat: RouteObject;
    collections: RouteObject[];
    payments: RouteObject[];
    common: RouteObject[];
    registers: RouteObject[];
  };
  unauth: RouteObject[];
};

const AppRoutes = ({ user }: any) => {
  const { state } = useContext(AppContext);
  const dispatch = useDispatch();
  const [currentRouteObj, setCurrentRouteObj] = useState<RouteObject[]>([]);
  const routes = useRoutes(currentRouteObj);
  const { isSuperAdmin } = useGetIsSuperAdmin();
  // fetch all organization users

  useEffect(() => {
    if (state.userId) {
      dispatch(fetchNotification(state?.userId));
    }
    if (state?.orgId && state.orgId !== 'null') {
      dispatch(fetchOrganizationMembers(state?.orgId));
    }
    if (state?.orgId) {
      dispatch(fetchDashboard(state.orgId));
    }

    dispatch(fetchConversations());
  }, [state?.userId, state?.orgId, dispatch]);

  useEffect(() => {
    // console.log('setting new routes');
    setCurrentRoute();
  }, [user, state, isSuperAdmin]);

  const routeObject: AllRoutesType = {
    auth: {
      noOrganizationUser: {
        path: '/',
        element: <DefaultLayout />,
        children: [
          {
            path: '/',
            element: <WelcomePage />,
            children: [
              { index: true, element: <Dashboard /> },
              { path: '/Notebook', element: <NotebooksList /> }
            ]
          },
          {
            path: '/pricing',
            element: <Pricing />
          },
          {
            path: '/createorganization',
            element: (
              <NoOrgOnlyRoute>
                <CreateOrganization />
              </NoOrgOnlyRoute>
            )
          },
          {
            path: '/organization/:organizationId',
            element: (
              <Elements stripe={stripePromise}>
                <ViewOrganization />
              </Elements>
            )
          },
          {
            path: '/dashboard/edit',
            element: <EditDashboard />
          },
          state.isChannelRockUser
            ? {
                path: '/tasks',
                element: <TasksLayout />,
                children: [
                  { index: true, element: <AllTasks /> },
                  { path: 'hubspot', element: <HubSpotTasksPage /> }
                ]
              }
            : {
                path: '/tasks',
                element: <AllTasks />
              },
          {
            path: '/tasks/new',
            element: <CreateTask />
          },

          {
            path: '/dashboard/tasks/new',
            element: <CreateTask />
          },
          {
            path: '/dashboard/tasks/:taskId/edit',
            element: <EditTask />
          },
          {
            path: '/tasks/:taskId/edit',
            element: <EditTask />
          },
          state.isChannelRockUser
            ? {
                path: '/tasks/hubspot/:taskId',
                element: <ViewHubspotTask />
              }
            : {},
          {
            path: '/tasks/:taskId',
            element: <ViewTask />,
            children: [
              { index: true, element: <AboutTask /> },
              { path: 'associated', element: <ListAssociatedTasksPage /> },
              { path: 'note', element: <TaskNote /> }
            ]
          },
          {
            path: 'dashboard/tasks/:taskId',
            element: <ViewTask />,
            children: [
              { index: true, element: <AboutTask /> },
              { path: 'associated', element: <ListAssociatedTasksPage /> },
              { path: 'note', element: <TaskNote /> }
            ]
          },
          {
            path: '/projects',
            element: <Projects />,
            children: [
              { index: true, element: <InternalProjectsListPage /> },
              { path: 'shared', element: <SharedProjectsListPage /> }
            ]
          },
          {
            path: '/files',
            caseSensitive: false,
            element: <Files />
          },
          {
            path: '/files/new',
            element: <CreateNewFile />
          },
          {
            path: '/files/:fileId',
            element: <ViewFile />,
            children: [
              { index: true, element: <AboutFile /> },
              { path: 'linkedfiles', element: <LinkedFiles /> },
              { path: 'notes', element: <LinkedNotes /> }
            ]
          },
          {
            path: '/files/:fileId/notes/new',
            element: <CreateNewLinkedNote />
          },
          {
            path: '/files/notes/:noteId',
            element: <ViewLinkedNote />
          },
          {
            path: '/notes',
            element: <Notes />
          },
          {
            path: '/notes/:noteId',
            element: <ViewNote />
          },
          {
            path: '/messages',
            element: <NewMessages />
          },
          {
            path: '/Trash',
            element: <Trash />
          },
          {
            path: '/invite',
            element: <OrgInvite />
          },
          {
            path: '/meetingnotes',
            element: <MeetingNotes />,
            children: [
              { index: true, element: <InternalNotes /> },
              { path: 'external', element: <ExternalNotes /> }
            ]
          }
        ]
      },
      memberWithoutSeat: {
        path: '/organization/:organizationId',
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            element: (
              <Elements stripe={stripePromise}>
                <ViewOrganization />
              </Elements>
            )
          }
        ]
      },
      memberWithSeat: {
        path: '/',
        element: <DefaultLayout />,
        children: [
          {
            path: '/organization/:organizationId',
            element: (
              <Elements stripe={stripePromise}>
                <ViewOrganization />{' '}
              </Elements>
            )
          },
          {
            path: '/project/:projectId',
            element: <NewProjectView />,
            children: [
              { index: true, element: <ProjectDetailsView /> },
              { path: 'workspaces', element: <ProjectWorkspacesView /> },
              {
                path: 'tasks',
                element: (
                  <SuperAdminOnlyRoute>
                    <ProjectTasks />
                  </SuperAdminOnlyRoute>
                )
              }
            ]
          },
          {
            path: '/project/members/:projectId',
            element: <ProjectMembers />
          },
          {
            path: '/project/members/:projectId/add',
            element: <AddEditProjectMembers />
          },
          {
            path: '/project/members/:projectId/update',
            element: <AddEditProjectMembers />
          },
          {
            path: '/project/address/:projectId',
            element: <ProjectAddress />
          },
          {
            path: '/project/othercontacts/:projectId',
            element: <OtherContacts />
          },
          {
            path: '/project/teams/:projectId',
            element: <TeamFormPage />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/',
            element: <ViewWorkspace />,
            children: [
              { index: true, element: <WorkspaceDetailsView /> },
              { path: 'notebooks', element: <ProjectNotesList /> },
              { path: 'registers', element: <ViewWorkspaceRegisters /> },
              { path: 'tasks', element: <WorkspaceTasks /> },
              { path: 'library', element: <WorkspaceLibrary /> },
              { path: 'meetingnotes', element: <WorkspaceMeetingNotesPage /> }
            ]
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/meetingnotes/newtemplate',
            element: <CreateMeetingNoteTemplate />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/meetingnotes/new',
            element: <NewEditMeetingNote />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/meetingnotes/:noteId',
            element: <NewEditMeetingNote />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/notebooks/:noteId',
            element: <ProjectNote />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/othercontacts',
            element: <OtherContacts />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/team',
            element: <TeamFormPage />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/tasks/:taskId',
            element: <ViewWorkspaceTask />,
            children: [
              { index: true, element: <AboutWorkspaceTask /> },
              { path: 'discussions', element: <TaskDiscussionWorkspace /> }
            ]
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/tasks/new',
            element: <CreateWorkspaceTask />
          },
          {
            path: 'project/workspace/:projectId/:workspaceId/tasks/:taskId/edit',
            element: <EditWorkspaceTask />
          },
          {
            path: '/newprojects/workspaceconfig/:projectId/new',
            element: <CreateNewWorkspace />
          },
          {
            path: '/newprojects/workspaceconfig/:projectId/:templateId',
            element: <ViewTemplate />
          },
          {
            path: '/newprojects/workspaceconfig/addmembers',
            element: <WorkspaceMembers />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/edit/members',
            element: <EditWorkspaceMembers />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/edit/othermembers',
            element: <EditOtherWorkspaceMembers />
          },
          {
            path: 'newprojects/workspaceconfig/:projectId/addmembers/:workspaceId',
            element: <WorkspaceMembers />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/library/files/new',
            element: <CreateNewWorkspaceFile />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/library/files/:fileId/notes/new',
            element: <CreateWorkspaceNewNote />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/library/files/:fileId/notes/:noteId',
            element: <ViewWorkspaceNote />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/library/files/:fileId',
            element: <ViewWorkspaceFile />,
            children: [
              { index: true, element: <AboutWorkspaceFile /> },
              { path: 'disussion', element: <WorkspaceLibraryDiscussion /> },
              { path: 'workspacenotes', element: <WorkspaceNotes /> },
              { path: 'workspacelinkedfiles', element: <WorkspaceLinkedFile /> }
            ]
          },
          {
            path: '/chooseinvite',
            element: (
              <InviteOnlyRoute>
                <ChooseWorkspaceInvite />
              </InviteOnlyRoute>
            )
          },
          {
            path: '/meetingnotes/:noteId',
            element: <ViewMeetingNote />
          },
          {
            path: '/meetingnotes/:noteId/task/:taskId',
            element: <EditTaskFromMeetingNote />
          },
          {
            path: '/meetingnotes/:noteId/publish',
            element: <MeetingNotesSharedList />,
            children: [
              { index: true, element: <AttendeeList /> },
              { path: 'shared', element: <SharedList /> }
            ]
          }
        ]
      },
      registers: [
        {
          path: '/',
          element: <DefaultLayout />,
          children: [
            {
              path: '/project/workspace/:projectId/:workspaceId/:registerId/dynamicdocument',
              element: <CreateNewDynamicDocumentInRegister />
            },
            {
              path: '/project/workspace/:projectId/:workspaceId/:registerId/staticdocument',
              element: <CreateNewStaticDocumentInRegister />
            },
            {
              path: '/project/workspace/:projectId/:workspaceId/:registerId/documentlist',
              element: <ListDocumentsInRegister />,
              children: [
                { index: true, element: <RegisterItemsPage /> },
                { path: 'summary', element: <RegisterSummaryPage /> }
              ]
            },
            {
              path: '/project/workspace/:projectId/:workspaceId/:registerId/documentlist/note',
              element: <RegisterNotePage />
            },
            {
              path: '/project/workspace/:projectId/:workspaceId/:registerId/viewdocument/:fileId',
              element: <ViewRegisterDocument />,
              children: [
                { index: true, element: <AboutRegisterDocument /> },
                { path: 'links', element: <LinkedFilesOfRegisterDocument /> },
                { path: 'notes', element: <NotesOfRegisterDocument /> },
                { path: 'discussions', element: <DiscussionsOfRegisterDocument /> },
                { path: 'itemRevision', element: <ItemRevision /> }
              ]
            },
            {
              path: '/project/workspace/:registerId/:fileId/response',
              element: <SendResponse />
            },
            {
              path: '/project/workspace/:projectId/:workspaceId/editregisterList',
              element: <EditRegisterList />
            },
            {
              path: '/newprojects/workspaceconfig/:projectId/new/register',
              element: <CreateNewRegister />
            },
            {
              path: '/newprojects/workspaceconfig/:projectId/newregister/:workspaceId',
              element: <CreateNewRegister />
            },
            {
              path: '/project/workspace/:projectId/add/register',
              element: <CreateNewRegister />
            }
          ]
        }
      ],
      superAdminWithoutSeat: {
        path: '/',
        element: <DefaultLayout />,
        children: [
          {
            path: '/organization/:organizationId',
            element: (
              <Elements stripe={stripePromise}>
                <ViewOrganization />
              </Elements>
            )
          },
          {
            path: '/editorganization',
            element: <EditOrganization />
          },
          {
            path: '/projects/archived',
            element: <ProjectArchivalPage />
          }
        ]
      },
      superAdminWithSeat: {
        path: '/',
        element: <DefaultLayout />,
        children: [
          { path: '/editorganization', element: <EditOrganization /> },
          {
            path: '/organization/:organizationId',
            element: (
              <Elements stripe={stripePromise}>
                <ViewOrganization />
              </Elements>
            )
          },
          {
            path: '/newprojects',
            element: <CreateProject />
          },
          {
            path: '/editproject/:projectId',
            element: <EditProject />
          },
          {
            path: '/project/:projectId/tasks',
            element: <ProjectTasks />
          },
          {
            path: '/project/:projectId/tasks/new',
            element: <CreateTask />
          },
          {
            path: '/project/:projectId/tasks/:taskId/edit',
            element: <EditTask />
          },
          {
            path: '/project/:projectId/tasks/:taskId',
            element: <ViewTask />,
            children: [
              { index: true, element: <AboutTask /> },
              { path: 'association', element: <ListAssociatedTasksPage /> }
            ]
          },
          {
            path: '/projects/archived',
            element: <ProjectArchivalPage />
          },
          {
            path: '/project/:projectId/workspaces/archived',
            element: <WorkspaceArchivalPage />
          },
          {
            path: '/project/workspace/:projectId/:workspaceId/editworkspace',
            element: <EditWorkspace />
          }
        ]
      },

      collections: [
        {
          path: '/',
          element: <DefaultLayout />,
          children: [
            { path: '/collections/new', element: <CreateNewCollection /> },
            { path: '/collections/:collectionId', element: <ViewCollection /> },
            { path: '/collections/:collectionId/add', element: <AddFilesToCollection /> },
            { path: '/collections/:collectionId/edit', element: <EditCollection /> }
          ]
        }
      ],
      payments: [
        {
          path: '/',
          element: <DefaultLayout />,
          children: [
            { path: '/organization/:organizationId/seatselection', element: <NewPaymentPage /> },
            {
              path: '/organization/:organizationId/managesubscription',
              element: <UpdatePlan />,
              children: [
                { index: true, element: <MonthlySubscriptionPage /> },
                { path: 'yearly', element: <YearlySubscriptionPage /> }
              ]
            },
            { path: '/organization/:organizationId/updatecard', element: <AddNewCardPage /> },
            { path: '/organization/:organizationId/invoices', element: <InvoicesPage /> }
          ]
        }
      ],
      common: [
        {
          path: '/',
          element: <DefaultLayout />,
          children: [
            { path: '*', element: <PageNotFound /> },
            { path: '/error', caseSensitive: false, element: <ErrorPage /> },
            { path: 'PageUnderConstruction', caseSensitive: false, element: <PageUnderConstruction /> }
          ]
        },
        {
          path: '/useragreement',
          element: <InformationPageLayout title="END USER LICENSE AGREEMENT" />,
          children: [{ index: true, element: <EndUserAgreementPage /> }]
        },
        {
          path: '/privacypolicy',
          element: <InformationPageLayout title="Privacy Policy" />,
          children: [{ index: true, element: <PrivacyPolicyPage /> }]
        },
        {
          path: '/termsandconditions',
          element: <InformationPageLayout title="Terms and Conditions" />,
          children: [{ index: true, element: <EndUserAgreementPage /> }]
        }
      ]
    },
    unauth: [
      {
        path: '/',
        element: <CustomLoginForm />
      },
      {
        path: '/signup',
        element: <CustomSignupForm />
      },
      {
        path: '/login',
        element: <Navigate replace to="/" />
      },
      {
        path: '/forgotPassword',
        element: <CustomForgotPasswordForm />
      },
      {
        path: '/confirmSignUp',
        element: <CustomConfirmSignUpForm />
      },
      {
        path: '/resetPassword',
        element: <CustomResetPasswordForm />
      },
      {
        path: 'forceResetPassword',
        element: <ForceResetPassword />
      },
      {
        path: 'invite',
        element: <OrgInvite />
      },
      {
        path: '*',
        element: <CustomLoginForm />
      },
      {
        path: '/useragreement',
        element: <InformationPageLayout title="END USER LICENSE AGREEMENT" />,
        children: [{ index: true, element: <EndUserAgreementPage /> }]
      },
      {
        path: '/privacypolicy',
        element: <InformationPageLayout title="Privacy Policy" />,
        children: [{ index: true, element: <PrivacyPolicyPage /> }]
      },
      {
        path: '/termsandconditions',
        element: <InformationPageLayout title="Terms and Conditions" />,
        children: [{ index: true, element: <EndUserAgreementPage /> }]
      }
    ]
  };

  const setCurrentRoute = () => {
    if (!user) {
      //inside here if user is not authenticated .
      setCurrentRouteObj([...routeObject.unauth]);
    } else if (user && state.orgId !== 'null' && state.orgId !== null) {
      // inside here if user is authenticated and is inside an organization.
      console.log('IS SUPER ADMIN IN ROUTES', isSuperAdmin);
      if (isSuperAdmin) {
        if (state.seatStatus === 0) {
          //when user is super admin and has a seat. Allow access for all routes.
          console.log('Super Admin with seat.');
          setCurrentRouteObj([
            ...routeObject.auth.common,
            ...routeObject.auth.collections,
            ...routeObject.auth.payments,
            ...routeObject.auth.registers,
            routeObject.auth.noOrganizationUser,
            routeObject.auth.memberWithSeat,
            routeObject.auth.superAdminWithSeat
          ]);
        } else if (state.seatStatus === 1) {
          // when user is super admin and has no seat.Allow Access for common routes, payments and some routes which are accessible by super admin which do not require a seat.
          console.log('Super admin without seat.');
          setCurrentRouteObj([
            ...routeObject.auth.common,
            ...routeObject.auth.collections,
            ...routeObject.auth.payments,
            routeObject.auth.noOrganizationUser,
            routeObject.auth.superAdminWithoutSeat
          ]);
        }
      } else {
        //inside here if user is not an super admin.
        if (state.seatStatus === 0) {
          // when user is member and has a seat . All routes are acceesible except the super admin routes.
          console.log('Member with seat.');
          setCurrentRouteObj([
            ...routeObject.auth.common,
            ...routeObject.auth.collections,
            ...routeObject.auth.registers,
            routeObject.auth.noOrganizationUser,
            routeObject.auth.memberWithSeat
          ]);
        } else if (state.seatStatus === 1) {
          //when user is member and does not have a seat. Only Common Routes and few other ones.
          console.log('Member Without Seat.');
          setCurrentRouteObj([
            ...routeObject.auth.common,
            ...routeObject.auth.collections,
            routeObject.auth.noOrganizationUser,
            routeObject.auth.memberWithoutSeat
          ]);
        }
      }
    } else if (user && (state.orgId === null || state.orgId === 'null')) {
      // inside here if user is authenticated and does not have an organization.
      console.log('No Org User');
      setCurrentRouteObj([
        ...routeObject.auth.common,
        ...routeObject.auth.collections,
        routeObject.auth.noOrganizationUser
      ]);
    }
  };

  return routes;
};

export default AppRoutes;
