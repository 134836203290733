export const getDate = (date?: Date | string) => {
  if (date) {
    return new Date(date).toLocaleDateString('En-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  } else {
    return new Date().toLocaleDateString('En-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }
};
