import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// import Amplify from 'aws-amplify';
import useInterval from '@use-it/interval';
import WebSocket from 'isomorphic-ws';
import {
  appendConversation,
  blockConversation,
  continueConversation,
  incomingMessage,
  leaveGroup,
  updateGroupMember
} from '../../reducers/conversations_new';
import { addToEditorTable, removeFromEditorTable, replaceContent } from '../../reducers/meetingNote';
import { postNotification } from '../../reducers/notification';
import {
  addToEditorTable as addToProjectNoteEditorTable,
  removeFromEditorTable as removeFromProjectNoteEditorTable,
  replaceContent as replaceProjectNoteContent
} from '../../reducers/projectNote';
import {
  addToEditorTable as addToTaskNoteEditorTable,
  removeFromEditorTable as removeFromTaskNoteEditorTable,
  replaceContent as replaceTaskNoteContent
} from '../../reducers/taskNote';
import { fetchOrganizationMembers } from '../../reducers/users';
import { postDiscussionMessage } from '../../reducers/discussion';
function useWebsocket(appState: any) {
  const [webSocket, setWebSocket] = useState(null);

  const dispatch = useDispatch();

  // send a `keep_alive` every minute
  // to keep the socket connection alive
  useInterval(() => {
    if (webSocket)
      webSocket?.send(
        JSON.stringify({
          type: 'KEEP_ALIVE'
        })
      );
  }, 60 * 1000);

  useEffect(() => {
    let ws: any;
    (async () => {
      if (appState.orgId) {
        let cognitoIdToken = await Auth.currentSession();
        const token = cognitoIdToken.getIdToken().getJwtToken();

        ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/?organizationId=${appState?.orgId}&token=${token}`);

        ws.onopen = () => {
          setWebSocket(ws);
        };

        ws.onmessage = function incoming(data: any) {
          const message = JSON.parse(data.data);
          if (message.type === 'notification') {
            dispatch(postNotification(message));
            //fetch new set of members if they are added or removed
            if (message.data.data.entityType === 'ORGANIZATION' && appState?.userId !== message.data.data.actionBy) {
              dispatch(fetchOrganizationMembers(appState?.orgId));
            }
          } else if (message.type === 'new_conversation') {
            dispatch(appendConversation(message.data));
          } else if (
            message.type === 'chat' &&
            (message.data.messageType === 'text' || message.data.messageType === 'file')
          ) {
            // if (appState?.userId !== message.data?.sentBy)
              dispatch(incomingMessage({ data: message.data, currentUser: appState?.userId }));
          } else if (message.type === 'chat' && message.data.messageType === 'new_conv_media') {
            // message.data.sentBy !== appState?.userId
            // ? dispatch(fetchConversations())
            // : dispatch(updateMediaMessage(message));
          } else if (message.type === 'chat' && message.data.messageType === 'media') {
            // dispatch(updateMediaMessage(message));
          } else if (message.type === 'chat' && message.data.messageType === 'new_conv_text') {
            // if (message.data.sentBy !== appState?.userId) dispatch(fetchConversations());
          } else if (message.type === 'chat' && message.data.sentBy !== appState?.userId) {
            // dispatch(addMessage(message));
          } else if (message.type === 'mark_as_read') {
            // dispatch(markAsRead(message.data.conversationId));
          } else if (message.type === 'discussion') {
            dispatch(postDiscussionMessage(message.data));
          } else if (message.type === 'update_members' && message.data.sentBy !== appState?.userId) {
            dispatch(updateGroupMember({ data: message.data, currentUser: appState?.userId }));
          } else if (message.type === 'block_conversation') {
            if (appState?.userId !== message.data?.sentBy) {
              dispatch(blockConversation(message.data));
            }
          } else if (message.type === 'continue_conversation') {
            if (appState?.userId !== message.data?.sentBy) {
              dispatch(continueConversation(message.data));
            }
          } else if (message.type === 'leave_group') {
            if (appState?.userId !== message.data?.sentBy)
              dispatch(leaveGroup({ data: message.data, currentUser: appState?.userId }));
            // if (message.data?.leftByUserId === appState.userId) {
            //   dispatch(leaveGroup(message.data));
            // } else {
            // dispatch(removeGroupMembers(message.data));
            // dispatch(updateMembers(message.data));
            // }
          }
          //Meeting notes events
          if (message.type === 'update_meeting_note_content' && message.editedBy !== appState?.userId) {
            dispatch(replaceContent(message.data));
          }
          if (message.type === 'edit_note_status') {
            //If project note
            if (message.noteType === 'workspace') dispatch(addToProjectNoteEditorTable(message));
            if (message.noteType === 'task') dispatch(addToTaskNoteEditorTable(message));
            else dispatch(addToEditorTable(message));
          }
          if (message.type === 'remove_edit_note_status') {
            if (message.noteType === 'workspace') dispatch(removeFromProjectNoteEditorTable(message));
            if (message.noteType === 'task') dispatch(removeFromTaskNoteEditorTable(message));
            else dispatch(removeFromEditorTable(message)); //meeting note
          }
          //project note events
          if (message.type === 'update_workspace_note_content') {
            dispatch(replaceProjectNoteContent(message.data));
          }

          //task note events
          if (message.type === 'update_task_note_content') {
            dispatch(replaceTaskNoteContent(message.data));
          }
        };

        ws.onerror = (err: any) => {
          console.error('error is', err);
        };
      }
    })();

    return () => {
      if (ws) ws.close();
      setWebSocket(null);
    };
  }, [appState?.orgId]);

  return webSocket;
}

export default useWebsocket;
