import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router';
import Button from '../../../components/CustomButton';
import { InputField } from '../../../components/Reusable/NewFormik/Input';
import { InputWrapper } from '../../../components/styles/FormStyles';
import { Header, PageTitle, TitleLeft, TitleRight } from '../../../components/styles/Header';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { updateWorkspaceMutationName } from '../../../graphql/operations/Mutations/Workspaces/WorkspaceMutations';
import { getWorkspaceQuery } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';

// import { useContext } from "react";
// import { AppContext } from "../../AppContext";
// import { Input } from "../../components/styles/Inputs";
// import { Label } from "../../components/styles/Label";
// import { Small } from "../../components/styles/SmallText";
// import CustomAvatar from "../../components/TableComponents/CustomAvatar";
// import CloseIcon from "../../assets/SvgComponents/CloseIcon";
// import PlusIcon from "../../assets/SvgComponents/PlusIcon";

const WorkspaceNameStyles = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    //Apply the style to only first div ie-Inputwrapper which is a div.
    flex: 0 0 75%;
  }
`;

// const RegisterContainer = styled.div`
//   /* border: 1px solid blue; */
// `;

// const RegisterList = styled.div`
//   padding-top: 25px;
//   table {
//     margin-bottom: 50px;
//   }
//   button {
//     svg {
//       vertical-align: middle;
//       margin-right: 5px;
//       display: inline-block;
//       padding-bottom: 2px;
//     }
//   }
//   tr {
//     td {
//       font-weight: 600 !important;
//     }
//   }
// `;

// const RegisterHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-right: 30px;
//   margin-right: 20px;
//   padding-bottom: 16px;
//   border-bottom: 1px solid var(--border-color);
//   h3 {
//     padding-bottom: 0px;
//     margin-left: 10px;
//   }
// `;

interface WorkspaceInterface {
  name: string;
}

function EditWorkspace() {
  let navigate = useNavigate();
  let { projectId, workspaceId } = useParams();
  // const { state } = useContext(AppContext);
  // const userId = state.userId;

  const [updateWorkSpace] = useMutation(updateWorkspaceMutationName);
  const {
    data: workspaceDetails
    // error: getWorkSpaceError,
    // loading: workspaceLoading,
    // refetch,
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    },
    fetchPolicy: 'network-only'
  });

  const workspaceValues: WorkspaceInterface = {
    name: workspaceDetails?.getWorkspace?.name
  };

  const handleSubmit = async (values: WorkspaceInterface) => {
    const toastId = toast.loading('Updating Workspace');
    try {
      let res = await updateWorkSpace({
        variables: {
          name: values?.name,
          id: workspaceId
        }
      });
      if (res) {
        setTimeout(() => {
          toast.success('Workspace Updated', {
            id: toastId
          });
          setTimeout(() => {
            window.location.href = `/project/workspace/${projectId}/${workspaceId}`;
          }, 3000);
        }, 5000);
      }
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const cancelButton = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}`);
  };

  return (
    <>
      <Formik
        initialValues={workspaceValues}
        // validationSchema={FileValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          // errors,
          // touched,
          // setFieldValue,
          // handleChange,
          handleSubmit
          // isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Header>
                <TitleLeft>
                  <PageTitle>Edit Workspace</PageTitle>
                </TitleLeft>
                <TitleRight>
                  <Button type="submit">Save</Button>
                  <Button onClick={cancelButton}>Cancel</Button>
                </TitleRight>
              </Header>
              <Container>
                <WorkspaceNameStyles>
                  <InputWrapper>
                    <InputField name="name" label="Workspace Name" value={values.name}></InputField>
                  </InputWrapper>
                  {/* <AssigneeWrapper>
                    <Label>Created By</Label>
                    <AssigneeSeparator>
                      <AvatarStyles>
                        <CustomAvatar name="Devon Lane" size={21} />
                      </AvatarStyles>
                      <Small>30 Apr 2021, 9:30 AM</Small>
                    </AssigneeSeparator>
                  </AssigneeWrapper> */}
                </WorkspaceNameStyles>
              </Container>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default EditWorkspace;
