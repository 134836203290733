import styled from '@emotion/styled';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import { getDate } from '../../lib/getDate';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../CustomButton';
import Modal from '../Modal';
import { Label } from '../styles/Label';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';
import { DueDateWrapper } from './ConfirmPaymentModal';

export const TotalSeatsWrapper = styled(DueDateWrapper)``;
export const BillAmountWrapper = styled(DueDateWrapper)``;

type CancelSubscriptionProps = {
  isOpen: boolean;
  onClose: () => void;
  planType: 'monthly' | 'yearly';
  dueDate: string;
  originalQuantity: number;
  latestCard: any;
  cardBrand: string;
  cancelSubscription: () => void;
  loading: boolean;
};

function CancelSubscriptionModal({
  isOpen,
  onClose,
  planType,
  cancelSubscription,
  originalQuantity,
  dueDate,
  loading
}: CancelSubscriptionProps) {
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalContainer width="638px">
          <ModalTitle>Collaborative Space - {capitalizeFirstLetter(planType)} Subscription</ModalTitle>
          <ModalBody>
            <ModalText>Your current {planType} subscription for PlatformSuite Collaborative space.</ModalText>
            <ModalText>Do you wish to cancel your subscription ?</ModalText>
            <TotalSeatsWrapper>
              <Label>{capitalizeFirstLetter(planType)} Seat Quantity</Label>
              <div>{originalQuantity}</div>
            </TotalSeatsWrapper>
            <BillAmountWrapper>
              <Label>{capitalizeFirstLetter(planType)} Bill Amount</Label>
              <div>US$ {planType === 'monthly' ? `${17 * originalQuantity}` : `${9 * originalQuantity * 12}`}.00</div>
            </BillAmountWrapper>
            <DueDateWrapper>
              <Label>Upcoming Due Date</Label>
              <div> {getDate(dueDate)}</div>
            </DueDateWrapper>
          </ModalBody>
          <ModalWrapper>
            <Button disabled={loading} onClick={onClose} secondary>
              Go Back
            </Button>
            <Button disabled={loading} onClick={() => cancelSubscription()}>
              Yes Confirm
            </Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CancelSubscriptionModal;
