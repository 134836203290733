import React, { useEffect, useContext } from 'react';
import { Header, NewTitleLeft, PageTitle } from '../../../components/styles/Header';
import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import { useNavigate } from 'react-router';
import { useQuery } from '@apollo/client';
import NotesContainer from '../../../components/Notes/NotesContainer';
import { AppContext } from '../../../AppContext';
import { LIST_NOTES_QUERY } from '../../../graphql/operations/Queries/Library/NotesQueries';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import { useToggle } from '../../../lib/UseToggle';
import CreatePersonalNoteModal from '../../../components/Modals/CreatePersonalNoteModal';
// const TitleLeft = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-left: 3.12rem;
//   h2 {
//     font-weight: 600;
//     margin-top: 0px;
//     margin-bottom: 0px;
//   }
//   small {
//     padding-left: 3px;
//   }
// `;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

function Notes() {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [isCreateNoteModalOpen, toggleModal] = useToggle();
  const { data, error, loading, refetch } = useQuery(LIST_NOTES_QUERY, {
    variables: {
      operation: 'query',
      type_typeId: `user_${state.userId}`,
      recordStatus: 0
    },
    fetchPolicy: 'network-only'
  });
  const createNewNote = () => {
    navigate(`/Notes/new`);
  };
  const viewNote = (_id: string) => {
    navigate(`/Notes/${_id}`);
  };
  useEffect(() => {
    refetch();
  }, [data]);
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  return (
    <>
      <Header>
        <NewTitleLeft>
          <PageTitle>Notes</PageTitle>
        </NewTitleLeft>
        <TitleRight>
          <Button onClick={toggleModal}>+ New Notes</Button>
        </TitleRight>
      </Header>
      <div className="notes-list">
        <NotesContainer clickHandler={viewNote} data={data?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems} />
      </div>
      <CreatePersonalNoteModal isOpen={isCreateNoteModalOpen} toggleModal={toggleModal} />
    </>
  );
}

export default Notes;
