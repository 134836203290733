import { useMemo, useState, useContext, useReducer } from 'react';
import styled from '@emotion/styled';
import { MOCKCOLUMNS } from '../../../constants/MockColumnData';
import Table from '../../../components/Table';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import { useSidebar } from '../../../lib/SidebarState';
import { TaskStyles, DropdownDiv, reducer, getValue } from '../../../components/Tasks';
import Button from '../../../components/CustomButton';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { WORKSPACE_LIST_TASKS_QUERY } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { AppContext } from '../../../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import Select from 'react-select';
import { editTaskOptions } from '../../../constants/DropDownOptions';
import { tableStatusStyles } from '../../../components/styles/StatusStyles';
import { HighPriority, LowPriority, MediumPriority } from '../../../components/TableComponents/Priority';
import { updateTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import WorkspaceKanban from '../../../components/KanbanComponents/WorkspaceKanban';
import { ReactComponent as KanbanIcon } from '../../../assets/icons/kanbanIcon.svg';
import { ReactComponent as TodoIcon } from '../../../assets/icons/todoiconblue.svg';
// import {
//   columnsFromBackend,
//   // KanbanColumns,
// } from "../../../constants/KanbanData";
// import WorkspaceKanban from "../../../components/KanbanComponents/WorkspaceKanban";
const TaskContainer = styled.div`
  margin-left: 45px;
  margin-right: 45px;
`;

const initialState: any = {};

function WorkspaceTasks() {
  const { projectId, workspaceId } = useParams();
  const [toggleKanban, setToggleKanban] = useState(false);
  const { sidebarOpen } = useSidebar();
  const navigate = useNavigate();
  const [taskdata, dispatch] = useReducer(reducer, initialState);
  const { state } = useContext(AppContext);
  const { state: taskState } = useLocation();
  // const [kanbanColumns, setColumns] = useState<any>(columnsFromBackend);

  const {
    data: apidata,
    error,
    loading
    // refetch,
  } = useQuery(WORKSPACE_LIST_TASKS_QUERY, {
    variables: {
      organizationId_projectId_typeId: `${state.orgId}_${projectId}_${workspaceId}`,
      type: 'workspace'
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (taskState) {
        console.log('INSIDE TASK STATE', taskState);
        let found = data.list_TaskItems._TaskItems.some((task) => task._id === taskState._id);
        if (!found) {
          // if (taskState.assignedTo === state.userId) {
          let newarr = [...data.list_TaskItems.TaskItems, taskState];
          dispatch({
            type: 'TABLE',
            data: newarr
          });
          // } else {
          //   dispatch({
          //     type: "TABLE",
          //     data: data.listTasks.Tasks,
          //   });
          // }
        } else {
          let newarr = [...data.list_TaskItems._TaskItems];
          const indexOfItemInArray = newarr.findIndex((task) => task._id === taskState._id);
          newarr.splice(indexOfItemInArray, 1, taskState);
          dispatch({
            type: 'TABLE',
            data: newarr
          });
        }
      } else {
        dispatch({
          type: 'TABLE',
          data: data.list_TaskItems._TaskItems
        });
      }
    }
  });
  const [updateTask] = useMutation(updateTaskMutation, {
    fetchPolicy: 'no-cache'
  });

  const updateStatus = (selectedOption: any, id: string, data) => {
    updateTask({
      variables: {
        status: selectedOption.value,
        id: id
      }
    })
      .then((res) => {
        if (res) {
          dispatch({
            type: 'UPDATESTATUS',
            data: { id: id, status: selectedOption.value }
          });
        }
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  };

  const columns = useMemo(
    () => [
      ...MOCKCOLUMNS,
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { row, value } }) => (
          <span onClick={(e) => e.stopPropagation()}>
            <Select
              name="status"
              options={editTaskOptions}
              defaultValue={getValue(editTaskOptions, value)}
              styles={{ ...tableStatusStyles }}
              width="200px"
              // height="100px"
              menuPortalTarget={document.body}
              onChange={(selectedOption) => {
                updateStatus(selectedOption, row.original._id, row.original);
              }}
            />
          </span>
        )
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ cell: { value } }) =>
          value === 'high' ? <HighPriority /> : value === 'medium' ? <MediumPriority /> : <LowPriority />
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ cell: { value } }) => (
          <span>
            {new Date(value).toLocaleDateString('en-GB', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}
          </span>
        )
      }
    ],
    [taskdata]
  );
  const data = useMemo(() => taskdata, [taskdata]);

  const viewTask = (id: string) => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/tasks/${id}`);
  };

  const createTask = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/tasks/new`);
  };
  const toggleAndFetch = (toggleState: boolean) => {
    if (toggleState) {
      dispatch({
        type: 'KANBAN'
      });
    }
    if (!toggleState) {
      dispatch({
        type: 'BACKTOTABLE',
        data: apidata.list_TaskItems._TaskItems
      });
    }
    setToggleKanban(toggleState);
  };

  // useEffect(() => {
  //   refetch();
  // }, []);

  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;

  return (
    <TaskContainer>
      <TaskStyles isOpen={sidebarOpen}>
        <div className="tasks-header">
          <h4 className="tasks-title">To-do List</h4>
          {!toggleKanban && <TodoIcon onClick={() => toggleAndFetch(true)} />}
          {toggleKanban && <KanbanIcon onClick={() => toggleAndFetch(false)} />}
        </div>
        <Button onClick={createTask}>+ New Task</Button>
      </TaskStyles>
      {!toggleKanban && (
        <DropdownDiv>
          <div className="drop-item">
            <TableDropDown state={true} text="Today">
              {data.today && <Table columns={columns} data={data?.today} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={true} text="This Month">
              {data.month && <Table columns={columns} data={data?.month} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={false} text="Coming Up">
              {data.upcoming && <Table columns={columns} data={data?.upcoming} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={true} text="Done">
              {data.done && <Table columns={columns} data={data?.done} clickHandler={viewTask} />}
            </TableDropDown>
          </div>
        </DropdownDiv>
      )}
      {toggleKanban && data && <WorkspaceKanban setColumns={dispatch} columns={taskdata} updateTask={updateTask} />}
    </TaskContainer>
  );
}

export default WorkspaceTasks;
