import styled from '@emotion/styled';
import { getDate } from '../lib/getDate';
import { ReactComponent as FileIcon } from '../assets/icons/NewFileIcon.svg';

const Divider = styled.span`
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

const PublishedSpan = styled.span`
  margin-left: 5px;
  color: #93979f;
  font-size: 15px;
`;

export const MockProjectNoteColumns = [
  {
    id: 'Note',
    Header: 'Note',
    accessor: 'title',
    Cell: (props: any) => {
      return (
        <Divider>
          <FileIcon />
          {props.row.original.title}
          {/* {!props.row.original.isDraft && <PublishedSpan>[Published]</PublishedSpan>} */}
        </Divider>
      );
    }
  },
  {
    id: 'author',
    Header: 'Author',
    accessor: 'author',
    Cell: ({ cell: { value } }: any) => value
  },
  {
    id: 'createdOn',
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }: any) => getDate(value)
  },

  {
    id: 'updatedOn',
    Header: 'Updated On',
    accessor: 'updatedAt',
    Cell: ({ cell: { value } }: any) => getDate(value)
  }
];

export const MockNotebookColumn = [
  {
    id: 'Note',
    Header: 'Note',
    accessor: 'title',
    Cell: (props: any) => {
      return (
        <Divider>
          <FileIcon />
          {props.row.original.title}
          {/* {!props.row.original.isDraft && <PublishedSpan>[Published]</PublishedSpan>} */}
        </Divider>
      );
    }
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'type',
    Cell: ({ cell: { value } }: any) => value
  },
  {
    id: 'createdOn',
    Header: 'Created On',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }: any) => getDate(value)
  },

  {
    id: 'updatedOn',
    Header: 'Updated On',
    accessor: 'updatedAt',
    Cell: ({ cell: { value } }: any) => getDate(value)
  }
];

export const MockProjectNotesData = [
  {
    title: 'Project Note 1',
    author: 'Aditya Sajoo',
    createdOn: '2022-09-15T05:16:15.625Z',
    updatedOn: '2022-09-15T05:16:15.625Z',
    workspaceId: '0182aa75-6a0a-afd4-e05d-3ecac4e89543'
  },
  {
    id: '2',
    author: 'Aditya Sajoo',
    title: 'Project Note 2',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '3',
    author: 'Aditya Sajoo',
    title: 'Project Note 3',
    createdOn: getDate(),
    updatedOn: getDate()
  },
  {
    id: '4',
    author: 'Aditya Sajoo',
    title: 'Project Note 4',
    createdOn: getDate(),
    updatedOn: getDate()
  }
];
