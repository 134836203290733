import axiosInstance from '../axiosInstance';
import { sentryError } from './SentryError';

export const getWorkspaceIds = async (projectId: string, userId: string) => {
  return axiosInstance
    .get(`/userEntity/?entityType=WORKSPACE&projectId=${projectId}&userId=${userId}`)
    .then((response) => {
      if (response.data.data && response.data.data.length > 0) {
        let entities = response.data.data;
        let Ids = entities.map((entity) => entity.entityId);
        return Ids;
      } else {
        return [];
      }
    })
    .catch((error) => {
      sentryError(error);
      return error;
    });
};
