import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import Avatar from 'react-avatar';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as SearchConversation } from '../../../../assets/illustration/searchConversation.svg';
import { appendConversation, createConversation, switchChat } from '../../../../reducers/conversations_new';
import Loader from '../../../Loader';
import { UserDataI } from '../../types';
import { RootState } from '../../../../store';
import { useNavigate } from 'react-router';

interface Props {
  users: UserDataI[];
  loading: boolean;
  toggleSearchByEmail: () => void;
  searchByEmail: boolean;
  toggleSearch: () => void;
  onClick?: () => void;
}

/**
 * @desc : Func to create a conversation
 * This function checks if the conversation with that user exists
 * If yes -> Go to that conversation
 * Else -> Create conversation
 */

const SearchBody = ({ users, loading, toggleSearchByEmail, searchByEmail, toggleSearch, onClick }: Props) => {
  const { state } = useContext(AppContext);
  const dispatch = useDispatch();
  const [createConversationLoading, setCreateConversationLoading] = useState(false);
  const { nonOrgData, orgData } = useSelector((state: RootState) => state.conversations);
  const navigate = useNavigate();

  const handleCreateConversation = async (currentOrgId: string, user: UserDataI) => {
    const data = await createConversation({
      id: uuidv4(),
      conversationType: 'individual',
      ownerOrgId: currentOrgId,
      userOrgId: user.orgId,
      conversationWithUserId: user.userId
    });
    return data;
  };
  const handleClick = async (user: UserDataI) => {
    if (createConversationLoading) return;
    /**
     * Check if the conversation exists
     * If yes switch chat
     */
    const allConversations = { ...orgData, ...nonOrgData };
    if (allConversations.hasOwnProperty(user.userId) && user.conversationDetails) {
      dispatch(switchChat(user.conversationDetails));
      toggleSearch();
      navigate('/messages');
      return;
    }

    setCreateConversationLoading(true);
    const id = toast.loading('Creating the conversation');
    try {
      const data = await handleCreateConversation(state.orgId, user);
      //Go back to ChatList
      if (searchByEmail) toggleSearchByEmail();
      toggleSearch();

      dispatch(appendConversation(data));
      dispatch(switchChat(data));

      toast.success('Successfully created the conversation', { id });
      onClick && onClick();
    } catch (error) {
      toast.error('Something went wrong', { id });
    } finally {
      setCreateConversationLoading(false);
    }
  };
  return (
    <Container>
      {loading ? (
        <Loader />
      ) : users.length === 0 ? (
        <div className="illustration">
          <SearchConversation />
          <button onClick={toggleSearchByEmail}>{!searchByEmail ? 'Search by Email' : 'Search By Username'}</button>
        </div>
      ) : (
        users.map((user) => (
          <User key={user.userId} onClick={() => handleClick(user)} loading={createConversationLoading as boolean}>
            <Avatar round={true} size="40" name={user?.name} src={user.picture ? user.picture : ''} color="#26303e" />
            <div className="userDetails">
              <span className="name">{user.name}</span>
              {user.email ? <span className="email">{user.email}</span> : null}
            </div>
          </User>
        ))
      )}
    </Container>
  );
};

export default SearchBody;

const User = styled.div<{ loading: boolean }>`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 10px;

  .userDetails {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 16px;
    margin-left: 10px;
    justify-content: center;

    .email {
      color: #05b191;
      font-size: 14px;
    }
  }

  &:hover {
    cursor: ${(props) => (props.loading ? 'wait' : 'pointer')};
    background-color: #f6f5f5;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  flex-direction: column;

  .illustration {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  button {
    margin-top: 15px;
    border: none;
    background: #deeef3;
    border-radius: 5px;
    padding: 5px 17px;
    font-weight: 600;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      background: #b4b9ba;
    }
  }
`;
