import React from 'react';
import Button from '../../components/CustomButton';
import Modal from '../../components/Modal';
import { BillAmountWrapper, TotalSeatsWrapper } from '../../components/Payment/CancelSubscriptionModal';
import { DueDateWrapper } from '../../components/Payment/ConfirmPaymentModal';
import { Label } from '../../components/styles/Label';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../components/styles/ModalStyles';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import { getDate } from '../../lib/getDate';
import getUtcDate from '../../lib/getUtcDate';
import { ModalText } from '../Workspaces/Registers/CreateNewRegister';

type RProps = {
  isOpen: boolean;
  onClose: () => void;
  planType: 'monthly' | 'yearly';
  dueDate: string;
  originalQuantity: number;
  reactivateSubscription: () => void;
  reactivatingSubscription: boolean;
};

function ReactivateSubscriptionModal({
  isOpen,
  onClose,
  planType,
  dueDate,
  originalQuantity,
  reactivateSubscription,
  reactivatingSubscription
}: RProps) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer width="638px">
        <ModalTitle>Collaborative Space - {capitalizeFirstLetter(planType)} Subscription</ModalTitle>
        <ModalBody>
          <ModalText style={{ lineHeight: '22px' }}>
            Your {planType} subscription for PlatformSuite Collaborative space will end <br />
            on {getUtcDate(dueDate)}. Do you wish to reactivate your subscription?
          </ModalText>
          <TotalSeatsWrapper>
            <Label>{capitalizeFirstLetter(planType)} Seat Quantity </Label>
            <div>{originalQuantity}</div>
          </TotalSeatsWrapper>
          <BillAmountWrapper>
            <Label>{capitalizeFirstLetter(planType)} Bill Amount</Label>
            <div>US$ {planType === 'monthly' ? `${17 * originalQuantity}` : `${9 * originalQuantity * 12}`}.00</div>
          </BillAmountWrapper>
          <DueDateWrapper>
            <Label>Upcoming Due Date</Label>
            <div>{getDate(dueDate)}</div>
          </DueDateWrapper>
        </ModalBody>
        <ModalWrapper>
          <Button disabled={reactivatingSubscription} secondary onClick={onClose}>
            Go Back
          </Button>
          <Button disabled={reactivatingSubscription} onClick={reactivateSubscription}>
            Reactivate {planType} subscription
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
}

export default ReactivateSubscriptionModal;
