import React from 'react';
import NavigationLink from '../NavigationLink';
import { Outlet } from 'react-router';
import { PageNavStyles } from '../styles/PageNavStyles';

function TasksLayout() {
  return (
    <>
      <PageNavStyles>
        <NavigationLink end to="/tasks">
          All Tasks
        </NavigationLink>
        <NavigationLink to="hubspot">Hubspot</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
}

export default TasksLayout;
