import { useMutation, useQuery } from '@apollo/client';

import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import { archiveProjectMutation } from '../../../graphql/operations/Mutations/Projects/ProjectMutations';
import { LIST_ARCHIVED_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';

const useArchivedProjects = () => {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const { data, error, loading } = useQuery(LIST_ARCHIVED_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId
    },
    fetchPolicy: 'network-only'
  });
  const [unArchiveProject] = useMutation(archiveProjectMutation);

  const handleUnArchive = async (projectId: string) => {
    const toastId = toast.loading('UnArchiving');
    try {
      let response = await unArchiveProject({
        variables: {
          id: projectId,
          recordStatus: 0
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Project unArchived.');
        navigate(`/projects`);
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('Error in un-archiving project.');
    }
  };
  return {
    data,
    error,
    loading,
    handleUnArchive
  };
};

export default useArchivedProjects;
