import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import axiosInstance from '../../axiosInstance';

function useGetWorkspaceUsers(workspaceId: string) {
  const { state } = useContext(AppContext);
  const [error, setIsError] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);

  useEffect(() => {
    const getWorkspaceUsers = async () => {
      if (!workspaceId) {
        setIsError(true);
      } else {
        setIsLoading(true);
        axiosInstance
          .get(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`)
          .then((response) => {
            if (response.data.data && response.data.data?.length > 0) {
              let fullDetails = response.data.data.map((user) => {
                return {
                  id: user.userId,
                  label: user.userId === state.userId ? `Myself (${user.fullName})` : user.fullName,
                  value: user.fullName
                };
              });
              setWorkspaceUsers(fullDetails);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error('error in loading ws members', error);
            setIsLoading(false);
            setIsError(true);
            setWorkspaceUsers([]);
          });
      }
    };
    getWorkspaceUsers();
  }, []);
  return {
    error,
    loading,
    workspaceUsers
  };
}

export default useGetWorkspaceUsers;
