import { DateInput } from '../../styles/Inputs';
import { ErrorText } from './TextError';
import { CalendarIconPlacer } from '../../styles/FormStyles';
import { FormikErrors, FormikTouched } from 'formik';

type CustomInputProps = JSX.IntrinsicElements['input'] & {
  label?: string;
  name?: string;
  id?: string;
  type?: string;
  touched: boolean | undefined | FormikTouched<any> | FormikTouched<any>[];
  errors: string | string[] | undefined | FormikErrors<any> | FormikErrors<any>[];
};

// type ownProps = FieldInputProps<""> & CustomInputProps;

export const DateInputField = ({ type, name, touched, errors, ...props }: CustomInputProps): JSX.Element => {
  return (
    <div>
      <CalendarIconPlacer>
        <DateInput id={name} type={type} {...props} error={touched && errors} />
      </CalendarIconPlacer>
      {touched && errors ? <ErrorText>{errors}</ErrorText> : null}
    </div>
  );
};

// export const DateInputField: React.FC<ownProps> = ({
//   label,
//   type,
//   ...props
// }) => {
//   const [field, meta] = useField(props);
//   return (
//     <>
//       {/* <Label htmlFor={props.id || props.name}>{label}</Label> */}
//       <CalendarIconPlacer>
//         <DateInput
//           type={type}
//           {...field}
//           {...props}
//           error={meta.touched && meta.error}
//         />
//       </CalendarIconPlacer>
//       {meta.touched && meta.error ? (
//         // <div style={{ flex: '1 1 100%' }}>
//         <ErrorMessage component={TextError} name={props.name} />
//       ) : // </div>
//         null}
//     </>
//   );
// };
