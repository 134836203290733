import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance';

export const fetchDiscussionMessages = createAsyncThunk(
  'discussion/fetchDiscussionMessages',
  async (entityId: String) => {
    const result = await axiosInstance.get(`/discussion?entityId=${entityId}`);
    return result?.data.data;
  }
);

export const discussionSlice = createSlice({
  name: 'discussion',
  initialState: {
    discussionMessageData: [],
    error: false,
    loading: false
  },
  reducers: {
    postDiscussionMessage: (state: any, action) => {
      state.discussionMessageData = [...state.discussionMessageData, action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscussionMessages.fulfilled, (state, action) => {
        action.payload.sort(function (a, b) {
          var dateA = new Date(a.createdAt),
            dateB = new Date(b.createdAt);
          return dateA - dateB;
        });
        state.discussionMessageData = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchDiscussionMessages.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.discussionMessageData = [];
      })
      .addCase(fetchDiscussionMessages.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      });
  }
});

export const { postDiscussionMessage } = discussionSlice.actions;
