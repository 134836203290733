import * as yup from "yup";

export const StaticDocumentSchemaValidation = yup.object({
  documentName: yup
    .string()
    .min(2, "Document name is too short")
    .max(50, "Document name is too Long")
    .required("Document name is required"),
  file: yup.mixed().required("A file is required"),
  links: yup
    .array()
    .of(
      yup
        .string()
        .url("Please Enter correct url")
        .required("A Link cannot be empty. ")
    ),
  description: yup
    .string()
    .required("A description for the document is required"),

  // .required('required')
});
