import styled from '@emotion/styled';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import useTextEditor from '../../../hooks/features/editor/useTextEditor';
import { useToggle } from '../../../lib/UseToggle';
import { BlockDataI, EditingTable, MeetingNoteI, SectionI } from '../../../pages/MeetingNotes/types';
import { ProjectNoteI } from '../../../reducers/projectNote';
import { TaskNoteI } from '../../../reducers/taskNote';
import EditorDeleteContentModal from '../../Modals/EditorDeleteContentModal';
import { OptionItem, ToggleSettings } from '../../OptionsMenu';
import { ToggleButton } from '../styles/EditorStyles';

export const HeaderStyles = styled.div`
  .ProseMirror {
    outline: none;
    background-color: #ffffff;
    padding: 2px 20px;
    padding-right: 55px;
  }
  .ProseMirror .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }
  .my-custom-heading {
    font-size: 20px;
  }
  border-bottom: 1px solid #e9eaec;

  margin: 0px;
  position: relative;
`;

const CustomDocument = Document.extend({
  content: 'heading'
});

interface Props {
  sectionId: string;
  sectionHeader: string;
  editable?: boolean;
  noteData: MeetingNoteI | ProjectNoteI | TaskNoteI;
  editorType: 'MEETING_NOTE' | 'PROJECT_NOTE' | 'TASK_NOTE';
  noOfSections: number;
  editingTable: EditingTable;
  blocks: BlockDataI[];
}

const SectionHeader = ({
  sectionHeader,
  sectionId,
  noteData,
  editorType,
  noOfSections,
  editingTable,
  blocks,
  editable = true
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, toggleModal] = useToggle();

  const timer = useRef<any>(null);

  const { handleAutoSave, handleHeaderUpdate, handleSectionDelete } = useTextEditor(sectionId, null);

  const [showDeleteOption, setShowDeleteOption] = useState(true);

  const deleteSection = () => {
    if (noOfSections === 1) {
      toast.error('This item cannot be deleted, as this is the only section.');
      toggleModal();
      return;
    }
    handleSectionDelete(editorType);
    handleAutoSave(editorType, undefined, true);
    toggleModal();
    toast.success('Block deleted successfully');
  };

  const delaySave = (updatedSections: SectionI[]) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(
      // () => handleMeetingNoteAutoSave(ws, noteData as MeetingNoteI, updatedSections, authToken, undefined, undefined),
      () => handleAutoSave(editorType, undefined),
      2000
    );
  };

  const headerEditor = useEditor({
    editable,
    extensions: [
      CustomDocument,
      Placeholder.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return 'Section Heading';
          }

          return 'Start Typing...';
        }
      }),
      Heading.configure({
        HTMLAttributes: {
          class: 'my-custom-heading'
        }
      }),
      Text
    ],
    onBlur({ editor }) {
      handleHeaderUpdate(editorType, editor.getText());
      handleAutoSave(editorType, undefined);
      setIsFocused(false);
    },
    onFocus() {
      setIsFocused(true);
    },

    content: `${sectionHeader}`
  });

  useEffect(() => {
    if (!isFocused && sectionHeader !== headerEditor?.getText()) {
      headerEditor?.commands.setContent(sectionHeader);
    }
  }, [sectionHeader]);

  useEffect(() => {
    //Check any of the blockIDs of this section is currently in the editing table
    if (editorType === 'MEETING_NOTE') {
      const _ = blocks.map((block) => {
        if (Object.keys(editingTable).includes(block.blockId)) setShowDeleteOption(false);
        else setShowDeleteOption(true);
      });
    } else {
      const _ = blocks.map((block) => {
        if (Object.keys(editingTable).includes(block.sectionId)) setShowDeleteOption(false);
        else setShowDeleteOption(true);
      });
    }
  }, [editingTable]);

  return (
    <HeaderStyles>
      {editable && showDeleteOption && (
        <ToggleButton>
          <ToggleSettings style={{ top: '40px', left: '35px' }}>
            <OptionItem onClick={toggleModal}>Delete Section</OptionItem>
          </ToggleSettings>
        </ToggleButton>
      )}
      <EditorContent editor={headerEditor}></EditorContent>

      <EditorDeleteContentModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        deleteType="section"
        confirmHandler={deleteSection}
      />
    </HeaderStyles>
  );
};

export default SectionHeader;
