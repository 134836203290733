import { gql } from '@apollo/client';

export const MEETING_NOTE_TEMPLATE_QUERY = gql`
  query MeetingNoteTemplateQuery($type: String, $isGlobal: Boolean) @api(name: "workspace") {
    list_TemplateItems(filter: { type: { eq: $type }, isGlobal: { eq: $isGlobal } }) {
      _TemplateItems {
        _id
        createdAt
        createdBy
        isGlobal
        metaData
        organizationId
        organizationId_type
        otherFields
        recordStatus
        templateName
        type
        updateBy
        updatedAt
      }
    }
  }
`;

export const LIST_MEETING_NOTE_TEMPLATE_QUERY = gql`
  query MeetingNoteTemplateQuery($organizationId_type: String) @api(name: "workspace") {
    list_TemplateItems(filter: { organizationId_type: { eq: $organizationId_type } }, readMode: NODE_COMMITTED) {
      _TemplateItems {
        _id
        isGlobal
        recordStatus
        templateName
        type
        organizationId_type
      }
    }
  }
`;

export const GET_MEETING_NOTE_TEMPLATE_QUERY = gql`
  query getMeetingNoteTemplateQuery($id: ID!) @api(name: "workspace") {
    get_Template(id: $id) {
      _id
      metaData
      otherFields
      templateName
      type
      createdBy
    }
  }
`;
