/* eslint-disable no-useless-escape */
import React from 'react';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

const useForceResetPassword = () => {
  const initialValues = {
    password: '',
    confirm_password: ''
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('New Password is Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm New Password is Required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  let navigate = useNavigate();
  const location = useLocation();

  const forgotPasswordSubmit = async (values) => {
    const { username, password: oldPassword } = location?.state;

    const password = values.password.trim();
    try {
      setLoading(true);
      // await Auth.forgotPasswordSubmit(username, code, password);
      const userDetails = await Auth.signIn(username, oldPassword);
      await Auth.completeNewPassword(
        userDetails,
        password
      )
      toast.success('Password has been changed.');
      navigate('/');
      // navigate('/dashboard');
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.error('Invalid Reset code.');
    }
  };
  const goToHomePage = () => {
    // navigate('/dashboard');
    navigate('/');
  };
  return {
    loading,
    validationSchema,
    forgotPasswordSubmit,
    initialValues,
    location,
    goToHomePage
  };
};

export default useForceResetPassword;
