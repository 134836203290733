import * as yup from "yup";

export const SendResponsesValidationSchema = yup.object().shape({
  documentStatus: yup.string().required("Please select a response option"),
  file: yup.mixed().required("Please upload a file"),
  comment: yup
    .string()
    .min(2, "Comment is bit too short!")
    .required("Comment is required"),
  dueDate: yup
  .string()
  .required("Due Date is required")
});
