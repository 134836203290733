import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import Select from 'react-select';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { createNoteMutation } from '../../../graphql/operations/Mutations/Library/NoteMutations';
import { CREATE_PROJECT_NOTE_MUTATION } from '../../../graphql/operations/Mutations/ProjectNotes/ProjectNoteMutations';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { generateQuery } from '../../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import { ModalInput, ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import { getWorkspaces } from '../MeetingNotes/AddNewNoteModal';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { ProjectDropdownStyles } from '../../styles/ProjectDropdownStyles';

const InputStyles = styled.div`
  display: flex;
  width: 100%;

  .item {
    border: 1px solid #acadac;
    border-radius: 5px;
    margin: 5px 10px;
    padding: 15px 10px;
  }

  .active {
    border: 1px solid #00b292;
  }

  input[name='note-type'] {
    accent-color: rgb(18, 124, 103);
    margin-right: 10px;
    margin-bottom: 20px;
  }
`;

const FormControl = styled.div`
  display: flex;
  /* width: 100%; */
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 20px;

  .select {
    width: 100%;
    margin-left: 20px;
  }

  .projectSelect {
    width: 100%;
    margin-left: 50px;
  }
`;

const AddNewProjectNote = ({ isOpen, toggleModal }: { isOpen: boolean; toggleModal: () => void }) => {
  const { projectId } = useParams();

  const [selectedNoteType, setSelectedNoteType] = useState('personal');
  const [noteName, setNoteName] = useState('');

  const { state } = useContext(AppContext);

  const navigate = useNavigate();

  const selectedProjectId = useRef<string | null>(null);
  const selectedWorkspaceId = useRef<string | null>(null);

  /**
   * Ref to workspace select
   * Used to clear selected workspace option if user changes the Project
   */
  const selectInputRef = useRef<any>();

  const [workspaceIds, setWorkspaceIds] = useState<string[]>([]);
  const batchGetWorkspaces = generateQuery(workspaceIds);
  const [getAllWorkspaces, { data: WorkspaceData }] = useLazyQuery(batchGetWorkspaces, {
    fetchPolicy: 'network-only'
  });

  const [createNote] = useMutation(CREATE_PROJECT_NOTE_MUTATION, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (workspaceIds.length > 0) {
      getAllWorkspaces();
    }
  }, [workspaceIds]);

  const {
    data: projectData,
    error: projectsError,
    loading: projectsLoading
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    },
    fetchPolicy: 'network-only'
  });
  const [addNote, { error }] = useMutation(createNoteMutation, {
    // refetchQueries: [{ query: 'LIST_NOTES_QUERY' }]
  });

  const projects = projectData?.list_ProjectItems._ProjectItems.map((data: any) => {
    return { ...data, label: data.name };
  });

  const workspaces = WorkspaceData
    ? Object.values(WorkspaceData).map((data: any) => {
        return { ...data, label: data.name };
      })
    : [];

  const initWorkspaceSelect = async (projectId: string) => {
    const id = toast.loading('Loading workspaces, please wait');
    const workspaceIds = await getWorkspaces(projectId, state.userId);
    toast.dismiss(id);

    if (workspaceIds.length) {
      setWorkspaceIds(workspaceIds);
    } else {
      toast.error('You are not part of any workspaces in this project');
      setWorkspaceIds([]);
    }
  };

  const onProjectChange = (options: any) => {
    if (!options) return;
    selectedProjectId.current = options._id;
    initWorkspaceSelect(options._id);
    selectInputRef.current.select.clearValue();
  };

  const onWorkspaceChange = (options: any) => {
    if (!options) return;
    selectedWorkspaceId.current = options._id;
  };

  const handleOptionChange = (changeEvent: ChangeEvent<HTMLInputElement>) => {
    setSelectedNoteType(changeEvent.target.value);
  };

  const handleCreateNote = async () => {
    const id = toast.loading('Creating note');
    if (!noteName) return toast.error('Please enter the note name');
    if (selectedNoteType === 'shared' && (selectedProjectId.current === null || selectedWorkspaceId.current === null))
      return toast.error('Select project and workspace for the new note', { id });

    if (selectedNoteType === 'shared') {
      try {
        const response = await createNote({
          variables: {
            content: '',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            createdBy: state.userId,
            updateBy: state.userId,
            noteType: 'workspace',
            typeId: selectedWorkspaceId.current,
            noteType_typeId: `workspace_${selectedWorkspaceId.current}`,
            organizationId: state.orgId,
            organizationId_projectId_workspaceId: `${state.orgId}_${selectedProjectId.current}_${selectedWorkspaceId.current}`,
            projectId: selectedProjectId.current,
            recordStatus: 0,
            workspaceId: selectedWorkspaceId.current,
            title: noteName,
            isDraft: true
          }
        });
        const noteId = response.data.add_NoteAndDisscussion.result._id;

        //Create discussion for this note
        const data = await axiosInstance.post('/discussion', {
          entityId: noteId,
          entityType: 'WORKSPACE_NOTE', //Project_Note === Workspace_Note
          orgId: state.orgId,
          workspaceId: selectedWorkspaceId.current
        });

        navigate(`/project/workspace/${selectedProjectId.current}/${selectedWorkspaceId.current}/notebooks/${noteId}`);
        toast.success('Note created successfully', { id });
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong !', { id });
      }
    } else {
      try {
        let Response = await addNote({
          variables: {
            libraryType: 'notesAndCollections',
            title: noteName,
            content: '',
            createdAt: new Date().toISOString(),
            createdBy: state?.userId,
            noteType: 'user',
            typeId: state?.userId,
            noteType_typeId: `user_${state?.userId}`,
            projectId: 'NOPROJECT',
            updateBy: state?.userId,
            updatedAt: new Date().toISOString()
          }
        });
        if (Response) {
          toast.success('Note created successfully', { id });
          navigate(`/notes/${Response?.data?.add_NoteAndDisscussion.result._id}`);
          setTimeout(() => {
            toggleModal();
          }, 1000);
        }
      } catch (e) {
        toast.error('oh no something went wrong', { id });
        console.error(e);
      }
    }
  };

  const getProjectDefault = () => {
    if (projectId) {
      const found = projects.findIndex((p: any) => p._id === projectId);
      return projects[found];
    }
    return '';
  };

  if (projectsLoading) {
    return null; //No need of showing error screen as this is a modal
  }

  if (projectsError) {
    console.error(projectsError);
    toast.error('Something went wrong');
    return null;
  }
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="728px">
        <ModalTitle>New Project Note</ModalTitle>
        <ModalBody>
          <div style={{ borderBottom: '1px solid #EEEFEE', padding: '15px' }}>
            <ModalText>Enter note name</ModalText>
            <ModalInput value={noteName} onChange={(e) => setNoteName(e.target.value)} />
          </div>
          {/* <hr /> */}
          <ModalText>Note Type</ModalText>
          <InputStyles>
            <div className={`item ${selectedNoteType === 'personal' && 'active'}`} style={{ width: '30%' }}>
              <label>
                <input
                  type="radio"
                  name="note-type"
                  value="personal"
                  checked={selectedNoteType === 'personal'}
                  onChange={handleOptionChange}
                  className="note-type"
                />
                Personal Note
              </label>
            </div>
            <div className={`item ${selectedNoteType === 'shared' && 'active'}`} style={{ width: '70%' }}>
              <label>
                <input
                  type="radio"
                  name="note-type"
                  value="shared"
                  checked={selectedNoteType === 'shared'}
                  onChange={handleOptionChange}
                  className="note-type"
                />
                Shared Note
              </label>
              <FormControl>
                Project
                <Select
                  className="projectSelect"
                  options={[...projects]}
                  components={{ IndicatorSeparator: () => null }}
                  styles={ProjectDropdownStyles}
                  onChange={onProjectChange}
                  isDisabled={selectedNoteType !== 'shared'}
                  defaultValue={getProjectDefault()}
                />
              </FormControl>
              <FormControl>
                Workspace
                <Select
                  ref={selectInputRef}
                  className="select"
                  components={{ IndicatorSeparator: () => null }}
                  options={workspaces}
                  styles={ProjectDropdownStyles}
                  isDisabled={workspaceIds.length === 0 || state.orgId === 'null'}
                  onChange={onWorkspaceChange}
                />
              </FormControl>
            </div>
          </InputStyles>
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={false} onClick={toggleModal}>
            Cancel
          </Button>
          <Button disabled={noteName.trim().length === 0} onClick={handleCreateNote}>
            Confirm
          </Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default AddNewProjectNote;
