import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { sentryError } from '../../../lib/SentryError';

function useGetLatestCard() {
  const [latestCard, setLatestCard] = useState();
  const [cardLoading, setCardLoading] = useState(false);
  const [cardError, setCardError] = useState(false);
  const { state } = useContext(AppContext);

  useEffect(() => {
    const getLatestCardDetails = async () => {
      setCardLoading(true);
      axiosInstance
        .get(`/payment/card-details?organizationId=${state.orgId}&email=${encodeURIComponent(state.email)}`)
        .then((res) => {
          console.log('RESPONSE OF CARD DETAILS', res?.data?.paymentMethods[0]);
          if (res.data.paymentMethods.length && res.data.paymentMethods.length > 0) {
            setLatestCard(res.data.paymentMethods[0]);
          }
        })
        .catch((error) => {
          sentryError(error);
          setCardError(true);
        })
        .finally(() => {
          setCardLoading(false);
        });
    };

    getLatestCardDetails();
  }, []);

  return {
    latestCard,
    cardError,
    cardLoading
  };
}

export default useGetLatestCard;
