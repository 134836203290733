import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import Button, { CustomButton } from '../../components/CustomButton';
import FileTag from '../../components/FileTag';
import toast from 'react-hot-toast';
import OrganizationValidationSchema from '../../components/Validations/CreateOrganizationValidation';
import { Header, HeaderLeft, NewTitleLeft, TitleRight } from '../../components/styles/Header';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { AppContext } from '../../AppContext';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { Label } from '../../components/styles/Label';
import { FieldSet, InputWrapper, FileUploadWrapper, FilePreview } from '../../components/styles/FormStyles';
import { TaskDetails } from '../Tasks/TaskViews/CreateTask';
import { getFileType } from '../../lib/getFileType';
import { InputField } from '../../components/Reusable/NewFormik/Input';
import { GreyButton } from '../../components/styles/ButtonStyles';
import { GET_ORGANIZATION } from '../../graphql/operations/Queries/Organization/OrganizationQueries';
import _ from 'lodash';
import Compress from 'browser-image-compression';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../constants/LoaderDefaultOptions';
import { updateOrganizationMutation } from '../../graphql/operations/Mutations/Organization/OrganizationMutations';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle } from '../../components/styles/ModalStyles';
import { ModalText } from '../Workspaces/Registers/CreateNewRegister';
import { ButtonWrapper } from '../Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';

import CancelIcon from '../../assets/SvgComponents/CancelIcon';
// import CustomAvatar from "../../components/TableComponents/CustomAvatar";
// import TextError from "../../components/Reusable/NewFormik/TextError";

const Form = styled.form``;

const PageTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600 !important;
  margin: 0px;

  img {
    height: 2.5rem;
    width: 3rem;
    margin-right: 1rem;
  }
`;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #dbe2e0;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
  }
`;
export const ImgPreview = styled.div`
  .preview {
    width: 250px;
    position: relative;
    height: 200px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
`;

interface InitialValues {
  organizationName: any;
  file: any;
  createdBy: string;
}

function EditOrganization() {
  const [selectedImage, setSelectedImage] = useState();
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [active, setActive] = useState(false);
  const userId = state.userId;

  const { data: getOrganization } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: state?.orgId
    },
    fetchPolicy: 'network-only'
  });
  const [updateOrganization, loading] = useMutation(updateOrganizationMutation);

  const viewOrg = () => {
    navigate(`/organization/${state.orgId}`);
  };

  const initialValues: InitialValues = {
    file: '',
    organizationName: getOrganization?.get_Organization?.name ? getOrganization?.get_Organization?.name : '',
    createdBy: state.userName
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSubmit = async (values: InitialValues) => {
    const toastId = toast.loading('Updating...');
    let id = uuidv4();
    try {
      if (values.file === '') {
        await updateOrganization({
          variables: {
            name: values?.organizationName,
            updatedAt: new Date().toISOString(),
            updatedBy: userId,
            id: state.orgId,
            notificationData: {
              projectId: 'NOPROJECT',
              organizationId: state.orgId,
              actionType: 'edit',
              entityId: state.orgId
            }
          }
        });
        toast.success('ORGANIZATION UPDATED SUCCESSFULLY', {
          id: toastId
        });
        viewOrg();
        // window.location.href = `/organization/${state.orgId}`;
      } else {
        const url = getOrganization?.get_Organization?.photo ? getOrganization?.get_Organization?.photo.split('/') : '';
        const fileId = url !== '' ? url[url?.length - 1] : 'organization_' + id;
        await Storage.put(fileId, values.file, {
          contentType: values.file.type,
          bucket: process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME,
          level: 'public'
        }).then(async (data) => {
          if (data.key) {
            toast.success('ORGANIZATION UPDATED SUCCESSFULLY', {
              id: toastId
            });
          }
          url === ''
            ? await updateOrganization({
                variables: {
                  name: values?.organizationName,
                  fileType: values?.file.type,
                  updatedAt: new Date().toISOString(),
                  photo: `https://${process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME}.s3.${
                    process.env.REACT_APP_S3_REGION
                  }.amazonaws.com/public/${'organization_' + id}`,
                  updatedBy: userId,
                  id: state.orgId,
                  subscriptionType: 'FREE',
                  notificationData: {
                    projectId: 'NOPROJECT',
                    organizationId: state.orgId,
                    actionType: 'edit',
                    entityId: state.orgId
                  }
                }
              })
            : await updateOrganization({
                variables: {
                  name: values?.organizationName,
                  fileType: values?.file.type,
                  updatedAt: new Date().toISOString(),
                  updatedBy: userId,
                  id: state.orgId,
                  subscriptionType: 'FREE',
                  notificationData: {
                    projectId: 'NOPROJECT',
                    organizationId: state.orgId,
                    actionType: 'edit',
                    entityId: state.orgId
                  }
                }
              });
        });
        viewOrg();
        // window.location.href = `/organization/${state.orgId}`;
      }
    } catch (err) {
      console.error('ERROR IN UPLOADING', err);
    }
  };
  return (
    <>
      {_.isEmpty(getOrganization) ? (
        <Lottie options={defaultOptions} width={500} height={500} />
      ) : (
        <Formik initialValues={initialValues} validationSchema={OrganizationValidationSchema} onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue, handleChange, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <>
                <Header>
                  <HeaderLeft>
                    <CancelIcon />
                    <NewTitleLeft>
                      <PageTitle style={{ display: 'flex', alignItems: 'center' }}>
                        {getOrganization?.get_Organization?.photo ? (
                          <img
                            src={
                              getOrganization?.get_Organization?.photo
                                ? `${getOrganization?.get_Organization?.photo}?${new Date().getTime()}`
                                : ''
                            }
                            alt="Organization"
                          />
                        ) : null}
                        Edit Organization
                      </PageTitle>
                    </NewTitleLeft>
                  </HeaderLeft>
                  <TitleRight>
                    <Button type="submit">Update</Button>
                  </TitleRight>
                </Header>
                <TaskDetails>
                  <FieldSet>
                    <div>
                      <InputWrapper style={{ width: '40rem' }}>
                        <Label> Organization Name </Label>
                        <InputField name="organizationName" type="text" disabled={loading.loading} />
                      </InputWrapper>
                      <FileUploadWrapper>
                        {values.file ? (
                          <FilePreview>
                            <FileTag>{getFileType(values.file.name)?.toUpperCase()}</FileTag>
                            <span className="file">{values.file.name}</span>
                            <ImgPreview>
                              <img className="preview" src={URL.createObjectURL(selectedImage)} alt="Thumb" />
                            </ImgPreview>
                            <GreyButton type="button" onClick={() => setFieldValue('file', '')}>
                              Remove Photo <CloseIcon />
                            </GreyButton>
                          </FilePreview>
                        ) : (
                          <>
                            <GreyButton type="button" onClick={handleFile}>
                              {' '}
                              Change Photo
                            </GreyButton>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              style={{ display: 'none' }}
                              accept="image/*"
                              ref={hiddenFileInput}
                              onChange={(event) => {
                                const image = event.currentTarget.files[0];
                                if (image?.size < 6000000) {
                                  const toastId = image && toast.loading('Uploading Image');
                                  const options = {
                                    // As the key specify the maximum size
                                    // Leave blank for infinity
                                    maxWidthOrHeight: 2000,
                                    maxSizeMB: 0.2,
                                    initialQuality: 0.6,
                                    // Use webworker for faster compression with
                                    // the help of threads
                                    useWebWorker: true
                                  };
                                  setSelectedImage(image);
                                  // Initialize compression
                                  // First argument is the file object from the input
                                  // Second argument is the options object with the config
                                  Compress(image, options).then((compressedBlob) => {
                                    toast.success('Uploaded', {
                                      id: toastId
                                    });
                                    compressedBlob.lastModifiedDate = new Date();

                                    // Conver the blob to file
                                    const convertedBlobFile = new File([compressedBlob], image.name, {
                                      type: image.type,
                                      lastModified: Date.now()
                                    });
                                    setFieldValue('file', convertedBlobFile);
                                  });
                                } else {
                                  image && toast.error('Image size is too large');
                                }
                              }}
                              disabled={loading.loading}
                            />
                            {touched['file'] && errors['file'] ? (
                              <p style={{ color: 'red' }}>{errors['file']}</p>
                            ) : null}
                          </>
                        )}
                      </FileUploadWrapper>
                    </div>
                  </FieldSet>
                </TaskDetails>
              </>
            </Form>
          )}
        </Formik>
      )}
      {/* <Toaster
        toastOptions={{
          success: {
            duration: 5000,
          },
        }}
      /> */}
      <Modal open={active} onClose={() => setActive(false)}>
        <ModalContainer width="450px">
          <ModalTitle>Add members to organization</ModalTitle>
          <ModalBody>
            <ModalText>
              Do you want to delete this organization? You will lose your access to this collaborative space. This
              action is not reversible.
            </ModalText>
            <ButtonWrapper>
              <Button secondary>Delete Organization</Button>
              <Button onClick={() => setActive(false)}>Cancel</Button>
            </ButtonWrapper>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default EditOrganization;
