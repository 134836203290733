import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { getProjectQuery } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { getRegisterQuery } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import { getWorkspaceQuery } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import toast from 'react-hot-toast';
import { RemoveRegisterNoteMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';
import { useState } from 'react';
// import { useContext, useState } from 'react';
// import { AppContext } from '../AppContext';
function useGetRegisterSummary() {
  let navigate = useNavigate();
  let [registerDescription, setRegisterDescription] = useState('');
  let { projectId, workspaceId, registerId } = useParams();
  // const { state } = useContext(AppContext);
  const {
    loading,
    error,
    data: projectData
  } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    },
    onCompleted: (data) => {}
  });
  const {
    data: workspaceData,
    error: workspaceError,
    loading: workspaceLoading
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    }
  });
  const {
    data: registerData,
    error: registerError,
    loading: registerLoading
  } = useQuery(getRegisterQuery, {
    variables: {
      id: registerId
    },
    onCompleted(data) {
      let description = data?.get_Register?.registerDescription;
      setRegisterDescription(description);
    }
  });

  const [RemoveRegisterNote] = useMutation(RemoveRegisterNoteMutation);

  const createRegisterNote = () => {
    navigate(`../note`);
  };

  const editRegisterNote = (description: string) => {
    navigate(`../note`, {
      state: {
        description: description
      }
    });
  };

  const removeRegisterNote = async () => {
    let toastId = toast.loading('Deleting Note.');
    try {
      let result = await RemoveRegisterNote({
        variables: {
          id: registerId
        }
      });
      if (result) {
        toast.dismiss(toastId);
        toast.success('Note Deleted.');
        setRegisterDescription('');
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('Oh no something went wrong');
    }
  };

  return {
    loading,
    workspaceLoading,
    registerLoading,
    error,
    workspaceError,
    registerError,
    projectData,
    workspaceData,
    registerData,
    createRegisterNote,
    editRegisterNote,
    removeRegisterNote,
    registerDescription
  };
}

export default useGetRegisterSummary;
