import { useContext } from 'react';
import { useSidebar } from '../lib/SidebarState.js';
import styled from '@emotion/styled';
import NavItem from './NavItem';
import { DropdownMenu } from './DropdownMenu';
import { ReactComponent as SidebarToggle } from '../assets/icons/SidebarToggle.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg';
import { ReactComponent as ProjectIcon } from '../assets/icons/projects.svg';
import { ReactComponent as LibraryIcon } from '../assets/icons/library.svg';
import { ReactComponent as MessagesIcon } from '../assets/icons/messages.svg';
import { ReactComponent as SidebarTaskIcon } from '../assets/icons/SidebarTaskIcon.svg';
import { ReactComponent as SidebarExpanded } from '../assets/icons/SidebarExpanded.svg';
import { ReactComponent as MeetingNotes } from '../assets/icons/IconMeetinNotes.svg';
import Logo from '../assets/images/Platform Suite_Logo_White_LG.png';
import { ReactComponent as Caret } from '../assets/icons/dropDown.svg';
import { AppContext } from '../AppContext';
import { PinnedProjectsContext } from '../PinnedProjectContext';
import { useNavigate } from 'react-router';
// import { useEffect } from "react";
// import axiosInstance from "../axiosInstance";
// import { ReactComponent as PlusIcon } from "../assets/icons/plusIcon.svg";
// import { ReactComponent as ExpenseRegisterIcon } from "../assets/icons/expenses.svg";

// const projectData = [
//   { id: 1, title: "Project1" },
//   { id: 2, title: "Project2" },
//   { id: 3, title: "Project3" },
// ];
const libraryData = [
  { id: 1, title: 'Files' },
  { id: 2, title: 'Notes' },
  { id: 3, title: 'Trash' }
];

type SidebarProps = {
  open: boolean;
};

const LogoImage = styled.img`
  /* transform: translate(0, -25%); */
  width: 137px;
  height: 40px;
  margin-left: 10px;
  margin-top: 5px;
  cursor: pointer;
  /* padding: 5px 10px; */
`;

const LogoContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

const SidebarStyles = styled.nav<SidebarProps>`
  width: ${(props) => (props.open ? '250px' : '45px')};
  position: fixed;
  /* border-right: 3px solid var(--border-color); */
  transition: width 500ms ease;
  overflow-x: hidden;
  background: #26303e;
  position: fixed;
  z-index: 9;
  .sidebar-toggle {
    outline: none;
    border: none;
    padding: ${(props) => (props.open ? '0px 0px' : '10px 20px')};
    background-color: transparent;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
      background-color: ${(props) => (props.open ? '#2F3F48' : '#2F3F48')};
      border-radius: 50%;
      .svg-white {
        fill: #cdcaca !important;
      }
    }
  }
  .crtlogo {
    animation: cssAnimation 0s 500ms forwards;
    visibility: hidden;
    display: inline-block;
  }
  /* @keyframes cssAnimation {
    to {
      visibility: visible;
    }
  } */
  .sidebar-nav {
    list-style: none;
    padding: 0;
    margin: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100vh;
    margin-top: 10px;
  }
  .nav-link {
    display: flex;
    align-items: center;
    height: 3.125rem;
    text-decoration: none;
    /* color: var(--text-primary); */
    /* color: #CDCACA !important; */
    color: hsla(0, 3%, 80%, 1);
    filter: grayscale(100%) opacity(0.7);
    transition: var(--transition-speed);
    &:hover {
      filter: grayscale(0%) opacity(1);
      background: #2f3f48;
      color: #ffca72;
      margin: unset;
    }
  }
  .nav-link svg {
    width: 2rem;
    min-width: 2rem;
    margin: 0 0.5rem;
  }
  .link-text {
    display: inline;
    white-space: nowrap;
  }
  .nav-item {
    width: 100%;
    .active {
      filter: grayscale(0%) opacity(1);
      background: #2f3f48;
      color: #ffca72;
      margin: unset;
      font-weight: 600;
    }
  }
  .svg-primary {
    fill: var(--text-secondary) !important;
    fill: #ffca72 !important;
  }
  .svg-secondary {
    stroke: #ffca72 !important;
  }
  .svg-primary {
    transition: var(--transition-speed);
  }
`;

// type PinnedProjects = {
//   id: string;
//   name: string;
// };

function Sidebar(props: any) {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { sidebarOpen, toggleSidebar } = useSidebar();
  const { state } = useContext(AppContext);
  const { state: pinnedProjectState } = useContext(PinnedProjectsContext);
  // const [pinnedProjects, setPinnedProjects] = useState<PinnedProjects[]>([]);

  const goToDashboard = () => {
    navigate(`/`);
  };

  // useEffect(() => {
  //   axiosInstance.get(`/users/${state.userId}`)
  //     .then((res) => {
  //       setPinnedProjects(res.data.data[0].pinnedProjects)
  //     })
  // }, [])
  // const permission: string[] = state.permission;
  const userId = state.userId;
  const handleOnClick = (id: any) => {
    fetch(`${process.env.REACT_APP_USER_ROLE_SETTING_URL}?userId=${userId}&projectId=${id}`, {
      method: 'get'
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: 'CURRENT_USER',
          data: res
        });
      });
  };
  return (
    <SidebarStyles open={sidebarOpen}>
      <LogoContainer>
        <button onClick={toggleSidebar} type="button" className="sidebar-toggle">
          {!sidebarOpen && <SidebarToggle />}
          {sidebarOpen && (
            <>
              <SidebarExpanded />
            </>
          )}
        </button>
        {sidebarOpen && <LogoImage onClick={goToDashboard} src={Logo} alt="logo" />}
      </LogoContainer>
      <ul className="sidebar-nav">
        <NavItem icon={<DashboardIcon />} route={`/`} name="My Dashboard"></NavItem>
        <NavItem icon={<SidebarTaskIcon />} route="tasks" name="Tasks"></NavItem>
        <NavItem
          icon={<ProjectIcon />}
          // add={<PlusIcon />}
          drop={pinnedProjectState.length > 0 ? <Caret /> : null}
          route="projects"
          name="Projects"
        >
          <DropdownMenu
            getId={(id: string) => handleOnClick(id)}
            type="projects"
            data={pinnedProjectState}
          ></DropdownMenu>
        </NavItem>
        <NavItem icon={<LibraryIcon />} drop={<Caret />} route="files" name="Library">
          <DropdownMenu type="files" data={libraryData}></DropdownMenu>
        </NavItem>
        <NavItem icon={<MessagesIcon />} route="messages" name="Messages">
          {props.children}
        </NavItem>
        <NavItem icon={<MeetingNotes />} route="meetingnotes" name="Meeting Notes">
          {props.children}
        </NavItem>
      </ul>
    </SidebarStyles>
  );
}

export default Sidebar;
