import * as Yup from 'yup';
import toast from 'react-hot-toast';
import jwt from 'jsonwebtoken';
import { Auth } from 'aws-amplify';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';

const useConfirmSignUp = () => {
  const location = useLocation();
  const { email, decoded, password, nickname, username } = location.state ? location.state : {};
  const initialValues = {
    email: email,
    code: ''
  };
  const validationSchema = Yup.object({
    code: Yup.string().required('User verification code is required.'),
    email: Yup.string().email('Email is required.')
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [alreadyConfirmed, setAlreadyConfirmed] = React.useState<boolean>(false);
  let navigate = useNavigate();
  const { dispatch } = useContext(AppContext);

  const querry = new URLSearchParams(location.search);
  const token = querry.get('token');
  useEffect(() => {
    if (token) {
      //if there is token param then automatically verify and navigate to login
      const decoded = jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY);
      const code = querry.get('code');
      const confirmAndJoinOrg = async () => {
        const toastId = toast.loading('Verifying..');
        await Auth.confirmSignUp(decoded.userName, code)
          .then(() => {
            toast.success('Verified, Please Login', { id: toastId });
            decoded.invitationParams ? navigate(`/?token=${decoded.invitationParams}`) : navigate('/');
          })
          .catch((err) => {
            console.error('err', err);
            toast.remove();
            if (err.code === 'NotAuthorizedException') {
              setAlreadyConfirmed(true);
            }
          });
      };
      confirmAndJoinOrg();
    }
  }, [token]);
  const goToHomePage = () => {
    navigate('/');
  };
  const confirmSignUp = async (values) => {
    const code = values.code;
    setLoading(true);
    try {
      const toastId = toast.loading('Verifying..');
      await Auth.confirmSignUp(username, code)
        .then((data) => {
          Auth.signIn(username, password)
            .then((userInfo) => {
              if (decoded) {
                decoded.type = 'addMember';
                decoded.userId = userInfo.attributes.sub;
              }
              decoded
                ? axiosInstance.post('/addMemberToOrganization', decoded).then(async (response) => {
                    if (response.data.statusCode === 200) {
                      dispatch({
                        type: 'UPDATE_USER',
                        data: {
                          orgId: decoded?.entityId
                        }
                      });
                      await axiosInstance.post('/notification', {
                        entityType: 'ORGANIZATION',
                        entityId: decoded?.entityId,
                        actionType: 'new_member',
                        actionBy: '',
                        actionByName: nickname,
                        actionByProfilePic: '',
                        message: `<p><span class=blackBold>${nickname}</span> joined the organization</p>`,
                        workspaceId: '',
                        projectId: 'NOPROJECT',
                        organizationId: decoded?.entityId
                      });
                    }
                  })
                : console.log('');
            })
            .catch((err) => {
              console.error(err);
              setLoading(false);
              if (err.code === 'NotAuthorizedException') {
                toast.remove();
                toast.error('Invalid password please login again');
                navigate('/');
              }
            });
        })
        .then(() => {
          toast.success('Verified, Logging in', { id: toastId });
          setLoading(false);
          navigate('/', { state: { loggedIn: true } });
        });
    } catch (err) {
      setLoading(false);
      console.error(err);
      toast.remove();
      toast.error('Invalid verification code');
    }
  };

  return {
    loading,
    validationSchema,
    confirmSignUp,
    alreadyConfirmed,
    token,
    initialValues,
    location,
    goToHomePage
  };
};

export default useConfirmSignUp;
