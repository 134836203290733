import React from 'react';
import { NavLink } from 'react-router-dom';

type LinkProps = {
  to: string;
  end?: boolean;
  children: React.ReactNode;
};

function NavigationLink({ to, end, children }: LinkProps) {
  return end ? (
    <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} end to={to}>
      {children}
    </NavLink>
  ) : (
    <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to={to}>
      {children}
    </NavLink>
  );
}

export default NavigationLink;
