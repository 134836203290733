import React from 'react';
import { BoxStyles } from './MeetingNoteTemplateTile';
import { TemplateName } from './MeetingNoteTemplateTile';
import { ReactComponent as AddNewIcon } from '../../assets/icons/CreateNewPlusIcon.svg';
import styled from '@emotion/styled';

const AlignmentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #a8a8a8;
    margin-top: 5px;
  }
  svg {
    color: #a8a8a8 !important;
    display: block;
  }

  padding-bottom: 20px;
`;

type NewTileProps = {
  selected: string;
  handleSelect: (id: string) => void;
  template: { templateName: string; isGlobal: boolean; _id: string };
};

function CreateNewTemplateTile(props: NewTileProps) {
  return (
    <BoxStyles
      isSelected={props.template._id === props.selected}
      onClick={() => {
        props.handleSelect(props.template._id);
      }}
      style={{ paddingTop: '45px', paddingBottom: '20px' }}
    >
      <AlignmentDiv>
        <AddNewIcon />
        <TemplateName>Create New Template</TemplateName>
      </AlignmentDiv>
    </BoxStyles>
  );
}

export default CreateNewTemplateTile;
