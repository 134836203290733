import styled from '@emotion/styled';
// import { css, jsx } from "@emotion/react"

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 5rem;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.4rem;
  svg {
    cursor: pointer;
  }
`;
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  right: -18rem;
  svg {
    cursor: pointer;
  }
`;
export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4.6rem;
`;
export const NewTitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.85rem;
`;

export const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

export const PageTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600 !important;
  margin: 0px;
`;
