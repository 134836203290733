import { gql } from '@apollo/client';

export const addResponseFile = gql`
  mutation MyMutation($DestinationKey: String!, $SourceBucket: String!, $SourceKey: String!, $SourceRegion: String!)
  @api(name: "library") {
    addVendia_File(
      syncMode: NODE_COMMITTED
      input: {
        sourceBucket: $sourceBucket
        sourceKey: $sourceKey
        sourceRegion: $sourceRegion
        destinationKey: $destinationKey
      }
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const addResponseMutation = gql`
  mutation addResponseMutation($id: ID!, $status: String, $responses: [Self_File_responses_responsesItem_UpdateInput_])
  @api(name: "library") {
    update_File(id: $id, syncMode: NODE_COMMITTED, input: { status: $status, responses: $responses }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
