import styled from '@emotion/styled'
import React from 'react'

const RadioButtonStyles = styled.div`

--md-radio-checked-color: #00B292;
--md-radio-border-color: #93979F;
--md-radio-size: 20px;
--md-radio-checked-size: 10px; 
--md-radio-ripple-size: 15px;

    @keyframes ripple {
    0% {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }

    50% { 
      box-shadow: 0px 0px 0px var(--md-radio-ripple-size) rgba(0, 0, 0, 0.1);
    }

    100% {
      box-shadow: 0px 0px 0px var(--md-radio-ripple-size) rgba(0, 0, 0, 0);
    }
  }
  margin: 16px 0;

    input[type="radio"] {
        display: none;

        &:checked + label:before {
            border-color: var(--md-radio-checked-color);
            animation: ripple 0.2s linear forwards;   
        }

        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        height:var(--md-radio-size);
        position: relative;
        padding: 0 var(--md-radio-size) + 15px;
        padding-left: 35px;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;

        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: var(--md-radio-size);
            height: var(--md-radio-size);
            border: 2px solid var(--md-radio-border-color);
        }

        &:after {
            /* top: ((var(--md-radio-size) / 2) - (var(--md-radio-checked-size) / 2));
            left: ((var(--md-radio-size )/ 2) - (var(--md-radio-checked-size) / 2)); */
            top: 5px;
            left: 5px;
            width:var(--md-radio-checked-size);
            height:var(--md-radio-checked-size);
            transform: scale(0);
            background:var(--md-radio-checked-color);
        }
    }  


`

const RadioButton = (props) => {
  return (
    <RadioButtonStyles>
      <input id={props.id} onChange={props.onChange} name={props.name} value={props.value} type="radio" checked={props.isSelected} />
      <label htmlFor={props.id}>{props.label}</label>
    </RadioButtonStyles>
  )
}

export default RadioButton
