export const taskDropdownStyles = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    minWidth: "120px"
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (styles, { data, isFocused }) => ({
    ...styles,
    color: isFocused ? "white" : "#1C1C1C",
    background: isFocused ? "#2F3F48" : "white",
    height: "30px",
    // borderRadius: "5px"
  }),
  menu: (styles, { data }) => ({
    ...styles,
    position: "absolute",
    border: "1px solid #ACADAC",
    borderRadius: "5px",
    top: "-10px",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: "0px",
    borderRadius: "5px",
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#929292",
    fontWeight: "400",
    overflow: "visible !important",
    // height: '1.2rem'
  }),
};
