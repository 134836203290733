import { ReactComponent as RegisterIcon } from '../assets/icons/RegisterIcon.svg';
// import FileTag from "../components/FileTag";
import capitalizeFirstLetter from '../lib/CapitaliseFirstLetter';

export const COLUMNS = [
  // {
  //   // Header: '',
  //   // accessor: '',
  //   Cell: ({ cell }) => <RegisterIcon />,
  // },
  {
    Header: 'Register Name',
    accessor: 'name',
    Cell: ({ cell: { value } }) => <span className="text-wrap divider">{value}</span>
  },
  {
    Header: 'Type',
    accessor: 'registerType',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value ? capitalizeFirstLetter(value) : ''}</div>
  },
  // {
  //   Header: "File Formats",
  //   accessor: "fileFormats",
  //   Cell: ({ cell: { value } }) => {
  //     return <FileTag>{value[0].toUpperCase()}</FileTag>;
  //   },
  // },
  {
    Header: 'Initiator',
    accessor: 'initiatorRole',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value ? value : ''}</div>
  },
  {
    Header: 'Responder',
    accessor: 'responderRole',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value ? value : ''}</div>
  }
];

export const ListOfRegisterColumns = [
  {
    Header: 'Register Name',
    accessor: 'name',
    Cell: ({ cell: { value } }) => (
      <span className="text-wrap divider">
        <RegisterIcon />
        {value}
      </span>
    )
  },
  {
    Header: 'Open Items',
    accessor: 'otherFields.openItems',
    Cell: ({ cell: { value } }) => <div className="count"> {value ? value : '0'} </div>
  },
  {
    Header: 'Unviewed Items',
    accessor: 'otherFields.unViewed',
    Cell: ({ cell: { value } }) => <div className="count"> {value ? value : '0'} </div>
  },
  {
    Header: 'Total Items',
    accessor: 'otherFields.totalItems',
    Cell: ({ cell: { value } }) => <div className="count">{value ? value : '0'}</div>
  }
];
