import {
  AddressBlock,
  BillingBox,
  BillingContent,
  BillingText,
  EmailBlock,
  EmailTag,
  EmailText
} from '../../pages/Payments/UpdatePlan';

function DisplayBillingInformation({ email }: { email: string }) {
  return (
    <>
      <BillingBox>
        <BillingText>Billing Information</BillingText>
        <BillingContent>
          <EmailBlock>
            <EmailTag>Email</EmailTag>
          </EmailBlock>
          <AddressBlock>
            <EmailText>{email}</EmailText>
          </AddressBlock>
        </BillingContent>
      </BillingBox>
    </>
  );
}

export default DisplayBillingInformation;
