import { gql } from '@apollo/client';

export const LIST_NOTES_QUERY = gql`
      query getNoteQuery($type_typeId: String, $recordStatus: Float) @api(name: "library") {
            list_NoteAndDisscussionItems(
            filter: { noteType_typeId: { eq: $type_typeId }, recordStatus: { eq: $recordStatus } }
            ) {
                  _NoteAndDisscussionItems {
                        content
                        createdAt
                        createdBy
                        _id
                        title
                  }
            }
      }
`;

export const SINGLE_NOTE_QUERY = gql`
      query GET_NOTE($id: ID!) @api(name: "library") {
            get_NoteAndDisscussion(id: $id) {
                  content
                  _id
                  createdBy
                  createdAt
                  isHighlighted
                  title
                  recordStatus
            }
      }
`;


export const LIST_TASKS_IN_NOTES_QUERY = gql`
      query ListTaskQuery($organizationId_projectId_typeId: String, $noteId: String) @api(name: "task") {
            list_TaskItems(
            filter: {
                  organizationId_projectId_typeId: { beginsWith: $organizationId_projectId_typeId }
                  recordStatus: { eq: 0 }
                  noteId: { eq: $noteId }
            }
            ) {
                  _TaskItems {
                        assignedTo
                        assignedToDetails {
                              name
                              profilePicture
                        }
                        assignee
                        assigneeDetails {
                              name
                              profilePicture
                        }
                        createdAt
                        description
                        dueDate
                        _id
                        noteId
                        isAcknowledged
                        priority
                        projectId
                        recordStatus
                        status
                        title
                        links
                        updatedAt
                        updatedBy
                        type
                        typeId
                  }
            }
      }
`