export const roles = {
  ORGANIZATION: {
    SUPER_ADMIN: 1,
    MEMBER: 2
  },
  PROJECT: {
    ADMIN: 1,
    MEMBER: 2
  },
  WROKSPACE: {
    ADMIN: 1,
    MEMBER: 2
  }
};
