import toast from 'react-hot-toast';
import axiosInstance from '../../../axiosInstance';
import CreateTaskValidationSchema from '../../../components/Validations/CreateTaskValidation';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import { useDispatch } from 'react-redux';
import { generateQuery } from '../../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { selectOptions, statusOptions } from '../../../constants/DropDownOptions';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { FormikHelpers, useFormik } from 'formik';
import { Success } from '../../../constants/ToastMessages';
import { postDashboardApi } from '../../../reducers/dashboard';
import {
  addAssociateTaskMutation,
  addTaskMutation,
  updateTaskAssociationMutation
} from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { LIST_WORKSPACE_QUERY } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { sentryError } from '../../../lib/SentryError';

type TaskValues = {
  title: string;
  projectId?: string;
  type?: string;
  workspaceId?: string;
  workspaceName: string;
  projectName: string;
  description: string;
  dueDate: string;
  isPersonal?: boolean;
  priority: { value: string; label: string; color: string };
  recordStatus: string;
  status: { value: string; label: string; color: string };
  links: [{ value: string; saved: boolean }] | null | Array<{ value: string; saved: boolean }>;
  assignedTo: string;
  assignedToDetails: { name: string; profilePicture: string; email: string };
  assignee: string;
  assigneeDetails: { name: string; profilePicture: string; email: string };
  updatedBy: string;
  isAssociated?: Boolean;
  parentAssociatedId?: string;
};

export const useCreateTask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const { state } = useContext(AppContext);
  const { projectId } = useParams();
  const location = useLocation();
  const parentTaskData = location.state;
  const [workspaceIds, setWorkspaceIds] = useState<string[]>([]);
  const batchGetWorkspaces = generateQuery(workspaceIds);
  const [submitting, setIsSubmitting] = useState(false);
  const [getAllWorkspaces, { data: WorkspaceData }] = useLazyQuery(batchGetWorkspaces, {
    fetchPolicy: 'network-only'
  });
  // let workspaceref = React.useRef<HTMLInputElement>(null);
  // let userRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (workspaceIds.length > 0) {
      getAllWorkspaces();
    }
  }, [workspaceIds]);

  const {
    data: projectData,
    error: projectsError,
    loading: projectsLoading
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    },
    fetchPolicy: 'network-only'
  });

  const {
    data: workspaces,
    loading: workspaceLoading
    // error: workspaceError
  } = useQuery(LIST_WORKSPACE_QUERY, {
    variables: {
      organizationId: state.orgId,
      noValidate: true
    }
  });

  const getWorkspaceName = (id: string) => {
    // list_WorkspaceItems?._WorkspaceItems
    let found = workspaces.list_WorkspaceItems._WorkspaceItems?.find((workspace) => workspace._id === id);
    if (found) {
      return { name: found.name, id: id };
    }
    return { label: 'PERSONAL', value: 'PERSONAL' };
  };

  const getProjectName = (id: string) => {
    let found = projectData?.list_ProjectItems?._ProjectItems.find((project) => project._id === id);
    if (found) {
      return { name: found.name, id: found.id };
    }
    return { label: 'PERSONAL', value: 'PERSONAL' };

    // return "PERSONAL";
  };

  const [addTask, { error }] = useMutation(addTaskMutation, {
    fetchPolicy: 'no-cache'
  });

  const [addAssociateTask, { error: associateTaskError }] = useMutation(addAssociateTaskMutation);
  const [updateTaskAssociation] = useMutation(updateTaskAssociationMutation, { fetchPolicy: 'no-cache' });

  console.log('PARENT TASK DATA', parentTaskData);

  const initialState: TaskValues = {
    title: '',
    projectId: parentTaskData?.projectId ? parentTaskData?.projectId : projectId ? projectId : '',
    type: parentTaskData?.type ? parentTaskData?.type : '',
    workspaceId: parentTaskData?.typeId ? parentTaskData?.typeId : '',
    workspaceName: '',
    projectName: '',
    description: '',
    dueDate: parentTaskData?.dueDate ? parentTaskData?.dueDate : '',
    isPersonal: parentTaskData //so first this check the routerstate , if routerstate exists, its going to set the value according to it.If there is no router state it checks if the orgId is Equal to null if true it sets true else it check if orgId is equal to the string "null" and then sets value according to that.
      ? parentTaskData?.isPersonal
      : state.orgId === null
      ? true
      : state.orgId === 'null'
      ? true
      : false,
    priority: selectOptions[1],
    recordStatus: 'active',
    status: statusOptions[0],
    // links: [{ value: '', saved: false }],
    links: [],
    assignedTo: parentTaskData?.assignedTo ? parentTaskData?.assignedTo : '',
    assignedToDetails: {
      name: parentTaskData?.assignedToDetails?.name ? parentTaskData?.assignedToDetails?.name : '',
      profilePicture: '',
      email: ''
    },
    assignee: state.userId,
    assigneeDetails: {
      name: state.fullName,
      profilePicture: '',
      email: ''
    },
    updatedBy: state.userId
  };

  const handleSubmit = async (taskData: TaskValues, { resetForm }: FormikHelpers<TaskValues>) => {
    let linkValues: string[] = [];
    setIsSubmitting(true);
    if (taskData.links && taskData.links.length > 0) {
      linkValues = taskData.links.map((link) => link.value);
    }
    let values = {
      assignee: taskData?.assignee,
      assignedTo: taskData?.assignedTo,
      description: taskData?.description,
      dueDate: taskData?.dueDate,
      priority: taskData?.priority?.value,
      status: taskData?.status?.value,
      title: taskData?.title,
      links: linkValues,
      recordStatus: 0,
      assignedToDetails: {
        name: taskData?.assignedToDetails.name,
        profilePicture: ''
      },
      assigneeDetails: {
        name: state.fullName,
        profilePicture: ''
      },
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString()
    };

    const toastId = toast.loading('Creating task ...');
    try {
      if (taskData.isPersonal) {
        let variables = {
          ...values,
          projectId: 'NOPROJECT',
          type: 'user',
          typeId: taskData?.assignee,
          assignedTo: state.userId,
          assignedToDetails: {
            name: state.fullName,
            profilePicture: ''
          },
          organizationId: state.orgId,
          organizationId_projectId_typeId: `${state.orgId}_NOPROJECT_${taskData?.assignee}`
        };
        let response = await addTask({
          variables: variables
        });
        if (response) {
          let task = {
            _id: response.data.add_Task.result._id,
            ...variables
          };
          dispatch(
            postDashboardApi({
              type: 'metaData',
              moduleType: 'TASKS',
              orgId: state.orgId,
              metaDataId: task._id,
              metaData: { _id: task._id, title: task.title, status: task.status, dueDate: task.dueDate }
            })
          );

          toast.success(Success.taskCreated, { id: toastId });
          setIsSubmitting(false);
          navigate('/tasks', { state: task });
        }
      } else if (!taskData.isPersonal) {
        let variables = {
          projectId: taskData.projectId,
          type: 'workspace',
          typeId: taskData.workspaceId,
          isAcknowledged: false,
          organizationId: state.orgId,
          organizationId_projectId_typeId: `${state.orgId}_${taskData.projectId}_${taskData.workspaceId}`,
          ...values,

          notificationData: {
            actionType: 'assignment',
            userToNotify: values.assignedTo,
            organizationId: state.orgId,
            workspaceName: taskData.workspaceName,
            projectName: taskData.projectName,
            host: window.location.origin
          }
        };
        let response = await addTask({
          variables: variables
        });
        if (response) {
          let task = {
            _id: response.data.add_Task.result._id,
            ...variables
          };
          dispatch(
            postDashboardApi({
              type: 'metaData',
              moduleType: 'TASKS',
              orgId: state.orgId,
              metaDataId: task._id,
              assignedUserId: task.assignedTo,
              metaData: { _id: task._id, title: task.title, status: task.status, dueDate: task.dueDate }
            })
          );
          setIsSubmitting(false);
          toast.success(Success.taskCreated, { id: toastId });
          navigate(projectId ? `/project/${projectId}/tasks` : '/tasks', { state: task });
        }
      }
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
      toast.dismiss(toastId);
    }
  };

  const createAssociateTask = async (taskData: TaskValues) => {
    let linkValues: string[] = [];
    setIsSubmitting(true);
    if (taskData.links && taskData.links.length > 0) {
      linkValues = taskData.links.map((link) => link.value);
    }
    const toastId = toast.loading('Creating a task association ...');
    let values = {
      assignee: taskData?.assignee,
      assignedTo: taskData?.assignedTo,
      description: taskData?.description,
      dueDate: taskData?.dueDate,
      priority: taskData?.priority?.value,
      status: taskData?.status?.value,
      title: taskData?.title,
      links: linkValues,
      recordStatus: 0,
      assignedToDetails: {
        name: taskData?.assignedToDetails.name,
        profilePicture: ''
      },
      assigneeDetails: {
        name: state.fullName,
        profilePicture: ''
      },
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString()
    };
    let variables;
    if (taskData?.isPersonal) {
      variables = {
        ...values,
        projectId: 'NOPROJECT',
        type: 'user',
        typeId: taskData?.assignee,
        assignedTo: state.userId,
        assignedToDetails: {
          name: state.fullName,
          profilePicture: ''
        },
        organizationId: state.orgId,
        organizationId_projectId_typeId: `${state.orgId}_NOPROJECT_${taskData?.assignee}`,
        parentAssociateId: parentTaskData?._id,
        isAssociated: true
      };
    } else {
      variables = {
        projectId: parentTaskData?.projectId,
        type: 'workspace',
        typeId: parentTaskData?.typeId,
        isAcknowledged: false,
        organizationId: state.orgId,
        organizationId_projectId_typeId: `${state.orgId}_${taskData.projectId}_${taskData.workspaceId}`,
        ...values,
        parentAssociateId: parentTaskData?._id,
        isAssociated: true
      };
    }

    const createAssociateTaskPromise = addAssociateTask({ variables });
    const updateCurrentTaskAssociation = updateTaskAssociation({
      variables: {
        isAssociated: true,
        updatedAt: new Date().toISOString(),
        updatedBy: state.userId,
        id: parentTaskData?._id
      }
    });
    const results = Promise.all([createAssociateTaskPromise, updateCurrentTaskAssociation]);
    results
      .then((values) => {
        console.log('VALUES', values);
        toast.success('Created associated task');
        toast.dismiss(toastId);
        setIsSubmitting(false);
        navigate(-1);
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error('Error in creating task');
        setIsSubmitting(false);
        sentryError(error);
      });
  };

  const getWorkspaceIds = async (projectId: string) => {
    let toastId = toast.loading('Loading Workspaces...');
    return axiosInstance
      .get(`/userEntity/?entityType=WORKSPACE&projectId=${projectId}&userId=${state.userId}`)
      .then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          let entities = response.data.data;
          let Ids = entities.map((entity) => entity.entityId);
          toast.dismiss(toastId);
          return Ids;
        } else {
          toast.dismiss(toastId);
          return [];
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        sentryError(error);
        return error;
      });
  };

  const getWorkspaceUsers = async (workspaceId: string, userId: string) => {
    let toastId = toast.loading('Loading Users');
    return axiosInstance
      .get(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            let fullDetails = response.data.data.map((user) => {
              return {
                id: user.userId,
                email: user.email,
                value: user.fullName,
                label: user.userId === userId ? `Myself (${user.fullName})` : user.fullName
              };
            });
            toast.dismiss(toastId);
            return fullDetails;
          }
        }
      })
      .catch((error) => {
        sentryError(error);
        toast.error('Error loading users of the selected workspace');
        toast.dismiss(toastId);
        return [];
      });
  };

  const handleProjectChange = async (selectedOption: any, workspaceref: any) => {
    if (selectedOption.name === 'Personal Task') {
      formik.setFieldValue('isPersonal', true);
    } else {
      formik.setFieldValue('isPersonal', false);
      formik.setFieldValue('projectId', selectedOption._id);
      formik.setFieldValue('projectName', selectedOption.name);
      formik.setFieldValue('workspaceId', '');
      formik.setFieldValue('workspaceName', '');
      workspaceref?.select?.clearValue();
      const workspaceIds = await getWorkspaceIds(selectedOption._id);
      if (workspaceIds.length) {
        setWorkspaceIds(workspaceIds);
      } else {
        toast.error('You are not part of any workspaces in this project');
        setWorkspaceIds([]);
      }
    }
  };

  const handleWorkspaceChange = async (selectedOption: any, userRef: any) => {
    formik.setFieldValue('workspaceId', selectedOption?._id ? selectedOption._id : '');
    formik.setFieldValue('workspaceName', selectedOption?.name ? selectedOption.name : '');
    if (selectedOption?._id) {
      userRef?.select?.clearValue();
      const users = await getWorkspaceUsers(selectedOption._id, state.userId);
      if (users.length > 0) {
        setUsers(users);
      } else {
        setUsers([]);
      }
      formik.setFieldTouched('workspaceId', true);
      formik.setFieldTouched('workspaceName', true);
      formik.setFieldError('workspaceId', undefined);
    }
  };

  const handlePersonalTask = (event) => {
    formik.setFieldValue('isPersonal', event.target.checked);
  };

  const doNothing = () => {};

  useEffect(() => {
    const getUsersOfWorkspace = async () => {
      if (parentTaskData && parentTaskData?.type === 'workspace') {
        const users = await getWorkspaceUsers(parentTaskData?.typeId, state.userId);
        if (users.length > 0) {
          setUsers(users);
        } else {
          setUsers([]);
        }
      }
    };
    getUsersOfWorkspace();
    //this method is triggered when you try to create an associate task from parent task.
  }, []);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CreateTaskValidationSchema,
    onSubmit: parentTaskData ? createAssociateTask : handleSubmit,
    validateOnBlur: true
  });

  return {
    formik,
    projectData,
    projectsError,
    projectsLoading,
    handleWorkspaceChange,
    handleProjectChange,
    // workspaceref,
    WorkspaceData,
    submitting,
    doNothing,
    state,
    error,
    users,
    parentTaskData,
    // userRef,
    handlePersonalTask,
    workspaceIds,
    getProjectName,
    getWorkspaceName,
    workspaceLoading
  };
};
