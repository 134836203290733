import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { AppContext } from '../../../AppContext';
import ChooseProject from '../../../components/ChooseProject';
import ProjectList from '../../../components/ProjectList';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries'
import { ProjectsContainer } from '../ProjectViews/Projects';

function ChooseProjectList({ setProjectId }: { setProjectId: (id: string) => void }) {
  const { state } = useContext(AppContext);
  const { data, error, loading } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId,
    },
  })
  if (loading)
    return <p>Loading</p>
  if (error)
    return <p>error</p>
  return (
    <div style={{ marginTop: "25px" }}>
      <ProjectsContainer>
        <ChooseProject setProjectId={setProjectId} data={data?.list_ProjectItems?._ProjectItems}>

        </ChooseProject>
      </ProjectsContainer>
    </div>
  )
}

export default ChooseProjectList
