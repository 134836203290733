import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { updateTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { getProjectQuery } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { SINGLE_TASK_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import { getWorkspaceQuery } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';

function useGetWorkspaceTask() {
  let { projectId, workspaceId, taskId } = useParams();
  const { data, error, loading } = useQuery(SINGLE_TASK_QUERY, {
    variables: { id: taskId },
    fetchPolicy: 'network-only'
  });
  const {
    data: projectData,
    error: projectError,
    loading: projectLoading
  } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    }
  });
  const {
    data: workspaceData,
    error: workspaceError,
    loading: workspaceLoading
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    }
  });
  const [updateTask] = useMutation(updateTaskMutation, {
    fetchPolicy: 'no-cache'
  });

  const handleStatusChange = (statusValue: string, id: string) => {
    updateTask({
      variables: {
        status: statusValue,
        id: id
      }
    })
      .then((res) => {
        toast.success('Task Updated');
      })
      .catch((err) => {
        console.error('ERR', err);
        toast.error('Oh no something went wrong . Try again.');
      });
  };

  const handlePriorityChange = (priority: string, id: string, type: string, typeId: string) => {
    updateTask({
      variables: {
        priority: priority,
        id: id,
        type,
        typeId
      }
    })
      .then((res) => {
        toast.success('Task Updated');
      })
      .catch((err) => {
        console.error('ERR', err);
        toast.error('Oh no something went wrong . Try again.');
      });
  };
  return {
    loading,
    projectLoading,
    workspaceLoading,
    data,
    error,
    projectError,
    workspaceError,
    projectData,
    workspaceData,
    handlePriorityChange,
    handleStatusChange
  };
}

export default useGetWorkspaceTask;
