import styled from '@emotion/styled';
import axiosInstance from '../../../axiosInstance';
import React, { useContext, useReducer, useState } from 'react';
import { useToggle } from '../../../lib/UseToggle';
import Modal from '../../../components/Modal';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../components/styles/ModalStyles';
import Button from '../../../components/CustomButton';
import MemberItem, { NameTag } from '../../../components/MemberItem';
import RoleItem, { ModalInput } from '../../../components/Roles/RoleItem';
import { GreyButton } from '../../../components/styles/ButtonStyles';
import { Header, PageTitle, TitleLeft, TitleRight } from '../../../components/styles/Header';
import { ItemContainer, ItemStyles } from '../../../components/styles/ListItemStyles';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { useEffect } from 'react';
import Checkbox from '../../../components/Checkbox';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { AppContext } from '../../../AppContext';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import Avatar from 'react-avatar';

export const OtherMembersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding: 20px 20px;
  padding-bottom: 10px;
  padding-right: 34px;
  .action-buttons > button {
    flex: 1 1 auto;
    margin: 5px;
  }
  /* border: 1px solid red; */
`;

const RolesWrapper = styled.div`
  padding: 10px 30px;
  border-bottom: 1px solid var(--border-color);
`;
export const Text = styled.p`
  font-weight: 600;
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;
export const CheckBoxListItem = styled.div`
  padding: 12px;
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-top: 15px;
  .memberName {
    margin-left: 22px;
  }
  /* justify-content: center;
 align-self: flex-start; */
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 33px;
  margin-bottom: 30px;
  /* border: 1px solid red; */
`;

const NoRolesAdded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;
`;

// interface RoleDetails {
//   selectedMember: any;
//   role: any;
//   toggle: () => void;
// }

const initialState: any = {};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET USERS':
      return action.data;
    case 'UPDATE USER':
      let newState = state.map((user) => {
        if (user.id === action.data.userId) {
          user.addedToRole = action.data.roleValue;
        }
        return user;
      });
      return newState;
    default:
      return initialState;
  }
};

function WorkspaceMembers() {
  let navigate = useNavigate();
  let { projectId, workspaceId } = useParams();
  const [isOpen, toggle] = useToggle(false);
  const [isOtherMembersInviteModalOpen, toggleOtherMembersInviteModal] = useToggle(false);
  const { state } = useContext(AppContext);
  const [roles, setRoles] = useState([]);
  const [users, dispatch] = useReducer(reducer, initialState);
  const [otherMembers, setOtherMembers] = useState([]);
  const [onlyStaticRoles, setOnlyStaticRoles] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [invitedEmailIds, setInvitedEmailIds] = useState<string[]>([]);
  const location = useLocation();
  const orgMembers = useSelector((state) => state.orgMembers.users);
  const registerData = location?.state?.registerData;
  const workspaceName = location?.state?.workspaceName;

  let schema = yup.object().shape({
    email: yup.string().email()
  });
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const goToFinalPage = () => {
    let toastId = toast.loading('Adding Members and Redirecting');
    let data = users
      .filter((user) => user.checked === true || user.addedToRole === true)
      .map((user) => {
        return {
          userId: user.id,
          entityType: 'WORKSPACE',
          entityId: workspaceId,
          projectId: projectId,
          organizationId: state?.orgId
        };
      });
    let listUserId = data
      .filter((userData) => userData.userId !== state?.userId)
      .map((data) => {
        return data.userId;
      });
    data.push({
      userId: state?.userId,
      entityType: 'WORKSPACE',
      entityId: workspaceId,
      projectId: projectId,
      organizationId: state?.orgId
    });
    axiosInstance
      .post('/userEntity', data)
      .then((res) => {
        toast.dismiss(toastId);
        navigate(`/project/${projectId}`);
      })
      .catch((error) => {
        console.error('ERROR', error);
        toast.dismiss(toastId);
        toast.error('Oh no something went wrong.');
      });
  };

  const handleOnChange = (position, selected) => {
    let members = users.slice();
    members.forEach((member) => {
      if (member.id === selected.id) {
        member.checked = !member.checked;
      }
    });
    dispatch({ type: 'SET USERS', data: members });
    setOtherMembers(members);
  };

  const removeUser = (member) => {
    let newMembers = users.slice();
    newMembers.forEach((user) => {
      if (user.id === member.id) {
        user.checked = !user.checked;
      }
    });
    dispatch({ type: 'SET USERS', data: newMembers });
    setOtherMembers(newMembers);
  };

  const inviteMemberToWorkspace = () => {
    let toastId;
    schema
      .isValid({
        email: email
      })
      .then((valid) => {
        if (valid && email !== '') {
          toastId = toast.loading('Sending Email');
          let arrayOfEmailIds = orgMembers && orgMembers.length > 0 && orgMembers.map((user) => user.email);
          if (arrayOfEmailIds.includes(email)) {
            throw new Error('ExistingUser');
          }
          setSendingEmail(true);
          setEmailError(false);
          let values = {
            workspaceId: workspaceId,
            email: email.toLowerCase(),
            inviterId: state.userId,
            entityType: 'WORKSPACE',
            entityId: workspaceId,
            projectId: projectId,
            organizationId: state.orgId,
            host: window.location.origin
          };
          axiosInstance
            .post(`workspaceInvite`, {
              ...values
            })
            .then((res) => {
              setSendingEmail(false);
              if (res.data) {
                setInvitedEmailIds([...invitedEmailIds, email.toLowerCase()]);
                toggleOtherMembersInviteModal();
                toast.dismiss(toastId);
                toast.success('Invite Sent.');
              }
            })
            .catch((error) => {
              setSendingEmail(false);
              toast.dismiss(toastId);
              console.error(error);

              if (error?.message) {
                toast.error(error?.response?.data?.message);
              } else {
                toast.error('Something went wrong try again');
              }
            });
        } else {
          setSendingEmail(false);
          setEmailError(true);
          toast.dismiss(toastId);
          toast.error('Please enter correct email address');
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        if (error.message === 'ExistingUser') {
          toast.error(`This user's email is already part of the organization`);
        }
        console.error('Error', error);
      });
  };

  useEffect(() => {
    if (projectId && workspaceId && state.orgId) {
      axiosInstance
        .get(`/roles?entityType=WORKSPACE&entityId=${workspaceId}`)
        .then((res) => {
          if (res.data.data.length === 0) {
            setOnlyStaticRoles(true);
          } else {
            let result = res.data.data.map((data) => {
              return {
                ...data,
                registerData,
                workspaceName,
                assigneeUser: {
                  userId: state.userId,
                  name: state.fullName
                }
              };
            });
            setRoles(result);
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    }
  }, []);
  useEffect(() => {
    if (projectId && workspaceId && state.orgId) {
      axiosInstance
        .get(`/project/members?projectId=${projectId}`)
        .then((response) => {
          if (response.data) {
            let members = response.data.data.projectDirectory.map((user) => {
              return {
                id: user.userId,
                value: user.userData.fullName,
                label: user.userData.fullName,
                checked: false,
                addedToRole: false,
                email: user.userData.email
              };
            });
            dispatch({ type: 'SET USERS', data: members });
            setUsersLoaded(true);
            let otherMembers = members.map((user) => ({
              ...user,
              checked: false
            }));
            setOtherMembers(otherMembers);
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    }
  }, []);

  useEffect(() => {
    if (projectId && workspaceId) {
      axiosInstance
        .get(`rolesMapping?entityType=WORKSPACE&entityId=${workspaceId}`)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0 && users.length > 0) {
            let usersWithRoles = response.data.data;
            let updatedUsers = users.map((user) => {
              usersWithRoles.map((userWithRole) => {
                if (userWithRole.userId === user.id) {
                  user.addedToRole = true;
                }
              });
              return user;
            });
            dispatch({ type: 'SET USERS', data: updatedUsers });
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    }
  }, [usersLoaded]);

  return (
    <>
      <Header>
        <TitleLeft>
          <PageTitle>Add Members</PageTitle>
        </TitleLeft>
        <TitleRight>
          <Button onClick={goToFinalPage}>Save Workspace</Button>
        </TitleRight>
      </Header>
      <Container>
        <RolesWrapper>
          <PageTitle>Required Roles</PageTitle>
        </RolesWrapper>
        <ItemContainer>
          {roles.length <= 0 && !onlyStaticRoles ? (
            <Lottie options={defaultOptions} width={400} height={500} />
          ) : onlyStaticRoles ? (
            <NoRolesAdded>No Dynamic Registers Selected</NoRolesAdded>
          ) : (
            roles.map((role) => {
              // let userArray = usersWithRoles?.filter((data) => {
              //   return data.roleName === role.roleName;
              // });
              return (
                <RoleItem
                  key={role.id}
                  role={role}
                  users={users}
                  dispatch={dispatch}
                  // userArray={userArray}
                  // addSelectedMemberToRole={addSelectedMemberToRole}
                />
              );
            })
          )}
        </ItemContainer>
        <OtherMembersWrapper>
          <PageTitle>Members</PageTitle>
          <div className="action-buttons">
            <GreyButton onClick={toggleOtherMembersInviteModal}>Invite Members</GreyButton>
            <GreyButton onClick={toggle}>Add Members</GreyButton>
          </div>
        </OtherMembersWrapper>
        <Modal open={isOpen} onClose={toggle}>
          <ModalContainer width="396px">
            <ModalTitle>Other Members</ModalTitle>
            <ModalBody>
              <ModalText>Add Members to Workspace</ModalText>
              <CheckBoxContainer>
                {users.length > 0 &&
                  users
                    .filter((user) => user.addedToRole === false)
                    .filter((user) => user.id !== state.userId)
                    .map((member, index) => {
                      return (
                        <React.Fragment key={index}>
                          <CheckBoxListItem>
                            <label>
                              <Checkbox checked={member.checked} onChange={() => handleOnChange(index, member)} />
                              <span className="memberName">{member.value}</span>
                            </label>
                          </CheckBoxListItem>
                        </React.Fragment>
                      );
                    })}
              </CheckBoxContainer>
            </ModalBody>
            <ModalWrapper>
              <Button onClick={toggle} secondary>
                Cancel
              </Button>
              <Button onClick={toggle}>Confirm</Button>
            </ModalWrapper>
          </ModalContainer>
        </Modal>
        <Modal open={isOtherMembersInviteModalOpen} onClose={toggleOtherMembersInviteModal}>
          <ModalContainer width="528px">
            <ModalTitle>Send Invitation via Email</ModalTitle>
            <ModalBody>
              <ModalText>Invite a member to this workspace</ModalText>
              <ModalInput
                placeholder="email"
                type="email"
                className="input-email"
                value={email}
                onChange={(e) => handleEmailChange(e)}
                error={emailError}
                disabled={sendingEmail}
              />
            </ModalBody>
            <ModalWrapper>
              <Button secondary disabled={sendingEmail} onClick={toggleOtherMembersInviteModal}>
                Cancel
              </Button>
              <Button disabled={sendingEmail} onClick={() => inviteMemberToWorkspace()}>
                Send Invitation
              </Button>
            </ModalWrapper>
          </ModalContainer>
        </Modal>
        <ItemContainer>
          {users.length > 0 &&
            users
              .filter((user) => user.id === state.userId)
              .map((user) => {
                return <MemberItem name={user}></MemberItem>;
              })}
          {otherMembers.length > 0 &&
            otherMembers.map((member, index) => {
              return member && member.checked ? <MemberItem removeUser={removeUser} name={member}></MemberItem> : null;
            })}
          {invitedEmailIds.length > 0 &&
            invitedEmailIds.map((email: String, index) => {
              return (
                <ItemStyles>
                  <NameTag>
                    <Avatar round={true} size="21" name={email} textSizeRatio={2} />
                    <p className="name">{email}</p>
                  </NameTag>
                </ItemStyles>
              );
            })}
        </ItemContainer>
      </Container>
    </>
  );
}

export default WorkspaceMembers;
