import { ButtonWrapper } from '../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Modal from '../Modal';
import { ModalBody, ModalContainer, ModalTitle } from '../styles/ModalStyles';
import Button from '../CustomButton';

// const NumberDiv = styled.div`
//   width: fit-content;
//   margin: 0 auto;
//   padding: 5px;
//   /* height: 2rem; */
//   border: 1px solid #adacad;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   .modal-icon {
//     width: 1.5rem;
//     height: 1.5rem;
//     padding: 0.2rem;
//     background-color: #f3f3f3;
//     font-size: 1rem;
//     color: #93979f;
//     border-radius: 8px;
//     cursor: pointer;
//   }
//   span {
//     font-weight: 400;
//   }
// `;

// const SeatQuantity = styled.div`
//   font-weight: 400;
//   margin-left: 25px;
//   margin-right: 25px;
// `;

export const UpgradeOrganisationPlanModal = (props: {
  active: any;
  onClose: any;
  onSubmit: () => void;
  buttonState: boolean;
  numberOfSeats: number;
}) => {
  // const [num, setnum] = useState(1);

  // const onClick = (T: 1 | 0) => {
  //   if (T === 1) setnum(num + 1);
  //   else num === 0 ? setnum(num) : setnum(num - 1);
  // };
  return (
    <Modal open={props.active} onClose={props.onClose}>
      <ModalContainer width="550px">
        <ModalTitle>Upgrade your plan</ModalTitle>
        <ModalBody>
          {/* <ModalText>Please choose the number of seats for your organization</ModalText> */}
          <ModalText>Note : You have {props.numberOfSeats} users waiting for seats.</ModalText>
          {/* <NumberDiv>
            <AiOutlineMinus onClick={() => onClick(0)} className="modal-icon" />
            <SeatQuantity>{num}</SeatQuantity>
            <AiOutlinePlus onClick={() => onClick(1)} className="modal-icon" />
          </NumberDiv> */}
          <ButtonWrapper>
            <Button disabled={props.buttonState} secondary onClick={props.onClose}>
              Cancel
            </Button>
            <Button disabled={props.buttonState} onClick={props.onSubmit}>
              Upgrade
            </Button>
          </ButtonWrapper>
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};
