import Select from 'react-select';
import axiosInstance from '../../axiosInstance';
import styled from '@emotion/styled';
import Button from '../../components/CustomButton';
import TableDropDown from '../../components/TableComponents/TableDropDown';
import getUtcDate from '../../lib/getUtcDate';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import { taskDropdownStyles } from '../../components/styles/TaskDropdownStyles';
import { DropdownDiv, isMonth, isToday, isUpcoming, TaskStyles } from '../../components/Tasks';
import { taskOptions } from '../../constants/TasksDropdown';
import { Reducer, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { AppContext } from '../../AppContext';
import { sentryError } from '../../lib/SentryError';
import { ErrorPage } from '../Others';
import { HighPriority, LowPriority, MediumPriority } from '../../components/TableComponents/Priority';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { EmptyDataContainer, ProjectsContainer } from '../Projects/ProjectViews/Projects';

const PageHeaderStyles = styled.div`
  padding-left: 45px;
  padding-right: 45px;
`;

const NoAccountContainer = styled(ProjectsContainer)``;

type HubSpotTask = {
  description: string;
  dueDate: Date;
  id: string;
  numberOfAssociations: string;
  priority: string;
  status: string;
  taskTitle: string;
  type: string;
  hubspotUserId: string;
};

export type AssignedHubspotTask = HubSpotTask & {
  assignedTo: string;
  createdBy: number;
};

export type DelegatedHubspotTask = HubSpotTask & {
  assignedTo: number;
  createdBy: string;
};

type THubspotTasks = {
  assignedTasks: {
    today: AssignedHubspotTask[];
    month: AssignedHubspotTask[];
    upcoming: AssignedHubspotTask[];
    done: AssignedHubspotTask[];
  };
  delegatedTasks: {
    today: DelegatedHubspotTask[];
    month: DelegatedHubspotTask[];
    upcoming: DelegatedHubspotTask[];
    done: DelegatedHubspotTask[];
  };
  error: string;
  loading: boolean;
};

type ActionType =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | {
      type: 'SET_TASKS_TABLE';
      payload: { assignedTasks: AssignedHubspotTask[]; delegatedTasks: DelegatedHubspotTask[] };
    };

const initialState: THubspotTasks = {
  assignedTasks: {
    done: [],
    month: [],
    today: [],
    upcoming: []
  },
  delegatedTasks: {
    done: [],
    month: [],
    today: [],
    upcoming: []
  },
  error: '',
  loading: false
};

const columns = [
  {
    Header: 'Task',
    accessor: 'taskTitle',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    Cell: ({ cell: { value } }) =>
      value === 'HIGH' ? (
        <HighPriority />
      ) : value === 'MEDIUM' ? (
        <MediumPriority />
      ) : value === 'LOW' ? (
        <LowPriority />
      ) : (
        'None'
      )
  },
  {
    Header: 'Due Date',
    accessor: 'dueDate',
    Cell: ({ cell: { value } }) => <span>{getUtcDate(value)}</span>
  }
];

const reducer: Reducer<THubspotTasks, ActionType> = (state: THubspotTasks, action: ActionType) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: state.loading ? false : true };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_TASKS_TABLE':
      let obj: THubspotTasks = {
        assignedTasks: {
          done: [],
          month: [],
          today: [],
          upcoming: []
        },
        delegatedTasks: {
          done: [],
          month: [],
          today: [],
          upcoming: []
        },
        error: '',
        loading: true
      };
      obj.assignedTasks.today = action.payload.assignedTasks
        .filter((task: AssignedHubspotTask) => isToday(task.dueDate))
        .filter((task: AssignedHubspotTask) => task.status !== 'done');
      obj.assignedTasks.month = action.payload.assignedTasks
        .filter((task: AssignedHubspotTask) => isMonth(task.dueDate))
        .filter((task: AssignedHubspotTask) => task.status !== 'done');
      obj.assignedTasks.upcoming = action.payload.assignedTasks
        .filter((task: AssignedHubspotTask) => isUpcoming(task.dueDate))
        .filter((task: AssignedHubspotTask) => task.status !== 'done');
      obj.assignedTasks.done = action.payload.assignedTasks.filter(
        (task: AssignedHubspotTask) => task.status === 'DONE'
      );
      obj.delegatedTasks.today = action.payload.delegatedTasks
        .filter((task: DelegatedHubspotTask) => isToday(task.dueDate))
        .filter((task: DelegatedHubspotTask) => task.status !== 'done');
      obj.delegatedTasks.month = action.payload.delegatedTasks
        .filter((task: DelegatedHubspotTask) => isMonth(task.dueDate))
        .filter((task: DelegatedHubspotTask) => task.status !== 'done');
      obj.delegatedTasks.upcoming = action.payload.delegatedTasks
        .filter((task: DelegatedHubspotTask) => isUpcoming(task.dueDate))
        .filter((task: DelegatedHubspotTask) => task.status !== 'done');
      obj.delegatedTasks.done = action.payload.delegatedTasks.filter(
        (task: DelegatedHubspotTask) => task.status === 'DONE'
      );

      return { ...obj, loading: false };
  }
};

function HubSpotTasksPage() {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [taskdata, dispatch] = useReducer(reducer, initialState);
  const [pageView, setPageView] = useState<'assignedTasks' | 'delegatedTasks'>('assignedTasks');

  const viewTask = (row: AssignedHubspotTask | DelegatedHubspotTask) => {
    // console.log('row', row);
    if (!isNaN(row.assignedTo)) {
      navigate(`${row.id}`, { state: { ...row, getDataByField: 'assignedTo' } });
    } else if (!isNaN(row.createdBy)) {
      navigate(`${row.id}`, { state: { ...row, getDataByField: 'createdBy' } });
    } else {
      navigate(`${row.id}`, { state: { ...row, getDataByField: 'none' } });
    }
  };

  useEffect(() => {
    const getAllHubspotTasks = () => {
      dispatch({ type: 'SET_LOADING' });
      axiosInstance
        .get(`/task?organizationId=${state.orgId}&email=${encodeURIComponent(state.email)}`)
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          console.log('RES', res);
          if (Object.keys(res).length !== 0) {
            let obj = {
              assignedTasks: [...res.assignedTasks, ...res.selfAssignedTasks],
              delegatedTasks: res.delegatedTasks
            };
            dispatch({ type: 'SET_TASKS_TABLE', payload: obj });
          } else {
            dispatch({ type: 'SET_ERROR', payload: 'No Account in hubspot' });
            dispatch({ type: 'SET_LOADING' });
            toast.error('You need to create an account in hubspot to view your tasks');
          }
        })
        .catch((error) => {
          sentryError(error);
          dispatch({ type: 'SET_ERROR', payload: error.message });
        });
    };
    getAllHubspotTasks();
  }, []);

  const handleTaskChange = async (option: { value: string; label: string }) => {
    if (option.value === 'assignedtask') {
      setPageView('assignedTasks');
    } else if (option.value === 'delegatedtask') {
      setPageView('delegatedTasks');
    }
  };

  const data = useMemo(() => taskdata, [taskdata]);
  const taskColumns = useMemo(() => columns, [taskdata]);

  if (taskdata.loading) {
    return <Loader />;
  }

  if (taskdata.error) {
    if (taskdata.error === 'No Account in hubspot') {
      return (
        <>
          <NoAccountContainer>
            <div className="empty-data">
              <EmptyDataContainer>
                You need to have an hubspot account with the same email to view your hubspot tasks here.
              </EmptyDataContainer>
            </div>
          </NoAccountContainer>
        </>
      );
    } else {
      return <ErrorPage />;
    }
  }

  // console.log({ taskdata });
  return (
    <>
      <PageHeaderStyles>
        <TaskStyles>
          <div className="tasks-header">
            <h4 className="tasks-title"> To-do List</h4>
            <Select
              options={taskOptions}
              name="hubspottasks"
              className="task-dropdown"
              isSearchable={false}
              styles={taskDropdownStyles}
              onChange={(selectedOption) => {
                handleTaskChange(selectedOption);
              }}
              defaultValue={taskOptions[0]}
            />
          </div>
          <a href={`https://app.hubspot.com`} target="_blank" rel="noreferrer">
            <Button>Go to Hubspot</Button>
          </a>
        </TaskStyles>
        <DropdownDiv>
          <div className="drop-item ">
            <TableDropDown state={taskdata[pageView].today.length > 0 ? true : false} text="Today">
              {taskdata.assignedTasks.today && (
                <Table columns={taskColumns} data={data[pageView].today} clickHandler={viewTask} />
              )}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={taskdata[pageView].month.length > 0 ? true : false} text="This Month">
              {taskdata.assignedTasks.month && (
                <Table columns={taskColumns} data={data[pageView].month} clickHandler={viewTask} />
              )}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={taskdata[pageView].upcoming.length > 0 ? true : false} text="UpComing">
              {taskdata.assignedTasks.upcoming && (
                <Table columns={taskColumns} data={data[pageView].upcoming} clickHandler={viewTask} />
              )}
            </TableDropDown>
          </div>
          <div className="drop-item">
            <TableDropDown state={taskdata[pageView].done.length > 0 ? true : false} text="Done">
              {taskdata.assignedTasks.done && (
                <Table columns={taskColumns} data={data[pageView].done} clickHandler={viewTask} />
              )}
            </TableDropDown>
          </div>
        </DropdownDiv>
      </PageHeaderStyles>
    </>
  );
}

export default HubSpotTasksPage;
