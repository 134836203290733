import React from "react";
import ListItem from "./ListItem";
import styled from "@emotion/styled";
import { ReactComponent as ProjectIcon } from "../assets/icons/ProjectTitleIcon.svg";
import { getDate } from "../lib/getDate";
import { getTime } from "../lib/getTime";
// import { Text } from "../components/styles/Text";

export const ProjectInfo = styled.div`
  display: flex;
  padding-left: 2.6rem;
  align-items: center;
`;
export const ProjectName = styled.p`
  font-weight: 600;
  margin-left: 2.01rem;
`;

export const ProjectDate = styled.div`
  color: var(--text-light);
  display: flex;
  align-items: center;
  padding-right: 7.6rem;
`;

export const DateStyle = styled.p`
  margin-right: 20px;
`;
export const Time = styled.p``;

export default function ProjectList({ data, children }: { data: any, children?: React.ReactNode }) {

  return (
    <div>
      {data && data?.length > 0 && data.map((item, index) => {
        return (
          <React.Fragment key={item._id}>
            <ListItem item={item}>
              <ProjectInfo>
                <ProjectIcon />
                <ProjectName>{item.name}</ProjectName>
              </ProjectInfo>
              <ProjectDate>
                <DateStyle>
                  {getDate(item?.createdAt)}
                </DateStyle>
                <Time>
                  {getTime(item?.createdAt)}
                </Time>
              </ProjectDate>
              {children}
            </ListItem>
          </React.Fragment>
        );
      })}
    </div>
  );
}
