import { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { Header } from '../../../../components/styles/Header';
import Button from '../../../../components/CustomButton';
import * as ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from '../../../../components/Files/textEditorConfig';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { useFormik } from 'formik';
import InputField from '../../../../components/Reusable/Formik/Input';
import NoteValidation from '../../../../components/Validations/NoteValidation';
import { AppContext } from '../../../../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { createNoteMutation } from '../../../../graphql/operations/Mutations/Library/NoteMutations';
// import useForm from "../../../../lib/UseForm";
const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4.6rem;
  .notename {
    border: none;
    font-size: 1.5rem;
    height: 60px;
  }
`;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

const TextEditorStyles = styled.div`
  .ql-editor {
    min-height: 76vh;
  }
`;

const Form = styled.form``;

function CreateWorkspaceNewNote() {
  const { state } = useContext(AppContext);
  let navigate = useNavigate();
  let { fileId, projectId, workspaceId } = useParams();
  const [html, setHtml] = useState('');

  const initialState = {
    title: ''
  };

  const onTextChange = (content: any, delta: any, source: any, editor: any) => {
    let data = editor.getHTML();
    setHtml(data);
  };

  const [addNote, { error }] = useMutation(createNoteMutation);

  const handleSubmit = async (values: { title: string }) => {
    const toastId = toast.loading('Creating...');
    try {
      let Response = await addNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: values?.title,
          content: html,
          createdAt: new Date().toISOString(),
          createdBy: state?.userId,
          noteType: 'workspace',
          typeId: workspaceId,
          noteType_typeId: `workspace_${workspaceId}`,
          projectId: projectId,
          updateBy: state?.userId,
          updatedAt: new Date().toISOString()
        }
      });
      if (Response) {
        toast.success('New Note Created', {
          id: toastId
        });
        navigate(-1);
      }
    } catch (e) {
      toast.dismiss(toastId);
      console.error(e);
      toast.error('Error in creating Note');
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: NoteValidation,
    enableReinitialize: true,
    onSubmit: handleSubmit
  });

  if (error) return <ErrorPage />;
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Header>
          <TitleLeft>
            <InputField
              style={{ border: 'none', fontSize: '1.5rem', height: '60px' }}
              name="title"
              type="text"
              formik={formik}
              disabled={formik.isSubmitting}
              placeHolder="New Note"
            />
          </TitleLeft>
          <TitleRight>
            <Button type="submit">Save Note</Button>
          </TitleRight>
        </Header>
        <TextEditorStyles>
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            onChange={onTextChange}
            defaultValue={html}
            readOnly={formik.isSubmitting}
            // bounds={'.app'}
          ></ReactQuill>
        </TextEditorStyles>
      </Form>
    </>
  );
}

export default CreateWorkspaceNewNote;
