export const FilesFilterStyles = {
  control: (base) => ({
    ...base,
    width: "110px",
    // border: "1px solid #E5E5E5",
    boxShadow: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    color: isFocused ? "white" : "#1C1C1C",
    background: isFocused ? "#2F3F48" : "white",
    height: "30px",
  }),
};
