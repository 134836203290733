import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { createMeetingNoteMutation } from '../../../graphql/operations/Mutations/MeetingNotes/MeetingNotesMutation';
import { useToggle } from '../../../lib/UseToggle';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import Compress from 'browser-image-compression';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';

const initialState = {
  notesName: '',
  organizationName: '',
  phone: '',
  email: '',
  address: '',
  brandLogo: '',
  otherFields: {}
};

function useCreateMeetingNoteTemplate() {
  let navigate = useNavigate();
  let { projectId, workspaceId } = useParams();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [isOpen, toggle] = useToggle(false);
  const [isOpenSaveTemplate, saveTemplateToggle] = useToggle(false);
  const [selectedField, setSelectedField] = useState();
  const { state } = useContext(AppContext);
  const [confirmField, setConfirmField] = useState([]);
  const [createMeetingNoteTemplate] = useMutation(createMeetingNoteMutation);
  const [fileObj, setFileObj] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [submitting, setIsSubmitting] = useState(false);

  let schema = yup.object().shape({
    templateName: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, 'invalid')
      .required()
  });

  const handleSelectedField = (field) => {
    setSelectedField({ ...field, fieldId: uuidv4() });
  };
  const handleClose = () => {
    toggle();
  };
  const handleConfirm = () => {
    setConfirmField((currentArray) => [...currentArray, selectedField]);
    toggle();
  };
  const handleRemove = (fieldId) => {
    setConfirmField(confirmField.filter((item) => item.fieldId !== fieldId));
  };

  const handleSaveTemplateConfirm = async () => {
    schema
      .isValid({
        templateName: templateName
      })
      .then((valid) => {
        if (valid) {
          saveTemplateToggle();
          formik.handleSubmit();
        } else {
          toast.error('Template name cannot contain special characters');
        }
      })
      .catch((error) => {
        toast.error('Please Enter template Name');
      });
  };

  const handleSaveTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };
  const handleImageUpload = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSubmit = async (values) => {
    let toastId = toast.loading('Creating Template');
    setIsSubmitting(true);
    let key = '';
    if (values.brandLogo !== '') {
      let toast2 = toast.loading('Uploading Logo..');
      await Storage.put(state.fullName, fileObj ? fileObj : values.brandLogo, {
        contentType: fileObj ? fileObj.type : values.brandLogo.type,
        bucket: process.env.REACT_APP_MEETING_NOTES_BUCKET_NAME,
        level: 'public'
      })
        .then((data) => {
          if (data.key) {
            toast.dismiss(toast2);
            toast.success('Brand Logo added.');
            key = data.key;
          }
        })
        .catch((error) => {
          console.error('Error in uploading logo', error);
          toast.dismiss(toast2);
          toast.dismiss(toastId);
          setIsSubmitting(false);
          toast.error('Error in uploading Brand Logo.');
          return;
        });
    }
    let finalValues = {
      notesName: '',
      organizationName: state.orgId,
      phone: values.phone,
      email: values.email,
      address: values.address,
      brandLogo:
        key !== ''
          ? `https://${process.env.REACT_APP_MEETING_NOTES_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${state.fullName}`
          : '',
      otherFields: [...confirmField]
    };

    let stringifiedValues = JSON.stringify(finalValues);

    try {
      let response = await createMeetingNoteTemplate({
        variables: {
          createdBy: state.userId,
          createdAt: new Date().toISOString(),
          isGlobal: false,
          organizationId: state.orgId,
          organizationId_type: `${state.orgId}_MEETING_NOTES`,
          recordStatus: 0,
          templateName: templateName.trim(),
          type: 'MEETING_NOTES',
          updateBy: state.userId,
          updatedAt: new Date().toISOString(),
          metaData: stringifiedValues
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Meeting note template created.');
        setIsSubmitting(false);
        navigate(`/project/workspace/${projectId}/${workspaceId}/meetingnotes`);
      }
    } catch (error) {
      console.error('ERROR', error);
      toast.dismiss(toastId);
      setIsSubmitting(false);
      toast.error('Something went wrong');
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleSubmit
  });

  const handleClick = () => {
    toggle();
  };

  const handleSaveTemplateClick = () => {
    saveTemplateToggle();
  };

  const handleImageChange = (event) => {
    const image = event.currentTarget.files[0];
    if (image?.size < 10000000) {
      const toastId = image && toast.loading('Uploading Image');
      const options = {
        maxWidthOrHeight: 2000,
        maxSizeMB: 0.2,
        initialQuality: 0.6,
        useWebWorker: true
      };
      Compress(image, options).then((compressedBlob) => {
        toast.success('Uploaded', { id: toastId });
        formik.setFieldValue('brandLogo', URL.createObjectURL(compressedBlob));
        setFileObj(compressedBlob);
      });
    }
  };

  const meetingNotesChooseFields = [
    {
      id: 1,
      formLabel: 'Date',
      formType: 'single',
      formValue: false
    },
    {
      id: 2,
      formHeader: 'Contact',
      formType: 'nested',
      formData: [
        {
          formLabel: 'Phone',
          formValue: false,
          type: 'number'
        },
        {
          formLabel: 'Organization Name',
          formValue: false,
          type: 'text'
        },
        {
          formLabel: 'Email',
          formValue: false,
          type: 'email'
        },
        {
          formLabel: 'Address',
          formValue: false,
          type: 'text'
        }
      ]
    },
    {
      id: 3,
      formLabel: 'Address',
      formType: 'single',
      formValue: false
    },
    {
      id: 4,
      formLabel: 'Project',
      formType: 'single',
      formValue: false
    },
    {
      id: 5,
      formLabel: 'Subject',
      formType: 'single',
      formValue: false
    }
    // {
    //   id: 6,
    //   formLabel: 'Custom Field',
    //   formType: 'single',
    //   formValue: false
    // }
  ];

  return {
    isOpen,
    hiddenFileInput,
    isOpenSaveTemplate,
    submitting,
    handleImageUpload,
    handleSelectedField,
    handleSaveTemplateNameChange,
    handleRemove,
    handleConfirm,
    handleSaveTemplateConfirm,
    handleClose,
    handleClick,
    handleSaveTemplateClick,
    handleImageChange,
    meetingNotesChooseFields,
    formik,
    confirmField,
    templateName,
    selectedField,
    saveTemplateToggle,
    toggle
  };
}

export default useCreateMeetingNoteTemplate;
