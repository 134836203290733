import React from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { Label } from "../../styles/Label";
import TextError from "./TextError";
import { TextArea } from "../../styles/Inputs";

// interface TextAreaProps {
//   label?: string;
//   id?: string;
//   name: string
// }

export const TextAreaField = (props: any) => {
  const { label, name, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <>
          <Label htmlFor={name}>{label}</Label>
          <TextArea
            id={name}
            {...rest}
            {...field}
            error={form.touched[name] && form.errors[name]}
          ></TextArea>
          {form.errors[name] && form.touched[name] ? (
            <ErrorMessage component={TextError} name={name} />
          ) : null}
        </>
      )}
    </Field>
  );
};
