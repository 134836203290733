import styled from "@emotion/styled";

export const PageNavStyles = styled.div`
  height: 2.68rem;
  a {
    text-decoration: none;
    color: var(--black);
    /* border: 1px solid red; */
    padding: 11px 50px;
  }
  display: flex;
  text-decoration: none;
  border-bottom: 2px solid var(--table-border);
  align-items: center;
  .active {
    border-bottom: 5px solid var(--text-secondary);
  }
`;
