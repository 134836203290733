import styled from '@emotion/styled';

import EditorComponent from '../Editor/Editor';
import RightSideBar from '../MeetingNotes/RightSideBar';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const MainContainer = styled.div`
  /* width: 100%; */
  min-height: 100%;
  background: #f6f5f5;
  height: 100vh;
  /* margin-top: 7px; */
  display: flex;
  flex-wrap: nowrap;

  overflow-y: auto;
  /*  */
`;

const LeftContainer = styled.div`
  width: 75%;
  padding: 10px 24px;

  /* overflow-y: auto; */
`;
const RightContainer = styled.div`
  background-color: #ffffff;
  height: 600px;
  width: 25%;
`;

interface Props {
  editable?: boolean;
  handleCreateTask: (title: string, blockId: string) => void;
  tasks: any[];
  saveAsDraft: (noteId: string, showLoading?: boolean) => void;
}

const ProjectEditor = ({ handleCreateTask, tasks, saveAsDraft }: Props) => {
  // @ts-ignore
  const { sections, editingTable,projectNoteData } = useSelector((state) => state.projectNote);
  const { noteId } = useParams();

  return (
    <MainContainer>
      <LeftContainer>
        {/* <ProjectEditorItem handleCreateTask={handleCreateTask} /> */}
        <EditorComponent
          editorType="PROJECT_NOTE"
          handleCreateTask={handleCreateTask}
          sections={sections}
          editingTable={editingTable}
          noteData={projectNoteData}
        />
      </LeftContainer>
      <RightContainer>
        <RightSideBar
          tasks={tasks}
          saveNoteAsDraft={saveAsDraft}
          entityType="WORKSPACE_NOTE"
          entityId={noteId as string}
        />
      </RightContainer>
    </MainContainer>
  );
};

export default ProjectEditor;
