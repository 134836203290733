import styled from "@emotion/styled";
import { EmptyDataContainer, ProjectsContainer } from "../../pages/Projects/ProjectViews/Projects";
import DocumentList from "../DocumentList";



const Container = styled.div`
  /* border: 1px solid red; */
  /* padding-top: 20px; */
  padding-left: 37px;
  padding-right: 49px;
  margin-top: 1.7rem;
`;

function ViewCollectionFileList({
  data,
  icon,
  CollectionData,
}: {
  data: any;
  icon: any;
  CollectionData: any;
}) {
  const alterData = data.filter((list) => {
    return CollectionData?.includes(list?._id)
  });
  return (
    alterData && alterData.length === 0 ? (
      <ProjectsContainer>
        <div className="empty-data">
          <EmptyDataContainer>
            No Files in the collection.
          </EmptyDataContainer>
        </div>
      </ProjectsContainer>
    ) : (
      <Container>
        <DocumentList data={alterData} icons={icon} navlink={"/files/"} />
      </Container>)
  );
}

export default ViewCollectionFileList;
