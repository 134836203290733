import React from "react";
import Select from "react-select";
import styled from "@emotion/styled";

interface InputFieldProps {
  name?: string;
  isDisabled?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  onChange?: any;
  onBlur?: any;
  error?: any;
  touched?: any;
  options?: any;
  value?: any;
  defaultValue?: any;
  noOptionsMessage?: string;
  menuPortalTarget?: any;
  components?: any;
  styles?: any;
}

const Container = styled.div`
  .input-feedback {
    padding-top: 10px;
    color: #ff0000;
  }
`;

const ReactSelect: React.FC<InputFieldProps> = (props) => {
  const {
    name,
    placeholder,
    onChange,
    onBlur,
    error,
    touched,
    options,
    value,
    defaultValue,
    isMulti,
    isClearable,
    isSearchable,
    isDisabled,
    noOptionsMessage,
    menuPortalTarget,
    components,
    styles,
  } = props;
  return (
    <Container>
      <div
        style={{ margin: "1rem 0" }}
        error={`error ${
          error && error.split("_")[0] === "invalid" ? "invalid" : ""
        } ${!!error && touched ? "active" : "inactive"}`}
      >
        <Select
          id={name}
          options={options}
          onChange={(value: any) => {
            onChange(value);
          }}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          name={name}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          isMulti={isMulti}
          autoBlur
          isDisabled={isDisabled}
          className="react-select-container"
          classNamePrefix="react-select"
          menuPortalTarget={menuPortalTarget}
          components={components}
          styles={styles}
        />
        {/* {error ? <div className="input-feedback">{error}</div> : null}  */}
        {!!error && touched && <div className="input-feedback">{error}</div>}
      </div>
    </Container>
  );
};

export default ReactSelect;
