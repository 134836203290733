import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { sentryError } from '../../../lib/SentryError';
import { ILatestInvoice } from '../../../pages/Payments/MonthlySubscriptionPage';

function useGetLatestInvoice(subscriptionId: string) {
  const [latestInvoice, setLatestInvoice] = useState<ILatestInvoice>();
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const { state } = useContext(AppContext);
  useEffect(() => {
    const getLatestInvoice = async () => {
      if (subscriptionId) {
        setInvoiceLoading(true);
        axiosInstance
          .get(`/payment/get-invoices?organizationId=${state.orgId}&subscriptionId=${subscriptionId}`)
          .then((res) => {
            let latestInvoice = res.data?.invoices.length && res.data.invoices[0];
            let obj: ILatestInvoice = {
              created: latestInvoice?.created,
              invoicePdf: latestInvoice?.invoice_pdf,
              amountDue: latestInvoice?.amount_due,
              accountName: latestInvoice?.account_name,
              status: latestInvoice?.status
            };
            setLatestInvoice(obj);
          })
          .catch((error) => {
            sentryError(error);
          })
          .finally(() => {
            setInvoiceLoading(false);
          });
      }
    };
    getLatestInvoice();
  }, [subscriptionId]);

  return {
    latestInvoice,
    invoiceLoading
  };
}

export default useGetLatestInvoice;
