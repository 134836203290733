import * as React from 'react';
import { useRowSelect, useTable } from 'react-table';
import styled from '@emotion/styled';

const TableStyles = styled.div`
  /* display: block;
  max-width: 100%; */
  /* overflow-x: scroll; */

  table {
    width: 100%;
    margin-left: 8px;
    border-spacing: 0;
    tr {
      transform: translateY(0);
      th {
        padding-bottom: 11px;
        padding-left: 20px;
        text-align: left;
        color: #93979f;
        font-weight: 600;
        border-bottom: 1px solid var(--table-border);
      }
      th::first-of-type {
        width: 10px;
      }
      td {
        text-align: left;
        border-bottom: 1px solid var(--table-border);
        padding: 5px 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #1c1c1c;
        overflow: hidden;
      }
      .text-wrap {
        /* white-space: normal; */
        /* wrap: nowrap; */
      }
    }
    tbody {
      tr:hover {
        cursor: pointer;
        /* transform: translateY(-10px); */

        border-radius: 5px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
        transition: 0.3s;
        td {
          /* text-align: center;
          vertical-align: middle; */
          /* border-top: 2px solid #E5E5E5; */
          /* border-bottom: 2px solid #E5E5E5; */
          /* border-style: none !important; */
        }
        td:first-of-type {
          border-left: 2px solid #e5e5e5;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        td:last-child {
          border-right: 2px solid #e5e5e5;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      td:hover .new-tooltip {
        visibility: visible;
      }
      .new-tooltip {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        bottom: 70%;
        margin-left: -10px;
        cursor: pointer;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  // console.log("ref", resolvedRef)
  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

function AwaitingTable({
  columns,
  data,
  clickHandler,
  onRowSelectStateChange,
  getCellProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  setRow,
  isSuperAdmin
}: {
  columns?: any;
  data?: any;
  clickHandler?: (id: string) => void;
  onRowSelectStateChange: any;
  getCellProps?: any;
  getRowProps?: any;
  setRow: any;
  isSuperAdmin: boolean;
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      onRowSelectStateChange
    },
    useRowSelect,
    (hooks) => {
      isSuperAdmin &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>{isSuperAdmin ? <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> : null}</>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <>{isSuperAdmin ? <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /> : null}</>
            )
          },
          ...columns
        ]);
    }
  );

  // React.useEffect(() => {
  //   setRow.current = selectedFlatRows;
  // }, [selectedFlatRows]);

  React.useEffect(
    () => onRowSelectStateChange?.(selectedFlatRows),
    [onRowSelectStateChange, selectedRowIds, selectedFlatRows]
  );

  //TODO: remove this
  const doNothing = () => {};

  return (
    <TableStyles>
      <table className="tasks-table" {...getTableProps()}>
        {columns.length > 0 && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(getRowProps(row))}
                onClick={() => (clickHandler ? clickHandler(row.original?._id) : doNothing())}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      // style={{ ...cell.getCellProps().style }}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style
                        },
                        getCellProps(cell)
                      ])}
                    >
                      {cell.render('Cell')}
                      {cell.column.tooltip ? <span className="new-tooltip">{cell.render('Cell')}</span> : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableStyles>
  );
}

export default AwaitingTable;
