import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from '../apollo';
import axiosInstance from '../axiosInstance';
import { GET_SINGLE_PROJECT_NOTE_QUERY } from '../graphql/operations/Queries/ProjectNotes/ProjectNoteQueries';
import { EditingTable, EditorI, SectionI } from '../pages/MeetingNotes/types';
import { createNewSection } from './meetingNote';

export interface ProjectNoteI {
  content: SectionI[];
  noteType_typeId: string;
  organizationId_projectId_workspaceId: string;
  _id: string;
  createdBy: string;
  createdAt: string;
  title: string;
  recordStatus: number;
  updatedAt: string;
  organizationId: string;
  projectId: string;
  workspaceId: string;
  isDraft: boolean;
}

export const fetchProjectNote = createAsyncThunk('projectNote/fetchMeetingNote', async (noteId: string) => {
  const data = await client.query({
    query: GET_SINGLE_PROJECT_NOTE_QUERY,
    variables: { id: noteId },
    fetchPolicy: 'network-only'
  });

  let response = { ...data?.data?.get_NoteAndDisscussion };
  if (response.content === '') response.content = [];
  else response.content = JSON.parse(response.content);

  const editingStatus = await axiosInstance.get(`/notes/status?noteId=${noteId}`);

  return { data: response as ProjectNoteI, editingStatus: editingStatus?.data };
});

interface InitialStateI {
  loading: boolean;
  error: string;
  projectNoteData: ProjectNoteI;
  sections: SectionI[];
  editingTable: EditingTable;
}

//Editing table keeps track of current edited section
//Using same type as meeting note for EditingTable, but we store sectionId as key here
const initialState: InitialStateI = {
  loading: false,
  error: '',
  projectNoteData: {} as ProjectNoteI,
  sections: [],
  editingTable: {} as EditingTable
};

const projectNoteSlice = createSlice({
  name: 'projectNote',
  initialState,
  reducers: {
    addSection: (state) => {
      const section = createNewSection(state.sections.length + 1);
      state.sections.push(section);
    },
    deleteSection: (state, action) => {
      state.sections = state.sections.filter((section) => section.sectionId !== action.payload);
    },
    updateBlockContent: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string; blockContent: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      const blockIndex = state?.sections[sectionIndex]?.blockData?.findIndex((x) => x.blockId === data.blockId);
      state.sections[sectionIndex].blockData[blockIndex].blockContent = data.blockContent;
    },
    updateSectionHeader: (state, action) => {
      const data = action.payload as { sectionId: string; header: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      state.sections[sectionIndex].sectionHeader = data.header;
    },
    updateLastUpdateDate: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string; newDate: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      const blockIndex = state.sections[sectionIndex].blockData.findIndex((x) => x.blockId === data.blockId);
      state.sections[sectionIndex].blockData[blockIndex].blockDate = data.newDate;
    },
    replaceContent: (state, action) => {
      const { content, _id: noteId } = action.payload;

      if (noteId !== state.projectNoteData._id) return;

      state.sections = JSON.parse(content);
    },
    addToEditorTable: (state, action) => {
      //If project note not loaded
      if (
        state.projectNoteData &&
        Object.keys(state.projectNoteData).length === 0 &&
        Object.getPrototypeOf(state.projectNoteData) === Object.prototype
      )
        return;

      if (action.payload.noteId !== state.projectNoteData._id) return;

      if (!state.editingTable.hasOwnProperty(action.payload.blockId)) {
        state.editingTable[action.payload.blockId] = {
          editorDetails: action.payload.editedByData,
          editingStatus: true,
          editingBy: action.payload.editingBy
        };
      }
    },
    removeFromEditorTable: (state, action) => {
      //If project note not loaded
      if (
        state.projectNoteData &&
        Object.keys(state.projectNoteData).length === 0 &&
        Object.getPrototypeOf(state.projectNoteData) === Object.prototype
      )
        return;

      if (action.payload.noteId !== state.projectNoteData._id) return;

      if (state.editingTable.hasOwnProperty(action.payload.blockId)) {
        delete state.editingTable[action.payload.blockId];
      }
    },
    resetNote: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchProjectNote.fulfilled, (state, action) => {
        state.projectNoteData = action.payload.data;

        //Populate the editing status table
        action.payload.editingStatus.map((status: { blockId: string; editingBy: string; editingByData: EditorI }) => {
          if (!state.editingTable.hasOwnProperty(status.blockId))
            state.editingTable[status.blockId] = {
              editingStatus: true,
              editorDetails: status.editingByData,
              editingBy: status.editingBy
            };
        });

        state.loading = false;
        state.sections = action.payload?.data.content;
        if (state.sections.length === 0) {
          const section = createNewSection(state.sections.length + 1);
          state.sections.push(section);
        }
        state.error = '';
      })
      .addCase(fetchProjectNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ? action.error.message : 'Error';
      });
  }
});

export default projectNoteSlice;
export const {
  addSection,
  updateBlockContent,
  updateLastUpdateDate,
  updateSectionHeader,
  replaceContent,
  addToEditorTable,
  removeFromEditorTable,
  resetNote,
  deleteSection
} = projectNoteSlice.actions;
