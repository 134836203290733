import styled from '@emotion/styled';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/IconSearchShat.svg';
import { ReactComponent as NoUsersIllustration } from '../../../../assets/illustration/noOrgChat.svg';
import { ModalText } from '../../../../pages/Workspaces/Registers/CreateNewRegister';
import { appendConversation, createConversation, switchChat } from '../../../../reducers/conversations_new';
import Checkbox from '../../../Checkbox';
import Button from '../../../CustomButton';
import Modal from '../../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../styles/ModalStyles';

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  users: any;
  groupName: string;
  setGroupName: (name: string) => void;
}

const SelectMembersModal = ({ isOpen, toggleModal, users, groupName, setGroupName }: Props) => {
  const { state } = useContext(AppContext);
  const [selected, setSelected] = useState<string[]>([]);

  const [userDetails, setUserDetails] = useState(users);
  const dispatch = useDispatch();

  const handleSearchChange = (e: any) => {
    let enteredText = e.target.value;
    let filteredUsers = users.filter((user: any) =>
      user?.name?.toLowerCase().includes(enteredText?.toLowerCase() || '')
    );
    setUserDetails(filteredUsers);
  };

  const handleOnChange = (userId: string) => {
    if (selected.includes(userId)) {
      setSelected(selected.filter((id) => id !== userId));
    } else {
      setSelected([...selected, userId]);
    }
  };

  const handleCreateGroup = async () => {
    if (selected.length === 0) {
      toast.error('Please select atleast one user');
      return;
    }
    const id = toast.loading('Creating the conversation');

    try {
      const data = await createConversation({
        id: uuidv4(),
        groupName: groupName,
        members: selected,
        conversationType: 'group'
      });
      dispatch(appendConversation(data));
      dispatch(switchChat(data));
      toast.success('Conversation created successfully', { id });
      setSelected([]);
      setGroupName('');
      toggleModal();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id });
    }
  };

  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="400px" style={{ minHeight: '350px' }}>
        <ModalTitle>Group Members</ModalTitle>
        <ModalBody>
          <ModalText>Add members to group</ModalText>
          <AddMembers>
            <SearchInput>
              <SearchIcon className="icon" />
              <input onChange={(e) => handleSearchChange(e)} type="text" placeholder="Search Members" />
            </SearchInput>
            <div className="members">
              {userDetails.length === 0 ? (
                <IllustrationDiv>
                  <NoUsersIllustration />
                  <span>No users found</span>
                </IllustrationDiv>
              ) : (
                userDetails?.map((item: any) =>
                  item.id !== state?.userId ? (
                    <label key={item.id}>
                      <Checkbox checked={selected.includes(item.id)} onChange={() => handleOnChange(item.id)} />
                      <p className="chatItemDP">
                        {item.picture ? <img src={item.picture} alt="Chat Dp" /> : <span>{item.name.charAt(0)}</span>}
                      </p>
                      <p>{item.name}</p>
                    </label>
                  ) : null
                )
              )}
            </div>
          </AddMembers>
        </ModalBody>
        <ModalWrapper>
          <Button onClick={toggleModal} secondary>
            Cancel
          </Button>
          <Button onClick={handleCreateGroup}>Create Group</Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default SelectMembersModal;

export const AddMembers = styled.div`
  font-size: 16px;
  /* padding: 5px 10px; */
  .members {
    margin-top: 15px;
    padding: 5px 20px;
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 15px 0;
      border-bottom: solid 1px #e5e5e5;

      &:hover {
        background: #f6f5f5;
      }
      p {
        margin: 0;
      }

      .chatItemDP {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        margin-right: 10px;
        img {
          height: 30px;
          width: 30px;
          border-radius: 20px;
        }
        span {
          height: 30px;
          width: 30px;
          background: #c37f0f;
          border-radius: 20px;
          color: #fff;
          display: flex;
          font-size: 16px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;
export const SearchInput = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    position: absolute;
    left: 30px;
  }
  input {
    /* padding-left: 35px; */
    width: 300px;
    height: 40px;
    padding-left: 35px;
    border: 1px solid #acadac;
    border-radius: 5px;
    color: #93979f;
  }
`;

export const IllustrationDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
