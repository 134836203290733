import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import styled from '@emotion/styled';
import CustomAvatar from '../TableComponents/CustomAvatar';
import { ReactComponent as RedArrow } from '../../assets/icons/High.svg';
import { ReactComponent as YellowArrow } from '../../assets/icons/Medium.svg';
import { ReactComponent as BlueArrow } from '../../assets/icons/Low.svg';

type CardProps = {
  isDragging: boolean;
};

const TaskInformation = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  padding: 0 15px;
  min-height: 106px;
  max-width: 311px;
  background: ${({ isDragging }) => (isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white')};
  margin-top: 15px;
  /* font-weight: 400; */

  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12px;
    font-weight: 400px;
    color: #7d7d7d;
  }
  .priority {
    /* margin-right: 12px; */
    align-self: center;
    svg {
      width: 12px !important;
      height: 12px !important;
      margin-right: 12px;
      /* margin-top: 2px; */
    }
  }
`;

const TaskCard = ({ item, index }: { item: any; index: any }) => {
  return (
    <Draggable key={item._id} draggableId={item._id} index={index}>
      {(provided: DraggableProvided, snapshotDraggable: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}

        // style={getItemStyle(
        //   snapshot.isDragging,
        //   provided.draggableProps.style
        // )}
        >
          <TaskInformation isDragging={snapshotDraggable.isDragging}>
            <p>{item.title}</p>
            <div className="secondary-details">
              <div>
                <CustomAvatar name={item.assignedToDetails?.name} isTable={false} size={16} />
              </div>
              <p>
                <span className="priority">
                  {item.priority === 'high' ? (
                    <RedArrow />
                  ) : item.priority === 'medium' ? (
                    <YellowArrow />
                  ) : (
                    <BlueArrow />
                  )}
                </span>
                <span>
                  {new Date(item.dueDate).toLocaleDateString('en-us', {
                    month: 'short',
                    day: '2-digit'
                  })}
                </span>
              </p>
            </div>
          </TaskInformation>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
