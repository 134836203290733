import styled from '@emotion/styled';
import { ChangeEvent, KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../AppContext';
import LiveConnectionContext from '../contexts/LiveConnectionContext';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDiscussionMessages } from '../reducers/discussion';
import Loader from './Loader';
import { ReactComponent as EmptyChat } from '../../src/assets/illustration/emptychat.svg';
import Avatar from 'react-avatar';
import { AlignmentProps } from './ItemRevisionComponents/ItemRevisionCards';
import ScrollToBottom from 'react-scroll-to-bottom';

interface Props {
  entityId: string;
  entityType: 'MEETING_NOTES' | 'WORKSPACE_NOTE' | 'TASK' | 'register';
  registerId?: string;
}

const GetUserDataById = (userId: string, users: any[]) => {
  let result = users.find((user) => {
    return user.id === userId;
  });
  return result;
};

export default function NewDiscussion({ entityId, entityType, registerId }: Props) {
  const [inputText, setInputText] = useState('');
  const { state } = useContext(AppContext);
  const ws = useContext(LiveConnectionContext);

  const bottomRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDiscussionMessages(entityId));
  }, [entityId]);
  const listMessages = useSelector((state: any) => state.discussion.discussionMessageData);
  const users = useSelector((state: any) => state.orgMembers.users);
  const messagesLoading = useSelector((state: any) => state.discussion.loading);
  const MessageData = listMessages.map((data: any) => {
    let GetUserData = GetUserDataById(data.sentBy, users);
    let addUserData = { ...data, ...GetUserData, createdAt: data.createdAt, id: data.id };

    return addUserData;
  });

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [listMessages]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      sendMessage(inputText);
      setInputText('');
      return;
    } else return;
  };

  const sendMessage = (message: string) => {
    let messageProperty = {
      token: state.authToken,
      message,
      entityType: entityType,
      messageType: 'text',
      type: 'DISCUSSION',
      entityId: entityId,
      sentBy: state?.userId,
      orgId: state?.orgId,
      registerId: registerId || 'null'
    };
    //@ts-ignore
    ws.send(JSON.stringify(messageProperty));
  };

  return (
    <>
      <DiscussionStyles>
        <ScrollToBottom className="messages-container">
          {messagesLoading ? (
            <Loader />
          ) : MessageData.length === 0 ? (
            <div className="emptyChat">
              <EmptyChat />
            </div>
          ) : (
            MessageData.map((data: any) => {
              return (
                <>
                  <div className="messageContainer">
                    {data.sentBy !== state.userId ? (
                      <Avatar round={true} size="25" name={data.fullName} textSizeRatio={1} />
                    ) : (
                      ''
                    )}
                    <div className={`${data.sentBy === state.userId ? 'sent' : 'received'}`}>
                      {data.sentBy !== state.userId && <Name>{data.fullName}</Name>}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data.message
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <DateTimeStyles alignment={`${data.sentBy === state.userId ? 'right' : 'left'}`}>
                      {new Date(data.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                      ,<span> </span>
                      {new Date(data.createdAt).toLocaleTimeString('En-US', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </DateTimeStyles>
                  </div>
                </>
              );
            })
          )}
          <div ref={bottomRef} />
        </ScrollToBottom>
        <div className="input-container">
          <input
            type=""
            className="textarea"
            placeholder="Type in your message"
            value={inputText}
            onChange={(e) => handleInputChange(e)}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </div>
      </DiscussionStyles>
    </>
  );
}

const DiscussionStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f6f5f5;
  overflow: hidden;

  .messages-container {
    flex: 1;
    overflow-y: auto;
  }

  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    float: 'bottom';
    height: 50px;
    background-color: #ffffff;
  }

  .textarea {
    width: 100%;
    resize: none;
    height: 35px;
    padding: 5px 10px;
    opacity: 0.5;
    background: #f6f5f5;
    border: 1px solid #757575;
    border-radius: 5px;
  }

  .messageContainer {
    display: flex;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .loader {
    height: 100%;
  }
  .emptyChat {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sent {
    max-width: 65%;
    width: fit-content;
    background: #f6ecdb;
    margin-left: auto;
    margin-right: 10px;
    text-align: right;
    border-radius: 5px;
    padding: 5px;
    p {
      margin: 0;
      padding: 2px;
      font-size: 14px;
      color: #1c1c1c;
    }
  }
  .received {
    max-width: 65%;
    width: fit-content;
    background: #fff;
    margin-left: 10px;
    margin-right: auto;
    text-align: left;
    border-radius: 5px;
    padding: 5px;
    p {
      margin: 0;
      padding: 2px;
      font-size: 14px;
      color: #1c1c1c;
    }
  }
`;

const Name = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 3px;
  /* color: red; */
`;
const DateTimeStyles = styled.div<AlignmentProps>`
  ${(props) =>
    props.alignment === 'right' &&
    ` 
    max-width: 65%;
    margin-left: auto;
    margin-right: 35px;
    text-align: right;       
    width: fit-content;
    font-size: 0.7rem;
    color: #7B7B7B;
    margin-top:5px;
    p
    {
      margin:0;
      padding: 2px;
      font-size: 8px;
      color: #1c1c1c;
    }`}
  ${(props) =>
    props.alignment === 'left' &&
    `max-width: 65%;
    width: fit-content;   
    margin-left: 55px;
    margin-top:5px;
    font-size: 0.7rem;
    margin-right: auto;
    color: #7B7B7B;
    text-align: left;
    // border-radius: 5px;
    // padding: 5px;
     p{
        margin:0;
        padding: 2px;
        font-size: 14px;
        color: #1c1c1c;
     }
  `}
`;
