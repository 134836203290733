import { Form, Formik } from 'formik';
import { CustomButton } from '../../components/CustomButton';
import { InputField } from '../../components/Reusable/NewFormik/Input1';
import logo from '../../assets/images/Platform Suite_Logo_White_LG.png';
import styled from '@emotion/styled';
import { AssigneeWrapper, FieldSet, InputWrapper, LinkWrapper } from '../../components/styles/FormStyles';
import { Toaster } from 'react-hot-toast';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../constants/LoaderDefaultOptions';
import { DarkBlueButton } from '../../components/styles/ButtonStyles';
import useLogin from '../../hooks/features/auth/useLogin';

const AuthWrapper = styled.div`
  display: flex;
`;
const Line = styled.div`
  width: 550px;
  display: block;
  background: #e9eaec;
  height: 1px;
  margin-bottom: 40px;
`;

const Links = styled.a`
  font-weight: 600;
  color: #4c6d66;
  cursor: pointer;
`;

const LeftLogoContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #26303e;
`;
const RightFormContainer = styled.div`
  width: 50%;
  height: 100vh;
  overflow: auto;
`;
const LogoImage = styled.img`
  transform: translate(0, -25%);
  width: 27rem;
  /* height:27rem; */
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const InputContainer = styled.div`
  width: 433px;
`;
const HeaderText = styled.div`
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
`;

export const CustomLoginForm = () => {
  const { initialValues, signIn, validationSchema, loading, location, goToForgotPasswordPage, goToSignUpPage } =
    useLogin();

  return !location.state ? (
    <div>
      <AuthWrapper>
        <Toaster />
        <LeftLogoContainer>
          <LogoImage src={logo} alt="logo" />
        </LeftLogoContainer>
        <RightFormContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={signIn}
            enableReinitialize={true}
            validateOnBlur={true}
          >
            {({ values, touched, errors, handleSubmit, handleBlur, handleChange }) => {
              return (
                <Form id="signin" onSubmit={handleSubmit}>
                  <FormContainer>
                    <FieldSet>
                      <InputContainer>
                        <InputWrapper>
                          <HeaderText>Login to Platformsuite</HeaderText>
                          <br />
                        </InputWrapper>

                        <InputWrapper>
                          <InputField
                            label="Email"
                            placeholder="Enter Email"
                            name="email"
                            id="Email"
                            onChange={handleChange}
                            touched={touched.email}
                            errors={errors.email}
                            value={values.email}
                            onBlur={handleBlur}
                            disabled={loading}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputField
                            label="Password"
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            id="password"
                            touched={touched.password}
                            errors={errors.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            disabled={loading}
                          />
                        </InputWrapper>
                        <AssigneeWrapper>
                          <CustomButton disabled={loading} style={{ height: 37, width: 102 }} type="submit">
                            {loading ? 'Loading' : 'Login'}
                          </CustomButton>
                          <LinkWrapper>
                            <Links onClick={() => !loading && goToForgotPasswordPage()}>
                              Having trouble logging in?
                            </Links>
                          </LinkWrapper>
                        </AssigneeWrapper>
                      </InputContainer>
                    </FieldSet>
                    <Line />
                    <LinkWrapper>
                      <Links>New to PlatformSuite?</Links>
                    </LinkWrapper>
                    <DarkBlueButton disabled={loading} onClick={() => goToSignUpPage()}>
                      Sign Up Here
                    </DarkBlueButton>
                  </FormContainer>
                </Form>
              );
            }}
          </Formik>
        </RightFormContainer>
      </AuthWrapper>
    </div>
  ) : (
    <Lottie options={defaultOptions} width={500} height={500} />
  );
};

export default CustomLoginForm;
