import { Header, HeaderLeft, NewTitleLeft } from '../../components/styles/Header';
import styled from '@emotion/styled';
import Button from '../../components/CustomButton';
import _ from 'lodash';
import { ReactComponent as Ellipsis } from '../../assets/icons/IconThreeDots.svg';
import TableDropDown from '../../components/TableComponents/TableDropDown';
import { ErrorPage } from '../Others/ErrorPage';
import Loader from '../../components/Loader';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import useGetOrganization from '../../hooks/features/organization/useGetOrganization';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '../../lib/UseToggle';
import { UpgradeOrganisationPlanModal } from '../../components/Modals/UpgradeOrganisationPlanModal';
import RemoveSeatModal from '../../components/Organisation/Modals/RemoveSeatModal';
import useUpdateSeats from '../../hooks/features/payments/useUpdateSeats';
import AssignSeatModal from '../../components/Organisation/Modals/AssignSeatModal';
import AssignSeatsForAllModal from '../../components/Organisation/Modals/AssignSeatsForAllModal';
import CustomTableDropDown from '../../components/TableComponents/CustomTableDropDown';
import AwaitingTable from '../../components/Organisation/AwaitingTable';
import { useEffect, useRef } from 'react';
import AssignSeatsForSelectedUsersModal from '../../components/Organisation/Modals/AssignSeatsForSelectedUsersModal';
import OrganizationDetails from '../../components/Organisation/Placeholders/OrganizationDetails';
import NoCurrentOrgUsers from '../../components/Organisation/Placeholders/NoCurrentOrgUsers';
import NoAwaitingUsers from '../../components/Organisation/Placeholders/NoAwaitingUsers';
import NoPendingUsers from '../../components/Organisation/Placeholders/NoPendingUsers';
import { usePopperTooltip } from 'react-popper-tooltip';
import { ChoosePlanModal } from '../../components/Organisation/Modals/ChoosePlanModal';
import ActiveAndInvitedMembersTable from '../../components/Organisation/Tables/ActiveAndInvitedMembersTable';
import TrialEnd from '../../components/Organisation/Placeholders/TrialEnd';
import TrialRemaining from '../../components/Organisation/Placeholders/TrialRemaining';
import { useStripe } from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import SubscriptionIsActiveModal from '../../components/Organisation/Modals/SubscriptionIsActiveModal';
import InviteMembersToOrganizationModal from '../../components/Organisation/Modals/InviteMembersToOrganizationModal';
import 'react-popper-tooltip/dist/styles.css';
import SubscriptionCancelled from '../../components/Organisation/Placeholders/SubscriptionCancelled';

export type PlanType = 'month' | 'year';
export type SeatType = 'MONTHLY' | 'YEARLY' | 'TRIAL';

const PageTitle = styled.span`
  font-size: 1.5rem;
  font-weight: 600 !important;
  margin: 0px;
  display: flex;
  align-items: center;
  .avatar-name {
    height: 40px;
    width: 40px;
    background: #ff6b00;
    border-radius: 50%;
    font-size: 18px;
    margin-right: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .org-pic {
    max-height: 80%;
    max-width: 80%;
    border-radius: 50%;
    font-size: 18px;
    margin-right: 20px;
    /* color: #fff; */
  /* display: flex;
    align-items: center;
    justify-content: center; */
  /* } */

  img {
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    object-fit: cover;
  }
`;

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;

  .icon-button {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    :hover {
      background-color: #f0f0f0;
      cursor: pointer;
    }
  }
`;

const OrganizationList = styled.div`
  padding-top: 25px;
  margin: 20px;
  margin-right: 55px;
  tr {
    height: 49px;
    td {
      /* font-weight: 600 !important; */
    }
  }
  .divider {
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .table-heading {
    margin-left: 3.5rem;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid black;
  }
`;

const AwaitingList = styled.div`
  padding-top: 25px;
  margin: 20px;
  margin-right: 55px;
  .divider {
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .pending-table-heading {
    margin-left: 3.5rem;
    font-weight: bold;
  }
`;

const AwaitingUserCount = styled.span`
  background-color: hsla(167, 18%, 36%, 1);
  color: white;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  margin-left: 1rem;
  width: 25px;
`;

const PendingList = styled.div`
  padding-top: 25px;
  margin: 20px;
  margin-right: 55px;
  tr {
    height: 39px;
    td {
      /* font-weight: 600 !important; */
    }
  }
  .divider {
    svg {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .pending-table-heading {
    margin-left: 3.5rem;
    font-weight: bold;
  }
`;

export const TooltipStyles = styled.div`
  border-radius: 5px;
  width: 250px;
  color: grey;
  font-size: 14px;
`;

const ViewOrganization = () => {
  let navigate = useNavigate();
  const stripe = useStripe();
  const [seatQuantityModal, toggleSeatQuantityModal] = useToggle(false);
  const {
    loading,
    error,
    initialvalues,
    state,
    getOrganization,
    isOpen,
    handleSubmit,
    validationSchema,
    editOrganization,
    toggle,
    isSuperAdmin,
    viewOrganization,
    columns,
    data,
    pendingColumns,
    pendingData,
    isSubmitting,
    orgUsers,
    userDetails,
    upgradePlan,
    disableUpgradePlanButton,
    awaitingUsersData,
    isRemoveSeatModalOpen,
    removeSeatFromUser,
    toggleRemoveSeatModal,
    handleRemoveSeatFromUser,
    seatAssigningTo,
    assignSeatToUser,
    awaitingUsersColumns,
    cancelAssigningSeat,
    isAssignSeatModalOpen,
    isAssignSeatsForAllModalOpen,
    toggleAssignSeatsForAllModal,
    submittingAssignSeatData,
    assignSeatToAllUsers,
    isInsufficientSeats,
    cancelAssigningSeatToAllUsers,
    submittingAssignSeatToAll,
    setSelectedRowIds,
    assignSeatsToSelectedUsers,
    isAssignSeatToSelectedUsersModalOpen,
    submittingAssignSeatToSelectedData,
    toggleAssignSeatToSelectedUsersModal,
    selectedRowIds,
    disableRemoveSeatButton,
    monthlySeats,
    totalSeats,
    yearlySeats,
    setShowConfirmForAssigningSeat,
    showConfirmForAssigningSeat,
    cancelAtPeriodEnd
  } = useGetOrganization();

  const rowRef = useRef();
  const { stripeLoading, updateSeats } = useUpdateSeats();
  const [isSubsriptionIsActiveModalOpen, toggleSubscriptionIsActiveModal] = useToggle(false);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  const [isChoosePlanModalOpen, toggleChoosePlanModal] = useToggle(false);
  const handleNavigate = () => {
    navigate('/projects');
  };
  const goToChooseSeatsPage = (plan: PlanType) => {
    navigate(`seatselection`, {
      state: { plan, awaiting: awaitingUsersData.length, seatvalue: plan === 'month' ? monthlySeats : yearlySeats }
    });
  };
  const goToManageSubscriptionsPage = () => {
    navigate(`managesubscription`);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          toast.success('Payment succeeded!');
          navigate(`/organization/${userDetails.orgId}`);
          toggleSubscriptionIsActiveModal();
          break;
        case 'processing':
          toast.loading('Your payment is processing.');
          break;
        case 'requires_payment_method':
          toast.error('Your payment was not successful, please try again.');
          break;
        default:
          toast.error('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  if (loading) return <Loader />;
  if (error) return <ErrorPage />;

  const subscriptionType = getOrganization?.get_Organization?.subscriptionType;

  //this is a component that is used inside the custom table dropdown compoenent.
  const TableButtons = () => {
    return (
      <>
        {awaitingUsersData.length > 0 && isSuperAdmin ? (
          <>
            {selectedRowIds.length > 0 ? (
              <Button onClick={toggleAssignSeatToSelectedUsersModal} secondary>
                Assign Seats for Selected
              </Button>
            ) : null}
            <Button onClick={toggleAssignSeatsForAllModal}>Assign Seats for All</Button>
          </>
        ) : null}
      </>
    );
  };

  //this is the main component markup.
  return (
    <>
      {_.isEmpty(getOrganization) ? (
        <Loader />
      ) : (
        <>
          <Header>
            <HeaderLeft>
              <BackIcon handlenavigate={handleNavigate} />
              <NewTitleLeft>
                <PageTitle>
                  {getOrganization?.get_Organization?.name ? getOrganization?.get_Organization?.name : null}
                </PageTitle>
              </NewTitleLeft>
            </HeaderLeft>
            <TitleRight>
              {/*convertToSeparateComponents*/}
              {isSuperAdmin && (
                <>
                  {subscriptionType === 'FREE' || subscriptionType === 'TRIAL_EXPIRED' ? (
                    <TrialRemaining endDate={getOrganization?.get_Organization?.subscriptionEndDate} />
                  ) : null}

                  {subscriptionType === 'MONTHLY' || subscriptionType === 'YEARLY' || subscriptionType === 'PAID' ? (
                    <>
                      <span ref={setTriggerRef}>
                        <Button
                          disabled={disableUpgradePlanButton}
                          type="button"
                          secondary={true}
                          onClick={() => goToManageSubscriptionsPage()}
                        >
                          Manage Subscription
                        </Button>
                      </span>
                      {visible && (
                        <TooltipStyles ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
                          Click to manage your billing settings and modify plans.
                          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                        </TooltipStyles>
                      )}
                    </>
                  ) : null}
                  {subscriptionType === 'FREE' || subscriptionType === 'TRIAL_EXPIRED' || subscriptionType === null ? (
                    <Button
                      disabled={disableUpgradePlanButton}
                      secondary={true}
                      onClick={() => toggleChoosePlanModal()}
                    >
                      Upgrade Plans
                    </Button>
                  ) : null}
                </>
              )}
              {isSuperAdmin ? (
                <Button onClick={editOrganization}>Edit Details</Button>
              ) : (
                <>
                  <div ref={setTriggerRef} className="icon-button">
                    <Ellipsis />
                  </div>
                  {visible && (
                    <TooltipStyles ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
                      Only Admins can edit the organization
                      <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                    </TooltipStyles>
                  )}
                </>
              )}
            </TitleRight>
          </Header>

          <OrganizationDetails
            getOrganization={getOrganization}
            orgUsers={orgUsers}
            state={state}
            userDetails={userDetails}
            totalSeats={totalSeats}
            monthlySeats={monthlySeats}
            yearlySeats={yearlySeats}
          />
          <div style={{ padding: '0 3rem', marginTop: '2rem' }}>
            <Header>
              <NewTitleLeft>
                <PageTitle>Organization Members</PageTitle>
              </NewTitleLeft>
              <TitleRight>
                {isSuperAdmin ? (
                  <>
                    {(subscriptionType === 'MONTHLY' ||
                      subscriptionType === 'PAID' ||
                      subscriptionType === 'YEARLY') && (
                      <Button secondary onClick={() => goToManageSubscriptionsPage()}>
                        Modify Seats Quantity
                      </Button>
                    )}
                    <Button disabled={isSubmitting} onClick={() => toggle()}>
                      + Add Members
                    </Button>
                  </>
                ) : (
                  <Button disabled> Add Members</Button>
                )}
              </TitleRight>
            </Header>
          </div>

          {getOrganization?.get_Organization?.subscriptionType === 'TRIAL_EXPIRED' ? (
            <TrialEnd disabledButtonState={disableUpgradePlanButton} upgradePlan={upgradePlan} />
          ) : null}

          {cancelAtPeriodEnd.monthly.isCancelled ? (
            <SubscriptionCancelled dueDate={cancelAtPeriodEnd.monthly.endDate} planType="monthly" />
          ) : null}
          {cancelAtPeriodEnd.yearly.isCancelled ? (
            <SubscriptionCancelled dueDate={cancelAtPeriodEnd.yearly.endDate} planType="yearly" />
          ) : null}

          <OrganizationList>
            <TableDropDown state={true} text="Active Members">
              {data?.length > 0 ? (
                <>
                  <ActiveAndInvitedMembersTable columns={columns} clickHandler={viewOrganization} data={data} />
                </>
              ) : (
                <NoCurrentOrgUsers toggle={toggle} />
              )}
            </TableDropDown>
          </OrganizationList>
          <AwaitingList>
            <CustomTableDropDown
              state={true}
              nav={TableButtons()}
              text="Awaiting for seat"
              icon={<AwaitingUserCount>{awaitingUsersData?.length}</AwaitingUserCount>}
            >
              {awaitingUsersData.length > 0 ? (
                <AwaitingTable
                  columns={awaitingUsersColumns}
                  data={awaitingUsersData}
                  setRow={rowRef}
                  onRowSelectStateChange={setSelectedRowIds}
                  isSuperAdmin={isSuperAdmin}
                ></AwaitingTable>
              ) : (
                <NoAwaitingUsers />
              )}
            </CustomTableDropDown>
          </AwaitingList>
          <PendingList>
            <TableDropDown state={true} text="Invited Members">
              {pendingData?.length > 0 ? (
                <>
                  <ActiveAndInvitedMembersTable
                    columns={pendingColumns}
                    clickHandler={viewOrganization}
                    data={pendingData}
                  />
                </>
              ) : (
                <NoPendingUsers />
              )}
            </TableDropDown>
          </PendingList>

          <InviteMembersToOrganizationModal
            isOpen={isOpen}
            toggle={toggle}
            validationSchema={validationSchema}
            initialvalues={initialvalues}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
          <AssignSeatModal
            seatAssigningTo={seatAssigningTo}
            isOpen={isAssignSeatModalOpen}
            onClose={cancelAssigningSeat}
            assignSeatToUser={assignSeatToUser}
            buttonState={submittingAssignSeatData}
            isInSufficient={isInsufficientSeats}
            updateSeats={goToManageSubscriptionsPage}
            currentUsers={state.currentOrganizationUsers}
            monthlySeats={monthlySeats}
            yearlySeats={yearlySeats}
            totalSeats={totalSeats}
            showConfirm={showConfirmForAssigningSeat}
            setShowConfirm={setShowConfirmForAssigningSeat}
            subscriptionType={subscriptionType}
          />
          <AssignSeatsForAllModal
            isOpen={isAssignSeatsForAllModalOpen}
            onClose={cancelAssigningSeatToAllUsers}
            handleAssignSeatForAll={assignSeatToAllUsers}
            isInSufficient={isInsufficientSeats}
            updateSeats={goToManageSubscriptionsPage}
            buttonState={submittingAssignSeatToAll}
            stripeLoading={stripeLoading}
            currentUsers={state.currentOrganizationUsers}
            monthlySeats={monthlySeats}
            yearlySeats={yearlySeats}
            subscriptionType={subscriptionType}
          />
          <AssignSeatsForSelectedUsersModal
            isOpen={isAssignSeatToSelectedUsersModalOpen}
            onClose={toggleAssignSeatToSelectedUsersModal}
            isInSufficient={isInsufficientSeats}
            buttonState={submittingAssignSeatToSelectedData}
            handleAssignSeatsForSelected={assignSeatsToSelectedUsers}
            updateSeats={goToManageSubscriptionsPage}
            stripeLoading={stripeLoading}
            currentUsers={state.currentOrganizationUsers}
            monthlySeats={monthlySeats}
            yearlySeats={yearlySeats}
            subscriptionType={subscriptionType}
          />
          <UpgradeOrganisationPlanModal
            buttonState={stripeLoading}
            active={seatQuantityModal}
            onClose={toggleSeatQuantityModal}
            onSubmit={() => updateSeats(getOrganization?.get_Organization?._id)}
            numberOfSeats={awaitingUsersData.length}
          />
          <RemoveSeatModal
            user={removeSeatFromUser}
            isOpen={isRemoveSeatModalOpen}
            onClose={toggleRemoveSeatModal}
            onSubmit={() => handleRemoveSeatFromUser(removeSeatFromUser)}
            buttonState={disableRemoveSeatButton}
          />
          <ChoosePlanModal
            isOpen={isChoosePlanModalOpen}
            onClose={toggleChoosePlanModal}
            upgradePlan={goToChooseSeatsPage}
            buttonState={disableUpgradePlanButton}
          />
          <SubscriptionIsActiveModal
            isOpen={isSubsriptionIsActiveModalOpen}
            onClose={toggleSubscriptionIsActiveModal}
          />
        </>
      )}
    </>
  );
};

export default ViewOrganization;
