import styled from '@emotion/styled';
import React, { useRef } from 'react';
import { ReactComponent as Ellipsis } from '../assets/icons/IconThreeDots.svg';
import useOnClickOutside from '../lib/useClickOutside';
import { useToggle } from '../lib/UseToggle';

const Options = styled.div`
  .icon-button {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    width: 42px;
    height: 42px;
    :hover {
      background-color: #f0f0f0;
      cursor: pointer;
      filter: drop-shadow(0px 0px 10px #f0f0f0);
    }
  }
`;

const OptionsList = styled.div`
  /* top: 130px; */
  min-width: 90px;
  /* width: auto;   */
  transform: translateX(-80%);
  background-color: #ffffff;
  border: 2px solid #b4b7ba;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  position: absolute;
  /* z-index: 1000; */

  /* :after,
  :before{
    position: absolute;
    left: 88%;
    bottom: 60px; 
    width: 0;
    height: 0;
    content: '';
    border: solid transparent;
  } */
`;

// type ItemProps = {
//   disabled: boolean | undefined
// }

const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  color: #929292;
  padding-top: 2px;
  width: auto;
  white-space: nowrap;
  line-height: 1.5;
  transition: background 300ms;
  font-weight: 600;
  :hover {
    cursor: pointer;
    color: hsl(215, 24%, 20%);
  }
  :disabled {
    cursor: not-allowed;
  }
`;

// export const OptionsMenu = (props) => {
//   const ref = useRef();
//   return (
//     <OptionsList>
//       {props.children}
//     </OptionsList>
//   )
// }

export const OptionItem = (props) => {
  return (
    <Item disabled={props?.disabled} onClick={props.onClick}>
      {props.children}
    </Item>
  );
};

export const ToggleSettings = (props: any) => {
  const [open, setOpen] = useToggle(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setOpen(false));
  return (
    <Options>
      <div className="icon-button" onClick={() => setOpen(!open)}>
        {props.icon ? props.icon : <Ellipsis />}
      </div>
      {open && (
        <>
          <OptionsList style={props.style} ref={ref} onClick={() => setOpen(false)}>
            {props.children}
          </OptionsList>
        </>
      )}
    </Options>
  );
};
