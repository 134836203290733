import { gql } from '@apollo/client';

// to add task
//type = "workspace"
//typeId = "workspaceId"
//noteId = "meetingNoteId"
//assignee="state.userId"
//assigneeDetails = {
//   name:"state.fullName"
//   profilePicture:"state.profilePhoto"
// }
//recordStatus= 0

export const addTaskInMeetingNotesMutation = gql`
  mutation addTaskMutation(
    $assignee: String
    $assigneeDetails: Self_Task_assigneeDetails_Input_
    $createdAt: String
    $priority: String
    $projectId: String
    $organizationId_projectId_typeId: String
    $organizationId: String
    $recordStatus: Float
    $status: String
    $title: String
    $noteId: String
    $links: [String]
    $updatedAt: String
    $updatedBy: String
    $type: String
    $typeId: String
    $blockId: String
  ) @api(name: "task") {
    add_Task(
      syncMode: NODE_COMMITTED
      input: {
        assignee: $assignee
        assigneeDetails: $assigneeDetails
        createdAt: $createdAt
        priority: $priority
        projectId: $projectId
        organizationId_projectId_typeId: $organizationId_projectId_typeId
        organizationId: $organizationId
        recordStatus: $recordStatus
        status: $status
        title: $title
        noteId: $noteId
        updatedAt: $updatedAt
        updatedBy: $updatedBy
        links: $links
        type: $type
        typeId: $typeId
        blockId: $blockId
      }
    ) {
      result {
        _id
      }
    }
  }
`;

//update task
//assignedTo = "assignedToUserId"
//assignedToDetails = {
//   name:"assignedTo.fullName"
//   profilePicture:"assignedTo.profilePhoto"
// }
//
const updateMeetingNotesTaskMutation = gql`
  mutation updateTaskMutation(
    $id: ID!
    $assignedTo: String
    $description: String
    $assignedToDetails: Self_Task_assignedToDetails_UpdateInput_
    $dueDate: String
    $priority: String
    $recordStatus: Float
    $status: String
    $links: [String]
    $updatedAt: String
    $updatedBy: String
  ) @api(name: "task") {
    update_Task(
      id: $id
      syncMode: NODE_COMMITTED
      input: {
        assignedTo: $assignedTo
        assignedToDetails: $assignedToDetails
        description: $description
        dueDate: $dueDate
        priority: $priority
        recordStatus: $recordStatus
        status: $status
        links: $links
        updatedAt: $updatedAt
        updatedBy: $updatedBy
      }
    ) {
      result {
        _id
      }
    }
  }
`;

//list meetingNotes task
export const LIST_MEETING_NOTES_TASKS_QUERY = gql`
  query ListTaskQuery($organizationId_projectId_typeId: String, $noteId: String) @api(name: "task") {
    list_TaskItems(
      filter: {
        organizationId_projectId_typeId: { beginsWith: $organizationId_projectId_typeId }
        recordStatus: { eq: 0 }
        noteId: { eq: $noteId }
      }
    ) {
      _TaskItems {
        assignedTo
        assignedToDetails {
          name
          profilePicture
        }
        assignee
        assigneeDetails {
          name
          profilePicture
        }
        createdAt
        description
        dueDate
        _id
        noteId
        isAcknowledged
        priority
        projectId
        recordStatus
        status
        title
        links
        updatedAt
        updatedBy
        type
        typeId
      }
    }
  }
`;
