import styled from '@emotion/styled';
import React from 'react';

const DaysRemaining = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: rgba(30, 39, 54, 0.6);
`;

function TrialRemaining(props) {
  let currentDate = new Date();
  let differenceInTime = new Date(props.endDate).getTime() - currentDate.getTime();
  let differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return props.endDate ? <DaysRemaining> {parseInt(differenceInDays)} Days trial remaining</DaysRemaining> : null;
}

export default TrialRemaining;
