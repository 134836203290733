import { gql } from '@apollo/client';

export const createMeetingNoteMutation = gql`
  mutation exampleMutation(
    $createdBy: String
    $createdAt: String
    $isGlobal: Boolean
    $metaData: String
    $organizationId: String
    $organizationId_type: String
    $otherFields: String
    $recordStatus: Float
    $templateName: String
    $type: String
    $updateBy: String
    $updatedAt: String
  ) @api(name: "workspace") {
    add_Template(
      input: {
        createdAt: $createdAt
        createdBy: $createdBy
        isGlobal: $isGlobal
        metaData: $metaData
        otherFields: $otherFields
        organizationId_type: $organizationId_type
        organizationId: $organizationId
        recordStatus: $recordStatus
        templateName: $templateName
        type: $type
        updateBy: $updateBy
        updatedAt: $updatedAt
      }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const deleteNoteMutation = gql`
  mutation exampleMutation($id: ID!, $recordStatus: Float) @api(name: "workspace") {
    update_NoteAndDisscussion(id: $id, input: { recordStatus: $recordStatus }, syncMode: NODE_COMMITTED) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
