import CheckoutForm from '../../components/Payment/CheckoutForm';
import SubscriptionNotActive from '../../components/Payment/SubscriptionNotActive';
import UpdateCurrentPlanSeats from '../../components/Payment/UpdateCurrentPlanSeats';
import SeatSummary from './SeatSummary';
import LatestInvoice from '../../components/Payment/LatestInvoice';
import DisplayCurrentPlan from '../../components/Payment/DisplayCurrentPlan';
import SavedPaymentMethod from '../../components/Payment/SavedPaymentMethod';
import DisplayBillingInformation from '../../components/Payment/DisplayBillingInformation';
import useGetYearlySubscription from '../../hooks/features/payments/useGetYearlySubscription';
import DisplayProrateAmount from '../../components/Payment/DisplayProrateAmount';
import ConfirmPaymentModal from '../../components/Payment/ConfirmPaymentModal';
import Loader from '../../components/Loader';
import { ErrorPage } from '../Others';
import { ButtonGroup, FormDiv, PageWrapper } from './MonthlySubscriptionPage';
import { RightBox } from './UpdatePlan';
import { stripePromise } from '../../index';
import { Elements } from '@stripe/react-stripe-js';
import Button from '../../components/CustomButton';
import CancelSubscriptionModal from '../../components/Payment/CancelSubscriptionModal';
import ConfirmedCancellationModal from '../../components/Payment/ConfirmedCancellationModal';
import ReactivateSubscriptionModal from './ReactivateSubscriptionModal';

function YearlySubscriptionPage() {
  const {
    addYearlySubscription,
    calculatingProrate,
    cardLoading,
    changePaymentDetails,
    clientSecret,
    decreaseSeatQuantity,
    error,
    increaseSeatQuantity,
    invoiceLoading,
    isConfirmPaymentModalOpen,
    isYearlySubscriptionActive,
    latestCard,
    latestInvoice,
    loading,
    options,
    originalYearlyQuantity,
    pageMode,
    prorateAmount,
    reset,
    setPageMode,
    showPaymentConfirmationModal,
    state,
    takenYearlyUsers,
    toggleConfirmPaymentModal,
    upcomingDueDate,
    updateSubscription,
    updatingSubscription,
    yearlyQuantity,
    isCancelSubscriptionModalOpen,
    cancelSubscription,
    toggleCancelSubscriptionModal,
    cancellingSubscription,
    isConfirmedCancellationModalOpen,
    toggleConfirmedCancellationModal,
    goToOrganization,
    cancelAtPeriodEnd,
    isReactivateSubscriptionModalOpen,
    toggleReactivateSubscriptionModal,
    reactivatingSubscription,
    reactivateSubscription
  } = useGetYearlySubscription();

  if (loading || invoiceLoading || cardLoading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      {!isYearlySubscriptionActive && <SubscriptionNotActive plan="year" clickHandler={addYearlySubscription} />}

      {isYearlySubscriptionActive && (
        <PageWrapper>
          <div style={{ width: '45%' }}>
            {pageMode === 'update' && (
              <>
                <UpdateCurrentPlanSeats
                  currentQuantity={yearlyQuantity}
                  decreaseSeatQuantity={decreaseSeatQuantity}
                  increaseSeatQuantity={increaseSeatQuantity}
                  pageMode={pageMode}
                  plan="year"
                />
                <DisplayProrateAmount
                  calculatingProrate={calculatingProrate}
                  currentQuantity={yearlyQuantity}
                  originalQuantity={originalYearlyQuantity}
                  prorateAmount={prorateAmount}
                />
              </>
            )}

            {pageMode === 'default' && (
              <>
                <DisplayCurrentPlan plan="year" pageMode={pageMode} originalQuantity={originalYearlyQuantity} />
                <SeatSummary
                  originalQuantity={originalYearlyQuantity}
                  takenSeats={takenYearlyUsers.length}
                  plan="year"
                />
                <LatestInvoice latestInvoice={latestInvoice} plan="year" upcomingDueDate={upcomingDueDate} />
              </>
            )}
          </div>
          {!clientSecret && (
            <RightBox>
              <SavedPaymentMethod latestCard={latestCard} changePaymentDetails={changePaymentDetails} />
              <DisplayBillingInformation email={state.email} />
              <ButtonGroup>
                {pageMode === 'default' && (
                  <>
                    {cancelAtPeriodEnd ? (
                      <Button onClick={toggleReactivateSubscriptionModal}>Reactivate Plan</Button>
                    ) : (
                      <>
                        <Button onClick={() => setPageMode('update')}>Update Plan</Button>
                        <Button secondary onClick={() => toggleCancelSubscriptionModal()}>
                          Cancel Subscription
                        </Button>
                      </>
                    )}
                  </>
                )}
                {pageMode === 'update' && (
                  <>
                    <Button onClick={() => showPaymentConfirmationModal()}>Update Plan</Button>
                    <Button onClick={() => reset()} secondary>
                      Go Back
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </RightBox>
          )}
          {clientSecret && (
            <FormDiv>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </FormDiv>
          )}
        </PageWrapper>
      )}
      <ConfirmPaymentModal
        isOpen={isConfirmPaymentModalOpen}
        onClose={toggleConfirmPaymentModal}
        planType={'yearly'}
        totalQuantity={yearlyQuantity}
        dueDate={upcomingDueDate}
        originalQuantity={originalYearlyQuantity}
        prorateAmount={prorateAmount}
        latestCard={latestCard}
        cardBrand={latestCard?.card?.brand}
        confirmPayment={updateSubscription}
        loading={updatingSubscription}
      />
      <CancelSubscriptionModal
        isOpen={isCancelSubscriptionModalOpen}
        onClose={toggleCancelSubscriptionModal}
        planType="yearly"
        cancelSubscription={cancelSubscription}
        dueDate={upcomingDueDate}
        originalQuantity={originalYearlyQuantity}
        latestCard={latestCard}
        cardBrand={latestCard?.card?.brand}
        loading={cancellingSubscription}
      />
      <ConfirmedCancellationModal
        isOpen={isConfirmedCancellationModalOpen}
        onClose={toggleConfirmedCancellationModal}
        planType="yearly"
        dueDate={upcomingDueDate}
        goToOrganization={goToOrganization}
      />
      <ReactivateSubscriptionModal
        isOpen={isReactivateSubscriptionModalOpen}
        onClose={toggleReactivateSubscriptionModal}
        planType="yearly"
        dueDate={upcomingDueDate}
        originalQuantity={originalYearlyQuantity}
        reactivateSubscription={reactivateSubscription}
        reactivatingSubscription={reactivatingSubscription}
      />
    </>
  );
}

export default YearlySubscriptionPage;
