import React from 'react';
import styled from '@emotion/styled/macro';

interface ButtonProps {
  secondary?: boolean;
  clear?: boolean;
}

export const CustomButton = styled('button')<ButtonProps>`
  width: auto;
  height: 37px;
  letter-spacing: 0.5px;
  line-height: 21px;
  padding: 0 12px 0 12px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: 'Open Sans';

  background-color: ${(props) => (!props.secondary ? `var(--button-background)` : 'transparent')};
  color: ${(props) => (!props.secondary ? 'white' : `var(--button-background)`)};
  border: ${(props) => (props.secondary ? `1px solid var(--button-background)` : 'none')};
  display: ${(props) => (props.clear ? 'none' : false)};
  :hover {
    /* filter: drop-shadow(0px 0px 0px rgba(0, 178, 146, 0.26)); */
    background-color: ${(props) => (!props.secondary ? `var(--button-secondary)` : ``)};
    box-shadow: rgba(0, 178, 146, 0.26) 0 0 15px;
  }
  :disabled {
    cursor: not-allowed;
    background-color: #658a82;
  }
`;

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
  secondary?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  className?: string;
}

const Button = (props: Props) => {
  return (
    <CustomButton
      secondary={props.secondary}
      onClick={props.onClick}
      type={props.type || 'button'}
      disabled={props.disabled}
      className={props?.className}
      {...props}
    >
      {props.children}
    </CustomButton>
  );
};

export default Button;
