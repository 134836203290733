import styled from '@emotion/styled';
import React from 'react';

export const PageContainer = styled.div`
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  height: 100%;
`;
export const PageContentStyles = styled.div`
  height: fit-content;
  width: 75%;
  background: hsla(0, 0%, 100%, 1);
  border-radius: 20px;
  padding: 31px 68px 31px 68px;
  margin-top: 52px;
  margin-bottom: 52px;
`;
export const TextStyles = styled.p`
  font: 'Open-Sans';
  font-weight: 400;
  line-height: 21.79px;
`;
export const OrderedListStyles = styled.ol`
  padding-inline-start: 16px;
  li {
    margin-top: 1em;
    margin-bottom: 1em;
    font: 'Open-Sans';
    font-weight: 400 !important;
    line-height: 21.79px;
  }
`;
export const SuborderedListStyles = styled.ol`
  counter-reset: list;
  li {
    list-style: none;
    position: relative;
    padding-left: 16px;
    margin-top: 24px;
  }
  li:before {
    counter-increment: list;
    content: '(' counter(list, lower-alpha) ') ';
    position: absolute;
    left: -1.4em;
  }
`;

export const RomanNumeralListStyles = styled.ol`
  counter-reset: list;
  li {
    list-style: none;
    position: relative;
    padding-left: 16px;
    margin-top: 24px;
  }
  li:before {
    counter-increment: list;
    content: '(' counter(list, lower-roman) ') ';
    position: absolute;
    left: -1.4em;
  }
`;

function EndUserAgreementPage() {
  return (
    <PageContainer>
      <PageContentStyles>
        <TextStyles>
          This End User License Agreement, including the Order Form which by this reference is incorporated herein (this{' '}
          <strong>"Agreement"</strong>), is a binding agreement between Channel Rock Technologies, LLC (
          <strong>"Licensor"</strong>) and the person or entity identified on the Order Form as the licensee of the
          Software (<strong>"Licensee"</strong>).
        </TextStyles>
        <TextStyles>
          LICENSOR PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE
          CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY CLICKING THE "ACCEPT" BUTTON YOU (A) ACCEPT THIS
          AGREEMENT AND AGREE THAT LICENSEE IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT: (I) YOU
          ARE 18 YEARS OF AGE OR OLDER; AND (II) IF LICENSEE IS A CORPORATION, GOVERNMENTAL ORGANIZATION, OR OTHER LEGAL
          ENTITY, YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF LICENSEE AND BIND
          LICENSEE TO ITS TERMS. IF LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, LICENSOR WILL NOT AND DOES
          NOT LICENSE THE SOFTWARE TO LICENSEE AND YOU MUST NOT ACCESS OR USE THE SOFTWARE OR DOCUMENTATION.
        </TextStyles>
        <TextStyles>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR YOUR OR LICENSEE'S ACCEPTANCE OF THE TERMS AND
          CONDITIONS OF THIS AGREEMENT, NO LICENSE IS GRANTED (WHETHER EXPRESSLY, BY IMPLICATION, OR OTHERWISE) UNDER
          THIS AGREEMENT, AND THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT, CONCERNING ANY SOFTWARE THAT LICENSEE DID NOT
          ACQUIRE USE OF LAWFULLY OR THAT IS NOT A LEGITIMATE, AUTHORIZED ACCESS OF LICENSOR'S SOFTWARE.
        </TextStyles>
        <OrderedListStyles>
          <li> Definitions. For purposes of this Agreement, the following terms have the following meanings:</li>
          <TextStyles>
            <strong>"Authorized Users"</strong> means solely those individuals authorized by Licensee to use the
            Software under Licensee’s account pursuant to the license granted under this Agreement, as set forth on the
            Order Form. Authorized Users may include, without limitation, Licensee’s employees, consultants, contracts,
            and subcontractors, and Licensee’s clients who may have been invited by an Authorized User to use
            customer-facing features of the Software.
          </TextStyles>
          <TextStyles>
            <strong>"Documentation"</strong> means materials provided by Licensor, in printed, electronic, or other
            form, whether featured on Licensor’s website or made available upon access to the Software, that describe
            the operation, use, technical specifications, features and/or functionality of the Software.
          </TextStyles>
          <TextStyles>
            <strong>Intellectual Property Rights"</strong>" means any and all registered and unregistered rights
            granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright,
            trademark, trade secret, database protection, or other intellectual property rights laws, and all similar or
            equivalent rights or forms of protection, in any part of the world.
          </TextStyles>
          <TextStyles>
            <strong>"License"</strong> has the meaning set forth in Section 2.
          </TextStyles>
          <TextStyles>
            <strong>"License Fees"</strong> means the license fees, including all taxes thereon, paid or required to be
            paid by Licensee for the license granted under this Agreement.
          </TextStyles>
          <TextStyles>
            <strong>"License Fees"</strong> means the license fees, including all taxes thereon, paid or required to be
            paid by Licensee for the license granted under this Agreement.
          </TextStyles>
          <TextStyles>
            <strong>"Licensee"</strong> has the meaning set forth in the preamble.
          </TextStyles>
          <TextStyles>
            <strong>"Licensor"</strong> has the meaning set forth in the preamble.
          </TextStyles>
          <TextStyles>
            <strong>"Order Form"</strong> means the order form filled out and submitted by or on behalf of Licensee, and
            accepted by Licensor, for Licensee's purchase of the License granted under this Agreement with respect to
            Licensee’s access and use of the Software.
          </TextStyles>
          <TextStyles>
            <strong>"Person"</strong> means an individual, corporation, partnership, joint venture, limited liability
            company, governmental authority, unincorporated organization, trust, association, or other entity.
          </TextStyles>
          <TextStyles>
            <strong>"Software"</strong> means the online-accessed non-downloadable software platform and its
            construction project management programs for which Licensee is purchasing a license, as expressly set forth
            in the Order Form.
          </TextStyles>
          <TextStyles>
            <strong>"Term"</strong> has the meaning set forth in Section 11.
          </TextStyles>
          <TextStyles>
            <strong>"Third Party"</strong> means any Person other than Licensee or Licensor.
          </TextStyles>
          <TextStyles>
            <strong>"Update"</strong> has the meaning set forth in Section 7(b).
          </TextStyles>
          <TextStyles>
            <strong>“User Data”</strong> means the information submitted by an Authorized User through use of the
            Software, including all associated project information, messages, attachments, files, to-do lists, activity
            logs, plans, photographs, videos, customer information and other like content.
          </TextStyles>
          <li>
            {' '}
            License Grant and Scope. Subject to and conditioned upon Licensee's payment of the License Fees and
            Licensee's strict compliance with all terms and conditions set forth in this Agreement, Licensor hereby
            grants to Licensee a non-exclusive, non-transferable, non-sublicensable, limited license during the Term to
            use, solely by and through its Authorized Users, the Software and Documentation, solely as set forth in this
            Section 2 and subject to all conditions and limitations set forth in Section 4 or elsewhere in this
            Agreement (the “License”). This License grants Licensee the right, exercisable solely by and through
            Licensee's Authorized Users, to access, use, and run the Software in accordance with this Agreement and the
            Documentation, solely as set forth in the Documentation and solely for Licensee's internal business
            purposes.
          </li>
          <li>
            {' '}
            Third-Party Materials. The Software may include software, content, data, or other materials, including
            related documentation, that are owned by Persons other than Licensor and that are provided to Licensee on
            licensee terms that are in addition to and/or different from those contained in this Agreement ("Third-Party
            Licenses"). A list of all materials, if any, included in the Software and provided under Third-Party
            Licenses will be provided upon written request. Licensee is bound by and shall comply with all Third-Party
            Licenses. Any breach by Licensee or any of its Authorized Users of any Third-Party License is also a breach
            of this Agreement.
          </li>
          <li>
            {' '}
            Use Restrictions. Licensee shall not, and shall require its Authorized Users not to, directly or indirectly:
          </li>
        </OrderedListStyles>
        <SuborderedListStyles>
          <li>
            Use (including make any copies of) the Software or Documentation beyond the scope of the license granted
            under Section 2;
          </li>
          <li>
            {' '}
            Provide any other Person who is not an Authorized User with access to or use of the Software or
            Documentation;
          </li>
          <li>
            Modify, translate, adapt, or otherwise create derivative works or improvements, whether or not patentable,
            of the Software or Documentation or any part thereof;
          </li>
          <li>
            combine the Software or any part thereof with, or incorporate the Software or any part thereof in, any other
            programs;
          </li>
          <li>
            Reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain access to the
            source code of the Software or any part thereof;
          </li>
          <li>
            Remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual
            property or proprietary rights notices provided on or with the Software or Documentation, including any copy
            thereof;
          </li>
          <li>Copy the Software or Documentation, in whole or in part;</li>
          <li>
            Rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the
            Software, or any features or functionality of the Software, to any Third Party for any reason, whether or
            not over a network or on a hosted basis, including in connection with the internet or any web hosting, wide
            area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as
            a service, cloud, or other technology or service;
          </li>
          <li>Use the Software or Documentation in violation of any law, regulation, or rule; or</li>
          <li>
            Use the Software or Documentation for purposes of competitive analysis of the Software, the development of a
            competing software product or service, or any other purpose that is to the Licensor's commercial
            disadvantage.
          </li>
        </SuborderedListStyles>
        <OrderedListStyles start={5}>
          <li>
            Responsibility for Use of Software and User Data. Licensee is responsible and liable for all uses of the
            Software and Documentation through access thereto provided by Licensee, directly or indirectly.
            Specifically, and without limiting the generality of the foregoing, Licensee is responsible and liable for
            all actions and failures to take required actions with respect to the Software and Documentation by its
            Authorized Users or by any other Person to whom Licensee or an Authorized User may provide access to or use
            of the Software and/or Documentation, whether such access or use is permitted by or in violation of this
            Agreement. Licensee is solely responsible for the accuracy, quality, reliability and legality of all User
            Data, whether supplied by Licensee, an Authorized User, or any other party. Licensee shall ensure it is
            entitled, by ownership, license or other arrangement, to transfer the User Data to Licensor so that Licensor
            and any affiliates thereof may lawfully use, process and transfer the User Data in accordance with the terms
            of this Agreement on Licensee’s behalf. Licensee shall promptly notify Licensor it if becomes aware of any
            unauthorized use of or access to Licensee’s account or the Software. Licensee grants Licensor and its
            authorized third party service providers a perpetual, worldwide, non-exclusive, royalty-free license to
            host, copy, access, process, transmit and display User Data, for the duration of the Term, including any
            extensions ore renewal thereof, (i) to maintain, provide, and improve the Software and perform under this
            Agreement; (ii) to prevent or address security issues and resolve support requests; (iii) to investigate in
            good faith an allegation that Licensee or an Authorized User is in violation of this Agreement; or (iv) at
            Licensee’s written direction or request. Notwithstanding the foregoing, Licensor may collect, process, and
            use non-personal aggregated or anonymized data in any way, including by disclosing, sharing or otherwise
            making it available to third parties for various research, marketing, analytics and measurement purposes.
          </li>
          <li>Compliance Measures.</li>
        </OrderedListStyles>
        <SuborderedListStyles>
          <li>
            The Software may contain technological copy protection or other security features designed to prevent
            unauthorized use of the Software, including features to protect against any use of the Software that is
            prohibited under Section 4. Licensee shall not, and shall not attempt to, remove, disable, circumvent, or
            otherwise create or implement any workaround to, any protection or security features.
          </li>
          <li>
            On an annual basis, and otherwise on Licensor's written request, Licensee shall conduct a review of its and
            its Authorized Users use the Software and certify to Licensor in a written instrument signed by an officer
            of Licensee that it is in full compliance with this Agreement or, if Licensee discovers any noncompliance:
          </li>
          <RomanNumeralListStyles type="I">
            <li>
              Licensee shall immediately remedy such noncompliance and provide Licensor with written notice thereof.
              Licensee shall provide Licensor with all access and assistance as Licensor requests to further evaluate
              and remedy such noncompliance.
            </li>
            <li>
              If Licensee's use of the Software exceeds the number of copies or Authorized Users permitted under the
              License and as set forth on the Order Form, Licensor shall have the remedies set forth in Section 6(d).
            </li>
          </RomanNumeralListStyles>
          <li>
            During the Term, Licensor may, in Licensor's sole discretion, audit Licensee's use of the Software to ensure
            Licensee's compliance with this Agreement, provided that (i) any such audit shall be conducted on not less
            than 90 days' prior notice to Licensee, and (ii) no more than one audit may be conducted in any 12-month
            period except for good cause shown. Licensor also may, in its sole discretion, audit Licensee's systems
            within 12 months after the end of the Term to ensure Licensee has ceased use of the Software and removed all
            copies of the Software from such systems as required hereunder. The Licensee shall reasonably cooperate with
            Licensor's personnel conducting such audits and provide all reasonable access requested by the Licensor to
            records, systems, equipment, information, and personnel, including machine IDs, serial numbers, and related
            information. Licensor shall only examine information directly related to the Licensee's use of the Software.
            Licensor may conduct audits only during Licensee's normal business hours and in a manner that does not
            unreasonably interfere with the Licensee's business operations.
          </li>
          <li>
            If the audit or any of the measures taken or implemented under this Section 6 determines that the Licensee's
            use of the Software exceeds or exceeded the use permitted by this Agreement then:
          </li>
          <RomanNumeralListStyles type="I">
            <li>
              Licensee shall, within 30 days following the date of such determination by Licensee, or Licensor's written
              notification thereof, pay to Licensor the retroactive License Fees for such excess use and, unless
              Licensor terminates this Agreement pursuant to Section 6.1(d)(iii), obtain and pay for a valid license to
              bring Licensee's use into compliance with this Agreement. In determining the Licensee Fee payable pursuant
              to the foregoing, (x) unless Licensee can demonstrate otherwise by documentary evidence, all excess use of
              the Software shall be deemed to have commenced on the commencement date of this Agreement or, if later,
              the completion date of any audit previously conducted by Licensor hereunder, and continued uninterrupted
              thereafter, and (y) the rates for such licenses shall be determined without regard to any discount to
              which Licensee may have been entitled had such use been properly licensed prior to its commencement (or
              deemed commencement).
            </li>
            <li>
              If the use exceeds or exceeded the use permitted by this Agreement by more than 10%, Licensee shall also
              pay to Licensor, within 30 days following the date of Licensor's written request therefor, Licensor's
              reasonable costs incurred in conducting the audit.]
            </li>
            <li>
              If the use exceeds or exceeded the use permitted by this Agreement by more than 20%, Licensor, shall also
              have the right to terminate this Agreement and the License granted hereunder, effective immediately upon
              written notice to Licensee.
              <br />
              <br />
              Licensor's remedies set forth in this Section 6(d) are cumulative and are in addition to, and not in lieu
              of, all other remedies the Licensor may have at law or in equity, whether under this Agreement or
              otherwise.
            </li>
          </RomanNumeralListStyles>
        </SuborderedListStyles>
        <OrderedListStyles start={7}>
          <li>Maintenance and Support.</li>
          <SuborderedListStyles>
            <li>
              Subject to Section 7(b), the License granted hereunder entitles Licensee to the basic software maintenance
              and support services during the term set forth on the Order Form, which services will include provision of
              such updates, upgrades, bug fixes, patches, and other error corrections (collectively, "Updates") as
              Licensor makes generally available free of charge to all licensees of the Software. Licensor may develop
              and provide Updates in its sole discretion, and Licensee agrees that Licensor has no obligation to develop
              any Updates at all or for particular issues. Licensee further agrees that all Updates will be deemed
              Software, and related documentation will be deemed Documentation, all subject to all terms and conditions
              of this Agreement. Maintenance and support services do not include any new version or new release of the
              Software that Licensor may issue as a separate or new product, and Licensor may determine whether any
              issuance qualifies as a new version, new release, or Update in its sole discretion.
            </li>
            <li>
              Licensor reserves the right to condition the provision of maintenance and support services, including all
              or any Updates, on Licensee's proper registration of its access and use of the Software for which support
              is requested. Licensor has no obligation to provide maintenance and support services, including Updates:
            </li>
            <RomanNumeralListStyles type="I">
              <li>For any but the most current version or release of the Software; or</li>
              <li>If Licensee is in breach under this Agreement.</li>
            </RomanNumeralListStyles>
          </SuborderedListStyles>
        </OrderedListStyles>
        <OrderedListStyles start={8}>
          <li>Collection and Use of Information.</li>
          <SuborderedListStyles>
            <li>
              {' '}
              Licensee acknowledges that Licensor may, directly or indirectly through the services of Third Parties,
              collect and store information regarding use of the Software and about equipment on which the Software is
              accessed and used, through:
              <RomanNumeralListStyles type="I">
                <li>Improving the performance of the Software or developing Updates; and </li>
                <li>
                  Verifying Licensee's compliance with the terms of this Agreement and enforcing the Licensor's rights,
                  including all Intellectual Property Rights in and to the Software.{' '}
                </li>
              </RomanNumeralListStyles>
            </li>
            <li value="b">
              {' '}
              Licensee agrees that the Licensor may use such information for any purpose related to any use of the
              Software by Licensee or on Licensee's equipment, including but not limited to:
            </li>
            <RomanNumeralListStyles type="I">
              <li>Improving the performance of the Software or developing Updates; and </li>
              <li>
                {' '}
                Verifying Licensee's compliance with the terms of this Agreement and enforcing the Licensor's rights,
                including all Intellectual Property Rights in and to the Software.{' '}
              </li>
            </RomanNumeralListStyles>
          </SuborderedListStyles>
          <li>
            Intellectual Property Rights. Licensee acknowledges and agrees that the Software and Documentation are
            provided under license, and not sold, to Licensee. Licensee does not acquire any ownership interest in the
            Software or Documentation under this Agreement, or any other rights thereto, other than to use the same in
            accordance with the License granted and subject to all terms, conditions, and restrictions under this
            Agreement. Licensor shall reserve and shall retain its entire right, title, and interest in and to the
            Software, including Updates and improvements thereto, and all Intellectual Property Rights arising out of or
            relating to the Software, except as expressly granted to the Licensee in this Agreement. Licensee shall use
            commercially reasonable efforts to safeguard the Software from infringement, misappropriation, theft,
            misuse, or unauthorized access. Licensee shall promptly notify Licensor if Licensee becomes aware of any
            infringement of the Licensor's Intellectual Property Rights in the Software and fully cooperate with
            Licensor in any legal action taken by Licensor to enforce its Intellectual Property Right.
          </li>
          <li>Payment of Fees.</li>
          <SuborderedListStyles>
            <li>
              The Licensee Fees are set forth in the applicable Order Form delivered to Licensee by Licensor. All
              License Fees are subject to change upon notice. Licensee shall pay all fees when due. If such fees are
              paid via credit care or other electronic means, Licensee authorizes Licensor to charge such fees using
              Licensee’s selected payment method. Payment obligations are non-cancelable and fees paid are
              non-refundable, except as may be expressly set forth herein. The number of Authorized Users for those
              Licensees on any applicable pricing package cannot be decreased during the applicable Term. Licensor
              reserves the right to suspend Licensee’s account, in addition to exercising any and all of its other
              available rights and remedies, in the event Licensee’s account becomes overdue for any reason, including
              failure to provide updated credit card information. Suspension shall not relieve Licensee of its
              obligation to pay all amounts due.
            </li>
            <li>
              Licensee agrees that the License granted hereunder shall automatically renew on a monthly basis after the
              initial Term (the “Renewal Date”) at Licensor’s then-current License Fee rate. Licensee authorizes
              Licensor to automatically charge Licensee for the applicable fees on or after the Renewal Date unless the
              License has been terminated or cancelled in accordance with this Agreement. Any renewal of the license or
              maintenance and support services hereunder shall not be effective until the fees for such renewal have
              been paid in full.
            </li>
            <li>
              If Licensee desires to reduce the number of Authorized Users, it must notify Licensor of the same prior to
              the Renewal Date. Licensee may cancel its License prior to the Renewal Date in order to order to avoid
              being charged the applicable License Fee for the relevant Term on the Renewal Date.{' '}
            </li>
            <li>
              Licensee must cancel the License prior to the Renewal Date in order to avoid being charged the applicable
              License Fee for the relevant Term. If Licensee cancels this Agreement prior to the end of the Term,
              Licensee will not be issued, nor will be entitled to receive, a refund for the most recent License Fee or
              any previously charged fees and the License will terminate on the next applicable Renewal Date.{' '}
            </li>
            <li>
              Any fees charged to Licensee are exclusive of taxes. With the exception of taxes based on Licensor’s net
              income, Licensee shall be responsible for all applicable taxes in connection with this Agreement,
              including, but not limited to, sales, use, excise, value-added, goods and services, consumption and other
              similar taxes.{' '}
            </li>
          </SuborderedListStyles>
          <li>Term and Termination.</li>
          <SuborderedListStyles>
            <li>
              This Agreement and the License granted hereunder shall remain in effect for the term set forth on the
              Order Form, as may be extended by the mutual written agreement of the parties or by automatic renewal in
              accordance with the provisions of Section 10(b) hereof, or until earlier terminated as set forth herein
              (the "Term").
            </li>
            <li>
              Licensee may terminate this Agreement after the minimum period of service set forth in the applicable
              Order From and prior to the Renewal Date by providing Licensor with thirty (30) days’ written notice,
              after which Licensor shall cease all use of the Software and Documentation.{' '}
            </li>
            <li>
              Licensor may terminate this Agreement, effective upon written notice to Licensee, if Licensee materially
              breaches this Agreement and such breach: (i) is incapable of cure; or (ii) being capable of cure, remains
              uncured for twenty (20) days after Licensor provides written notice thereof.
            </li>
            <li>
              Licensor may terminate this Agreement, effective immediately, if Licensee files, or has filed against it,
              a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency law, makes or seeks
              to make a general assignment for the benefit of its creditors or applies for, or consents to, the
              appointment of a trustee, receiver, or custodian for a substantial part of its property.
            </li>
            <li>
              Upon expiration or earlier termination of this Agreement, the License granted hereunder shall also
              terminate, Licensee shall cease using the Software and Documentation, and Licensor may immediately
              deactivate Licensee’s account(s) associated with the Agreement. In no event will any expiration or
              termination of this Agreement relieve Licensee of its obligation to pay all Licensee Fees that may have
              become due before such expiration or termination, or entitle Licensee to any refund, in each case except
              as set forth in Section 12(c)(ii). The following sections of this Agreement shall survive the expiration
              or termination thereof: Section 4 (Restrictions on Use), Section 5 (Responsibility for Use of Software),
              Section 9 (Intellectual Property Rights), Section 10 (Payment of Fees), this Section 11(e), Section 12
              (Limited Warranties, Exclusive Remedy, and Disclaimer), Section 13 (Limitation of Liability), Section 14
              (Export Regulations), Section 15 (U.S. Government Rights) and Section 16 (Miscellaneous).
            </li>
          </SuborderedListStyles>
          <li>Limited Warranties, Exclusive Remedy, and Disclaimer.</li>
          <SuborderedListStyles>
            <li>
              Solely with respect to Software for which Licensor receives a License Fee, Licensor warrants that, during
              the Term, the Software will substantially function as described in the Documentation, and when properly
              accessed on a computer meeting the specifications set forth in, and operated in accordance with, the
              Documentation, will substantially perform in accordance therewith. Licensor specifically disclaims any
              other warranties. THE FOREGOING WARRANTY DOES NOT APPLY, AND LICENSOR STRICTLY DISCLAIMS ALL WARRANTIES,
              WITH RESPECT TO ANY THIRD-PARTY MATERIALS.
            </li>
            <li>
              The warranty set forth in Section 12(a) will not apply and will become null and void if Licensee
              materially breaches any provision of this Agreement, or if Licensee, any Authorized User, or any other
              Person provided access to the Software by Licensee or any Authorized User, whether or not in violation of
              this Agreement:
            </li>
            <RomanNumeralListStyles>
              <li>
                Uses the Software on or in connection with any hardware or software not specified in the Documentation
                or expressly authorized by Licensor in writing;
              </li>
              <li>
                Misuses the Software, including any use of the Software other than as specified in the Documentation or
                expressly authorized by Licensor in writing.
              </li>
            </RomanNumeralListStyles>
            <li>
              If, during the period specified in Section 12(a), any Software covered by the warranty set forth in such
              Section fails to perform substantially in accordance with the Documentation, and such failure is not
              excluded from warranty pursuant to the Section 12(b), Licensor will, subject to Licensee's promptly
              notifying Licensor in writing of such failure, at its sole option, either:
            </li>
            <RomanNumeralListStyles>
              <li>
                Repair the Software, provided that Licensee provides Licensor with all information Licensor reasonably
                requests to resolve the reported failure, including sufficient information to enable the Licensor to
                recreate such failure; or
              </li>
              <li>
                Refund the License Fees paid for such Software, subject to Licensee's ceasing all use of the Software.
              </li>
            </RomanNumeralListStyles>
            <li>
              EXCEPT FOR THE LIMITED WARRANTY SET FORTH IN SECTION 12(a), THE SOFTWARE AND DOCUMENTATION ARE PROVIDED TO
              LICENSEE AND THE AUTHORIZED USERS "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO
              THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, LICENSOR, ON ITS OWN BEHALF AND ON BEHALF OF ITS
              AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL
              WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE AND
              DOCUMENTATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
              PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, THE LICENSOR PROVIDES NO
              WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE WILL MEET THE
              LICENSEE'S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE,
              APPLICATIONS, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY
              STANDARDS OR BE ERROR FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
            </li>
          </SuborderedListStyles>
          <li>Limitation of Liability. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:</li>
          <SuborderedListStyles>
            <li>
              IN NO EVENT WILL LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE
              PROVIDERS, BE LIABLE TO LICENSEE, ANY AUTHORIZED USER, OR ANY OTHER PARTY FOR ANY USE, INTERRUPTION,
              DELAY, OR INABILITY TO USE THE SOFTWARE; LOST REVENUES OR PROFITS; DELAYS, INTERRUPTION, OR LOSS OF
              SERVICES, BUSINESS, OR GOODWILL; LOSS OR CORRUPTION OF DATA; LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE
              FAILURE, MALFUNCTION, OR SHUTDOWN; FAILURE TO ACCURATELY TRANSFER, READ, OR TRANSMIT INFORMATION
              (INCLUDING, BUT NOT LIMITED TO, USER DATA); FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION; SYSTEM
              INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION; OR BREACHES IN SYSTEM SECURITY; OR
              FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES, WHETHER ARISING OUT
              OF OR IN CONNECTION WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE,
              REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE LICENSOR WAS ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES.
            </li>
            <li>
              IN NO EVENT WILL LICENSOR'S AND ITS AFFILIATES', INCLUDING ANY OF ITS OR THEIR RESPECTIVE LICENSORS' AND
              SERVICE PROVIDERS', COLLECTIVE AGGREGATE LIABILITY UNDER OR IN CONNECTION WITH THIS AGREEMENTOR ITS
              SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
              NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE TOTAL AMOUNT PAID TO THE LICENSOR PURSUANT TO
              THIS AGREEMENT FOR (i) THE SOFTWARE, OR (ii) UP TO TWELVE (12) MONTHS OF THE SPECIFIC SERVICES, THAT IS OR
              ARE THE SUBJECT OF THE CLAIM.{' '}
            </li>
            <li>
              {' '}
              THE LIMITATIONS SET FORTH IN SECTION 13(a) AND SECTION 13(b) SHALL APPLY EVEN IF THE LICENSEE'S REMEDIES
              UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
            </li>
          </SuborderedListStyles>
          <li>
            {' '}
            Export Regulation. The Software and Documentation may be subject to US export control laws, including the
            Export Control Reform Act and its associated regulations. The Licensee shall not, directly or indirectly,
            make the Software or Documentation accessible from, any jurisdiction or country to which export, re-export,
            or release is prohibited by law, rule, or regulation. The Licensee shall comply with all applicable federal
            laws, regulations, and rules, and complete all required undertakings (including obtaining any necessary
            export license or other governmental approval), prior to making the Software or Documentation available
            outside the US.
          </li>
          <li>
            {' '}
            US Government Rights. The Software is commercial computer software, as such term is defined in 48 C.F.R.
            §2.101. Accordingly, if the Licensee is the US Government or any contractor therefor, Licensee shall receive
            only those rights with respect to the Software and Documentation as are granted to all other end users under
            license, in accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with respect to the
            Department of Defense and their contractors, or (b) 48 C.F.R. §12.212, with respect to all other US
            Government licensees and their contractors.{' '}
          </li>
          <li> Miscellaneous</li>
          <SuborderedListStyles>
            <li>
              All matters arising out of or relating to this Agreement shall be governed by and construed in accordance
              with the internal laws of the Commonwealth of Massachusetts without giving effect to any choice or
              conflict of law provision or rule. Any legal suit, action, or proceeding arising out of or relating to
              this Agreement or the transactions contemplated hereby shall be instituted in the federal courts of the
              United States of America or the courts of the Commonwealth of Massachusetts in each case located in
              Suffolk County, and each party irrevocably submits to the exclusive jurisdiction, including personal
              jurisdiction, of such courts in any such legal suit, action, or proceeding. Service of process, summons,
              notice, or other document by mail to such party's address set forth herein shall be effective service of
              process for any suit, action, or other proceeding brought in any such court.
            </li>
            <li>
              Licensor will not be responsible or liable to Licensee, or deemed in default or breach hereunder by reason
              of any failure or delay in the performance of its obligations hereunder where such failure or delay is due
              to strikes, labor disputes, civil disturbances, riot, rebellion, invasion, epidemic, hostilities, war,
              terrorist attack, embargo, natural disaster, acts of God, flood, fire, sabotage, fluctuations or
              non-availability of electrical power, heat, light, air conditioning, or Licensee equipment, loss and
              destruction of property, or any other circumstances or causes beyond Licensor's reasonable control.
            </li>
            <li>
              All notices, requests, consents, claims, demands, waivers, and other communications hereunder shall be in
              writing and shall be deemed to have been given: (i) when delivered by hand (with written confirmation of
              receipt); (ii) when received by the addressee if sent by a nationally recognized overnight courier
              (receipt requested); (iii) on the date sent by facsimile or email (with confirmation of transmission) if
              sent during normal business hours of the recipient, and on the next business day if sent after normal
              business hours of the recipient; or (iv) on the third day after the date mailed, by certified or
              registered mail, return receipt requested, postage prepaid. Such communications must be sent to the
              respective parties at the addresses set forth on the Order Form (or to such other address as may be
              designated by a party from time to time in accordance with this Section 16(c)).
            </li>
            <li>
              This Agreement, together with the Order Form, all annexes, schedules, and exhibits attached hereto, and
              all other documents that are incorporated by reference herein, constitute the sole and entire agreement
              between Licensee and Licensor with respect to the subject matter contained herein, and supersedes all
              prior and contemporaneous understandings, agreements, representations, and warranties, both written and
              oral, with respect to such subject matter.{' '}
            </li>
            <li>
              Licensee shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any
              of its obligations or performance, under this Agreement, in each case whether voluntarily, involuntarily,
              by operation of law, or otherwise, without Licensor's prior written consent, which consent Licensor may
              give or withhold in its sole discretion. For purposes of the preceding sentence, and without limiting its
              generality, any merger, consolidation, or reorganization involving Licensee (regardless of whether
              Licensee is a surviving or disappearing entity) will be deemed to be a transfer of rights, obligations, or
              performance under this Agreement for which Licensor's prior written consent is required. No delegation or
              other transfer will relieve Licensee of any of its obligations or performance under this Agreement. Any
              purported assignment, delegation, or transfer in violation of this Section 16(e) is void. Licensor may
              freely assign or otherwise transfer all or any of its rights, or delegate or otherwise transfer all or any
              of its obligations or performance, under this Agreement without Licensee's consent. This Agreement is
              binding upon and inures to the benefit of the parties hereto and their respective permitted successors and
              assigns.
            </li>
            <li>
              This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted
              assigns and nothing herein, express or implied, is intended to or shall confer on any other Person any
              legal or equitable right, benefit, or remedy of any nature whatsoever under or by reason of this
              Agreement.
            </li>
            <li>
              This Agreement may only be amended, modified, or supplemented by an agreement in writing signed by each
              party hereto. No waiver by any party of any of the provisions hereof shall be effective unless explicitly
              set forth in writing and signed by the party so waiving. Except as otherwise set forth in this Agreement,
              no failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from this
              Agreement shall operate or be construed as a waiver thereof; nor shall any single or partial exercise of
              any right, remedy, power, or privilege hereunder preclude any other or further exercise thereof or the
              exercise of any other right, remedy, power, or privilege.
            </li>
            <li>
              If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such
              invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement
              or invalidate or render unenforceable such term or provision in any other jurisdiction.
            </li>
            <li>
              For purposes of this Agreement, (a) the words "include," "includes," and "including" shall be deemed to be
              followed by the words "without limitation"; (b) the word "or" is not exclusive; and (c) the words
              "herein," "hereof," "hereby," "hereto," and "hereunder" refer to this Agreement as a whole. Unless the
              context otherwise requires, references herein: (x) to Sections, Annexes, Schedules, and Exhibits refer to
              the Sections of, and Annexes, Schedules, and Exhibits attached to, this Agreement; (y) to an agreement,
              instrument, or other document means such agreement, instrument, or other document as amended,
              supplemented, and modified from time to time to the extent permitted by the provisions thereof and (z) to
              a statute means such statute as amended from time to time and includes any successor legislation thereto
              and any regulations promulgated thereunder. This Agreement shall be construed without regard to any
              presumption or rule requiring construction or interpretation against the party drafting an instrument or
              causing any instrument to be drafted.{' '}
            </li>
            <li>
              The headings in this Agreement are for reference only and do not affect the interpretation of this
              Agreement.
            </li>
          </SuborderedListStyles>
        </OrderedListStyles>
      </PageContentStyles>
    </PageContainer>
  );
}

export default EndUserAgreementPage;
