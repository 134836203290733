import React from 'react';
import { ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../CustomButton';
import Modal from '../Modal';
import { ModalInput } from '../Roles/RoleItem';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../styles/ModalStyles';

type IModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  email: string;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
  disabled: boolean;
  submitFunction: () => void;
};

function InviteMemberToWorkspaceModal({
  isOpen,
  toggleModal,
  email,
  handleEmailChange,
  error,
  disabled,
  submitFunction
}: IModalProps) {
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="396px">
        <ModalTitle>Send Invitation via Email</ModalTitle>
        <ModalBody>
          <ModalText>Invite a member to this workspace.</ModalText>
          <ModalInput
            placeholder="email"
            type="email"
            className="input-email"
            value={email}
            onChange={(e) => handleEmailChange(e)}
            error={error}
            disabled={disabled}
          />
        </ModalBody>
        <ModalWrapper>
          <Button secondary disabled={disabled} onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={() => submitFunction()}>Send Invitation</Button>
        </ModalWrapper>
      </ModalContainer>
    </Modal>
  );
}

export default InviteMemberToWorkspaceModal;
