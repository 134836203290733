//This Method is no more in Use. It is not required.

import { useState } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '../../../axiosInstance';

function useUpdateSeats() {
  const [stripeLoading, setStripeLoading] = useState(false);

  const updateSeats = async (stripeCustomerId: string) => {
    setStripeLoading(true);
    let toastId = toast.loading('Redirecting to checkout page.');
    axiosInstance
      .post(`payment/create-portal-session`, {
        organizationId: stripeCustomerId
      })
      .then((response) => {
        console.log('response', response);
        window.location = `${response?.data?.url}`;
      })
      .catch((error) => {
        console.error(error);
        toast.dismiss(toastId);
        toast.error('Error in checkout.');
        setStripeLoading(false);
      });
  };

  return {
    stripeLoading,
    updateSeats
  };
}

export default useUpdateSeats;
