import styled from "@emotion/styled";

type ContainerProps = {
  width: string;
};

export const ModalContainer = styled.div<ContainerProps>`
  width: ${(props) => props.width};
`;

export const ModalTitle = styled.div`
  background: #26303e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
`;

export const ModalBody = styled.div`
  background: white;
  padding: 15px;
  /* border: 1px solid blue; */
`;

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 28px;
  > * + * {
    margin-left: 10px;
  }
`;
