import styled from '@emotion/styled';
import Lottie from 'react-lottie';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ClientSeparator,
  ClientWrapper,
  DateWrapper,
  FieldSet,
  InputWrapper
} from '../../../../components/styles/FormStyles';
import { Label } from '../../../../components/styles/Label';
import { Small } from '../../../../components/styles/SmallText';
import { Text } from '../../../../components/styles/Text';
import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import { DateText } from '../CreateNewDynamicDocumentInRegister';
import { Container } from '../../../Projects/ProjectViews/CreateProject';
import { getProjectQuery } from '../../../../graphql/operations/Queries/Projects/ProjectQueries';
import {
  getRegisterQuery,
  GET_SINGLE_FILE_REGISTER_QUERY
} from '../../../../graphql/operations/Queries/Registers/RegisterQueries';
import { ReactComponent as RoundIcon } from '../../../../assets/icons/RoundIcon.svg';
import { GreyButton } from '../../../../components/styles/ButtonStyles';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { AppContext } from '../../../../AppContext';
import { DownloadFileQuery } from '../../../../graphql/operations/Queries/Library/FilesQueries';
// import LiveConnectionContext from "../../../../contexts/LiveConnectionContext";
// import Button from "../../../../components/CustomButton";
// import SendResponse from "./SendResponse";
// const ResponseHistoryText = styled.span`
//   margin-left: 1.6rem;
//   color: #c37f0f;
//   font-weight: 600;
//   cursor: pointer;
// `;
export const Paragraph = styled.p`
  max-width: 60vw;
  min-width: 50vw;
`;

const FileTextWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 20px;
    font-size: 16px;
    font-weight: normal;
    color: #4c6d66;
  }
  button {
    margin-left: 50px !important;
  }
`;

function AboutRegisterDocument() {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  let { projectId, workspaceId, registerId, fileId } = useParams();
  const { data, error, loading, refetch } = useQuery(GET_SINGLE_FILE_REGISTER_QUERY, {
    variables: { id: fileId },
    fetchPolicy: 'network-only'
  });
  const {
    data: projectData,
    // error: projectError,
    loading: projectLoading
  } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    }
  });

  const {
    data: registerData,
    error: registerError,
    loading: registerLoading
  } = useQuery(getRegisterQuery, { variables: { id: registerId } });

  useEffect(() => {
    refetch();
  }, []);
  const downloadfileId = data?.get_File?.fileId;
  const { data: downloadData } = useQuery(DownloadFileQuery, {
    skip: !downloadfileId,
    variables: { id: downloadfileId, operation: 'query' },
    fetchPolicy: 'network-only'
  });
  const [fileDownload, { data: downloadedData }] = useLazyQuery(DownloadFileQuery);

  const downloadFile = async (id: string) => {
    let response = await fileDownload({ variables: { id, operation: 'query' } });
    let url = downloadedData?.getVendia_File?.temporaryUrl;
    window.open(url);
  };

  const sendResponse = () => {
    navigate(`/project/workspace/${registerId}/${fileId}/response`);
  };
  const goToResponseHistory = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/${fileId}/responsehistory`);
  };
  if (error || registerError) return <ErrorPage />;
  if (loading || projectLoading || registerLoading) return <Lottie options={defaultOptions} width={400} height={500} />;

  let lastResponse =
    data?.get_File?.responses?.length > 0 ? data?.get_File?.responses[data.get_File.responses.length - 1] : null;
  let type = registerData.get_Register.registerType;
  // let fields = JSON.parse(data?.getFile?.fields);
  return (
    <>
      <Container>
        <FieldSet>
          <div>
            <InputWrapper>
              {type === 'dynamic' && <Label>From[{data.get_File.initiatorRole}]</Label>}
              <FileTextWrapper>
                <RoundIcon />
                <Text>{data.get_File.fileName}</Text>
                <a
                  href={downloadData?.getVendia_File?.temporaryUrl}
                  download={`${data.get_File?.name}.${data.get_File?.fileType}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GreyButton>Download File</GreyButton>
                </a>
              </FileTextWrapper>
            </InputWrapper>
            <InputWrapper>
              {type === 'dynamic' && <Label>From [{data.get_File.responderRole}]</Label>}
              {type === 'dynamic' && data?.get_File?.responses.length > 0 ? (
                <FileTextWrapper>
                  <RoundIcon />
                  <Text>{data.get_File.responses[data.get_File.responses.length - 1].responseFileName}</Text>
                  <GreyButton
                    onClick={() =>
                      downloadFile(data.get_File.responses[data.get_File.responses.length - 1].responseFileId)
                    }
                  >
                    Download File
                  </GreyButton>
                </FileTextWrapper>
              ) : type === 'dynamic' ? (
                <Small>Waiting for response</Small>
              ) : null}
            </InputWrapper>
            {type === 'dynamic' && (
              <>
                <AssigneeWrapper>
                  {/* <DarkButton
                    disabled={
                      lastResponse === null &&
                      data.get_File.responder !== state.fullName
                    }
                    type="button"
                    onClick={() => sendResponse()}
                  >
                    Send Response
                  </DarkButton> */}
                  {/* <ResponseHistoryText onClick={() => goToResponseHistory()}>
                    View Response History
                  </ResponseHistoryText> */}
                </AssigneeWrapper>
              </>
            )}
            <InputWrapper>
              <Label>Project Name</Label>
              <p>{projectData.get_Project.name}</p>
            </InputWrapper>
            <InputWrapper>
              <Label>Description</Label>
              <Paragraph>{projectData.get_Project.projectDescription}</Paragraph>
            </InputWrapper>
          </div>
          <div>
            <DateWrapper>
              <Label htmlFor="date">Document Date </Label>
              <DateText>
                {new Date(data?.get_File?.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </DateText>
              <CalendarIconPlacer style={{ marginLeft: '20px' }}></CalendarIconPlacer>
            </DateWrapper>
            <AssigneeWrapper>
              <Label>Created by</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar name={data?.get_File?.createdByName} size={20} />
                </AvatarStyles>
                <Small>
                  {new Date(data?.get_File?.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                  })}
                  {/* </Small>
                <Small> */}{' '}
                  {new Date(data?.get_File?.createdAt).toLocaleTimeString('en-US')}
                </Small>
              </AssigneeSeparator>
            </AssigneeWrapper>
            <ClientWrapper>
              <Label>Client</Label>
              <ClientSeparator>
                <CustomAvatar isTable={false} name={projectData?.get_Project?.client} size={21} />
              </ClientSeparator>
            </ClientWrapper>
            {type === 'dynamic' && (
              <>
                <AssigneeWrapper>
                  <Label>Initiator</Label>
                  <AssigneeSeparator>
                    <AvatarStyles>
                      <CustomAvatar name={data.get_File.initiator} size={21} />
                    </AvatarStyles>
                    <Small>{data.get_File.initiatorRole}</Small>
                  </AssigneeSeparator>
                </AssigneeWrapper>
                <AssigneeWrapper>
                  <Label>Responder</Label>
                  <AssigneeSeparator>
                    <AvatarStyles>
                      <CustomAvatar name={data.get_File.responder} size={21} />
                    </AvatarStyles>
                    <Small>{data?.get_File?.responderRole}</Small>
                  </AssigneeSeparator>
                </AssigneeWrapper>
              </>
            )}
          </div>
        </FieldSet>
      </Container>
    </>
  );
}

export default AboutRegisterDocument;
