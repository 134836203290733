import { Form, Formik } from 'formik';
import { CustomButton } from '../../components/CustomButton';
import { InputField } from '../../components/Reusable/NewFormik/Input1';
import { Label } from '../../components/styles/Label';
import logo from '../../assets/images/Platform Suite_Logo_White_LG.png';
import styled from '@emotion/styled';
import { LinkWrapper } from '../../components/styles/FormStyles';
import SelectCountryCodeField from '../../components/Reusable/NewFormik/SelectCountryCodeField';
import { PhoneInputField } from '../../components/Reusable/NewFormik/PhoneInput';
import useSignUp from '../../hooks/features/auth/useSignUp';
import { Small } from '../../components/styles/SmallText';
import { Link } from 'react-router-dom';

const AuthWrapper = styled.div`
  display: flex;
`;

const LeftLogoContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #26303e;
`;
const CustomStyle = {
  control: (styles) => ({
    ...styles,
    marginTop: '10px',
    width: '150px',
    height: '44px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    color: isFocused ? 'white' : '#1C1C1C',
    background: isFocused ? '#2F3F48' : 'white',
    height: '30px'
  })
};
const PhoneNumberInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const RightFormContainer = styled.div`
  width: 50%;
  height: 100vh;
  overflow: auto;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const InputContainer = styled.div`
  width: 433px;
`;
const LogoImage = styled.img`
  transform: translate(0, -25%);
  width: 27rem;
  cursor: pointer;
`;
const Links = styled.a`
  font-weight: 600;
  color: #4c6d66;
  display: flex;
  cursor: pointer;
  padding: 1rm;
`;
const HeaderText = styled.div`
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
`;

const SignUpInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;
const SignUpInputOverflow = styled.div``;
const SignUpButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  background-color: #ffffffb3;
`;

const PrivacyPolicyText = styled.div`
  margin-top: 2px;
  margin-bottom: 5px;
  width: 50%;
  padding: 1rem;
  a {
    all: unset;
    color: #385898;
    cursor: pointer;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const CustomSignupForm = () => {
  const { initialValues, loading, validationSchema, signUp, decoded, goToHomePage } = useSignUp();
  return (
    <AuthWrapper>
      <LeftLogoContainer>
        <LogoImage onClick={() => goToHomePage()} src={logo} alt="logo" />
      </LeftLogoContainer>
      <RightFormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={signUp}
          enableReinitialize={true}
          validateOnBlur={true}
        >
          {({ handleChange, handleBlur, touched, errors, values }) => {
            return (
              <Form id="signup">
                <FormContainer>
                  <InputContainer>
                    <HeaderText>Create your Platformsuite account</HeaderText>
                    <br />
                    <SignUpInputOverflow>
                      <SignUpInputWrapper>
                        <InputField
                          label="Email"
                          placeholder="Enter Email ID"
                          type="email"
                          name="email"
                          id="email"
                          value={values.email}
                          onBlur={handleBlur}
                          errors={errors.email}
                          touched={touched.email}
                          onChange={handleChange}
                          disabled={decoded ? true : loading ? true : false}
                        />
                      </SignUpInputWrapper>
                      <SignUpInputWrapper>
                        <InputField
                          label="First Name"
                          placeholder="Enter First Name"
                          name="firstname"
                          id="firstname"
                          onChange={handleChange}
                          errors={errors.firstname}
                          touched={touched.firstname}
                          value={values.firstname}
                          onBlur={handleBlur}
                          disabled={loading}
                        />
                      </SignUpInputWrapper>
                      <SignUpInputWrapper>
                        <InputField
                          label="Last Name"
                          placeholder="Enter Last Name"
                          name="lastname"
                          id="lastname"
                          onChange={handleChange}
                          errors={errors.lastname}
                          touched={touched.lastname}
                          value={values.lastname}
                          onBlur={handleBlur}
                          disabled={loading}
                        />
                      </SignUpInputWrapper>
                      <SignUpInputWrapper>
                        <InputField
                          label="Password"
                          placeholder="Enter Password"
                          type="password"
                          name="password"
                          id="password"
                          onChange={handleChange}
                          errors={errors.password}
                          touched={touched.password}
                          value={values.password}
                          disabled={loading}
                          onBlur={handleBlur}
                        />
                      </SignUpInputWrapper>
                      <SignUpInputWrapper>
                        <InputField
                          label="Confirm Password"
                          placeholder="Re-enter Password"
                          type="password"
                          name="confirm_password"
                          id="confirm_password"
                          onChange={handleChange}
                          errors={errors.confirm_password}
                          touched={touched.confirm_password}
                          value={values.confirm_password}
                          disabled={loading}
                          onBlur={handleBlur}
                        />
                      </SignUpInputWrapper>
                      <SignUpInputWrapper>
                        <Label htmlFor="ph_country_code">Phone Number</Label>
                        <PhoneNumberInputWrapper>
                          <SelectCountryCodeField
                            styles={CustomStyle}
                            name="ph_country_code"
                            id="ph_country_code"
                            disabled={loading}
                          />

                          <PhoneInputField
                            style={{ width: '60%', marginLeft: '20px' }}
                            type="tel"
                            placeholder="Enter Phone Number"
                            name="phone_number"
                            id="phone_number"
                            disabled={loading}
                          />
                        </PhoneNumberInputWrapper>
                      </SignUpInputWrapper>
                    </SignUpInputOverflow>
                  </InputContainer>
                  <PrivacyPolicyText>
                    <Small>
                      By creating an account in Platform Suite, you agree to the{' '}
                      <Link to="/termsandconditions" target="_blank" rel="noopener noreferrer">
                        Terms of Service{' '}
                      </Link>
                      and{' '}
                      <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
                        Privacy Policy.
                      </Link>
                    </Small>
                  </PrivacyPolicyText>
                  <SignUpButtonWrapper>
                    {/* <AssigneeWrapper className="button-field"> */}
                    <CustomButton disabled={loading} type="submit">
                      {loading ? 'Loading' : 'SignUp'}
                    </CustomButton>
                    <LinkWrapper>
                      <Links onClick={() => goToHomePage()}>Log in to your existing account</Links>
                    </LinkWrapper>
                    {/* </AssigneeWrapper> */}
                  </SignUpButtonWrapper>
                </FormContainer>
              </Form>
            );
          }}
        </Formik>
      </RightFormContainer>
    </AuthWrapper>
  );
};
