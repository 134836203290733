import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { TokenContext } from '../../../TokenContext';
import jwt from 'jsonwebtoken';
import { useGetUserDetails } from '../../common/useGetUserDetails';

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const useHandleInvite = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(AppContext);
  const { setToken } = useContext(TokenContext);
  const querry = new URLSearchParams(location.search);
  const [tokenProcessed, setTokenProcessed] = useState(false);
  const { getUserData } = useGetUserDetails();
  const token = querry.get('token');
  let decoded: any;

  useEffect(() => {
    if (token) {
      toast.remove();
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 > Date.now()) {
        decoded = jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY);
      } else {
        navigate('/');
        toast.remove();
        toast.error('Invite Expired.');
      }
    }
    //if decoded and entity type is workspace but there is no roleId. then its just adding member to workspace.
    if (decoded && decoded?.entityType === 'workspace' && !decoded.roleId) {
      if (decoded.email === state?.email) {
        setToken(null);
        navigate('/chooseinvite', {
          state: { ...decoded }
        });
      } else {
        toast.error('The invite does not belong to your emailid');
        setToken(null);
        navigate('/');
      }
    } else if (decoded && decoded.roleId) {
      if (decoded.email === state?.email) {
        setToken(null);
        navigate('/chooseinvite', {
          state: { ...decoded }
        });
      } else {
        toast.error('The invite does not belong to your emailid');
        setToken(null);
        navigate('/');
      }
    }
    // else if(decoded && )
    else if (decoded && token) {
      if (
        decoded.email === state?.email &&
        decoded?.entityId !== state?.orgId &&
        decoded?.invitationStatus === 'pending'
      ) {
        decoded.type = 'addMember';
        decoded.userId = state?.userId;
        toast.remove();
        toast.loading('Joining the Organization');
        axiosInstance
          .post('/addMemberToOrganization', decoded)
          .then(async (response) => {
            if (response.data.statusCode === 200) {
              //   let result = await axiosInstance.post('/notification', {
              //     entityType: 'ORGANIZATION',
              //     entityId: decoded?.entityId,
              //     actionType: 'new_member',
              //     actionBy: state?.userId,
              //     actionByName: state?.fullName,
              //     actionByProfilePic: state?.profilePhoto,
              //     message: `<p><span class=blackBold>${state?.fullName}</span> joined the organization</p>`,
              //     workspaceId: '',
              //     projectId: 'NOPROJECT',
              //     organizationId: decoded?.entityId
              //   });
              getUserData().then((data) => {
                toast.remove();
                toast.success('Joined organization');
                decoded = undefined;
                setTokenProcessed(true);
                navigate('/');
              });
            }
            //have to set context here.
            // window.location.href = '/';
            decoded = undefined;
          })
          .catch((error) => {
            toast.remove();
            toast.error('Error Joining the Organization.');
          });
      } else {
        setToken(null);
        if (decoded?.entityId === state?.orgId) {
          decoded = undefined;
          navigate('/');
          toast.remove();
          toast.error('Already in the organization');
        } else if (decoded?.email !== state?.email) {
          navigate('/');
          toast.remove();
          toast.error(`Please login with the email Id (i.e ${decoded?.email}) in which you received the invite`);
        } else {
          toast.remove();
          toast.error(`The invite has already been declined or expired. Please request for a new one`);
        }
      }
    }
  }, [token]);

  return {
    token,
    tokenProcessed
  };
};
