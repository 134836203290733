import * as yup from "yup";

const CreateFileValidationSchema = yup.object().shape({
  documentName: yup
    .string()
    .min(2, "Name is too short!")
    .max(50, "Name is too long!")
    .required("Name is required"),
  description: yup
    .string()
    .min(2, "Description is bit too short!")
    .required("Description is required"),
  linkedFiles: yup
    .array()
    .of(
      yup
        .string()
        .url("Please enter correct url")
        .required(
          "A link cannot be empty.Please remove if link is not required."
        )
    ),
  customInputs: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .required(
          "A custom field cannot be empty.Please remove the field if it is not required"
        ),
    })
  ),
  createdAt: yup.date().required("Date is required").nullable(),
  file: yup.mixed().required("Please upload a file"),
});

export default CreateFileValidationSchema;
