import styled from '@emotion/styled';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/IconSearchShat.svg';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { CustomButton } from '../../../CustomButton';

interface Props {
  toggleSearch: () => void;
  handleSearchChange: (value: string) => void;
  searchByEmail: boolean;
  toggleSearchByEmail: () => void;
  handleEmailSearch: (value: string) => void;
  loading: boolean;
}

const SearchHeader = ({
  toggleSearch,
  handleSearchChange,
  searchByEmail,
  toggleSearchByEmail,
  handleEmailSearch,
  loading
}: Props) => {
  const [searchEmail, setSearchEmail] = useState('');

  const emailSearch = () => {
    const regex_pattern =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex_pattern.test(searchEmail)) {
      handleEmailSearch(encodeURIComponent(searchEmail));
    } else {
      toast.error('Enter a valid email');
    }
  };

  return (
    <>
      <Header>
        {searchByEmail ? <BackIcon handlenavigate={toggleSearchByEmail} /> : <BackIcon handlenavigate={toggleSearch} />}
        <p>Search</p>
      </Header>
      <SearchInput>
        {!searchByEmail ? (
          <>
            <SearchIcon className="search-icon" />
            <input type="text" placeholder="Search for users" onChange={(e) => handleSearchChange(e.target.value)} />
          </>
        ) : (
          <>
            <input
              type="email"
              placeholder="Search for email"
              onChange={(e) => setSearchEmail(e.target.value)}
              value={searchEmail}
            />
            <CustomButton secondary style={{ marginLeft: '5px' }} onClick={emailSearch} disabled={loading}>
              Search
            </CustomButton>
          </>
        )}
      </SearchInput>
    </>
  );
};

export default SearchHeader;

const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
    margin-left: 25px;
  }
`;
const SearchInput = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e6e6e6;
  position: relative;

  .search-icon {
    position: absolute;
    top: 8px;
    left: 25px;
  }

  input {
    width: 90%;
    border-radius: 5px;
    border: 1px solid #bdbab2;
    background: #f8f8f8;
    height: 40px;
    padding-left: 40px;
  }
`;
