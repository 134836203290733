import React from 'react';
import { ListProjectItem } from './ListItem';
import styled from '@emotion/styled';
import RadioButton from './RadioButton';
// import { Text } from "../components/styles/Text";
// import { ReactComponent as ProjectIcon } from "../assets/icons/RadioIcon.svg";

const ProjectInfo = styled.div`
  display: flex;
  padding-left: 2.6rem;
  align-items: center;
`;

const Heading = styled.p`
  font-weight: 600;
  margin-bottom: 5px;
  color: #1c1c1c;
`;
const SubHeading = styled.p`
  font-size: 0.8rem;
`;

const HeadingContainer = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding-top: 0.5rem;
  p {
    margin-top: 2px;
  }
`;
// const ProjectName = styled.p`
//   font-weight: 600;
//   margin-left: 2.01rem;
// `;

// const ProjectDate = styled.div`
//   color: var(--text-light);
//   display: flex;
//   align-items: center;
//   padding-right: 7.6rem;
// `;

export const DateStyle = styled.p`
  margin-right: 20px;
`;
export const Time = styled.p``;

export default function ChooseProject({ setProjectId, data }: { setProjectId: (id: string) => void; data: any }) {
  const handleProjectChange = (e) => {
    setProjectId(e.target.value);
  };
  return (
    <div>
      <HeadingContainer>
        <div>
          <Heading>Select a project.</Heading>
          <SubHeading>Select a project to which you want to add this workspace to.</SubHeading>
        </div>
      </HeadingContainer>
      <form>
        {data.map((item) => {
          return (
            <React.Fragment key={item._id}>
              <ListProjectItem item={item}>
                <ProjectInfo>
                  <RadioButton
                    label={item.name}
                    id={item._id}
                    name="projectItems"
                    checked={false}
                    value={item._id}
                    onChange={(e) => handleProjectChange(e)}
                  />
                  {/* <ProjectName>{item.name}</ProjectName> */}
                </ProjectInfo>
              </ListProjectItem>
            </React.Fragment>
          );
        })}
      </form>
    </div>
  );
}
