import Button from '../../../components/CustomButton';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/ArchiveIcon.svg';
import { Header, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Outlet, useNavigate } from 'react-router';
import { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { PageNavStyles } from '../../Library/FilesView/Files/ViewFile/ViewFile';
import { Link } from 'react-router-dom';
import useGetIsSuperAdmin from '../../../hooks/common/useGetIsSuperAdmin';
import NavigationLink from '../../../components/NavigationLink';
import { usePopperTooltip } from 'react-popper-tooltip';
import { TooltipStyles } from '../../Organization/ViewOrganization';
// import { useLocation } from 'react-router';
// import { useState } from 'react';
// import { useQuery } from "@apollo/client";
// import Lottie from "react-lottie";
// import ProjectList from "../../../components/ProjectList";
// import { defaultOptions } from "../../../constants/LoaderDefaultOptions";
// import { ErrorPage } from "../../Others/ErrorPage";
// import { LIST_PROJECTS_QUERY } from "../../../graphql/operations/Queries/Projects/ProjectQueries";

// import { errorStyles } from "../../constants/ToasterOptions";

// interface Project {
//   id: string;
//   name: string;
//   client: string;
//   createdAt: string;
//   createdBy: string;
// }

export const StyledArchiveIcon = styled(ArchiveIcon)`
  cursor: pointer;
`;

export const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  > * + * {
    margin: 10px;
  }
  margin-right: 4rem;
  .empty-data {
    margin-top: 30px;
  }
`;
export const EmptyDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8c8b8b;
  border-radius: 5px;
  background: #f6f5f5;
  min-height: 30vh;
  font-size: 1.5rem;
`;
function Projects() {
  const navigate = useNavigate();
  const { state: userDetails } = useContext(AppContext);
  const { isSuperAdmin } = useGetIsSuperAdmin();
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  // const { state } = useLocation();
  // const [projectList, setProjectList] = useState<Project[]>([]);

  const createProject = () => {
    if (userDetails.orgId === 'null' || !userDetails.orgId) {
      toast.error('You have to be a part of an organization or create an organization before creating a project');
    } else if (!isSuperAdmin) {
      toast.error('You have to be an super admin to create a new organization.');
    } else {
      navigate(`/newprojects`);
    }
  };

  return (
    <>
      <Header>
        <NewTitleLeft>
          <PageTitle>Projects</PageTitle>
        </NewTitleLeft>
        <TitleRight>
          {userDetails.orgId !== 'null' && userDetails.orgId !== null && isSuperAdmin && (
            <Link to="archived">
              <StyledArchiveIcon />
            </Link>
          )}
          <span ref={setTriggerRef}>
            <Button
              disabled={userDetails.orgId === 'null' || !isSuperAdmin || !userDetails.orgId}
              onClick={createProject}
            >
              +New Project
            </Button>
          </span>
          {(userDetails.orgId === 'null' || !isSuperAdmin || !userDetails.orgId) && visible && (
            <TooltipStyles ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
              Only SuperAdmins of an organization can create a project.
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </TooltipStyles>
          )}
        </TitleRight>
      </Header>
      <>
        <PageNavStyles>
          <NavigationLink end to="/projects">
            Internal
          </NavigationLink>
          <NavigationLink to="shared">Shared</NavigationLink>
        </PageNavStyles>
        <Outlet />
      </>
    </>
  );
}

export default Projects;
