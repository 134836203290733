import * as React from "react";

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width ? props.width : "1em"}
      height={props.height ? props.height : "1em"}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 0A7.454 7.454 0 000 7.5C0 11.679 3.321 15 7.5 15S15 11.679 15 7.5 11.679 0 7.5 0zm2.893 11.25L7.5 8.357 4.607 11.25l-.857-.857L6.643 7.5 3.75 4.607l.857-.857L7.5 6.643l2.893-2.893.857.857L8.357 7.5l2.893 2.893-.857.857z"
        fill={props.fill}
      />
    </svg>
  );
}

export default CloseIcon;
