import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useContext, useMemo, useReducer } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import CloseIcon from '../../../assets/SvgComponents/CloseIcon';
import PlusIcon from '../../../assets/SvgComponents/PlusIcon';
import Button from '../../../components/CustomButton';
import Loader from '../../../components/Loader';
import { InputField } from '../../../components/Reusable/NewFormik/Input1';
import { TextAreaField } from '../../../components/Reusable/NewFormik/TextArea1';
import { DarkButton, GreyButton, LightButton } from '../../../components/styles/ButtonStyles';
import { AssigneeSeparator, AssigneeWrapper, AvatarStyles, InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import Table from '../../../components/Table';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import CreateWorkspaceValidationSchema from '../../../components/Validations/CreateWorkspaceValidationSchema';
import { COLUMNS, SELECTEDCOLUMNS } from '../../../constants/RegisterColumnData';
import { updateRegisterMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';
import { createWorkspaceMutation } from '../../../graphql/operations/Mutations/Workspaces/WorkspaceMutations';
import {
  listOtherRegistersQuery,
  listRegistersQuery
} from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import { sentryError } from '../../../lib/SentryError';
import { TextAreaWrapper } from '../../Projects/AboutProject/ProjectInformation/ProjectAddress';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { ReactComponent as NoRegistersIcon } from '../../../assets/icons/NoRegistersIcon.svg';
import center from '../../../components/styles/shared/center.css';
// import { Input } from '../../../components/styles/Inputs';
// import { ErrorText } from '../../../components/styles/Text';
// import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
// import { getWorkspaceQuery } from "../../graphql/operations/Queries/Workspaces/WorkspaceQueries";
// import {  createdRegisterData } from '../../constants/MockRegisterData'
// import { registerData } from '../../constants/MockRegisterData'
// import axiosInstance from "../../axiosInstance";
// import InputField from "../../components/Reusable/NewFormik/Input";
// import InputField from "../../components/Reusable/Formik/Input";
// import Lottie from 'react-lottie';

export type WorkspaceData = {
  workspaceName: string;
  description: string;
};

export const WorkspaceNameStyles = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    //Apply the style to only first div ie-Inputwrapper which is a div.
    flex: 0 0 75%;
  }
`;

export const RegisterContainer = styled.div`
  /* border: 1px solid blue; */
`;

const RegisterList = styled.div`
  padding-top: 25px;
  table {
    margin-bottom: 50px;
  }
  button {
    svg {
      vertical-align: middle;
      margin-right: 5px;
      display: inline-block;
      padding-bottom: 2px;
    }
  }
  tr {
    td {
      font-weight: 600 !important;
    }
  }
`;

export const NoRegistersAdded = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;
`;

export const EmptyRegisterStyles = styled.div`
  ${center}
`;

const RegisterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  margin-right: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
  h3 {
    padding-bottom: 0px;
    margin-left: 10px;
  }
`;

// interface WorkspaceInterface {
//   name: string;
//   registers: string[];
// }

const initialRegisterState: any = { selected: [], notSelected: [] };
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_REGISTERS':
      if (action.id) {
        let selectedregisters = state.selected.filter((selected) => selected._id !== action.id);
        return {
          ...state,
          selected: selectedregisters,
          notSelected: action.data
        };
      } else {
        return {
          ...state,
          selected: action.data
        };
      }

    case 'SET_OTHER_REGISTERS':
      return {
        ...state,
        notSelected: action.data
      };
    case 'REMOVE_REGISTER_FROM_WORKSPACE':
      return action.data;
    case 'ADD_REGISTER_TO_WORKSPACE':
      return action.data;
  }
};

function CreateNewWorkspace() {
  const [registers, dispatch] = useReducer(reducer, initialRegisterState);
  let navigate = useNavigate();
  let { projectId } = useParams();
  const { state: RouterState } = useLocation();
  const { state } = useContext(AppContext);
  const userId = state.userId;
  const initialState: WorkspaceData = {
    workspaceName: RouterState?.workspaceName ? RouterState.workspaceName : '',
    description: RouterState?.description ? RouterState.description : ''
  };
  //query for fetching registers that were created with the wsID
  const {
    data: createdRegisterData,
    // error: listError,
    loading: registerLoading
  } = useQuery(listRegistersQuery, {
    variables: {
      workspaceId: RouterState ? RouterState?.workspaceId : null,
      organizationId: state.orgId
    },
    onCompleted: async (data) => {
      if (RouterState && RouterState.registerData) {
        let found = createdRegisterData.list_RegisterItems._RegisterItems.some(
          (register) => register._id === RouterState.registerData._id
        );
        if (!found) {
          let newarr = [...createdRegisterData.list_RegisterItems._RegisterItems, RouterState.registerData];
          dispatch({ type: 'SET_REGISTERS', data: newarr });
          getOtherRegisters();
        } else if (found) {
          dispatch({
            type: 'SET_REGISTERS',
            data: createdRegisterData.list_RegisterItems._RegisterItems
          });
          getOtherRegisters();
        }
      } else {
        dispatch({
          type: 'SET_REGISTERS',
          data: createdRegisterData.list_RegisterItems._RegisterItems
        });
        // getOtherRegisters()
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  //query for fetching registers that are removed from ws and same ProjectID
  const [
    getOtherRegisters,
    {
      data: otherRegisterData,
      // error: otherRegistersError,
      loading: otherRegistersLoading
    }
  ] = useLazyQuery(listOtherRegistersQuery, {
    variables: {
      projectId: projectId,
      workspaceId: ''
    },
    onCompleted: () => {
      if (RouterState && RouterState.registerData) {
        let found = otherRegisterData.list_RegisterItems._RegisterItems.some(
          (register) => register._id === RouterState.registerData._id
        );
        if (!found) {
          dispatch({
            type: 'SET_OTHER_REGISTERS',
            data: otherRegisterData.list_RegisterItems._RegisterItems
          });
        } else if (found) {
          dispatch({
            type: 'SET_REGISTERS',
            data: otherRegisterData.list_RegisterItems._RegisterItems,
            id: RouterState.registerData._id
          });
        }
      } else {
        dispatch({
          type: 'SET_OTHER_REGISTERS',
          data: otherRegisterData.list_RegisterItems._RegisterItems
        });
      }
    },
    fetchPolicy: 'network-only'
  });

  const [
    updateRegister
    // { loading: updatingRegister, error: updateRegisterError },
  ] = useMutation(updateRegisterMutation);

  //Creating workspace mutation
  const [createWorkSpace] = useMutation(createWorkspaceMutation);

  //get workspace details query
  // const {
  //   data: workspaceDetails,
  //   error: getWorkSpaceError,
  //   loading: workspaceLoading,
  //   refetch,
  // } = useQuery(getWorkspaceQuery, {
  //   variables: {
  //     id: "",
  //   },
  //   onCompleted: (data) => {
  //     let name = data.getWorkspace.name;
  //     let registers = data.getWorkspace.registers;
  //     // setWorkSpaceInfo({
  //     //   name: name,
  //     //   registers: [...registers],
  //     // });
  //   },
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  // });
  //updating the workspace
  // const [updateWorkspace, { loading: updating, error: updateError }] =
  //   useMutation(updateWorkspaceMutation, {
  //     onCompleted: (data) => { },
  //     // refetchQueries: [{ query: getWorkspaceQuery, variables: { id: workspaceId } }]
  //   });
  const addRegisterToWorkspace = async (data, registers) => {
    let notSelected = registers.notSelected.filter((register) => register._id !== data._id);
    let selected = [...registers.selected, { ...data, workspaceId: RouterState.workspaceId }];
    dispatch({
      type: 'ADD_REGISTER_TO_WORKSPACE',
      data: {
        selected: selected,
        notSelected: notSelected
      }
    });
    try {
      let response = await updateRegister({
        variables: {
          id: data._id,
          workspaceId: RouterState.workspaceId
        }
      });
      if (response) {
      }
    } catch (error) {
      sentryError(error);
      // console.error(error);
    }
  };
  const removeRegisterFromWorkspace = async (data, registers) => {
    let selected = registers.selected.filter((register) => register._id !== data._id);
    let notSelectedRegisters = [...registers.notSelected, { ...data, workspaceId: '' }];
    dispatch({
      type: 'REMOVE_REGISTER_FROM_WORKSPACE',
      data: {
        selected: selected,
        notSelected: notSelectedRegisters
      }
    });
    try {
      let response = await updateRegister({
        variables: {
          id: data._id,
          workspaceId: ''
        }
      });
      if (response) {
        // dispatch({
        //   type: "REMOVE_REGISTER_FROM_WORKSPACE", data: {
        //     selected: selected,
        //     notSelected: notSelectedRegisters
        //   }
        // })
      }
    } catch (error) {
      console.error('ERROR', error);
    }
  };
  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        id: 'Action',
        Cell: ({ cell: { row } }) => {
          return (
            <>
              <span onClick={(e) => e.stopPropagation()}>
                <DarkButton type="button" onClick={() => removeRegisterFromWorkspace(row.original, registers)}>
                  <CloseIcon fill="#FFF" />
                  Remove
                </DarkButton>
              </span>
            </>
          );
        }
      }
    ],
    [registers.selected]
  );
  const createdColumns = useMemo(
    () => [
      ...SELECTEDCOLUMNS,
      {
        id: 'Action',
        Cell: ({ cell: { row } }) => {
          return (
            <>
              <span onClick={(e) => e.stopPropagation()}>
                <LightButton type="button" onClick={() => addRegisterToWorkspace(row.original, registers)}>
                  <PlusIcon stroke="#313B48" />
                  Add Register
                </LightButton>
              </span>
            </>
          );
        }
      }
    ],
    [registers.notSelected]
  );
  const selectedData = useMemo(() => (registers ? registers.selected : null), [registers, registers.selected]);
  const createdData = useMemo(() => (registers ? registers.notSelected : null), [registers, registers.notSelected]);

  const createNewRegister = async (values) => {
    if (RouterState?.workspaceId) {
      navigate(`/newprojects/workspaceconfig/${projectId}/new/register`, {
        state: {
          workspaceId: RouterState.workspaceId,
          workspaceName: RouterState.workspaceName,
          description: RouterState.description
        }
      });
    } else {
      let toastId = toast.loading('Redirecting Please Wait');
      const response = await createWorkSpace({
        variables: {
          name: values.workspaceName,
          projectId: projectId,
          createdAt: new Date().toISOString(),
          createdBy: userId,
          registers: [],
          workspaceDescription: values.description
        }
      });
      if (response) {
        let workspaceId = response.data.add_Workspace.result._id;
        toast.dismiss(toastId);
        navigate(`/newprojects/workspaceconfig/${projectId}/new/register`, {
          state: {
            workspaceId: workspaceId,
            workspaceName: values.workspaceName,
            description: values.description
          }
        });
      }
    }
  };

  const addMembers = async () => {
    if (RouterState?.workspaceId && projectId) {
      navigate(`/newprojects/workspaceconfig/${projectId}/addmembers/${RouterState.workspaceId}`, {
        state: {
          workspaceName: RouterState.workspaceName,
          registerData: RouterState.registerData
        }
      });
    } else {
      let toastId = toast.loading('Redirecting Please wait');
      try {
        const response = await createWorkSpace({
          variables: {
            name: formik.values.workspaceName,
            projectId: projectId,
            createdAt: new Date().toISOString(),
            createdBy: userId,
            registers: [],
            workspaceDescription: formik.values.description
          }
        });
        if (response) {
          let workspaceId = response.data.add_Workspace.result._id;
          toast.dismiss(toastId);
          navigate(`/newprojects/workspaceconfig/${projectId}/addmembers/${workspaceId}`, {
            state: {
              workspaceName: formik.values.workspaceName,
              registerData: []
            }
          });
        }
      } catch (error) {
        toast.dismiss(toastId);
        toast.error('OOPS Something went wrong. Try Again');
      }
    }
  };

  const handleNavigate = () => {
    navigate(`/project/${projectId}/workspaces`);
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CreateWorkspaceValidationSchema,
    onSubmit: createNewRegister,
    enableReinitialize: true
  });

  if (registerLoading || otherRegistersLoading) {
    return <Loader />;
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon handlenavigate={handleNavigate} />
            <NewTitleLeft>
              <PageTitle>Add Workspace</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button
              // type="submit"
              onClick={addMembers}
              disabled={
                formik.errors.workspaceName || (formik.values.workspaceName === '' && formik.values.description === '')
              }
            >
              Next
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <WorkspaceNameStyles>
            <InputWrapper style={{ marginLeft: '10px' }}>
              <InputField
                label="Workspace Name"
                type="text"
                name="workspaceName"
                value={formik.values.workspaceName}
                onChange={formik.handleChange}
                errors={formik.errors.workspaceName}
                touched={formik.touched.workspaceName}
                disabled={formik.isSubmitting}
              />
            </InputWrapper>
            <AssigneeWrapper>
              <Label>Created By</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar name={state.fullName} size={21} />
                </AvatarStyles>
                <Small>
                  {getDate()}
                  {''}
                  {getTime()}
                </Small>
              </AssigneeSeparator>
            </AssigneeWrapper>
          </WorkspaceNameStyles>
          {/* <InputWrapper> */}
          {/* <WorkspaceNameStyles> */}
          <TextAreaWrapper>
            <TextAreaField
              label="Description"
              type="text"
              name="description"
              id="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              touched={formik.touched.description}
              errors={formik.errors.description}
              disabled={formik.isSubmitting}
            />
          </TextAreaWrapper>
          {/* </WorkspaceNameStyles> */}
          {/* </InputWrapper> */}
          <RegisterContainer>
            <RegisterHeader>
              <PageTitle>Register Selection</PageTitle>
              <GreyButton type="submit">Create New Register</GreyButton>
            </RegisterHeader>
            <RegisterList>
              {selectedData?.length > 0 ? (
                selectedData && <Table columns={columns} data={selectedData} />
              ) : (
                <EmptyRegisterStyles>
                  <NoRegistersIcon />
                </EmptyRegisterStyles>
              )}
              {createdData?.length > 0
                ? createdData && <Table columns={createdColumns} data={createdData} />
                : // <EmptyRegisterStyles><NoRegistersIcon /></EmptyRegisterStyles>
                  null}
            </RegisterList>
          </RegisterContainer>
        </Container>
      </form>
    </>
  );
}

export default CreateNewWorkspace;
