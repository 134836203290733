import styled from '@emotion/styled';
import { ReactComponent as WhiteBackIcon } from '../../assets/icons/WhiteBackIcon.svg';
import { ReactComponent as PlatformSuiteIcon } from '../../assets/icons/PlatformSuiteWhiteLogo.svg';
import { Outlet } from 'react-router';
import { HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../styles/Header';
import { Link } from 'react-router-dom';
const InformationHeaderStyles = styled.div`
  background: hsla(215, 24%, 20%, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8.5rem;
  padding-left: 124px;
  padding-right: 160px;
`;
const IconStyles = styled.div``;

const InformationPageTitle = styled(PageTitle)`
  color: hsla(0, 0%, 100%, 1);
  font-weight: 600;
`;

function InformationPageLayout({ title }: { title: string }) {
  return (
    <>
      <InformationHeaderStyles>
        <HeaderLeft>
          <Link to="../">
            <WhiteBackIcon />
          </Link>
          <NewTitleLeft>
            <InformationPageTitle>{title}</InformationPageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <IconStyles>
            {' '}
            <PlatformSuiteIcon />
          </IconStyles>
        </TitleRight>
      </InformationHeaderStyles>
      <Outlet />
    </>
  );
}

export default InformationPageLayout;
