import { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../axiosInstance';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../components/styles/Header';
import { ReactComponent as InvoiceIcon } from '../../assets/icons/InvoiceIcon.svg';
import { InvoiceStatus } from './MonthlySubscriptionPage';
import Table from '../../components/Table';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION } from '../../graphql/operations/Queries/Organization/OrganizationQueries';
import Loader from '../../components/Loader';
import { ErrorPage } from '../Others';
import { useLocation } from 'react-router';
import { ReactComponent as NoInvoicesIcon } from '../../assets/icons/NoInvoices.svg';
import center from '../../components/styles/shared/center.css';
// import _ from 'lodash';

const NoInvoicesStyles = styled.div`
  ${center};
`;

const InvoiceContainer = styled.div`
  margin: 10px;
  margin-right: 55px;
  margin-left: 50px;
  padding-top: 25px;

  .invoice-date {
    a {
      margin-left: 5px;
    }
  }
  table {
    width: 90%;
    th {
      text-align: left !important;
    }
    tr {
      margin-left: 20px !important;
    }
    td {
      text-align: left;
    }
  }
`;

function InvoicesPage() {
  const location = useLocation();
  const { plan } = location?.state ? location?.state : { plan: null };
  const { state } = useContext(AppContext);
  const [invoices, setInvoices] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [invoiceLoading, setLoading] = useState(false);

  const {
    // data: getOrganization,
    loading,
    error
  } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: state.orgId
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (plan === 'month') {
        let subscriptionData = data?.get_Organization?.monthlySubscription;
        if (subscriptionData !== null && subscriptionData !== 'null') {
          let monthlySubscriptionData = JSON.parse(subscriptionData);
          setSubscriptionId(monthlySubscriptionData.subscriptionId);
        }
      } else if (plan === 'year') {
        let subscriptionData = data?.get_Organization?.yearlySubscription;
        if (subscriptionData !== null && subscriptionData !== 'null') {
          let yearlySubscriptionData = JSON.parse(subscriptionData);
          setSubscriptionId(yearlySubscriptionData.subscriptionId);
        }
      }
    }
  });

  useEffect(() => {
    const getInvoices = () => {
      setLoading(true);
      axiosInstance
        .get(`/payment/get-invoices?organizationId=${state.orgId}&subscriptionId=${subscriptionId}`)
        .then((res) => {
          let invoices = res.data?.invoices.length && res.data.invoices;
          // setLatestInvoice(latestInvoice)
          setInvoices(invoices);
          setLoading(false);
        })
        .catch((error) => {
          console.error('ERROR', error);
          setLoading(false);
        });
    };
    getInvoices();
  }, [subscriptionId]);

  const INVOICES_COLUMN = [
    {
      Header: 'Date',
      accessor: 'created',
      Cell: (props) => (
        <>
          <div className="invoice-date">
            {new Intl.DateTimeFormat('en-US').format(props.value * 1000)}
            <a href={props.cell.row.original?.invoice_pdf}>
              <InvoiceIcon />
            </a>
          </div>
        </>
      )
    },
    {
      Header: 'Amount',
      accessor: 'amount_due',
      Cell: ({ cell: { value } }) => (
        <>
          <div>US${value / 100}</div>
        </>
      )
    },
    {
      Header: 'Status',
      accessor: 'paid',
      Cell: ({ cell: { value } }) => (
        <>
          <InvoiceStatus>{value ? 'PAID' : 'UNPAID'}</InvoiceStatus>
        </>
      )
    },
    {
      Header: 'Account Name',
      accessor: 'account_name',
      Cell: ({ cell: { value } }) => (
        <>
          <div>{value}</div>
        </>
      )
    }
  ];

  const columns = useMemo(() => INVOICES_COLUMN, []);
  const data = useMemo(() => invoices, [invoices]);

  console.log('PLAN', plan);
  if (loading || invoiceLoading) {
    return <Loader />;
  }

  if (error || !plan || (plan !== 'month' && plan !== 'year')) {
    return <ErrorPage />;
  }
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>{plan === 'month' ? 'Monthly' : 'Yearly'} Invoice History</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
      </Header>
      <InvoiceContainer>
        {invoiceLoading && <Loader />}
        {!invoiceLoading && invoices.length > 0 ? (
          <Table columns={columns} data={data} />
        ) : !invoiceLoading && invoices.length === 0 ? (
          <NoInvoicesStyles>
            <NoInvoicesIcon />
          </NoInvoicesStyles>
        ) : null}
      </InvoiceContainer>
    </>
  );
}

export default InvoicesPage;
