import { useMemo, useState, useContext, useReducer, useEffect } from 'react';
import styled from '@emotion/styled';
import Table from '../../../components/Table';
import TableDropDown from '../../../components/TableComponents/TableDropDown';
import { useSidebar } from '../../../lib/SidebarState';
import {
  TaskStyles,
  DropdownDiv,
  reducer,
  getValue,
  isOverdue
  // isToday,
  // isMonth,
  // isUpcoming,
} from '../../../components/Tasks';
import Button from '../../../components/CustomButton';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_WORKSPACE_QUERY } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { AppContext } from '../../../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import Select from 'react-select';
import { editTaskOptions } from '../../../constants/DropDownOptions';
import { tableStatusStyles } from '../../../components/styles/StatusStyles';
import { ReactComponent as KanbanIcon } from '../../../assets/icons/kanbanIcon.svg';
import { ReactComponent as TodoIcon } from '../../../assets/icons/todoiconblue.svg';
import { HighPriority, LowPriority, MediumPriority } from '../../../components/TableComponents/Priority';
import { UPDATE_STATUS_TASK_MUTATION } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { PROJECT_LIST_TASKS_QUERY } from '../../../graphql/operations/Queries/Tasks/TaskQueries';
import { taskDropdownStyles } from '../../../components/styles/TaskDropdownStyles';
import WorkspaceKanban from '../../../components/KanbanComponents/WorkspaceKanban';
import { getProjectQuery } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
// import BackIcon from '../../../assets/SvgComponents/BackIcon';
// import { ToggleSettings, OptionItem } from '../../../components/OptionsMenu';
// import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../components/styles/Header';
// import { TitleRight } from '../../Library/FilesView/Files/Files';
// import { taskOptions } from "../../../constants/TasksDropdown";
// import { MOCKCOLUMNS } from "../../../constants/MockColumnData";
// import {
//   columnsFromBackend,
//   // KanbanColumns,
// } from "../../../constants/KanbanData";
// import WorkspaceKanban from "../../../components/KanbanComponents/WorkspaceKanban";
const TaskContainer = styled.div`
  margin-left: 45px;
  margin-right: 45px;
`;

const initialState: any = {};

function ProjectTasks() {
  const { state } = useContext(AppContext);
  const { state: taskState } = useLocation();
  const { projectId } = useParams();
  const [toggleKanban, setToggleKanban] = useState(false);
  const { sidebarOpen } = useSidebar();
  const navigate = useNavigate();
  const [taskdata, dispatch] = useReducer(reducer, initialState);
  const {
    data: apidata,
    error,
    loading,
    refetch
  } = useQuery(PROJECT_LIST_TASKS_QUERY, {
    variables: {
      organizationId_projectId_typeId: `${state.orgId}_${projectId}`
    },
    onCompleted: (data) => {
      if (taskState) {
        let found = data.list_TaskItems._TaskItems.some((task) => task._id === taskState._id);
        if (!found) {
          if (taskState.assignedTo === state.userId) {
            let newarr = [...data.list_TaskItems._TaskItems, taskState];
            dispatch({
              type: 'TABLE',
              data: newarr
            });
          } else {
            dispatch({
              type: 'TABLE',
              data: data.list_TaskItems._TaskItems
            });
          }
        } else {
          let newarr = [...data.list_TaskItems._TaskItems];
          const indexOfItemInArray = newarr.findIndex((task) => task._id === taskState._id);
          newarr.splice(indexOfItemInArray, 1, taskState);
          dispatch({
            type: 'TABLE',
            data: newarr
          });
        }
      } else {
        dispatch({
          type: 'TABLE',
          data: data.list_TaskItems._TaskItems
        });
      }
    },
    fetchPolicy: 'network-only'
  });

  // const [filterTasks,] =
  //   useLazyQuery(PROJECT_LIST_TASKS_QUERY, {
  //     fetchPolicy: "network-only",
  //     onCompleted: (data) => {
  //       if (!toggleKanban) {
  //         dispatch({
  //           type: "TABLE",
  //           data: data?.list_TaskItems._TaskItems,
  //         });
  //       } else if (toggleKanban) {
  //         let object = {
  //           today: [],
  //           month: [],
  //           upcoming: [],
  //           done: [],
  //         };
  //         object.today = data?.list_TaskItems._TaskItems.filter((task) =>
  //           isToday(task.dueDate)
  //         ).filter((task) => task.status !== "done");
  //         object.month = data?.list_TaskItems._TaskItems.filter((task) =>
  //           isMonth(task.dueDate)
  //         ).filter((task) => task.status !== "done");
  //         object.upcoming = data?.list_TaskItems._TaskItems.filter((task) =>
  //           isUpcoming(task.dueDate)
  //         ).filter((task) => task.status !== "done");
  //         object.done = data?.list_TaskItems._TaskItems.filter((task) => task === "done");
  //         dispatch({
  //           type: "TABLE",
  //           data: data?.list_TaskItems._TaskItems,
  //         });
  //         dispatch({
  //           type: "KANBAN",
  //         });
  //       }
  //     },
  //   });

  const {
    data: workspaces,
    loading: workspaceLoading,
    error: workspaceError
  } = useQuery(LIST_WORKSPACE_QUERY, { fetchPolicy: 'network-only' });
  const {
    data: project
    // loading: projectLoading,
    // error: projectError,
  } = useQuery(getProjectQuery, { variables: { id: projectId }, fetchPolicy: 'network-only' });
  const [updateTask] = useMutation(UPDATE_STATUS_TASK_MUTATION, {
    fetchPolicy: 'no-cache'
  });
  const updateStatus = (selectedOption: any, id: string, data) => {
    let actionType = selectedOption.value === 'done' ? 'completed' : 'status';
    let messageType = selectedOption.value === 'done' ? 'completed' : 'status';
    let userToNotify = selectedOption.value === 'done' ? data?.assignee : data?.assignedTo;
    let variables =
      userToNotify !== state?.userId
        ? {
            status: selectedOption.value,
            id: id,
            type: data?.type,
            typeId: data?.typeId,
            notificationData: {
              actionType,
              messageType,
              taskName: data?.title,
              dueDate: data?.dueDate,
              workspaceName: getWorkspaceName(data?.typeId),
              projectName: getProjectName(data?.projectId),
              workspaceId: data?.typeId,
              projectId: data?.projectId,
              organizationId: state?.orgId,
              userToNotify: userToNotify,
              host: window.location.origin,
              status: selectedOption.value,
              assignedToName: data?.assignedToDetails.name
            }
          }
        : { status: selectedOption.value, id: id };
    updateTask({
      variables
    }).then((res) => {
      dispatch({
        type: 'UPDATESTATUS',
        data: { id: id, status: selectedOption.value }
      });
    });
  };

  // const handleTaskChange = async (option: { value: string; label: string }) => {
  //   if (option.value === "assignedtask") {
  //     let res = await filterTasks({
  //       variables: {
  //         assignedTo: state.userId,
  //         assignee: "",
  //       },
  //     });
  //   } else if (option.value === "delegatedtask") {
  //     let res = await filterTasks({
  //       variables: {
  //         assignee: state.userId,
  //         assignedTo: "",
  //       },
  //     });
  //   }
  // };

  //these 2 functions are kind of doing the same . so have to optimise later.

  const getWorkspaceName = (id: string) => {
    let found = workspaces?.list_WorkspaceItems?._WorkspaceItems.find((workspace) => workspace._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };
  const getProjectName = (id: string) => {
    let found = project.list_ProjectItems._ProjectItems.find((project) => project._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Task',
        accessor: 'title',
        Cell: ({ cell: { value } }) => <div className="text-wrap">{value}</div>
      },

      {
        Header: 'Workspace',
        accessor: 'typeId',
        Cell: ({ cell: { value } }) => getWorkspaceName(value, workspaces)
      },
      {
        Header: 'Assigned To',
        accessor: 'assignedToDetails.name',
        Cell: ({ cell: { value } }) => <CustomAvatar isTable={true} name={value} size={21} />
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { row, value } }) => {
          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Select
                name="status"
                options={editTaskOptions}
                defaultValue={getValue(editTaskOptions, value)}
                styles={{ ...tableStatusStyles }}
                width="200px"
                menuPortalTarget={document.body}
                onChange={(selectedOption) => {
                  updateStatus(selectedOption, row.original._id, row.original);
                }}
              />
            </span>
          );
        }
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ cell: { value } }) =>
          value === 'high' ? <HighPriority /> : value === 'medium' ? <MediumPriority /> : <LowPriority />
      },
      {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ cell: { value } }) => (
          <span>
            {new Date(value).toLocaleDateString('en-GB', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}
          </span>
        )
      }
    ],
    [project, workspaces, taskdata]
  );
  const data = useMemo(() => taskdata, [taskdata]);
  useEffect(() => {
    refetch();
  }, []);
  const viewTask = (id: string) => {
    navigate(`/project/${projectId}/tasks/${id}`);
  };
  const createTask = () => {
    navigate(`/project/${projectId}/tasks/new`);
  };
  const toggleAndFetch = (toggleState: boolean) => {
    if (toggleState) {
      dispatch({
        type: 'KANBAN'
      });
    }
    if (!toggleState) {
      dispatch({
        type: 'BACKTOTABLE',
        data: apidata.list_TaskItems._TaskItems
      });
    }
    setToggleKanban(toggleState);
  };
  // const editProject = () => {
  //   navigate(`/editproject/${projectId}`);
  // };
  if (loading || workspaceLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error || workspaceError) return <ErrorPage />;
  return (
    <>
      {/* <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle> {project?.get_Project?.name} </PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <ToggleSettings>
            <OptionItem onClick={editProject}>Edit Project</OptionItem>
          </ToggleSettings>
     
        </TitleRight>
      </Header> */}
      <TaskContainer>
        <TaskStyles isOpen={sidebarOpen}>
          <div className="tasks-header">
            <h4 className="tasks-title">Todo-List</h4>
            <Select
              options={[{ value: 'Everyone', label: 'Everyone' }]}
              name="tasks"
              styles={taskDropdownStyles}
              isSearchable={false}
              defaultValue={{ value: 'Everyone', label: 'Everyone' }}
              className="task-dropdown"
            />
            {!toggleKanban && <TodoIcon onClick={() => toggleAndFetch(true)} />}
            {toggleKanban && <KanbanIcon onClick={() => toggleAndFetch(false)} />}
          </div>
          <Button onClick={createTask}>+ New Task</Button>
        </TaskStyles>
        {!toggleKanban && (
          <DropdownDiv>
            <div className="drop-item">
              <TableDropDown state={true} text="Today">
                {data.today && (
                  <Table
                    columns={columns}
                    data={data?.today}
                    clickHandler={viewTask}
                    getRowProps={(row) => {
                      return {
                        style: {
                          color: isOverdue(row.values.dueDate) && row.values.status !== 'done' ? '#D14747' : 'black'
                        }
                      };
                    }}
                  />
                )}
              </TableDropDown>
            </div>
            <div className="drop-item">
              <TableDropDown state={true} text="This Month">
                {data.month && <Table columns={columns} data={data?.month} clickHandler={viewTask} />}
              </TableDropDown>
            </div>
            <div className="drop-item">
              <TableDropDown state={data?.upcoming?.length > 0 && true} text="Coming Up">
                {data.upcoming && <Table columns={columns} data={data?.upcoming} clickHandler={viewTask} />}
              </TableDropDown>
            </div>
            <div className="drop-item">
              <TableDropDown state={data?.done?.length > 0 && true} text="Completed">
                {data.done && <Table columns={columns} data={data?.done} clickHandler={viewTask} />}
              </TableDropDown>
            </div>
            {/*  */}
          </DropdownDiv>
        )}
        {toggleKanban && <WorkspaceKanban columns={taskdata} setColumns={dispatch} updateTask={updateTask} />}
      </TaskContainer>
    </>
  );
}

export default ProjectTasks;
