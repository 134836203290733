import { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { columnsFromBackend } from '../constants/KanbanData';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import TaskCard from './KanbanComponents/TaskCard';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from '../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../constants/LoaderDefaultOptions';

import { ErrorPage } from '../pages/Others/ErrorPage';
import { LIST_TASKS_QUERY } from '../graphql/operations/Queries/Tasks/TaskQueries';
import { UPDATE_STATUS_TASK_MUTATION } from '../graphql/operations/Mutations/Tasks/TaskMutations';
import { LIST_PROJECTS_QUERY } from '../graphql/operations/Queries/Projects/ProjectQueries';
import { LIST_WORKSPACE_QUERY } from '../graphql/operations/Queries/Workspaces/WorkspaceQueries';

export type TitleProps = {
  title: string;
};

export const handleTitleStyles = (title: string) => {
  switch (title) {
    case 'To-do':
      return 'color: #10957D; background: rgba(16, 149, 125, 0.15);';
    case 'In Progress':
      return 'color: #C37F0F; background: rgba(255, 242, 220, 1);';
    case 'Done':
      return 'color: #26303E; background: rgba(237, 240, 240, 1);';
    default:
      return 'color: #10957D; background: rgba(16, 149, 125, 0.15);';
  }
};

const Container = styled.div`
  display: flex;
`;

export const TaskList = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
`;

export const TaskColumnStyles = styled.div`
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 60vh;
`;

export const Title = styled.span<TitleProps>`
  /* color: #10957D;
  background: rgba(16, 149, 125, 0.15); */
  padding: 2px 10px;
  border-radius: 5px;
  min-width: 90px;
  align-self: flex-start;
  text-align: center;
  ${({ title }) => handleTitleStyles(title)};
`;

const Kanban = () => {
  const [columns, setColumns] = useState<any>(columnsFromBackend);
  const { state } = useContext(AppContext);
  const { error, loading, refetch } = useQuery(LIST_TASKS_QUERY, {
    variables: {
      assignee: state.userId
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let inProgress = data.list_TaskItems._TaskItems.filter((task) => task.status === 'inProgress');
      let todo = data.list_TaskItems._TaskItems.filter((task) => task.status === 'todo');
      let done = data.list_TaskItems._TaskItems.filter((task) => task.status === 'done');
      let result = Object.entries(columns).map((item: any) => {
        if (item[1].title === 'In Progress') {
          item[1].items = [...inProgress];
          return item;
        } else if (item[1].title === 'To-do') {
          item[1].items = [...todo];
          return item;
        } else {
          item[1].items = [...done];
          return item;
        }
      });
      setColumns(Object.fromEntries(result));
    }
  });
  const [
    updateTask
    // { error: mutationError, loading: mutationLoading }
  ] = useMutation(UPDATE_STATUS_TASK_MUTATION, {
    fetchPolicy: 'no-cache'
  });
  const {
    data: projects
    // loading: projectLoading,
    // error: projectError,
  } = useQuery(LIST_PROJECTS_QUERY, { fetchPolicy: 'network-only' });
  const {
    data: workspaces
    // loading: workspaceLoading,
    // error: workspaceError,
  } = useQuery(LIST_WORKSPACE_QUERY, { fetchPolicy: 'network-only' });
  const getProjectName = (id: string) => {
    let found = projects.list_ProjectItems._ProjectItems.find((project) => project._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };

  const getWorkspaceName = (id: string) => {
    let found = workspaces?.list_WorkspaceItems?._WorkspaceItems.find((workspace) => workspace._id === id);
    if (found) {
      return found.name;
    }
    return 'PERSONAL';
  };
  const onDragEnd = async (result: DropResult, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
      try {
        let actionType = destination.droppableId === 'done' ? 'completed' : 'status';
        let messageType = destination.droppableId === 'done' ? 'completed' : 'status';
        let userToNotify = destination.droppableId === 'done' ? removed.assignee : removed.assignedTo;
        let variables =
          userToNotify !== state?.userId
            ? {
                status: destination.droppableId,
                id: removed._id,
                type: removed.type,
                typeId: removed.typeId,
                notificationData: {
                  actionType,
                  messageType,
                  taskName: removed?.title,
                  dueDate: removed?.dueDate,
                  workspaceName: getWorkspaceName(removed.typeId),
                  projectName: getProjectName(removed.projectId),
                  projectId: removed.projectId,
                  workspaceId: removed.typeId,
                  organizationId: state?.orgId,
                  userToNotify,
                  host: window.location.origin,
                  status: destination.droppableId
                }
              }
            : { status: destination.droppableId, id: removed._id, type: removed.type, typeId: removed.typeId };
        let response = await updateTask({
          variables: variables
        });
        if (response) {
          refetch();
        }
      } catch (error) {
        console.error('ERROR', error);
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };
  if (error) {
    return <ErrorPage />;
  }
  if (loading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
      <Container>
        <TaskColumnStyles>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                    <Title title={column.title}>{column.title}</Title>
                    {column.items.map((item: any, index: any) => (
                      <TaskCard key={item.id} item={item} index={index} />
                    ))}
                    {provided.placeholder}
                  </TaskList>
                )}
              </Droppable>
            );
          })}
        </TaskColumnStyles>
      </Container>
    </DragDropContext>
  );
};

export default Kanban;
