import styled from '@emotion/styled';
import React from 'react';
import { TotalSeatsDiv } from '../../components/Organisation/Placeholders/OrganizationDetails';
import capitalizeFirstLetter from '../../lib/CapitaliseFirstLetter';
import { PlanType } from '../Organization/ViewOrganization';

const SeatsSummary = styled(TotalSeatsDiv)`
  width: 90%;
  margin-left: 35px;
  border-radius: 5px;
  padding: 1rem;
  padding-left: 32px;
  padding-bottom: 16px;
`;

const SeatsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  span {
    font-size: 16px;
    /* color: #1c1c1c; */
    &:first-of-type {
      font-weight: 600;
    }
    &:nth-of-type(2) {
      font-weight: 400;
    }
  }
`;
type SeatSummaryProps = {
  originalQuantity: number;
  takenSeats: number;
  plan: PlanType;
};

function SeatSummary({ originalQuantity, takenSeats, plan }: SeatSummaryProps) {
  const planType = plan === 'month' ? 'monthly' : 'yearly';
  return (
    <SeatsSummary>
      <SeatsDiv>
        <span>Total {capitalizeFirstLetter(planType)} Seats</span>
        <span>{originalQuantity} Seats</span>
      </SeatsDiv>
      <SeatsDiv>
        <span>Available Seats</span>
        <span>{originalQuantity - takenSeats} Seats</span>
      </SeatsDiv>
    </SeatsSummary>
  );
}

export default SeatSummary;
