import * as yup from "yup";

const NoteValidation = yup.object().shape({
  title: yup
    .string()
    .min(2, "Title is too Short!")
    .max(50, "Title is too Long!")
    .required("A Title for note is required"),
});

export default NoteValidation;
