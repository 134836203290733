import styled from '@emotion/styled';
import Button from './CustomButton';
import { useState } from 'react';
import { ListItemStyles } from './ListItem';
import { ProjectName } from './ProjectList';
import { ReactComponent as ProjectIcon } from '../assets/icons/ProjectTitleIcon.svg';
import { ReactComponent as TaskIcon } from '../assets/icons/tast-tick.svg';
import { ReactComponent as DoneTaskIcon } from '../assets/icons/done-task.svg';
import { ReactComponent as FileIcon } from '../assets/icons/NewFileIcon.svg';
import { ReactComponent as DropdownIcon } from '../assets/icons/GreenCaret.svg';
import { CardDropDownStyles } from './Dashboard/CardDropdown';
import Chats from './Chat/Chats_old';
import { useSidebar } from '../lib/SidebarState';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import DashboardChat from './Chat/DashboardChat/DashboardChat';
// import { DateStyle } from './ChooseProject'
// import { ToggleProps } from './TableComponents/TableDropDown'

interface CardProps {
  open: boolean;
  sidebarOpen: boolean;
}

const CardStyles = styled('div')<CardProps>`
  border-radius: 5px;
  margin-top: 25px;
  margin-left: 20px;
  background-color: ${(props) => (props.open ? 'white' : 'transparent')};
  background-color: white;
  cursor: pointer;
  overflow-y: hidden;
  /* max-width: 48%; */
  max-height: 40vh;
  width: 100%;
  /* flex: 50%; */
  /* flex-grow: 0.5; */
  /* min-height: 40vh; */
  /* height: auto; */
  /* border: 1px solid white; */
  /* flex: 1; */
  /* width: 40%; */
  /* width: 50vw; */
  /* position: absolute; */
  /* width: ${(props) => (props.sidebarOpen ? '26vw' : '40vw')} */
`;

const CardHeader = styled.div`
  min-width: 48%;
  min-height: 59px;
  /* width: 100%; */
  background-color: #26303e;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  padding: 0 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* position: sticky; */
  align-self: flex-start;
`;

const CardBody = styled.div`
  border-radius: 5px;
  overflow-y: auto;
  max-height: 200px;
  min-height: 200px;
  /* display: flex ; */

  /* min-height: 246px; */
  /* background-color: white; */
  /* padding-top: 20px; */
  /* position: absolute; */
  /* border: 1px solid blue; */
`;
// const ListDashboardItemStyles = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   cursor: pointer;
//   /* width: 70rem; */
//   border-bottom: 1px solid var(--table-border);
// `;
const ItemInfo = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  /* padding-right: 25px; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* max-width: 50%; */
`;

const CardTitleDiv = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  /* gap: 15px; */
`;

const CardTitle = styled.h4`
  font-weight: 600;
  margin-left: 15px;
`;

export const NoDataContainer = styled.div`
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: center;
  background: #f6f5f5;
  min-height: 102px;
  color: #8c8b8b;
  border-radius: 5px;
  margin-top: 5%;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ProjectDate = styled.div`
  /* color: var(--text-light); */
  display: flex;
  align-items: center;
  padding-right: 2rem;
  /* word-wrap: unset; */
`;
const DashboardCard = ({ title, data }: { title: string; data: any }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { sidebarOpen } = useSidebar();

  // const numAscending = [...data].sort((a, b) => a.dueDate - b.dueDate);

  const viewAll = () => {
    switch (title) {
      case 'Task Manager':
        return '/tasks';
      case 'Projects':
        return '/projects';
      case 'Library':
        return '/files';
      case 'Messages':
        return '/messages';
      default:
        return '/';
    }
  };

  const goToPage = (id: string) => {
    switch (title) {
      case 'Task Manager':
        navigate(`/dashboard/tasks/${id}`);
        break;
      case 'Projects':
        navigate(`/project/${id}`);
        break;
      case 'Library':
        navigate(`/files/${id}`);
        break;
      default:
        navigate(`/dashboard`);
    }
    // navigate to a task
    // navigate to a file
    // navigate to a project
  };

  const getIcon = (item) => {
    switch (title) {
      case 'Task Manager':
        if (item?.status === 'done') return <DoneTaskIcon />;
        else {
          return <TaskIcon />;
        }
      case 'Projects':
        return <ProjectIcon />;
      case 'Library':
        return <FileIcon />;
      default:
        return <ProjectIcon />;
    }
  };

  const getTitle = (data) => {
    switch (title) {
      case 'Task Manager':
        return data.title;
      case 'Projects':
        return data.name;
      case 'Library':
        return data.name;
      default:
        return '';
    }
  };
  const numAscending = [].concat(data).sort((a, b) => (a.dueDate < b.dueDate ? 1 : -1));

  return (
    <CardStyles sidebarOpen={sidebarOpen} open={open}>
      <CardHeader>
        <CardTitleDiv>
          <CardDropDownStyles open={open} onClick={() => setOpen(!open)}>
            <button className="caret">
              <DropdownIcon />
            </button>
            <CardTitle>{title}</CardTitle>
          </CardDropDownStyles>
        </CardTitleDiv>
        <Link to={viewAll()}>
          <Button>View All</Button>
        </Link>{' '}
        {/*onClickHandler */}
      </CardHeader>
      {title === 'Messages'
        ? open && (
            <div style={{ height: '200px', overflowY: 'auto' }}>
              <DashboardChat
              // closeFunction={() => setOpenMessages(false)}
              // setOpenMessages={setOpenMessages}
              />
            </div>
          )
        : null}
      {open && title !== 'Messages' && (
        <CardBody>
          {numAscending.length > 0 &&
            numAscending.map((item) => {
              return (
                <ListItemStyles key={item._id} onClick={() => goToPage(item._id)}>
                  <ItemInfo>
                    {getIcon(item)}
                    <ProjectName>{getTitle(item)}</ProjectName> {/*title as props*/}
                  </ItemInfo>
                  {title === 'Task Manager' && (
                    <ProjectDate>
                      {/* <DateStyle> */}
                      {new Date(item?.dueDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                      {/* </DateStyle> */}
                    </ProjectDate>
                  )}
                </ListItemStyles>
              );
            })}
          {data.length <= 0 && (
            <NoDataContainer>
              <p>No Items Available</p>
            </NoDataContainer>
          )}
        </CardBody>
      )}
    </CardStyles>
  );
};

export default DashboardCard;
