import styled from '@emotion/styled';
import React, { useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Navigate, useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../../axiosInstance';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/CustomButton';
import MemberItem, { NameTag } from '../../../components/MemberItem';
import Loader from '../../../components/Loader';
import Modal from '../../../components/Modal';
import InviteMemberToWorkspaceModal from '../../../components/Modals/InviteMemberToWorkspaceModal';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { ItemContainer, ItemStyles } from '../../../components/styles/ListItemStyles';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../components/styles/ModalStyles';
import { EmptyValueContainer } from '../../../components/styles/NoValuesPresentStyles';
import { useToggle } from '../../../lib/UseToggle';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { ModalText } from '../Registers/CreateNewRegister';
import { CheckBoxContainer, CheckBoxListItem } from './WorkspaceMembers';
import * as yup from 'yup';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { Small } from '../../../components/styles/SmallText';
import { Link } from 'react-router-dom';
import { TooltipStyles } from '../../Organization/ViewOrganization';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Tooltip } from '../../../components/Tooltip';

// import axios from "axios";
// import { userInfo } from "os";
// import { Text } from "../../components/styles/Text";

// function comparer(otherArray) {
//   return function (current) {
//     return (
//       otherArray.filter(function (other) {
//         return other.userId === current.userId;
//       }).length === 0
//     );
//   };
// }

const SubHeading = styled.h3`
  font-weight: 600;
`;
const OutsideMembersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
`;

function EditOtherWorkspaceMembers() {
  let navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const [selectedWorkspaceIds, setSelectedWorkspaceIds] = useState([]);
  const [workspaceMembers, setWorkspaceMembers] = useState([]);
  const [organizationMembers, setOrganizationMembers] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [isOpen, toggle] = useToggle(false);
  const [isInviteMemberModalOpen, toggleInviteMemberModal] = useToggle(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noInvitedMembers, setNoInvitedMembers] = useState(false);
  const [invitedEmailIds, setInvitedEmailIds] = useState<string[]>([]);
  const orgMembers = useSelector((state) => state.orgMembers.users);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();

  const { state } = useContext(AppContext);
  const [loadingOrgMembers, setLoadingOrgMembers] = useState(false);
  const [loadingWorkspaceIds, SetLoadingWorkspaceIds] = useState(false);
  useEffect(() => {
    setLoadingOrgMembers(true);
    axiosInstance
      .get(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`)
      .then((response) => {
        if (response.data.data) {
          setLoadingOrgMembers(false);
          if (response.data.data.length > 0) {
            let ids = response.data.data.map((user) => {
              return user.userId;
            });
            let fulldetails = response.data.data.map((user) => {
              return {
                userId: user.userId,
                mappingId: user.id,
                fullName: user.fullName,
                orgId: user.organizationId,
                value: user.fullName
              };
            });
            setUserDetails(fulldetails);
            setSelectedWorkspaceIds(ids);
            setWorkspaceMembers(ids);
            let noInvitedMembers = fulldetails.filter((user) => user.orgId !== state.orgId);
            if (noInvitedMembers.length === 0) {
              setNoInvitedMembers(true);
            }
          }
        }
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  }, []);

  useEffect(() => {
    if (projectId && workspaceId && state.orgId) {
      SetLoadingWorkspaceIds(true);
      axiosInstance
        .get(`/project/members?projectId=${projectId}`)
        .then((response) => {
          if (response.data) {
            setLoading(false);
            SetLoadingWorkspaceIds(false);
            let members = response.data.data.projectDirectory.map((user) => {
              return {
                id: user.userId,
                value: user.userData.fullName,
                label: user.userData.fullName,
                email: user.userData.email,
                checked: false,
                orgId: state.orgId
              };
            });
            setOrganizationMembers(members);
          }
        })
        .catch((error) => {
          console.error('ERROR', error);
        });
    }
  }, []);
  let schema = yup.object().shape({
    email: yup.string().email()
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleOnChange = (position, selected) => {
    if (selectedWorkspaceIds.includes(selected.id)) {
      let ids = selectedWorkspaceIds.filter((id) => id !== selected.id);
      // let newIds = selectedWorkspacenewIds.filter((id) => id != selected.id)
      setSelectedWorkspaceIds(ids);
      // setSelectedWorkspacenewIds(newIds)
    } else {
      let ids = [...selectedWorkspaceIds, selected.id];
      setSelectedWorkspaceIds(ids);
      // setSelectedWorkspacenewIds([...selectedWorkspacenewIds, selected.id])
    }
  };

  const inviteMemberToWorkspace = () => {
    let toastId;
    schema
      .isValid({
        email: email
      })
      .then((valid) => {
        if (valid && email !== '') {
          toastId = toast.loading('Sending Email');
          let arrayOfEmailIds = orgMembers && orgMembers.length > 0 && orgMembers.map((user) => user.email);
          if (arrayOfEmailIds.includes(email)) {
            throw new Error('ExistingUser');
          }
          setSendingEmail(true);
          setEmailError(false);
          let values = {
            workspaceId: workspaceId,
            email: email.toLowerCase(),
            inviterId: state.userId,
            entityType: 'WORKSPACE',
            entityId: workspaceId,
            projectId: projectId,
            organizationId: state.orgId,
            host: window.location.origin
          };
          axiosInstance
            .post(`workspaceInvite`, {
              ...values
            })
            .then((res) => {
              setSendingEmail(false);
              if (res.data) {
                setInvitedEmailIds([...invitedEmailIds, email.toLowerCase()]);
                toggleInviteMemberModal();
                toast.dismiss(toastId);
                toast.success('Invite Sent.');
              }
            })
            .catch((error) => {
              setSendingEmail(false);
              toast.dismiss(toastId);
              console.error(error);

              if (error?.message) {
                toast.error(error?.response?.data?.message);
              } else {
                toast.error('Something went wrong try again');
              }
            });
        } else {
          setSendingEmail(false);
          setEmailError(true);
          toast.dismiss(toastId);
          toast.error('Please enter correct email address');
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        if (error.message === 'ExistingUser') {
          toast.error(`This user's email is already part of organization.`);
          toast('Press the Add Members button to add members of your organization.');
        }
        console.error(error);
      });
  };

  const removeUser = (member) => {
    let newMembers = organizationMembers.slice();
    newMembers.forEach((user) => {
      if (user.id === member.id) {
        user.checked = !user.checked;
      }
    });
    setOrganizationMembers(newMembers);
    let ids = selectedWorkspaceIds.filter((id) => id !== member.id);
    setSelectedWorkspaceIds(ids);
  };
  const saveWorkspace = () => {
    // if (selectedWorkspaceIds.length > 0) {
    //   setLoading(true);
    //   // const existinguserIds=userDetails.map(a => a.id);
    //   const toastId = toast.loading('Saving...');
    //   const selectedWorkspacedata = selectedWorkspaceIds.map((id) => {
    //     return {
    //       userId: id,
    //       entityType: 'WORKSPACE',
    //       entityId: workspaceId
    //     };
    //   });
    //   const notifyDeteleUserIds: any[] = [];
    //   const itemsToDeleteIds: { id: any }[] = [];

    //   const itemsToDelete = userDetails.length && userDetails.filter(comparer(selectedWorkspacedata));
    //   itemsToDelete?.length &&
    //     itemsToDelete.map((data) => {
    //       itemsToDeleteIds.push({
    //         id: data.mappingId
    //       });
    //       if (state?.userId !== data.userId) {
    //         notifyDeteleUserIds.push(data.userId);
    //       }
    //     });
    //   const itemsToAdd = userDetails.length && selectedWorkspacedata.filter(comparer(userDetails));
    //   const notifyAddUserIds =
    //     itemsToAdd?.length &&
    //     itemsToAdd
    //       .filter((userData) => userData.userId !== state?.userId)
    //       .map((data) => {
    //         return data.userId;
    //       });

    //   const data = {
    //     itemsToDelete: itemsToDeleteIds,
    //     itemsToAdd: itemsToAdd
    //   };
    //   axiosInstance
    //     .patch(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`, data)
    //     .then((res) => {
    //       axiosInstance.post('/notification', {
    //         entityType: 'WORKSPACE',
    //         entityId: workspaceId,
    //         actionType: 'invite',
    //         actionBy: state?.userId,
    //         actionByName: state?.fullName,
    //         actionByProfilePic: state?.profilePhoto,
    //         message: `<p><span class=blackBold>${state?.fullName}</span> added you to the workspace</p>`,
    //         userToNotify: notifyAddUserIds,
    //         workspaceId: workspaceId,
    //         projectId: projectId,
    //         organizationId: state?.orgId
    //       });
    //       axiosInstance.post('/notification', {
    //         entityType: 'WORKSPACE',
    //         entityId: workspaceId,
    //         actionType: 'delete',
    //         actionBy: state?.userId,
    //         actionByName: state?.fullName,
    //         actionByProfilePic: state?.profilePhoto,
    //         message: `<p><span class=blackBold>${state?.fullName}</span> removed you from the workspace</p>`,
    //         userToNotify: notifyDeteleUserIds,
    //         workspaceId: workspaceId,
    //         projectId: projectId,
    //         organizationId: state?.orgId
    //       });
    //       toast.success('Saved', { id: toastId });
    //       setLoading(false);
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       console.error('ERROR IN HANDLING USER CHANGE', error);
    //     });
    // }
    if (selectedWorkspaceIds.length > 0) {
      setLoading(true);
      const toastId = toast.loading('Saving...');
      const data = {
        userIds: selectedWorkspaceIds,
        projectId,
        workspaceId,
        organizationId: state.orgId
      };
      axiosInstance
        .patch(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`, data)
        .then((res) => {
          toast.success('Saved', { id: toastId });
          setLoading(false);
          navigate(`/project/workspace/${projectId}/${workspaceId}`);
        })
        .catch((error) => {
          setLoading(false);
          console.error('ERROR IN HANDLING USER CHANGE', error);
        });
    } else if (selectedWorkspaceIds.length === 0) {
      toast.error('A Workspace should contain atleast one user.');
    }
  };
  if (loadingOrgMembers || loadingWorkspaceIds) {
    return <Loader />;
  }

  // console.log("SELECTED WORKSPACE IDS", selectedWorkspaceIds)

  return (
    <>
      <Toaster />
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Workspace Members</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Tooltip tooltipText={'Click here to invite members to this workspace who are from other organizations.'}>
            <Button disabled={loading} onClick={toggleInviteMemberModal}>
              Send Invite
            </Button>
          </Tooltip>
          <Tooltip tooltipText={'Click here to add members from your project to the workspace.'}>
            <Button disabled={loading} onClick={toggle}>
              Add Members
            </Button>
          </Tooltip>
          <Button disabled={loading} onClick={saveWorkspace} secondary>
            Save
          </Button>
        </TitleRight>
      </Header>
      <Container>
        <ItemContainer>
          {organizationMembers.length > 0 &&
            organizationMembers.map((member, index) => {
              return member && selectedWorkspaceIds.includes(member.id) ? (
                <MemberItem key={index} removeUser={removeUser} name={member}></MemberItem>
              ) : null;
            })}
        </ItemContainer>
      </Container>
      <Container>
        <ItemContainer>
          {userDetails.length > 0 && (
            <>
              <OutsideMembersWrapper>
                <SubHeading>Invited/Outside Organization Members</SubHeading>
              </OutsideMembersWrapper>
            </>
          )}
          {
            noInvitedMembers ? (
              <EmptyValueContainer>No invited members or members outside organization.</EmptyValueContainer>
            ) : (
              // {
              userDetails.length > 0 &&
              userDetails.map((user, index) => {
                return user && user.orgId !== state.orgId ? <MemberItem key={index} name={user} /> : null;
              })
            )
            // }
          }
          {invitedEmailIds.length > 0 &&
            invitedEmailIds.map((email: string, index) => {
              return (
                <ItemStyles>
                  <NameTag>
                    <Avatar round={true} size="21" name={email} textSizeRatio={2} />
                    <p className="name">{email}</p>
                  </NameTag>
                </ItemStyles>
              );
            })}
        </ItemContainer>
      </Container>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Workspace Members</ModalTitle>
          <ModalBody>
            <ModalText>Add Members to Workspace</ModalText>
            <Small>
              <strong>Note:</strong>If the members of your organization is not visible here , they have not been added
              to the project.Please add members to your project before adding them to your workspace. Click{' '}
              <Link to={`/project/members/${projectId}/add`}>here</Link> to add members to your project.
            </Small>
            <CheckBoxContainer>
              {organizationMembers.length > 0 &&
                organizationMembers.map((member, index) => {
                  return (
                    <React.Fragment key={index}>
                      <CheckBoxListItem>
                        <label>
                          <Checkbox
                            checked={selectedWorkspaceIds.includes(member.id)}
                            onChange={() => handleOnChange(index, member)}
                          />
                          <span className="memberName">{member.value}</span>
                        </label>
                      </CheckBoxListItem>
                    </React.Fragment>
                  );
                })}
            </CheckBoxContainer>
          </ModalBody>
          <ModalWrapper>
            <Button onClick={toggle} secondary>
              Cancel
            </Button>
            <Button onClick={toggle}>Confirm</Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
      <InviteMemberToWorkspaceModal
        isOpen={isInviteMemberModalOpen}
        disabled={sendingEmail}
        email={email}
        handleEmailChange={handleEmailChange}
        toggleModal={toggleInviteMemberModal}
        error={emailError}
        submitFunction={inviteMemberToWorkspace}
      />
    </>
  );
}

export default EditOtherWorkspaceMembers;
