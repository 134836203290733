/* eslint-disable no-useless-escape */
import React from 'react';
import { Form, Formik } from 'formik';
import { CustomButton } from '../../components/CustomButton';
import { InputField } from '../../components/Reusable/NewFormik/Input1';
import logo from '../../assets/images/Platform Suite_Logo_White_LG.png';
import styled from '@emotion/styled';
import { AssigneeWrapper, FieldSet, InputWrapper, LinkWrapper } from '../../components/styles/FormStyles';
import useForceResetPassword from '../../hooks/features/auth/useForceResetPassword';

const AuthWrapper = styled.div`
  display: flex;
`;

const Links = styled.a`
  font-weight: 600;
  color: #4c6d66;
  cursor: pointer;
`;

const LeftLogoContainer = styled.div`
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #26303e;
`;

const RightFormContainer = styled.div`
  width: 50vw;
  height: 100vh;
  overflow: auto;
`;
const LogoImage = styled.img`
  transform: translate(0, -25%);
  width: 27rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const InputContainer = styled.div`
  width: 433px;
`;
const HeaderText = styled.div`
  font-weight: 600;
  font-size: 24px;
`;
const HeaderSubText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #1c1c1c;
`;

export const ForceResetPassword = () => {
  const { loading, validationSchema, forgotPasswordSubmit, initialValues, location, goToHomePage } =
    useForceResetPassword();
    return location.state ? (
    <AuthWrapper>
      <LeftLogoContainer>
        <LogoImage src={logo} alt="logo" />
      </LeftLogoContainer>
      <RightFormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={forgotPasswordSubmit}
          enableReinitialize={true}
          validateOnBlur={true}
        >
          {({ handleChange, errors, touched, isSubmitting, values, handleBlur }) => {
            return (
              <Form id="resetPassword">
                <FormContainer>
                  <FieldSet>
                    <InputContainer>
                      <InputWrapper>
                        <HeaderText>Reset Password</HeaderText>
                      </InputWrapper>
                      <InputWrapper>
                        <HeaderSubText>A password reset code will be sent to your email.</HeaderSubText>
                      </InputWrapper>
                      {/* <InputWrapper>
                        <InputField
                          label="Reset Password Code"
                          placeholder="Enter Code"
                          name="code"
                          id="code"
                          onChange={handleChange}
                          touched={touched.code}
                          errors={errors.code}
                          value={values.code}
                          disabled={isSubmitting || loading}
                        />
                      </InputWrapper> */}
                      <InputWrapper>
                        <InputField
                          label="New Password"
                          type="password"
                          placeholder="Enter New Password"
                          name="password"
                          id="password"
                          onChange={handleChange}
                          touched={touched.password}
                          errors={errors.password}
                          value={values.password}
                          disabled={isSubmitting || loading}
                          onBlur={handleBlur}
                        />
                      </InputWrapper>
                      <InputWrapper>
                        <InputField
                          label="Confirm New Password"
                          type="password"
                          placeholder="Re-enter New Password"
                          name="confirm_password"
                          id="confirm_password"
                          onChange={handleChange}
                          touched={touched.confirm_password}
                          errors={errors.confirm_password}
                          value={values.confirm_password}
                          onBlur={handleBlur}
                          disabled={isSubmitting || loading}
                        />
                      </InputWrapper>
                      <AssigneeWrapper>
                        <CustomButton disabled={loading} type="submit">
                          {loading ? 'Loading' : 'Reset Password'}
                        </CustomButton>
                        <LinkWrapper>
                          <Links onClick={() => goToHomePage()}>Go back to login page</Links>
                        </LinkWrapper>
                      </AssigneeWrapper>
                    </InputContainer>
                  </FieldSet>
                </FormContainer>
              </Form>
            );
          }}
        </Formik>
      </RightFormContainer>
    </AuthWrapper>
  ) : (
    <div>
      <h1>Ristricted Access</h1>
    </div>
  );
};
