import styled from '@emotion/styled';
import { CommentCard } from './ItemRevisionCommentCard';
import { FileCard } from './ItemRevisionFileCard';
import UserAvatar from './UserAvatar';
// import Avatar from "react-avatar"
// import AvatarTags from "../AvatarTags"

type CardProps = {
  name: string;
  subject: String;
  status: String;
  comment: String;
  role: String;
  alignment?: 'left' | 'right';
  fileId?: String;
};
export type AlignmentProps = {
  alignment?: String;
};
const Container = styled.div<AlignmentProps>`
  display: flex;
  margin: 24px;
  justify-content: space-between;
  flex-direction: column;
`;
const CardContainer = styled.div<AlignmentProps>`
  margin-left: ${(props) => (props.alignment === 'right' ? 'auto' : '0px')};
  min-width: 90%;
  max-width: 90%;
  margin-top: 9px;
`;

const ResponseInfo = styled.div<AlignmentProps>`
  min-width: 90%;
  margin-left: ${(props) => (props.alignment === 'right' ? 'auto' : '0px')};
`;

export const ItemRevisionCard = (props: CardProps) => {
  return (
    <Container>
      <ResponseInfo alignment={props.alignment}>
        <UserAvatar name={props.name} role={props.role} alignment={props.alignment} />
        <CardContainer alignment={props.alignment}>
          <FileCard subject={props.subject} status={props.status} fileId={props.fileId} />
          <CommentCard comment={props.comment} />
        </CardContainer>
      </ResponseInfo>
    </Container>
  );
};
