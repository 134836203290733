import * as yup from "yup"

const CreateProjectValidation = yup.object().shape({
  projectCode: yup.string()
    .required("Project Code is Required")
    .min(6, "Project Code should be atleast 6 characters"),
  projectName: yup.string().required("Project Name is Required"),
  client: yup.string().required("Client Name is Required"),
  description: yup.string().required("Description is Required"),
})

export default CreateProjectValidation