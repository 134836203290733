import styled from "@emotion/styled";
import { ReactComponent as PageNotFoundIllustration } from "../../assets/illustration/404.svg";
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 134px);
  padding: 0;
`;

export const PageNotFound = () => {
  return (
    <ImageWrapper>
      <PageNotFoundIllustration />
    </ImageWrapper>
  );
};
