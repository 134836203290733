import { gql } from '@apollo/client';

export const createProjectMutation = gql`
  mutation createProjectMutation(
    $client: String
    $createdAt: String
    $createdBy: String
    $projectName: String
    $description: String
    $recordStatus: Float
    $updateAt: String
    $updatedBy: String
    $projectCode: String
    $organizationId: String
    $isShared: Boolean
  ) @api(name: "project") {
    add_Project(
      syncMode: NODE_COMMITTED
      input: {
        client: $client
        createdAt: $createdAt
        createdBy: $createdBy
        name: $projectName
        projectDescription: $description
        updateAt: $updateAt
        updatedBy: $updatedBy
        recordStatus: $recordStatus
        code: $projectCode
        organizationId: $organizationId
        isShared: $isShared
      }
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const updateProjectMutation = gql`
  mutation updateProjectMutation(
    $id: ID!
    $client: String
    $projectName: String
    $description: String
    $projectCode: String
  ) @api(name: "project") {
    update_Project(
      id: $id
      syncMode: NODE_COMMITTED
      input: { client: $client, projectDescription: $description, name: $projectName, code: $projectCode }
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const UPDATE_PROJECT_SHARED_STATUS = gql`
  mutation updateProjectMutation($id: ID!, $isShared: Boolean) @api(name: "project") {
    update_Project(id: $id, syncMode: NODE_COMMITTED, input: { isShared: $isShared }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const archiveProjectMutation = gql`
  mutation updateProjectMutation($id: ID!, $recordStatus: Float) @api(name: "project") {
    update_Project(id: $id, syncMode: NODE_COMMITTED, input: { recordStatus: $recordStatus }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
