import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../components/CustomButton';
import { Header, NewTitleLeft, PageTitle, TitleLeft } from '../../../components/styles/Header';
import Table from '../../../components/Table';
import { MockProjectNoteColumns, MockProjectNotesData } from '../../../constants/MockProjectNoteData';
import { TitleRight } from '../../Tasks/TaskViews/CreateTask';
import { ReactComponent as SearchIcon } from '../../../assets/icons/IconSearchShat.svg';
import Select from 'react-select';
import { useToggle } from '../../../lib/UseToggle';
import { ProjectNotesListStyles, SearchInput, selectStyles } from './styles';
import { PROJECT_NOTES_SEARCH_OPTIONS as options } from '../../../constants/ProjectNotesDropdown';
import { LIST_PROJECT_NOTES_IN_WORKSPACE_QUERY } from '../../../graphql/operations/Queries/ProjectNotes/ProjectNoteQueries';
import { useNavigate, useParams } from 'react-router';
import Loader from '../../../components/Loader';
import { client } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import { useSelector } from 'react-redux';
import { getFullNameById } from '../../../lib/getFullName';
import { ErrorPage } from '../../Others';
import { ReactComponent as NoNotesIllustration } from '../../../assets/illustration/noNotes.svg';
import AddNewProjectNoteOnlyTitle from '../../../components/Modals/ProjectNotes/AddNewProjectNoteOnlyTitle';

const fetchProjectNotes = async (workspaceId: string, projectId: string, orgId: string) => {
  const data = await client.query({
    query: LIST_PROJECT_NOTES_IN_WORKSPACE_QUERY,
    variables: {
      recordStatus: 0,
      type_typeId: `workspace_${workspaceId}`,
      organizationId_projectId_workspaceId: `${orgId}_${projectId}_${workspaceId}`
    },
    fetchPolicy: 'network-only'
  });
  return data.data?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems;
};

const ProjectNotesList = () => {
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [isOpen, toggleModal] = useToggle();
  const { workspaceId, projectId } = useParams();
  const [allNotes, setAllNotes] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // const [searchFilter, setSearchFilter] = useState('title');
  const [searchText, setSearchText] = useState<string>('');
  const searchFilter = useRef<any>('title');

  const { state } = useContext(AppContext);
  // @ts-ignore
  const orgUsers = useSelector((state) => state.orgMembers.users);
  const navigate = useNavigate();

  useEffect(() => {
    initNoteList();
  }, []);

  const initNoteList = async () => {
    if (!workspaceId || !projectId) return;
    setLoading(true);
    try {
      let result = await fetchProjectNotes(workspaceId, projectId, state.orgId);
      const columnData = result.map((note: any) => {
        return { ...note, author: getFullNameById(note.createdBy, orgUsers, '') };
      });
      setAllNotes(columnData);
      setNotes(columnData);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const filterProjectNotes = (value: string) => {
    let filteredNotes: any = [];
    if (searchFilter.current === 'author')
      filteredNotes = allNotes.filter((note: any) => note.author.toLowerCase().includes(value.toLowerCase()));
    else filteredNotes = allNotes.filter((note: any) => note.title.toLowerCase().includes(value.toLowerCase()));
    setNotes(filteredNotes);
  };

  const onChange = (event: any) => {
    // perform any event related action here
    setSearchText(event.target.value);

    filterProjectNotes(event.target.value);
  };

  const viewProjectNote = (id: string) => {
    navigate(`${id}`);
  };

  const columns = useMemo(() => MockProjectNoteColumns, []);
  const data = useMemo(() => notes, [notes]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <>
      <Header>
        <NewTitleLeft>
          <PageTitle>Project Notebooks</PageTitle>
        </NewTitleLeft>
        <TitleRight>
          <SearchInput>
            <div className={isInputOpen ? 'input-control show' : 'input-control hide'}>
              <SearchIcon className="icon " onClick={() => setIsInputOpen(!isInputOpen)} />
              <input
                className={isInputOpen ? 'input show' : 'input'}
                type="text"
                onChange={onChange}
                value={searchText}
              />
              <Select
                defaultValue={options[0]}
                options={options}
                className="select"
                components={{
                  IndicatorSeparator: () => null
                }}
                styles={selectStyles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  border: 'none',
                  colors: {
                    ...theme.colors,
                    primary25: '#F3F3F3',
                    primary: '#f3f3f3af'
                  }
                })}
                onChange={(e) => {
                  searchFilter.current = e?.value;
                }}
              />
            </div>

            <SearchIcon className={isInputOpen ? 'hide' : 'show'} onClick={() => setIsInputOpen(!isInputOpen)} />
          </SearchInput>

          <Button onClick={() => toggleModal()}>Add New Project Note</Button>
        </TitleRight>
      </Header>
      {notes.length === 0 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            flexDirection: 'column'
          }}
        >
          <NoNotesIllustration />
        </div>
      ) : (
        <ProjectNotesListStyles>
          <Table columns={columns} data={data} key={''} clickHandler={viewProjectNote} />
        </ProjectNotesListStyles>
      )}
      <AddNewProjectNoteOnlyTitle isOpen={isOpen} toggleModal={toggleModal} />
    </>
  );
};

export default ProjectNotesList;
