import { gql } from '@apollo/client';
export const addTaskInTaskNoteMutation = gql`
      mutation addTaskMutation(
      $assignee: String
      $assigneeDetails: Self_Task_assigneeDetails_Input_
      $createdAt: String
      $priority: String
      $projectId: String
      $organizationId_projectId_typeId: String
      $organizationId: String
      $recordStatus: Float
      $status: String
      $title: String
      $noteId: String #NOTEID
      $links: [String]
      $updatedAt: String
      $updatedBy: String
      $type: String  #same as current task
      $typeId: String # same as current task
      $parentAssociateId: String #current TASKID
      $isAssociated: Boolean #True
      ) @api(name: "task") {
            add_Task(
            syncMode: NODE_COMMITTED
            input: {
                  assignee: $assignee
                  assigneeDetails: $assigneeDetails
                  createdAt: $createdAt
                  priority: $priority
                  projectId: $projectId
                  organizationId_projectId_typeId: $organizationId_projectId_typeId
                  organizationId: $organizationId
                  recordStatus: $recordStatus
                  status: $status
                  title: $title
                  noteId: $noteId
                  updatedAt: $updatedAt
                  updatedBy: $updatedBy
                  links: $links
                  type: $type
                  typeId: $typeId
                  isAssociated: $isAssociated
                  parentAssociateId: $parentAssociateId
            }
            ) {
                  result {
                        _id
                  }
            }
      }
`;

export const updateTaskAssociationMutation = gql`
      mutation exampleMutation($isAssociated: Boolean , $updatedAt: String , $updatedBy: String,$id: ID!)
      @api(name: "task") {
            update_Task(
            syncMode: NODE_COMMITTED
            id: $id
            input: {isAssociated: $isAssociated, updatedBy: $updatedBy, updatedAt: $updatedAt}
            )
            
            {result {
                  _id
            }
      }
}
`