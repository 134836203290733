import styled from '@emotion/styled';
import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/editor-icons/NewFileIcon.svg';
import { ReactComponent as DeleteFile } from '../../../../assets/icons/editor-icons/deleteFile.svg';

const FileContainer = styled.div`
  height: 46px;
  background-color: #e9eaec;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 5px;
  color: #000000;
  padding: 5px 15px;
  p {
    font-size: 16px;
    font-weight: 600;
    margin-left: 30px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  .delete-button {
    margin-left: 20px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export default (props: any) => {
  const handleDelete = () => {
    props.deleteNode();
  };

  const goToDoc = () => {
    window.open(props.node.attrs.src, '_blank');
  };

  return (
    <NodeViewWrapper className="file-component" contentEditable={false}>
      <Container contentEditable={false}>
        <FileContainer onClick={goToDoc} contentEditable={false}>
          <DocumentIcon />
          <p contentEditable={false}>{props.node.attrs.platform_suite_file_upload_name}</p>
        </FileContainer>
        <DeleteFile className="delete-button" onClick={handleDelete} />
      </Container>
    </NodeViewWrapper>
  );
};
