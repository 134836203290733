import { Editor } from '@tiptap/react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { client } from '../../../apollo';
import axiosInstance from '../../../axiosInstance';
import { updateNoteDraftStatusMutation } from '../../../graphql/operations/Mutations/MeetingNotes/UpdateNoteDraftStatus';
import { getDate } from '../../../lib/getDate';
import { BlockDataI, MeetingNoteI, SectionI, UserDataI } from '../../../pages/MeetingNotes/types';



const getMeetingNotes = async (noteId: string) => {
  try {
    const data = await axiosInstance.get(`/meetingNotes/${noteId}`);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const useMeetingNotes = (meetingNoteId: string | undefined) => {
  const [currentEditor, setCurrentEditor] = useState<Editor | null>(null);
  const [sections, setSections] = useState<SectionI[]>([]);
  const [meetingNoteData, setMeetingNoteData] = useState<MeetingNoteI>({} as MeetingNoteI);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [didMount, setDidMount] = useState(false);

  // useEffect(() => {
  //   init();
  //   setDidMount(true);
  //   return () => setDidMount(false);
  // }, [meetingNoteId]);

  // if (!didMount) {
  //   return null;
  // }

  // const addSection = () => {
  //   const section = createNewSection(sections.length + 1);
  //   setSections([...sections, section]);
  // };

  // const addBlock = (sectionId: string) => {
  //   const foundSection = sections.find((section) => section.sectionId === sectionId);
  //   const block = createNewBlock(sectionId, sections.length, foundSection!.blockData.length + 1);

  //   setSections((current) =>
  //     current.map((obj) => (obj.sectionId === sectionId ? { ...obj, blockData: [...obj.blockData, block] } : obj))
  //   );
  // };

  const updateLastUpdateDate = (sectionId: string, blockId: string, newDate: string) => {
    setSections((current) =>
      current.map((obj) => {
        if (obj.sectionId === sectionId) {
          obj.blockData.map((block) => (block.blockId === blockId ? { ...block, blockDate: newDate } : block));
        }
        return obj;
      })
    );
  };

  const updateBlockContent = (sectionId: string, blockId: string, blockContent: string) => {
    const section = sections.findIndex((x) => x.sectionId === sectionId);
    const updatedBlock = sections[section].blockData.find((x) => x.blockId === blockId);
    updatedBlock!.blockContent = blockContent;
    setSections((current) =>
      current.map((obj) => {
        if (obj.sectionId === sectionId) {
          obj.blockData.map((block) => (block.blockId === blockId ? updatedBlock : block));
        }
        return obj;
      })
    );
  };

  const updateSectionHeader = (sectionId: string, header: string) => {
    setSections((current) =>
      current.map((obj) => (obj.sectionId === sectionId ? { ...obj, sectionHeader: header } : obj))
    );
  };

  const deleteSection = (sectionId: string) => {
    setSections((current) =>
      current.filter((section) => {
        return section.sectionId !== sectionId;
      })
    );
  };

  const deleteBlock = (sectionId: string, blockId: string) => {
    const section = sections.findIndex((x) => x.sectionId === sectionId);
    const updatedBlock = sections[section].blockData.filter((block) => block.blockId !== blockId);
    setSections((current) =>
      current.map((obj) => {
        if (obj.sectionId === sectionId) {
          obj.blockData.map((block) => (block.blockId === blockId ? updatedBlock : block));
        }
        return obj;
      })
    );
  };

  // const init = async () => {
  //   // if (!meetingNoteId) return;
  //   // const toastId = toast.loading('Loading');
  //   setLoading(true);
  //   try {
  //     if (meetingNoteId !== undefined) {
  //       const data = await getMeetingNotes(meetingNoteId!);
  //       setMeetingNoteData(data.data);

  //       if (data?.data.data.length === 0) {
  //         addSection();
  //       } else setSections(data?.data.data);
  //     } else {
  //       addSection();
  //     }

  //     // toast.dismiss(toastId);
  //   } catch (error: any) {
  //     // toast.dismiss(toastId)
  //     setError(error.message);
  //   }
  //   setLoading(false);
  // };

  const handleSaveDraft = async (noteId: string, metaData?: string, otherFields?: string) => {
    // const toastId = toast.loading('Saving Note..');
    const body: any = {
      organizationId: meetingNoteData.organizationId,
      workspaceId: meetingNoteData.workspaceId,
      projectId: meetingNoteData.projectId,
      title: meetingNoteData.title,
      content: sections,
      metaData: metaData ? metaData : 'meta-data',
      noteId: noteId
    };

    if (otherFields) body['otherFields'] = otherFields;

    try {
      const res = await axiosInstance.post(`/meetingNotes/`, body);
      // toast.success('Notes saved successfully!', { id: toastId });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handlePublishNote = async (selectedMembers: any, selectedPermission: any, selectedAttendees: any) => {
    const usersData: UserDataI[] = [];
    selectedMembers.map((members: any) => {
      if (members.id) {
        usersData.push({ userId: members.id, access: selectedPermission!.value, memberType: 'SHARED' });
      } else {
        usersData.push({ emailId: members.value, access: selectedPermission!.value, memberType: 'SHARED' });
      }
    });
    selectedAttendees.current.map((members: any) => {
      usersData.push({ userId: members.id, access: members.permission.value, memberType: 'ATTENDEE' });
    });
    const toastId = toast.loading('Publishing note ...');
    try {
      const data = await axiosInstance.post(`/meetingNotes/${meetingNoteId}`, {
        organizationId: meetingNoteData.organizationId,
        workspaceId: meetingNoteData.workspaceId,
        projectId: meetingNoteData.projectId,
        usersData: usersData
      });

      //call graphql mutation function
      const query = await client.mutate({
        mutation: updateNoteDraftStatusMutation,
        variables: { isDraft: false, id: meetingNoteData.id }
      });

      toast.success('Note published successfully!', { id: toastId });

      // console.log(data);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', { id: toastId });
    }
  };

  return {
    handleSaveDraft,
    currentEditor,
    setCurrentEditor,
    sections,
    updateBlockContent,
    updateLastUpdateDate,
    updateSectionHeader,
    meetingNoteData,
    loading,
    error,
    handlePublishNote
  };
};

export default useMeetingNotes;
