import { useState, useContext, useEffect } from 'react';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import styled from '@emotion/styled';
import { PageNavStyles } from '../../../Library/FilesView/Files/ViewFile/ViewFile';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useToggle } from '../../../../lib/UseToggle';
import Modal from '../../../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../../components/styles/ModalStyles';
import Button, { CustomButton } from '../../../../components/CustomButton';
import { useFormik } from 'formik';
import InputField from '../../../../components/Reusable/Formik/Input';
import { AppContext } from '../../../../AppContext';
import AddTemplateWorkspace from '../../../../components/Validations/AddTemplateWorkspaceValidation';
import { OptionItem, ToggleSettings } from '../../../../components/OptionsMenu';
import Lottie from 'react-lottie';
// import _ from 'lodash';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../../../axiosInstance';
import { getWorkspaceQuery } from '../../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { createTemplateMutation } from '../../../../graphql/operations/Mutations/Workspaces/TemplateMutations';
import { listRegistersQuery } from '../../../../graphql/operations/Queries/Registers/RegisterQueries';
import toast from 'react-hot-toast';
// import { useNavigate } from 'react-router';

import _ from 'lodash';

import useGetIsSuperAdmin from '../../../../hooks/common/useGetIsSuperAdmin';
import UseGetIsProjectAdmin from '../../../../hooks/common/UseGetIsProjectAdmin';
import { ARCHIVED_WORKSPACE_MUTATION } from '../../../../graphql/operations/Mutations/Workspaces/WorkspaceMutations';
import NavigationLink from '../../../../components/NavigationLink';
// import toast, { Toaster } from "react-hot-toast";
// import { ReactComponent as Ellipsis } from "../../../assets/icons/ellipsis.svg";

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;

export const Text = styled.p`
  font-weight: 600;
`;

export const NumberOfMembers = styled(Text)`
  color: var(--button-background);
  cursor: pointer;
`;

const Modalinput = styled.div`
  margin: 1.5rem;
`;

const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;
const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;

const ViewWorkspace = () => {
  const { projectId, workspaceId } = useParams();
  const [isOpen, toggle] = useToggle(false);
  const { state } = useContext(AppContext);
  const [noOfMembers, setNoOfMembers] = useState();
  const [workspaceUserRole, setWorkspaceUserRole] = useState();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isGlobal, setIsGlobal] = useState(false);
  // let orgRole = state?.roleName;
  let navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);
  const { isSuperAdmin } = useGetIsSuperAdmin();
  const { isProjectAdmin } = UseGetIsProjectAdmin(projectId);
  let orgRole = state?.roleName;

  const archiveWorkspace = async (workspaceId: string) => {
    const toastId = toast.loading('Archiving Workspace..');
    try {
      let response = await archiveWorkspaceMutation({
        variables: {
          id: workspaceId,
          recordStatus: 1.0
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Workspace Archived');
        navigate(`/project/${projectId}/workspaces/archived`);
      }
    } catch (error) {
      console.error('Error in Archival Of Workspace', error);
      toast.dismiss(toastId);
      toast.success('Error in archiving workspace. Please try again');
    }
  };

  const [archiveWorkspaceMutation] = useMutation(ARCHIVED_WORKSPACE_MUTATION);

  const editWorkspace = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/editworkspace`);
  };

  const { data: RegisterData } = useQuery(listRegistersQuery, {
    variables: {
      workspaceId: workspaceId,
      organizationId: state.orgId
    }
  });

  const initialState = {
    templatename: ''
  };

  useEffect(() => {
    axiosInstance
      .get(`/userEntity/?entityType=WORKSPACE&entityId=${workspaceId}`)
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            setNoOfMembers(response.data.data.length);
          }
        }
      })
      .catch((error) => {
        console.error('ERROR', error);
      });
  }, []);

  // useEffect(() => {
  //   if (projectId && workspaceId && state.orgId) {
  //     axiosInstance
  //       .get(`/roles?entityType=WORKSPACE&entityId=${workspaceId}&userId=${state.userId}`)
  //       .then((res) => {
  //         setWorkspaceUserRole(res.data.userRoles);
  //         if (res.data.data.length === 0) {
  //           // setOnlyStaticRoles(true);
  //           // setIsLoading(false);
  //         } else {
  //           // setRoles(res.data.data);
  //           // setIsLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('ERROR', error);
  //         // setIsLoading(false);
  //       });
  //   }
  // }, []);

  const {
    data: workspace
    // error,
    // loading,
    // refetch,
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    }
  });

  const [addTemplate] = useMutation(createTemplateMutation, {
    fetchPolicy: 'no-cache'
  });
  const goToOtherMembersPage = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/edit/othermembers`);
  };

  const openModal = (global: boolean) => {
    if (global) {
      setIsGlobal(true);
      toggle();
    } else {
      toggle();
      setIsGlobal(false);
    }
  };

  const AddTemplate = async (templateData: any, setSubmitting: any, resetForm: any) => {
    const toastId = toast.loading('Creating Template');
    setIsSubmitting(true);
    try {
      let registerIds = RegisterData.list_RegisterItems._RegisterItems.map((register) => {
        return register._id;
      });
      let registerList = RegisterData.list_RegisterItems._RegisterItems.map((register) => {
        return {
          fileFormats: register.fileFormats,
          registerName: register.name,
          registerType: register.registerType
        };
      });
      let object = {
        workspaceId: workspaceId,
        registerIds: registerIds
      };
      let Response = await addTemplate({
        variables: {
          createdAt: new Date().toISOString(),
          createdBy: state?.userId,
          templateName: templateData?.templatename,
          workspaceName: workspace?.get_Workspace?.name,
          organizationId: state?.orgId,
          recordStatus: 0,
          updatedBy: state?.userId,
          updatedAt: new Date().toISOString(),
          type: 'WORKSPACE',
          organizationId_type: `${state.orgId}_WORKSPACE`,
          workspace: {
            workspaceName: workspace?.get_Workspace?.name,
            registers: registerList
          },
          isGlobal: isGlobal,
          otherFields: JSON.stringify(object)
        }
      });
      if (Response) {
        toast.dismiss(toastId);
        toast.success('Template Created');
        setIsSubmitting(false);
        toggle();
      }
    } catch (e) {
      toast.dismiss(toastId);
      toast.error('Oops! something went wrong. Please Try Again');
      console.error(e);
      setIsSubmitting(false);
      toggle();
    }
    toast.dismiss(toastId);
    setSubmitting(false);
    setIsSubmitting(false);
  };
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: AddTemplateWorkspace,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      AddTemplate(values, setSubmitting, resetForm);
    }
  });

  const handlenavigate = () => {
    navigate(`/project/${projectId}/workspaces`);
  };
  return _.isEmpty(workspace) ? (
    <Lottie options={defaultOptions} width={500} height={500} />
  ) : (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handlenavigate} />
          <NewTitleLeft>
            <PageTitle> {workspace?.get_Workspace?.name} </PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <NumberOfMembers onClick={() => goToOtherMembersPage()}>
            {noOfMembers ? (noOfMembers === 1 ? '1 Member in Workspace' : `${noOfMembers} Members in Workspace`) : null}
          </NumberOfMembers>
          {/* {!_.isEmpty(workspaceUserRole) ? ( */}
          {orgRole !== 'MEMBER' && (
            <ToggleSettings>
              <OptionItem onClick={editWorkspace}>Edit Workspace</OptionItem>
              {(isSuperAdmin || isProjectAdmin) && (
                <OptionItem onClick={() => archiveWorkspace(workspaceId)}>Archive Workspace</OptionItem>
              )}
              <OptionItem onClick={() => openModal(false)}>Save as Template</OptionItem>
              <OptionItem onClick={() => openModal(true)}>Save as Global Template</OptionItem>
            </ToggleSettings>
          )}

          {/* ) : null} */}
        </TitleRight>
      </Header>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <form onSubmit={formik.handleSubmit}>
            <ModalTitle>Save Template</ModalTitle>
            <ModalBody>
              <ModalText>Add template name to workspace</ModalText>
              <Modalinput className="input-field">
                <InputField
                  name="templatename"
                  type="text"
                  formik={formik}
                  disabled={isSubmitting}
                  placeHolder="Enter Name"
                />
              </Modalinput>
            </ModalBody>
            <ButtonWrapper>
              <Button onClick={toggle} secondary>
                Cancel
              </Button>
              <Button disabled={isSubmitting} type="submit">
                Add
              </Button>
            </ButtonWrapper>
          </form>
        </ModalContainer>
      </Modal>
      <PageNavStyles>
        <NavigationLink end to={`/project/workspace/${projectId}/${workspaceId}`}>
          About
        </NavigationLink>
        <NavigationLink to="notebooks">Notebooks</NavigationLink>
        <NavigationLink
          // end
          to="registers"
        >
          Registers
        </NavigationLink>
        <NavigationLink to="tasks">Tasks</NavigationLink>
        <NavigationLink to="library">Library</NavigationLink>
        <NavigationLink to="meetingnotes">Meeting Notes</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
};

export default ViewWorkspace;
