import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getRegisterQuery } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import * as yup from 'yup';
import { AddRegisterNoteMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';

import toast from 'react-hot-toast';
import { useState } from 'react';

type Note = {
  description: string;
};
export type IRouterProps = {
  description?: string | null;
};
function useRegisterNote() {
  let { registerId } = useParams();
  const { state: RouterState }: { state: IRouterProps | unknown } = useLocation();
  let navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    data: registerData,
    error: registerError,
    loading: registerLoading
  } = useQuery(getRegisterQuery, {
    variables: {
      id: registerId
    }
  });

  const [addRegisterNote, { error, loading }] = useMutation(AddRegisterNoteMutation);

  const noteValidation = yup.object().shape({
    description: yup.string().required('Please add a note.')
  });

  const initialState: Note = {
    description: RouterState?.description ? RouterState.description : ''
  };

  const handleSubmit = async (values: Note) => {
    let toastId = toast.loading('Submitting data.');
    try {
      setIsSubmitting(true);
      let result = await addRegisterNote({
        variables: {
          id: registerId,
          registerDescription: values.description
        }
      });
      if (result) {
        toast.dismiss(toastId);
        if (RouterState?.description) {
          toast.success('Register note edited.');
        } else {
          toast.success('Register note added.');
        }
        navigate(-1);
      }
    } catch (error) {
      console.error('ERROR IN UPDATING NOTE DATA', error);
      toast.dismiss(toastId);
      toast.error('Oh no something went wrong.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: handleSubmit,
    validationSchema: noteValidation
  });

  return {
    registerData,
    registerError,
    registerLoading,
    formik,
    error,
    loading,
    isSubmitting,
    RouterState
  };
}

export default useRegisterNote;
