import styled from '@emotion/styled';
import Button from '../../../components/CustomButton';
import { Field, FieldArray, FormikProvider } from 'formik';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../components/styles/Header';
import { TaskDetails } from './CreateTask';
import { editTaskOptions, priorityOptions } from '../../../constants/DropDownOptions';
import { statusStyles } from '../../../components/styles/StatusStyles';
import { Small } from '../../../components/styles/SmallText';
import { Label } from '../../../components/styles/Label';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  ChoicesWrapper,
  DateWrapper,
  DropDownWrapper,
  ErrorWrapper,
  FieldSet,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import { customStyles } from './CreateTask';
import InputField from '../../../components/Reusable/Formik/Input';
import { ProjectDropdownStyles } from '../../../components/styles/ProjectDropdownStyles';
import Select from 'react-select';
import { WorkspaceDropdownStyles } from '../../../components/styles/WorkspaceDropdownStyles';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import Checkbox from '../../../components/Checkbox';
import { LinkInputWrapper } from '../../Library/FilesView/Files/CreateNewFile';
import { Input } from '../../../components/styles/Inputs';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import { ErrorText } from '../../../components/Reusable/NewFormik/TextError';
import { ErrorPage } from '../../Others/ErrorPage';
import LinkDisplay from '../../../components/LinkDisplay';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import { TextAreaField } from '../../../components/Reusable/NewFormik/TextArea1';
import useEditTask from '../../../hooks/features/tasks/useEditTask';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';

const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.18rem;
  gap: 19px;
`;

const CheckBoxText = styled.span`
  margin-left: 8px;
`;

const CheckBoxLabel = styled(Label)`
  margin-left: 20px;
`;

const CheckBoxDiv = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;
// const Form = styled.form``;

function EditTask() {
  let selectRef = null;
  let userRef = null;

  const {
    formik,
    WorkspaceData,
    data,
    doNothing,
    error,
    getProjectName,
    getUser,
    getWorkspaceName,
    handleProjectChange,
    handleWorkspaceChange,
    loading,
    mutationError,
    projectData,
    projectsError,
    projectsLoading,
    submitting,
    users,
    workspaceError,
    workspaceLoading,
    handlePersonalTask
  } = useEditTask();
  if (error || mutationError || projectsError || workspaceError) return <ErrorPage />;

  if (
    loading ||
    projectsLoading ||
    workspaceLoading ||
    // isLoading ||
    users.length < 0
  )
    return <Lottie options={defaultOptions} width={400} height={500} />;

  const statusIndex = editTaskOptions?.find((option) => option.value === formik.values.status);
  const priorityIndex = priorityOptions?.find((option) => option.value === formik.values.priority);
  // const assignedToIndex = options?.find(
  //   (option) => option.value === formik.values.assignedTo
  // );
  return (
    <form onSubmit={formik.handleSubmit}>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Edit Task</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button disabled={submitting} type="submit">
            Update Task
          </Button>
        </TitleRight>
      </Header>
      <TaskDetails>
        <FieldSet>
          <div>
            <InputWrapper>
              <Label htmlFor="title">Task Title</Label>
              <InputField name="title" type="text" formik={formik} disabled={submitting} />
            </InputWrapper>
            <ChoicesWrapper>
              <Label htmlFor="projectId">Projects</Label>
              <Select
                name="projectId"
                options={[...projectData?.list_ProjectItems?._ProjectItems, { name: 'Personal Task', _id: 'personal' }]}
                defaultValue={getProjectName(formik.values.projectId)}
                styles={{
                  ...ProjectDropdownStyles
                }}
                isDisabled={formik.values.isPersonal || submitting}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e._id}
                onChange={(selectedOption) => {
                  handleProjectChange(selectedOption, selectRef);
                }}
              />
              <Label htmlFor="workspaceId">Workspace</Label>
              <Select
                name="workspaceId"
                styles={WorkspaceDropdownStyles}
                menuPortalTarget={document.body}
                // placeholder={getWorkspaceName(data?.get_Task?.typeId)}
                defaultValue={getWorkspaceName(formik.values.workspaceId)}
                options={WorkspaceData ? Object.values(WorkspaceData) : []}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e._id}
                isDisabled={formik.values.projectId === 'NOPROJECT' || formik.values.isPersonal || submitting}
                onChange={(selectedOption) => {
                  handleWorkspaceChange(selectedOption, userRef);
                }}
                onBlur={formik.handleBlur}
                ref={(ref) => {
                  selectRef = ref;
                }}
              ></Select>
            </ChoicesWrapper>
            <ErrorWrapper>
              {formik.touched['projectId'] && formik.errors['projectId'] && formik.values.projectId !== 'NOPROJECT' ? (
                <p style={{ color: 'red' }}>{formik.errors['projectId']}</p>
              ) : null}
              {formik.touched['workspaceId'] && formik.errors['workspaceId'] ? (
                <p style={{ color: 'red' }}>{formik.errors['workspaceId']}</p>
              ) : null}
            </ErrorWrapper>
            <CheckBoxDiv>
              <CheckBoxLabel>
                <Checkbox name="isPersonal" checked={formik.values.isPersonal} onChange={handlePersonalTask} />
                <CheckBoxText>This is a personal task</CheckBoxText>
              </CheckBoxLabel>
            </CheckBoxDiv>

            <InputWrapper>
              <TextAreaField
                label="Description"
                type="text"
                name="description"
                id="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                touched={formik.touched.description}
                errors={formik.errors.description}
                disabled={formik.isSubmitting || submitting}
              />
            </InputWrapper>
            <LinkWrapper>
              <Label>Links</Label>
              <FormikProvider value={formik}>
                <FieldArray name="links">
                  {({ remove, push }) => (
                    <>
                      {formik.values.links &&
                        formik.values.links.length > 0 &&
                        formik.values.links.map((link, index) => {
                          return (
                            <>
                              {link.saved ? (
                                <LinkDisplay
                                  link={link.value}
                                  editLink={() => formik.setFieldValue(`links[${index}].saved`, false)}
                                  removeLink={() => remove(index)}
                                />
                              ) : (
                                <Field name={`links.${index}`}>
                                  {({ meta, field }) => {
                                    return (
                                      <>
                                        <LinkInputWrapper>
                                          <Input
                                            className="link-input"
                                            type="text"
                                            {...field}
                                            value={field.value.value}
                                            name={`links.${index}`}
                                            onChange={(e) =>
                                              formik.setFieldValue(`links[${index}].value`, e.target.value)
                                            }
                                            error={meta.touched && meta.error}
                                            disabled={submitting}
                                          />
                                          <Button
                                            disabled={submitting}
                                            onClick={() => {
                                              if (!meta.error?.value) {
                                                formik.setFieldValue(`links[${index}].saved`, true);
                                              }
                                            }}
                                          >
                                            Save
                                          </Button>
                                          <CloseIcon
                                            onClick={() => (!submitting ? remove(index) : doNothing())}
                                            height="25px"
                                            width="25px"
                                          />
                                        </LinkInputWrapper>
                                        {meta.touched && meta.error && (
                                          <ErrorText>{meta.error.value || meta.error.saved} </ErrorText>
                                        )}
                                      </>
                                    );
                                  }}
                                </Field>
                              )}
                            </>
                          );
                        })}
                      <div>
                        <Button disabled={submitting} onClick={() => push({ value: '', saved: false })}>
                          + Add Link
                        </Button>
                      </div>
                    </>
                  )}
                </FieldArray>
              </FormikProvider>
            </LinkWrapper>
          </div>
          <div>
            {/* {(data?.get_Task?.isAcknowledged ) } */}
            <ChoicesWrapper>
              <Label>Status</Label>
              <Select
                name="status"
                value={statusIndex}
                onChange={(status: any) => {
                  if (status) {
                    formik.setFieldValue('status', status.value);
                  } else {
                    formik.setFieldValue('status', '');
                  }
                }}
                error={formik.errors.status}
                touched={formik.touched.status}
                options={editTaskOptions}
                styles={{ ...statusStyles }}
                isDisabled={submitting}
              />
              <Label>Priority</Label>
              <Select
                name="priority"
                options={priorityOptions}
                value={priorityIndex}
                styles={statusStyles}
                onChange={(priority: any) => {
                  if (priority) {
                    formik.setFieldValue('priority', priority.value);
                  } else {
                    formik.setFieldValue('priority', '');
                  }
                }}
                error={formik.errors.priority}
                touched={formik.touched.priority}
                isDisabled={submitting}
              />
            </ChoicesWrapper>
            <DateWrapper>
              <Label>Due Date</Label>
              <DateInputField
                type="date"
                name="dueDate"
                value={formik.values.dueDate}
                touched={formik.touched.dueDate}
                errors={formik.errors.dueDate}
                onChange={formik.handleChange}
                disabled={submitting}
              />
            </DateWrapper>
            <AssigneeWrapper>
              <Label>Reporter</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar name={data?.get_Task?.assigneeDetails?.name} size={21} />
                </AvatarStyles>
                <Small>
                  {getDate(data?.get_Task?.createdAt)} {getTime(data?.get_Task?.createdAt)}
                </Small>
              </AssigneeSeparator>
            </AssigneeWrapper>
            <DropDownWrapper>
              <Label>Assigned To</Label>
              <Select
                name="assignedTo"
                menuPortalTarget={document.body}
                value={getUser(formik.values.assignedTo)}
                isDisabled={formik.values.isPersonal || submitting}
                isSearchable={true}
                isClearable={true}
                options={users}
                onBlur={formik.handleBlur}
                ref={(ref) => {
                  userRef = ref;
                }}
                onChange={(value: any) => {
                  if (value) {
                    formik.setFieldValue('assignedTo', value.id);
                    formik.setFieldValue('assignedToDetails.name', value ? value.value : '');
                    formik.setFieldValue('assignedToDetails.email', value ? value.email : '');
                  } else {
                    formik.setFieldValue('assignedTo', '');
                  }
                }}
                styles={{
                  ...customStyles,
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
              />
            </DropDownWrapper>
            {formik.touched['assignedTo'] && formik.errors['assignedTo'] ? (
              <p style={{ color: '#ff3c3c', marginLeft: '1rem' }}>{formik.errors['assignedTo']}</p>
            ) : null}
          </div>
        </FieldSet>
      </TaskDetails>
    </form>
  );
}

export default EditTask;
