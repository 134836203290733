import React from 'react';
import styled from '@emotion/styled';
// import { ReactComponent as FileIcon } from '../assets/icons/fileIcon.svg'
import { ReactComponent as CloseIcon } from '../assets/icons/BlueCloseIcon.svg';
import { DarkGreenButton, GreyButton } from './styles/ButtonStyles';
const LinkStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e9eaec;
  padding: 12px;
  border-radius: 5px;
  max-width: 100%;
  /* margin-top: 10px; */
  margin-bottom: 10px;

  .link {
    /* padding-bottom: 3px; */
    font-size: 1rem;
    cursor: pointer;
    color: #26303e;
    font-weight: 600;
    max-width: 25rem;
    min-width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px;
  }
  .link-type {
    color: var(--text-light);
    margin: 0px;
    margin-right: 10px;
  }
  svg {
    height: 18px !important;
    cursor: pointer;
  }
`;

const LinkDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5px;
  }
  button {
    padding: 5px;
    line-height: 0px;
    font-weight: 400;
  }
`;

interface LinkDisplayProps {
  link: string;
  removeLink?: (link: string) => void;
  editLink?: () => void;
}

function LinkDisplay({ link, removeLink, editLink }: LinkDisplayProps) {
  return (
    <LinkStyles>
      <p onClick={() => window.open(link, '_blank')} className="link">
        {link}
      </p>
      <LinkDiv>
        <p className="link-type">{link.includes('localhost') ? 'Internal' : 'External'}</p>
        {editLink && <DarkGreenButton onClick={() => editLink()}>Edit</DarkGreenButton>}
        {removeLink && <CloseIcon onClick={() => removeLink(link)} />}
      </LinkDiv>
    </LinkStyles>
  );
}

export default LinkDisplay;
