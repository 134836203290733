import React, { ReactElement, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import closeIcon from '../assets/icons/IconClose.svg';
import backIcon from '../assets/icons/IconBackArrow.svg';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

const Trigger = styled.div``;
const FlyInCard = styled.div<{ openStatus: boolean }>`
  background: #fff;
  position: fixed;
  width: ${(props) => (props.openStatus ? '362px' : '0')};
  right: 0;
  bottom: 0;
  top: 67px;
  border: solid 1px #f1f1f1;
  transition: all 0.5s ease;
  border-width: 0 0 0 3px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 9;
`;
const FlyInHeader = styled.div`
  height: 70px;
  border-bottom: solid 1px #f6f5f5;
  display: flex;
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: no-wrap;
`;

const CloseIcon = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
`;

const FlyInBody = styled.div<{ width: string | undefined; fullWidth?: boolean }>`
  max-height: ${(props) => (props.fullWidth ? 'auto' : 'calc(100% - 70px)')};
  overflow: auto;
  width: ${(props) => (props.width ? props.width : '350px')};
`;

interface Props {
  children: ReactElement;
  trigger: ReactJSXElement;
  title: ReactJSXElement | string | null;
  openStatus: boolean;
  closeFunction: () => void;
  openFunction: () => void;
  goBack?: boolean;
  width?: string;
  fullWidth?:boolean
}
const FlyInContainer = ({
  children,
  trigger,
  title,
  openStatus,
  closeFunction,
  openFunction,
  goBack,
  width,
  fullWidth
}: Props) => {
  const theComponent = useRef<any>();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  const handleClickOutside = (e: any) => {
    if (!theComponent.current.contains(e.target)) {
      closeFunction();
    }
  };
  return (
    <div ref={theComponent}>
      {trigger ? <Trigger onClick={() => openFunction()}>{trigger}</Trigger> : null}
      <FlyInCard openStatus={openStatus}>
        {title && (
          <FlyInHeader>
            <CloseIcon onClick={() => closeFunction()}>
              <img src={goBack ? backIcon : closeIcon} alt="Back Icon" />
            </CloseIcon>
            {title}
          </FlyInHeader>
        )}
        <FlyInBody width={width} fullWidth={fullWidth}>{children}</FlyInBody>
      </FlyInCard>
    </div>
  );
};

export default FlyInContainer;
