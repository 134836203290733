import { Auth, Hub } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import isEmpty from 'lodash/isEmpty';
import axiosInstance from '../../axiosInstance';
import { PinnedProjectsContext } from '../../PinnedProjectContext';
const useSetUser = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { dispatch, state } = useContext(AppContext);
  const { dispatch: setPinnedProjects } = useContext(PinnedProjectsContext);
  // const [error, setError] = useState(false);

  const setUserData = async () => {
    try {
      let { attributes } = await Auth.currentAuthenticatedUser();
      let userData = attributes;
      if (!isEmpty(attributes) && state.userId === '') {
        let cognitoIdToken = await Auth.currentSession();
        const token = cognitoIdToken.getIdToken().getJwtToken();
        axiosInstance.get(`/users/${attributes.sub}`).then((response) => {
          // console.log('ADMIN USER', process.env.REACT_APP_ADMIN_DOMAIN?.split(' '));
          const data = response.data.data[0];
          let emailDomain = data.email.substring(data.email.indexOf('@') + 1); // gets the last part of the email
          userData = {
            ...userData,
            userId: data.id,
            userName: data.username,
            phone: data.phoneNumber,
            countryCode: data.countryCode,
            fullName: data.fullName,
            firstName: data.first_name,
            lastName: data.last_name,
            profilePhoto: data.profilePhoto,
            email: data.email,
            orgId: data.organizationId,
            designation: data.designation,
            allOrganizations: data.allOrganizations,
            authToken: token,
            role: data.roles,
            roleName: data.roles?.ORGANIZATION[0]?.roleName,
            seatStatus: data.seatStatus,
            subscription: data.subscription,
            cookiesEnabled: data?.cookiesEnabled || false,
            isChannelRockUser: process.env.REACT_APP_ADMIN_DOMAIN?.split(' ').includes(emailDomain) ? true : false
          };
          dispatch({
            type: 'SET_USER',
            data: userData
          });
          setUser(attributes);
          setTimeout(() => {
            setLoading(false);
          }, 3000);
          if (response.data.data[0].pinnedProjects && response.data.data[0].pinnedProjects.length > 0) {
            setPinnedProjects({
              type: 'SET_PROJECTS',
              data: response.data.data[0].pinnedProjects
            });
          }
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setUser(null);
      // setError(true);
    }
  };

  useEffect(() => {
    const listener = async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          await setUserData();
          break;
        case 'signOut':
          setUser(null);
          setLoading(false);
          break;
      }
    };

    Hub.listen('auth', listener);
    setUserData();
    return () => Hub.remove('auth', listener);
  }, []);

  return {
    user,
    loading,
    // error,
    state
  };
};

export default useSetUser;
