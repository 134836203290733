import styled from "@emotion/styled";
import React from "react";
import Avatar from "react-avatar";
import CloseIcon from "../assets/SvgComponents/CloseIcon";
import { ItemStyles } from "./styles/ListItemStyles";

export const NameTag = styled.div`
  display: flex;
  align-items: center;
  padding-left: 50px;
  .name {
    margin-left: 16px;
  }
`;
// const EmailTag = styled.div`
//   padding-right: 50px;
// `;
const IconWrapper = styled.div`
  padding-right: 30px;
`;

function MemberItem({ name, removeUser }) {
  return (
    <ItemStyles>
      <NameTag>
        <Avatar round={true} size="21" name={name.value} textSizeRatio={2} />
        <p className="name">{name.value}</p>
      </NameTag>
      {/* <EmailTag>
        <p className="name">{name.email}</p>
      </EmailTag> */}
      <IconWrapper>
        {removeUser &&
          <CloseIcon
            onClick={() => removeUser(name)}
            width="1.9rem"
            height="1.9rem"
            fill="#4C6D66"
          />}
      </IconWrapper>
    </ItemStyles>
  );
}

export default MemberItem;
