import { gql } from '@apollo/client';

export const createWorkspaceMutation = gql`
  mutation createWorkspaceMutation(
    $createdAt: String
    $createdBy: String
    $name: String
    $projectId: String
    $workspaceDescription: String
    $registers: [String]
  ) @api(name: "workspace") {
    add_Workspace(
      syncMode: NODE_COMMITTED
      input: {
        createdAt: $createdAt
        createdBy: $createdBy
        name: $name
        projectId: $projectId
        registers: $registers
        workspaceDescription: $workspaceDescription
      }
    ) {
      result {
        _id
      }
    }
  }
`;

// const createWorkspaceMutation = gql`
//   mutation createWorkspaceMutation(
//     $createdAt: String
//     $createdBy: String
//     $name: String
//     $projectId: String
//     $registers: [String]
//   ) {
//     addWorkspace_async(
//       input: {
//         createdAt: $createdAt
//         createdBy: $createdBy
//         name: $name
//         projectId: $projectId
//         registers: $registers
//       }
//     ) {
//       error
//       result {
//         _id
//       }
//     }
//   }
// `;

export const updateWorkspaceMutation = gql`
  mutation updateWorkspaceMutation($id: ID!, $name: String, $registers: [String]) @api(name: "workspace") {
    update_Workspace(id: $id, syncMode: NODE_COMMITTED, input: { name: $name, registers: $registers }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const updateWorkspaceMutationName = gql`
  mutation updateWorkspaceMutation($id: ID!, $name: String) @api(name: "workspace") {
    update_Workspace(id: $id, syncMode: NODE_COMMITTED, input: { name: $name }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const ARCHIVED_WORKSPACE_MUTATION = gql`
  mutation updateWorkspaceMutation($id: ID!, $recordStatus: Float) @api(name: "workspace") {
    update_Workspace(id: $id, syncMode: NODE_COMMITTED, input: { recordStatus: $recordStatus }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
