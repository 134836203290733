import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { PageNavStyles } from '../../components/styles/PageNavStyles';

const WelcomePage = () => {
  return (
    <div>
      <PageNavStyles>
        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} end to="/">
          Dashboard
        </NavLink>
        <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="/notebook">
          Notebook
        </NavLink>
      </PageNavStyles>
      <Outlet />
    </div>
  );
};

export default WelcomePage;
