import axiosInstance from '../axiosInstance';

export const getOrganizationUsers = async (organizationId: string, userId: string) => {
  return axiosInstance.get(`/userEntity/?entityId=${organizationId}&entityType=ORGANIZATION`).then((response) => {
    if (response.data.data) {
      if (response.data.data.length > 0) {
        let fullDetails = response.data.data.map((user: any) => {
          return {
            id: user.userId,
            email: user.email,
            value: user.fullName,
            label: user.userId === userId ? `Myself (${user.fullName})` : user.fullName
          };
        });
        return fullDetails;
      }
    }
  });
};
