import styled from '@emotion/styled';
import { useSidebar } from '../../lib/SidebarState';

const TabStyles = styled.div`
  border: 1px solid var(--table-border);
  border-top: none;
  border-bottom: none;
  border-left: none;
`;
const Nav = styled.div`
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  /* width: 350px; */
  width: 100%;
`;

const ListItem = styled.div`
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 100%;
  border-bottom: ${(props) => (props.isActive ? '5px solid var(--text-secondary)' : '')};
  .active {
    border-bottom: 5px solid var(--text-secondary);
  }

  /* :nth-child(1) {
    padding: ${(props) => (props.isOpen ? '1.7rem' : '')};
  }*/
  :nth-of-type(2) {
    /* padding-bottom: ${(props) => (props.isOpen ? '0.7rem' : '')}; */
    white-space: nowrap;
  }
`;

function SideBarTabs({ activeTab, setActiveTab, isAssociatedTasks = false }) {
  const { sidebarOpen } = useSidebar();
  const handleTabChange = (tab) => {
    if (tab === 'tab1') {
      setActiveTab('tab1');
    } else if (tab === 'tab2') {
      setActiveTab('tab2');
    }
  };
  return (
    <Nav>
      <ListItem isOpen={sidebarOpen} isActive={activeTab === 'tab1'} onClick={() => handleTabChange('tab1')}>
        {isAssociatedTasks ? 'Associated Tasks' : 'Tasks'}
      </ListItem>
      <ListItem isOpen={sidebarOpen} isActive={activeTab === 'tab2'} onClick={() => handleTabChange('tab2')}>
        Discussion
      </ListItem>
    </Nav>
  );
}

export default SideBarTabs;
