import { gql } from '@apollo/client';

export const GET_REGISTER_QUERY = gql`
  query getRegister($id: ID!) @api(name: "register") {
    get_Register(id: $id) {
      _id
      name
      projectId
      workspaceId
      registerType
      createdAt
      responderRole
      initiatorRole
      initiatorRoleId
      responderRoleId
      initiatorStatusOptions {
        status
        statusType
        description
      }
      responderStatusOptions {
        status
        statusType
        description
      }
    }
  }
`;

export const getRegisterQuery = gql`
  query getRegisterQuery($id: ID!) @api(name: "register") {
    get_Register(id: $id) {
      _id
      name
      projectId
      registerType
      createdAt
      responderRole
      initiatorRole
      initiatorRoleId
      responderRoleId
      registerDescription
    }
  }
`;

// export const listRegistersQuery = gql`
//   query listRegistersQuery($workspaceId: String) {
//     listRegisters(filter: { workspaceId: { eq: $workspaceId } }) {
//       Registers {
//         _id
//         name
//         registerType
//         fileFormats
//         initiatorRole
//         responderRole
//         registerType

//       }
//     }
//   }
// `;

export const listRegistersQuery = gql`
  query listRegistersQuery($workspaceId: String, $organizationId:String) @api(name: "register") {
    list_RegisterItems(filter: { workspaceId: { eq: $workspaceId },organizationId: { eq: $organizationId } }) {
      _RegisterItems {
        fileFormats
        initiatorRole
        responderRole
        workspaceId
        _id
        projectId
        registerType
        name
        initiatorStatusOptions {
          status
          statusType
        }
        responderStatusOptions {
          status
          statusType
        }
        roles
        registerDescription
        otherFields
      }
    }
  }
`;

export const listRegistersQuery_Project = gql`
  query listRegistersQuery($projectId: String) @api(name: "register") {
    list_RegisterItems(filter: { projectId: { eq: $projectId } }) {
      _RegisterItems {
        _id
        name
        registerType
        fileFormats
        initiatorRole
        responderRole
        registerType
      }
    }
  }
`;

export const listOtherRegistersQuery = gql`
  query listRegistersQuery($workspaceId: String, $projectId: String) @api(name: "register") {
    list_RegisterItems(filter: { workspaceId: { eq: $workspaceId }, projectId: { eq: $projectId } }) {
      _RegisterItems {
        _id
        name
        registerType
        fileFormats
        initiatorRole
        responderRole
        registerType
        workspaceId
        projectId
      }
    }
  }
`;

// export const listOtherRegistersQuery = gql`
//   query listRegistersQuery($workspaceId: String, $projectId: String) {
//     listRegisters(
//       filter: {
//         workspaceId: { eq: $workspaceId }
//         projectId: { eq: $projectId }
//       }
//     ) {
//       Registers {
//         _id
//         name
//         registerType
//         fileFormats
//         initiatorRole
//         responderRole
//         registerType
//         workspaceId
//         projectId
//       }
//     }
//   }
// `;

export const LIST_REGISTER_FILES_QUERY = gql`
  query MyQuery($type_typeId: String) @api(name: "register") {
    list_FileItems(filter: { type_typeId: { eq: $type_typeId } }) {
      _FileItems {
        createdAt
        createdByName
        createdBy
        fields
        fileDescription
        fileId
        fileType
        fileName
        _id
        links
        name
        projectId
        type
        typeId
        updatedAt
        updatedBy
        status
      }
    }
  }
`;

export const GET_SINGLE_FILE_REGISTER_QUERY = gql`
  query GET_FILE($id: ID!) @api(name: "register") {
    get_File(id: $id) {
      fileType
      fileDescription
      fileId
      createdByName
      links
      name
      projectId
      createdBy
      createdAt
      fields
      status
      # documentStatus,
      # projectName,
      client
      initiatorRole
      responderRole
      initiatorId
      responderId
      status
      initiator
      responder
      fileName
      responses {
        action
        comment
        registerId
        respondedAt
        respondedBy
        responseFileId
        responseFileName
        subject
      }
    }
  }
`;
