import React from 'react';
import styled from '@emotion/styled';
import { TextArea } from '../../../components/styles/Inputs';

interface TextareaFieldProps {
  type?: string;
  name?: string;
  formik?: any;
  disabled?: boolean;
  placeHolder?: string;
  value?: string;
  defaultValue?: string;
  id?: string;
}

const Container = styled.span`
  .input {
    width: 100%;
    &:focus {
      width: 100%;
      border-color: #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
      outline: none;
    }
  }
  .input.error {
    border-color: #ff0000;
    width: 100%;
  }
  .input-feedback {
    margin-top: 7px;
    color: #ff0000;
  }
`;

const TextAreaField: React.FC<TextareaFieldProps> = (props) => {
  const { type, name, formik, disabled, placeHolder, value, defaultValue, id } = props;
  return (
    // <div className="inputFormikField inputtenant">
    <Container>
      <>
        <TextArea
          {...formik.getFieldProps(name)}
          className={formik.errors[name] && formik.touched[name] ? 'input error' : 'input'}
          type={type}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeHolder}
          id={id}
        />
        {formik.touched[name] && formik.errors[name] ? (
          <p className="input-feedback" style={{ color: '#ff3c3c' }}>
            {formik.errors[name]}
          </p>
        ) : null}
      </>
    </Container>
    // </div>
  );
};

export default TextAreaField;
