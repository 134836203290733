import styled from '@emotion/styled';
import Button from '../../components/CustomButton';
import Loader from '../../components/Loader';
import CheckoutForm from '../../components/Payment/CheckoutForm';
import ConfirmPaymentModal from '../../components/Payment/ConfirmPaymentModal';
import SubscriptionNotActive from '../../components/Payment/SubscriptionNotActive';
import DisplayProrateAmount from '../../components/Payment/DisplayProrateAmount';
import UpdateCurrentPlanSeats from '../../components/Payment/UpdateCurrentPlanSeats';
import LatestInvoice from '../../components/Payment/LatestInvoice';
import DisplayCurrentPlan from '../../components/Payment/DisplayCurrentPlan';
import SeatSummary from './SeatSummary';
import SavedPaymentMethod from '../../components/Payment/SavedPaymentMethod';
import DisplayBillingInformation from '../../components/Payment/DisplayBillingInformation';
import useGetMonthlySubscription from '../../hooks/features/payments/useGetMonthlySubscription';
import CancelSubscriptionModal from '../../components/Payment/CancelSubscriptionModal';
import ConfirmedCancellationModal from '../../components/Payment/ConfirmedCancellationModal';
import ReactivateSubscriptionModal from './ReactivateSubscriptionModal';
import { TotalSeatsDiv } from '../../components/Organisation/Placeholders/OrganizationDetails';
import { StyledTag } from '../../components/FileTag';
import { RightBox } from './UpdatePlan';
import { ErrorPage } from '../Others';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../index';
import { SeatWrapper } from '../../components/Payment/SelectSeats';

export type PageMode = 'default' | 'update';

export const PageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MonthlySeatsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  span {
    /* width: 50%; */
    /* color: #1c1c1c; */
    font-size: 16px;
    &:first-of-type {
      font-weight: 600;
    }
    &:nth-of-type(2) {
      font-weight: 400;
    }
  }
`;

export const MonthlySeatsSummary = styled(TotalSeatsDiv)`
  width: 90%;
  margin-left: 35px;
  border-radius: 5px;
  padding: 1rem;
  padding-left: 32px;
  padding-bottom: 16px;
`;
export const InvoiceBoxDiv = styled(SeatWrapper)`
  width: 90%;
  margin-top: 10px;
  padding-top: 24px;
  display: flex;
`;

export const InvoiceDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InvoiceStatus = styled(StyledTag)``;
export const InvoiceHistoryDiv = styled.div`
  margin-top: 20px;
`;
export const InvoiceList = styled.div`
  margin-top: 20px;
  label {
    display: block;
    margin-bottom: 8px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-top: 24px;
  button {
    width: 40%;
    &:nth-of-type(2) {
      margin-left: 24px;
    }
  }
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  /* padding: 0rem rem; */
  form {
    padding-top: 0px;
  }
`;

export const DueDateDiv = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    font-weight: 600 !important;
  }
`;

export const AccountName = styled.span`
  max-width: 107px;
`;

export type ILatestInvoice = {
  status: string;
  invoicePdf: string;
  amountDue: number;
  accountName: string;
  created: number;
};

function MonthlySubscriptionPage() {
  const {
    pageMode,
    loading,
    addMonthlySubscription,
    calculatingProrate,
    cardLoading,
    changePaymentDetails,
    clientSecret,
    decreaseSeatQuantity,
    error,
    increaseSeatQuantity,
    invoiceLoading,
    isConfirmPaymentModalOpen,
    isMonthlySubscriptionActive,
    latestCard,
    latestInvoice,
    monthlyQuantity,
    options,
    prorateAmount,
    reset,
    setPageMode,
    showPaymentConfirmationModal,
    state,
    takenMonthlyUsers,
    toggleConfirmPaymentModal,
    upcomingDueDate,
    originalMonthlyQuantity,
    updateSubscription,
    updatingSubscription,
    isCancelSubscriptionModalOpen,
    cancelSubscription,
    toggleCancelSubscriptionModal,
    cancellingSubscription,
    goToOrganization,
    isConfirmedCancellationModalOpen,
    toggleConfirmedCancellationModal,
    cancelAtPeriodEnd,
    isReactivateSubscriptionModalOpen,
    toggleReactivateSubscriptionModal,
    reactivateSubscription,
    reactivatingSubscription
  } = useGetMonthlySubscription();

  if (loading || cardLoading || invoiceLoading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorPage />;
  }
  return (
    <>
      {!isMonthlySubscriptionActive && <SubscriptionNotActive plan="month" clickHandler={addMonthlySubscription} />}
      {isMonthlySubscriptionActive && (
        <PageWrapper>
          <div style={{ width: '45%' }}>
            {pageMode === 'update' && (
              <>
                <UpdateCurrentPlanSeats
                  currentQuantity={monthlyQuantity}
                  decreaseSeatQuantity={decreaseSeatQuantity}
                  increaseSeatQuantity={increaseSeatQuantity}
                  pageMode={pageMode}
                  plan="month"
                />
                <DisplayProrateAmount
                  calculatingProrate={calculatingProrate}
                  currentQuantity={monthlyQuantity}
                  originalQuantity={originalMonthlyQuantity}
                  prorateAmount={prorateAmount}
                />
              </>
            )}
            {pageMode === 'default' && (
              <>
                <DisplayCurrentPlan plan="month" pageMode={pageMode} originalQuantity={originalMonthlyQuantity} />
                <SeatSummary
                  originalQuantity={originalMonthlyQuantity}
                  takenSeats={takenMonthlyUsers.length}
                  plan="month"
                />
                <LatestInvoice latestInvoice={latestInvoice} plan="month" upcomingDueDate={upcomingDueDate} />
              </>
            )}
          </div>
          {!clientSecret && (
            <RightBox>
              <>
                <SavedPaymentMethod latestCard={latestCard} changePaymentDetails={changePaymentDetails} />
                <DisplayBillingInformation email={state.email} />
                <ButtonGroup>
                  {pageMode === 'default' && (
                    <>
                      {cancelAtPeriodEnd ? (
                        <Button onClick={toggleReactivateSubscriptionModal}>Reactivate Plan</Button>
                      ) : (
                        <>
                          <Button onClick={() => setPageMode('update')}>Update Plan</Button>
                          <Button onClick={() => toggleCancelSubscriptionModal()} secondary>
                            Cancel Subscription
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {pageMode === 'update' && (
                    <>
                      <Button onClick={() => showPaymentConfirmationModal()}>Update Plan</Button>
                      <Button onClick={() => reset()} secondary>
                        Go Back
                      </Button>
                    </>
                  )}
                </ButtonGroup>
              </>
            </RightBox>
          )}
          {clientSecret && (
            <FormDiv>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </FormDiv>
          )}
        </PageWrapper>
      )}
      <ConfirmPaymentModal
        isOpen={isConfirmPaymentModalOpen}
        onClose={toggleConfirmPaymentModal}
        planType={'monthly'}
        totalQuantity={monthlyQuantity}
        dueDate={upcomingDueDate}
        originalQuantity={originalMonthlyQuantity}
        prorateAmount={prorateAmount}
        latestCard={latestCard}
        cardBrand={latestCard?.card?.brand}
        confirmPayment={updateSubscription}
        loading={updatingSubscription}
      />
      <CancelSubscriptionModal
        isOpen={isCancelSubscriptionModalOpen}
        onClose={toggleCancelSubscriptionModal}
        planType="monthly"
        originalQuantity={originalMonthlyQuantity}
        dueDate={upcomingDueDate}
        cancelSubscription={cancelSubscription}
        latestCard={latestCard}
        cardBrand={latestCard?.card?.brand}
        loading={cancellingSubscription}
      />
      <ConfirmedCancellationModal
        isOpen={isConfirmedCancellationModalOpen}
        onClose={toggleConfirmedCancellationModal}
        planType="monthly"
        dueDate={upcomingDueDate}
        goToOrganization={goToOrganization}
      />
      <ReactivateSubscriptionModal
        isOpen={isReactivateSubscriptionModalOpen}
        onClose={toggleReactivateSubscriptionModal}
        planType="monthly"
        dueDate={upcomingDueDate}
        originalQuantity={originalMonthlyQuantity}
        reactivateSubscription={reactivateSubscription}
        reactivatingSubscription={reactivatingSubscription}
      />
    </>
  );
}

export default MonthlySubscriptionPage;
