import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import axiosInstance from '../../axiosInstance';

export const useGetUserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { dispatch } = useContext(AppContext);

  const getUserData = async () => {
    let result;
    try {
      setLoading(true);
      let { attributes } = await Auth.currentAuthenticatedUser();
      let cognitoIdToken = await Auth.currentSession();
      let token = cognitoIdToken.getIdToken().getJwtToken();
      result = axiosInstance.get(`/users/${attributes.sub}`).then((response) => {
        const data = response.data.data[0];
        let emailDomain = data.email.substring(data.email.indexOf('@') + 1); // gets the last part of the email
        let userData = {
          ...attributes,
          userId: data.id,
          userName: data.username,
          phone: data.phoneNumber,
          countryCode: data.countryCode,
          fullName: data.fullName,
          firstName: data.first_name,
          lastName: data.last_name,
          profilePhoto: data.profilePhoto,
          email: data.email,
          orgId: data.organizationId,
          designation: data.designation,
          allOrganizations: data.allOrganizations,
          authToken: token,
          role: data.roles,
          roleName: data.roles?.ORGANIZATION[0]?.roleName,
          seatStatus: data.seatStatus,
          subscription: data.subscription,
          cookiesEnabled: data?.cookiesEnabled || false,
          isChannelRockUser: process.env.REACT_APP_ADMIN_DOMAIN?.split(' ').includes(emailDomain) ? true : false
        };
        dispatch({
          type: 'SET_USER',
          data: userData
        });
        return userData;
      });
    } catch {
      console.error('ERROR IN FETCHING');
      setError(true);
      return error;
    } finally {
      setLoading(false);
    }
    return result;
  };

  return {
    getUserData,
    loading,
    error
  };
};
