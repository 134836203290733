import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { LeftLogoContainer } from '../customAuth/customConfirmSignUp';
import logo from '../../assets/images/Platform Suite_Logo_White_LG.png';
import { PageTitle } from '../../components/styles/Header';
import Button from '../../components/CustomButton';
import Modal from '../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../components/styles/ModalStyles';
import { useToggle } from '../../lib/UseToggle';
import { useLocation, useNavigate } from 'react-router';
import jwt from 'jsonwebtoken';
import toast from 'react-hot-toast';
import axios from 'axios';
import ChannelRockFooter from '../../components/ChannelRockFooter';
// import crtlogo from "../../assets/images/Channel Rock_Primary Logo_1_SM.png"

const InviteContainer = styled.div`
  display: flex;
`;
const LogoImage = styled.img`
  transform: translate(0, -25%);
  width: 27rem;
`;

const RightFormContainer = styled.div`
  height: 100vh;
  width: 50vw;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
`;
const InformationContainer = styled.div`
  margin-top: 20%;
  /* border: 1px solid red; */
  /* height: 100%; */
  /* width: 100%; */
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionItems = styled.div`
  /* margin-top: 55px; */
  /* border: 1px solid red; */
`;

const MainContent = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  padding-left: 21%;
  padding-right: 20%;
`;
const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  display: block;
  display: flex;
`;

const HighlightedText = styled.span`
  text-decoration: underline;
  color: #00b292;
  cursor: pointer;
`;

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const OrgInvite = () => {
  const navigate = useNavigate();
  const [isOpen, toggle] = useToggle(false);
  const [inviteDeclined, setInviteDeclined] = useState(false);
  const location = useLocation();
  const querry = new URLSearchParams(location.search);
  const token = querry.get('token');
  const [decoded, setDecoded] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [requestForInvite, setRequestForInvite] = useState(false);
  let host = window.location.hostname;

  const openDeclineModal = () => {
    toggle();
  };
  const handleModalClose = () => {
    toggle();
  };

  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 > Date.now()) {
        let decodedVar = jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY);
        setDecoded(decodedVar);
      } else {
        //navigate to invitation expiry
        let decodedVar = jwt.verify(token, process.env.REACT_APP_PRIVATE_KEY, { ignoreExpiration: true });
        setDecoded(decodedVar);
        setIsExpired(true);
      }
    }
  }, [token]);

  const acceptInvitation = () => {
    if (decoded.existingUser) {
      // window.location.href = `/?token=${token}`
      toast(`Please Login in to join your ${decoded?.entityType}`);
      navigate(`/?token=${token}`);
    } else {
      window.location.href = `/signup/?token=${token}`;
    }
  };

  const declineInvitation = async () => {
    let toastId = toast.loading('Declining request');
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/rejectInvite`, {
        email: decoded.email,
        date: decoded.date
      })
      .then((response) => {
        if (response.data) {
          toast.dismiss(toastId);
          toast.success('Invitation Declined');
          setInviteDeclined(true);
        }
        //set invitation declined
      })
      .catch((error) => {
        console.error('ERROR OF DECLINE INVITATION', error);
        toast.dismiss(toastId);
        toast.error('Oh no something went wrong');
      })
      .finally(() => {
        toast.dismiss(toastId);
        toggle();
      });
  };

  const requestToReinvite = async () => {
    let toastId = toast.loading('Sending Request');
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/resendInvite`, {
        email: decoded.email,
        entityId: decoded.entityId,
        host: window.location.origin
      })
      .then((response) => {
        if (response.data) {
          toast.dismiss(toastId);
          toast.success('Request sent for a new invitation');
          setRequestForInvite(true);
        }
      })
      .catch((error) => {
        console.error('ERROR OF RESEND INVITATION', error);
        toast.dismiss(toastId);
        toast.error('Oh no something went wrong');
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };

  return (
    <InviteContainer>
      <LeftLogoContainer style={{ height: '100vh' }}>
        <LogoImage src={logo} alt="Platform Suite Logo" />
      </LeftLogoContainer>
      <RightFormContainer>
        <InformationContainer>
          {requestForInvite ? (
            <>
              <TitleDiv>
                <PageTitle>Request for new invitation has been sent.</PageTitle>
              </TitleDiv>
              <MainContent>
                <Paragraph>Hello,</Paragraph>
                <Paragraph>
                  Your request for a new invitaion to join {`${decoded?.entityName}`} has been sent.
                </Paragraph>
                <Paragraph>Please wait for you new invitation.</Paragraph>
              </MainContent>
            </>
          ) : isExpired ? (
            <>
              <TitleDiv>
                <PageTitle>Your invitation has been expired!</PageTitle>
              </TitleDiv>
              <MainContent>
                <Paragraph>Hello,</Paragraph>
                <Paragraph>
                  Your invitation to join {`${decoded?.entityName}`} on CR Platform Suite has been expired.
                </Paragraph>
                <Paragraph>Please request for a new invitation to join.</Paragraph>
                <Button onClick={requestToReinvite}>Request new invitation</Button>
              </MainContent>
            </>
          ) : inviteDeclined ? (
            <TitleDiv>
              <PageTitle>You have declined your invitation to join {`${decoded?.entityName}`}</PageTitle>
            </TitleDiv>
          ) : (
            <>
              <TitleDiv>
                <PageTitle>You have been invited to {`${decoded?.entityName}`}</PageTitle>
              </TitleDiv>
              <MainContent>
                <Paragraph>Welcome !</Paragraph>
                <Paragraph>
                  You have been invited by {`${decoded?.inviterName}`} to join {`${decoded?.entityName}`} on CR Platform
                  Suite.
                </Paragraph>
                <Paragraph style={{ marginBottom: '50px' }}>Join now to avoid invitation cancelation.</Paragraph>
                <Button onClick={acceptInvitation}>Accept Invitation</Button>
                <Paragraph style={{ justifyContent: 'center' }}>
                  To decline the invitation, <HighlightedText onClick={openDeclineModal}>Click here</HighlightedText>
                </Paragraph>
              </MainContent>
            </>
          )}
          <ChannelRockFooter />
        </InformationContainer>
      </RightFormContainer>
      <Modal open={isOpen} onClose={handleModalClose}>
        <ModalContainer width="490px">
          <ModalTitle>Decline Invitation</ModalTitle>
          <ModalBody>
            <p>
              Do you want to decline the invitation to join the {`${decoded?.entityType}`} {`${decoded?.entityName}`}?
              This action cannot be undone.
            </p>
          </ModalBody>
          <ModalWrapper>
            <Button onClick={toggle}>Cancel</Button>
            <Button onClick={declineInvitation} secondary>
              Decline Invitation
            </Button>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
    </InviteContainer>
  );
};
