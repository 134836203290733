import useDebounce from '../../common/useDebounce';
import useGetTakenSeats from '../organization/useGetTakenSeats';
import useGetLatestCard from './useGetLatestCard';
import useGetLatestInvoice from './useGetLatestInvoice';
import axiosInstance from '../../../axiosInstance';
import toast from 'react-hot-toast';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import { GET_ORGANIZATION } from '../../../graphql/operations/Queries/Organization/OrganizationQueries';
import { useToggle } from '../../../lib/UseToggle';
import { PageMode } from '../../../pages/Payments/MonthlySubscriptionPage';
import { sentryError } from '../../../lib/SentryError';

function useGetMonthlySubscription() {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [pageMode, setPageMode] = useState<PageMode>('default');
  const [monthlyQuantity, setMonthlyQuantity] = useState<number>(0);
  const [originalMonthlyQuantity, setOriginalMonthyQuantity] = useState<number>(0);
  const [upcomingDueDate, setUpComingDueDate] = useState('');
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [subscriptionId, setSubscriptionId] = useState<string>();
  const [isMonthlySubscriptionActive, setIsMonthlyubscriptionActive] = useState(false);
  const [isConfirmPaymentModalOpen, toggleConfirmPaymentModal] = useToggle(false);
  const [prorateAmount, setProrateAmount] = useState(0);
  const [calculatingProrate, setCalculatingProrate] = useState(false);
  const [updatingSubscription, setUpdatingSubscription] = useState(false);
  const debouncedQuantity = useDebounce(monthlyQuantity, 300);
  const [isCancelSubscriptionModalOpen, toggleCancelSubscriptionModal] = useToggle(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [isConfirmedCancellationModalOpen, toggleConfirmedCancellationModal] = useToggle(false);
  const [isReactivateSubscriptionModalOpen, toggleReactivateSubscriptionModal] = useToggle(false);
  const [reactivatingSubscription, setReactivatingSubscription] = useState(false);
  const { latestCard, cardLoading } = useGetLatestCard();
  const { latestInvoice, invoiceLoading } = useGetLatestInvoice(subscriptionId as string);
  const { takenMonthlyUsers } = useGetTakenSeats();

  const { loading, error } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: state.orgId
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      let subscriptionData = data?.get_Organization?.monthlySubscription;
      if (subscriptionData !== null && subscriptionData !== 'null') {
        let monthlySeats = JSON.parse(subscriptionData);
        console.log('Monthly Seats', monthlySeats);
        if (monthlySeats.state) {
          //the api will have a field called state if payment has failed. So if this field exists provide no access to the subscription data.
          setIsMonthlyubscriptionActive(false);
          return;
        }
        setMonthlyQuantity(monthlySeats.numberOfSeats);
        setOriginalMonthyQuantity(monthlySeats.numberOfSeats);
        setSubscriptionId(monthlySeats.subscriptionId);
        setUpComingDueDate(monthlySeats.endDate);
        setCancelAtPeriodEnd(monthlySeats.cancelAtPeriodEnd);
        setIsMonthlyubscriptionActive(true);
      } else if (subscriptionData === null || subscriptionData === 'null') {
        setIsMonthlyubscriptionActive(false);
      }
    }
  });

  const preViewProration = async () => {
    setCalculatingProrate(true);
    await axiosInstance
      .get(
        `/payment/preview-proration?organizationId=${state.orgId}&price=${process.env.REACT_APP_MONTHLY}&quantity=${monthlyQuantity}`
      )
      .then((res) => {
        console.log('Proration response', res);
        let amount = res?.data?.invoice?.total / 100;
        let prorateamount = amount - 17 * monthlyQuantity;
        if (prorateamount >= 0) {
          setProrateAmount(prorateamount.toFixed(2));
        } else {
          setProrateAmount(0);
        }
        setCalculatingProrate(false);
      })
      .catch((error) => {
        console.error('Proration error', error);
        setCalculatingProrate(false);
      });
  };

  useEffect(() => {
    if (debouncedQuantity) {
      preViewProration();
    }
  }, [debouncedQuantity]);

  const increaseSeatQuantity = async () => {
    setMonthlyQuantity((monthlyQuantity) => monthlyQuantity + 1);
  };
  const decreaseSeatQuantity = () => {
    if (monthlyQuantity > 0) {
      setMonthlyQuantity((monthlyQuantity) => monthlyQuantity - 1);
    }
  };
  const reset = () => {
    setMonthlyQuantity(originalMonthlyQuantity);
    setPageMode('default');
  };

  const updateSubscription = () => {
    const toastId = toast.loading('Updating..');
    setUpdatingSubscription(true);
    axiosInstance
      .post(`/payment/update-subscription`, {
        organizationId: state.orgId,
        price: process.env.REACT_APP_MONTHLY,
        quantity: debouncedQuantity
      })
      .then((res) => {
        console.log('res of update sub', res);
        if (res.data.paymentRequired) {
          toast.dismiss(toastId);
          setClientSecret(res.data.clientSecret);
          toggleConfirmPaymentModal();
        } else {
          toggleConfirmPaymentModal();
          toast.dismiss(toastId);
          toast.success('Updated.');
          setMonthlyQuantity(monthlyQuantity);
          setOriginalMonthyQuantity(monthlyQuantity);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error('Something went wrong.');
        sentryError(error);
      })
      .finally(() => {
        setUpdatingSubscription(false);
      });
  };

  const addMonthlySubscription = () => {
    navigate(`/organization/${state.orgId}/seatselection`, {
      state: { plan: 'month', seatvalue: 0 }
    });
  };

  const cancelSubscription = () => {
    let toastId = toast.loading('Cancelling.');
    setCancellingSubscription(true);
    axiosInstance
      .post(`/payment/cancel-subscription`, {
        organizationId: state.orgId,
        price: process.env.REACT_APP_MONTHLY
      })
      .then((res) => {
        toast.success('Subscription Cancelled.');
        setCancelAtPeriodEnd(true);
        toggleCancelSubscriptionModal();
        toggleConfirmedCancellationModal();
      })
      .catch((error) => {
        sentryError(error);
      })
      .finally(() => {
        toast.dismiss(toastId);
        setCancellingSubscription(false);
      });
  };

  const reactivateSubscription = async () => {
    let toastId = toast.loading('Reactivating ...');
    setReactivatingSubscription(true);
    axiosInstance
      .post(`/payment/reactivate-subscription`, {
        organizationId: state.orgId,
        price: process.env.REACT_APP_MONTHLY
      })
      .then((res) => {
        toast.success('Plan Reactivated');
        setCancelAtPeriodEnd(false);
        toggleReactivateSubscriptionModal();
      })
      .catch((error) => {
        toast.error('Failed');
        sentryError(error);
      })
      .finally(() => {
        toast.dismiss(toastId);
        setReactivatingSubscription(false);
      });
  };

  const changePaymentDetails = () => {
    navigate(`/organization/${state.orgId}/updatecard`);
  };

  const showPaymentConfirmationModal = async () => {
    if (monthlyQuantity <= 0 && originalMonthlyQuantity === 0) {
      return;
    }
    if (monthlyQuantity === originalMonthlyQuantity) {
      toast('Please change the seat quantity.');
      return;
    }
    toggleConfirmPaymentModal();
    await preViewProration();
  };

  const goToOrganization = () => {
    toggleConfirmedCancellationModal();
    navigate(`/organization/${state.orgId}`);
  };

  const options = {
    clientSecret
  };

  return {
    pageMode,
    loading,
    cardLoading,
    invoiceLoading,
    isMonthlySubscriptionActive,
    monthlyQuantity,
    originalMonthlyQuantity,
    decreaseSeatQuantity,
    increaseSeatQuantity,
    calculatingProrate,
    prorateAmount,
    takenMonthlyUsers,
    latestCard,
    latestInvoice,
    upcomingDueDate,
    clientSecret,
    changePaymentDetails,
    state,
    setPageMode,
    showPaymentConfirmationModal,
    reset,
    isConfirmPaymentModalOpen,
    toggleConfirmPaymentModal,
    addMonthlySubscription,
    options,
    error,
    updateSubscription,
    updatingSubscription, //loading state
    toggleCancelSubscriptionModal,
    isCancelSubscriptionModalOpen,
    cancelSubscription,
    cancellingSubscription, //loading state
    goToOrganization,
    isConfirmedCancellationModalOpen,
    toggleConfirmedCancellationModal,
    cancelAtPeriodEnd,
    isReactivateSubscriptionModalOpen,
    toggleReactivateSubscriptionModal,
    reactivateSubscription,
    reactivatingSubscription //loading state
  };
}

export default useGetMonthlySubscription;
