import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const ItemFragment = styled.span`
  .menu-item {
    color: #cdcaca;
    display: flex;
    align-items: center;
    height: 42px;
    padding-left: 32px;
    &:hover {
      /* color: var(--black); */
      font-weight: bold;
      background-color: #121c29;
    }
    /* padding: 0.5rem; */
  }
`;

const Dropdown = styled.div`
  /* padding: 0.5rem; */
  width: 300px;
  background-color: #1e2736;
  overflow: hidden;
`;

export const DropdownMenu = (props: any) => {
  const handleFunction = (id: any) => {
    if (props.getId) {
      props.getId(id);
    }
  };
  function DropdownItem(props: any) {
    return (
      <ItemFragment>
        {props.type === 'files' ? (
          <Link to={`${props.title}`} onClick={(e) => handleFunction(props.id)} className="menu-item">
            {props.children}
          </Link>
        ) : (
          <Link reloadDocument to={`/project/${props.title}`} className="menu-item">
            {props.children}
          </Link>
        )}
      </ItemFragment>
    );
  }
  return (
    <Dropdown>
      {props.data.map((item: any, index: number) => {
        return (
          <DropdownItem type={props.type} title={item.title || item.id} key={index} id={item.id}>
            {item.title || item.name}
          </DropdownItem>
        );
      })}
    </Dropdown>
  );
};
