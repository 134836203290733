export const getFullNameById = (userId, users, fullName) => {
  if (userId === '') {
    return fullName;
  }
  if (users?.length === 0 || !users) {
    return fullName;
  }
  let orgUser = users.find((user) => user.id === userId);
  if (orgUser) {
    // console.log('actually returning the correct username', orgUser?.fullName);
    return orgUser.fullName;
  }
  return fullName;
};

export const getFullNameByUserName = (username, users) => {
  if (username === '' || users.length === 0 || !users) {
    return username;
  }
  let orgUser = users.find((user) => user.username === username);
  if (orgUser.fullName) {
    return orgUser.fullName;
  } else {
    return username;
  }
};
