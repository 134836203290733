import styled from '@emotion/styled';
import { Formik } from 'formik';
import { ReactComponent as ErrorIllustration } from '../../assets/illustration/ErrorIllustration.svg';
import { AssigneeWrapper, FieldSet, InputWrapper } from '../../components/styles/FormStyles';
import { TextAreaField } from '../../components/Reusable/NewFormik/Textarea';
import axiosInstance from '../../axiosInstance';
import * as Yup from 'yup';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import Button from '../../components/CustomButton';
import { TextArea } from '../../components/styles/Inputs';
// import { InputField } from "../../components/Reusable/NewFormik/Input1";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 124px);
  padding: 0;
`;
const LeftContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 40px;
  button {
    background: #00b292 !important;
    color: white;
  }
`;
const InputContainer = styled.div`
  width: 100%;
`;
const HeaderText = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #8d8d8d;
`;
const Links = styled.a`
  font-weight: 600;
  color: var(--button-background);
  cursor: pointer;
`;
const initialValues = {
  message: ''
};
const validationSchema = Yup.object({
  message: Yup.string().required('Report is Required')
});

export const ErrorPage = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const sendMail = async (values) => {
    try {
      const toastId = toast.loading('Sending ....');
      let result = await axiosInstance.post('/supportRequest', {
        type: 'SUPPORT',
        organizationId: state.orgId,
        comments: values.message,
        route: window.location.href
      });
      if (result) {
        toast.success('Sent , our team will look into it', { id: toastId });
        navigate('/');
      }
    } catch (err) {
      toast.error('Something is wrong please try later');
      console.error('error', err);
    }
  };
  return (
    <Wrapper>
      <LeftContainer>
        <ErrorIllustration />
      </LeftContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={sendMail}
        enableReinitialize={true}
      >
        <form id="support" onSubmit={sendMail}>
          <FormContainer>
            <FieldSet>
              <InputContainer>
                <InputWrapper>
                  <HeaderText>
                    <p>
                      Woah! That's not right! Sorry something went wrong and the platform encountered an error. Please{' '}
                      <Links onClick={() => navigate(-1)}>Try Again</Links>
                      <br />
                      <br />
                      Bugs are not OK! We need to make sure that we know what happend, so we can get right on with
                      fixing it. If you have a moment submit a report indicating what you were trying to do.
                    </p>
                  </HeaderText>
                  <br />
                </InputWrapper>
                <InputWrapper>
                  <TextAreaField
                    label="Reports"
                    style={{ maxWidth: '100%' }}
                    placeholder="Enter the Report"
                    name="message"
                    id="message"
                  />
                </InputWrapper>
                <Button type="submit">Send Report</Button>
              </InputContainer>
            </FieldSet>
          </FormContainer>
        </form>
      </Formik>
      <Toaster />
    </Wrapper>
  );
};
