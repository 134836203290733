import styled from '@emotion/styled'
import React, { useState } from 'react'
import { ReactComponent as DropDownIcon } from "../../assets/icons/GreenCaret.svg"
import { ToggleProps } from '../TableComponents/TableDropDown'

export const CardDropDownStyles = styled("div") <ToggleProps>`
  flex-direction: row;
  display: flex;
  align-items: center;
.caret {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    /* &:hover {
      /* cursor: pointer;  */
      svg {
        transform: rotate(360deg);
      }
   
    svg {
      transform: rotate(270deg);
      transition: transform var(--transition-speed);
    }
  }
  ${(props) =>
    props.open &&
    `
    .caret{
      svg{
        transform: rotate(360deg)
      }
    }`}
  ${(props) =>
    !props.open &&
    ` &:after{
              content: "";
              background: #e5e5e5;
              position: absolute;
              height: 1px;
              width:90vw;
              top: 41px;
              left: 10px;    
            }
    `}

`

const CardDropdown = (props: any) => {
  const [open, setOpen] = useState(props.state || true)
  return (
    <>
      <CardDropDownStyles open={open} onClick={() => setOpen(!open)}>
        <button className="caret">
          <DropDownIcon />
        </button>

      </CardDropDownStyles>
      <>
        {open && props.children}
      </>
    </>
  )
}

export default CardDropdown
