import React, { useEffect } from 'react';
import TaskNoteEditor from '../../../../../components/TaskNote';
import { useGetTask } from '../../../../../hooks/features/tasks/useGetTask';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTaskNote, resetNote } from '../../../../../reducers/taskNote';
import Loader from '../../../../../components/Loader';
import { ErrorPage } from '../../../../Others';

const TaskNote = () => {
  const dispatch = useDispatch();
  const { data, error, loading } = useGetTask();

  // @ts-ignore
  const { loading: TaskNoteLoading, error: TaskNoteError } = useSelector((state) => state.taskNote);

  useEffect(() => {
    if (!data) return;
    // const { type, typeId: workspaceId, organizationId: orgId, projectId, _id: taskId } = data?.get_Task;
    const taskData = { ...data?.get_Task };
    //personal project
    if (taskData?.projectId === 'NOPROJECT') {
      taskData.workspaceId = null;
    } else taskData.workspaceId = taskData.typeId;

    dispatch(
      fetchTaskNote({
        taskId: taskData._id,
        workspaceId: taskData.workspaceId,
        orgId: taskData.organizationId,
        projectId: taskData.projectId,
        taskTypeId: taskData.typeId
      })
    );

    return () => {
      dispatch(resetNote());
    };
  }, [data]);

  if (loading || TaskNoteLoading) return <Loader />;

  if (error || TaskNoteError) return <ErrorPage />;

  return <TaskNoteEditor />;
};

export default TaskNote;
