import styled from '@emotion/styled';
import { ReactComponent as NewGroupIcon } from '../../../../assets/icons/IconNewMessage.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/IconSearchShat.svg';
import { useToggle } from '../../../../lib/UseToggle';
import { PageNavStyles } from '../../../styles/PageNavStyles';
import GroupNameModal from '../../Modals/CreateGroupModal/GroupNameModal';
import { Tabs } from '../../types';

type Props = {
  activeTab: Tabs;
  setActiveTab: (activeTab: Tabs) => void;
  toggleSearch: () => void;
};

const ChatListHeader = ({ activeTab, setActiveTab, toggleSearch }: Props) => {
  const handleTabChange = (selected: Tabs) => {
    setActiveTab(selected);
  };

  const [isAddGroupOpen, toggleAddGroup] = useToggle();

  return (
    <>
      <ChatListHeaderStyles>
        <div>Messages</div>
        <CenteredFlexDiv>
          <SearchIcon className="icons" onClick={toggleSearch} />
          <NewGroupIcon className="icons" onClick={toggleAddGroup} />
        </CenteredFlexDiv>
      </ChatListHeaderStyles>
      <div>
        <PageNavStyles>
          <Nav>
            <ListItem
              className={activeTab === 'Organization' ? 'active' : ''}
              onClick={() => handleTabChange('Organization')}
            >
              Organization
            </ListItem>
            <ListItem
              className={activeTab === 'Outside Organization' ? 'active' : ''}
              onClick={() => handleTabChange('Outside Organization')}
            >
              Outside Organization
            </ListItem>
          </Nav>
        </PageNavStyles>
      </div>
      <GroupNameModal addGroup={isAddGroupOpen} closeAddGroup={toggleAddGroup} />
    </>
  );
};

export default ChatListHeader;

const ChatListHeaderStyles = styled.div`
  max-height: 60px;
  height: 60px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24.51px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;

  .icons {
    cursor: pointer;
  }
`;

const CenteredFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Nav = styled.div`
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  /* width: 350px; */
  width: 100%;
`;

const ListItem = styled.div`
  padding: 10px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 100%;
  .active {
    border-bottom: 5px solid var(--text-secondary);
  }
`;
