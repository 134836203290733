import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
import { StyledTag } from '../../../components/FileTag';
import { Header, HeaderLeft, PageTitle } from '../../../components/styles/Header';
import { PageNavStyles } from '../../Library/FilesView/Files/ViewFile/ViewFile';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { GET_SINGLE_FILE_REGISTER_QUERY } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import axiosInstance from '../../../axiosInstance';
import { AppContext } from '../../../AppContext';
import { useNavigate } from 'react-router';
import NavigationLink from '../../../components/NavigationLink';
// import { defaultOptions } from "../../../../constants/LoaderDefaultOptions";

const TitleLeft = styled.div`
  display: flex;
  margin-left: 2rem;
  align-items: center;
  /* flex-direction: column; */
  span {
    margin-left: 1.8rem;
  }
`;

function ViewRegisterDocument() {
  let { projectId, workspaceId, registerId, fileId } = useParams();
  let navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/documentlist`);
  };

  const { state } = useContext(AppContext);
  useEffect(() => {
    axiosInstance
      .get(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`)
      .then((res) => {
        let userIds = res.data.data.map((user) => {
          return user.userId;
        });
        axiosInstance.post('/discussion', {
          entityId: fileId,
          entityType: 'register',
          userIds,
          orgId: state?.orgId
        });
      })
      .then((res) => {
        console.log('res', res);
      });
  }, [fileId]);
  // console.log({ projectId, workspaceId, registerId, fileId })
  const { data, error, loading } = useQuery(GET_SINGLE_FILE_REGISTER_QUERY, {
    variables: { id: fileId }
  });
  if (error) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <TitleLeft>
            <PageTitle>{data?.get_File?.name}</PageTitle>
            {data.get_File.status ? (
              <StyledTag>{data?.get_File?.status ? data.get_File.status.toUpperCase() : ''}</StyledTag>
            ) : null}
          </TitleLeft>
        </HeaderLeft>
      </Header>
      <PageNavStyles>
        <NavigationLink end to={`/project/workspace/${projectId}/${workspaceId}/${registerId}/viewdocument/${fileId}`}>
          About
        </NavigationLink>
        <NavigationLink to="itemRevision">Item Revision</NavigationLink>
        <NavigationLink to="links">Links</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
}

export default ViewRegisterDocument;
