import * as React from 'react';
import { useTable } from 'react-table';
import styled from '@emotion/styled';

export const TableStyles = styled.div`
  /* display: block;
  max-width: 100%; */
  /* overflow-x: scroll; */

  table {
    width: 100%;
    margin-left: 8px;
    border-spacing: 0;
    /* border-collapse: separate; */
    /* table-layout: fixed; */
    tr {
      /* padding-bottom: 11px; */
      transform: translateY(0);
      /* vertical-align: top; */

      th {
        padding-bottom: 11px;
        padding-left: 20px;
        text-align: left;
        color: #93979f;
        font-weight: 600 !important;
        border-bottom: 1px solid var(--table-border);
      }
      td {
        text-align: left;
        border-bottom: 1px solid var(--table-border);
        padding: 9px 20px;
        font-weight: 400;
        line-height: 22px;
        overflow: hidden;
        .text-wrap {
        }
      }
      .text-wrap {
        /* white-space: normal; */
        /* wrap: nowrap; */
      }
    }
    tbody {
      tr:hover {
        cursor: pointer;
        /* transform: translateY(-10px); */

        border-radius: 5px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
        transition: 0.3s;
        td {
          /* border-top: 2px solid #E5E5E5; */
          /* border-bottom: 2px solid #E5E5E5; */
          /* border-style: none !important; */
        }
        td:first-of-type {
          border-left: 2px solid #e5e5e5;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        td:last-child {
          border-right: 2px solid #e5e5e5;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      td:hover .new-tooltip {
        visibility: visible;
      }
      .new-tooltip {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        bottom: 70%;
        margin-left: -10px;
        cursor: pointer;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

function Table({
  columns,
  data,
  clickHandler,
  getCellProps = defaultPropGetter,
  getRowProps = defaultPropGetter
}: {
  columns?: any;
  data?: any;
  clickHandler?: (id: string) => void;
  getCellProps?: any;
  getRowProps?: any;
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });
  //TODO: remove this
  const doNothing = () => {};

  return (
    <TableStyles>
      <table className="tasks-table" {...getTableProps()}>
        {columns.length > 0 && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: { width: column.width }
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps(getRowProps(row))}
                onClick={() =>
                  clickHandler ? clickHandler(row.original._id ? row.original._id : row.original) : doNothing()
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      // style={{ ...cell.getCellProps().style }}
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style
                        },
                        getCellProps(cell)
                      ])}
                    >
                      {cell.render('Cell')}
                      {cell.column.tooltip ? <span className="new-tooltip">{cell.render('Cell')}</span> : ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableStyles>
  );
}

export default Table;
