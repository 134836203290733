import styled from '@emotion/styled';

export const ErrorText = styled.p`
  color: var(--high-priority);
  /* margin-top: 0px; */
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 0.9rem;
  font-weight: 400;
  /* border: 1px solid red; */
`;

function TextError(props: any) {
  // if (props.children.saved || props.children.value) {
  //   return <ErrorText className="error">{props.children.saved ? props.children.saved : ""}{props.children.value ? props.children.value : ""}</ErrorText>
  // } else
  return <ErrorText className="error">{props.children}</ErrorText>;
}

export default TextError;
