import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import { GET_ORGANIZATION } from '../../../graphql/operations/Queries/Organization/OrganizationQueries';
import { sentryError } from '../../../lib/SentryError';
import { useToggle } from '../../../lib/UseToggle';
import { PageMode } from '../../../pages/Payments/MonthlySubscriptionPage';
import useDebounce from '../../common/useDebounce';
import useGetTakenSeats from '../organization/useGetTakenSeats';
import useGetLatestCard from './useGetLatestCard';
import useGetLatestInvoice from './useGetLatestInvoice';

function useGetYearlySubscription() {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [pageMode, setPageMode] = useState<PageMode>('default');
  const [yearlyQuantity, setYearlyQuantity] = useState<number>(0);
  const [originalYearlyQuantity, setOriginalYearlyQuantity] = useState<number>(0);
  const [upcomingDueDate, setUpComingDueDate] = useState('');
  const [cancelAtPeriodEnd, setCancelAtPeriodEnd] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [subscriptionId, setSubscriptionId] = useState<string>();
  const [isYearlySubscriptionActive, setIsYearlySubscriptionActive] = useState(false);
  const [isConfirmPaymentModalOpen, toggleConfirmPaymentModal] = useToggle(false);
  const [prorateAmount, setProrateAmount] = useState(0);
  const [calculatingProrate, setCalculatingProrate] = useState(false);
  const [updatingSubscription, setUpdatingSubscription] = useState(false);
  const debouncedQuantity = useDebounce(yearlyQuantity, 300);
  const [isCancelSubscriptionModalOpen, toggleCancelSubscriptionModal] = useToggle(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);
  const [isConfirmedCancellationModalOpen, toggleConfirmedCancellationModal] = useToggle(false);
  const [isReactivateSubscriptionModalOpen, toggleReactivateSubscriptionModal] = useToggle(false);
  const [reactivatingSubscription, setReactivatingSubscription] = useState(false);
  const { latestCard, cardLoading } = useGetLatestCard();
  const { latestInvoice, invoiceLoading } = useGetLatestInvoice(subscriptionId as string);
  const { takenYearlyUsers } = useGetTakenSeats();

  const {
    loading,
    error
    // refetch
  } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: state.orgId
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let subscriptionData = data?.get_Organization?.yearlySubscription;
      if (subscriptionData !== null && subscriptionData !== 'null') {
        let yearlySeats = JSON.parse(subscriptionData);
        console.log('yearly Seats', yearlySeats);
        if (yearlySeats.state) {
          //the api will have a field called state if payment has failed. So if this field exists provide no access to the subscription data.
          setIsYearlySubscriptionActive(false);
          return;
        }
        setYearlyQuantity(yearlySeats.numberOfSeats);
        setOriginalYearlyQuantity(yearlySeats.numberOfSeats);
        setSubscriptionId(yearlySeats.subscriptionId);
        setUpComingDueDate(yearlySeats.endDate);
        setCancelAtPeriodEnd(yearlySeats.cancelAtPeriodEnd);
        setIsYearlySubscriptionActive(true);
      } else if (subscriptionData === null || subscriptionData === 'null') {
        setIsYearlySubscriptionActive(false);
      }
    }
  });

  const preViewProration = async () => {
    setCalculatingProrate(true);
    await axiosInstance
      .get(
        `/payment/preview-proration?organizationId=${state.orgId}&price=${process.env.REACT_APP_YEARLY}&quantity=${yearlyQuantity}`
      )
      .then((res) => {
        console.log('Proration response', res);
        let amount = res?.data?.invoice?.total / 100;
        let prorateamount = amount - 9 * yearlyQuantity * 12;
        if (prorateamount >= 0) {
          setProrateAmount(prorateamount.toFixed(2));
        } else {
          setProrateAmount(0);
        }
        setCalculatingProrate(false);
      })
      .catch((error) => {
        console.error('Proration error', error);
        setCalculatingProrate(false);
      });
  };

  useEffect(() => {
    if (debouncedQuantity) {
      preViewProration();
    }
  }, [debouncedQuantity]);

  const increaseSeatQuantity = () => {
    setYearlyQuantity((yearlyQuantity) => yearlyQuantity + 1);
  };

  const decreaseSeatQuantity = () => {
    if (yearlyQuantity > 0) {
      setYearlyQuantity((yearlyQuantity) => yearlyQuantity - 1);
    }
  };

  const reset = () => {
    setYearlyQuantity(originalYearlyQuantity);
    setPageMode('default');
  };

  const updateSubscription = () => {
    const toastId = toast.loading('Updating..');
    setUpdatingSubscription(true);
    axiosInstance
      .post(`/payment/update-subscription`, {
        organizationId: state.orgId,
        price: process.env.REACT_APP_YEARLY,
        quantity: debouncedQuantity
      })
      .then((res) => {
        console.log('res of update sub', res);
        if (res.data.paymentRequired) {
          toast.dismiss(toastId);
          setClientSecret(res.data.clientSecret);
          toggleConfirmPaymentModal();
        } else {
          toast.dismiss(toastId);
          toast.success('Updated.');
          setYearlyQuantity(yearlyQuantity);
          setOriginalYearlyQuantity(yearlyQuantity);
          toggleConfirmPaymentModal();
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error('Something went wrong.');
        console.error('ERROR in updating subscription', error);
      })
      .finally(() => {
        setUpdatingSubscription(false);
      });
  };

  const addYearlySubscription = () => {
    navigate(`/organization/${state.orgId}/seatselection`, {
      state: { plan: 'year', seatvalue: 0 }
    });
  };

  const cancelSubscription = async () => {
    let toastId = toast.loading('Cancelling.');
    setCancellingSubscription(true);
    axiosInstance
      .post(`/payment/cancel-subscription`, {
        organizationId: state.orgId,
        price: process.env.REACT_APP_YEARLY
      })
      .then(() => {
        toast.success('Subscription Cancelled.');
        setCancelAtPeriodEnd(true);
        toggleCancelSubscriptionModal();
        toggleConfirmedCancellationModal();
      })
      .catch((error) => {
        sentryError(error);
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  };

  const reactivateSubscription = async () => {
    let toastId = toast.loading('Reactivating ...');
    setReactivatingSubscription(true);
    axiosInstance
      .post(`/payment/reactivate-subscription`, {
        organizationId: state.orgId,
        price: process.env.REACT_APP_YEARLY
      })
      .then((res) => {
        toast.success('Plan Reactivated');
        setCancelAtPeriodEnd(false);
        toggleReactivateSubscriptionModal();
      })
      .catch((error) => {
        toast.error('Failed');
        sentryError(error);
      })
      .finally(() => {
        toast.dismiss(toastId);
        setReactivatingSubscription(false);
      });
  };

  const changePaymentDetails = () => {
    navigate(`/organization/${state.orgId}/updatecard`);
  };

  const showPaymentConfirmationModal = async () => {
    if (yearlyQuantity <= 0 && originalYearlyQuantity === 0) {
      return;
    }
    if (yearlyQuantity === originalYearlyQuantity) {
      toast('Please change the seat quantity.');
      return;
    }
    toggleConfirmPaymentModal();
    await preViewProration();
  };

  const goToOrganization = () => {
    toggleConfirmedCancellationModal();
    navigate(`/organization/${state.orgId}`);
  };
  const options = {
    clientSecret
  };

  return {
    pageMode,
    loading,
    cardLoading,
    invoiceLoading,
    isYearlySubscriptionActive,
    yearlyQuantity,
    originalYearlyQuantity,
    decreaseSeatQuantity,
    increaseSeatQuantity,
    calculatingProrate,
    prorateAmount,
    takenYearlyUsers,
    latestCard,
    latestInvoice,
    upcomingDueDate,
    clientSecret,
    changePaymentDetails,
    state,
    setPageMode,
    showPaymentConfirmationModal,
    reset,
    isConfirmPaymentModalOpen,
    toggleConfirmPaymentModal,
    addYearlySubscription,
    options,
    error,
    updateSubscription,
    updatingSubscription,
    cancelSubscription,
    toggleCancelSubscriptionModal,
    isCancelSubscriptionModalOpen,
    cancellingSubscription,
    isConfirmedCancellationModalOpen,
    toggleConfirmedCancellationModal,
    goToOrganization,
    cancelAtPeriodEnd,
    isReactivateSubscriptionModalOpen,
    toggleReactivateSubscriptionModal,
    reactivateSubscription,
    reactivatingSubscription
  };
}

export default useGetYearlySubscription;
