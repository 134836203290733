import store from "../store";

export const handleTaskNoteAutoSave = async (ws: WebSocket, authToken: string) => {
  const { taskNote } = store.getState();

  const body = {
    data: { ...taskNote.taskNoteData, content: JSON.stringify(taskNote.sections) },
    _id: taskNote.taskNoteData._id,
    type: 'UPDATE_TASK_NOTE_CONTENT',
    token: authToken,
    workspaceId: taskNote.taskNoteData.workspaceId
  };

  ws.send(JSON.stringify(body));
};