import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ToolBarStyle = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  margin: 10px 0px;
  border-radius: 5px;
  .is-active {
    background-color: rgba(196, 196, 196, 0.4);
    border: 1px solid #cccccc;
    border-radius: 4px;
  }

  .center {
    /* display: flex;
  justify-content: center;
  align-items: center; */
  }
`;

export const EmojiButton = styled.div`
  padding: 5px 10px;
  margin-right: 10px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #93979f;

  &:hover {
    cursor: pointer;
  }
`;

export const MenuButton = styled.button<{ border?: boolean }>`
  border: none;
  /* height: 100%; */
  /* min-width: 50px; */
  /* margin: 2px 10px; */
  padding: 5px 10px;
  margin-right: 10px;
  background-color: transparent;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  color: #93979f;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.border &&
    css`
      border: 1px solid #93979f;
      border-radius: 5px;
    `}
`;

export const DropDownMenu = styled.div`
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    border: 2px solid #b4b7ba;
    border-radius: 5px;
    padding: 5px;
    z-index: 1;
  }

  &:hover {
    .dropdown-content {
      display: block;
    }
  }
`;
