import styled from '@emotion/styled';
import { ReactComponent as ErrorIllustration } from '../../assets/illustration/ErrorIllustration.svg';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 124px);
  padding: 0;
`;
const LeftContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;
const HeaderText = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #8d8d8d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
`;

function SeatNotProvided() {
  return (
    <Wrapper>
      <LeftContainer>
        <ErrorIllustration />
      </LeftContainer>
      <HeaderText>
        <p>
          Oh no. You were not able to access this page because the admin of your organization has not yet assiged a seat
          to you. You will be able to access this page once a seat is assigned to you.
        </p>
      </HeaderText>
    </Wrapper>
  );
}

export default SeatNotProvided;
