import { createContext, useState } from "react";

type TokenContextProviderProps = {
  children: React.ReactNode
}

type TokenContextProps = {
  token: string
  setToken: React.Dispatch<React.SetStateAction<string | null>>
}

export const TokenContext = createContext({} as TokenContextProps)

export const TokenContextProvider = ({ children }: TokenContextProviderProps) => {
  const [token, setToken] = useState<string | null>("")
  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  )
}