import { gql } from '@apollo/client';

export const DownloadFileQuery = gql`
  query DownloadFileQuery($id: ID!) @api(name: "library") {
    getVendia_File(id: $id) {
      temporaryUrl
      _id
    }
  }
`;

export const LIST_FILES_QUERY = gql`
  query MyQuery($type_typeId: String, $recordStatus: Float) @api(name: "library") {
    list_FileItems(
      filter: { type_typeId: { eq: $type_typeId }, isDraft: { eq: false }, recordStatus: { eq: $recordStatus } }
    ) {
      _FileItems {
        collections
        createdAt
        createdBy
        createdByName
        fields
        fileDescription
        fileId
        fileType
        fileName
        _id
        links
        name
        projectId
        type
        typeId
        updatedAt
        updatedBy
      }
    }
  }
`;

export const LIST_DRAFT_FILES_QUERY = gql`
  query MyQuery($type_typeId: String, $recordStatus: Float) @api(name: "library") {
    list_FileItems(
      filter: { type_typeId: { eq: $type_typeId }, isDraft: { eq: true }, recordStatus: { eq: $recordStatus } }
    ) {
      _FileItems {
        collections
        createdAt
        createdBy
        createdByName
        fields
        fileDescription
        fileId
        fileType
        fileName
        _id
        links
        name
        projectId
        type
        typeId
        updatedAt
        updatedBy
      }
    }
  }
`;

export const LIST_COLLECTION_QUERY = gql`
  query listCollectionQuery($typeId: String, $type: String) @api(name: "library") {
    list_CollectionItems(filter: { type: { eq: $type }, typeId: { eq: $typeId } }) {
      _CollectionItems {
        _id
        files
        createdBy
        createdAt
        collectionDescription
        name
        projectId
        type
        typeId
      }
    }
  }
`;

export const SINGLE_FILE_QUERY = gql`
  query GET_FILE($id: ID!) @api(name: "library") {
    get_File(id: $id) {
      _id
      fileType
      fileDescription
      fileId
      createdByName
      links
      fileName
      name
      projectId
      createdBy
      createdAt
      collections
      fields
      recordStatus
    }
  }
`;

export const SINGLE_COLLECTION = gql`
  query getSingleCollection($id: ID!) @api(name: "library") {
    get_Collection(id: $id) {
      collectionDescription
      createdAt
      createdBy
      files
      _id
      name
      projectId
      type
      typeId
    }
  }
`;
