import { useState } from 'react';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';
import { Flex, InputRadio, ModalLabel, Wrapper } from './ChoosePlanModal';
import { Seating } from './ChooseSeatTypeModal';

type SProps = {
  seatAssigningTo: any;
  isOpen: boolean;
  onClose: () => void;
  assignSeatToUser: any;
  buttonState: boolean;
  isInSufficient: boolean;
  stripeLoading?: boolean;
  updateSeats: any;
  monthlySeats: number;
  yearlySeats: number;
  currentUsers: any;
  totalSeats: any;
  showConfirm: any;
  setShowConfirm: any;
  subscriptionType: string;
};

function AssignSeatModal(props: SProps) {
  const {
    isOpen,
    assignSeatToUser,
    buttonState,
    isInSufficient,
    onClose,
    updateSeats,
    currentUsers,
    monthlySeats,
    yearlySeats,
    subscriptionType
  } = props;
  const [plan, setActivePlan] = useState<'PAID' | 'TRIAL' | 'MONTHLY' | 'YEARLY'>(
    subscriptionType === 'PAID' ? 'MONTHLY' : 'TRIAL'
  );
  const onClick = (type: 'MONTHLY' | 'YEARLY' | 'TRIAL') => {
    setActivePlan(type);
  };

  let availableMonthlySeats = monthlySeats - currentUsers.filter((user) => user?.seatType === 'MONTHLY').length;
  let availableYearlySeats = yearlySeats - currentUsers.filter((user) => user?.seatType === 'YEARLY').length;
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalContainer width="638px">
          {!isInSufficient && (
            <>
              <ModalTitle>Choose the seat type</ModalTitle>
              <ModalBody>
                <ModalText>Please choose preferred seat type</ModalText>
                <Flex margin="2rem 0 2rem ">
                  {subscriptionType === 'PAID' && (
                    <>
                      <Wrapper active={plan === 'MONTHLY'} onClick={() => onClick('MONTHLY')}>
                        <InputRadio active={plan === 'MONTHLY'} />
                        <ModalLabel htmlFor="month">
                          <Seating period="Monthly" quantity={availableMonthlySeats} />
                        </ModalLabel>
                      </Wrapper>
                      <Wrapper active={plan === 'YEARLY'} onClick={() => onClick('YEARLY')}>
                        <InputRadio active={plan === 'YEARLY'} />
                        <ModalLabel htmlFor="year">
                          {' '}
                          <Seating period="Yearly" quantity={availableYearlySeats} />
                        </ModalLabel>
                      </Wrapper>
                    </>
                  )}
                  {(subscriptionType === 'TRIAL' || subscriptionType === 'FREE') && (
                    <Wrapper active={plan === 'TRIAL'} onClick={() => onClick('TRIAL')}>
                      <InputRadio active={plan === 'TRIAL'} />
                      <ModalLabel htmlFor="month">
                        <Seating period="Trial" quantity="TRIAL SEATS" />
                      </ModalLabel>
                    </Wrapper>
                  )}
                </Flex>
              </ModalBody>
              <ModalWrapper>
                <Button disabled={buttonState} onClick={onClose}>
                  Cancel
                </Button>
                <Button disabled={buttonState} onClick={() => assignSeatToUser(plan)}>
                  Assign Seat
                </Button>
              </ModalWrapper>
            </>
          )}

          {/* {!isSufficient && showConfirm ? ( */}
          <>
            {/* <ModalTitle>Assign a seat</ModalTitle>
              <ModalBody>
                <ModalText>
                  Do you want to assign a seat to <strong>{`${seatAssigningTo?.userName}`}</strong>. The user will
                  become an active user in the organization
                </ModalText>
              </ModalBody>
              <ModalWrapper>
                <Button disabled={buttonState} secondary onClick={onClose}>
                  Cancel
                </Button>
                <Button disabled={buttonState} onClick={() => assignSeatToUser(plan)}>
                  Assign Seat
                </Button>
              </ModalWrapper> */}
          </>
          {/* ) : ( */}
          {isInSufficient && (
            <>
              <ModalTitle>Available seats insufficient</ModalTitle>
              <ModalBody>
                <ModalText>
                  You dont have sufficient seats available in your organization. Please increase the number of seats for
                  your organization.
                </ModalText>
              </ModalBody>
              <ModalWrapper>
                <Button secondary onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={updateSeats}>Upgrade</Button>
              </ModalWrapper>
            </>
          )}
        </ModalContainer>
      </Modal>
    </>
  );
}

export default AssignSeatModal;
