import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../AppContext';
import { createProjectMutation } from '../../../graphql/operations/Mutations/Projects/ProjectMutations';
import { LIST_PROJECTS_QUERY } from '../../../graphql/operations/Queries/Projects/ProjectQueries';

type Values = {
  projectName?: string;
  sequentialCode: boolean;
  client?: string;
  description?: string;
  projectCode?: string | number;
};

const useCreateProject = () => {
  const [sequentialNumber, setSequentialNumber] = useState<number>();
  const [submitting, setSubmitting] = useState(false);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const userId = state.userId;
  const fullName = state.fullName;

  const [createProject] = useMutation(createProjectMutation);

  const {
    data: projectList,
    loading: projectsLoading,
    error: projectsError
  } = useQuery(LIST_PROJECTS_QUERY, {
    variables: {
      organizationId: state.orgId
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      let projectListData = projectList?.list_ProjectItems?._ProjectItems;
      const projectCodeNumber =
        projectListData && projectListData.reduce((acc: number, shot) => (acc = acc > shot.code ? acc : shot.code), 0); //finding highest number
      const projectCodeLargestNumber = projectCodeNumber && parseInt(projectCodeNumber);
      const finalNumber = projectCodeLargestNumber && projectCodeLargestNumber + 1;
      setSequentialNumber(finalNumber);
    }
  });

  const updateCheckBoxClick = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    value: boolean
  ) => {
    let projectListData = projectList?.list_ProjectItems?._ProjectItems;
    const projectCodeNumber =
      projectListData && projectListData.reduce((acc, shot) => (acc = acc > shot.code ? acc : shot.code), 0);
    const projectCodeLargestNumber = projectCodeNumber && parseInt(projectCodeNumber);
    const sequentialNumber = projectCodeLargestNumber && projectCodeLargestNumber + 1;
    setFieldValue('sequentialCode', value);
    if (value === false) setFieldValue('projectCode', '');
    else setFieldValue('projectCode', sequentialNumber);
  };

  const generateCode = (setFieldValue) => {
    const code = `${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(
      Math.random() * 10
    )}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`;
    // return code;

    setFieldValue('projectCode', code);
  };

  const onSubmit = async (values: Values) => {
    if (!state.orgId || state.orgId === 'null') {
      toast.error('You have to be a part of an organization or create an organization before creating a project');
    } else {
      setSubmitting(true);
      const toastId = toast.loading('Creating Project');
      try {
        let res = await createProject({
          variables: {
            projectCode: values.projectCode !== '' ? values.projectCode?.toString() : '',
            projectName: values.projectName,
            client: values.client,
            description: values.description,
            organizationId: state.orgId,
            createdBy: state.userId,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            updatedBy: userId,
            recordStatus: 0,
            isShared: false,
            notificationData: {
              actionType: 'add_project',
              // projectId: res.data.add_Project_async.result._id,
              organizationId: state.orgId
            }
          }
        });
        if (res) {
          toast.success('New Project Created', {
            id: toastId
          });
          let data = {
            _id: res.data.add_Project.result._id,
            ...values,
            name: values.projectName,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
          };
          let projectId = res.data.add_Project.result._id;
          setTimeout(() => {
            navigate(`/project/${projectId}/workspaces`, { state: data });
          }, 3000);
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        toast.error('Oh no something went wrong');
        toast.dismiss(toastId);
      }
    }
  };

  const initialValues: Values = {
    sequentialCode: true,
    projectName: '',
    client: '',
    description: '',
    projectCode: !_.isEmpty(projectList?.list_ProjectItems?._ProjectItems)
      ? sequentialNumber && sequentialNumber.toString()
      : ''
  };

  return {
    initialValues,
    onSubmit,
    generateCode,
    updateCheckBoxClick,
    projectList,
    projectsError,
    projectsLoading,
    fullName,
    submitting
  };
};

export default useCreateProject;
