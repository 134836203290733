import {gql} from "@apollo/client"

export const getTemplateQuery = gql`
  query getTemplateQuery($id: ID!) @api(name: "workspace") {
    get_Template(id: $id) {
      _id
      createdAt
      otherFields
      recordStatus
      templateName
      createdBy
    }
  }
`;