import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import store from './store';
import { GlobalStyles } from '../src/components/styles/GlobalStyles';
import { Integrations } from '@sentry/tracing';
import { SidebarStateProvider } from '../src/lib/SidebarState.js';
import { ApolloProvider } from '@apollo/client';
import { AppContextProvider } from './AppContext';
import { client } from './apollo';
import { Provider } from 'react-redux';
import { PinnedProjectsProvider } from './PinnedProjectContext';
import { errorStyles, SuccessStyles } from './constants/ToasterOptions';
import { Toaster } from 'react-hot-toast';
import { ErrorPage } from './pages/Others/ErrorPage';
import { TokenContextProvider } from './TokenContext';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  // enabled: true,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: 0.2,
  environment: process.env.NODE_ENV,
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  },
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.Breadcrumbs({
      console: true,
      dom: true,
      fetch: true,
      history: true,
      sentry: true,
      xhr: true
    })
  ]
});

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const app = (
  <Router>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <AppContextProvider>
            <TokenContextProvider>
              <PinnedProjectsProvider>
                <React.StrictMode>
                  <SidebarStateProvider>
                    <GlobalStyles />
                    <App />
                    <Toaster
                      toastOptions={{
                        error: { ...errorStyles },
                        success: { ...SuccessStyles }
                      }}
                    />
                  </SidebarStateProvider>
                </React.StrictMode>
              </PinnedProjectsProvider>
            </TokenContextProvider>
          </AppContextProvider>
        </Sentry.ErrorBoundary>
      </Provider>
    </ApolloProvider>
  </Router>
);
ReactDOM.render(app, document.getElementById('root'));
