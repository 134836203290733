import styled from '@emotion/styled';
import React, { useState } from 'react';
import { PlanType } from '../../../pages/Organization/ViewOrganization';
import { ModalText } from '../../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../../CustomButton';
import Modal from '../../Modal';
import { ModalBody, ModalTitle, ModalWrapper } from '../../styles/ModalStyles';

type WrapperProps = {
  active: boolean;
};
type FlexProps = {
  flexDireaction?: string;
  margin?: string;
};
type TextProps = {
  fontWeight?: string;
  fontSize?: string;
  color?: string;
  margin?: string;
  padding?: string;
};

export const ModalLabel = styled.label`
  margin: 0;
  padding: 0;
  margin-right: 1rem;
`;
export const Wrapper = styled.div<WrapperProps>`
  width: 40%;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  border: 1px solid ${(props) => (props.active ? '#00B292' : '#ACADAC')};
  padding: 1rem;
  border-radius: 5px;
  &:not(:last-of-type) {
    margin-right: 2rem;
  }
`;

export const Flex = styled('div')<FlexProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.flexDireaction ? props.flexDireaction : 'row')};
  margin: ${(props) => props.margin};
`;
export const Text = styled('p')<TextProps>`
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  display: inline-block;
`;

export const InputRadio = styled.div<WrapperProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  outline: 1px solid ${(props) => (props.active ? '#00B292' : '#d9d9d9')};
  margin-top: 0.3rem;
  outline-offset: 2px;
  background-color: ${(props) => (props.active ? '#00B292' : '')};
`;

type ChoosePlanModalProps = {
  isOpen: boolean;
  onClose: any;
  upgradePlan: (type: PlanType) => void;
  buttonState: boolean;
};

const Pricing = (props: { period: string; price: string }) => (
  <Flex flexDireaction="column">
    <Text margin="0 0 1rem 0">{props.period} Plan</Text>
    <Text fontWeight="600" fontSize="24px" color="#7F9E90">
      ${props.price}{' '}
      <Text fontSize="16px" padding="0 0 5px 0" fontWeight="400" color="#93979F">
        /seat
      </Text>
    </Text>
    <Text fontWeight="400" fontSize="10px">
      {props.period === 'Yearly' ? 'per month' : null} Billed {props.period}
    </Text>
  </Flex>
);
export const ChoosePlanModal = (props: ChoosePlanModalProps) => {
  // const [[month, year], setChecked] = useState([true, false]);
  const [plan, setActivePlan] = useState<'month' | 'year'>('month');

  const onClick = (type: 'month' | 'year') => {
    setActivePlan(type);
  };
  return (
    <Modal styles={{ width: '550px' }} open={props.isOpen} onClose={props.onClose}>
      <ModalTitle>Choose Your Plan</ModalTitle>
      <ModalBody>
        <ModalText>Please choose your preferred billing cycle</ModalText>
        <Flex margin="2rem 0 2rem ">
          <Wrapper active={plan === 'month'} onClick={() => onClick('month')}>
            <InputRadio active={plan === 'month'} />
            <ModalLabel htmlFor="month">
              <Pricing period="Monthly" price="17" />
            </ModalLabel>
          </Wrapper>
          <Wrapper active={plan === 'year'} onClick={() => onClick('year')}>
            <InputRadio active={plan === 'year'} />
            <ModalLabel htmlFor="year">
              {' '}
              <Pricing period="Yearly" price="9" />
            </ModalLabel>
          </Wrapper>
        </Flex>

        <ModalWrapper>
          <Button disabled={props.buttonState} secondary onClick={props.onClose}>
            Cancel
          </Button>
          <Button disabled={props.buttonState} onClick={() => props.upgradePlan(plan)}>
            Upgrade
          </Button>
        </ModalWrapper>
      </ModalBody>
    </Modal>
  );
};
