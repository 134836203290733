import styled from '@emotion/styled';
import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { stripePromise } from '../..';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../axiosInstance';
import Loader from '../../components/Loader';
import AddCardForm from '../../components/Payment/AddCardForm';
import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../components/styles/Header';
// import { EmailInput, EmailInputDiv, EmailLabel, FormHeader } from '../../components/Payment/CheckoutForm';
//import { loadStripe } from '@stripe/stripe-js';
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentsWrapper = styled.div`
  /* display: flex; */
  width: 40vw;
  margin-top: 20px;
  margin-left: 80px;
  /* border: 1px solid red; */
`;

// const Form = styled.form`
//   width: 40vw;
//   height: 100vh;
//   min-width: 500px;
//   align-self: center;
//   /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
//     0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
//   border-radius: 7px;
//   padding: 40px;
//   @media only screen and (max-width: 600px) {
//     width: 80vw;
//     min-width: initial;
//   }
// `;

function AddNewCardPage() {
  let { organizationId } = useParams();
  const [loading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/payment/client-intent?organizationId=${organizationId}`)
      .then((res) => {
        console.log('Client Secret', res);
        setClientSecret(res.data.clientSecret);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('ERROR', error);
        setIsLoading(false);
      });
  }, []);

  const options = {
    clientSecret
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle> Add New Card</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
      </Header>
      <PaymentsWrapper>
        {clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <AddCardForm />
          </Elements>
        )}
      </PaymentsWrapper>
    </>
  );
}

export default AddNewCardPage;
