import NotesContainer from '../../../../../../components/Notes/NotesContainer';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { LIST_NOTES_QUERY } from '../../../../../../graphql/operations/Queries/Library/NotesQueries';
import { useNavigate, useParams } from 'react-router';
import { GreyButton } from '../../../../../../components/styles/ButtonStyles';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../../../Others';

const NoteStyles = styled.div`
  margin-left: 70px;
  margin-top: 18px;
`;

function LinkedNotes2() {
  let navigate = useNavigate();
  let { fileId } = useParams();
  const { data, error, loading, refetch } = useQuery(LIST_NOTES_QUERY, {
    variables: { type_typeId: `file_${fileId}`, recordStatus: 0, operation: 'query' },
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    refetch();
  }, [data]);
  const createNewLinkedNote = () => {
    navigate(`/Files/${fileId}/notes/new`);
  };
  const viewNote = (id: string) => {
    navigate(`/Files/notes/${id}`);
  };
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) return <ErrorPage />;
  return (
    <>
      <NoteStyles>
        <GreyButton onClick={createNewLinkedNote}>New Note</GreyButton>
      </NoteStyles>
      <NotesContainer clickHandler={viewNote} data={data?.list_NoteAndDisscussionItems?._NoteAndDisscussionItems} />
    </>
  );
}

export default LinkedNotes2;
