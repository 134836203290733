import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import CreateWorkspaceTaskValidationSchema from '../../../components/Validations/CreateWorkspaceTaskValidation';

import { selectOptions, statusOptions } from '../../../constants/DropDownOptions';
import { addTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';

function useCreateWorkspaceTask() {
  const navigate = useNavigate();
  const { projectId, workspaceId } = useParams();
  const { state } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [loadingWorkspaceMembers, setLoadingWorkspaceMembers] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const initialState = {
    title: '',
    projectId: projectId,
    type: '',
    workspaceId: workspaceId,
    description: '',
    dueDate: '',
    isPersonal: false,
    priority: selectOptions[1],
    recordStatus: 'active',
    status: statusOptions[0],
    links: [{ value: '', saved: false }],
    assignedTo: '',
    assignedToDetails: {
      name: '',
      profilePicture: '',
      email: ''
    },
    assignee: state.userId,
    assigneeDetails: {
      name: state.fullName,
      profilePicture: '',
      email: ''
    },
    updatedBy: state.userId
  };

  useEffect(() => {
    const getWorkspaceMembers = async () => {
      setLoadingWorkspaceMembers(true);
      axiosInstance
        .get(`/userEntity/?entityId=${workspaceId}&entityType=WORKSPACE`)
        .then((response) => {
          if (response.data.data && response.data.data?.length > 0) {
            let fullDetails = response.data.data.map((user) => {
              return {
                id: user.userId,
                label: user.userId === state.userId ? `Myself (${user.fullName})` : user.fullName,
                value: user.fullName
              };
            });
            setUsers(fullDetails);
            setLoadingWorkspaceMembers(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoadingWorkspaceMembers(false);
        });
    };

    getWorkspaceMembers();
  }, []);

  const [addTask] = useMutation(addTaskMutation, {
    onCompleted: async (data) => {},
    fetchPolicy: 'no-cache'
  });

  const handleSubmit = async (taskData: any) => {
    let linkValues: string[] = [];
    setSubmitting(true);
    const toastId = toast.loading('Creating task ...');
    if (taskData.links && taskData.links.length > 0) {
      linkValues = taskData.links.map((link) => link.value);
    }
    let values = {
      description: taskData?.description,
      dueDate: taskData?.dueDate,
      priority: taskData?.priority?.value,
      status: taskData?.status?.value,
      title: taskData?.title,
      links: linkValues,
      recordStatus: 0,
      assignee: taskData?.assignee,
      assignedTo: taskData?.assignedTo,
      assignedToDetails: {
        name: taskData?.assignedToDetails.name,
        profilePicture: ''
      },
      assigneeDetails: {
        name: state.fullName,
        profilePicture: ''
      },
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString()
    };
    console.log('VALUES', values);
    try {
      let variables = {
        ...values,
        projectId: taskData.projectId,
        type: 'workspace',
        typeId: taskData.workspaceId,
        organizationId: state.orgId,
        organizationId_projectId_typeId: `${state.orgId}_${taskData.projectId}_${taskData.workspaceId}`
        // notificationData: {
        //   actionType: 'assignment',
        //   userToNotify: values.assignedTo,
        //   organizationId: state.orgId,
        //   workspaceName: taskData.workspaceName,
        //   projectName: taskData.projectName,
        //   host: window.location.origin
        // }
      };
      let response = await addTask({
        variables: variables
      });
      if (response) {
        let task = {
          _id: response.data.add_Task.result._id,
          ...variables
        };
        toast.dismiss(toastId);
        toast.success('Task created');
        setSubmitting(false);
        navigate(`/project/workspace/${projectId}/${workspaceId}/tasks`, {
          state: task
        });
      }
    } catch (e) {
      console.error('ERROR IN CREATING TASK', e);
      toast.dismiss(toastId);
      setSubmitting(false);
    }
  };

  const doNothing = () => {};

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: CreateWorkspaceTaskValidationSchema,
    validateOnBlur: true,
    onSubmit: handleSubmit
  });

  return {
    formik,
    users,
    loadingWorkspaceMembers,
    doNothing,
    submitting,
    state
  };
}

export default useCreateWorkspaceTask;
