import styled from '@emotion/styled';
import useGetIsSuperAdmin from '../../../hooks/common/useGetIsSuperAdmin';
import { getDate } from '../../../lib/getDate';
import { getFullNameById } from '../../../lib/getFullName';
import { AssigneeSeparator, AssigneeWrapper, AvatarStyles } from '../../styles/FormStyles';
import { Label } from '../../styles/Label';
import { Small } from '../../styles/SmallText';
import CustomAvatar from '../../TableComponents/CustomAvatar';

const DetailContainer = styled.div`
  * {
    /* margin: 0; */
    /* padding: 0; */
  }
  display: flex;
  width: 100%;
  padding: 0 3rem;
  padding-top: 2rem;
  border-radius: 10px;
  gap: 2rem;
  .vieworg {
    &-pic {
      max-height: 90%;
      /* max-width: 50%;  */
      width: 30%;
      margin-right: 20px;
      border-radius: 10px;
      object-fit: cover;
    }
    &-avatar {
      height: 120px;
      width: 23%;
      background: #ff6b00;
      border-radius: 10px;
      font-size: 48px;
      margin-right: 20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      &-sm {
        width: 1rem;
        height: 1rem;
        background-color: red;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &-name {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 10px;
      /* identical to box height */

      color: #93979f;
    }
    &-platform {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      /* identical to box height */

      color: #1c1c1c;
    }
    &-info-table {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;
      & > span {
        /* font-weight: 600; */
        font-size: 16px;
        color: #93979f;
      }
      & > div {
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        & > span {
          font-weight: 400;
          :first-of-type {
            color: #1c1c1c;
          }
          :last-of-type {
            color: #93979f;
          }
        }
      }
    }
    &-divider {
      background: #cfd3db;
      height: 1px;
      width: 100%;
      margin-bottom: 1rem;
    }
    &-members {
      min-width: 50%;
      border-radius: 10px;
      background-color: #f6f6f6;
      padding: 2rem 2rem;
      /* max-height: 40%; */
    }
    &-cont {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      span {
        /* width: 50%; */
        font-size: 16px;
        /* color: #1c1c1c; */
        &:first-of-type {
          font-weight: 600;
        }
        &:nth-of-type(2) {
          font-weight: 400;
          margin-left: 20px;
        }
      }
    }
  }
  .vieworg-details {
    padding: 2rem 3rem;
    width: 60%;
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    max-height: 50vh;
    padding-bottom: 0;
  }
`;

export const SmallText = styled.span`
  color: hsla(220, 6%, 60%, 1);
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-left: 2px;
`;

const AssigneeWrapperExtended = styled(AssigneeWrapper)`
  padding-left: 0rem;
  align-items: initial;
  label {
    padding-top: 10px;
    color: #93979f;
  }
`;

export const TotalSeatsDiv = styled.div`
  min-width: 40%;
  border-radius: 10px;
  background-color: #f6f6f6;
  padding: 2rem 2rem;
  margin-top: 10px;
`;

const SeatType = styled.span`
  color: hsla(220, 6%, 60%, 1);
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 2px;
`;

type OrganizationProps = {
  getOrganization: any;
  userDetails: any;
  orgUsers: any;
  state: any;
  totalSeats: number;
  monthlySeats: number;
  yearlySeats: number;
};

function OrganizationDetails(props: OrganizationProps) {
  const { getOrganization, orgUsers, state, userDetails, monthlySeats, totalSeats, yearlySeats } = props;
  const { isSuperAdmin } = useGetIsSuperAdmin();
  const subscriptionType = getOrganization?.get_Organization?.subscriptionType;
  const paymentStatus = getOrganization?.get_Organization?.paymentStatus;
  return (
    <DetailContainer>
      <div className="vieworg-details">
        {getOrganization?.get_Organization?.photo ? (
          <img
            src={
              getOrganization?.get_Organization?.photo
                ? `${getOrganization?.get_Organization?.photo}?${new Date().getTime()}`
                : ''
            }
            className="vieworg-pic"
            alt="Organization"
          />
        ) : (
          <span className="vieworg-avatar">{getOrganization?.get_Organization?.name[0]}</span>
        )}
        <div className="vieworg-info">
          <p className="vieworg-name">Organization Name</p>
          <p className="vieworg-platform">{getOrganization?.get_Organization?.name}</p>
          <AssigneeWrapperExtended>
            <Label>Created by</Label>
            <AssigneeSeparator>
              <AvatarStyles>
                <CustomAvatar
                  isTable={false}
                  size={21}
                  name={getFullNameById(getOrganization?.get_Organization?.createdBy, orgUsers, userDetails.fullName)}
                />
              </AvatarStyles>
              <Small>{getDate(getOrganization?.get_Organization?.createdAt)}</Small>
            </AssigneeSeparator>
          </AssigneeWrapperExtended>
        </div>
      </div>
      <div>
        {isSuperAdmin ? (
          <div className="vieworg-members">
            <div className="vieworg-cont">
              <span>Total Number of Active Members</span>
              <span>
                {state?.currentOrganizationUsers?.length}{' '}
                {state?.currentOrganizationUsers?.length === 1 ? 'Member' : 'Members'}{' '}
              </span>
            </div>
            <div className="vieworg-cont">
              <span>Awaiting for seats</span>
              <span>
                {state?.awaitingSeatOrganizationUsers?.length}{' '}
                {state?.awaitingSeatOrganizationUsers?.length === 1 ? 'Member' : 'Members'}
              </span>
            </div>
            <div className="vieworg-cont" style={{ marginBottom: '0px' }}>
              <span>Number of invited members</span>
              <span>
                {state?.pendingOrganizationUsers?.length}{' '}
                {state?.pendingOrganizationUsers?.length === 1 ? 'Member' : 'Members'}
              </span>
            </div>
          </div>
        ) : null}
        <TotalSeatsDiv>
          <div className="vieworg-cont">
            <span>Total Available Seats</span>
            {subscriptionType === 'PAID' && paymentStatus !== 'null' ? (
              <span>
                {totalSeats - state.currentOrganizationUsers.length} / {totalSeats}{' '}
                <SmallText>Seats Available</SmallText>
              </span>
            ) : null}
            {subscriptionType === 'PAID' && paymentStatus === 'null' ? (
              <span>
                {totalSeats} / {totalSeats} <SmallText>Seats Available</SmallText>
              </span>
            ) : null}
            {subscriptionType === 'FREE' ? <span>No seat limit during trial period.</span> : null}
            {subscriptionType === null ? <span>Please upgrade plan to activate your subcription.</span> : null}
          </div>
          <div className="vieworg-cont">
            <>
              <SeatType>Monthly Seats</SeatType>
              {subscriptionType === 'PAID' ? (
                <span>
                  {monthlySeats - state.currentOrganizationUsers.filter((user) => user.seatType === 'MONTHLY').length} /{' '}
                  {monthlySeats} <SmallText>Seats Available</SmallText>
                </span>
              ) : subscriptionType === 'FREE' || subscriptionType === 'TRIAL' ? (
                <span>Trial Seats</span>
              ) : null}
            </>
          </div>
          <div className="vieworg-cont">
            <>
              <SeatType>Yearly Seats</SeatType>
              {subscriptionType === 'PAID' ? (
                <span>
                  {yearlySeats - state.currentOrganizationUsers.filter((user) => user.seatType === 'YEARLY').length} /{' '}
                  {yearlySeats} <SmallText>Seats Available</SmallText>
                </span>
              ) : subscriptionType === 'FREE' || subscriptionType === 'TRIAL' ? (
                <span>Trial Seats</span>
              ) : null}
            </>
          </div>
        </TotalSeatsDiv>
      </div>
    </DetailContainer>
  );
}

export default OrganizationDetails;
