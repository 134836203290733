//This function is purely used for converting a number less than 10
// to display in two digits. For Example if you pass 8, the function will return 08.
// for numbers greater than 10 or below 0 it will return the number as is.
// It mainly for display purposes .

const prependZero = (num: number) => {
  if (num >= 10) {
    return num;
  }
  if (num <= 0) {
    return num;
  }
  return String(num).padStart(2, '0');
};

export default prependZero;
