import BackIcon from '../../../assets/SvgComponents/BackIcon';
import ArchivedProjectsList from '../../../components/ArchivedProjectsList';
import Loader from '../../../components/Loader';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import useArchivedProjects from '../../../hooks/features/projects/useArchivedProjects';
import { ErrorPage } from '../../Others';
import { EmptyDataContainer, ProjectsContainer } from './Projects';

function ProjectArchivalPage() {
  const { data, error, loading, handleUnArchive } = useArchivedProjects();
  if (error) return <ErrorPage />;
  if (loading) return <Loader />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon />
          <NewTitleLeft>
            <PageTitle>Archived Projects</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight></TitleRight>
      </Header>
      <ProjectsContainer>
        {data?.list_ProjectItems?._ProjectItems && data?.list_ProjectItems?._ProjectItems?.length === 0 ? (
          <div className="empty-data">
            <EmptyDataContainer>No Archived projects.</EmptyDataContainer>
          </div>
        ) : (
          <ArchivedProjectsList data={data?.list_ProjectItems._ProjectItems} removeArchive={handleUnArchive} />
        )}
      </ProjectsContainer>
    </>
  );
}

export default ProjectArchivalPage;
