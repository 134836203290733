import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './BlockIdComponent';

export default Node.create({
  name: 'blockIdComponent',

  // group: 'block',

  group: 'block*',

  atom: true,

  addAttributes() {
    return {
      blockId: {
        default: '1.0'
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'block-id-component'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['block-id-component', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  }
});
