import { gql } from '@apollo/client';

export const createRegisterMutation = gql`
  mutation createRegisterMutation(
    $createdAt: String
    $createdBy: String
    $fileFormats: [String]
    $initiatorRole: String
    $name: String
    $registerType: String
    $responderRole: String
    $projectId: String
    $workspaceId: String
    $initiatorStatusOptions: [Self_Register_initiatorStatusOptions_initiatorStatusOptionsItem_Input_]
    $responderStatusOptions: [Self_Register_responderStatusOptions_responderStatusOptionsItem_Input_]
    $responderRoleId: String
    $initiatorRoleId: String
    $organizationId:String
  ) @api(name: "register") {
    add_Register(
      syncMode: NODE_COMMITTED
      input: {
        createdAt: $createdAt
        createdBy: $createdBy
        initiatorRole: $initiatorRole
        name: $name
        registerType: $registerType
        fileFormats: $fileFormats
        responderRole: $responderRole
        projectId: $projectId
        initiatorStatusOptions: $initiatorStatusOptions
        responderStatusOptions: $responderStatusOptions
        workspaceId: $workspaceId
        responderRoleId: $responderRoleId
        initiatorRoleId: $initiatorRoleId
        organizationId: $organizationId
      }
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
export const addRegisterFileMutation = gql`
  mutation MyMutation($destinationKey: String!, $sourceBucket: String!, $sourceKey: String!, $sourceRegion: String!)
  @api(name: "library") {
    addVendia_File(
      syncMode: NODE_LEDGERED
      input: {
        sourceBucket: $sourceBucket
        sourceKey: $sourceKey
        sourceRegion: $sourceRegion
        destinationKey: $destinationKey
      }
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const updateRegisterMutation = gql`
  mutation updateRegisterMutation($id: ID!, $workspaceId: String) @api(name: "register") {
    update_Register(id: $id, syncMode: NODE_COMMITTED, input: { workspaceId: $workspaceId }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const updateFileMutation = gql`
  mutation updateFileMutation($id: ID!, $status: String, $lastSender: String, $lastAction: String, $isViewed: String)
  @api(name: "library") {
    update_File(
      id: $id
      input: { status: $status, lastSender: $lastSender, lastAction: $lastAction }
      syncMode: NODE_COMMITTED
    ) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const AddRegisterNoteMutation = gql`
  mutation updateRegisterMutation($id: ID!, $registerDescription: String) @api(name: "register") {
    update_Register(id: $id, syncMode: NODE_COMMITTED, input: { registerDescription: $registerDescription }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;

export const RemoveRegisterNoteMutation = gql`
  mutation updateRegisterMutation($id: ID!) @api(name: "register") {
    update_Register(id: $id, syncMode: NODE_COMMITTED, input: { registerDescription: "" }) {
      result {
        _id
      }
      transaction {
        _id
      }
    }
  }
`;
