import { toast } from 'react-hot-toast';
import { client } from '../apollo';
import axiosInstance from '../axiosInstance';
import { updateNoteDraftStatusMutation } from '../graphql/operations/Mutations/MeetingNotes/UpdateNoteDraftStatus';
import store from '../store';
import { MeetingNoteI, SectionI, UserDataI } from './../pages/MeetingNotes/types';

export const meetingNoteSaveAsDraft = async (meetingNoteData: MeetingNoteI, sections: SectionI[], noteId: string, metaData: string, otherFields?: string) => {
  const body: any = {
    organizationId: meetingNoteData.organizationId,
    workspaceId: meetingNoteData.workspaceId,
    projectId: meetingNoteData.projectId,
    title: meetingNoteData.title,
    content: sections,
    metaData: metaData ? metaData : 'meta-data',
    noteId: noteId
  };

  if (otherFields) body['otherFields'] = otherFields;

  try {
    const res = await axiosInstance.post(`/meetingNotes/`, body);
    // toast.success('Notes saved successfully!', { id: toastId });
  } catch (error) {
    console.log(error);
    throw error;
  }
}


export const handlePublishNote = async (meetingNoteData: MeetingNoteI, selectedMembers: any, selectedPermission: any, selectedAttendees: any) => {
  const usersData: UserDataI[] = [];
  const toastId = toast.loading('Publishing note ...');

  selectedMembers.map((members: any) => {
    if (members.id) {
      usersData.push({ userId: members.id, access: selectedPermission!.value, memberType: 'SHARED' });
    } else {
      usersData.push({ emailId: members.value, access: selectedPermission!.value, memberType: 'SHARED' });
    }
  });
  selectedAttendees.current.map((members: any) => {
    usersData.push({ userId: members.id, access: members.permission.value, memberType: 'ATTENDEE' });
  });
  try {
    const data = await axiosInstance.post(`/meetingNotes/${meetingNoteData.id}`, {
      organizationId: meetingNoteData.organizationId,
      workspaceId: meetingNoteData.workspaceId,
      projectId: meetingNoteData.projectId,
      usersData: usersData
    });

    //call graphql mutation function
    const query = await client.mutate({
      mutation: updateNoteDraftStatusMutation,
      variables: { isDraft: false, id: meetingNoteData.id }
    });

    toast.success('Note published successfully!', { id: toastId });

  } catch (error) {
    console.log(error);
    toast.error('Something went wrong', { id: toastId });
  }
};


//Auto save
export const handleMeetingNoteAutoSave = async (ws: WebSocket, authToken: string, masterKey?: string | undefined, blockContent?: string | undefined) => {
  const { meetingNote } = store.getState();

  const body: any = {
    organizationId: meetingNote.meetingNoteData.organizationId,
    workspaceId: meetingNote.meetingNoteData.workspaceId,
    projectId: meetingNote.meetingNoteData.projectId,
    title: meetingNote.meetingNoteData.title,
    content: meetingNote.sections,
    metaData: JSON.stringify(meetingNote.metaData),
    noteId: meetingNote.meetingNoteData._id
  };

  if (meetingNote?.otherFields.length !== 0) body['otherFields'] = JSON.stringify(meetingNote.otherFields);

  const data: any = {
    type: 'UPDATE_MEETING_NOTE_CONTENT',
    noteId: meetingNote.meetingNoteData._id,
    data: body,
    token: authToken,
  }

  if (blockContent) {
    data["blockContent"] = JSON.stringify(blockContent)
  }

  if (masterKey) {
    data['masterKey'] = masterKey
  }
  ws.send(JSON.stringify(data))

}
