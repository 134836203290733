import { Label } from '../../styles/Label';
import { Input } from '../../styles/Inputs';
import { ErrorText } from './TextError';

type CustomInputProps = JSX.IntrinsicElements['input'] & {
  label?: string;
  name: string;
  id?: string;
  type?: string;
  touched: boolean | undefined;
  errors: string | undefined;
};

// type ownProps = FieldInputProps<""> & CustomInputProps;

export const InputField = ({ label, type, name, touched, errors, ...props }: CustomInputProps): JSX.Element => {
  return (
    <>
      <Label htmlFor={props.id || name}>{label}</Label>
      <Input id={name} type={type} name={name} {...props} error={touched && errors} />
      {touched && errors ? (
        // <ErrorMessage component={TextError} name={name} />
        <ErrorText>{errors}</ErrorText>
      ) : null}
    </>
  );
};
