import { Header, HeaderLeft, NewTitleLeft, PageTitle } from '../../../../components/styles/Header';
import styled from '@emotion/styled';
import Button, { CustomButton } from '../../../../components/CustomButton';

import ViewCollectionFileList from '../../../../components/Files/ViewCollectionFileList';
import { ReactComponent as FileIcon } from '../../../../assets/icons/NewFileIcon.svg';

import Modal from '../../../../components/Modal';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../../components/styles/ModalStyles';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { OptionItem, ToggleSettings } from '../../../../components/OptionsMenu';
import { ErrorPage } from '../../../Others/ErrorPage';
import Loader from '../../../../components/Loader';
import useGetCollectionFileItems from '../../../../hooks/features/library/useGetCollectionFileItems';
const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;

  .ellispsis_icon {
    text-decoration: none;
  }

  .popover__title {
    font-size: 1.5rem;
    line-height: 36px;
    text-decoration: none;
    color: #93979f;
    text-align: center;
  }

  .popover__wrapper {
    position: relative;
    // margin-top: 1.5rem;
    display: inline-block;
    // width: 1rem;
  }
  .popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    margin-top: 1rem;
    left: -50px;
    transform: translate(0, 10px);
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    width: auto;
  }
  .popover__content:before {
    position: absolute;
    z-index: -1;
    content: '';
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 10px 10px 10px 10px;
    border-color: #ffffff;
    transition-duration: 0.3s;
    transition-property: transform;
    font-size: 20px;
  }
  .popover__wrapper:hover .popover__content {
    z-index: 999;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__button {
    text-align: center;
    color: #929292;
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-weight: 600;
`;
const ModalText = styled(Text)`
  font-weight: 400;
  text-align: center;
`;

const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;

function ViewCollection() {
  const {
    documentData,
    loading,
    error,
    CollectionData,
    addFiles,
    editFiles,
    isDeleting,
    isOpen,
    removeCollection,
    toggle,
    collectionError,
    collectionLoading,
    handleNavigate
  } = useGetCollectionFileItems();
  if (loading || collectionLoading) return <Loader />;
  if (error || collectionError) return <ErrorPage />;
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={handleNavigate} />
          <NewTitleLeft>
            <PageTitle> {CollectionData?.get_Collection?.name} </PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Button disabled={isDeleting} onClick={addFiles}>
            Add/Remove Files
          </Button>
          <ToggleSettings>
            <OptionItem onClick={editFiles}>Edit Collection</OptionItem>
            <OptionItem onClick={() => toggle()}>Delete Collection</OptionItem>
          </ToggleSettings>
        </TitleRight>
      </Header>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Delete Collection</ModalTitle>
          <ModalBody>
            <ModalText>Do you want to delete this Collection?</ModalText>
          </ModalBody>
          <ButtonWrapper>
            <Button disabled={isDeleting} onClick={toggle} secondary>
              Cancel
            </Button>
            <Button disabled={isDeleting} onClick={() => removeCollection()}>
              Confirm
            </Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
      <div>
        {documentData ? (
          <ViewCollectionFileList
            data={documentData?.list_FileItems?._FileItems}
            CollectionData={CollectionData?.get_Collection?.files}
            icon={<FileIcon />}
          />
        ) : null}
      </div>
    </>
  );
}

export default ViewCollection;
