import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import axiosInstance from '../../axiosInstance';
import { FormikHelpers, Formik } from 'formik';
import toast from 'react-hot-toast';
import { Storage } from 'aws-amplify';
import profilePlaceholder from '../../assets/icons/profilePlaceholder.svg';
import { Label } from '../../components/styles/Label';
import { InputWrapper } from '../../components/styles/FormStyles';
import { InputField } from '../../components/Reusable/NewFormik/Input';
import Button from '../../components/CustomButton';
import { AppContext } from '../../AppContext';
import ProfileUpdateValidation from '../Validations/ProfileUpdateValidation';
import SelectCountryCodeField from '../Reusable/NewFormik/SelectCountryCodeField';
import { PhoneInputField } from '../Reusable/NewFormik/PhoneInput';
import Compress from 'browser-image-compression';
import Loader from '../Loader';
import { sentryError } from '../../lib/SentryError';
// import { v4 as uuidv4 } from "uuid";
// import { FilesFilterStyles } from "../styles/FilesFilterStyles";
// import IconOrg from "../../assets/icons/IconOrg.svg";
// import IconMail from "../../assets/icons/IconMail.svg";
// import IconPhone from "../../assets/icons/IconPhone.svg";
const ProfileCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  color: #1c1c1c;
  .image {
    position: relative;
    img {
      height: 18vw;
      width: 18vw;
      object-fit: cover;
      border-radius: 10px;
    }
    .options {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      backdrop-filter: blur(10px);
      transition: all 0.5s ease;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        background: #dbe2e0;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        color: #26303e;
        li {
          padding: 5px 10px;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
          & ~ li {
            border-top: solid 1px #999;
          }
        }
      }
    }
    &:hover {
      .options {
        opacity: 1;
      }
    }
  }
  .form {
    margin: 30px auto 0;
    width: 90%;
    .formRow {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      label {
        color: #1c1c1c;
      }
      input {
        font-size: 16px;
        width: 100%;
        padding: 7px;
        background: #f6f5f5;
        height: 43px;
        border: 1px solid #757575;
        box-sizing: border-box;
        border-radius: 5px;
      }
    }
  }
`;
const PhoneNumberInputWrapper = styled.div`
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
const CustomStyle = {
  control: (base) => ({
    ...base,
    width: '100px',
    height: '43px',
    marginTop: '10px',
    boxShadow: 'none'
  }),
  valueContainer: (style) => ({
    height: '43px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (styles: any, { isFocused }: any) => ({
    ...styles,
    color: isFocused ? 'white' : '#1C1C1C',
    background: isFocused ? '#2F3F48' : 'white',
    height: '30px'
  })
};

const Form = styled.form``;

interface InitialValues {
  firstname: string;
  lastname: string;
  picture: any;
  designation: string;
  phone: string;
  countryCode: string;
}

interface Props {
  editProfile: any;
  setEditProfile: any;
  formikRef: any;
}

const EditProfile: React.FC<Props> = ({ editProfile, setEditProfile, formikRef }) => {
  const { state, dispatch } = useContext(AppContext);

  const [fileObj, setFileObj] = useState('');
  const countryCodeLen = state?.countryCode?.length;
  const initialValues: InitialValues = {
    firstname: state?.firstName,
    lastname: state?.lastName,
    picture: state?.profilePhoto,
    phone: state?.phone?.slice(countryCodeLen),
    countryCode: state?.countryCode,
    designation: state?.designation || ''
  };
  const hiddenFileInput = React.useRef<any>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSubmit = async (values: InitialValues, { setSubmitting, resetForm }: FormikHelpers<InitialValues>) => {
    const phoneNumber = values.phone.replace(/[^0-9]/gi, '');
    const toastId = toast.loading('Updating...');
    try {
      state?.profilePhoto !== values.picture
        ? await Storage.put(state?.userName, fileObj ? fileObj : values.picture, {
            contentType: fileObj ? fileObj.type : values.picture.type,
            bucket: process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME,
            level: 'public'
          })
            .then((data) => {
              if (data.key) {
                toast.success('PROFILE UPDATED SUCCESSFULY', {
                  id: toastId
                });
                setSubmitting(true);
                // resetForm()
              }
            })
            .then((resp) => {
              return axiosInstance.patch(`/users/${state?.userId}`, {
                fullName: values.firstname + ' ' + values.lastname,
                first_name: values.firstname,
                last_name: values.lastname,
                phoneNumber: values.countryCode + phoneNumber,
                countryCode: values.countryCode,
                designation: values.designation,
                profilePhoto: `https://${process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${state?.userName}`
              });
            })
            .then((res) => {
              dispatch({
                type: 'UPDATE_USER',
                data: {
                  profilePhoto: values.picture,
                  fullName: values.firstname + ' ' + values.lastname,
                  firstName: values.firstname,
                  lastName: values.lastname,
                  phone: values.countryCode + phoneNumber,
                  countryCode: values.countryCode,
                  designation: values.designation
                }
              });
              setEditProfile(!editProfile);
              // setOpenProfile(true);
            })
        : await axiosInstance
            .patch(`/users/${state?.userId}`, {
              fullName: values.firstname + ' ' + values.lastname,
              first_name: values.firstname,
              last_name: values.lastname,
              phoneNumber: values.countryCode + phoneNumber,
              designation: values.designation,
              countryCode: values.countryCode
              // profilePhoto: `https://${process.env.REACT_APP_S3_USERPROFILE_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/public/${state?.userName}`
            })
            .then((res) => {
              toast.success('PROFILE UPDATED SUCCESSFULY', { id: toastId });
              dispatch({
                type: 'UPDATE_USER',
                data: {
                  profilePhoto: values.picture,
                  fullName: values.firstname + ' ' + values.lastname,
                  firstName: values.firstname,
                  lastName: values.lastname,
                  phone: values.countryCode + phoneNumber,
                  countryCode: values.countryCode,
                  designation: values.designation
                }
              });
              setEditProfile(!editProfile);
              // setOpenProfile(true);
            });
    } catch (err) {
      toast.error('Unable to update please try again later');
      sentryError(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={ProfileUpdateValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <ProfileCard>
                <div className="image">
                  <img src={values.picture ? values.picture : profilePlaceholder} alt="Profile" loading="lazy" />
                  <div className="options">
                    {values.picture ? (
                      <ul>
                        <li onClick={handleFile}>Change Photo</li>
                        <input
                          type="file"
                          name="picture"
                          id="picture"
                          style={{ display: 'none' }}
                          ref={hiddenFileInput}
                          accept="image/png, image/jpeg"
                          onChange={(event) => {
                            const image = event.currentTarget.files[0];
                            // Compression config
                            if (image?.size < 6000000) {
                              const toastId = image && toast.loading('Uploading Image');
                              const options = {
                                // As the key specify the maximum size
                                // Leave blank for infinity
                                maxWidthOrHeight: 2000,
                                maxSizeMB: 0.2,
                                initialQuality: 0.6,
                                // Use webworker for faster compression with
                                // the help of threads
                                useWebWorker: true
                              };
                              // Initialize compression
                              // First argument is the file object from the input
                              // Second argument is the options object with the config
                              Compress(image, options).then((compressedBlob) => {
                                toast.success('Uploaded', { id: toastId });
                                setFieldValue('picture', URL.createObjectURL(compressedBlob));
                                setFileObj(compressedBlob);
                              });
                            } else {
                              image && toast.error('Image size is too large');
                            }
                          }}
                        />
                        {/* <li>Remove Photo</li> */}
                      </ul>
                    ) : (
                      <ul>
                        <li onClick={handleFile}>Add Photo</li>
                        <input
                          type="file"
                          name="picture"
                          id="picture"
                          style={{ display: 'none' }}
                          ref={hiddenFileInput}
                          accept="image/png, image/jpeg"
                          onChange={(event) => {
                            const image = event.currentTarget.files[0];
                            if (image?.size < 6000000) {
                              const toastId = image && toast.loading('Uploading Image');
                              const options = {
                                // As the key specify the maximum size
                                // Leave blank for infinity
                                maxWidthOrHeight: 2000,
                                maxSizeMB: 0.2,
                                initialQuality: 0.6,
                                // Use webworker for faster compression with
                                // the help of threads
                                useWebWorker: true
                              };
                              // Initialize compression
                              // First argument is the file object from the input
                              // Second argument is the options object with the config
                              Compress(image, options).then((compressedBlob) => {
                                toast.success('Uploaded', { id: toastId });
                                setFieldValue('picture', URL.createObjectURL(compressedBlob));
                                setFileObj(compressedBlob);
                              });
                            } else {
                              image && toast.error('Image size is too large');
                            }
                          }}
                        />
                      </ul>
                    )}
                  </div>
                </div>
                <div className="form">
                  <div className="formRow">
                    <InputWrapper>
                      <InputField
                        name="firstname"
                        type="text"
                        placeholder="Add your First Name"
                        label="First Name"
                        disabled={isSubmitting}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputField
                        name="lastname"
                        type="text"
                        placeholder="Add your Last Name"
                        label="Last Name"
                        disabled={isSubmitting}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputField
                        name="designation"
                        type="text"
                        placeholder="Add your Title"
                        label="Title"
                        disabled={isSubmitting}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label htmlFor="countryCode">Phone Number</Label>
                      <PhoneNumberInputWrapper>
                        <SelectCountryCodeField styles={CustomStyle} name="countryCode" id="countryCode" />
                        <PhoneInputField style={{ width: '50%' }} placeholder="xxx-xxx-xxxx" name="phone" id="phone" />
                      </PhoneNumberInputWrapper>
                    </InputWrapper>
                  </div>
                </div>
                <Button type="submit">Save</Button>
              </ProfileCard>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditProfile;
