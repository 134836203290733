import React from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
// import { ReactComponent as NotebookIcon } from "../../assets/icons/notebook.svg";

const ListItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid var(--table-border);
  margin-left: 2rem;
  margin-right: 2rem;
  /* width: 70rem; */
`;

function ProjectWorkspaceItem(props: any) {
  let navigate = useNavigate();
  const viewWorkspace = () => {
    navigate(`/project/workspace/${props?.projectid}/${props?.item?._id}/notebooks`);
  };

  return <ListItemStyles onClick={viewWorkspace}>{props.children}</ListItemStyles>;
}

export default ProjectWorkspaceItem;
