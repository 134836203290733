import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { ARCHIVED_WORKSPACE_MUTATION } from '../../../graphql/operations/Mutations/Workspaces/WorkspaceMutations';
import { LIST_ARCHIVED_WORKSPACES_QUERY } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';

function useArchivedWorkspaces(projectId: string) {
  let navigate = useNavigate();
  const { data, error, loading } = useQuery(LIST_ARCHIVED_WORKSPACES_QUERY, {
    variables: {
      projectId: projectId
    }
  });

  const [unarchiveWorkspace] = useMutation(ARCHIVED_WORKSPACE_MUTATION);

  const handleUnArchive = async (workspaceId: string) => {
    const toastId = toast.loading('UnArchiving');
    try {
      let response = await unarchiveWorkspace({
        variables: {
          id: workspaceId,
          recordStatus: 0
        }
      });
      if (response) {
        toast.dismiss(toastId);
        toast.success('Workspace Un-archived');
        navigate(-1);
      }
    } catch (error) {
      console.error('Error in un-archival of workspace', error);
      toast.dismiss(toastId);
      toast.error('Error in un-archiving workspace.');
    }
  };

  return {
    data,
    error,
    loading,
    handleUnArchive
  };
}

export default useArchivedWorkspaces;
