import styled from '@emotion/styled';
import Alert from '../assets/icons/IconTaskAlert.svg';
import RegisterBadge from '../assets/icons/RegisterBadge.svg';
import TaskBadge from '../assets/icons/NotificationTaskIcon.svg';
import WorkspaceBadge from '../assets/icons/NotificationWorkspaceIcon.svg';
import DiscussionBadge from '../assets/icons/discussionIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from '../axiosInstance';
import { notificationOnClick } from '../reducers/notification';
import { getTime } from '../lib/getTime';
import { getDate } from '../lib/getDate';

//Badge on the notification
const badgesMap: Record<string, string> = {
  TASK: TaskBadge,
  WORKSPACE: WorkspaceBadge,
  REGISTER: RegisterBadge,
  DISCUSSION: DiscussionBadge
};

interface NotificationData {
  projectId: string;
  entityType: string;
  actionByProfilePic: string;
  actionBy: string;
  actionByName: string;
  entityId: string;
  createdAt: string;
  message: string;
  workspaceId: string;
  isActive: boolean;
  isRead: boolean;
  date: string;
  userId: string;
  organizationId: string;
  updatedAt: string;
  actionType: string;
  actionId: string;
}

const NotificationsBox = styled.div`
  display: flex;
  flex-direction: column;

  .notification {
    display: flex;
    padding: 15px 21px;
    border-bottom: solid 1px #f6f5f5;
    align-items: flex-start;
    &:hover {
      background: #f6f5f5;
    }

    .statusIndicator {
      background: var(--button-background);
      height: 8px;
      width: 9px;
      border-radius: 50%;
      padding: 2px;
      margin-left: 5px;
    }
    .avatarContainer {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      img {
        height: 40px;
        width: 40px;
      }
      .avatar {
        object-fit: cover;
        border-radius: 50%;
      }
      span {
        height: 40px;
        width: 40px;
        background: #ff6b00;
        border-radius: 50%;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
        }
      }
      .registerBadge {
        background: #ffdb5a;
        margin-left: -15px;
        margin-top: 25px;
        height: 15px;
        width: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 13px;
          width: 10px;
        }
      }
      .taskBadge {
        margin-left: -15px;
        margin-top: 25px;
        height: 15px;
        width: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .workspaceBadge {
        margin-left: -15px;
        margin-top: 25px;
        height: 15px;
        width: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .notificationDetails {
      margin-left: 21px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;
      flex-wrap: wrap;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        color: var(--text-light);

        .blackBold {
          color: #1c1c1c;
          font-weight: 600;
        }
        .todo {
          color: var(--button-background);
          text-transform: capitalize;
        }
        .inProgress {
          color: var(--medium-priority);
          text-transform: capitalize;
        }
        .done {
          text-transform: capitalize;
        }
        .no {
          //no due
          color: #1c1c1c;
          font-weight: 600;
        }
        .yes {
          color: var(--high-priority);
          font-weight: 600;
        }
      }

      .bottomBox {
        display: flex;
        flex-direction: row;
        margin-top: 7px;
        color: var(--text-light);
        font-size: 12px;
        line-height: 16px;
        span:nth-of-type(2) {
          margin-left: 11px;
        }
      }
    }
  }
`;

export const Notifications = ({ closeFunction }: { closeFunction: () => void }) => {
  //@ts-ignore
  const NotificationData: NotificationData[] = useSelector((state) => state.notifications.notificationData);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNotificationClick = async (url: string, data: NotificationData, index: number) => {
    closeFunction();
    navigate(url);
    if (data.isActive) {
      let result = await axiosInstance.post(`/notification/read`, {
        ...data
      });
      if (result) {
        dispatch(notificationOnClick(index));
      }
    }
  };

  const getUrl = (notificationData: NotificationData): string => {
    switch (notificationData.entityType) {
      case 'TASK':
        return `/dashboard/tasks/${notificationData.entityId}`;
      case 'ORGANIZATION':
        if (notificationData.actionType === 'add_project') return `/project/${notificationData.projectId}`;
        return `/organization/${notificationData.entityId}`;
      case 'WORKSPACE':
        if (notificationData.actionType === 'meetingNoteShared') return `/meetingnotes/${notificationData.actionId}`;
        return `/project/workspace/${notificationData.projectId}/${notificationData.workspaceId}`;
      case 'DISCUSSION':
        return `/dashboard/tasks/${notificationData.entityId}/discussions`;
      case 'REGISTER':
        return `/project/workspace/${notificationData.projectId}/${notificationData.workspaceId}/${notificationData.entityId}/documentlist`;
      default:
        return '';
    }
  };

  return (
    <NotificationsBox>
      {NotificationData.map((data, index) => {
        if (data.isActive) {
          const date = getDate(data.createdAt);
          const time = getTime(data.createdAt);

          return (
            <div
              className="notification"
              key={index}
              onClick={() => handleNotificationClick(data.actionType !== 'delete' ? getUrl(data) : '', data, index)}
            >
              <>
                <div className="avatarContainer">
                  {data.actionByProfilePic && data.actionByName ? (
                    <img className="avatar" src={data.actionByProfilePic} alt="avatar" />
                  ) : (
                    <span>
                      <img src={Alert} alt="Alert" />
                    </span>
                  )}
                  {data.actionByProfilePic ? (
                    <div className="taskBadge">
                      {badgesMap[data.entityType] ? <img src={badgesMap[data.entityType]} alt="Badge" /> : ''}
                    </div>
                  ) : null}
                </div>
                <div className="notificationDetails">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.message
                    }}
                  ></p>
                  <div className="bottomBox">
                    <span>{date}</span>
                    <span>{time}</span>
                  </div>
                </div>
                {data?.isRead !== true ? <div className="statusIndicator" /> : ''}
              </>
            </div>
          );
        }
      })}
    </NotificationsBox>
  );
};
