import { useField } from "formik";
import { ReactElement } from "react";
import Select, { Option, CommonProps } from "react-select";
// import { countries } from "../../../constants/CountryCodeDropdownData";

// Make the name required
type Props = CommonProps & {
  name: string;
};

export default function SelectCountryCodeField(props: Props): ReactElement {
  const { name } = props;
  const [field, , helpers] = useField<string>(name);

  const options = [
    {
      value: "+1",
      label: "US (+1)",
    },
  ];
  //Todo change options to country
  return (
    <>
      <Select
        {...field}
        {...props}
        options={options}
        name={field.name}
        value={
          options ? options.find((option) => option.value === field.value) : ""
        }
        onChange={(option: Option): void => helpers.setValue(option.value)}
        onBlur={field.onBlur}
      />
    </>
  );
}
