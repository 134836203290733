import { gql, useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import axiosInstance from '../../../axiosInstance';
import Button from '../../../components/CustomButton';
import { AssigneeSeparator, AssigneeWrapper, AvatarStyles, InputWrapper } from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Input } from '../../../components/styles/Inputs';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import { ErrorText } from '../../../components/styles/Text';
import Table from '../../../components/Table';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { COLUMNS } from '../../../constants/RegisterColumnData';
import { createRegisterMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';
import { createWorkspaceMutation } from '../../../graphql/operations/Mutations/Workspaces/WorkspaceMutations';
import { getTemplateQuery } from '../../../graphql/operations/Queries/Workspaces/TemplateQueries';
import { getDate } from '../../../lib/getDate';
import { getTime } from '../../../lib/getTime';
import { Container } from '../../Projects/ProjectViews/CreateProject';
import { RegisterContainer, WorkspaceData, WorkspaceNameStyles } from '../WorkspaceViews/CreateNewWorkspace';
import { RegisterHeader, RegisterList } from '../WorkspaceViews/WorkspaceConfiguration';
// import { string } from 'prop-types'
// import _ from 'lodash'
// import { object } from 'yup'

const generateQuery = (entityMappings: string[]) => {
  let emptyQuery = ``;
  if (entityMappings?.length > 0) {
    for (const [index, value] of entityMappings.entries()) {
      emptyQuery += `register_${index}: get_Register(id: "${value}") {
            _id
            name
            projectId
            registerType
            createdAt
            createdBy
            initiatorRole
            responderRole
            fileFormats
            workspaceId
            initiatorStatusOptions {
              status
              statusType
            }
            responderStatusOptions {
              status
              statusType
            }
          }\n`;
    }
  } else {
    emptyQuery = `get_Register(id: "null") {
              _id
              name
              projectId
              registerType
              createdAt
              createdBy           
            
        }\n`;
  }
  return gql`
      query getRegistersQuery @api(name: "register"){
          ${emptyQuery}
      }
    `;
};

// const generateRegisterMutation = (registerObject) => {
//   if (!_.isEmpty(registerObject)) {
//     let emptyQuery = ``;
//     let registerArray = Object.values(registerObject)
//     if (registerArray?.length > 0) {
//       for (const [index, value] of registerArray.entries()) {
//         emptyQuery += `register${index}: add_Register_async(input: ${{ ...value }}) {
//         result {
//           _id
//         }
//       }\n`;
//       }
//     } else {
//       emptyQuery = `add_Register_async(input: null) {
//       result {
//         _id
//       }
//     }\n`;
//     }
//     return gql`
//       mutation addRegisterBatchMutation {
//           ${emptyQuery}
//         }
//     `;
//   } else {
//     let emptyQuery = `add_Register_async(input: null) {
//       result {
//         _id
//       }
//     }\n`;
//     return gql`
//     mutation addRegisterBatchMutation {
//       ${emptyQuery}
//     }`;
//   }
// };

function ViewTemplate() {
  let navigate = useNavigate();
  let { projectId, templateId } = useParams();
  const { state } = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [registerIds, setRegisterIds] = useState<string[]>([]);
  const { data: templateData, loading } = useQuery(getTemplateQuery, {
    variables: {
      id: templateId
    },
    onCompleted: () => {
      let data = JSON.parse(templateData.get_Template.otherFields);
      let registerIds = data?.registerIds;
      setRegisterIds(registerIds);
    }
  });

  const batchGetRegisters = generateQuery(registerIds);
  const {
    data: registerData,
    loading: registersLoading,
    refetch
  } = useQuery(batchGetRegisters, {
    variables: {
      projectId
    },
    fetchPolicy: 'network-only'
  });

  // useEffect(() => {
  // }, [registerData])
  // const batchMutations = generateRegisterMutation(registerData)
  const [createRegister] = useMutation(createRegisterMutation);
  const [createWorkspace] = useMutation(createWorkspaceMutation);

  useEffect(() => {
    if (registerIds.length > 0) {
      refetch();
    }
  }, [registerIds]);

  const columns = useMemo(() => [...COLUMNS], [registerData]);

  const savedRegisters = useMemo(() => (registerData ? Object.values(registerData) : null), [registerData]);

  const initialState: WorkspaceData = {
    workspaceName: templateData?.get_Template.templateName
  };

  const createNewWorkspace = async (values) => {
    setIsSubmitting(true);
    let toastId = toast.loading('Creating the workspace');
    try {
      let createWorkspaceResponse = await createWorkspace({
        variables: {
          name: values.workspaceName,
          createdAt: new Date().toISOString(),
          createdBy: state.userId,
          projectId: projectId
        }
      });
      if (createWorkspaceResponse?.data) {
        let workspaceId = createWorkspaceResponse.data.add_Workspace.result._id;
        let registers = Object.values(registerData);
        let responses = await Promise.all(
          registers.map(async (register) => {
            let registerResult = await createRegister({
              variables: {
                createdAt: new Date().toISOString(),
                createdBy: state.userId,
                fileFormats: [],
                name: register.name,
                registerType: register.registerType,
                initiatorRole: register.initiatorRole,
                responderRole: register.responderRole,
                projectId: projectId,
                initiatorStatusOptions: register.initiatorStatusOptions,
                responderStatusOptions: register.responderStatusOptions,
                workspaceId: workspaceId,
                organizationId: state.orgId
              }
            });
            return registerResult;
          })
        );
        let dynamicRegisters = registers
          .filter((register) => register.registerType === 'dynamic')
          .reduce((acc, register) => {
            return acc.concat([register.initiatorRole, register.responderRole]);
          }, []);
        if (dynamicRegisters.length > 0) {
          let uniqueRoles = Array.from(new Set(dynamicRegisters));
          let requestArray = [];
          for (const [index, value] of uniqueRoles.entries()) {
            requestArray.push(
              axiosInstance.post('/roles', { entityType: 'WORKSPACE', roleName: value, entityId: workspaceId })
            );
          }
          axios
            .all(requestArray)
            .then(
              axios.spread((...responses) => {
                toast.dismiss(toastId);
                toast.success('Workspace Successfully created from template.');
                navigate(`/newprojects/workspaceconfig/${projectId}/addmembers/${workspaceId}`);
              })
            )
            .catch((error) => {
              toast.dismiss(toastId);
              toast.error('Something went wrong in creating roles from the template. Please Try Again');
            });
        } else if (dynamicRegisters.length === 0) {
          toast.dismiss(toastId);
          toast.success('Workspace Successfully created from template.');
          navigate(`/newprojects/workspaceconfig/${projectId}/addmembers/${workspaceId}`);
        }
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error('Something went wrong in creating the workspace. Please try again.');
      console.error('ERROR IN CREATING TEMPLATE', error);
    } finally {
      setIsSubmitting(false);
      toast.dismiss(toastId);
    }
  };
  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: createNewWorkspace
  });
  if (loading || registersLoading) {
    return <Lottie options={defaultOptions} width={400} height={500} />;
  }
  if (registerData) {
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Header>
          <HeaderLeft>
            <BackIcon />
            <NewTitleLeft>
              <PageTitle>Add Workspace</PageTitle>
            </NewTitleLeft>
          </HeaderLeft>
          <TitleRight>
            <Button disabled={isSubmitting} type="submit">
              Next
            </Button>
          </TitleRight>
        </Header>
        <Container>
          <WorkspaceNameStyles>
            <InputWrapper>
              <Label>Workspace Name</Label>
              <Input
                name="workspaceName"
                type="text"
                value={formik.values.workspaceName}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
              ></Input>
              {formik.touched['workspaceName'] && formik.errors['workspaceName'] ? (
                <ErrorText>{formik.errors['workspaceName']}</ErrorText>
              ) : null}
            </InputWrapper>
            <AssigneeWrapper>
              <Label>Created By</Label>
              <AssigneeSeparator>
                <AvatarStyles>
                  <CustomAvatar name={state.fullName} size={21} />
                </AvatarStyles>
                <Small>
                  {getDate()} {getTime()}
                </Small>
              </AssigneeSeparator>
            </AssigneeWrapper>
          </WorkspaceNameStyles>
          <RegisterContainer>
            <RegisterHeader>
              <PageTitle>Registers</PageTitle>
            </RegisterHeader>
            <RegisterList>
              {savedRegisters?.length > 0 ? savedRegisters && <Table columns={columns} data={savedRegisters} /> : null}
            </RegisterList>
          </RegisterContainer>
        </Container>
      </form>
    </>
  );
}

export default ViewTemplate;
