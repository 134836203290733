import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import Button, { CustomButton } from '../../../../components/CustomButton';
import CustomAvatar from '../../../../components/TableComponents/CustomAvatar';
import FileTag from '../../../../components/FileTag';
import toast from 'react-hot-toast';
import FileValidationSchema from '../../../../components/Validations/CreateFileValidation';
import TextError from '../../../../components/Reusable/NewFormik/TextError';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import Modal from '../../../../components/Modal';
import Select from 'react-select';
import CreateNewCollectionInAFileSchema from '../../../../components/Validations/CreateNewCollectionInAFileValidation';
import { FormikHelpers, ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { TextAreaField } from '../../../../components/Reusable/NewFormik/Textarea';
import { DateInputField } from '../../../../components/Reusable/NewFormik/DateInput';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { AppContext } from '../../../../AppContext';
import { useNavigate } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { Label } from '../../../../components/styles/Label';
import { Input } from '../../../../components/styles/Inputs';
import {
  LinkWrapper,
  FieldSet,
  InputWrapper,
  DateWrapper,
  AssigneeWrapper,
  AssigneeSeparator,
  AvatarStyles,
  FileUploadWrapper,
  FilePreview
} from '../../../../components/styles/FormStyles';
import { Small } from '../../../../components/styles/SmallText';
import { TaskDetails } from '../../../Tasks/TaskViews/CreateTask';
import { getFileType } from '../../../../lib/getFileType';
import { InputField } from '../../../../components/Reusable/NewFormik/Input';
import { GreyButton } from '../../../../components/styles/ButtonStyles';
import { CollectionDropDownStyles } from '../../../../components/styles/CollectionDropDownStyles';
import { useMutation, useQuery } from '@apollo/client';
import { LIST_COLLECTION_QUERY } from '../../../../graphql/operations/Queries/Library/FilesQueries';
import { ReactComponent as FileUploadIcon } from '../../../../assets/icons/fileupload.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/CloseIcon.svg';
import { useToggle } from '../../../../lib/UseToggle';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../../components/styles/ModalStyles';
import { addCollectionMutation } from '../../../../graphql/operations/Mutations/Library/FileMutations';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import { getDate } from '../../../../lib/getDate';
import { getTime } from '../../../../lib/getTime';
import { formatBytes } from '../../../../lib/getFileSize';

const Form = styled.form``;

export const AddNewField = styled.div`
  ${CustomButton} {
    color: #26303e;
    background: #ffffff;
    height: 1.8rem;
    font-size: 0.9rem;
    font-weight: 600;
    margin-left: 1rem;
    border: 1px solid #dbe2e0;
    :hover {
      background: #dbe2e0;
    }
  }
`;

export const LinkInputWrapper = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  .link-input {
    flex: 1 !important;
  }
  button {
    margin-left: 10px;
  }
  svg {
    vertical-align: middle;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
  }
`;

export const CustomWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;
  .custom-input {
    flex: 1 !important;
  }
  svg {
    vertical-align: middle;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
  }
`;

interface InitialValues {
  documentName: string;
  file: any;
  description: string;
  linkedFiles: string[];
  createdBy: string;
  collections: { id: number; collectionName: string }[];
  createdAt: string | Date;
  customFieldsLabels: string[];
  customInputs: string[];
  labelText: string;
}
interface CollectionValues {
  collectionName: string;
}

// interface CustomFieldValues {
//   label: string;
// }

function CreateNewFile() {
  let navigate = useNavigate();
  let selectRef = React.useRef<HTMLInputElement>(null);
  const [data, setData] = useState([]);
  const [isOpen, toggle] = useToggle(false);
  const [isOpen2, toggle2] = useToggle(false); //this is for the second Modal
  const [submitting, setIsSubmitting] = useState(false);
  const { state } = useContext(AppContext);
  const userId = state.userId;
  const {
    data: listData,
    error,
    loading
    // refetch,
  } = useQuery(LIST_COLLECTION_QUERY, {
    variables: {
      operation: 'query',
      type: 'library',
      libraryType: 'notesAndCollections',
      typeId: userId
    },
    fetchPolicy: 'network-only'
  });
  const [
    addCollection
    // {
    //   data: collectionData,
    //   error: collectionError,
    //   loading: collectionLoading,
    // },
  ] = useMutation(addCollectionMutation, {
    // refetchQueries: [{ query: LIST_COLLECTION_QUERY }],
    // update: (cache, mutationResult) => {
    //   // const newCollectionData = mutationResult;
    //   const data: any = cache.readQuery({
    //     query: LIST_COLLECTION_QUERY, variables: { type: 'library', typeId: userId }
    //   });
    //   cache.writeQuery({
    //     query: LIST_COLLECTION_QUERY,
    //     variables: { type: 'library', typeId: userId },
    //     data: { Collections: [...data.listCollections.Collections, { id: '234567', value: 'plane' }] }
    //   })
    // }
  });

  useEffect(() => {
    if (!loading) {
      setData(listData?.list_CollectionItems?._CollectionItems);
    }
  }, [loading]);

  const handleNavigate = () => {
    navigate('/Files');
  };

  const clearValue = () => {
    selectRef?.select?.clearValue();
  };

  const initialCollectionValues: CollectionValues = {
    collectionName: ''
  };

  const initialValues: InitialValues = {
    file: '',
    documentName: '',
    description: '',
    createdBy: state.fullName,
    collections: [],
    linkedFiles: [],
    createdAt: new Date().toISOString().split('T')[0],
    customFieldsLabels: [],
    customInputs: [],
    labelText: ''
  };

  // const customFieldValues: CustomFieldValues = {
  //   label: "",
  // };

  // const removeLabel = (index: number) => {
  // };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
    // console.log(hiddenFileInput, hiddenFileInput.current, 'hiddenFileInput');
  };
  const doNothing = () => {};

  const handleFocus = () => {
    // refetch()
  };

  const handleAddNewField = async (data, setFieldValue) => {
    setFieldValue('customInputs', [...data.customInputs, { label: data.labelText, value: '' }]);
    setFieldValue('labelText', '');
    toggle2();
  };
  // const handleAddNewField = async (
  //   values: CustomFieldValues,
  //   { resetForm }: FormikHelpers<CustomFieldValues>
  // ) => {
  //   setCustomFieldLabels([...customFieldLabels, values.label]);
  //   toggle2();
  // };

  const handleAddCollection = async (values: CollectionValues, { resetForm }: FormikHelpers<CollectionValues>) => {
    const toastId = toast.loading('Creating Collection');
    try {
      let formData = {
        name: values.collectionName,
        type: 'library',
        typeId: userId,
        projectId: 'NOPROJECT',
        createdAt: new Date().toISOString(),
        createdBy: state.username,
        libraryType: 'notesAndCollections'
      };
      let response = await addCollection({
        variables: formData
      });
      if (response.data) {
        formData._id = response.data.add_Collection.result._id;
        formData.files = null;
        let newarr = [];
        if (data && data?.length > 0) {
          newarr = [...data, formData];
        } else {
          newarr = [formData];
        }
        setData(newarr);

        // if (data.length > 0) {
        //   setData([formData, ...data]);
        // } else if (data.length === 0) {
        //   setData([formData])
        // }
        toast.success('COLLECTION CREATED', {
          id: toastId
        });
        // await refetch()
        toggle();
      }
    } catch (error) {
      console.error('ERROR OCCURED IN ADDING NEW COLLECTION', error);
      toast.dismiss(toastId);
      toast.error('ERROR CREATING COLLECTION');
    }
  };

  const handleSubmit = async (values: InitialValues, { setSubmitting, resetForm }: FormikHelpers<InitialValues>) => {
    setIsSubmitting(true);
    const toastId = toast.loading('Uploading...');
    let id = uuidv4();
    let size = (values.file.size / 1000).toString();
    if (Number(size) > 1000000) {
      setIsSubmitting(false);
      toast.error(`Upload Failed! File size should be less than 1GB`, { id: toastId });
    } else {
      // console.log(values.file.name, 'mesaage');
      // console.log(values.file.size.toString(), 'foile');
      try {
        return await Storage.put(id, values.file, {
          contentType: values.file.type,
          level: 'public',
          metadata: {
            documentName: values?.documentName,
            description: values?.description,
            createdByName: values?.createdBy,
            createdById: userId,
            createdAt: new Date().toISOString(),
            collections: JSON.stringify(values?.collections),
            linkedFiles: JSON.stringify(values?.linkedFiles),
            fileType: values?.file.type,
            fileName: values.file.name,
            size,
            fields: JSON.stringify(values.customInputs),
            projectId: 'NOPROJECT',
            type: 'library',
            updatedAt: new Date(values.createdAt).toISOString(),
            updatedBy: userId,
            typeId: userId,
            isDraft: 'false',
            userId: state.userId
          },
          progressCallback(progress: { loaded: number; total: number }) {
            // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            let percentage = parseInt((progress.loaded / progress.total) * 100);
            toast.loading(`${percentage}% uploaded`, { id: toastId });
            // setUploadProgress(calculatePercent(progress.loaded, progress.total));
          },
          errorCallback: (err: any) => {
            console.error('Unexpected error while uploading', err);
            toast.error(`upload failed please try again later`, { id: toastId });
            // setResumableUploadButtonDisabled(true);
          }
        }).then((data) => {
          if (data.key) {
            toast.success('Upload Completed Redirecting', {
              id: toastId
            });
            resetForm();
            clearValue();
            setIsSubmitting(false);
            setTimeout(() => {
              navigate('/files');
            }, 10000);
          }
        });
      } catch (err) {
        console.error('ERROR IN UPLOADING', err);
        toast.dismiss(toastId);
        toast.error('Error Uploading File.');
        setIsSubmitting(false);
      }
    }
  };
  const SaveDraft = async (values: any) => {
    if (values.documentName === '') {
      toast.error('Please provide a document name to your draft.');
      return;
    }
    setIsSubmitting(true);
    const toastId = toast.loading('Saving...');
    let id = uuidv4();
    let size = (values.file.size / 1000).toString();
    try {
      await Storage.put(id, values.file, {
        contentType: values.file.type,
        level: 'public',
        metadata: {
          documentName: values?.documentName,
          description: values?.description,
          createdByName: values?.createdBy,
          collections: JSON.stringify(values?.collections),
          linkedFiles: JSON.stringify(values?.linkedFiles),
          createdAt: new Date().toISOString(),
          createdById: userId,
          fileName: values.file.name,
          fileType: values?.file.type,
          fields: '',
          projectId: 'NOPROJECT',
          type: 'library',
          updatedAt: new Date().toISOString(),
          updatedBy: userId,
          typeId: userId,
          isDraft: 'true',
          size,
          userId: state.userId
        }
      }).then((data) => {
        if (data.key) {
          toast.success('SAVED TO DRAFT SUCCESSFULY', {
            id: toastId
          });
          setIsSubmitting(false);
          setTimeout(() => {
            navigate('/files');
          }, 10000);
        }
      });
    } catch (err) {
      console.error('ERROR IN UPLOADING', err);
      toast.dismiss(toastId);
      toast.error('Error in saving draft.');
      setIsSubmitting(false);
    }
  };
  if (error) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={FileValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue, handleChange, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Header>
                <HeaderLeft>
                  <BackIcon handlenavigate={handleNavigate} />
                  <NewTitleLeft>
                    <PageTitle>New Document</PageTitle>
                  </NewTitleLeft>
                </HeaderLeft>
                <TitleRight>
                  <Button disabled={submitting} secondary={true} onClick={() => SaveDraft(values)}>
                    Save as Draft
                  </Button>
                  <Button type="submit" disabled={isSubmitting || submitting}>
                    Save File
                  </Button>
                </TitleRight>
              </Header>
              <TaskDetails>
                <FieldSet>
                  <div>
                    <InputWrapper>
                      <Label>Document Name</Label>
                      <InputField name="documentName" type="text" disabled={isSubmitting || submitting} />
                    </InputWrapper>
                    <FileUploadWrapper>
                      {values.file ? (
                        <FilePreview>
                          <FileTag>{getFileType(values.file.name)?.toUpperCase()}</FileTag>
                          <span className="file">{values.file.name}</span>
                          <span className="file">{formatBytes(values.file.size)}</span>
                          <GreyButton
                            disabled={isSubmitting || submitting}
                            type="button"
                            onClick={() => setFieldValue('file', '')}
                          >
                            Remove File <CloseIcon />
                          </GreyButton>
                        </FilePreview>
                      ) : (
                        <>
                          <GreyButton type="button" onClick={handleFile}>
                            <FileUploadIcon /> Upload File
                          </GreyButton>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            style={{ display: 'none' }}
                            ref={hiddenFileInput}
                            onChange={(event) => {
                              setFieldValue('file', event.currentTarget.files[0]);
                            }}
                          />
                          {touched['file'] && errors['file'] ? <p style={{ color: 'red' }}>{errors['file']}</p> : null}
                        </>
                      )}
                    </FileUploadWrapper>
                    <InputWrapper>
                      <TextAreaField
                        disabled={isSubmitting || submitting}
                        label="Description"
                        name="description"
                        id="description"
                      />
                    </InputWrapper>
                    <LinkWrapper>
                      <Label>Links</Label>
                      <FieldArray name="linkedFiles">
                        {({ insert, remove, push }) => (
                          <>
                            {values.linkedFiles.length > 0 &&
                              values.linkedFiles.map((link, index) => {
                                return (
                                  <>
                                    <Field name={`linkedFiles.${index}`}>
                                      {({ field, form, meta }) => (
                                        <>
                                          <LinkInputWrapper>
                                            <Input
                                              className="link-input"
                                              disabled={isSubmitting || submitting}
                                              type="text"
                                              {...field}
                                              error={meta.touched && meta.error}
                                            />
                                            <CloseIcon
                                              onClick={() => (!isSubmitting ? remove(index) : doNothing())}
                                              height="25px"
                                              width="25px"
                                            />
                                          </LinkInputWrapper>
                                          {meta.touched && meta.error && (
                                            <ErrorMessage component={TextError} name={`linkedFiles.${index}`} />
                                          )}
                                        </>
                                      )}
                                    </Field>
                                  </>
                                );
                              })}
                            <div>
                              <Button disabled={isSubmitting || submitting} onClick={() => push('')}>
                                + Add Link
                              </Button>
                            </div>
                          </>
                        )}
                      </FieldArray>
                    </LinkWrapper>
                    <InputWrapper>
                      <Label htmlFor="collection">Collection</Label>
                      <Select
                        name="collections"
                        isMulti={true}
                        ref={(ref) => {
                          selectRef = ref; //this ref is for clearing the values of dropdown after submitting.
                        }}
                        menuPortalTarget={document.body}
                        closeMenuOnSelect={false}
                        isDisabled={isSubmitting || submitting}
                        styles={CollectionDropDownStyles}
                        options={data}
                        onFocus={handleFocus}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e._id}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            'collections',
                            selectedOption.map((item) => {
                              return {
                                id: item._id,
                                name: item.name,
                                files: item.files
                              };
                            })
                          );
                        }}
                      ></Select>
                      <div>
                        <GreyButton disabled={isSubmitting || submitting} onClick={() => toggle()} type="button">
                          Add New Collection
                        </GreyButton>
                      </div>
                    </InputWrapper>
                    <InputWrapper>
                      <FieldArray name="customInputs">
                        {({ remove }) => (
                          <>
                            {values.customInputs.length > 0 &&
                              values.customInputs.map((formgroup, index) => {
                                return (
                                  <Field name={`customInputs.${index}.value`}>
                                    {({ field, meta }) => (
                                      <>
                                        <Label>{formgroup.label}</Label>
                                        <CustomWrapper>
                                          <Input
                                            className="custom-input"
                                            type="text"
                                            {...field}
                                            error={meta.touched & meta.error}
                                          />
                                          <CloseIcon
                                            onClick={() => (!isSubmitting ? remove(index) : doNothing())}
                                            height="25px"
                                            width="25px"
                                          />
                                        </CustomWrapper>
                                        {meta.touched && meta.error && (
                                          <ErrorMessage component={TextError} name={`customInputs.${index}.value`} />
                                        )}
                                      </>
                                    )}
                                  </Field>
                                );
                              })}
                          </>
                        )}
                      </FieldArray>
                    </InputWrapper>
                    <AddNewField>
                      <Button disabled={isSubmitting || submitting} onClick={() => toggle2()} type="button">
                        Add New Field
                      </Button>
                    </AddNewField>
                  </div>
                  <div>
                    <DateWrapper>
                      <Label htmlFor="date">Document Date</Label>
                      <div>
                        <DateInputField
                          value={values.createdAt}
                          touched={touched.createdAt}
                          errors={errors.createdAt}
                          onChange={handleChange}
                          disabled={isSubmitting || submitting}
                          name="createdAt"
                          type="date"
                          // placeholder={values.createdAt}
                        />
                      </div>
                    </DateWrapper>
                    <AssigneeWrapper>
                      <Label>Created By</Label>
                      <AssigneeSeparator>
                        <AvatarStyles>
                          <CustomAvatar name={`${state.fullName}`} size={21} />
                        </AvatarStyles>
                        <Small>
                          {getDate()} {getTime()}
                        </Small>
                      </AssigneeSeparator>
                    </AssigneeWrapper>
                  </div>
                </FieldSet>
                <Modal open={isOpen2} onClose={toggle2}>
                  <ModalContainer width="528px">
                    <ModalTitle>New Field</ModalTitle>
                    <ModalBody>
                      <InputWrapper>
                        <Label>What do you want to name this field</Label>
                        <InputField disabled={submitting || isSubmitting} name="labelText" type="text" />
                      </InputWrapper>
                      <ModalWrapper>
                        <Button onClick={toggle2} secondary>
                          Cancel
                        </Button>
                        <Button onClick={() => handleAddNewField(values, setFieldValue)}>Confirm</Button>
                      </ModalWrapper>
                    </ModalBody>
                  </ModalContainer>
                </Modal>
              </TaskDetails>
            </Form>
          );
        }}
      </Formik>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="528px">
          <ModalTitle>Create a New Collection</ModalTitle>
          <ModalBody>
            <Formik
              initialValues={initialCollectionValues}
              onSubmit={handleAddCollection}
              validationSchema={CreateNewCollectionInAFileSchema}
            >
              {({ values, errors, handleSubmit, isSubmitting }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <InputWrapper>
                      <Label>Collection Name</Label>
                      <InputField name="collectionName" type="text" />
                    </InputWrapper>
                    <ModalWrapper>
                      <Button onClick={toggle} secondary>
                        Cancel
                      </Button>
                      <Button type="submit">Confirm</Button>
                    </ModalWrapper>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default CreateNewFile;

// update(cache, { data: { addCollection } }) {
//   cache.modify({
//     fields: {
//       collections(existingCollections = [data.listCollections?.Collections]) {
//         const newCollection = cache.writeFragment({
//           data: addCollection,
//           fragment: gql`
//             fragment NewCollection on listCollections.Collections {
//               name: values.collectionName,
//               type: 'library',
//               typeId: userId,
//               projectId: 'NOPROJECT',
//               createdAt: new Date().toISOString(),
//               createdBy: state.username
//             }
//           `
//         });
//         return [...existingCollections, newCollection]
//       }
//     }
//   })
// }

// client.writeQuery({
//   query: LIST_COLLECTION_QUERY,
//   data: {
//     Input: {
//       name: values.collectionName,
//       type: 'library',
//       typeId: userId,
//       projectId: 'NOPROJECT',
//       createdAt: new Date().toISOString(),
//       createdBy: state.username,
//       __typename: "Collection"
//     }
//   },
//   variables: {
//     id: response.data.addCollection_async.result.id
//   }
// })

// let formData = {
//   name: values.collectionName,
//   type: 'library',
//   typeId: userId,
//   projectId: 'NOPROJECT',
//   createdAt: new Date().toISOString(),
//   createdBy: state.username
// }
