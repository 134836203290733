// import React, { ReactChild } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
// import { ReactChildren } from "react";

export const ListItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid var(--table-border);  
`;

export const ListArchivedItemStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--table-border);  
  

`

function ListItem(props: any) {
  let navigate = useNavigate();
  const viewProject = () => {
    navigate(`/project/${props?.item?._id}`);
  };
  return (
    <ListItemStyles onClick={viewProject} >{props.children}</ListItemStyles>
  );
}

export const ListProjectItem = (props: any) => {
  return (
    <ListItemStyles>{props.children}</ListItemStyles>
  );
}

export const ListArchivedItem = (props: any) => {
  return (
    <>
      <ListArchivedItemStyles>{props.children}</ListArchivedItemStyles>
    </>
  )
}



export default ListItem;

  // let navigate = useNavigate();
  // const viewProject = () => {
  //   navigate(`/project/${props?.item?._id}`);
  // };