import _ from 'lodash';
import { useContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { AppContext } from '../../../../AppContext';
import axiosInstance from '../../../../axiosInstance';
import { RootState } from '../../../../store';
import { UserDataI } from '../../types';
import SearchBody from './SearchBody';
import SearchHeader from './SearchHeader';

interface Props {
  toggleSearch: () => void;
  onClick?: () => void;
}

const getChatContactsByUserName = async (searchValue: string, orgId: string) => {
  try {
    let result = await axiosInstance.get(`/conversationSearch?userName=${searchValue}&organizationId=${orgId}`);

    return { data: result.data as UserDataI[], type: 'success' };
  } catch (error) {
    return { type: 'error' };
  }
};

async function getChatContactsByEmail(emailId: string) {
  try {
    let result = await axiosInstance.get(`/conversationSearch?userEmail=${emailId}`);
    return { data: result.data, type: 'success' };
  } catch (error) {
    return { type: 'error' };
  }
}

//Get org users
function chatContactsSelector(state: RootState, userData: any) {
  const allConversations = { ...state?.conversations?.orgData, ...state?.conversations?.nonOrgData };

  /** All conversations
   *  userId -> conversationId
   *  profile -> ConversationPhoto
   *  name -> ConversationName
   */
  const conversations = Object.keys(allConversations).map((key) => {
    const conversation = allConversations[key][0];
    return {
      userId: conversation.id,
      lastMessage: conversation.latestMessage,
      name: conversation.conversationName,
      picture: conversation.profilePhoto,
      outside: conversation.sameOrg,
      conversationDetails: conversation
    };
  });

  const users = state?.orgMembers?.users
    ?.filter((item: any) => {
      return item.id !== userData.userId;
    })
    .map((item: any) => {
      return {
        userId: item.id,
        email: item.email,
        name: item?.fullName,
        picture: item?.profilePhoto,
        // conversationId: conversations[item.id]?.id,
        // message: conversations[item.id]?.message,
        outside: item?.organizationId !== userData.orgId ? true : false,
        orgId: userData.orgId
      };
    }, {});

  return { conversations, users: users ? [...users] : [] };
}

function SearchUsers({ toggleSearch, onClick }: Props) {
  const [searchText, setSearchText] = useState('');
  const [users, setUsers] = useState<UserDataI[]>([]);
  const { state } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [searchByEmail, setSearchByEmail] = useState(false);

  const toggleSearchByEmail = () => setSearchByEmail(!searchByEmail);

  // console.log('Users -> ', getChatContactsFromOrg(state.userId,state.orgMem));
  //@ts-ignore
  const chatContacts = useSelector((store) => chatContactsSelector(store, state));
  // console.log(chatContacts);

  const handleSearch = async (value: string) => {
    setLoading(true);
    if (chatContacts.conversations.length !== 0) {
      const filteredConversations = chatContacts.conversations.filter((contact) => {
        if (contact.name.toLowerCase().includes(value.trim().toLowerCase())) return contact;
        return null;
      });
      if (filteredConversations.length !== 0) {
        setLoading(false);
        return filteredConversations;
      }
    } else if (chatContacts.users.length !== 0) {
      const filteredUsers = chatContacts.users.filter((contact) => {
        if (contact.name.toLowerCase().includes(value.trim().toLowerCase())) return contact;
        return null;
      });
      if (filteredUsers.length !== 0) {
        setLoading(false);
        return filteredUsers;
      }
    }
    let data = await getChatContactsByUserName(value, state.orgId);
    setLoading(false);
    if (data?.data) return data.data;
    if (data.type === 'error') toast.error('User not found');
    return [];
  };

  const handleEmailSearch = async (value: string) => {
    setLoading(true);
    let data = await getChatContactsByEmail(value);
    if (data.data) setUsers([data.data.data]);
    else {
      toast.error('User not found');
    }
    setLoading(false);
  };

  const handleSearchChange = (value: string) => {
    if (value === '') return;
    setSearchText(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    _.debounce(async (value) => {
      setUsers(await handleSearch(value));
    }, 1000)
  ).current;

  return (
    <>
      <SearchHeader
        toggleSearch={toggleSearch}
        handleSearchChange={handleSearchChange}
        searchByEmail={searchByEmail}
        toggleSearchByEmail={toggleSearchByEmail}
        handleEmailSearch={handleEmailSearch}
        loading={loading}
      />
      <SearchBody
        users={users}
        loading={loading}
        toggleSearchByEmail={toggleSearchByEmail}
        searchByEmail={searchByEmail}
        toggleSearch={toggleSearch}
        onClick={onClick}
      />
    </>
  );
}

export default SearchUsers;
