import styled from '@emotion/styled';
import { useContext } from 'react';
import Button from '../../../components/CustomButton';
import { Formik, Form } from 'formik';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  FieldSet,
  InputWrapper
} from '../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { Label } from '../../../components/styles/Label';
import { Small } from '../../../components/styles/SmallText';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { InputField } from '../../../components/Reusable/NewFormik/Input';
import * as Yup from 'yup';
import { TextAreaField } from '../../../components/Reusable/NewFormik/Textarea';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from '../../../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import { getProjectQuery } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { updateProjectMutation } from '../../../graphql/operations/Mutations/Projects/ProjectMutations';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import toast from 'react-hot-toast';
// import { useToggle } from "../../lib/UseToggle";
// import Modal from "../../components/Modal";
// import WorkspaceTemplateTile from "../../components/WorkspaceTemplateTile";
// import { templates, savedTemplates } from "../../constants/MockTemplateOptions";
// import { ModalContainer } from "../../components/styles/ModalStyles";
// import ListItem from "../../components/ListItem";
// import toast, { Toaster } from "react-hot-toast";
// import { ReactComponent as RoundIcon } from "../../assets/icons/RoundIcon.svg";
// import { listWorkspaceQuery } from "../../graphql/operations/Queries/Workspaces/WorkspaceQueries";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.625rem;
  > * + * {
    margin: 10px;
  }
  .autoGenerateCode {
    margin-left: 1rem;
  }
`;

// const WorkspaceContainer = styled.div`
//   border-bottom: 1px solid var(--border-color);
//   display: flex;
//   justify-content: space-between;
//   margin-right: 51px;
//   padding-right: 50px;
// `;

// const WorkspaceTitle = styled.h4`
//   font-size: 1.5rem;
//   font-weight: 600;
//   margin-left: 50px;
//   margin: 0 0 14px 50px;
// `;

// const WorkspaceInfo = styled.div`
//   display: flex;
//   padding-left: 2.6rem;
//   align-items: center;
// `;
// const WorkspaceName = styled.p`
//   font-weight: 600;
//   margin-left: 2.01rem;
// `;

// const GreyButton = styled("button")`
//   font-size: 0.9rem;
//   line-height: 19px;
//   background-color: #dbe2e0;
//   color: #26303e;
//   border-radius: 5px;
//   font-family: "Open Sans";
//   border: none;
//   letter-spacing: 0.5px;
//   line-height: 21px;
//   padding: 5px 12px 5px 15px;
//   font-weight: 600;
//   cursor: pointer;
//   font-family: "Open Sans";
//   height: 1.8rem;
// `;

// const createProjectMutation = gql`
//   mutation createProjectMutation(
//     $client: String
//     $createdAt: String
//     $createdBy: String
//     $projectName: String
//     $description: String
//     $recordStatus: Float
//     $updateAt: String
//     $updatedBy: String
//   ) {
//     addProject_async(
//       input: {
//         client: $client
//         createdAt: $createdAt
//         createdBy: $createdBy
//         name: $projectName
//         projectDescription: $description
//         updateAt: $updateAt
//         updatedBy: $updatedBy
//         recordStatus: $recordStatus
//       }
//     ) {
//       error
//       result {
//         _id
//       }
//     }
//   }
// `;

interface Values {
  projectName?: string;
  client?: string;
  description?: string;
  projectCode?: string;
}

function EditProject() {
  // const navigate = useNavigate();
  let { projectId } = useParams();
  const { state } = useContext(AppContext);
  const userId = state.userId;
  // const [codeGenerator, setCodeGenerator] = useState('');
  // const [isOpen, toggle] = useToggle(false);
  // const [selectedTemplateIndex, setSelectedTemplate] = useState(0);
  // const [projectid, setProjectId] = useState("");
  // const [projectValues, setProjectValues] = useState<null | {}>(null);
  // const [workspaces, setWorkspaces] = useState([]);
  // const formRef = useRef();
  const [updateProject] = useMutation(updateProjectMutation);

  const {
    data: projectData,
    error: projectError,
    loading: projectLoading
  } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    },
    onCompleted: (projectData) => {
      // setProjectValues({
      //   projectName: projectData.get_Project.name,
      //   client: projectData.get_Project.client,
      //   description: projectData.get_Project.projectDescription,
      //   projectCode: codeGenerator,
      // });
    }
  });
  // const generateCode = () => {
  //   var code = `${Math.floor(Math.random() * 10)}${Math.floor(
  //     Math.random() * 10
  //   )}${Math.floor(Math.random() * 10)}${Math.floor(
  //     Math.random() * 10
  //   )}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`;
  //   setCodeGenerator(code);
  // };

  const initialValues = {
    projectName: projectData?.get_Project?.name,
    client: projectData?.get_Project?.client,
    description: projectData?.get_Project?.projectDescription,
    projectCode: projectData?.get_Project?.code
  };

  const onSubmit = async (values: Values) => {
    const toastId = toast.loading('Updating Project');
    try {
      let res = await updateProject({
        variables: {
          id: projectId,
          ...values,
          updatedAt: new Date().toISOString(),
          updatedBy: userId,
          notificationData: {
            projectId,
            entityId: projectId,
            actionType: 'add_project',
            // projectId: res.data.add_Project_async.result._id,
            organizationId: state.orgId
          }
        }
      });
      if (res) {
        setTimeout(() => {
          toast.success('Project Updated', {
            id: toastId
          });
          setTimeout(() => {
            window.location.href = '/projects';
          }, 3000);
        }, 5000);
      }
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const validationSchema = Yup.object({
    projectCode: Yup.string().required('Project Code is Required'),
    projectName: Yup.string().required('Project Name is Required'),
    client: Yup.string().required('Client Name is Required'),
    description: Yup.string().required('Description is Required')
  });
  if (projectId && projectLoading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (projectId && projectError) return <ErrorPage />;
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        <Form id="form1">
          <Header>
            <HeaderLeft>
              <BackIcon />
              <NewTitleLeft>
                <PageTitle>Edit Project</PageTitle>
              </NewTitleLeft>
            </HeaderLeft>
            <TitleRight>
              <Button type="submit">Update Project</Button>
            </TitleRight>
          </Header>
          <Container>
            <FieldSet>
              <div>
                <InputWrapper>
                  <InputField label="Project Name" name="projectName" id="projectName" />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label="Project Code"
                    name="projectCode"
                    id="projectCode"
                  // disabled
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField label="Client" name="client" id="client" />
                </InputWrapper>
                <InputWrapper>
                  <TextAreaField label="Description" name="description" id="description" />
                </InputWrapper>
              </div>
              <div>
                <AssigneeWrapper>
                  <Label>Created By</Label>
                  <AssigneeSeparator>
                    <AvatarStyles>
                      <CustomAvatar isTable={false} name={state.fullName} size={21} />
                    </AvatarStyles>
                    <Small>
                      {new Date(projectData?.get_Project?.createdAt).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}{' '}
                      {new Date(projectData?.get_Project?.createdAt).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </Small>
                  </AssigneeSeparator>
                </AssigneeWrapper>
              </div>
            </FieldSet>
          </Container>
        </Form>
      </Formik>
    </>
  );
}

export default EditProject;
