import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useFormik } from 'formik';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';
import { useNavigate, useParams } from 'react-router';
import { ReactComponent as EditTaskIcon } from '../../../assets/icons/editTaskIcon.svg';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import Button, { CustomButton } from '../../../components/CustomButton';
import Modal from '../../../components/Modal';
import { OptionItem, ToggleSettings } from '../../../components/OptionsMenu';
import InputField from '../../../components/Reusable/Formik/Input';
import { Header, HeaderLeft, NewTitleLeft } from '../../../components/styles/Header';
import { ModalBody, ModalContainer, ModalTitle, ModalWrapper } from '../../../components/styles/ModalStyles';
import NoteValidation from '../../../components/Validations/NoteValidation';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import {
  archiveNoteMutation,
  createTaskInNote,
  updateNoteMutation
} from '../../../graphql/operations/Mutations/Library/NoteMutations';
import { ReactComponent as EmptyTrash } from '../../../assets/illustration/EmptyNoteTrash.svg';
import { LIST_TASKS_IN_NOTES_QUERY, SINGLE_NOTE_QUERY } from '../../../graphql/operations/Queries/Library/NotesQueries';
import { useToggle } from '../../../lib/UseToggle';
import { ErrorPage } from '../../Others/ErrorPage';
import { ModalText } from '../../Workspaces/Registers/CreateNewRegister';

/**
 * TipTap imports
 */
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';

import Avatar from 'react-avatar';
import { AppContext } from '../../../AppContext';
import LiveConnectionContext from '../../../contexts/LiveConnectionContext';
import { getDate } from '../../../lib/getDate';
import Toolbar from '../../../components/Editor/Toolbar';

const Form = styled.form``;
const TitleRight = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.25rem;
  gap: 19px;
`;
export const TextEditorStyles = styled.div`
  width: 100%;
  min-height: 80vh;

  .editor-container {
    padding: 20px;
  }

  img {
    max-height: 200px;
    max-width: 500px;
  }

  .ProseMirror {
    outline: none;
    min-height: 400px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px 10px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0px;
      margin: 0px;
    }
  }

  .bubble_menu {
    padding: 5px 10px;
    margin: 2px 10px;
    border: 1px solid #93979f;
    border-radius: 5px;
    color: #93979f;

    &:hover {
      cursor: pointer;
    }
  }
`;
const ButtonWrapper = styled(ModalWrapper)`
  /* padding-bottom: 28px; */
  justify-content: center;
  ${CustomButton} {
    width: 6rem;
  }
  margin-top: 30px;
`;

export const EditorContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background: #f6f5f5;
`;

export const SideBar = styled.div`
  width: 350px;
  min-height: 483px;
  max-height: 483px;
  overflow-y: scroll;
  background: #ffffff;
  border-left: 5px solid #e6e6e6;
  border-bottom-left-radius: 15px;

  .header {
    border-bottom: 5px solid #c37f0f;
    padding: 10px;
    text-align: center;
    font-size: 16px;
  }

  .body {
    padding: 5px 10px;
  }

  .edit-task-icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .task-item {
    border: 1px solid #26303e;
    border-radius: 5px;
    width: 100%;
    margin: auto;
    padding: 10px;
    position: relative;
    margin-top: 5px;
  }

  .date {
    color: #00b292;
  }

  .assignee {
    display: flex;
    align-items: center;

    img {
      object-fit: cover;
      height: 30px;
      width: 30px;
      border-radius: 50%;
    }
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 268px);
  padding: 0;
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #1c1c1c;
    opacity: 0.5;
  }
`;

function ViewNote() {
  let { noteId } = useParams();
  let navigate = useNavigate();
  const [html, setHtml] = useState<string>('');

  const [isOpen, toggle] = useToggle(false);
  const { state } = useContext(AppContext);
  const [allTasks, setAllTasks] = useState([]);
  // @ts-ignore
  const ws: WebSocket = useContext(LiveConnectionContext);
  const timer = useRef<any>(null);

  // let input = {}
  // let content = {}
  const { data, error, loading } = useQuery(SINGLE_NOTE_QUERY, {
    variables: { id: noteId, operation: 'query' },
    onCompleted: () => {
      setHtml(data.get_NoteAndDisscussion.content);
      editor?.commands.setContent(data.get_NoteAndDisscussion.content);
    },
    fetchPolicy: 'network-only'
  });

  const { data: tasks, refetch } = useQuery(LIST_TASKS_IN_NOTES_QUERY, {
    variables: {
      organizationId_projectId_typeId: `${state.orgId}_NOPROJECT_${state.userId}`,
      noteId: noteId,
      noValidate: true
    },
    onCompleted: () => {
      setAllTasks(tasks.list_TaskItems._TaskItems);
    }
  });

  const [updateNote] = useMutation(updateNoteMutation);
  const [deleteNote] = useMutation(archiveNoteMutation);
  const [createTask] = useMutation(createTaskInNote, {
    onCompleted: () => refetch()
  });

  const deleteRecordStatus = 1; //1 means delete
  const initialState = {
    title: data?.get_NoteAndDisscussion?.title
  };

  const delaySave = (content: string) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      handleAutoUpdate(content);
    }, 2000);
  };

  const UpdateNote = async (noteData: any, setSubmitting: any, resetForm: any) => {
    const toastId = toast.loading('Updating ...');
    try {
      let response = await updateNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: noteData?.title,
          content: html,
          noValidate: true,
          id: noteId
        }
      });
      if (response) {
        toast.success('Note Updated', {
          id: toastId
        });
      }
      // refetch();
      setTimeout(() => {
        navigate('/notes');
      }, 5000);
    } catch (e) {
      toast.dismiss(toastId);
      toast.error('oh no something went wrong');
      console.error(e);
    }

    resetForm();
    setSubmitting(false);
  };

  const deleteHandler = async (id: string, recordStatus: number) => {
    try {
      toggle();
      const toastId = toast.loading('Removing ...');
      let response = await deleteNote({
        variables: {
          id,
          noValidate: true,
          libraryType: 'notesAndCollections',
          recordStatus
        }
      });
      if (response) {
        toast.success('Note is Moved to Trash, Redirecting...', {
          id: toastId
        });
      }
      setTimeout(() => {
        navigate('/notes');
      }, 5000);
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: NoteValidation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      await UpdateNote(values, setSubmitting, resetForm);
    }
  });

  const handleAutoUpdate = useCallback(
    (content: string) => {
      const body = {
        data: { content: content, _id: noteId, title: formik.values.title },
        _id: noteId,
        type: 'UPDATE_NOTE_CONTENT',
        token: state.authToken
      };
      ws.send(JSON.stringify(body));
    },
    [formik.values.title]
  );

  const CustomImage = Image.extend({
    draggable: true
  });

  const handleCreateTask = async (taskTitle: string) => {
    taskTitle = taskTitle !== '' ? taskTitle : 'Task';
    const toastId = toast.loading('Creating task');

    try {
      const variables: any = {
        assignee: state.userId,
        assigneeDetails: {
          name: state.fullName
        },
        assignedTo: state.userId,
        assignedToDetails: {
          name: state.fullName
        },
        createdAt: new Date().toISOString(),
        priority: 'medium',
        projectId: 'NOPROJECT',
        organizationId_projectId_typeId: `${state.orgId}_NOPROJECT_${state.userId}`,
        organizationId: state.orgId,
        recordStatus: 0,
        status: 'todo',
        title: taskTitle,
        noteId: noteId,
        updatedAt: new Date().toISOString(),
        updatedBy: state.userId,
        type: 'user',
        typeId: state.userId
      };

      if (state.profilePhoto) {
        variables.assigneeDetails.profilePicture = state.profilePhoto;
        variables.assignedToDetails.profilePicture = state.profilePhoto;
      }

      await createTask({ variables });

      toast.success('Task created successfully', { id: toastId });
    } catch (error) {
      toast.error('Something went wrong', { id: toastId });
    }
  };

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Heading,
      Text,
      TextStyle,
      BulletList,
      OrderedList,
      ListItem,
      Bold,
      Underline,
      Italic,
      History,
      TextAlign.configure({
        types: ['paragraph']
      }),
      Table.configure({
        resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      CustomImage.configure({
        inline: true
      }),
      Link.configure({
        openOnClick: true,
        linkOnPaste: true
      }),
      HardBreak
    ],
    content: `${html}`
  });

  editor?.on('update', ({ editor }) => {
    setHtml(editor.getHTML());
    delaySave(editor.getHTML());
  });

  useEffect(() => {
    return () => {
      editor?.off('update');
    };
  }, [editor]);

  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  if (error) {
    console.error(error);
    return <ErrorPage />;
  }
  if (data?.get_NoteAndDisscussion?.recordStatus === 1)
    return (
      <ImageWrapper>
        <EmptyTrash />
        <h2>Note is moved to trash</h2>
      </ImageWrapper>
    );
  if (data?.get_NoteAndDisscussion?.recordStatus === 9)
    return (
      <ImageWrapper>
        <EmptyTrash />
        <h2>Note is permanently deleted from Platform Suite</h2>
      </ImageWrapper>
    );

  return (
    <>
      <Form
        onSubmit={formik.handleSubmit}
        onBlur={() => {
          handleAutoUpdate(html);
        }}
      >
        <Header>
          <>
            <HeaderLeft>
              <BackIcon />
              <NewTitleLeft>
                <InputField
                  style={{
                    border: 'none',
                    fontSize: '1.5rem',
                    height: '60px'
                  }}
                  name="title"
                  type="text"
                  formik={formik}
                  disabled={formik.isSubmitting}
                  placeHolder="Note Title"
                />
              </NewTitleLeft>
            </HeaderLeft>
            <TitleRight>
              <Button type="submit">Update Note</Button>
              <ToggleSettings>
                <OptionItem onClick={() => toggle()}>Delete</OptionItem>
              </ToggleSettings>
            </TitleRight>
          </>
        </Header>
      </Form>
      <EditorContainer>
        <TextEditorStyles>
          <div className="editor-container">
            {/* Project note has similar toolbar as peronal note */}
            {editor && <Toolbar editor={editor} editorType="PROJECT_NOTE" />}
            {editor && (
              <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
                <button
                  onClick={() => {
                    const { view, state } = editor;
                    const { from, to } = view.state.selection;
                    const text = state.doc.textBetween(from, to);
                    console.log(text);
                    handleCreateTask(text);
                    return;
                  }}
                  className="bubble_menu"
                >
                  Create Task
                </button>
              </BubbleMenu>
            )}
            <EditorContent editor={editor} />
          </div>
        </TextEditorStyles>
        <SideBar>
          <div className="header">Tasks</div>
          <div className="body">
            {tasks?.list_TaskItems._TaskItems &&
              tasks?.list_TaskItems._TaskItems.length > 0 &&
              tasks?.list_TaskItems._TaskItems.map((task: any) => (
                <div className="task-item">
                  <p>{task.title}</p>
                  {task.dueDate && <p className="date">Due Date : {getDate(task.dueDate)}</p>}
                  {/* TODO: HOT-FIX */}
                  {task.assigneeDetails && (
                    <div className="assignee">
                      <strong style={{ marginRight: '15px' }}>Assignee </strong>
                      {task.assigneeDetails.profilePicture && task.assigneeDetails.profilePicture !== 'null' ? (
                        <img src={`${task.assigneeDetails.profilePicture}`} alt="profile" />
                      ) : task.assigneeDetails.name ? (
                        <div className="profile-logo " style={{ marginRight: '5px' }}>
                          <Avatar round={true} size="32" name={task.assigneeDetails.name} textSizeRatio={2} />
                        </div>
                      ) : (
                        ''
                      )}

                      {task.assigneeDetails.name}
                    </div>
                  )}
                  <EditTaskIcon
                    className="edit-task-icon"
                    onClick={() => {
                      // saveNoteAsDraft(noteId, false);
                      handleAutoUpdate(html);
                      navigate(`/meetingnotes/${noteId}/task/${task._id}`);
                    }}
                  />
                </div>
              ))}
          </div>
        </SideBar>
      </EditorContainer>
      <Modal open={isOpen} onClose={toggle}>
        <ModalContainer width="396px">
          <ModalTitle>Delete File</ModalTitle>
          <ModalBody>
            <ModalText>Do you want to delete this File?</ModalText>
          </ModalBody>
          <ButtonWrapper>
            <Button onClick={toggle} secondary>
              Cancel
            </Button>
            <Button onClick={() => deleteHandler(noteId!, deleteRecordStatus)}>Confirm</Button>
          </ButtonWrapper>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default ViewNote;
