import React from 'react';
import { useNavigate } from 'react-router';
import { LightBlueButton } from '../../components/styles/ButtonStyles';
import { ReactComponent as ErrorIllustration } from '../../assets/illustration/ErrorIllustration.svg';
import styled from '@emotion/styled';

const PageStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8d8d8d;
  font-weight: 400;
  font-size: 20px;
`;

const NoAccessPage = () => {
  const navigate = useNavigate();
  return (
    <PageStyles>
      <ErrorIllustration />
      <p> You do not have access to this meeting note. Please Contact the owner of the note.</p>
      <div>
        <LightBlueButton type="button" onClick={() => navigate('/meetingnotes')}>
          All Meeting Notes
        </LightBlueButton>
      </div>
    </PageStyles>
  );
};

export default NoAccessPage;
