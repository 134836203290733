import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { ReactComponent as WorkspaceIcon } from '../assets/icons/WorkspaceIcon.svg';
import { OptionItem, ToggleSettings } from '../components/OptionsMenu';
import capitalizeFirstLetter from '../lib/CapitaliseFirstLetter';

const WorkspaceItem = styled.div`
  display: flex;
  /* margin-bottom:12px ; */
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid black; */
  .ws-name {
    display: flex;
    align-items: center;
  }
  .icon-button {
    width: 29px;
    height: 29px;
  }
  svg {
    width: 19px;
    height: 20px;
  }
  span {
    display: inline-block;
    margin-left: 15px;
  }
`;

export const ListDetails = ({ values, row, isProjectAdmin }) => {
  let navigate = useNavigate();

  const updateMemberDetails = () => {
    navigate(`update`, {
      state: {
        username: {
          value: row.original.userId,
          label: row.original.userName
        },
        entityId: row.original.id,
        userType: row.original.userType,
        relationship: row.original.designation
      }
    });
  };

  return (
    <>
      {values.length > 0 ? (
        values.map((ws, index) => {
          return (
            <WorkspaceItem key={`${ws}${index}`}>
              <div className="ws-name">
                <WorkspaceIcon />
                <span>{ws}</span>
              </div>
              <div>
                {isProjectAdmin && (
                  <ToggleSettings style={{ top: '5px' }}>
                    <OptionItem onClick={() => updateMemberDetails()}>Update Details</OptionItem>
                  </ToggleSettings>
                )}
              </div>
            </WorkspaceItem>
          );
        })
      ) : (
        <>
          <WorkspaceItem>
            <div className="ws-name">Not yet added to any workspaces</div>
            <div>
              <ToggleSettings style={{ top: '5px' }}>
                <OptionItem onClick={() => updateMemberDetails()}>Update Details</OptionItem>
              </ToggleSettings>
            </div>
          </WorkspaceItem>
        </>
      )}
    </>
  );
};

// this is depreaceated or moved to another file.
// const ProjectMembersColumn = [
//   {
//     id: 'User',
//     Header: 'User',
//     width: 100,
//     accessor: 'userData.fullName'
//   },
//   {
//     id: 'Relationship',
//     Header: 'Relationship',
//     accessor: 'designation',
//     Cell: ({ cell: { value } }) => (value ? value : 'No Relationship Added'),
//     width: 100
//   },
//   {
//     Header: 'Member Type',
//     id: 'Member Type',
//     accessor: 'userType',
//     Cell: ({ cell: { value } }) => capitalizeFirstLetter(value),
//     width: 100
//   },
//   {
//     id: 'Workspaces',
//     Header: 'Workspaces',
//     accessor: 'workspaces',
//     Cell: ({ cell: { value }, row }) => <ListDetails values={value} row={row} />,
//     width: 100
//   }
// ];

export const MockData = [
  {
    id: '1',
    username: 'srikanth',
    relationship: 'CEO',
    organization: 'ACS INC',
    type: 'Admin',
    workspaces: [
      {
        name: 'workspace1-workspace1-workspace1-workspace1',
        id: 1
      },
      {
        name: 'workspace2',
        id: 2
      },
      {
        name: 'workspace3',
        id: 3
      }
    ]
  },
  {
    id: '2',
    username: 'Akash',
    relationship: 'CFO',
    organization: 'ACS INC',
    type: 'Admin',
    workspaces: [
      {
        name: 'main-workspace1',
        id: 1
      },
      {
        name: 'main-workspace2',
        id: 2
      },
      {
        name: 'main-workspace3',
        id: 3
      }
    ]
  },
  {
    id: '3',
    username: 'KRISHNA',
    relationship: 'CTO',
    organization: 'ACS INC',
    type: 'Admin',
    workspaces: [
      {
        name: 'Alpha-workspace1',
        id: 1
      }
      // {
      //   name: 'main-workspace2',
      //   id: 2
      // },
      // {
      //   name: 'main-workspace3',
      //   id: 3
      // }
    ]
  }
];
