import styled from '@emotion/styled';
import React, {  useMemo, useState } from 'react';
import Lottie from 'react-lottie';
import { useOutletContext, useParams } from 'react-router';
import Table from '../../../components/Table';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ReactComponent as NoMembers } from '../../../assets/illustration/noOrgChat.svg';

export const AlignedDiv = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

const Container = styled.div`
  margin-top: 10px;
  padding: 10px 30px;
`;

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || '';

const AttendeeList = () => {
  const { noteId } = useParams();
  const [loading, setLoading] = useState(false);

  const [, attendeeList] = useOutletContext<any>();

  const columns = useMemo(
    () => [
      {
        Header: 'Users',
        accessor: 'userData.fullName',
        Cell: ({ cell: { value } }: { cell: any }) => (
          <AlignedDiv className="text-wrap" style={{ display: 'flex', alignItems: 'center' }}>
            {value}
          </AlignedDiv>
        )
      },
      {
        Header: 'Access',
        accessor: 'access',
        Cell: ({ cell: { value } }) => <AlignedDiv className="text-wrap">{capitalize(value)}</AlignedDiv>
      }
    ],
    [attendeeList]
  );

  const data = useMemo(() => attendeeList, [attendeeList]);

  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;

  if (attendeeList.length === 0)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
          flexDirection: 'column'
        }}
      >
        <NoMembers />
        <p>No shared members</p>
      </div>
    );

  return (
    <Container>
      <Table columns={columns} data={data} />
    </Container>
  );
};

export default AttendeeList;
