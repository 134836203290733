export const registerTypeStyles = {
  control: (base) => ({
    ...base,
    width: "8.125rem",
    // height: '1.5rem',
    border: 0,
    boxShadow: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (styles, { data, isFocused }) => ({
    ...styles,
    color: isFocused ? "white" : "#1C1C1C",
    background: isFocused ? "#2F3F48" : "white",
    height: "30px",
  }),
  menu: (styles) => ({
    ...styles,
    position: "absolute",
    border: "1px solid #ACADAC",
    borderRadius: "5px",
    top: "-10px",
  }),
};
