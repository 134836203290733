import { BlockDataI, EditingTable, EditorI, MeetingNoteI, SectionI } from './../pages/MeetingNotes/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../axiosInstance';
import { v4 as uuidv4 } from 'uuid';
import { getDate } from '../lib/getDate';

export const createNewSection = (prefix: number): SectionI => {
  const id = uuidv4();
  const newBlock = createNewBlock(id, prefix);

  return {
    sectionId: id,
    sectionHeader: '',
    sectionStatus: 0,
    blockData: [newBlock]
  };
};

export const createNewBlock = (sectionId: string, prefix: number, blockNumber?: number): BlockDataI => {
  if (!blockNumber) blockNumber = 1;
  return {
    blockId: uuidv4(),
    blockNumber: prefix + '.' + ('0' + blockNumber).slice(-2),
    blockDate: getDate(Date().toLocaleString()),
    blockContent: '',
    blockStatus: 0,
    sectionId
  };
};

export const fetchMeetingNote = createAsyncThunk('meetingNote/fetchMeetingNote', async (noteId: String) => {
  const result = await axiosInstance.get(`/meetingNotes/${noteId}`);
  const editingStatus = await axiosInstance.get(`/notes/status?noteId=${noteId}`);
  return { data: result?.data, editingStatus: editingStatus?.data };
});

interface InitialStateI {
  loading: boolean;
  error: string;
  meetingNoteData: MeetingNoteI;
  sections: SectionI[];
  metaData: any;
  otherFields: any;
  editingTable: EditingTable;
}

const initialState: InitialStateI = {
  loading: false,
  error: '',
  meetingNoteData: {} as MeetingNoteI,
  sections: [],
  metaData: {},
  otherFields: [],
  editingTable: {} as EditingTable
};

export const meetingNoteSlice = createSlice({
  name: 'meetingNotes',
  initialState,
  reducers: {
    addBlock: (state, action) => {
      const sectionIndex = state.sections.findIndex((i) => i.sectionId === action.payload);
      const block = createNewBlock(
        action.payload,
        sectionIndex + 1,
        state.sections[sectionIndex]!.blockData.length + 1
      );
      state.sections[sectionIndex].blockData.push(block);
    },
    addSection: (state) => {
      const section = createNewSection(state.sections.length + 1);
      state.sections.push(section);
    },
    deleteBlock: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string };
      const sectionIndex = state.sections.findIndex((i) => i.sectionId === data.sectionId);
      const updatedBlocks = state.sections[sectionIndex].blockData.filter((block) => block.blockId !== data.blockId);
      state.sections[sectionIndex].blockData = updatedBlocks;
    },
    deleteSection: (state, action) => {
      state.sections = state.sections.filter((section) => section.sectionId !== action.payload);
    },
    updateBlockContent: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string; blockContent: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      const blockIndex = state?.sections[sectionIndex]?.blockData?.findIndex((x) => x.blockId === data.blockId);
      state.sections[sectionIndex].blockData[blockIndex].blockContent = data.blockContent;
    },
    updateSectionHeader: (state, action) => {
      const data = action.payload as { sectionId: string; header: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      state.sections[sectionIndex].sectionHeader = data.header;
    },
    updateLastUpdateDate: (state, action) => {
      const data = action.payload as { sectionId: string; blockId: string; newDate: string };
      const sectionIndex = state.sections.findIndex((x) => x.sectionId === data.sectionId);
      const blockIndex = state.sections[sectionIndex]?.blockData.findIndex((x) => x.blockId === data.blockId);
      state.sections[sectionIndex].blockData[blockIndex].blockDate = data.newDate;
    },
    replaceContent: (state, action) => {
      //If meeting note note loaded
      if (
        state.meetingNoteData &&
        Object.keys(state.meetingNoteData).length === 0 &&
        Object.getPrototypeOf(state.meetingNoteData) === Object.prototype
      )
        return;

      const { content, noteId, metaData } = action.payload as {
        organizationId: string;
        workspaceId: string;
        projectId: string;
        title: string;
        content: SectionI[];
        metaData: string;
        noteId: string;
      };

      if (noteId !== state.meetingNoteData._id) return;

      state.sections = content;
      state.metaData = JSON.parse(metaData);
      state.otherFields = state.metaData?.otherFields ? state.metaData.otherFields : [];
    },
    updateMetaData: (state, action) => {
      if (action.payload.noteId !== state.meetingNoteData._id) return;

      state.metaData = action.payload.data;
      state.otherFields = action.payload.data.otherFields ? action.payload.data.otherFields : [];
      state.meetingNoteData.metadata = JSON.stringify(state.metaData);
      state.meetingNoteData.otherFields = JSON.stringify(state.otherFields);
    },
    updateOtherFields: (state, action) => {
      if (action.payload.noteId !== state.meetingNoteData._id) return;
      state.otherFields = action.payload.data;
    },
    addToEditorTable: (state, action) => {
      //If meeting note note loaded
      if (
        state.meetingNoteData &&
        Object.keys(state.meetingNoteData).length === 0 &&
        Object.getPrototypeOf(state.meetingNoteData) === Object.prototype
      )
        return;

      if (action.payload.noteId !== state.meetingNoteData._id) return;

      if (!state.editingTable.hasOwnProperty(action.payload.blockId)) {
        state.editingTable[action.payload.blockId] = {
          editorDetails: action.payload.editedByData,
          editingStatus: true,
          editingBy: action.payload.editingBy
        };
      }
    },
    removeFromEditorTable: (state, action) => {
      //If meeting note note loaded
      if (
        state.meetingNoteData &&
        Object.keys(state.meetingNoteData).length === 0 &&
        Object.getPrototypeOf(state.meetingNoteData) === Object.prototype
      )
        return;

      if (action.payload.noteId !== state.meetingNoteData._id) return;

      if (state.editingTable.hasOwnProperty(action.payload.blockId)) {
        delete state.editingTable[action.payload.blockId];
      }
    },
    resetNote: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeetingNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchMeetingNote.fulfilled, (state, action) => {
        const { data, editingStatus } = action.payload;

        //Populate the editing status table
        editingStatus.map((status: { blockId: string; editingBy: string; editingByData: EditorI }) => {
          if (!state.editingTable.hasOwnProperty(status.blockId))
            state.editingTable[status.blockId] = {
              editingStatus: true,
              editorDetails: status.editingByData,
              editingBy: status.editingBy
            };
        });

        state.meetingNoteData = data;
        state.sections = data?.data;
        state.metaData = JSON.parse(data?.metadata);
        state.otherFields = state.metaData?.otherFields ? state.metaData?.otherFields : [];
        if (state.sections.length === 0) {
          const section = createNewSection(state.sections.length + 1);
          state.sections.push(section);
        }
        state.loading = false;
        state.error = '';
      })
      .addCase(fetchMeetingNote.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ? action.error.message : 'Error';
      });
  }
});

export default meetingNoteSlice;

export const {
  addBlock,
  addSection,
  deleteBlock,
  deleteSection,
  updateBlockContent,
  updateSectionHeader,
  updateLastUpdateDate,
  resetNote,
  replaceContent,
  updateMetaData,
  updateOtherFields,
  addToEditorTable,
  removeFromEditorTable
} = meetingNoteSlice.actions;
