import * as yup from 'yup';

const CreateTaskValidationSchema = yup.object().shape({
  description: yup.string().min(2, 'Description is too short.'),
  title: yup.string().min(2, 'Title is too short.').max(500, 'Title is too long.').required('Title is required.'),
  dueDate: yup.date().required('Date is required.').nullable(),
  links: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .url('Please enter correct url.')
        .required('A link cannot be empty.Please remove if link is not required.'),
      saved: yup.bool().oneOf([true], 'Please save this field before submission.')
    })
  ),
  projectId: yup.string().when('isPersonal', {
    is: false,
    then: yup.string().required('Please select a project.')
  }),
  workspaceId: yup.string().when(['isPersonal', 'projectId'], {
    is: (isPersonal, projectId) => !isPersonal && projectId,
    then: yup.string().required('Please select a workspace.')
  }),
  assignedTo: yup.string().when('isPersonal', {
    is: false,
    then: yup.string().required('Please assign a task to a member or make it a personal task.')
  })

  // assignedTo: yup.string().required("Required")
  // assignedTo: yup.object().shape({
  //     label: yup.string().required("Required"),
  //     value: yup.string().required("Required")
  // })
});

export default CreateTaskValidationSchema;
