
import styled from "@emotion/styled";


const CommentCardContainer = styled.div`
background: #F7F7F7;
padding: 16px;
display: flex;
flex-direction: column;
/* width: 80%; */
border-radius: 5px;
margin-top: 9px;

/* h3{
    margin :0
} */
 p{

 }
`

const CommentText = styled.p`
    margin: 0;
    padding: 10px;
`
export const CommentCard = (props: { comment: String }) => {
    // let comment = JSON.parse(props.comment)
    return (
        <CommentCardContainer>
            <CommentText>{props.comment}</CommentText>
        </CommentCardContainer>
    )
}
