import styled from '@emotion/styled';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import Button from '../../../../components/CustomButton';
import FileTag from '../../../../components/FileTag';
import { TextAreaField } from '../../../../components/Reusable/NewFormik/Textarea';
import { GreyButton } from '../../../../components/styles/ButtonStyles';
import {
  DateWrapper,
  ErrorWrapper,
  FilePreview,
  FileUploadWrapper,
  InputWrapper
} from '../../../../components/styles/FormStyles';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../../components/styles/Header';
import { Label } from '../../../../components/styles/Label';
import { getFileType } from '../../../../lib/getFileType';
import { Container } from '../../../Projects/ProjectViews/CreateProject';
import { ReactComponent as FileUploadIcon } from '../../../../assets/icons/fileupload.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/CloseIcon.svg';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router';
import {
  GET_REGISTER_QUERY,
  GET_SINGLE_FILE_REGISTER_QUERY
} from '../../../../graphql/operations/Queries/Registers/RegisterQueries';
import { useMutation, useQuery } from '@apollo/client';
import { AppContext } from '../../../../AppContext';
import toast from 'react-hot-toast';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../../Others/ErrorPage';
import BackIcon from '../../../../assets/SvgComponents/BackIcon';
import { SendResponsesValidationSchema } from '../../../../components/Validations/SendResponsesValidationSchema';
import { ErrorText } from '../../../../components/styles/Text';
import {
  addResponseFile
  // addResponseMutation
} from '../../../../graphql/operations/Mutations/Registers/ResponseMutations';
import { Paragraph } from './AboutRegisterDocument';
import { DateInputField } from '../../../../components/Reusable/NewFormik/DateInput';
import axiosInstance from '../../../../axiosInstance';
import InputField from '../../../../components/Reusable/NewFormik/Input';
// import { addTaskMutation } from '../../../../graphql/operations/Mutations/Tasks/TaskMutations';
// import { getWorkspaceQuery } from '../../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
// import { getProjectQuery } from '../../../../graphql/operations/Queries/Projects/ProjectQueries';
// import { updateFileMutation } from '../../../../graphql/operations/Mutations/Registers/RegisterMutations';
// import { RegisterTypeOptions } from "../../../../constants/MockRespondOptions";
// import LiveConnectionContext from "../../../../contexts/LiveConnectionContext";
// import { registerTypeStyles } from "../../../../components/styles/RegisterTypeStyles";

const Form = styled.form``;
const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  .file-upload {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    margin-left: 10px;
  }
`;

interface RespondValues {
  documentStatus: string;
  file: any;
  comment: string;
  description: string;
  dueDate: string;
  subject: string;
  itemStatus: string;
}

export const dropdownStyles = {
  control: (base, state) => {
    return {
      ...base,
      minWidth: '300px',
      maxWidth: '50vw',
      width: `${100 + state.selectProps?.value?.status.length * 6}px`
      // whiteSpace: "wrap",

      // width: state.selectProps.width
      // whiteSpace: "wrap",
      // width: "max-content",
      // minWidth: "100%",
      // height: '1.5rem',
      // border: "5px solid red",
      // boxShadow: 'none'
    };
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (styles, { data, isFocused }) => ({
    ...styles,
    color: isFocused ? 'white' : '#1C1C1C',
    background: isFocused ? '#2F3F48' : 'white',
    whiteSpace: 'wrap',
    lineHeight: '25px'
  }),
  menu: (styles, state) => ({
    ...styles,
    minWidth: '500px',
    width: `${100 + state.selectProps?.value?.status.length * 6}px`,
    // width: "408px",
    // width: "max-content",
    // minWidth: "100%",
    position: 'absolute',
    border: '1px solid #ACADAC',
    borderRadius: '5px'
  })
};

function SendResponse() {
  const { state } = useContext(AppContext);
  const { registerId, fileId } = useParams();
  let navigate = useNavigate();
  // const ws = useContext(LiveConnectionContext)
  const [usersWithRoles, setUsersWithRoles] = useState({});
  const { data, error, loading } = useQuery(GET_SINGLE_FILE_REGISTER_QUERY, {
    variables: { id: fileId }
  });
  const { data: registerData } = useQuery(GET_REGISTER_QUERY, {
    variables: { id: registerId, operation: 'query' } //have to include workspaceId and type. //have workspaceId in route.
  });
  const [
    addResponseFileMutation
    // { data: addResponseData, error: addResponseError },
  ] = useMutation(addResponseFile);

  useEffect(() => {
    axiosInstance
      .get(`/rolesMapping?entityType=WORKSPACE&entityId=${registerData?.get_Register?.workspaceId}`)
      .then((response) => {
        if (response.data.data.length > 0) {
          let results = response.data.data.map((item) => {
            return { user: item.user, role: item.role };
          });
          setUsersWithRoles(results);
        }
      });
  }, [registerData?.get_Register?.workspaceId]);
  //fuction to get name of user
  const getUserWithRole = (users: any, role: any) => {
    let user = users.find((user: any) => {
      return user.role.id === role;
    });
    if (user) {
      return user.user.fullName;
    } else {
      return 'NO MEMBER ASSIGNED';
    }
  };
  //function to get id oth the user
  const getUserIdWithRoles = (users: any, role: any) => {
    let user = users.find((user: any) => {
      return user.role.id === role;
    });
    if (user) {
      return user.user.id;
    } else {
      return 'NO MEMBER ASSIGNED';
    }
  };
  const responseValues: RespondValues = {
    documentStatus: '',
    comment: '',
    file: '',
    description: '',
    dueDate: '',
    subject: '',
    itemStatus: ''
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  // const getEmailIdWithUserId = (userId) => {
  //   let data = usersWithRoles.find((user: any) => {
  //     return user.user.id === userId;
  //   });
  //   if (data) {
  //     return data.user.email;
  //   } else {
  //     return 'NO MEMBER Found';
  //   }
  // };
  const handleSubmit = async (values: RespondValues) => {
    let id = uuidv4();
    const toastId = toast.loading('Sending Response');
    let initator = getUserWithRole(usersWithRoles, registerData?.get_Register?.initiatorRoleId);
    let responder = getUserWithRole(usersWithRoles, registerData?.get_Register?.responderRoleId);
    let responderId = getUserIdWithRoles(usersWithRoles, registerData?.get_Register?.responderRoleId);
    let initatorId = getUserIdWithRoles(usersWithRoles, registerData?.get_Register?.initiatorRoleId);
    let assignedToName = state.userId === initatorId ? responder : initator;
    let assignedTo = state.userId === initatorId ? responderId : initatorId;

    try {
      let size = (values.file.size / 1000).toString();
      if (Number(size) > 1000000) {
        toast.error(`Upload Failed! File size should be less than 1GB`, { id: toastId });
      } else {
        await Storage.put(id, values.file, {
          contentType: values.file.type,
          metadata: {
            operation: 'proxy',
            organizationId: state.orgId,
            entityType: 'WORKSPACE',
            entityId: registerData?.get_Register?.workspaceId,
            userId: state.userId
          },
          level: 'public'
        });

        let object = {
          action: values.documentStatus,
          comment: values.comment,
          registerId: registerId,
          respondedAt: new Date().toISOString(),
          respondedBy: state.fullName,
          responseFileName: values.file.name,
          subject: values.subject,
          dueDate: values.dueDate
        };

        let newArray = [...data.get_File.responses];
        newArray.push(object);
        const upload = await addResponseFileMutation({
          variables: {
            destinationKey: uuidv4(),
            sourceBucket: process.env.REACT_APP_S3_BUCKET_NAME,
            sourceKey: `public/${id}`,
            sourceRegion: process.env.REACT_APP_S3_REGION,
            isDraft: 'false',
            fileName: values.file.name,
            comment: values.comment,
            documentStatus: values.documentStatus,
            type: 'response',
            dueDate: values.dueDate,
            id: fileId,
            responses: newArray,
            status: values.itemStatus,
            lastAction: values.documentStatus,
            assignedTo,
            assignedToName,
            host: window.location.href,
            registerId,
            registerName: registerData?.get_Register?.name,
            libraryType: 'response',
            workspaceId: registerData?.get_Register?.workspaceId,
            projectId: registerData?.get_Register?.projectId,
            organizationId: state.orgId,
            documentName: values.subject,
            itemName: data.get_File.name
          }
        });

        if (upload) {
        }
        setTimeout(() => {
          toast.success('Response Sent', {
            id: toastId
          });
          navigate(-1);
        }, 7000);
      }
    } catch (err) {
      console.error('ERROR in File Upload TRY AGAIN', err);
      toast.dismiss(toastId);
      toast.error('OOPS Something went wrong. Try Again');
    }
  };
  if (error) return <ErrorPage />;
  if (loading) return <Lottie options={defaultOptions} width={400} height={500} />;
  let options =
    data.get_File.responses.length === 0
      ? registerData?.get_Register?.responderStatusOptions
      : state.fullName === data.get_File.initiator
      ? registerData?.get_Register?.initiatorStatusOptions
      : registerData?.get_Register?.responderStatusOptions;
  return (
    <>
      <Formik
        initialValues={responseValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={SendResponsesValidationSchema}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Header>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <PageTitle>Send Response</PageTitle>
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <Button type="submit">Save</Button>
              </TitleRight>
            </Header>
            <Container>
              <div>
                <WrapperDiv>
                  <div>
                    <InputWrapper>
                      <Label>Update Status</Label>
                      <Select
                        name="documentStatus"
                        styles={dropdownStyles}
                        menuPortalTarget={document.body}
                        options={options}
                        getOptionLabel={(e) => e.status}
                        getOptionValue={(e) => e.status}
                        onChange={(selectedOption) => {
                          setFieldValue('documentStatus', selectedOption.status);
                          setFieldValue('description', selectedOption.description);
                          setFieldValue('itemStatus', selectedOption.statusType);
                        }}
                      />
                    </InputWrapper>
                  </div>
                  <div className="file-upload">
                    <Label>Attach Your Response File</Label>
                    <FileUploadWrapper>
                      {values.file ? (
                        <FilePreview>
                          <FileTag>{getFileType(values.file.name)?.toUpperCase()}</FileTag>
                          <span className="file">{values.file.name}</span>
                          <GreyButton type="button" onClick={() => setFieldValue('file', '')}>
                            Remove File <CloseIcon />
                          </GreyButton>
                        </FilePreview>
                      ) : (
                        <>
                          <GreyButton type="button" onClick={handleFile}>
                            <FileUploadIcon /> Upload File
                          </GreyButton>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            style={{ display: 'none' }}
                            ref={hiddenFileInput}
                            onChange={(event) => {
                              setFieldValue('file', event.currentTarget.files[0]);
                            }}
                          />
                        </>
                      )}
                    </FileUploadWrapper>
                  </div>
                </WrapperDiv>
                <ErrorWrapper>
                  {touched['documentStatus'] && errors['documentStatus'] ? (
                    <ErrorText>{errors['documentStatus']}</ErrorText>
                  ) : null}
                  {touched['file'] && errors['file'] ? <p style={{ color: 'red' }}>{errors['file']}</p> : null}
                </ErrorWrapper>
                <DateWrapper>
                  <Label htmlFor="date">Due Date</Label>
                  <div>
                    <DateInputField
                      value={values.dueDate}
                      touched={touched.dueDate}
                      errors={errors.dueDate}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      name="dueDate"
                      type="date"
                    />
                  </div>
                </DateWrapper>
                <InputWrapper>
                  {values.description && (
                    <>
                      <Label>Status Description</Label>
                      <Paragraph>{values.description}</Paragraph>
                    </>
                  )}
                </InputWrapper>
                <InputWrapper>
                  <InputField style={{ width: '650px' }} label="Subject" name="subject" />
                </InputWrapper>
                <InputWrapper>
                  <TextAreaField name="comment" id="comment" label="Comment" />
                </InputWrapper>
              </div>
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default SendResponse;
