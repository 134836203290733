export const errorStyles = {
  duration: 5000,
  containerStyle: {},
  style: {
    background: "#d14747",
    color: "#ffffff",
  },
  iconTheme: {
    primary: "#ffffff",
    secondary: "#d14747",
  },
};

export const SuccessStyles = {
  duration: 5000,
  style: {
    background: "var(--button-background)",
    color: "#ffffff",
  },
  iconTheme: {
    primary: "#ffffff",
    secondary: "var(--button-background)",
  },
};
