import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';
import Button from '../../../components/CustomButton';
import FileTag from '../../../components/FileTag';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../../components/styles/Header';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { getProjectQuery } from '../../../graphql/operations/Queries/Projects/ProjectQueries';
import { TaskDetails } from '../../Tasks/TaskViews/CreateTask';
import {
  AssigneeSeparator,
  AssigneeWrapper,
  AvatarStyles,
  CalendarIconPlacer,
  ChoicesWrapper,
  ClientSeparator,
  ClientWrapper,
  DateWrapper,
  FieldSet,
  FilePreview,
  FileUploadWrapper,
  InputWrapper,
  LinkWrapper
} from '../../../components/styles/FormStyles';
import { InputField } from '../../../components/Reusable/NewFormik/Input';
import { TextAreaField } from '../../../components/Reusable/NewFormik/Textarea';
import { getFileType } from '../../../lib/getFileType';
import { GreyButton } from '../../../components/styles/ButtonStyles';
import { Label } from '../../../components/styles/Label';
import { documenetStatusOptions } from '../../../constants/DocumetStatusOptions';
import { registerTypeStyles } from '../../../components/styles/RegisterTypeStyles';
import { CreateNewDocumentInRegisterSchema } from '../../../components/Validations/CreateNewDocumentInRegisterValidation';
import { ReactComponent as FileUploadIcon } from '../../../assets/icons/fileupload.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/CloseIcon.svg';
import { Input } from '../../../components/styles/Inputs';
import TextError from '../../../components/Reusable/NewFormik/TextError';
import CustomAvatar from '../../../components/TableComponents/CustomAvatar';
import { Small } from '../../../components/styles/SmallText';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import axiosInstance from '../../../axiosInstance';
import { AppContext } from '../../../AppContext';
import Lottie from 'react-lottie';
import { defaultOptions } from '../../../constants/LoaderDefaultOptions';
import { ErrorPage } from '../../Others/ErrorPage';
import BackIcon from '../../../assets/SvgComponents/BackIcon';
import { LinkInputWrapper } from '../../Library/FilesView/Files/CreateNewFile';
import toast from 'react-hot-toast';
import { getWorkspaceQuery } from '../../../graphql/operations/Queries/Workspaces/WorkspaceQueries';
import { getRegisterQuery } from '../../../graphql/operations/Queries/Registers/RegisterQueries';
import { DateInputField } from '../../../components/Reusable/NewFormik/DateInput';
import { addTaskMutation } from '../../../graphql/operations/Mutations/Tasks/TaskMutations';
import { addRegisterFileMutation } from '../../../graphql/operations/Mutations/Registers/RegisterMutations';
// import { date } from "yup/lib/locale";
// import { errorStyles, SuccessStyles } from "../../../constants/ToasterOptions";
// import axios from 'axios';
// import InputComponent from '../../components/Reusable/NewFormik/Input';
// import { defaultOptions } from "../../constants/LoaderDefaultOptions";
const Form = styled.form``;
export const DateText = styled.span`
  display: inline-block;
  margin-right: 10px;
`;

interface DocumentValues {
  documentName: string;
  file: any;
  description: string;
  documentStatus: string;
  links: string[];
  dueDate: string;
}

function CreateNewDocumentInRegister() {
  const navigate = useNavigate();
  const { projectId, workspaceId, registerId } = useParams();
  const [usersWithRoles, setUsersWithRoles] = useState({});
  const [allowedToUpload, setAllowedToUpload] = useState(false);
  const { state } = useContext(AppContext);
  const { loading, error, data } = useQuery(getProjectQuery, {
    variables: {
      id: projectId
    }
  });
  const {
    data: workspaceData,
    error: workspaceError,
    loading: workspaceLoading
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: workspaceId
    }
  });
  const {
    data: registerData,
    error: registerDataError,
    loading: registerLoading
  } = useQuery(getRegisterQuery, {
    variables: {
      id: registerId
    }
  });

  const doNothing = () => {};

  const getUserWithRole = (users: any, role: any) => {
    let user = users.find((user: any) => {
      return user.role.id === role;
    });
    if (user) {
      return user.user.fullName;
    } else {
      return 'NO MEMBER ASSIGNED';
    }
  };

  const getUserIdWithRoles = (users: any, role: any) => {
    let user = users.find((user: any) => {
      return user.role.id === role;
    });
    if (user) {
      return user.user.id;
    } else {
      return 'NO MEMBER ASSIGNED';
    }
  };

  useEffect(() => {
    axiosInstance.get(`/rolesMapping?entityType=WORKSPACE&entityId=${workspaceId}`).then((response) => {
      if (response.data.data.length > 0) {
        let results = response.data.data.map((item) => {
          return { user: item.user, role: item.role };
        });
        console.log('RESULTS', results);
        setUsersWithRoles(results);
      }
    });
  }, []);
  const getEmailIdWithUserId = (userId) => {
    let data = usersWithRoles.find((user: any) => {
      return user.user.id === userId;
    });
    if (data) {
      return data.user.email;
    } else {
      return 'NO MEMBER Found';
    }
  };
  useEffect(() => {
    if (usersWithRoles.length > 0 && registerData) {
      let user = usersWithRoles.find((user: any) => {
        return user.role.roleName === registerData?.get_Register.initiatorRole;
      });
      if (user) {
        setAllowedToUpload(true);
      } else {
        toast.error('You are not allowed to upload the document in the register');
      }
    }
  }, [usersWithRoles, registerData]);
  const documentValues: DocumentValues = {
    documentName: '',
    file: '',
    description: '',
    documentStatus: 'open',
    links: [''],
    dueDate: ''
  };

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleFile = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const [addRegisterFile, { error: addRegisterFileError }] = useMutation(addRegisterFileMutation, {
    onCompleted: async (data) => {},
    fetchPolicy: 'no-cache'
  });
  const handleSubmit = async (values: DocumentValues) => {
    let id = uuidv4();
    let toastId = toast.loading('Uploading ...');
    try {
      let responderId = getUserIdWithRoles(usersWithRoles, registerData?.get_Register?.responderRoleId);
      let initiatorId = getUserIdWithRoles(usersWithRoles, registerData?.get_Register?.initiatorRoleId);
      let assignedTo = state.userId === initiatorId ? responderId : initiatorId;
      let variables = {
        destinationKey: uuidv4(),
        sourceBucket: process.env.REACT_APP_S3_BUCKET_NAME,
        sourceKey: `public/${id}`,
        sourceRegion: process.env.REACT_APP_S3_REGION,
        collections: JSON.stringify([]),
        projectId: projectId,
        workspaceId: workspaceId,
        organizationId: state?.orgId,
        registerId,
        registerName: registerData?.get_Register?.name,
        initiatorRole: registerData?.get_Register?.initiatorRole, //doubt
        responderRole: registerData?.get_Register?.responderRole, //doubt
        host: window.location.origin,
        initiatorId,
        responderId,
        isDraft: false,
        fileName: values.file.name,
        description: JSON.stringify(values.description),
        documentStatus: values.documentStatus,
        createdAt: new Date().toISOString(),
        linkedFiles: JSON.stringify(values.links),
        projectName: data.get_Project.name,
        workspaceName: workspaceData?.get_Workspace?.name,
        createdById: state.userId,
        createdByName: state.userName,
        fileType: values.file.type,
        documentName: values.documentName,
        client: data.get_Project.client,
        status: 'open',
        type: 'register',
        typeId: registerId,
        updatedAt: new Date().toISOString(),
        updatedBy: state.userId,
        dueDate: values.dueDate,
        name: values.documentName,
        assignedTo,
        libraryType: 'registerDocument',
        responses: []
        //otherFields:{dueDate:values.dueDate}
        // fields: JSON.stringify(fields)
      };
      let size = (values.file.size / 1000).toString();
      if (Number(size) > 1000000) {
        toast.error(`Upload Failed! File size should be less than 1GB`, { id: toastId });
      } else {
        const result = await Storage.put(id, values.file, {
          contentType: values.file.type,
          level: 'public',
          metadata: {
            operation: 'proxy',
            organizationId: state.orgId,
            entityType: 'WORKSPACE',
            entityId: workspaceId,
            userId: state.userId
          }
        });

        if (result) {
          await addRegisterFile({
            variables: variables
          });
          toast.success('Uploaded', {
            id: toastId
          });
          navigate(-1);
        }
      }
    } catch (err) {
      console.error('ERROR', err);
      toast.dismiss(toastId);
      toast.error('OOPS Something went wrong in uploading document');
    }
  };

  if (error || registerDataError || workspaceError || addRegisterFileError) return <ErrorPage />;
  if (loading || usersWithRoles.length <= 0 || registerLoading || workspaceLoading)
    return <Lottie options={defaultOptions} width={400} height={500} />;
  if (registerData.get_Register.registerType === 'static') {
    navigate(`/project/workspace/${projectId}/${workspaceId}/${registerId}/staticdocument`);
  }
  return (
    <>
      <Formik
        initialValues={documentValues}
        validationSchema={CreateNewDocumentInRegisterSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Header>
              <HeaderLeft>
                <BackIcon />
                <NewTitleLeft>
                  <PageTitle>New Document</PageTitle>
                </NewTitleLeft>
              </HeaderLeft>
              <TitleRight>
                <Button disabled={!allowedToUpload || isSubmitting} type="submit">
                  Save
                </Button>
              </TitleRight>
            </Header>
            <TaskDetails>
              <FieldSet>
                <div>
                  <InputWrapper>
                    <InputField disabled={isSubmitting || !allowedToUpload} label="Document Name" name="documentName" />
                  </InputWrapper>
                  <FileUploadWrapper>
                    {values.file ? (
                      <FilePreview>
                        <FileTag>{getFileType(values.file.name)?.toUpperCase()}</FileTag>
                        <span className="file">{values.file.name}</span>
                        <GreyButton type="button" onClick={() => setFieldValue('file', '')}>
                          Remove File <CloseIcon />
                        </GreyButton>
                      </FilePreview>
                    ) : (
                      <>
                        <GreyButton type="button" disabled={!allowedToUpload || isSubmitting} onClick={handleFile}>
                          <FileUploadIcon /> Upload File
                        </GreyButton>
                        <input
                          type="file"
                          name="file"
                          id="file"
                          style={{ display: 'none' }}
                          ref={hiddenFileInput}
                          onChange={(event) => {
                            setFieldValue('file', event.currentTarget.files[0]);
                          }}
                        />
                        {touched['file'] && errors['file'] ? <p style={{ color: 'red' }}>{errors['file']}</p> : null}
                      </>
                    )}
                  </FileUploadWrapper>
                  <ChoicesWrapper>
                    <Label htmlFor="status">Document Status</Label>
                    <Select
                      name="status"
                      options={documenetStatusOptions}
                      defaultValue={documenetStatusOptions[0]}
                      isDisabled={!allowedToUpload || isSubmitting}
                      styles={registerTypeStyles}
                      menuPortalTarget={document.body}
                      onChange={(selectedOption) => {
                        handleChange('documentStatus')(selectedOption.value);
                      }}
                    />
                  </ChoicesWrapper>
                  <InputWrapper>
                    <Label>Project Name</Label>
                    <p>{data.get_Project.name}</p>
                  </InputWrapper>
                  <ChoicesWrapper>
                    <Label>Workspace Name</Label>
                    <p>{workspaceData?.get_Workspace?.name}</p>
                  </ChoicesWrapper>
                  <InputWrapper>
                    <TextAreaField
                      label="Description"
                      name="description"
                      id="description"
                      disabled={!allowedToUpload || isSubmitting}
                    />
                  </InputWrapper>
                  <LinkWrapper>
                    <Label>Links</Label>
                    <FieldArray name="links">
                      {({ insert, remove, push }) => (
                        <>
                          {values.links.length > 0 &&
                            values.links.map((link, index) => {
                              return (
                                <>
                                  <Field key={index} name={`links.${index}`}>
                                    {({ field, form, meta }) => (
                                      <>
                                        <LinkInputWrapper>
                                          <Input
                                            className="link-input"
                                            type="text"
                                            {...field}
                                            error={meta.touched && meta.error}
                                            disabled={!allowedToUpload || isSubmitting}
                                          />
                                          <CloseIcon
                                            onClick={() => (!isSubmitting ? remove(index) : doNothing())}
                                            // disabled={!allowedToUpload}
                                            height="25px"
                                            width="25px"
                                          />
                                        </LinkInputWrapper>
                                        {meta.touched && meta.error && (
                                          <ErrorMessage component={TextError} name={`links.${index}`} />
                                        )}
                                      </>
                                    )}
                                  </Field>
                                </>
                              );
                            })}
                          <div>
                            <Button disabled={!allowedToUpload || isSubmitting} onClick={() => push('')}>
                              + Add Link
                            </Button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </LinkWrapper>
                </div>
                <div>
                  <DateWrapper>
                    <Label htmlFor="date">Document Date</Label>
                    <DateText>
                      {new Date().toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </DateText>
                    <CalendarIconPlacer></CalendarIconPlacer>
                  </DateWrapper>
                  <DateWrapper>
                    <Label htmlFor="date">Due Date</Label>
                    <div>
                      <DateInputField
                        value={values.dueDate}
                        touched={touched.dueDate}
                        errors={errors.dueDate}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        name="dueDate"
                        type="date"
                      />
                    </div>
                  </DateWrapper>
                  <AssigneeWrapper>
                    <Label>Created By</Label>
                    <AssigneeSeparator>
                      <AvatarStyles>
                        <CustomAvatar
                          isTable={false}
                          name={
                            usersWithRoles.length > 0
                              ? getUserWithRole(usersWithRoles, registerData?.get_Register?.initiatorRoleId)
                              : 'fetching'
                          }
                          size={21}
                        />
                      </AvatarStyles>
                      <Small>
                        {new Date().toLocaleDateString('En-US')}{' '}
                        {new Date().toLocaleTimeString('En-US', {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </Small>
                    </AssigneeSeparator>
                  </AssigneeWrapper>
                  <ClientWrapper>
                    <Label>Client</Label>
                    <ClientSeparator>
                      <CustomAvatar isTable={false} name={data?.get_Project?.client} size={21} />
                    </ClientSeparator>
                  </ClientWrapper>
                  <AssigneeWrapper>
                    <Label>Initiator</Label>
                    <AssigneeSeparator>
                      <AvatarStyles>
                        <CustomAvatar
                          name={
                            usersWithRoles.length > 0
                              ? getUserWithRole(usersWithRoles, registerData?.get_Register?.initiatorRoleId)
                              : 'fetching'
                          }
                          size={21}
                        />
                      </AvatarStyles>
                      <Small>{registerData?.get_Register?.initiatorRole}</Small>
                    </AssigneeSeparator>
                  </AssigneeWrapper>
                  <AssigneeWrapper>
                    <Label>Responder</Label>
                    <AssigneeSeparator>
                      <AvatarStyles>
                        <CustomAvatar
                          name={
                            usersWithRoles.length > 0
                              ? getUserWithRole(usersWithRoles, registerData?.get_Register?.responderRoleId)
                              : 'fetching'
                          }
                          size={21}
                        />
                      </AvatarStyles>
                      <Small>{registerData?.get_Register?.responderRole}</Small>
                    </AssigneeSeparator>
                  </AssigneeWrapper>
                </div>
              </FieldSet>
            </TaskDetails>
          </Form>
        )}
      </Formik>
      {/* <Toaster
        toastOptions={{
          success: {
            ...SuccessStyles,
            duration: 5000,
          },
          error: {
            ...errorStyles,
          },
        }}
      /> */}
    </>
  );
}

export default CreateNewDocumentInRegister;
