import { useContext, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ReactComponent as NoOrgIllustration } from '../../../assets/illustration/noOrgChat.svg';
import Button from '../../../components/CustomButton';
import AddNewNoteModal from '../../../components/Modals/MeetingNotes/AddNewNoteModal';
import SelectMeetingNoteTemplateModal from '../../../components/Modals/MeetingNotes/SelectMeetingNoteTemplateModal';
import { LightBlueButton } from '../../../components/styles/ButtonStyles';
import { Header, HeaderLeft, PageTitle, TitleLeft, TitleRight } from '../../../components/styles/Header';
import { useToggle } from '../../../lib/UseToggle';
import { PageNavStyles } from '../../Library/FilesView/Files/ViewFile/ViewFile';
import { NoOrganizationContainer } from './styles';

const MeetingNotes = () => {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const [isAddNewNoteModalOpen, toggleModal] = useToggle(false);
  const [isTemplateModalOpen, toggleTemplateModal] = useToggle(false);
  const selectedProjectId = useRef<string | null>(null);
  const selectedWorkspaceId = useRef<string | null>(null);

  return (
    <>
      <Header>
        <HeaderLeft>
          <PageTitle>Meeting Notes</PageTitle>
        </HeaderLeft>
        <TitleRight>
          {state?.orgId !== 'null' && <Button onClick={toggleModal}>Add New Meeting Note</Button>}
        </TitleRight>
      </Header>
      <>
        {state?.orgId === 'null' ? (
          <NoOrganizationContainer>
            <NoOrgIllustration />
            <p>Sorry! You are not part of any organization.</p>
            <div>
              <LightBlueButton type="button" onClick={() => navigate('/createorganization')}>
                Create Organization
              </LightBlueButton>
            </div>
          </NoOrganizationContainer>
        ) : (
          <>
            <PageNavStyles>
              <NavLink className={(navData) => (navData.isActive ? 'active' : '')} end to="/meetingnotes">
                Internal Notes
              </NavLink>
              <NavLink className={(navData) => (navData.isActive ? 'active' : '')} to="external">
                External Notes
              </NavLink>
            </PageNavStyles>
            <Outlet />
          </>
        )}
      </>
      <AddNewNoteModal
        isOpen={isAddNewNoteModalOpen}
        toggleModal={toggleModal}
        toggleTemplateModal={toggleTemplateModal}
        selectedProjectId={selectedProjectId}
        selectedWorkspaceId={selectedWorkspaceId}
      />
      <SelectMeetingNoteTemplateModal
        isOpen={isTemplateModalOpen}
        toggleModal={toggleTemplateModal}
        selectedProjectId={selectedProjectId.current as string}
        selectedWorkspaceId={selectedWorkspaceId.current as string}
      />
    </>
  );
};

export default MeetingNotes;
