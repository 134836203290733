import { FormikHelpers, FormikState, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useParams } from 'react-router';
import { AppContext } from '../../../AppContext';
import axiosInstance from '../../../axiosInstance';
import OtherContactsValidationsSchema from '../../../components/Validations/AddOtherContactsValidation';
import { designationOptions } from '../../../constants/DesignationOptions';
import { useNavigate } from 'react-router';
import useProjectAddress, { OtherContactsPayload, recordTypes } from './useProjectAddress';

export enum designationEnum {
  OWNER = 'Owner',
  CLIENT = 'Client',
  ENDUSER = 'End User'
}

export type ModuleType = 'project' | 'workspace';

export type IWorkspaceId = {
  workspaceId: string;
};

export type Contacts = {
  name: string;
  phone: string;
  emailId: string;
  address: string;
  designation: designationEnum | '';
};

type RouterProps = {
  contactId?: string;
  isEdit?: boolean | null;
  module?: ModuleType;
};

export type isEditProp = {
  id?: string;
};

export const useOtherContacts = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  // const { contactedId, contactId } = useParams();
  const { projectId, workspaceId } = useParams();
  const { submitData } = useProjectAddress();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<Contacts>();
  const [isSubmitting, setSubmitting] = useState(false);
  const { state: linkState }: { state: RouterProps | null } = useLocation();
  const initialState: Contacts = {
    name: '',
    phone: '',
    emailId: '',
    address: '',
    designation: ''
  };

  useEffect(() => {
    const getContactDetails = async () => {
      if (linkState?.isEdit && linkState?.module === 'project') {
        try {
          setLoading(true);
          let res = await axiosInstance.get(
            `/project/contact?id=${linkState.contactId}&projectId=${projectId}&recordType=${recordTypes.CONTACT}`
          );
          let obj: Contacts = {
            name: res.data.data.recordName,
            phone: res.data.data?.phoneNumber,
            emailId: res.data.data.emailId,
            address: res.data.data.address,
            designation: res.data.data.contactName
          };
          setContact(obj);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    getContactDetails();
  }, []);

  useEffect(() => {
    const getWorkspaceContactDetails = async () => {
      if (linkState?.isEdit && linkState?.module === 'workspace') {
        try {
          setLoading(true);
          let response = await axiosInstance.get(
            `/workspace/contact?id=${linkState.contactId}&workspaceId=${workspaceId}&recordType=${recordTypes.CONTACT}`
          );
          let obj: Contacts = {
            name: response.data.data?.recordName,
            phone: response.data.data?.phoneNumber,
            emailId: response.data.data?.emailId,
            address: response.data.data?.address,
            designation: response.data.data?.contactName
          };
          setContact(obj);
        } catch (error) {
          console.error('ERROR in edit ws contact', error);
        } finally {
          setLoading(false);
        }
      }
    };
    getWorkspaceContactDetails();
  }, []);

  const AddWorkspaceContact = (data: OtherContactsPayload) => {
    return axiosInstance.post(`/workspace/contact`, data);
  };

  const UpdateWorkspaceContact = (data: OtherContactsPayload) => {
    return axiosInstance.patch(`/workspace/contact`, data);
  };

  const handleEdit = async (
    values: Contacts,
    resetForm: (nextState?: Partial<FormikState<Contacts>> | undefined) => void
  ) => {
    if (values?.designation) {
      setSubmitting(true);
      let obj: OtherContactsPayload & isEditProp = {
        entityType: 'PROJECT',
        projectId: projectId,
        organizationId: state.orgId,
        recordType: recordTypes.CONTACT,
        recordName: values.name,
        emailId: values.emailId,
        address: values.address,
        phoneNumber: values.phone,
        id: linkState?.contactId,
        contactName: values?.designation as designationEnum
      };

      let toastId = toast.loading('Sending data');
      await submitData(obj)
        .then((res) => {
          if (res.data) {
            toast.dismiss(toastId);
            toast.success('Contact edited successfully');
            resetForm();
            setContact({
              name: '',
              phone: '',
              emailId: '',
              address: '',
              designation: ''
            });
            setSubmitting(false);
            navigate(`/project/${projectId}`);
          }
        })
        .catch((error) => {
          toast.dismiss(toastId);
          toast.error('Error while editing data');
          setSubmitting(false);
          console.error('ERROR IN UPDATE CONTACT', error);
        });
    }
  };

  const handleAdd = async (
    values: Contacts,
    resetForm: (nextState?: Partial<FormikState<Contacts>> | undefined) => void
  ) => {
    const toastId = toast.loading('Submitting data');
    setSubmitting(true);
    if (values?.designation) {
      let obj: OtherContactsPayload = {
        entityType: 'PROJECT',
        projectId: projectId,
        organizationId: state.orgId,
        recordType: recordTypes.CONTACT,
        recordName: values.name,
        emailId: values.emailId,
        address: values.address,
        phoneNumber: values.phone,
        contactName: values.designation
      };
      await submitData(obj)
        .then((res) => {
          if (res) {
            toast.dismiss(toastId);
            toast.success('Added a contact successfully.');
            resetForm();
            setSubmitting(false);
            navigate(`/project/${projectId}`);
          }
        })
        .catch((error) => {
          console.error('ERROR IN SUBMITTING DATA', error);
          toast.dismiss(toastId);
          setSubmitting(false);
          toast.error('Oh no something went wrong.Please try again');
        });
    }
  };

  const handleSubmit = async (values: Contacts, { resetForm }: FormikHelpers<Contacts>) => {
    if (linkState?.isEdit) {
      handleEdit(values, resetForm);
    } else {
      handleAdd(values, resetForm);
    }
  };

  const handleWorkspaceContact = async (
    values: Contacts,
    resetForm: (nextState?: Partial<FormikState<Contacts>> | undefined) => void
  ) => {
    const toastId = toast.loading('Submitting Data');
    setSubmitting(true);
    let obj: OtherContactsPayload & IWorkspaceId = {
      entityType: 'WORKSPACE',
      projectId: projectId,
      workspaceId: workspaceId,
      organizationId: state.orgId,
      recordType: recordTypes.CONTACT,
      recordName: values.name,
      emailId: values.emailId,
      address: values.address,
      phoneNumber: values.phone,
      contactName: values.designation as designationEnum
    };
    await AddWorkspaceContact(obj)
      .then((res) => {
        if (res) {
          toast.dismiss(toastId);
          toast.success('Added a contact successfully.');
          resetForm();
          navigate(`/project/workspace/${projectId}/${workspaceId}`);
        }
      })
      .catch((error) => {
        console.error('ERROR IN SUBMITTING DATA', error);
        toast.dismiss(toastId);

        toast.error('Oh no something went wrong.Please try again');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdateWorkspaceContact = async (
    values: Contacts,
    resetForm: (nextState?: Partial<FormikState<Contacts>> | undefined) => void
  ) => {
    setSubmitting(true);
    let obj: OtherContactsPayload & isEditProp & IWorkspaceId = {
      entityType: 'WORKSPACE',
      projectId: projectId,
      organizationId: state.orgId,
      workspaceId: workspaceId,
      recordType: recordTypes.CONTACT,
      recordName: values.name,
      emailId: values.emailId,
      address: values.address,
      phoneNumber: values.phone,
      id: linkState?.contactId,
      contactName: values.designation as designationEnum
    };
    let toastId = toast.loading('Sending data');
    await UpdateWorkspaceContact(obj)
      .then((res) => {
        if (res.data) {
          toast.dismiss(toastId);
          toast.success('Contact edited successfully');
          resetForm();
          setContact({
            name: '',
            phone: '',
            emailId: '',
            address: '',
            designation: ''
          });
          navigate(`/project/workspace/${projectId}/${workspaceId}`);
        }
      })
      .catch((error) => {
        toast.dismiss(toastId);
        toast.error('Error while editing data');
        console.error('ERROR IN UPDATING CONTACT', error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleWorkspaceSubmit = async (values: Contacts, { resetForm }: FormikHelpers<Contacts>) => {
    if (linkState?.isEdit && linkState?.module === 'workspace') {
      handleUpdateWorkspaceContact(values, resetForm);
    } else {
      handleWorkspaceContact(values, resetForm);
    }
  };

  const formik = useFormik({
    initialValues: contact || initialState,
    onSubmit: projectId && workspaceId ? handleWorkspaceSubmit : handleSubmit,
    validationSchema: OtherContactsValidationsSchema,
    enableReinitialize: true
  });

  const designationOption = designationOptions?.find((option) => option.value === formik.values.designation);

  return {
    formik,
    initialState,
    handleSubmit,
    contact,
    loading,
    linkState,
    isSubmitting,
    designationOption
  };
};
