import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../../AppContext';
import LiveConnectionContext from '../../../contexts/LiveConnectionContext';
import { handleMeetingNoteAutoSave } from '../../../lib/meetingNoteHelper';
import { handleProjectNoteAutoSave } from '../../../lib/projectNoteHelper';
import { handleTaskNoteAutoSave } from '../../../lib/taskNoteEditor';
import { BlockDataI, MeetingNoteI } from '../../../pages/MeetingNotes/types';
import { deleteSection, updateBlockContent, updateSectionHeader } from '../../../reducers/meetingNote';
import {
  deleteSection as projectNoteSectionDelete,
  ProjectNoteI,
  updateBlockContent as updateProjectNoteContent,
  updateSectionHeader as updateProjectNoteHeader
} from '../../../reducers/projectNote';
import {
  deleteSection as taskNoteSectionDelete,
  TaskNoteI,
  updateBlockContent as updateTaskNoteContent,
  updateSectionHeader as updateTaskNoteHeader
} from '../../../reducers/taskNote';

type EditorType = 'MEETING_NOTE' | 'PROJECT_NOTE' | 'TASK_NOTE';

const useTextEditor = (sectionId: string, block: BlockDataI | null) => {
  // @ts-ignore
  const ws: WebSocket = useContext(LiveConnectionContext);
  const dispatch = useDispatch();

  const {
    state: { authToken }
  } = useContext(AppContext);

  const handleUpdateContents = (editorType: EditorType, content: string) => {
    if (!block) return;
    switch (editorType) {
      case 'MEETING_NOTE':
        dispatch(updateBlockContent({ sectionId: sectionId, blockId: block.blockId, blockContent: content }));
        break;
      case 'PROJECT_NOTE':
        dispatch(updateProjectNoteContent({ sectionId: sectionId, blockId: block.blockId, blockContent: content }));
        break;

      case 'TASK_NOTE':
        dispatch(updateTaskNoteContent({ sectionId: sectionId, blockId: block.blockId, blockContent: content }));
        break;

      default:
        break;
    }
  };

  const handleHeaderUpdate = (editorType: EditorType, content: string) => {
    switch (editorType) {
      case 'MEETING_NOTE':
        dispatch(updateSectionHeader({ sectionId: sectionId, header: content }));
        break;
      case 'PROJECT_NOTE':
        dispatch(updateProjectNoteHeader({ sectionId: sectionId, header: content }));
        break;

      case 'TASK_NOTE':
        dispatch(updateTaskNoteHeader({ sectionId: sectionId, header: content }));

        break;

      default:
        return;
    }
  };

  /**
   * Delete a section from the editor
   * @param editorType
   * @param content
   * @returns
   */
  const handleSectionDelete = (editorType: EditorType) => {
    switch (editorType) {
      case 'MEETING_NOTE':
        dispatch(deleteSection(sectionId));
        break;
      case 'PROJECT_NOTE':
        dispatch(projectNoteSectionDelete(sectionId));
        break;

      case 'TASK_NOTE':
        dispatch(taskNoteSectionDelete(sectionId));

        break;

      default:
        return;
    }
  };

  const handleAddEditStatus = (editorType: EditorType, noteData: MeetingNoteI | ProjectNoteI | TaskNoteI) => {
    if (!block) return;

    let data = {};
    switch (editorType) {
      case 'MEETING_NOTE':
        data = {
          type: 'EDIT_NOTE_STATUS',
          noteId: noteData._id,
          blockId: block.blockId,
          token: authToken,
          noteType: 'MEETING_NOTE'
        };
        break;
      case 'PROJECT_NOTE':
        data = {
          type: 'EDIT_NOTE_STATUS',
          noteId: noteData._id,
          workspaceId: noteData.workspaceId,
          token: authToken,
          blockId: sectionId,
          noteType: 'workspace'
        };
        break;

      case 'TASK_NOTE':
        data = {
          type: 'EDIT_NOTE_STATUS',
          noteId: noteData._id,
          workspaceId: noteData.workspaceId,
          token: authToken,
          blockId: sectionId,
          noteType: 'task'
        };

        break;

      default:
        return;
    }

    ws.send(JSON.stringify(data));
  };

  const handleRemoveEditStatus = (editorType: EditorType, noteData: MeetingNoteI | ProjectNoteI | TaskNoteI) => {
    if (!block) return;

    let data = {};
    switch (editorType) {
      case 'MEETING_NOTE':
        data = {
          type: 'REMOVE_EDIT_NOTE_STATUS',
          noteId: noteData._id,
          blockId: block.blockId,
          token: authToken,
          noteType: 'MEETING_NOTE'
        };
        break;
      case 'PROJECT_NOTE':
        data = {
          type: 'REMOVE_EDIT_NOTE_STATUS',
          noteId: noteData._id,
          workspaceId: noteData.workspaceId,
          blockId: sectionId,
          token: authToken,
          noteType: 'workspace'
        };
        break;

      case 'TASK_NOTE':
        data = {
          type: 'REMOVE_EDIT_NOTE_STATUS',
          noteId: noteData._id,
          workspaceId: noteData.workspaceId,
          blockId: sectionId,
          token: authToken,
          noteType: 'task'
        };

        break;

      default:
        return;
    }

    ws.send(JSON.stringify(data));
  };

  const handleAutoSave = (editorType: EditorType, blockData: string | undefined, isDelete = false) => {
    switch (editorType) {
      case 'MEETING_NOTE':
        /**
         * Only meeting note we have masterId
         */
        let masterId: string | undefined | null = block?.masterId;
        if (isDelete) masterId = null;
        handleMeetingNoteAutoSave(ws, authToken, masterId as string, blockData);
        break;
      case 'PROJECT_NOTE':
        handleProjectNoteAutoSave(ws, authToken);
        break;

      case 'TASK_NOTE':
        handleTaskNoteAutoSave(ws, authToken);

        break;

      default:
        return;
    }
  };

  return {
    handleUpdateContents,
    handleHeaderUpdate,
    handleAddEditStatus,
    handleRemoveEditStatus,
    handleAutoSave,
    handleSectionDelete
  };
};

export default useTextEditor;
