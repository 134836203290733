import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import axiosInstance from '../axiosInstance';
// import { array_move } from "./conversations";

const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const fetchDashboard = createAsyncThunk('dashboard/fetchDashboard', async (orgId: String) => {
  const result =
    orgId !== 'null' ? await axiosInstance.get(`/dashboard?orgId=${orgId}`) : await axiosInstance.get(`/dashboard`);
  return result?.data;
});
export const postDashboardApi = createAsyncThunk(
  'dashboard/postDashboard',
  //data = {orgId,type="config",configData:[{}]}
  // or
  //data = {orgId,type="metaData",metaDataIds:[{id:'someid1'},{id:'someid2'}],metaData={_id,name,status,updatedAt}}
  async (data: any, { getState }) => {
    const state = getState();
    let finalData = { ...data };
    if (data.type === 'metaData') {
      let moduleIds = [...state.dashboard.metaDataIds[data.moduleType]];
      let idExists = moduleIds.findIndex((item) => item.id === data.metaDataId);
      if (idExists === -1) {
        finalData.metaDataIds = [{ id: data.metaDataId }, ...moduleIds];
        finalData.reordered = false;
        finalData.idIndex = idExists;
        await axiosInstance.post(`/dashboard`, finalData);
      } else if (idExists === 0) {
        finalData.metaDataIds = [...moduleIds];
        finalData.idIndex = idExists;
      } else if (idExists > 0) {
        const newArray = array_move(moduleIds, idExists, 0);
        finalData.metaDataIds = newArray;
        finalData.reordered = true;
        finalData.idIndex = idExists;
        await axiosInstance.post(`/dashboard`, finalData);
      } else if (data.type === 'config') {
        const result = await axiosInstance.post(`/dashboard`, finalData);
        if (result) {
          toast.success('Dashboard Updated');
        }
      }
    }
    return finalData;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboardData: [],
    latestId: { FILES: '', TASKS: '', PROJECTS: '' },
    metaDataIds: { FILES: [], TASKS: [], PROJECTS: [] },
    error: false,
    loading: false,
    apidashboardData: []
  },
  reducers: {
    // postDashboard: (state, action) => {
    //   let index=state.dashboardData.findIndex((data)=>{
    //       return data.moduleType === action.payload.data.moduleType
    //   })
    //   state.dashboardData[index].data.unshift(action.payload.data.metaData)
    //   //metaData = basic data required to render the dashboard cards
    //   // state.latestId[action.payload.data.moduleType]=action.payload.data.metaData.id

    // },
    // configDashboard: (state,action) =>{
    //     //[{order,moduleType,isvisible,...},{},{}]
    //   // action.payload.data.map((data)=>{
    //   // let index=state.dashboardData.findIndex((indexData)=>{
    //   //     return indexData.moduleType === data.moduleType
    //   // })
    //   // state.dashboardData[index].order=data.order
    //   // state.dashboardData[index].isVisible=data.isVisible
    //   // return true
    //   // })
    //   action.payload.map((data)=>{
    //     let index=state.dashboardData.findIndex((indexData)=>{
    //         return indexData.moduleType === data.moduleType
    //     })
    //     state.dashboardData[index].order=data.order
    //     state.dashboardData[index].isVisible=data.isVisible
    //     return true
    //     })
    // state.loading = false;
    // state.error = false;
    // },
    reorderItems: (state, action) => {
      state.dashboardData = action.payload;
    },
    resetItems: (state) => {
      state.dashboardData = [...state.apidashboardData];
    },
    updateItem: (state, action) => {
      console.log('CALLING REDUCER');
      console.log('ACTION PAYLOAD', action.payload);
      state.dashboardData = state.dashboardData.map((module) => {
        if (module.moduleType === 'TASKS') {
          let taskData = module.data.map((task) => {
            if (task._id === action.payload._id) {
              return action.payload;
            } else return task;
          });
          console.log('UPDATED TASK DATA', taskData);
          return { ...module, data: taskData };
        } else return module;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        // state.dashboardData = action.payload;
        state.dashboardData = action.payload.sort((a, b) => a.order - b.order);
        state.apidashboardData = [...state.dashboardData];
        let ids;
        //  action.payload = [
        //     {
        //       order: 1,
        //       isVisible: true,
        //       title: ‘Task Manager’,
        //       id: ‘1’,
        //       moduleType:'TASKS',
        //       // data: [{id,name,status,..}]
        //     },{...},...]
        action.payload.map((value) => {
          //ids = [{id:'someid1'},{id:'someid2'},...]
          ids = value.data.map((data) => {
            return { id: data?._id };
          });
          //metadata={Tasks:[{id:'someid1'},{id:'someid2'},...],...}
          state.metaDataIds[value.moduleType] = ids;
          // state.latestId
          return true;
        });
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.dashboardData = [];
      })
      .addCase(fetchDashboard.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(postDashboardApi.fulfilled, (state, action) => {
        if (action.payload.type === 'metaData') {
          let index = state.dashboardData.findIndex((data) => {
            return data.moduleType === action.payload.moduleType;
          });
          if (action.payload.reordered) {
            let newArray = array_move(state.dashboardData[index].data, action.payload.idIndex, 0);
            state.dashboardData[index].data = newArray;
            state.metaDataIds[action.payload.moduleType] = action.payload.metaDataIds;
            state.dashboardData[index].data[0] = action.payload.metaData;
          } else if (action.payload.idIndex === -1) {
            state.dashboardData[index].data.unshift(action.payload.metaData);
            //metaData = basic data required to render the dashboard cards i.e {_id,name,status,...}
            // state.latestId[action.payload.moduleType]=action.payload.metaData._id
            state.metaDataIds[action.payload.moduleType] = action.payload.metaDataIds;
          } else if (action.payload.idIndex === 0) {
            state.dashboardData[index].data[action.payload.idIndex] = action.payload.metaData;
          }
        } else if (action.payload.type === 'config') {
          state.apidashboardData = [...state.dashboardData];
        }
        //     // action.payload.map((data)=>{
        //     //     let index=state.dashboardData.findIndex((indexData)=>{
        //     //         return indexData.moduleType === data.moduleType
        //     //     })
        //     //     state.dashboardData[index].order=data.order
        //     //     state.dashboardData[index].isVisible=data.isVisible
        //     //     return true
        //     //     })
        // }
        state.loading = false;
        state.error = false;
      })
      .addCase(postDashboardApi.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.dashboardData = [];
      })
      .addCase(postDashboardApi.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      });
  }
});

export default dashboardSlice;
export const { resetItems, reorderItems, updateItem } = dashboardSlice.actions;
