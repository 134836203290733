import styled from '@emotion/styled'
import React from 'react'
import Tasks from '../../components/Tasks'

const DashboardStyles = styled.div`
  padding-left: 45px;
  padding-right: 45px;
`

export const AllTasks = () => {
  return (
    <DashboardStyles>
      <Tasks />
    </DashboardStyles>
  )
}


