import React from 'react';
import styled from '@emotion/styled';
import { Input } from '../../../components/styles/Inputs';

interface InputFieldProps {
  type?: string;
  name?: string;
  formik?: any;
  disabled?: boolean;
  placeHolder?: string;
  style?: any;
  ref?: any;
  id?: string;
}

const Container = styled.span`
  .input {
    width: 100%;
    &:focus {
      width: 100%;
      border-color: #007eff;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
      outline: none;
    }
  }
  .input.error {
    border-color: #ff3c3c;
    width: 100%;
  }
  .input-feedback {
    margin-top: 7px;
    color: #ff3c3c;
  }
`;

const InputField: React.FC<InputFieldProps> = (props) => {
  const { type, name, formik, disabled, placeHolder, style, ref, id } = props;
  return (
    <Container>
      <>
        <Input
          {...formik.getFieldProps(name)}
          className={formik.errors[name] && formik.touched[name] ? 'input error' : 'input'}
          type={type}
          disabled={disabled}
          placeholder={placeHolder}
          style={style}
          ref={ref}
          id={id}
        />
        {formik.touched[name] && formik.errors[name] ? <p className="input-feedback">{formik.errors[name]}</p> : null}
      </>
    </Container>
  );
};

export default InputField;
