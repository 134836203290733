import { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import axiosInstance from '../../axiosInstance';
import organizationPlaceholder from '../../assets/icons/orgplaceholder.svg';
import { LightBlueButton } from '../../components/styles/ButtonStyles';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { GET_ORGANIZATION } from '../../graphql/operations/Queries/Organization/OrganizationQueries';
import { fetchDashboard } from '../../reducers/dashboard';
import { useDispatch } from 'react-redux';
// import Button, { CustomButton } from "../../components/CustomButton";
// import { Auth } from "aws-amplify";
// import IconOrg from "../../assets/icons/IconOrg.svg";
// import IconMail from "../../assets/icons/IconMail.svg";
// import IconPhone from "../../assets/icons/IconPhone.svg";
// import { PinnedProjectsContext } from "../../PinnedProjectContext";

const OrgHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  color: #1c1c1c;
  border-bottom: solid 1px #e6e6e6;
  .image {
    position: relative;
    img {
      height: 20vh;
      width: 18vw;
      object-fit: cover;
    }
    .options {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      backdrop-filter: blur(10px);
      transition: all 0.5s ease;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        background: #dbe2e0;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        color: #26303e;
        li {
          padding: 5px 10px;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
          & ~ li {
            border-top: solid 1px #999;
          }
        }
      }
    }
    &:hover {
      .options {
        opacity: 1;
      }
    }
  }
  h2 {
    font-size: 25px;
    font-weight: 600;
    margin: 30px 0 15px;
  }
  h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 15px;
  }
  .additionInfo {
    .icoList {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
        height: 35px;
        width: 35px;
      }
      span {
        line-height: 1;
        font-size: 16px;
      }
      small {
        font-size: 16px;
        cursor: pointer;
        color: #93979f;
        &:hover {
          color: var(--button-background);
        }
      }
    }
  }
  .logoutButton {
    margin-top: 1.2rem;
  }
`;

const OrganizationList = styled.div`
  display: flex;
  flex-direction: column;

  .organisation {
    cursor: pointer;
    display: flex;
    padding: 15px 21px;
    border-bottom: solid 1px #e6e6e6;
    align-items: flex-start;
    &:hover {
      background: #f6f5f5;
    }

    .avatarContainer {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }

    .organisationDetails {
      margin-left: 21px;
      display: flex;
      align-items: flex-start;
      width: auto;
      flex-direction: column;
      flex-wrap: wrap;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .createOrganization {
    margin: 1rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
`;

const OrganizationFlymenu = ({ setOpenOrganization }) => {
  const { state, dispatch } = useContext(AppContext);
  const reduxDispatch = useDispatch();

  let navigate = useNavigate();
  const createOrganization = () => {
    setOpenOrganization(false);
    navigate('/createorganization');
  };
  const viewOrg = () => {
    setOpenOrganization(false);
    navigate(`/organization/${state.orgId}`);
  };
  const { data: getOrganization } = useQuery(GET_ORGANIZATION, {
    variables: {
      id: state?.orgId
    },
    skip: state.orgId === null || state.orgId === 'null' || !state.orgId,
    fetchPolicy: 'network-only'
  });

  const generateQuery = (arrayOfIds: any) => {
    let emptyQuery = ``;
    if (arrayOfIds?.length > 0) {
      for (const [index, value] of arrayOfIds.entries()) {
        emptyQuery += `org${index}: get_Organization(id: "${value.entityId}") {
              _id
              name
              photo
          }\n`;
      }
    } else {
      emptyQuery = `get_Organization(id: "null") {
          _id
          name
          photo
        }\n`;
    }
    return gql`
      query getOrganizationQuery @api(name: "organization") {
          ${emptyQuery}
      }
    `;
  };
  const batchGetOrganization = generateQuery(state?.allOrganizations?.Items);
  const { data: getOrganizationLists, refetch } = useQuery(batchGetOrganization, {
    fetchPolicy: 'cache-and-network',
    variables: {
      noValidate: true
    }
  });

  useEffect(() => {
    refetch();
  }, []);

  const activeOrg = (key: any) => {
    try {
      axiosInstance
        .patch(`/users/${state.userId}`, {
          organizationId: key?._id
        })
        .then(() => {
          dispatch({
            type: 'UPDATE_USER',
            data: {
              orgId: key?._id
            }
          });
          reduxDispatch(fetchDashboard(key?._id));
          navigate(`/organization/${key._id}`);
        });
    } catch (err) {
      console.error('ERROR', err);
    }
  };
  return (
    <>
      <OrgHeader>
        <div className="image">
          <img
            src={
              getOrganization?.get_Organization?.photo
                ? `${getOrganization?.get_Organization?.photo}?${new Date().getTime()}`
                : organizationPlaceholder
            }
            alt="organization"
          />
        </div>
        <h2>{getOrganization?.get_Organization?.name}</h2>
        {state?.orgId === 'null' ? (
          <LightBlueButton type="button" onClick={createOrganization}>
            {' '}
            Create Organization
          </LightBlueButton>
        ) : (
          <LightBlueButton type="button" onClick={viewOrg}>
            View Organization
          </LightBlueButton>
        )}
      </OrgHeader>
      <OrganizationList>
        {/* {state?.allOrganizations?.Items.map((list) => { */}
        {getOrganizationLists
          ? Object.keys(getOrganizationLists).map((key, index) => {
              return getOrganizationLists[key]?._id !== state?.orgId ? (
                <div key={index} className="organisation" onClick={() => activeOrg(getOrganizationLists[key])}>
                  <div className="avatarContainer">
                    <img
                      src={
                        getOrganizationLists[key]?.photo ? getOrganizationLists[key]?.photo : organizationPlaceholder
                      }
                      alt="avatar"
                    />
                  </div>
                  <div className="organisationDetails">
                    <p> {getOrganizationLists[key]?.name} </p>
                  </div>
                </div>
              ) : null;
            })
          : null}
      </OrganizationList>
    </>
  );
};

export default OrganizationFlymenu;
