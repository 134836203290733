import { useContext } from 'react';
import styled from '@emotion/styled';
import BackIcon from '../../assets/SvgComponents/BackIcon';
import { Header, HeaderLeft, NewTitleLeft, PageTitle, TitleRight } from '../../components/styles/Header';
import { AppContext } from '../../AppContext';
import { PageNavStyles } from '../Library/FilesView/Files/ViewFile/ViewFile';
import NavigationLink from '../../components/NavigationLink';
import { Outlet, useNavigate } from 'react-router';
import Button from '../../components/CustomButton';
import { Link } from 'react-router-dom';

export const MainBox = styled.div`
  display: flex;
  width: 100%;
  /* padding: 2rem 3rem 0px; */
  border-radius: 10px;
  gap: 2rem;
`;
export const LeftBox = styled.div`
  padding: 2rem 3rem 0px;
  width: 50%;
  border-radius: 10px;
  background-color: rgb(246, 246, 246);
  display: flex;
  max-height: 40vh;
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 2rem 3rem;
`;

export const TitleText = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1c1c1c;
  padding-bottom: 20px;
  border-bottom: 1px solid #cfd3db;
`;
export const MiddleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 10px;
  .visa-content {
    display: flex;
    align-items: center;
    width: 50%;
  }
  span {
    margin-left: 10px;
  }
`;
export const PaymentButton = styled.div`
  width: 300px;
`;
export const BillingBox = styled.div`
  padding-top: 24px;
  border-top: 1px solid #cfd3db;
  border-bottom: 1px solid #cfd3db;
  margin-top: 10px;
`;
export const BillingText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #1c1c1c;
`;
export const EmailBlock = styled.div`
  display: flex;
  /* margin-top: 20px; */
  flex-direction: column;
  width: 160px;
`;
export const EmailTag = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #acadac;
  margin-bottom: 16px;
`;
export const EmailText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 16px;
  /* margin-left: 80px; */
`;
export const AddressBlock = styled.div`
  display: flex;
  align-items: baseline;
  padding: 10px 0;

  flex-direction: column;
`;
export const NameBlock = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  word-break: keep-all;
  word-wrap: break-word;
  width: 300px;
  /* margin-left: 14px; */
`;
export const Address = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #acadac;
`;
export const BillingContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const NewPaymentMethodDiv = styled.div`
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 10px;
`;

const UpdatePlan = () => {
  let navigate = useNavigate();
  const { state } = useContext(AppContext);
  const goBack = () => {
    navigate(`/organization/${state.orgId}`);
  };
  return (
    <>
      <Header>
        <HeaderLeft>
          <BackIcon handlenavigate={() => goBack()} />
          <NewTitleLeft>
            <PageTitle>Manage Subscription</PageTitle>
          </NewTitleLeft>
        </HeaderLeft>
        <TitleRight>
          <Link to="/pricing">
            <Button>View Plans</Button>
          </Link>
        </TitleRight>
      </Header>
      <PageNavStyles>
        <NavigationLink end to={`/organization/${state.orgId}/managesubscription`}>
          Monthly
        </NavigationLink>
        <NavigationLink to="yearly">Yearly</NavigationLink>
      </PageNavStyles>
      <Outlet />
    </>
  );
};
export default UpdatePlan;
