import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppContext } from '../../AppContext';
import { createNoteMutation } from '../../graphql/operations/Mutations/Library/NoteMutations';
import { ButtonWrapper } from '../../pages/Projects/AboutProject/ProjectInformation/ProjectWorkspacesView';
import { ModalInput, ModalText } from '../../pages/Workspaces/Registers/CreateNewRegister';
import Button from '../CustomButton';
import Modal from '../Modal';
import { ModalBody, ModalContainer, ModalTitle } from '../styles/ModalStyles';

const CreatePersonalNoteModal = ({ isOpen, toggleModal }: { isOpen: boolean; toggleModal: () => void }) => {
  const [name, setName] = useState<string>('');
  const navigate = useNavigate();
  const [addNote, { error }] = useMutation(createNoteMutation, {
    // refetchQueries: [{ query: 'LIST_NOTES_QUERY' }]
  });
  const { state } = useContext(AppContext);

  const handleCreateNote = async () => {
    const toastId = toast.loading('Creating...');

    try {
      let Response = await addNote({
        variables: {
          libraryType: 'notesAndCollections',
          title: name,
          content: '',
          createdAt: new Date().toISOString(),
          createdBy: state?.userId,
          noteType: 'user',
          typeId: state?.userId,
          noteType_typeId: `user_${state?.userId}`,
          projectId: 'NOPROJECT',
          updateBy: state?.userId,
          updatedAt: new Date().toISOString()
        }
      });
      if (Response) {
        toast.success('New Note Created', {
          id: toastId
        });
        setTimeout(() => {
          toggleModal();
          navigate(`/notes/${Response?.data?.add_NoteAndDisscussion.result._id}`);
        }, 1000);
      }
    } catch (e) {
      toast.dismiss(toastId);
      toast.error('oh no something went wrong');
      console.error(e);
    }
    setName('');
  };
  return (
    <Modal open={isOpen} onClose={toggleModal}>
      <ModalContainer width="550px" style={{ paddingBottom: '20px' }}>
        <ModalTitle>Create Note</ModalTitle>
        <ModalBody style={{ paddingLeft: '40px', maxHeight: '80vh', overflowY: 'scroll' }}>
          <ModalText>Enter the note name</ModalText>
          <ModalInput value={name} placeholder="Note name" onChange={(e) => setName(e.target.value)} />
        </ModalBody>
        <ButtonWrapper>
          <Button secondary onClick={toggleModal}>
            Cancel
          </Button>
          <Button onClick={handleCreateNote} disabled={name.trim().length === 0}>
            Create Note
          </Button>
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default CreatePersonalNoteModal;
